import { CalculeiApiService } from './../../../../../servicos/calculei/calculei-api.service';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { RequestCalculoBeneficios } from 'src/app/modelos/previdenciario/requestcalculobeneficios';
import { ListarGruposSumarioCalculoBeneficiosService } from 'src/app/servicos/calculo/previdenciario/listar-grupos-sumario-calculo-beneficios.service';
import { ToastrService } from "ngx-toastr";


import { v4 as uuidv4 } from 'uuid';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { ListarRelatorioRendaMensalInicialPdfService } from 'src/app/servicos/relatorio/listar-relatorio-renda-mensal-inicial-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { FasesBeneficios } from 'src/app/modelos/ui/fasesbeneficios';
import { FluxoBeneficios } from 'src/app/modelos/ui/fluxobeneficios';
import { FluxoRevisaoVidaToda } from 'src/app/modelos/ui/fluxorevisaovidatoda';
import { FasesRevisaoVidaToda } from 'src/app/modelos/ui/fasesrevisaovidatoda';
import { Fluxos } from 'src/app/modelos/ui/fluxos';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { RelatorioBeneficioComponent } from '../relatorio-beneficio/relatorio-beneficio.component';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { StatusBeneficio } from 'src/app/modelos/previdenciario/statusbeneficio';

@Component({
  selector: 'app-grupos-sumario-calculo-beneficios',
  templateUrl: './grupos-sumario-calculo-beneficios.component.html',
  styleUrls: ['./grupos-sumario-calculo-beneficios.component.scss']
})
export class GruposSumarioCalculoBeneficiosComponent implements OnInit {

  @ViewChild('relatorioBeneficio') relatorioBeneficio: RelatorioBeneficioComponent;

  @Input() calculoBeneficios: CalculoBeneficios;
  @Input() beneficioRelatorio: Beneficio
  @Output() visualizarSumarioEvent = new EventEmitter<boolean>();
  @Output() gruposProntoEvent = new EventEmitter<CalculoBeneficios>();
  @Output() requestFinalizadaEvent = new EventEmitter<boolean>();
  @Output() processandoCNISEvent = new EventEmitter<boolean>();
  @Output() processandoEvent = new EventEmitter<boolean>();

  @Output() verDetalheNavegacaoEvent = new EventEmitter<Beneficio>();
  @Output() inicializadoEvent = new EventEmitter<Navegacao>();


  visaoRelatorioFlag: boolean = false;
  aguardandoPdf: boolean = false;
  revisaoVidaToda: boolean = false;
  

  private statusBeneficioType = StatusBeneficio;



  aguarde: boolean;
  beneficioCanvas: Beneficio;



  contribuicoesEvolucaoSalario: Contribuicao[];

  constructor(private listarGruposSumarioCalculoBeneficiosService: ListarGruposSumarioCalculoBeneficiosService,
    private listarPDFService: ListarPDFService,
    private calculeiApiService: CalculeiApiService,
    private configService: ConfigService,
    private listarRelatorioRendaMensalInicialPdfService: ListarRelatorioRendaMensalInicialPdfService,
    private notificacoesService: NotificacoesService, private changeDetector: ChangeDetectorRef) {

    this.configService.getAguarde().subscribe(status => this.aguarde = status);
  }



  ngOnInit(): void {


  }

  gerarPDFSumario() {
    // console.log('pdf sumario');

    // this.aguardeVisivel = true;
    this.configService.setAguarde(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    this.calculoBeneficios.tipoCalculo = CalculoTipos.CALCULOBENEFICIOS;
    this.calculoBeneficios.usuario = Global.usuario;

    // console.log('calculobeneficios', this.calculoBeneficios);

    this.calculeiApiService.apiBlobLx("ListarPDF",this.calculoBeneficios)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO-SUMARIO-RESULTADOS-RMI-' + this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.configService.setAguarde(false);

      });
  }

  gerarPDFBeneficio(beneficioSelecionado: Beneficio) {



    // console.log('pdf beneficio selecionado', beneficioSelecionado.qualificacaoDetalhada);

    // this.aguardeVisivel = true;
    this.configService.setAguarde(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');



    // this.calculoBeneficios.tipoCalculo = CalculoTipos.BENEFICIO;
    this.calculoBeneficios.usuario = Global.usuario;

    let calculoListarPDF: CalculoBeneficios = JSON.parse(JSON.stringify(this.calculoBeneficios));
    calculoListarPDF.beneficios = new Array();
    calculoListarPDF.beneficios.push(beneficioSelecionado);

    // console.log('beneficio selecionado', beneficioSelecionado.qualificacaoDetalhada);

    this.calculeiApiService.apiBlobLx("ListarPDF",this.calculoBeneficios)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO--RMI-'+ beneficioSelecionado.qualificacaoDetalhada + '-' + this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.configService.setAguarde(false);

      });
  }

  showBeneficioCanvas(beneficio:Beneficio){
    this.beneficioCanvas = beneficio;
    // console.log('show beneficio canvas', beneficio);
    // console.log('tempo valido', beneficio.relacoesPrevidenciariasTempoValido)

  }
}
