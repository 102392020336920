


<div class="row py-4">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">
            Contribuições
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#help" aria-expanded="false" aria-controls="help">

            </i>
            <div class="collapse" id="help">
                <p class=" lh-1 py-2">
                    Painel de contribuições da sequência
                </p>

            </div>
        </h5>
    </div>
</div>

<div class="row flex d-flex justify-content-between">

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
            <a class="text-default" aria-expanded="false" data-bs-toggle="collapse" href="#controleNovaContribuicao" role="button" container="body" ngbTooltip="Nova Contribuição" triggers="hover">
                <a class id="controleNovaContribuicao"></a>
                <i class="comando bi bi-plus-square-dotted fs-5"></i>
                <i class="comando bi bi-plus-square-fill fs-5"></i>
            </a>
        </div>

    </div>

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
            <a class="text-default " role="button"  container="body" ngbTooltip="Exportar Excel">
                <i class="comando bi bi-filetype-xls fs-5 "></i>
            </a>
        </div>

        <div class="col-auto p-0 m-0">
            <a class="text-default " role="button" container="body" ngbTooltip="Exportar CSV">
                <i class="comando bi bi-filetype-csv fs-5 "></i>
            </a>
        </div>
    </div>

</div>
 <!-- nova contribuicao -->
 <div class="collapse m-2" id="controleNovaContribuicao">
    <div class="row align-items-center">

        <div class="col-2">
            <div class="mb-3">
                <label for="inputcompetencia" class="form-label">Competência</label>
                <input mask="00/0000" placeholder="MM/AAAA" type="text" class="form-control" id="inputCompetencia" aria-describedby="inputcompetencia">
                <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
            </div>
        </div>

        <div class="col-2">

            <div class="mb-3">
                <label for="inputvalor" class="form-label">Salário de Contribuição</label>
                <input [inputMask]="currencyInputMask" placeholder="0,00" type="text" class="form-control" id="inputValor">
            </div>

        </div>
        <div class="col-2  pt-3 ">

            <button class="btn btn-default" (click)="novaContribuicao(sequencia, 'inputCompetencia', 'inputValor', 'novaContribuicao')">Criar nova contribuição</button>

        </div>
    </div>

</div>
<div class="row py-2">
    <div class="col-12">

        <div class="table-responsive">
            <table class="table table-bordered table-sm  table-striped  table-hover align-middle overflow-auto">
                <thead>

                    <tr>
                        <th scope="col">
                            <span>
                                
                            </span>
                        </th>
                        <th scope="col">
                            <span>
                                Competência
                            </span>
                        </th>
                        <th scope="col">
                            <span>
                                Salário de Contribuição
                            </span>
                        </th>

                        <th scope="col" *ngIf="sequencia.tipoVinculo === tiposVinculosType.SOCIOEMPRESARIO || sequencia.tipoVinculo === tiposVinculosType.FACULTATIVO">
                            <span>
                                Data de Pagamento
                            </span>
                        </th>

                        

                        <th scope="col" *ngIf="sequencia.tipoVinculo === tiposVinculosType.SOCIOEMPRESARIO || sequencia.tipoVinculo === tiposVinculosType.FACULTATIVO">
                            Carência
                        </th>
                        <th scope="col">
                            <span>
                                Indicadores
                            </span>
                        </th>
                        <th scope="col">
                            <span>
                                Pontos de Atenção
                            </span>
                        </th>

                        <th scope="col">
                            <span>
                                Opções
                            </span>
                        </th>

                      

                    </tr>

                </thead>
                <tbody>
                    <ng-container class *ngFor="let contribuicao of sequencia.contribuicoes; index as index">

                        <tr>
                            <!-- competencia  -->
                            <td>
                                <div>
                                    <span>{{index + 1}}</span>
                                </div>
                                
                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                            </td>
                            <!-- competencia  -->
                            <td>
                                <div style="min-width: 150px;">
                                    <ejs-datepicker [enableMask]="true" [strictMode]="true" format="MM/yyyy" (change)="changeDataInicio($event,contribuicao, index)" [showClearbutton]="false" locale="pt" [(ngModel)]="contribuicao.competencia"></ejs-datepicker>
                                </div>
                                
                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                            </td>

                            <!-- salário de contribuição -->
                            <td>
                                <!-- view -->
                                <a class="text-default" data-bs-toggle="collapse" [href]="'#editsalariocontribuicao' + contribuicao.rowKey" role="button" aria-expanded="false" [aria-controls]="'editsalariocontribuicao' +  contribuicao.rowKey">
                                    <span>
                                        {{contribuicao | moedaCompetencia}} {{contribuicao.valor | number : '1.2-2' }}
                                    </span>
                                    <span class="my-1 badge rounded-pill bg-red " *ngIf="(contribuicao.valor < contribuicao.salarioMinimoCompetencia) && (contribuicao.competencia | posteriorPlanoReal)">
                                        <i class="bi bi-arrow-down"></i> SM
                                    </span>
                                </a>
                                <!-- edit -->
                                <div class="collapse " [id]="'editsalariocontribuicao' + contribuicao.rowKey">

                                    <div class="mb-3">
                                        <label class="form-label">Nova salário de contribuição</label>
                                        <input [inputMask]="currencyInputMask" type="text" class="form-control" [id]="'inputsalariocontribuicao'+contribuicao.rowKey" aria-describedby="salarioContribuicao">

                                    </div>

                                    <div class="row">
                                        <div class="col-auto">

                                            <button class="btn btn-default btn-sm" (click)="editarSalarioContribuicao(contribuicao, 'inputsalariocontribuicao'+contribuicao.rowKey, 'editsalariocontribuicao' + contribuicao.rowKey)">Confirmar</button>
                                            <button class="btn btn-outline-default btn-sm" (click)="cancelEditarSalarioContribuicao('editsalariocontribuicao' + contribuicao.rowKey)">Cancelar</button>
                                        </div>

                                    </div>

                                </div>

                            </td>

                            <!-- data de pagamento para contribuinte individual -->
                            <td *ngIf="sequencia.tipoVinculo === tiposVinculosType.SOCIOEMPRESARIO|| sequencia.tipoVinculo === tiposVinculosType.FACULTATIVO">
                                <div style="min-width: 150px;">
                                    <ejs-datepicker [enableMask]="true" [strictMode]="true" [showClearbutton]="false" locale="pt" [(ngModel)]="contribuicao.dataPagamento"></ejs-datepicker>
                                </div>
                                
                                 <!-- {{contribuicao.dataPagamento | date : 'dd/MM/yyyy'}} -->
                            </td>


                            <!-- carencia -->
                            <td *ngIf="sequencia.tipoVinculo === tiposVinculosType.SOCIOEMPRESARIO|| sequencia.tipoVinculo === tiposVinculosType.FACULTATIVO">

                                <div class="form-check form-switch">
                                    <input (input)="switchCarenciaContribuicao($event, contribuicao)" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="contribuicao.carencia" container="body" ngbTooltip="Indica se a contribuição deve ou não entrar na contagem de carência" triggers="hover">
                                    
                                </div>
                            </td>


                            <!-- indicadores -->
                            <td>
                                <ng-container *ngFor="let indicador of contribuicao.indicadoresCnis">
                                    <span class=" badge rounded-pill mx-1" [ngClass]="{'bg-red': indicador.tipo === tiposIndicadoresCnis.CSPENDENCIA, 'bg-yellow text-default': indicador.tipo === tiposIndicadoresCnis.CSINDICADOR, 'bg-green': indicador.tipo === tiposIndicadoresCnis.CSACERTO}">
                                        {{indicador.sigla}}
                                    </span>
                                </ng-container>

                                <ng-container *ngIf="contribuicao.indicadoresCnis && contribuicao.indicadoresCnis.length <=0">
                                    <span class=" badge rounded-pill text-default">
                                        -
                                    </span>
                                </ng-container>
                            </td>

                            <!-- pontos de atenção -->
                            <td>
                                <div *ngIf="contribuicao.pontosAtencaoCNIS && contribuicao.pontosAtencaoCNIS.length > 0" class="col-12">

                                    <ng-container *ngFor="let ponto of contribuicao.pontosAtencaoCNIS">
                                        <span class="badge rounded-pill  mx-1" [ngClass]="{'bg-red': ponto.severidade === severidadePontoAtencaoCnisType.URGENTE, 'bg-yellow text-default': ponto.severidade === severidadePontoAtencaoCnisType.MODERADO, 'bg-yellow text-default': ponto.severidade === severidadePontoAtencaoCnisType.BAIXORISCO}">
                                            <i class="bi bi-stoplights"></i> {{ponto.origem}}
                                        </span>

                                    </ng-container>

                                </div>
                            </td>


                            <!-- opcoes -->
                            <td>
                                <i class="bi bi-trash3" role="button" (click)="excluirContribuicao(sequencia, contribuicao)" container="body" ngbTooltip="Excluir contribuição do cálculo" triggers="hover"></i>

                            </td>

                        </tr>

                    </ng-container>

                </tbody>
            </table>

        </div>

    </div>
</div>