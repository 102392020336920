import {Pipe, PipeTransform} from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';

@Pipe({name:'statusBeneficio'})
export class StatusBeneficioPipe implements PipeTransform{

    transform(value: any, ...args: any[]): string {
        return Beneficio.statusBeneficioToString(value);
    }

}