import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CivelRoutingModule } from './civel-routing.module';
import { CivelComponent } from '../civel/civel.component';
import { LiquidacaoSentencaCivelComponent } from './liquidacao-sentenca-civel/liquidacao-sentenca-civel.component';
import { CompartilhadoModule } from './compartilhado/compartilhado.module';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { CompartilhadoModule as CompartilhadoAplicacaoModule } from "../../compartilhado/compartilhado.module";


@NgModule({
  declarations: [
    CivelComponent,
    LiquidacaoSentencaCivelComponent
  ],
  imports: [
    CommonModule,
    CivelRoutingModule,
    CompartilhadoModule,
    TabsModule.forRoot(),
    CompartilhadoAplicacaoModule
  ],
  providers:[TabsetConfig],
})
export class CivelModule { }
