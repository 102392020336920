import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrevidenciarioRoutingModule } from './previdenciario-routing.module';
import { AposentadoriaIdadeComponent } from './aposentadoria-idade/aposentadoria-idade.component';
import { AposentadoriaTempoContribuicaoComponent } from './aposentadoria-tempo-contribuicao/aposentadoria-tempo-contribuicao.component';
import { CompartilhadoModule } from './compartilhado/compartilhado.module';
import { PrevidenciarioComponent } from './previdenciario.component';
import { RelatorioTempoContribuicaoComponent } from './relatorio-tempo-contribuicao/relatorio-tempo-contribuicao.component';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { SumarioCalculoBeneficiosComponent } from './sumario-calculo-beneficios/sumario-calculo-beneficios.component';
import { LiquidacaoSentencaComponent } from './liquidacao-sentenca/liquidacao-sentenca.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from '@ngneat/input-mask';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { CompartilhadoModule as CompartilhadoAplicacaoModule } from "../../compartilhado/compartilhado.module";
import { ValorCausaComponent } from './valor-causa/valor-causa.component';

import { RevisaoTempoServicoComponent } from './revisao-tempo-servico/revisao-tempo-servico.component';
import { PlanejamentoComponent } from './planejamento/planejamento.component';
import { PlanejamentoKertzmanComponent } from './planejamento-kertzman/planejamento-kertzman.component';
import { EdicaoCNISComponent } from './edicao-cnis/edicao-cnis.component';

import { QualidadeSeguradoComponent } from './qualidade-segurado/qualidade-segurado.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PreparacaoCnisComponent } from './preparacao-cnis/preparacao-cnis.component';
import { CompartilhadoDashboardModule } from '../../dashboard/compartilhado-dashboard/compartilhado-dashboard.module';
import { AuxilioAcidenteComponent } from './auxilio-acidente/auxilio-acidente.component';
import { IncapacidadeTemporariaComponent } from './incapacidade-temporaria/incapacidade-temporaria.component';
import { MatrizComponent } from './matriz/matriz.component';
import { InvalidezComponent } from './invalidez/invalidez.component';





@NgModule({
  declarations: [
    PrevidenciarioComponent,
    AposentadoriaIdadeComponent,
    AposentadoriaTempoContribuicaoComponent,
    RelatorioTempoContribuicaoComponent,
    BeneficiosComponent,
    SumarioCalculoBeneficiosComponent,
    LiquidacaoSentencaComponent,
    ValorCausaComponent,
    
    RevisaoTempoServicoComponent,
    PlanejamentoComponent,
    PlanejamentoKertzmanComponent,
    EdicaoCNISComponent,
    QualidadeSeguradoComponent,
    PreparacaoCnisComponent,
    AuxilioAcidenteComponent,
    IncapacidadeTemporariaComponent,
    MatrizComponent,
    InvalidezComponent,
    
    
  ],
  imports: [
    CommonModule,
    PrevidenciarioRoutingModule,
    CompartilhadoModule,
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    InputMaskModule.forRoot({inputSelector: 'input', isAsync: true}),
    TabsModule.forRoot(),
    CompartilhadoAplicacaoModule,
    
    NgbTooltipModule,
    CompartilhadoDashboardModule,
    
    
    
    
    
    
    
  ],
  providers:[
    TabsetConfig
  ]
})
export class PrevidenciarioModule { }
