import { Base } from "../base";
import { TiposMarcador } from "./tiposmarcador";

export class Marcador extends Base{

    nome:string;
    descricao:string;
    cor:string;
    link:string;
    tipoMarcador:TiposMarcador;

}