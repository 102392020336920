import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { CalculoRevisaoTempoServico } from 'src/app/modelos/previdenciario/calculorevisaotemposervico';
import { ExtratoContribuicaoPrisma } from 'src/app/modelos/previdenciario/extratocontribuicaoprisma';
import { ListarRelatorioRevisaoTempoServicoService } from 'src/app/servicos/calculo/previdenciario/listar-relatorio-revisao-tempo-servico.service';

@Component({
  selector: 'app-revisao-tempo-servico',
  templateUrl: './revisao-tempo-servico.component.html',
  styleUrls: ['./revisao-tempo-servico.component.scss']
})
export class RevisaoTempoServicoComponent implements OnInit  {

  @ViewChild('tabsRevisaoTempoServico', {static: false}) tabs?: TabsetComponent;

  calculoRevisaoTempoServico:CalculoRevisaoTempoServico;
  aguardeVisivel:boolean;
  //[className]="relatorioVisivel ? 'visible' : 'invisible'"
  relatorioVisivel:boolean;
  titulo:string = "Nova Revisão do Extrato de Tempo de Contribuição";
  mock:boolean;

  constructor(private listarRelatorioRevisaoTempoServico:ListarRelatorioRevisaoTempoServicoService){
  }

  ngOnInit(): void {

    this.mock = false;
    if (this.mock) {
      this.mockRelatorio();  
    }else{
      this.iniciar();
    }
    
  }

  iniciar(){
    this.calculoRevisaoTempoServico = new CalculoRevisaoTempoServico();
  }

  mockRelatorio(){
    this.fetchCalculo(calculo=>{
      this.calculoRevisaoTempoServico = calculo as CalculoRevisaoTempoServico;
      this.selectTab(1);
    });
    

  }

  processandoImportacaoExtratoPrisma(processando:boolean){
    this.aguardeVisivel = processando;
  }
  importacaoExtratoPrisma(calculo:CalculoRevisaoTempoServico){

    this.calculoRevisaoTempoServico = calculo;
    this.listarRelatorioRevisaoTempoServico.relatorio(this.calculoRevisaoTempoServico)
    .then(calculo=>{

      this.calculoRevisaoTempoServico = calculo as CalculoRevisaoTempoServico;

      this.selectTab(1);
      this.relatorioVisivel = true;
      

    });

  }

  selectTab(tab:number){
    if(this.tabs?.tabs[tab]){
      this.tabs.tabs[tab].active = true;
    }
  }

  fetchCalculo(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/data/calculorevisaotemposervico.json`);

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }

  

}
