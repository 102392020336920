import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CriarPlanoRequest } from 'src/app/modelos/safe2pay/criarplanorequest';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListarPlanosCriadosService {

  constructor(private httpClient:HttpClient) { }

  listarPlanos(){

    return Promise.all([this.apicall()]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ListarPlanosCriados',{}, {'headers':headers}).toPromise();
    return data;
  }
}