


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ImportacaoFatura } from 'src/app/modelos/faturas/importacaofatura';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { ImportacaoCNIS } from 'src/app/modelos/previdenciario/importacaocnis';
import { RequestCalculoBeneficios } from 'src/app/modelos/previdenciario/requestcalculobeneficios';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class ListarGruposSumarioCalculoBeneficiosService {

  constructor(private httpClient: HttpClient) { }

  listarGruposSumarioCalculoBeneficios(request:CalculoBeneficios){
    return Promise.all([this.apiCall(request)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(request:CalculoBeneficios){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ListarGruposSumarioCalculoBeneficios',request, {'headers':headers}).toPromise();
    // const response = data;
    // const dataSalvar = this.httpClient.post(environment.api as string + 'api/SalvarGruposSumarioCalculoBeneficios',response, {'headers':headers}).toPromise();
    return data;
  }
}
