import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigService } from 'src/app/servicos/ui/config.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-novo-calculo',
  templateUrl: './novo-calculo.component.html',
  styleUrls: ['./novo-calculo.component.scss']
})
export class NovoCalculoComponent implements OnInit {

  periodoTeste: boolean;
  diasRestantes: number;
  assinaturaValida: boolean;

  constructor(private router: Router, private configService: ConfigService) {

    // this.configService.getDuracaoDiasTesteRestante().subscribe(diasRestantes => {
    //   if (diasRestantes > 0) {
        
    //   } else {

    //     this.configService.getAssinaturaAtiva().subscribe(ativa => {
          

    //       if (!ativa) {

    //         Swal.fire({
    //           title: 'O seu período de teste chegou ao fim',
    //           text: 'Olá. O calculei está disponível em duas formas de pagamento anual e mensal. A única diferença é que o anual é melhor para quem gosta de bastante desconto. Obrigado por continuar com o calculei. Se você precisar de mais tempo de teste deixe um recado.',
    //           icon: 'info',
    //           showCloseButton: true,
    //           showConfirmButton: true,
    //           showCancelButton: true,
    //           cancelButtonText: 'vou pensar, quero mais tempo para testar',
    //           backdrop:false,
    //           allowEscapeKey:false,

    //           confirmButtonText: 'ok, assinar plano calculei',
    //           confirmButtonColor: '#172b4d'

    //         }).then(value=>{

    //           if (value.isConfirmed) {
    //             this.router.navigateByUrl('/app/usuario/planos');
    //           }
    //           if(value.dismiss == Swal.DismissReason.cancel ){

    //             Swal.fire({
    //               title: 'Ok, período de teste extendido',
    //               text: 'Qualquer dúvida ou sugestão use nossos canais de atendimento. Será um prazer atender-lhe',
    //               icon: 'success',
    //               showCloseButton: true,
    //               showConfirmButton: true,
    
    //               confirmButtonText: 'ok, até breve',
    //               confirmButtonColor: '#172b4d'
    
    //             })

    //           }
              
    //         });
            

    //       }
    //     });

    //   }


    // });

    
  }



  ngOnInit(): void {
    // Swal.fire({
    //   title: 'Estamos em obra...',
    //   text: 'Iniciamos mais uma rodada de homologação dos resultados. O Calculei ficará novamente disponível as 21:00h desta quarta-feira 28 de junho. Obrigado pela compreensão',
    //   icon: 'info',
    //   showCloseButton: true,
    //   showConfirmButton: true,
    //   confirmButtonText: 'ok',
    //   confirmButtonColor: '#172b4d'

    // }).then(() => {});
  }

}
