<div *ngIf="relatorio && relatorio.otimizacao">






<div class="row">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">Planejamento Contributivo</h5>
    </div>
</div>


<div *ngIf="visualizarPdf" class="row py-2  flex d-flex justify-content-between">

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
            
            <!-- <i class="comando bi bi-stopwatch fs-5 " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasTempoContribuicao" aria-controls="canvasTempoContribuicao"  container="body" ngbTooltip="Contagem de Tempo de Contribuição" triggers="hover"></i> -->
            <!-- <i class="comando bi bi-stoplights fs-5 " role="button" data-bs-toggle="offcanvas" data-bs-target="#canvasPontosAtencao" aria-controls="canvasPontosAtencao"  container="body" ngbTooltip="Pendências" triggers="hover"></i> -->
        </div>

    </div>

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
            <!-- <i class="comando bi bi-filetype-xls fs-5 " role="button" (click)="exportarXls()" container="body" ngbTooltip="Exportar Excel" triggers="hover"></i> -->
        </div>

        <div class="col-auto p-0 m-0">
            <i class="comando bi bi-filetype-pdf fs-5 " role="button"  container="body" (click)="gerarPdf()" ngbTooltip="Salvar PDF" triggers="hover"></i>
        </div>
    </div>

</div>



<div class="row py-4 text-default">
    <div class="col-12">
        










        <div class="row justify-content-end ">

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalSubstituicoesTeto}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Contribuições opcionais previstas para otimização do benefício
                </span>


            </div>

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorContribuicaoComplementarMensal | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor mensal da contribuição de otimização
                </span>


            </div>
            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalInvestimentoSubstituicoes | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Custo total das contribuições opcionais para otimização do benefício
                </span>


            </div>




            <!-- <div *ngIf="relatorio.opcoesPlanejamento.impostoRenda.investimento" class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.custoContributivoTotalImpostoRenda / relatorio.otimizacao.totalSubstituicoesTeto| currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor mensal em Imposto de Renda depois das deduções
                </span>


            </div> -->


            <!-- <div *ngIf="relatorio.opcoesPlanejamento.impostoRenda.investimento" class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.custoContributivoTotalImpostoRenda | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Custo total em Imposto de Renda
                </span>


            </div> -->

            



            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalInvestimento | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Total do Investimento
                </span>


            </div>



        </div>


        <div class="row justify-content-end">

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.taxaRetornoPlanejamentoContributivo * 100 | number : '1.2-2'}} % a. m.

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Taxa de Retorno do Investimento de Otimização
                </span>


            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste-ouro">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorBeneficio | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor do benefício otimizado (sem abono anual)
                </span>


            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste-ouro">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorBeneficioAbonoAnual | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor do benefício otimizado (com abono anual)
                </span>


            </div>


            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorBeneficioCenarioBase| currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor do benefício sem otimização (pré-análise)
                </span>


            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.ganhoBeneficio | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Ganho no valor do benefício com as contribuições opcionais de otimização incluindo abono anual
                </span>


            </div>
            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.lucroPlanejamentoContributivo | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Ganho no lucro com as contribuições opcionais de otimização incluindo abono anual
                </span>


            </div>

            

            


            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.dataAposentadoria | date : 'dd/MM/yyyy'}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Data prevista para a aposentadoria 
                </span>


            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.tempoRetornoInvestimentoMesesAbono | number : '1.0-0'}} meses

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Payback do investimento nas contribuições opcionais de otimização
                </span>


            </div>



        </div>











        <div *ngIf="relatorio.otimizacao.totalContribuicoesPlanejadas > 0" class="row justify-content-end">

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalContribuicoesPlanejadas}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Contribuições previstas para cumprimento de carência
                </span>


            </div>

            <div  class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorContribuicaoComplementarMensal | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor mensal da contribuição para cumprimento de carência
                </span>


            </div>
            
            <div  class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalInvestimentoPlanejamento | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Custo total das contribuições previstas para cumprimento de Carência
                </span>


            </div>



        </div>








        <div *ngIf="relatorio.otimizacao.totalContributivoQualidadeSeguradoMeses > 0" class="row justify-content-end">

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalContributivoQualidadeSeguradoMeses | number : '1.0-0'}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Contribuições previstas para manutenção da Qualidade de Segurado
                </span>


            </div>

            <div  class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorContribuicaoQualidadeSegurado | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor da contribuição para cumprimento de manutenção da qualidade de segurado
                </span>


            </div>
            <div  class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalInvestimentoQualidadeSegurado | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Custo total das contribuições para manutenção da qualidade de segurado
                </span>


            </div>



        </div>


    </div>

</div>

</div>