

import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';

import { CapitalizacaoMensal } from 'src/app/modelos/previdenciario/capitalizacaomensal';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { RelatorioPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioplanejamentoprevidenciario';
import { FasesPlanejamento } from 'src/app/modelos/ui/fasesplanejamento';
import { FasesPlanejamentoKertzman } from 'src/app/modelos/ui/fasesplanejamentokertzman';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { ListarCalculoChaveService } from 'src/app/servicos/calculo/common/listar-calculo-chave.service';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ListarTabelaCapitalizacaoSaqueService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao-saque.service';
import { ListarTabelaCapitalizacaoService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao.service';
import { LeituraJsonBlobService } from 'src/app/servicos/relatorio/leitura-json-blob.service';
import { ListarRelatorioPlanejamentoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-planejamento-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';


@Component({
  selector: 'app-relatorio-variacao-contribuicoes-originai-simulacao',
  templateUrl: './relatorio-variacao-contribuicoes-originai-simulacao.component.html',
  styleUrls: ['./relatorio-variacao-contribuicoes-originai-simulacao.component.scss']
})
export class RelatorioVariacaoContribuicoesOriginaiSimulacaoComponent  implements OnInit {

  constructor(private location: Location, private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private leituraJsonBlobService:LeituraJsonBlobService,
    private listarTabelaCapitalizacaoSaqueService: ListarTabelaCapitalizacaoSaqueService,
    private listarTabelaCapitalizacaoService: ListarTabelaCapitalizacaoService,
    private route: ActivatedRoute,
    private listatCalculoChaveService: ListarCalculoChaveService,
    private listarCalculoResultadoService: ListarCalculoResultadoService) { }




  simulacoes:CasoPlanejamentoPrevidenciario[];
  calculoPlanejamentoKertzman:CalculoPlanejamentoKertzman;

  caso: CasoPlanejamentoPrevidenciario;

  logoUrl: string;


  linhasCapitalizacaoMensal: CapitalizacaoMensal[];
  linhasCapitalizacaoMensalSaque: CapitalizacaoMensal[];

  calculoRowKey: string;
  calculoPartitionKey: string;
  calculoUrl: string;

  resultadoCalculo: ResultadoCalculo;

  
  

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {


      this.calculoUrl = params.url;
      this.calculoRowKey = params.rowkey;
      this.calculoPartitionKey = params.partitionkey;

      let resultadoCalculo: ResultadoCalculo = new ResultadoCalculo();

      resultadoCalculo.rowKey = this.calculoRowKey;
      resultadoCalculo.partitionKey = this.calculoPartitionKey;


      let calculo: Calculo = new Calculo();
      calculo.rowKey = this.calculoRowKey;
      calculo.partitionKey = this.calculoPartitionKey;
      


      this.leituraJsonBlobService.ListarJsonBlob(this.calculoUrl, request =>{

        // let casoResultado:CalculoPlanejamentoKertzman = request as CalculoPlanejamentoKertzman;
        // console.log(casoResultado);
        this.carregarDados(request as CasoPlanejamentoPrevidenciario);
      });
      


      // this.listatCalculoChaveService.listarCalculo(calculo)
      //   .then(calculo => {

      //     console.log(calculo);
      //     this.carregarDados(calculo as CasoPlanejamentoPrevidenciario);



      //   });
    });


    
  }

  carregarDados(request:CasoPlanejamentoPrevidenciario) {


    this.caso = request;


    // this.linhasCapitalizacaoMensal = CapitalizacaoMensal.tabelaCapitalizacao(this.caso);
    // this.listarTabelaCapitalizacaoService.listarTabelaCapitalizacao(this.caso)
    //   .then(tabela => {
    //     this.linhasCapitalizacaoMensal = tabela as CapitalizacaoMensal[];
    //   });

    // this.linhasCapitalizacaoMensalSaque = CapitalizacaoMensal.tabelaCapitalizacaoSaque(this.caso);

    // this.listarTabelaCapitalizacaoSaqueService.listarTabelaCapizacaoMensalSaque(this.caso)
    //   .then(tabela => {
    //     this.linhasCapitalizacaoMensalSaque = tabela as CapitalizacaoMensal[];
    //   });





    // if (this.resultadoCalculo.usuario.logoUrl != null) {
    //   this.logoUrl = this.resultadoCalculo.usuario.logoUrl;
    // }else{
    //   this.logoUrl = "../../../assets/img/brand/android-chrome-512x512.png";
    // }

    // this.logoUrl = "../../../assets/img/brand/android-chrome-512x512.png";
  }


}
