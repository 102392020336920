import { Component } from '@angular/core';

@Component({
  selector: 'app-entrevistas',
  templateUrl: './entrevistas.component.html',
  styleUrl: './entrevistas.component.scss'
})
export class EntrevistasComponent {

}
