<div *ngIf="entrevista && entrevista.cliente">
    <div class="container vh-100 align-items-center flex d-flex">
        <div class="row flex d-flex">
            <div class="">
                <span class="display-1 ">Olá, </span>
                <span class="display-1">{{entrevista.cliente.nome}}</span>
                <span class="display-3 d-block">{{entrevista.mensagemInicial}}</span>
            </div>
        </div>
    </div>
</div>

