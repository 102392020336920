import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import * as currency from 'currency.js';
import { AppComponent } from 'src/app/app.component';
import { Global } from 'src/app/modelos/global';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { HonorariosLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/honorariosliquidacaosentencaprevidenciario';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import { ReferenciaLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/referencialiquidacaosentencaprevidenciario';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { ListarClienteService } from 'src/app/servicos/usuario/listar-cliente.service';

@Component({
  selector: 'app-honorarios-liquidacao-sentenca-previdenciario',
  templateUrl: './honorarios-liquidacao-sentenca-previdenciario.component.html',
  styleUrls: ['./honorarios-liquidacao-sentenca-previdenciario.component.scss']
})
export class HonorariosLiquidacaoSentencaPrevidenciarioComponent implements OnInit {

  @Input() calculoLiquidacaoSentencaPrevidenciario: CalculoLiquidacaoSentencaPrevidenciario;

  tiposProcessoType = TiposProcessoLiquidacaoSentencaPrevidenciario;

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  jurosInputMask = createMask(AppComponent.jurosInputOptions);

  honorariosForm = new UntypedFormGroup({

    valorPercentual: new UntypedFormControl(''),
    dataLimite: new UntypedFormControl(''),
  });


  isCollapsed:boolean=true;

  constructor() { }


  ngOnInit(): void {

  }

  carregarDados(calculo:CalculoLiquidacaoSentencaPrevidenciario){
    // this.calculoLiquidacaoSentencaPrevidenciario = calculo;

    // if (this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios) {
    //   this.honorariosForm.get('valorPercentual').setValue(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.valorPercentualSucumbencia);
    //   if (this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.dataLimite) {
    //     this.honorariosForm.get('dataLimite').setValue(new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.dataLimite));
    //   }
      

    // }

  }
  continuar() {
    // let honorarios = new HonorariosLiquidacaoSentencaPrevidenciario();


    // if (!(this.honorariosForm.get('valorPercentual').value === '')) {
    //   honorarios.valorPercentualSucumbencia = currency(this.honorariosForm.get('valorPercentual').value, { separator: '.', decimal: ',' }).value;
    //   honorarios.dataLimite = this.honorariosForm.get('dataLimite').value != '' ? new Date(this.honorariosForm.get('dataLimite').value) : new Date(Date.now());
    //   this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorariosInclusos = true;
    // } else {
    //   this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorariosInclusos = false;
    // }




    // this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios = honorarios;
    // // console.log('honorarios continuar');
    // // console.log(this.calculoLiquidacaoSentencaPrevidenciario);
    

  }

}
