import { Pipe, PipeTransform } from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { TipoAtividade } from 'src/app/modelos/previdenciario/tipoatividade';

@Pipe({ name: 'tipoAtividade' })
export class TipoAtividadePipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {

        let tipo: TipoAtividade = value as TipoAtividade;
        let texto: string = '';

        switch (tipo) {
            case TipoAtividade.Normal:

                texto = 'Normal';
                break;
            case TipoAtividade.Especial15:
                texto = 'Especial 15';
                break;
            case TipoAtividade.Especial20:
                texto = 'Especial 20';
                break;
            case TipoAtividade.Especial25:
                texto = 'Especial 25';
                break;
            case TipoAtividade.Professor:
                texto = 'Professor';
                break;
            case TipoAtividade.Rural:
                texto = 'Rural';
                break;


            default:
                break;
        }

        // console.log('transform atividade');
        // console.log(tipo);
        // console.log(texto);
        return texto;

    }

}