<h5 class=" h3 mb-0 linha-quadro">Meus Vouchers</h5>

<div class="row flex d-flex m-2 py-4">
  <div *ngFor="let voucher of listaVouchers" class="col-3 py-3">
    <div class="col-12 h-100 p-2 m-2 rounded bg-secondary ">
        <p class="h2">
            {{voucher.nome}}
        </p>

        <p class="h6">
            {{voucher.dataValidade | date : 'dd/MM/yyyy'}}
        </p>

        <div class="align-self-end p-0 m-0">
            <p class="h1 text-right ">
                {{voucher.codigo}}
            </p>
            <p class="h1 text-right">
                {{voucher.desconto}} %
            </p>

            <i class="bi bi-trash" role="button" (click)="excluirVoucher(voucher)"></i>
    
        </div>
    </div>
    
  </div>
  
</div>