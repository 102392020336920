import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';


import { MeuAvatarComponent } from './meu-avatar/meu-avatar.component';
import { MinhaLogoComponent } from './minha-logo/minha-logo.component';
import { OpcoesFiliadoComponent } from './opcoes-filiado/opcoes-filiado.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { GeradorVoucherComponent } from './gerador-voucher/gerador-voucher.component';
import { ListagemVoucherComponent } from './listagem-voucher/listagem-voucher.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    
    MeuAvatarComponent,
    MinhaLogoComponent,
    OpcoesFiliadoComponent,
    GeradorVoucherComponent,
    ListagemVoucherComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    InputMaskModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule.forRoot(),
    RouterModule
    
  ],
  exports:[
    
    MeuAvatarComponent,
    MinhaLogoComponent,
    OpcoesFiliadoComponent,
    GeradorVoucherComponent,
    ListagemVoucherComponent
  ]
})
export class CompartilhadoModule { }
