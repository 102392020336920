

<div *ngIf="calculoMatrizPlanejamento && calculoMatrizPlanejamento.matrizCustoOportunidade">

    <div class="row ">
        <div class="col">
            <h5 class=" h3 mb-0 linha-quadro">
                Custo de Oportunidade
                <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpCustoOportunidade" aria-expanded="false" aria-controls="helpCustoOportunidade"></i>
            </h5>

            <div class="collapse" id="helpCustoOportunidade">
                <div class="row d-flex ">
                    <div class="col">
                        <p>
                            Compare 2 cenários ou mais.
                        </p>
                    </div>

                </div>

                <div class="rowd-flex ">

                    <div class="col-3 py-2 d-flex">
                        <div class="row bg-secondary rounded m-2 w-100">
                            <div class="col d-flex p-2">

                                <p class=" p-2 lh-1 text-justify">
                                    Arraste os cenários que deseja comparar e veja na tabela a diferença entre escolher um e renunciar o outro. Para mais detalhes da comparação, clique no valor da diferença na tabela.

                                </p>

                            </div>
                            <div class="mt-auto pb-2 text-right">
                                <i class="comando bi bi-window-split fs-3"></i>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>

    <!-- <div class="row py-2  flex d-flex justify-content-between">

        <div class="col-auto flex d-flex">
            <div class="col-auto p-0 m-0">
                <a class="text-default" data-bs-toggle="collapse" data-bs-target="#areaFiltros" aria-expanded="false" aria-controls="areaFiltros" role="button">
                    <i class="comando bi bi-funnel fs-5"></i>
                    <i class="comando bi bi-funnel-fill fs-5 "></i>

                </a>
            </div>

        </div>

        <div class="col-auto flex d-flex">
            <div class="col-auto p-0 m-0">
                <a class="text-default " role="button" container="body" ngbTooltip="Baixar PDF da matriz de custo de oportunidade">
                    <i class="comando bi bi-filetype-pdf fs-5 "></i>
                </a>
            </div>

            <div class="col-auto p-0 m-0">
                <a class="text-default " role="button" container="body" ngbTooltip="Exportar matriz em excel">
                    <i class="comando bi bi-filetype-xls fs-5 "></i>
                </a>
            </div>
        </div>

    </div> -->

    <div class="row flex py-4">
        <div class="col-2">
            <!-- <p>Arraste o cenário para comparação</p> -->
            <ejs-listbox #controleListaDisponiveis [dataSource]="listaCenariosDisponiveis" [allowDragAndDrop]="true" [fields]="setfield" scope="combined-list" (drop)="dropDisponiveis($event)"></ejs-listbox>
        </div>

        <div class="col-2">
            <p>Arraste aqui os cenários para comparação</p>
            <ejs-listbox [dataSource]="listaCenariosSelecionados" [allowDragAndDrop]="true" [fields]="setfield" scope="combined-list" (drop)="dropSelecionados($event)"></ejs-listbox>
        </div>

        <div class="col-8">

            <p>Matriz de custo de oportunidade <br>entre os cenários selecionados</p>
            <div class="row">
                <div class="col">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm  table-striped  table-hover align-middle overflow-auto">
                            <thead>

                                <tr>
                                    <td class="">
                                        <span>
                                            Cenários Comparativos (escolhido) <i class="bi bi-arrow-right"></i><br>Cenários Base (renunciado)<i class="bi bi-arrow-down"></i>
                                        </span>
                                    </td>

                                    <ng-container *ngFor="let lista of calculoMatrizPlanejamento.matrizCustoOportunidade; index as custoIndex;">
                                        <ng-container *ngIf="lista[custoIndex].filtrarMatriz">
                                            <td class="">

                                                <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                                                    {{lista[custoIndex].referenciaBase}}
                                                </span>
                                            </td>
                                        </ng-container>
                                    </ng-container>

                                </tr>

                            </thead>
                            <tbody>

                                <ng-container *ngFor="let lista of calculoMatrizPlanejamento.matrizCustoOportunidade; index as custoIndex;">
                                    <ng-container *ngIf="lista[custoIndex].filtrarMatriz">
                                        <tr>
                                            <th class="">
                                                <span>
                                                    {{lista[custoIndex].referenciaBase}}
                                                </span>
                                            </th>

                                            <ng-container *ngFor="let custo of lista; index as i;">
                                                <ng-container *ngIf="custo.filtrarMatriz">
                                                    <td>
                                                        <span *ngIf="custo.diferencaRoi!=0" role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {{custo.diferencaRoi | currency}}
                                                        </span>

                                                        <div class="dropdown">
                                                            <div class="dropdown-menu">
                                                                <div class="container">

                                                                    <div class="row py-1">
                                                                        <div class="col">
                                                                            <p class="h3">
                                                                                Comparativo de custo de oportunidade
                                                                            </p>

                                                                            <p class="h4">
                                                                                Escolha do {{custo.referenciaComparativa}} e renúncia do {{custo.referenciaBase}}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row py-1">
                                                                        <div class="col">
                                                                            <p class="h4">
                                                                                Análise
                                                                            </p>

                                                                            <p class="h5">
                                                                                Meses de espera até o {{custo.referenciaComparativa}}: {{custo.mesesDiferenca}}
                                                                            </p>

                                                                            <p class="h5">
                                                                                Diferença no valor do benefício: {{custo.diferencaValorBeneficioCasoComparativo | currency}}
                                                                            </p>

                                                                            <p class="h5">
                                                                                Payback do custo da renúncia do {{custo.referenciaBase}}: {{custo.paybackCustoOportunidadeCasoComparativo | number : '1.0-0'}} meses
                                                                            </p>

                                                                            <p class="h5">
                                                                                Diferença no ROI acumulado: {{custo.diferencaRoi | currency}}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="py-1">

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </td>
                                                </ng-container>

                                            </ng-container>

                                        </tr>
                                    </ng-container>

                                    <!-- <ng-container *ngFor="let custo of lista; index as i;">
            
                                    </ng-container> -->

                                </ng-container>

                            </tbody>
                        </table>

                    </div>

                </div>
            </div>

        </div>
    </div>

</div>

<!-- <app-destaque-beneficio *ngIf="destaque" [destaque]="destaque"
        (relatorioCompletoEvent)="verRelatorioCompleto($event)"></app-destaque-beneficio> -->
