import { Component, Input } from '@angular/core';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-validacao-tempo-contribuicao-carencia',
  templateUrl: './validacao-tempo-contribuicao-carencia.component.html',
  styleUrl: './validacao-tempo-contribuicao-carencia.component.scss'
})
export class ValidacaoTempoContribuicaoCarenciaComponent {

  @Input() calculoBeneficios:CalculoBeneficios;

  constructor(){}

  ativarSequencia(contribuicao:Contribuicao,libero:boolean){

    contribuicao.libero = libero;
    LogService.log('libero', contribuicao.libero);

  }

}
