import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { StatusBeneficio } from 'src/app/modelos/previdenciario/statusbeneficio';

@Component({
  selector: 'app-caso-planejamento-preview',
  templateUrl: './caso-planejamento-preview.component.html',
  styleUrls: ['./caso-planejamento-preview.component.scss']
})
export class CasoPlanejamentoPreviewComponent implements OnInit {

  @Input() caso:CasoPlanejamentoPrevidenciario;
  @Input() visaoRelatorio:boolean;
  @Input() visualizarDetalhe:boolean =true;
  @Output() casoSelecionadoEvent = new EventEmitter<CasoPlanejamentoPrevidenciario>();

  statusBeneficioType = StatusBeneficio;

  ngOnInit(): void {
    
  }

  
  
  verCaso(){
   
    this.casoSelecionadoEvent.emit(this.caso);
  }

}
