import { Base } from "../base";
import { Contribuicao } from "./contribuicao";
import { Periodicidade } from "./periodicidade";

import { TiposVinculos } from "./tiposvinculos";

export class Atividade extends Base{
    inicio:Date;
    fim:Date
    salario:number;
    contribuicoes:Contribuicao[];
    limitarDataFim:boolean;
    periodicidade:Periodicidade;
    tipoVinculo:TiposVinculos;
    aliquota:number;
    aliquotaAutomatica:boolean
}