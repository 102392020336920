
<div *ngIf="calculoBeneficios && calculoBeneficios.beneficios">

  <div class="py-2">
    <h5 class=" h3 mb-0 linha-quadro pb-4">Resultado</h5>

    <div class="row flex d-flex">
      <div class="col-3  flex d-flex p-0 " *ngFor="let beneficio of calculoBeneficios.beneficios; index as i;">

        <div class="row rounded  bg-secondary m-2" style="min-height: 250px;" (click)="showBeneficioCanvas(beneficio)" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">

          <div class="row p-0 m-0">
            <div class="col">
              <p class="h2">
                {{beneficio.qualificacaoDetalhada}}
              </p>
              <p class="h6">
                {{beneficio.qualificacao}}
              </p>
            </div>

          </div>

          <div class="row p-0 m-0 align-items-end text-right">

            <div class="row p-0 m-0">
              <div class="col-12 text-right">
                <p class="display-5">
                  <span class="h6 mx-0 px-0 ">RMI</span> {{beneficio.relatorioRendaMensalInicial.rendaMensalInicial| currency}}
                </p>
              </div>

              <div class="col-12 flex d-flex">

                <div class="col-4  text-center">
                  <!-- <progressbar type="green" [value]="beneficio.relacaoIdadeCarencia"> </progressbar> -->

                  <div class="progress m-0 p-0">
                    <div class="progress-bar bg-success m-0 p-0" role="progressbar" [style.width]="(beneficio.relacaoIdadeCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">

                    </div>
                  </div>

                  <div class="row p-0 m-0 ">
                    <span class="h6 mt-0 pt-0">
                      Idade {{(beneficio.relacaoIdadeCarencia | number : '1.0-0')}}%
                    </span>
                    <!-- <span *ngIf="beneficio.cumpreRequisitoIdade" class="mb-0 text-sm text-default">
                          requisito idade cumprido em {{ beneficio.dataCumprimentoIdade | date : 'dd/MM/yyyy'}}
                      </span> -->
                  </div>

                </div>

                <div class="col-4 text-center">
                  <!-- <progressbar type="green" [value]="beneficio.relacaoTempoContribuicaoCarencia">
                  </progressbar> -->

                  <div class="progress m-0 p-0">
                    <div class="progress-bar bg-success m-0 p-0" role="progressbar" [style.width]="(beneficio.relacaoTempoContribuicaoCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">

                    </div>
                  </div>
                  <div class="row p-0 m-0 ">
                    <span class="h6 mt-0 pt-0">
                      Tempo {{beneficio.relacaoTempoContribuicaoCarencia | number : '1.0-0'}}%
                    </span>
                    <!-- <span *ngIf="beneficio.cumpreRequisitoTempoContribuicao" class="mb-0 text-sm text-default">
                          Tempo de contribuição excedente {{ beneficio.tempoContribuicaoExcedenteCarencia.porExtenso }}
                      </span> -->
                  </div>
                </div>

                <div class="col-4  text-center" *ngIf="beneficio.pontos <= 0">

                  <!-- <progressbar type="green" [value]="beneficio.relacaoCarenciaMeses"> </progressbar> -->
                  <div class="progress m-0 p-0">
                    <div class="progress-bar bg-success m-0 p-0" role="progressbar" [style.width]="(beneficio.relacaoCarenciaMeses | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">

                    </div>
                  </div>
                  <div class="row p-0 m-0 ">
                    <span class="h6 mt-0 pt-0">
                      Carência {{(beneficio.relacaoCarenciaMeses | number : '1.0-0')}}%
                    </span>
                    <!-- <span *ngIf="beneficio.cumpreRequisitoCarencia" class="mb-0 text-sm text-default">
                          requisito carência excedente: {{ beneficio.carenciaCumprida.meses - beneficio.carenciaMeses.meses }}
                      </span> -->
                  </div>

                </div>

                <div class="col-4 text-center" *ngIf="beneficio.pontos > 0">
                  <!-- <progressbar type="green" [value]="beneficio.relacaoPontosCarencia"> </progressbar> -->
                  <div class="progress m-0 p-0">
                    <div class="progress-bar bg-success m-0 p-0" role="progressbar" [style.width]="(beneficio.relacaoPontosCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">

                    </div>
                  </div>
                  <div class="row p-0 m-0 ">
                    <span class="h6 mt-0 pt-0">
                      Pontos {{(beneficio.relacaoPontosCarencia | number : '1.0-0')}} %
                    </span>

                  </div>

                </div>

              </div>
              <div class="col-6 text-left">

                <div [ngSwitch]="statusBeneficioType[beneficio.status]">
                  <small>
                    <p *ngSwitchCase="statusBeneficioType.Apto" class="badge bg-success rounded-pill text-white h6 px-2 mr-4 ">{{beneficio.status | statusBeneficio }} em {{calculoBeneficios.dataCalculo | date : 'dd/MM/yyyy'}}</p>
                  </small>
                  <small>
                    <p *ngSwitchCase="statusBeneficioType.Inapto" class="badge bg-danger rounded-pill text-white  h6 px-2 mr-4 "> {{beneficio.status | statusBeneficio }} em {{calculoBeneficios.dataCalculo | date : 'dd/MM/yyyy'}}</p>
                  </small>
                </div>

              </div>

              <div class="col-6 text-right">

              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

  <div class="offcanvas w-75 offcanvas-end" data-bs-scroll="false"  tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
    <div class="offcanvas-header">
      <h5 *ngIf="beneficioCanvas" class="offcanvas-title" id="offcanvasBottomLabel">{{beneficioCanvas.qualificacaoDetalhada}}</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row p-0 mb-4 m-0 rounded flex-row-reverse">
        <div class="col-auto px-0 mx-1">
          <i role="button" class="comando bi bi-filetype-pdf fs-3  m-2" (click)="gerarPDFBeneficio(beneficioCanvas)"></i>
        </div>
      </div>
      <div class="row p-0 m-0 text-left">
        <app-relatorio-beneficio #relatorioBeneficio *ngIf="beneficioCanvas" [beneficio]="beneficioCanvas"></app-relatorio-beneficio>
      </div>
    </div>
  </div>

  <div class="py-2">
    <app-ranking-rmi *ngIf="calculoBeneficios.beneficios" [beneficios]="calculoBeneficios.beneficios"
      (rmiSelecionadaEvent)="verRelatorioCompleto($event)"></app-ranking-rmi>
  </div>

  <!-- <div class="py-2">
    <app-evolucao-salarios-pos-real *ngIf="calculoBeneficios.beneficios"
      [beneficio]="calculoBeneficios.beneficios[0]"></app-evolucao-salarios-pos-real>
  </div> -->

</div>