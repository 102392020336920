import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-aguarde-local',
  templateUrl: './aguarde-local.component.html',
  styleUrl: './aguarde-local.component.scss'
})
export class AguardeLocalComponent {

  @Input() aguarde:boolean;

}
