import { CompartilhadoModule as CompartilhadoPipes} from 'src/app/aplicacao/compartilhado/compartilhado.module';
import { CompartilhadoModule } from './../calculos/previdenciario/compartilhado/compartilhado.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntrevistasRoutingModule } from './entrevistas-routing.module';
import { EntrevistasComponent } from '../entrevistas/entrevistas.component';
import { NovaEntrevistaComponent } from './nova-entrevista/nova-entrevista.component';
import { NovaFichaComponent } from './nova-ficha/nova-ficha.component';
import { EditorPerguntasComponent } from './editor-perguntas/editor-perguntas.component';
import { EditorOpcoesFichaComponent } from './editor-opcoes-ficha/editor-opcoes-ficha.component';
import { PublicarFichaComponent } from './publicar-ficha/publicar-ficha.component';
import { NovaPerguntaComponent } from './nova-pergunta/nova-pergunta.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListagemPerguntasComponent } from './listagem-perguntas/listagem-perguntas.component';
import { ResumoOpcoesFichaComponent } from './resumo-opcoes-ficha/resumo-opcoes-ficha.component';
import { ListagemFichaComponent } from './listagem-ficha/listagem-ficha.component';
import { EditorOpcoesEntrevistaComponent } from './editor-opcoes-entrevista/editor-opcoes-entrevista.component';
import { AcoesEntrevistaComponent } from './acoes-entrevista/acoes-entrevista.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { ResumoEntrevistaComponent } from './resumo-entrevista/resumo-entrevista.component';
import { ListagemEntrevistasComponent } from './listagem-entrevistas/listagem-entrevistas.component';




@NgModule({
  declarations: [
    EntrevistasComponent,
    NovaEntrevistaComponent,
    NovaFichaComponent,
    EditorPerguntasComponent,
    EditorOpcoesFichaComponent,
    PublicarFichaComponent,
    NovaPerguntaComponent,
    ListagemPerguntasComponent,
    ResumoOpcoesFichaComponent,
    ListagemFichaComponent,
    EditorOpcoesEntrevistaComponent,
    AcoesEntrevistaComponent,
    ResumoEntrevistaComponent,
    ListagemEntrevistasComponent,
    
  ],
  exports:[
    ListagemEntrevistasComponent,
    NovaPerguntaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EntrevistasRoutingModule,
    CompartilhadoModule,
    CompartilhadoPipes,
    InputMaskModule,
    
    
  ]
})
export class EntrevistasModule { }
