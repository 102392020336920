import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked, AfterViewInit, OnChanges, SimpleChanges, AfterContentInit, AfterViewChecked } from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Calculo } from 'src/app/modelos/common/calculo';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { RequestCalculoBeneficios } from 'src/app/modelos/previdenciario/requestcalculobeneficios';
import { RequestRelatorioRendaMensalInicial } from 'src/app/modelos/previdenciario/requestrelatoriorendamensalinicial';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { GruposSumarioCalculoBeneficiosComponent } from '../compartilhado/grupos-sumario-calculo-beneficios/grupos-sumario-calculo-beneficios.component';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { BarraNavegacaoComponent } from 'src/app/aplicacao/compartilhado/barra-navegacao/barra-navegacao.component';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { ListarRelatorioRendaMensalInicialPdfService } from 'src/app/servicos/relatorio/listar-relatorio-renda-mensal-inicial-pdf.service';
import { EditorCNISComponent } from '../compartilhado/editor-cnis/editor-cnis.component';
import { ImportadorCNISComponent } from '../compartilhado/importador-cnis/importador-cnis.component';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';

import { CalculoAnaliseCnis } from 'src/app/modelos/previdenciario/calculoanalisecnis';
import { ResultadoAnaliseCnisComponent } from '../compartilhado/resultado-analise-cnis/resultado-analise-cnis.component';
import { CnisListagemComponent } from 'src/app/aplicacao/dashboard/compartilhado-dashboard/cnis-listagem/cnis-listagem.component';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import Stepper from 'bs-stepper';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { LogService } from 'src/app/servicos/log.service';



@Component({
  selector: 'app-preparacao-cnis',
  templateUrl: './preparacao-cnis.component.html',
  styleUrls: ['./preparacao-cnis.component.scss']
})
export class PreparacaoCnisComponent implements OnInit, AfterViewInit, OnChanges, AfterViewChecked {
  @ViewChild('seletorCnis', { static: false }) seletorCnis?: CnisListagemComponent;
  @ViewChild('tabsCalculoBeneficios', { static: false }) tabs?: TabsetComponent;
  @ViewChild('importadorCNIS') importadorCNIS: ImportadorCNISComponent;
  @ViewChild('editorCNIS') editorCNIS: EditorCNISComponent;
  @ViewChild('resultadoAnaliseCnis') resultadoAnaliseCnis: ResultadoAnaliseCnisComponent;



  calculoAnaliseCnis: CalculoAnaliseCnis;

  aguardeConfig: boolean;
  aguarde: boolean;

  beneficioSelecionado: Beneficio;
  aguardeVisivel: boolean = false;
  relatorioVisivel: boolean = true;

  private stepper: Stepper;






  //usado para atualizar ou nao o grupo sumario depois da navegacao voltar
  //identifica se houve alteracao no cnis para calcular novamente
  cnisEditado: CNIS;
  dataCalculoEditada: Date;
  calculoArquivo: boolean;

  

  atualizarCalculoCnisModificado:boolean;


  constructor(private location: Location, private listarCalculoResultadoService: ListarCalculoResultadoService,
    private datePipe: DatePipe, private changeDetector: ChangeDetectorRef,
    private router: Router,
    private configService: ConfigService,
    private notificacoesService: NotificacoesService,
    private listarPDFService: ListarPDFService,
    private calculeiApiService:CalculeiApiService,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService) {

      this.configService.getAguarde().subscribe(aguarde=>this.aguarde=aguarde);



    // this.calculoAnaliseCnis = new CalculoAnaliseCnis();
    // this.calculoAnaliseCnis.calculoBeneficios = new CalculoBeneficios();




  }


  ngAfterViewChecked(): void {
    //ng0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    //erro quando coloca retorno do evento edicaocnisfinalizada no objeto calculo
    this.changeDetector.detectChanges();
  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.



    if (this.location.getState().hasOwnProperty('calculo')) {

      this.calculoAnaliseCnis = this.location.getState()['calculo'] as CalculoAnaliseCnis;
      this.stepper.to(3)
    }
    else {
      this.calculoAnaliseCnis = new CalculoAnaliseCnis();
      this.calculoAnaliseCnis.nomeCalculo = "Nova Análise CNIS";
      this.calculoAnaliseCnis.data = new Date();
      this.calculoAnaliseCnis.tipoCalculo = CalculoTipos.PREPARACAOANALISECNIS;
      this.calculoAnaliseCnis.calculoBeneficios = new CalculoBeneficios();
      this.calculoAnaliseCnis.calculoBeneficios.dataCalculo = new Date();
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {

    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {
      // You can call preventDefault to stop the rendering of your step
      // console.warn('stepper show', event.detail);

      switch (event.detail.to) {
        case 0:
          // console.log('entrar no passo seleção CNIS');
          break;
        case 1:
          if (this.calculoAnaliseCnis.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Selecione um CNIS no passo 1 - Selecionar CNIS, para continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok, ir para o passo 1 - Selecionar CNIS',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }
          break;
        case 2:
          if (this.calculoAnaliseCnis.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
            this.calcularResultado();
          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Selecione um CNIS no passo 1 - Selecionar CNIS, para continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok, ir para o passo 1 - Selecionar CNIS',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }

          break;

        default:
          break;
      }

      //controle salvar cnis

      switch (event.detail.from) {
        case 1:
          this.editorCNIS.salvarModificacoesCnis();
          break;

        default:
          break;
      }
    });

    stepperElement.addEventListener('shown.bs-stepper', (event: CustomEvent) => {
      // console.warn('step shown mostrando', event.detail);

      // console.log('passo ', event.detail.indexStep);
      switch (event.detail.indexStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }

    });

    this.configService.getAguarde().subscribe(status => this.aguardeConfig = status);

    if (!environment.production) {
      // this.mock();
    };

    
  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculoresultadoanalisecnis.json", request => {
      this.carregarDados(request as CalculoAnaliseCnis);
      this.stepper.to(3);

    });
  }

  carregarDados(calculoAnaliseCnis: CalculoAnaliseCnis) {


    this.calculoAnaliseCnis = calculoAnaliseCnis;
    this.cnisEditado = this.calculoAnaliseCnis.calculoBeneficios.leituraCnis.cnis;
    this.dataCalculoEditada = this.calculoAnaliseCnis.calculoBeneficios.dataCalculo;

    // this.resultadoAnaliseCnis.carregarDados(this.calculoAnaliseCnis);



  }

  leituraCNISEvent(leituraCNIS: LeituraCNIS) {


    this.calculoAnaliseCnis.calculoBeneficios.leituraCnis = leituraCNIS;

    this.cnisAtualizado(this.calculoAnaliseCnis.calculoBeneficios);
    this.proximo(true);

  }
  processandoCNISEvent(processando: boolean) {
    this.aguardeVisivel = processando;
  }

  //editor cnis

  compararEdicaoCNIS(cnisAnterior: CNIS, cnisAtual: CNIS, dataAnterior: Date, dataAtual: Date): boolean {
    let resultado: boolean;
    resultado = (JSON.stringify(cnisAnterior) === JSON.stringify(cnisAtual)) && dataAnterior.toDateString() === dataAtual.toDateString();

    return resultado;
  }

  edicaoCNISFinalizada(calculoBeneficios: CalculoBeneficios) {


    this.calculoAnaliseCnis.calculoBeneficios = calculoBeneficios;
    this.cnisEditado = calculoBeneficios.leituraCnis.cnis;
    this.dataCalculoEditada = calculoBeneficios.dataCalculo;
    this.changeDetector.detectChanges();
    // this.resultadoAnaliseCnis.carregarDados(this.calculoAnaliseCnis);
  }

  sumarioFinalizado(pronto: boolean) {
    this.aguardeVisivel = false;
  }


  incluirMarcadorCnis(incluir: boolean) {


    this.editorCNIS.incluirMarcador();


  }

  excluirCnis() {
    this.aguardeVisivel = true;
    this.editorCNIS.excluirCnis();
  }

  excluirCnisFinalizado(exclui: boolean) {
    // console.log('cnis excluido - beneficios component');
    this.editorCNIS.excluirFinalizado();
    // this.seletorCnis.carregarDados();

    this.aguardeVisivel = false;
  }

  salvarEdicaoCnis(event) {
    this.editorCNIS.salvarModificacoesCnis();
  }



  

  pdf(event) {

    if(!this.calculoAnaliseCnis.relatorioAnaliseCnis)return;

    this.configService.setAguarde(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    this.calculoAnaliseCnis.tipoCalculo = CalculoTipos.PREPARACAOANALISECNIS;
    this.calculoAnaliseCnis.usuario = Global.usuario;

    this.calculeiApiService.apiBlobLx("ListarPDF",this.calculoAnaliseCnis)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'ANALISE-CNIS-'+ this.calculoAnaliseCnis.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
        this.configService.setAguarde(false);

      });


  }

  atualizarListaCnis(atualizar: boolean) {
    // this.seletorCnis.carregarDados();
  }

  proximoStepper() {
    // console.log('proximo stepper');
    this.stepper.next();
  }
  anteriorStepper() {
    // console.log('anterior stepper');
    this.stepper.previous();
  }

  proximo(event) {
    this.proximoStepper()
  }
  anterior(event) {
    this.anteriorStepper()
  }
  cancelar(event) {
    // console.log('cancelar');
    this.router.navigateByUrl('calculei/home');

  }
  
  cnisModificado(event) {
    // console.log('cnis Modificado set true atualizarCalculo');
    this.atualizarCalculoCnisModificado = true;

  }

  cnisAtualizado(calculoBeneficios: CalculoBeneficios) {
    // console.log('atualizando cnis beneficios');
    if (this.editorCNIS) {
      this.editorCNIS.carregarDados();
      this.atualizarCalculoCnisModificado = true;
    }
    // this.configService.setAguarde(true);
    // this.changeDetector.detectChanges();
    // let prepCalculoApi: CalculoBeneficios = JSON.parse(JSON.stringify(calculoBeneficios));
    // prepCalculoApi.beneficios = new Array();

    // this.listarGruposSumarioCalculoBeneficiosService.listarGruposSumarioCalculoBeneficios(prepCalculoApi)
    //   .then(calculo => {

    //     // this.gruposSumarioBeneficios.carregarDados()


    //     console.log('calculo pronto beneficios');
    //     console.log(calculo);

    //     this.calculoBeneficios = calculo as CalculoBeneficios;
    //     this.configService.setAguarde(false);


    //   });
  }


  calcularResultado(){

    this.configService.getUsuario().subscribe(usuario => this.calculoAnaliseCnis.usuario = usuario);
    if(!this.atualizarCalculoCnisModificado)return;
    this.configService.setAguarde(true);
    this.calculeiApiService.api("ListarAnaliseCnis", this.calculoAnaliseCnis)
    .subscribe(calculo=>{
      
      
      this.calculoAnaliseCnis = calculo as CalculoAnaliseCnis;
      LogService.log('resultado calculo analise cnis', this.calculoAnaliseCnis);
      this.configService.setAguarde(false);
      this.atualizarCalculoCnisModificado = false;
      

      
      
      
    });
    

  }



}
