import { Base } from "../base";
import { Arquivo } from "../faturas/arquivo";
import { Cliente } from "../usuario/cliente";
import { Usuario } from "../usuario/usuario";
import { CalculoCategoria } from "./calculocategoria";
import { CalculoTipos } from "./calculotipos";
import { Resultado } from "./resultado";

export class Calculo extends Base{
    categoria:CalculoCategoria;
    tipo:CalculoTipos;
    usuario:Usuario;
    arquivos:Arquivo[];
    cliente:Cliente;
    data:Date;
    resultado:Resultado;
    

}