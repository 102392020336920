import { Pipe, PipeTransform } from '@angular/core';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { IndicadorCNIS } from 'src/app/modelos/previdenciario/indicadorcnis';
import { LogService } from 'src/app/servicos/log.service';

@Pipe({
  name: 'indicadoresUnicos',
  
})
export class IndicadoresUnicosPipe implements PipeTransform {

  transform(contribuicoes: Contribuicao[], ...args: unknown[]): IndicadorCNIS[] {


    let indicadores:IndicadorCNIS[] = new Array();


    // LogService.log('indicadores contribuicao',contribuicoes.filter(contribuicao=>contribuicao.indicadoresCnis && contribuicao.indicadoresCnis.length > 0).flatMap(contribuicao=>contribuicao.indicadoresCnis));

    // return indicadores;


    // LogService.log('indicadores contribuicao',contribuicoes.filter(contribuicao=>contribuicao.indicadoresCnis && contribuicao.indicadoresCnis.length > 0).flatMap(contribuicao=>contribuicao.indicadoresCnis));
    // let lista = contribuicoes.flatMap(contribuicao=>contribuicao.indicadoresCnis).map(item =>item.sigla).filter((value, index, self)=>self.indexOf(value)===index)

    function filtrarUnicosPorSigla(array: any[]): any[] {
      const mapaUnicos = new Map();
    
      array.forEach(objeto => {
        if (!mapaUnicos.has(objeto.sigla)) {
          mapaUnicos.set(objeto.sigla, objeto);
        }
      });
    
      return Array.from(mapaUnicos.values());
    }

    // LogService.log('indicadores unicos', filtrarUnicosPorSigla(contribuicoes.filter(contribuicao=>contribuicao.indicadoresCnis && contribuicao.indicadoresCnis.length > 0).flatMap(contribuicao=>contribuicao.indicadoresCnis)));
    
    return filtrarUnicosPorSigla(contribuicoes.filter(contribuicao=>contribuicao.indicadoresCnis && contribuicao.indicadoresCnis.length > 0).flatMap(contribuicao=>contribuicao.indicadoresCnis));
  }

}
