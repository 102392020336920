import { Component } from '@angular/core';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-opcoes-administrador',
  templateUrl: './opcoes-administrador.component.html',
  styleUrl: './opcoes-administrador.component.scss'
})
export class OpcoesAdministradorComponent {

  constructor(private confgService:ConfigService,
    private calculeiApiService:CalculeiApiService,
    private autenticationService:AutenticacaoService
  ){}

  testeApi(){
    this.calculeiApiService.api("CriarMatrizPlanejamento", null)
    .subscribe(resultado=>{
      // console.log('teste api admin', resultado);
    })
  }

  logout(){
    this.autenticationService.logout();
  }

}
