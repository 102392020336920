import { CalculoBeneficios } from "./calculobeneficios";
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from "./tipobeneficioliquidacaosentencaprevidenciario";

export class BeneficioLiquidacaoSentencaPrevidenciario {
    tipoBeneficio:TipoBeneficioLiquidacaoSentencaPrevidenciario;
    numeroBeneficio:string;
    dataCessacao:Date;
    dib:Date;
    dip:Date;
    rmi:number;
    dib25:Date;
    calculoBeneficios:CalculoBeneficios;
}