import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import * as moment from 'moment';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';

@Component({
  selector: 'app-variacao-rmi-matriz',
  templateUrl: './variacao-rmi-matriz.component.html',
  styleUrls: ['./variacao-rmi-matriz.component.scss']
})
export class VariacaoRmiMatrizComponent {

  @Input() matriz: MatrizPlanejamento;


  @Output() casoSelecionadoEvent = new EventEmitter<CasoPlanejamentoPrevidenciario>();


  public data: any[];

  chart: Chart;

  constructor(private currency: CurrencyPipe,
    private decimalPipe:DecimalPipe,
    private changeDetector: ChangeDetectorRef, private datePipe: DatePipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('data change ranking rmi');
    if (this.chart) {
      this.changeDetector.detectChanges();
      // console.log('update chart');
      this.carregarDados();
      this.changeDetector.detectChanges();
    }

  }

  ngOnInit() {
    this.carregarDados();
  }

  carregarDados() {

    if (this.chart) { this.chart.destroy(); this.chart = null; }

    if(this.matriz.casos.length == 0)return;

    moment.locale('pt-BR');


    this.data = this.matriz.marcadorReferencia != undefined ? this.matriz.casos.filter(caso => caso.marcador.rowKey == this.matriz.marcadorReferencia.rowKey) : this.matriz.casos;
    this.data = this.data.sort((a, b) => b.valorBeneficio - a.valorBeneficio);

    let datasetLabels = this.data.map(caso => caso.nome);

    let dataset = {
      labels: datasetLabels, datasets: [
        {
          data: this.data.map(caso => caso.valorBeneficio),
          label: 'R.M.I.: ',
          borderColor: this.data.map(caso => caso.marcador.cor),
          backgroundColor: this.data.map(caso => caso.marcador.cor),
        }
      ]
    };



    let chart = document.getElementById('chartVariacaoRmiMatriz') as HTMLCanvasElement;


    Chart.register(annotationPlugin);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      options: {
        onClick: (e, activeEls) => {
          if (activeEls[0])
            this.casoSelecionadoEvent.emit(this.data.sort((a, b) => b.valorBeneficio - a.valorBeneficio)[activeEls[0].index]);
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          datalabels:{
            display:true,
            color:"white",
            font:{
              
              weight: 'bold',

            },
            formatter: (value, context) => {
                return 'R$ ' + this.decimalPipe.transform(value, '1.2-2');
            },
            
          },

          annotation: {
            annotations: {

              // line1: {
              //   drawTime: 'afterDatasetsDraw',
              //   type: 'line',
              //   yMin: this.media.toFixed(2),
              //   yMax: this.media.toFixed(2),
              //   xMin: new Date(1994,7,1).toISOString(),
              //   xMax: new Date(datasetLabels[0]).toISOString(),
              //   borderColor: '#000000',
              //   borderWidth: 2,
              //   label: {
              //     position: 'center',
              //     display: true,
              //     // font:{
              //     //   size:24,
              //     //   weight:'normal',
              //     // },
              //     content: (ctx, options) => {
              //       return 'Média ' + this.currency.transform(this.media.toFixed(2));
              //     },
              //   }

              // },
            }
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'R.M.I. por Cenário',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              size: 24,
              weight: 'normal',
            },
            titleFont: {
              size: 24,
              weight: 'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.currency.transform(context.parsed.y);
                }
                return label;
              },
              title: (tooltipItens) => {
                return "Cenário " + tooltipItens[0].label;
              },

            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,

            ticks: {
              callback: (value, index, ticks) => {
                return this.currency.transform(value);
              }
            }

          },
          x: {

            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Cenários'
            },




          }
        }
      }

    });
  }



}
