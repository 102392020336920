<nav
  id="navbar-main"
  class="navbar navbar-horizontal navbar-main navbar-expand-lg navbar-dark bg-dark">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/paginas/home']">
      <!-- <img src="assets/img/brand/banner-logo-dark.svg" /> -->
      <img src="assets/img/brand/logo-light-default.svg" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbar-collapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse navbar-custom-collapse collapse"
      id="collapseBasic" [collapse]="isCollapsed">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/']">
              <!-- <img src="assets/img/brand/banner-logo-light.svg" /> -->
              <img src="assets/img/brand/logo-light-default.svg" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbar-collapse"
              aria-controls="navbar-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              (click)="isCollapsed = !isCollapsed">
              <span></span> <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/dashboards/dashboard']"
          >
            <span class="nav-link-inner--text">Dashboard</span>
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/examples/pricing']"
          >
            <span class="nav-link-inner--text">Pricing</span>
          </a>
        </li> -->

        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/examples/register']"
          >
            <span class="nav-link-inner--text">Register</span>
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/examples/lock']"
          >
            <span class="nav-link-inner--text">Lock</span>
          </a>
        </li> -->
      </ul>
      <hr class="d-lg-none" />
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Facebook">
            <i class="fab fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Instagram">
            <i class="fab fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://twitter.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Twitter">
            <i class="fab fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li>
        <!-- <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://github.com/creativetimofficial"
            target="_blank"
            tooltip="Star us on Github"
          >
            <i class="fab fa-github"></i>
            <span class="nav-link-inner--text d-lg-none">Github</span>
          </a>
        </li> -->
        <li class="nav-item">
          <!-- <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/paginas/login/']"
          >
            <span class="nav-link-inner--text">Login</span>
          </a> -->
          <a
            class="nav-link"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="login()">
            <span class="nav-link-inner--text">Entrar</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class=" main-content">

  <div *ngIf="!usuario" class=" header  py-7 py-lg-8 pt-lg-9">
    <div class=" container">
      <div class=" text-center mb-7">
        <div class=" row justify-content-center">
          <div class="text-default col-xl-5 col-lg-6 col-md-8 px-5">
            <div class="spinner-border spinner-border-lg" role="status">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon class="fill-primary" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div> -->
  </div>

  <div *ngIf="usuario" class=" header  py-7 py-lg-8 pt-lg-9">
    <div class=" container">
      <div class=" header-body text-center mb-7">
        <div class=" row justify-content-center">
          <div class=" col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class=" text-default">
              Olá Seja bem-vindo(a) ao Calculei
            </h1>
            <p class=" text-lead text-default">
              Seu login deu certo mas notamos que você ainda não confirmou o e-mail que enviamos para <span class="font-weight-bold">{{usuario.email}}</span>
            </p>

            <p class=" text-lead text-default text-bold">
              Acabamos de enviar novamente. Estamos aguardando a sua confirmação.
            </p>

            <p class=" text-lead text-default text-bold">
              Depois de confirmar você pode voltar aqui e clicar em
            </p>
            <p class=" text-lead text-default">
              <a
                href="javascript:void(0)"
                class="btn btn-outline-black"
                (click)="login()">

                <span class="nav-link-inner--text">Entrar</span>
              </a>
            </p>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
