import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CriarPlanoRequest } from 'src/app/modelos/safe2pay/criarplanorequest';
import { environment } from 'src/environments/environment';
import { ListarPlanoResponseObject } from 'src/app/modelos/safe2pay/listarplanosresponse';

@Injectable({
  providedIn: 'root'
})
export class BuscaMunicipioService {

  constructor(private httpClient:HttpClient) { }

  buscaMunicipio(request:string){

    return Promise.all([this.apicall(request)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(request:string){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.get('https://brasilapi.com.br/api/ibge/municipios/v1/' +request + '?providers=gov', {'headers':headers}).toPromise();
    return data;
  }
}