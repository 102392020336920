import { Component, Input } from '@angular/core';
import { Ficha } from 'src/app/modelos/previdenciario/ficha';
import { Pergunta } from 'src/app/modelos/previdenciario/pergunta';

@Component({
  selector: 'app-editor-perguntas',
  templateUrl: './editor-perguntas.component.html',
  styleUrl: './editor-perguntas.component.scss'
})
export class EditorPerguntasComponent {

  @Input() ficha:Ficha;

  constructor(){}


  addPergunta(pergunta:Pergunta){
    // console.log('ficha', this.ficha);
    // console.log('add pergunta editor', pergunta);
    this.ficha.perguntas.push(pergunta);
  }

}
