






<div class="row ">
    <div class="col-12">
        <div class="row justify-content-end ">



            <div class="continuo">

                <div class="col-12 ">


                    <h1 class=" display-4 card-title text-capitalize mb-0 text-default">

                        <!-- {{beneficio.reforma}} {{beneficio.regra | regra}} -->
                        {{caso.beneficio.qualificacao}} - {{caso.beneficio.qualificacaoDetalhada}}

                    </h1>


                    <div [ngSwitch]="statusBeneficioType[caso.beneficio.status]">
                        <span *ngSwitchCase="statusBeneficioType.Apto"
                            class="badge badge-success badge-pill text-default px-4 mr-4">{{caso.beneficio.status
                            | statusBeneficio }}</span>
                        <span *ngSwitchCase="statusBeneficioType.Inapto"
                            class="badge badge-danger badge-pill text-default px-4 mr-4"> {{caso.beneficio.status
                            | statusBeneficio }}</span>
                        <span *ngSwitchCase="statusBeneficioType.Outros"
                            class="badge badge-warning badge-pill text-default px-4 mr-4">{{caso.beneficio.status
                            | statusBeneficio }}</span>
                        
                    </div>

                
    
    
                </div>
    
    
                <div *ngIf="caso.totalContributivoComplementarMeses > 0" class="col-12 ">
                    <span class="h2 text-default">
                        {{caso.totalContributivoComplementarMeses}} contribuições de
                        {{caso.valorContribuicaoComplementarMensal | currency}} para cumprimento de carência / tempo de contribuição
                    </span>
                </div>
                <div *ngFor="let contribuicaoEspecifica of caso.contribuicoesEspecificas" class="col-12 ">
                    <span class="h2 text-default">
                        {{contribuicaoEspecifica.quantidade}} contribuições de
                        {{contribuicaoEspecifica.edicaoSalarioAliquota.salario *
                        contribuicaoEspecifica.edicaoSalarioAliquota.aliquota | currency}} específicas das opcões de simulação
                    </span>
                </div>
                <div *ngIf="caso.totalContributivoComplementarMeses <= 0" class="col-12 ">
                    <span class="h2 text-default">
                        0 contribuições. Carência e Tempo de Contribuição cumpridos.
                    </span>
                </div>
                <div *ngIf="caso.totalContributivoQualidadeSeguradoMeses > 0" class="col-12 ">
                    <span class="h2 text-default">
                        {{caso.totalContributivoQualidadeSeguradoMeses}} contribuições de
                        {{caso.valorContribuicaoQualidadeSegurado | currency}} para manutenção da qualidade
                        de segurado
                    </span>
                </div>
                <div class="col-12 pt-3">
                    <span class="h3 text-default">
                        Total do Investimento {{caso.totalInvestimento | currency}}
                    </span>
                </div>
    
                <div class="col-12">
                    <span class="h3 text-default">
                        Lucro líquido {{caso.retornoTotalInvestimentoRoi | currency}}
                    </span>
                </div>
    
    
    
    
    
                <div class="row justify-content-end">
                    <div class="col-auto ">
    
    
                        <h1 class=" display-2 card-title text-capitalize mb-0 text-default">
    
                            {{caso.beneficio.relatorioRendaMensalInicial.rendaMensalInicial | currency}}
    
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 text-default">
                            Renda Mensal Inicial
                        </span>
    
                    </div>
    
                    <div class="col-auto   ">
    
    
                        <h1 class=" display-2 card-title text-capitalize mb-0 text-default">
    
                            {{caso.beneficio.dataCalculo | date : 'dd/MM/yyyy'}}
    
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 text-default">
                            Data Prevista
                        </span>
    
                    </div>
    
    
                    <div *ngIf="visualizarDetalhe" class="col-auto habilitado clicavel " (click)="verCaso()">
                        <div class="col-1 p-4 mb-5 active ">
                            <span class=" timeline-step timeline-step-xlg  ">
                                <i class="bi-arrow-right"></i>
                            </span>
                        </div>
                      
                    </div>
    
                    
                </div>

            </div>

            


        </div>

    </div>

</div>