export enum TipoBeneficioLiquidacaoSentencaPrevidenciario{
    APOSENTADORIARURALVELHICE,
    APOSENTADORIATEMPOCONTRIBUICAO,
    APOSENTADORIATEMPOCONTRIBUICAOPONTOS,
    APOSENTADORIAIDADERURAL,
    APOSENTADORIATEMPOCONTRIBUICAOPROFESSOR,
    APOSENTADORIATEMPOCONTRIBUICAOPROFESSORPONTOS,
    APOSENTADORIAIDADEPESSOADECIFICIENCIA,
    APOSENTADORIAIDADE,
    APOSENTADORIAESPECIAL,
    APOSENTADORIAINVALIDEZ,
    APOSENTADORIAINCAPACIDADEPERMANENTE,
    AUXILIODOENCA,
    AUXILIOACIDENTE,
    BENEFICIOPRESTACAOCONTINUADAASSISTENCIALLOAS,
    PENSAOMORTE,
    AUXILIORECLUSAO,
    SALARIOMATERNIDADE,
    APOSENTADORIAINVALIDEZDECORRENTEACIDENTETRABALHO
}