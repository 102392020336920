import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Global } from 'src/app/modelos/global';
import { Assinatura } from 'src/app/modelos/pagamento/assinatura';
import { PreApproval } from 'src/app/modelos/pagseguro/preapproval';
import { StatusAdesaoPagseguro } from 'src/app/modelos/pagseguro/statusadesaopagseguro';
import { Usuario } from 'src/app/modelos/usuario/usuario';

import { AtualizarUsuarioService } from '../usuario/atualizar-usuario.service';
import { AutenticacaoService } from '../oauth/autenticacao.service';
import { DatePipe } from '@angular/common';
import { ListarUsuarioService } from '../usuario/listar-usuario.service';
import { StatusAssinatura } from 'src/app/modelos/usuario/statusassinatura';
import { AtualizarLogoService } from '../usuario/atualizar-logo.service';
import { AtualizarAvatarService } from '../usuario/atualizar-avatar.service';
import { Permissoes } from 'src/app/modelos/usuario/permissoes';
import { Marcador } from 'src/app/modelos/common/marcador';
import { ListarMarcadoresService } from '../usuario/listar-marcadores.service';
import { AtualizarUsuarioDadosService } from '../usuario/atualizar-usuario-dados.service';
import { CalculeiApiService } from '../calculei/calculei-api.service';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {


  constructor(
    private atualizarUsuarioService: AtualizarUsuarioService,
    private listarUsuarioService: ListarUsuarioService,
    private atualizarLogoService: AtualizarLogoService,
    private atualizarAvatarService: AtualizarAvatarService,
    private listarMarcadoresService: ListarMarcadoresService,
    private atualizarUsuarioDadosService: AtualizarUsuarioDadosService,
    private calculeiApiService: CalculeiApiService,
    private router: Router,
    private autenticacaoService: AutenticacaoService, private datePipe: DatePipe) {


  }


  private _historicoLeituraCnis = new BehaviorSubject<LeituraCNIS[]>(undefined);
  private _historicoLeituraCnis$ = this._historicoLeituraCnis.asObservable();

  getHistoricoLeituraCnis(): Observable<LeituraCNIS[]> {

    // let jsonListaHistoricoLeituraCnis:string = localStorage.getItem("listaLeituraCnis");
    // let listaHistoricoLeituraCnisLocalStorage:LeituraCNIS[] = jsonListaHistoricoLeituraCnis ? JSON.parse(jsonListaHistoricoLeituraCnis) : new Array<LeituraCNIS>();


    // console.log('teste lista storage <= 0', listaHistoricoLeituraCnisLocalStorage);

    // if (listaHistoricoLeituraCnisLocalStorage.length  0) {
    //   console.log('lista storage <= 0');
    //   this.setHistoricoLeituraCnis();
    // }



    // console.log('teste valor historico get', this._historicoLeituraCnis.getValue())
    if (this._historicoLeituraCnis.getValue() == undefined) {
      if (!environment.production) {
        // this._historicoLeituraCnis.next(JSON.parse(localStorage.getItem("listaLeituraCnis")));
      }
      // console.log('chamando setHistoricoLeituraCnis get retornou undefined')
      this.setHistoricoLeituraCnis();
    }

    return this._historicoLeituraCnis$;
  }

  setHistoricoLeituraCnis() {

    let requestBase: RequestBase = new RequestBase();
    this.getUsuario().subscribe(usuario => requestBase.usuario = usuario);

    this.calculeiApiService.api("ListarLeituraCnis", requestBase)
      .subscribe(resultado => {

        if (!environment.production) {
          // localStorage.setItem("listaLeituraCnis", JSON.stringify(resultado as LeituraCNIS[]))
        }

        this._historicoLeituraCnis.next(resultado as LeituraCNIS[]);

      });
  }

  atualizarHistoricoLeituraCnis(lista: LeituraCNIS[]) {
    this._historicoLeituraCnis.next(lista);
  }


  inserirHistoricoLeituraCnis(leituraCnis: LeituraCNIS) {
    this._historicoLeituraCnis.getValue().push(leituraCnis);

    this._historicoLeituraCnis.next(this._historicoLeituraCnis.getValue());
    if (!environment.production) {
      // localStorage.setItem("listaLeituraCnis", JSON.stringify(this._historicoLeituraCnis.getValue()))
    }
  }


  private _salarioMinimo = new BehaviorSubject<number>(0);
  private _salarioMinimo$ = this._salarioMinimo.asObservable();

  getSalarioMinimo(): Observable<number> {

    if (this._salarioMinimo.getValue() === 0) {
      this.setSalarioMinimo();
      // console.log('chamada sm');
    }

    return this._salarioMinimo$;
  }

  setSalarioMinimo() {

    if (this._salarioMinimo.getValue() == 0) {
      this.calculeiApiService.api("ListarSalarioMinimo", {})
        .subscribe(resultado => {

          this._salarioMinimo.next(resultado['salarioMinimo']);
          this._salarioTeto.next(resultado['salarioTeto']);

        });

    }
  }


  private _salarioTeto = new BehaviorSubject<number>(0);
  private _salarioTeto$ = this._salarioTeto.asObservable();

  getSalarioTeto(): Observable<number> {

    if (this._salarioTeto.getValue() === 0) {
      this.setSalarioTeto();
      // console.log('chamada st');
    }

    return this._salarioTeto$;
  }

  setSalarioTeto() {

    this.calculeiApiService.api("ListarSalarioMinimo", {})
      .subscribe(resultado => {

        this._salarioMinimo.next(resultado['salarioMinimo']);
        this._salarioTeto.next(resultado['salarioTeto']);

      });
  }

  private _listaMarcadores = new BehaviorSubject<Marcador[]>(new Array());
  private _listaMarcadores$ = this._listaMarcadores.asObservable();

  getListaMarcadores(): Observable<Marcador[]> {
    return this._listaMarcadores$;
  }

  setListaMarcadores() {

    this.listarMarcadoresService.listarMarcadores()
      .then((listaMarcadores: Marcador[]) => {

        this._listaMarcadores.next(listaMarcadores);

      });
  }


  private _duracaoDiasTesteRestante = new BehaviorSubject<number>(0);
  private _duracaoDiasTesteRestante$ = this._duracaoDiasTesteRestante.asObservable();

  getDuracaoDiasTesteRestante(): Observable<number> {
    return this._duracaoDiasTesteRestante$;
  }

  setDuracaoDiasTesteRestante() {
    this.getUsuario().subscribe(usuario => {


    });
  }


  private aguarde = new BehaviorSubject<boolean>(false);
  private aguarde$ = this.aguarde.asObservable();

  getAguarde(): Observable<boolean> {
    return this.aguarde$;
  }

  setAguarde(habilitar: boolean) {
    this.aguarde.next(habilitar);
  }





  private _usuario = new BehaviorSubject<Usuario>(null);
  private _usuario$ = this._usuario.asObservable();

  getUsuario(): Observable<Usuario> {
    return this._usuario$;
  }

  async setUsuario(alteracao: Usuario) {
    if (this.autenticacaoService.oauthService.hasValidAccessToken()) {
      this._usuario.next(alteracao);
      Global.usuario = alteracao;
      localStorage.removeItem('usuario');
      localStorage.setItem('usuario', JSON.stringify(alteracao));

      //periodo teste
      this.contagemDiasRestantesPeriodoTeste();


      //controle de assinatura

      this.verificacaoAssinaturaAtiva();
    }

  }


  async setUsuarioDados(alteracao: Usuario) {
    if (this.autenticacaoService.oauthService.hasValidAccessToken()) {
      // console.log("setusuariodados");
      this.setUsuario(alteracao);
      this.atualizarUsuarioDadosService.atualizarUsuario(alteracao)
        .then((usuario: Usuario) => {
          //usuario atualizado na base
        });
    }

  }

  async setUsuarioAtualizarDaBase() {

    if (this.autenticacaoService.oauthService.hasValidAccessToken()) {

      await this.listarUsuarioService.listarUsuario(this._usuario.getValue())
        .then(async resultado => {
          // console.log("setusuarioatualizardabase");
          await this.setUsuario(resultado as Usuario);

        });
    }

  }

  async setUsuarioAtualizarParaBaseLogo(usuario: Usuario) {

    if (this.autenticacaoService.oauthService.hasValidAccessToken()) {

      await this.atualizarLogoService.atualizarLogo(usuario)
        .then(async resultado => {
          // console.log("setUsuarioAtualizarParaBaseLogo");
          await this.setUsuario(resultado as Usuario);

        });
    }

  }


  async setUsuarioAtualizarParaBaseAvatar(usuario: Usuario) {

    if (this.autenticacaoService.oauthService.hasValidAccessToken()) {

      await this.atualizarAvatarService.atualizarAvatar(usuario)
        .then(async resultado => {
          // console.log("setUsuarioAtualizarParaBaseAvatar");
          await this.setUsuario(resultado as Usuario);

        });
    }

  }

  // async setUsuarioAtualizar(alteracao: Usuario) {
  //   if (this.autenticacaoService.oauthService.hasValidAccessToken()) {
  //     this._usuario.next(alteracao);
  //     await this.atualizarUsuarioService.atualizarUsuario(alteracao)
  //       .then((usuario: Usuario) => {
  //         Global.usuario = alteracao;
  //         localStorage.removeItem('usuario');
  //         localStorage.setItem('usuario', JSON.stringify(alteracao))

  //       });
  //   }

  // }











  contagemDiasRestantesPeriodoTeste() {
    let fimPeriodo: Date = new Date(this._usuario.getValue().fimPeriodoTeste);
    fimPeriodo = new Date(fimPeriodo.getFullYear(), fimPeriodo.getMonth(), fimPeriodo.getDate());



    // let fimPeriodo: Date = new Date(this._usuario.getValue().fimPeriodoTeste);

    let hoje: Date = new Date();
    hoje = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate());


    let diferencaTime = fimPeriodo.getTime() - hoje.getTime();
    let diasRestantes: number = diferencaTime / (1000 * 3600 * 24);

    this._duracaoDiasTesteRestante.next(diasRestantes);


  }









  private _assinatura = new BehaviorSubject<Assinatura>(null);
  private _assinatura$ = this._assinatura.asObservable();
  getAssinatura(): Observable<Assinatura> {
    return this._assinatura$;
  }
  setAssinatura(assinatura: Assinatura) {

    if (assinatura != null) {
      this._assinatura.next(assinatura);
    }
  }


  private _assinaturaAtiva = new BehaviorSubject<boolean>(false);
  private _assinaturaAtiva$ = this._assinaturaAtiva.asObservable();
  getAssinaturaAtiva(): Observable<boolean> {
    return this._assinaturaAtiva$;
  }


  private _assinaturaStatus = new BehaviorSubject<StatusAdesaoPagseguro>(null);
  private _assinaturaStatus$ = this._assinaturaStatus.asObservable();
  getAssinaturaStatus(): Observable<StatusAdesaoPagseguro> {
    return this._assinaturaStatus$;
  }
  verificacaoAssinaturaAtiva() {

    // console.log('verificacao assinatura ativa');

    let assinatura: Assinatura = this._usuario.getValue().assinatura;

    if (assinatura != null) {
      if (assinatura.statusAssinatura == StatusAssinatura.ATIVA) {
        this._assinaturaAtiva.next(true);
      }
      else {


        if (this._usuario.getValue().permissao == Permissoes.Socio || this._usuario.getValue().permissao == Permissoes.AssinaturaPix || this._usuario.getValue().permissao == Permissoes.Filiado) {
          this._assinaturaAtiva.next(true);
        }
        else {
          this._assinaturaAtiva.next(false);
        }


      }
    } else {

      if (this._usuario.getValue().permissao == Permissoes.Socio || this._usuario.getValue().permissao == Permissoes.AssinaturaPix || this._usuario.getValue().permissao == Permissoes.Filiado) {
        this._assinaturaAtiva.next(true);
      }
      else {
        this._assinaturaAtiva.next(false);
      }
    }

  }


  public initializeAfterLogin() {
    this.setSalarioMinimo();
    this.router.navigateByUrl('calculei/home');
  }


}
