import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { Global } from 'src/app/modelos/global';
import { CalculoAnaliseCnis } from 'src/app/modelos/previdenciario/calculoanalisecnis';
import { CalculoIncapacidadeTemporaria } from 'src/app/modelos/previdenciario/calculoincapacidadetemporaria';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CriarContaRequest } from 'src/app/modelos/usuario/criarcontarequest';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListarAuxilioIncapacidadeTemporariaService {

  constructor(private httpClient:HttpClient) { }

  listar(request:CalculoIncapacidadeTemporaria){

    return Promise.all([this.apicall(request)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(request:CalculoIncapacidadeTemporaria){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ListarAuxilioIncapacidadeTemporaria',request, {'headers':headers}).toPromise();
    return data;
  }
}
