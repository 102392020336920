export enum MeiosPagamento {
    CARTAOCREDITO,
    BOLETO,
    PIX,
    CARTAOCREDITOVISTA,
    CARTAOCREDITO12
}
export class MeiosPagamentoEx{
    static meioToString(meio:MeiosPagamento){

        let texto:string = "";
        switch (meio) {
            case MeiosPagamento.BOLETO:
                texto = "Boleto"
                
                break;
                case MeiosPagamento.CARTAOCREDITO:
                texto = "Cartão de Crédito"
                
                break;
                case MeiosPagamento.CARTAOCREDITO12:
                texto = "Cartão de Crédito parcelado em 12X"
                
                break;
                case MeiosPagamento.CARTAOCREDITOVISTA:
                texto = "Cartão de Crédito à Vista"
                
                break;
                case MeiosPagamento.PIX:
                texto = "PIX"
                
                break;
        
            default:
                break;
        }
        return texto;

    }
}