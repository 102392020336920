import { BehaviorSubject, Observable } from "rxjs";
import { StatusAdesaoPagseguro } from "./pagseguro/statusadesaopagseguro";
import { Usuario } from "./usuario/usuario";

export class Global{
    public static usuario:Usuario;
    public static logo:string = 'logo-light-default';
    public static assinaturaAtiva:boolean;
    public static assinaturaStatus:StatusAdesaoPagseguro;
    public static pularRedirecionamentoConfirmacaoEmail:boolean;
}