import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { createMask } from '@ngneat/input-mask';

import { NgxSelectOption } from 'ngx-select-ex';
import { AppComponent } from 'src/app/app.component';
import { OpcaoIndice } from 'src/app/modelos/common/opcaoindice';
import { OpcoesIndicesCorrecaoMonetaria } from 'src/app/modelos/common/opcoesindicescorrecaomonetaria';
import { RegraCorrecaoMonetaria } from 'src/app/modelos/correcaomonetaria/regracorrecaomonetaria';
import { IdentificadorIndice } from 'src/app/modelos/previdenciario/identificadorindice';
import { ListarOpcoesIndicesCorrecaoMonetariaService } from 'src/app/servicos/calculo/common/listar-opcoes-indices-correcao-monetaria.service';

@Component({
  selector: 'app-nova-regra-atualizacao-monetaria',
  templateUrl: './nova-regra-atualizacao-monetaria.component.html',
  styleUrls: ['./nova-regra-atualizacao-monetaria.component.scss']
})
export class NovaRegraAtualizacaoMonetariaComponent implements OnInit{


  @Output() adicionarNovaRegraEvent = new EventEmitter<RegraCorrecaoMonetaria>();
  @Input() dib:Date;
  @Input() dataCalculo:Date;

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);
  currencyInputMask = createMask(AppComponent.currencyInputOptions);


  itemsSelectIndice:any[];
  itemIndice:any;

  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0'
  });

  focus:boolean;
  closeResult: string;

  novaRegraForm = new UntypedFormGroup({

    dataInicial: new UntypedFormControl('', Validators.required),
    dataFinal: new UntypedFormControl('', Validators.required),
    indice: new UntypedFormControl('', Validators.required)
  });

  opcoesIndicesCorrecaoMonetaria:OpcoesIndicesCorrecaoMonetaria;

  constructor(private modalService: NgbModal, private listarOpcoesIndicesCorrecaoMonetariaService:ListarOpcoesIndicesCorrecaoMonetariaService){}

  ngOnInit(){

    this.listarOpcoesIndicesCorrecaoMonetariaService.listarOpcoes()
    .then((opcoes:OpcoesIndicesCorrecaoMonetaria)=>{
      this.opcoesIndicesCorrecaoMonetaria = opcoes;
      
    });
  }

  openModal(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
        this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else {
        this.modalService.open(content,{ centered: true, size: 'lg' }).shown.subscribe(ref=>{

          this.dib = new Date(this.dib);
          this.novaRegraForm.get('dataInicial').setValue(new Date(this.dib.getFullYear(), this.dib.getMonth(), this.dib.getDate()));
          this.novaRegraForm.get('dataFinal').setValue(new Date());


          this.itemsSelectIndice = this.opcoesIndicesCorrecaoMonetaria.opcoesIndices.map((opcaoIndice:OpcaoIndice)=>({value: IdentificadorIndice[opcaoIndice.indice] , text: opcaoIndice.texto}));

          // var selectIndice: any = document.getElementById('selectIndice');
          // var options = { 
          //   defaultSelected: false,
          //   placeholder: 'Selecione uma opção',
          //   searchable: true,
          // };

          // var optionsNoSearch = { 
          //   defaultSelected: false,
          //   placeholder: 'Selecione uma opção',
          //   searchable: false,
          // };

          // var selectorIndice = new Selectr(selectIndice, options);
          // selectorIndice.add(this.opcoesIndicesCorrecaoMonetaria.opcoesIndices.map((opcaoIndice:OpcaoIndice)=>({value: IdentificadorIndice[opcaoIndice.indice].toString() , text: opcaoIndice.texto})));
        });
    }
  }

  selectItemIndicesChanges(select:NgxSelectOption){
    this.itemIndice = select[0].data.value;
    this.novaRegraForm.get('indice').setValue(this.opcoesIndicesCorrecaoMonetaria.opcoesIndices[Number.parseInt(select[0].data.value)]);
  }


  novaRegra(content){
    this.openModal(content, '', '');
  }
  submitNovaRegra(){
    let nova = new RegraCorrecaoMonetaria();
    
    nova.dataInicial = this.novaRegraForm.get('dataInicial').value;

    nova.dataFinal = this.novaRegraForm.get('dataFinal').value;
    // nova.indice = this.opcoesIndicesCorrecaoMonetaria.opcoesIndices[Number.parseInt(this.novaRegraForm.get('indice').value)].indice;
    // nova.indice = this.opcoesIndicesCorrecaoMonetaria.opcoesIndices.filter(opcao=>IdentificadorIndice[opcao.indice] === IdentificadorIndice[Number.parseInt(this.novaRegraForm.get('indice').value)])[0].indice;

    nova.indice = this.opcoesIndicesCorrecaoMonetaria.opcoesIndices.filter(opcao=> opcao.indice.toString() === IdentificadorIndice[this.itemIndice])[0].indice;
    

    this.adicionarNovaRegraEvent.emit(nova);
    this.modalService.dismissAll();
    

    
    
    
  }
  cancelarNovaRegra(){
    this.modalService.dismissAll();
  }

}
