import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EvolucaoSalariosPosRealComponent } from 'src/app/aplicacao/calculos/previdenciario/compartilhado/evolucao-salarios-pos-real/evolucao-salarios-pos-real.component';
import { MapaConsolidadoQualidadeSeguradoComponent } from 'src/app/aplicacao/calculos/previdenciario/compartilhado/mapa-consolidado-qualidade-segurado/mapa-consolidado-qualidade-segurado.component';
import { MapaQualidadeSeguradoComponent } from 'src/app/aplicacao/calculos/previdenciario/compartilhado/mapa-qualidade-segurado/mapa-qualidade-segurado.component';
import { RelatorioBeneficioComponent } from 'src/app/aplicacao/calculos/previdenciario/compartilhado/relatorio-beneficio/relatorio-beneficio.component';
import { ResultadoAnaliseCnisComponent } from 'src/app/aplicacao/calculos/previdenciario/compartilhado/resultado-analise-cnis/resultado-analise-cnis.component';
import { ResultadoQualidadeSeguradoComponent } from 'src/app/aplicacao/calculos/previdenciario/compartilhado/resultado-qualidade-segurado/resultado-qualidade-segurado.component';
import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';
import { CalculoAnaliseCnis } from 'src/app/modelos/previdenciario/calculoanalisecnis';
import { CalculoIncapacidadeTemporaria } from 'src/app/modelos/previdenciario/calculoincapacidadetemporaria';
import { CalculoMapaQualidadeSegurado } from 'src/app/modelos/previdenciario/calculomapaqualidadesegurado';
import { LeituraJsonBlobService } from 'src/app/servicos/relatorio/leitura-json-blob.service';


@Component({
  selector: 'app-relatorio-mapa-qualidade-segurado-consolidado',
  templateUrl: './relatorio-mapa-qualidade-segurado-consolidado.component.html',
  styleUrls: ['./relatorio-mapa-qualidade-segurado-consolidado.component.scss']
})
export class RelatorioMapaQualidadeSeguradoConsolidadoComponent  {

  // @ViewChild('relatorioBeneficio') relatorioBeneficio:RelatorioBeneficioComponent;
  @ViewChild('mapaConsolidado') mapaConsolidado:MapaConsolidadoQualidadeSeguradoComponent;
  calculo: CalculoMapaQualidadeSegurado;
  logoUrl: string;


  calculoRowKey: string;
  calculoPartitionKey: string;
  calculoUrl: string;

  resultadoCalculo: ResultadoCalculo;


  constructor(private route: ActivatedRoute,
    private leituraJsonBlobService: LeituraJsonBlobService,) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {


      this.calculoUrl = params.url;
      this.calculoRowKey = params.rowkey;
      this.calculoPartitionKey = params.partitionkey;

      let resultadoCalculo: ResultadoCalculo = new ResultadoCalculo();

      resultadoCalculo.rowKey = this.calculoRowKey;
      resultadoCalculo.partitionKey = this.calculoPartitionKey;


      let calculo: Calculo = new Calculo();
      calculo.rowKey = this.calculoRowKey;
      calculo.partitionKey = this.calculoPartitionKey;

      this.leituraJsonBlobService.ListarJsonBlob(this.calculoUrl, request => {

        // let casoResultado:CalculoPlanejamentoKertzman = request as CalculoPlanejamentoKertzman;
        // console.log(casoResultado);
        this.carregarDados(request as CalculoMapaQualidadeSegurado);
      });



    });
  }

  carregarDados(request: CalculoMapaQualidadeSegurado) {
    this.calculo = request;
    this.mapaConsolidado.calculoMapaQualidadeSegurado = this.calculo;
    this.mapaConsolidado.carregarDados();
    // this.relatorioBeneficio.carregarDados();

    // this.resultadoAnaliseCnis.carregarDados(this.calculo);
  }
}

