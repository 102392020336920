import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plano } from 'src/app/modelos/pagamento/plano';
import { CriarPlanoRequest } from 'src/app/modelos/safe2pay/criarplanorequest';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InserirPlanoService {

  constructor(private httpClient:HttpClient) { }

  inseriPlano(plano:Plano){

    return Promise.all([this.apicall(plano)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(plano:Plano){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/InserirPlano',plano, {'headers':headers}).toPromise();
    return data;
  }
}