import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CriarContaRequest } from 'src/app/modelos/usuario/criarcontarequest';
import { Recado } from 'src/app/modelos/usuario/recado';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../ui/config.service';

@Injectable({
  providedIn: 'root'
})
export class ExtenderPeriodoTesteService {

  constructor(private httpClient:HttpClient,
    private configService:ConfigService) { }

  extenderPeriodoTeste(){

    let request:Usuario;

    this.configService.getUsuario().subscribe(usuario=>request = usuario);

    return Promise.all([this.apicall(request)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(request:Usuario){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ExtenderPeriodoTeste',request, {'headers':headers}).toPromise();
    return data;
  }
}
