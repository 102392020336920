
import { Base } from "../base";
import { IdentificacaoFiliado } from "../previdenciario/identificacaofiliado";
import { Sexo } from "../previdenciario/sexo";
import { Usuario } from "./usuario";

export class Cliente extends Base{

    nome:string;
    dataNascimento:Date;
    sexo:Sexo;
    email:string;
    telefone:string;
    sobrenome:string;
    endereco:string;
    cidade:string;
    uf:string;
    cpf:string;
    usuario:Usuario;
    cep:string;
    pais:string;

}