import { Component, EventEmitter, Output } from '@angular/core';
import { Entrevista } from 'src/app/modelos/previdenciario/entrevista';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-listagem-entrevistas',
  templateUrl: './listagem-entrevistas.component.html',
  styleUrl: './listagem-entrevistas.component.scss'
})
export class ListagemEntrevistasComponent {

  @Output() entrevistaSelecionadaEvent = new EventEmitter<Entrevista>();
  entrevistas: Entrevista[];
  constructor(private configService: ConfigService, private calculeiApiService: CalculeiApiService) {

    let usuarioRequest: Usuario;
    this.configService.getUsuario().subscribe(usuario => usuarioRequest = usuario);

    this.calculeiApiService.api("ListarEntrevistas", usuarioRequest)
      .subscribe(entrevistas => {
        // console.log('resultado entrevistas', entrevistas);
        this.entrevistas = entrevistas as Entrevista[];
      });
  }


  selecionarEntrevista(entrevista:Entrevista){
    this.entrevistaSelecionadaEvent.emit(entrevista);

  }

}
