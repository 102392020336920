

<div class="col-auto px-0 text-center">
    <div [className]="aguarde ? 'visible' : 'invisible'">

        <!-- <div class="spinner-grow my-2" style="width: 2rem; height: 2rem;" role="status">
        </div> -->

        <div class="container">
            <div class="linha-crescendo"></div>
        </div>

    </div>
</div>