import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Marcador } from 'src/app/modelos/common/marcador';
import { Global } from 'src/app/modelos/global';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { RelatorioPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioplanejamentoprevidenciario';
import { StatusBeneficio } from 'src/app/modelos/previdenciario/statusbeneficio';
import { TipoBeneficio } from 'src/app/modelos/previdenciario/tipobeneficio';
import { FasesPlanejamento } from 'src/app/modelos/ui/fasesplanejamento';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { LogService } from 'src/app/servicos/log.service';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { ListarRelatorioPlanejamentoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-planejamento-pdf.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-sumario-resultado-planejamento',
  templateUrl: './sumario-resultado-planejamento.component.html',
  styleUrls: ['./sumario-resultado-planejamento.component.scss']
})
export class SumarioResultadoPlanejamentoComponent implements OnInit {

  @Input() calculoPlanejamentoPrevidenciario: CalculoPlanejamentoPrevidenciario;
  private statusBeneficioType = StatusBeneficio;


  casoDestaqueMenorInvestimentoMenorTempo: CasoPlanejamentoPrevidenciario;
  criterioMenorInvestimentoMenorTempo: string;

  casoDestaqueMenorInvestimentoRetornoMaisRapido: CasoPlanejamentoPrevidenciario;
  criterioMenorInvestimentoRetornoMaisRapido: string;

  casoDestaqueMenorTempo: CasoPlanejamentoPrevidenciario;
  criterioMenorTempo: string

  casoDestaqueMaiorRetorno: CasoPlanejamentoPrevidenciario;
  criterioMaiorRetorno: string

  casoDestaqueMaiorRetornoMenorTempo: CasoPlanejamentoPrevidenciario;
  criterioMaiorRetornoMenorTempo: string

  casoSelecionado: CasoPlanejamentoPrevidenciario;
  verCasoVisivel: boolean;

  // beneficioRelatorio:Beneficio;

  casosIdade: CasoPlanejamentoPrevidenciario[];
  casosTempoContribuicao: CasoPlanejamentoPrevidenciario[];

  casos: CasoPlanejamentoPrevidenciario[];

  marcadorCaso: Marcador;
  casoCanvas:CasoPlanejamentoPrevidenciario;

  aguarde:boolean;


  constructor(private notificacoesService: NotificacoesService,
    private configService:ConfigService,
    private calculeiApiService:CalculeiApiService,
    
    private listarPDFService: ListarPDFService) {
      this.configService.getAguarde().subscribe(aguarde=>this.aguarde=aguarde);
     }


  ngOnInit(): void {
    
  }



  public limparDados() {
    this.calculoPlanejamentoPrevidenciario = new CalculoPlanejamentoPrevidenciario();;
    this.calculoPlanejamentoPrevidenciario.casos = [];
  

  }

  public carregarDados(calculo: CalculoPlanejamentoPrevidenciario) {

    


    this.calculoPlanejamentoPrevidenciario = calculo;
    this.calculoPlanejamentoPrevidenciario.casos.sort((a, b) => a.beneficio.relatorioRendaMensalInicial.rendaMensalInicial - b.beneficio.relatorioRendaMensalInicial.rendaMensalInicial).reverse()[0].beneficio.maiorRMI = true;

    this.casos = this.calculoPlanejamentoPrevidenciario.casos;
    

    
    // this.casos = this.calculoPlanejamentoPrevidenciario.casos.filter(caso=>caso.enquadramento.base == this.calculoPlanejamentoPrevidenciario.opcoesPlanejamento.edicaoSalarioAliquota.salario);

    // this.beneficios = this.casos.map(caso => caso.beneficio);

    if (this.verCasoVisivel) {

      if (this.casos && this.casoSelecionado) {

        if (this.casos.filter(caso => caso.rowKey === this.casoSelecionado.rowKey).length > 0)
          this.marcadorCaso = this.casos.filter(caso => caso.rowKey === this.casoSelecionado.rowKey)[0].marcador;
      }

    }
    

    // this.verCaso(this.casos[0]);
  }

   gerarPDFSumario() {
    // console.log('pdf sumario');

    // this.aguardeVisivel = true;
    this.configService.setAguarde(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    this.calculoPlanejamentoPrevidenciario.tipoCalculo = CalculoTipos.SUMARIOSIMULACAORMI;
    this.calculoPlanejamentoPrevidenciario.usuario = Global.usuario;

    // console.log('calculobeneficios', this.calculoBeneficios);

    this.calculeiApiService.apiBlobLx("ListarPDF",this.calculoPlanejamentoPrevidenciario)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO-SUMARIO-SIMULACAO-RMI-' + this.calculoPlanejamentoPrevidenciario.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.configService.setAguarde(false);

      });
  }

  gerarPDFCaso(caso: CasoPlanejamentoPrevidenciario) {
    // console.log('pdf sumario');

    
    // this.configService.setAguarde(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    // this.calculoPlanejamentoPrevidenciario.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    // this.calculoPlanejamentoPrevidenciario.usuario = Global.usuario;
    caso.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    caso.usuario = Global.usuario;
    

    this.calculeiApiService.apiBlobLx("ListarPDF",caso)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO-'+ caso.beneficio.qualificacaoDetalhada +'-RESULTADOS-RMI-' + this.calculoPlanejamentoPrevidenciario.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.aguarde = false

      });

  }

  showCasoCanvas(caso:CasoPlanejamentoPrevidenciario){
    this.casoCanvas = caso;
  }


}
