
import { Marcador } from "./common/marcador";

export class Base{
    
    id:string;
    serializacao:string;
    partitionKey:string;
    rowKey:string;
    marcadores:Marcador[];
}