export enum PeriodoPlano {
    MENSAL,
    ANUAL
    
}

export class PeriodoPlanoEx {

    static periodoToString(value: string) {
        if(Number(value)){
            value = PeriodoPlano[value];
        }
        
        let periodoplano: PeriodoPlano = PeriodoPlano[value];
        let texto: string = "";
        switch (periodoplano) {
            case PeriodoPlano.MENSAL:
                texto = "mês";
                break;
            case PeriodoPlano.ANUAL:
                texto = "ano";
                break;
            
            default:
                break;

        }
        return texto;

    }
}