import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CalculosDashboardComponent } from './calculos-dashboard/calculos-dashboard.component';
import { ClientesDashboardComponent } from './clientes-dashboard/clientes-dashboard.component';
import { DashboardComponent } from './dashboard.component';
import { MatrizDashboardComponent } from './matriz-dashboard/matriz-dashboard.component';
import { EntrevistasDashboardComponent } from './entrevistas-dashboard/entrevistas-dashboard.component';

const routes: Routes = [
  {
    path:'',
    component:DashboardComponent,
    children:[
      {
        path:'',
        redirectTo:'calculos',
        pathMatch:'full'
      },
      {
        path:'calculos',
        component:CalculosDashboardComponent,
      },
      {
        path:'clientes',
        component:ClientesDashboardComponent,
      },
      {
        path:'matrizes',
        component:MatrizDashboardComponent,
      },
      {
        path:'entrevistas',
        component:EntrevistasDashboardComponent,
      },
    ]

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
