import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aposentadoria-idade',
  templateUrl: './aposentadoria-idade.component.html',
  styleUrls: ['./aposentadoria-idade.component.scss']
})
export class AposentadoriaIdadeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
