import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Global } from 'src/app/modelos/global';
import { Permissoes } from 'src/app/modelos/usuario/permissoes';
import { ConfigService } from '../ui/config.service';
import { AutenticacaoService } from './autenticacao.service';

@Injectable({
  providedIn: 'root'
})
export class AutorizacaoAdminContasService  {

  constructor(private autenticacaoService: AutenticacaoService, private router: Router, private configService:ConfigService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let permissao:boolean;
    this.configService.getUsuario()
    .subscribe(usuario=>{

      if (this.autenticacaoService.oauthService.hasValidAccessToken() && Global.usuario.permissao == Permissoes.Administrador) {
        permissao = true;
      }
      else{

        permissao = false;
        //this.router.navigate(['/']);
        // this.autenticacaoService.login();
      }

    });

    return permissao;
    
  }
}