import { ResultadoCalculo } from "../common/resultadocalculo";
import { Usuario } from "../usuario/usuario";
import { LiquidacaoSentencaPrevidenciario } from "./liquidacaosentencaprevidenciario";
import { RelatorioLiquidacaoSentencaoPrevidenciario } from "./relatorioliquidacaosentencaprevidenciario";

export class CalculoLiquidacaoSentencaPrevidenciario extends ResultadoCalculo{
    liquidacaoSentenca:LiquidacaoSentencaPrevidenciario;
    resumoLiquidacaoSentenca:LiquidacaoSentencaPrevidenciario;
    relatorioLiquidacaoSentenca:RelatorioLiquidacaoSentencaoPrevidenciario;
    usuario:Usuario;
}