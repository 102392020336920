


import { Location } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { createMask } from '@ngneat/input-mask';
import Stepper from 'bs-stepper';
import * as e from 'express';
import { Voucher } from 'src/app/modelos/common/voucher';
import { Global } from 'src/app/modelos/global';
import { MeiosPagamento } from 'src/app/modelos/pagamento/meiospagamento';
import { Plano } from 'src/app/modelos/pagamento/plano';
import { BuscaCepResponse } from 'src/app/modelos/safe2pay/buscacepresponse';
import { BuscaMunicipioResponse, BuscaMunicipioResponseMunicipio } from 'src/app/modelos/safe2pay/buscamunicipioresponse';
import { CriarAssinaturaData } from 'src/app/modelos/safe2pay/criarassinaturadata';
import { CriarAssinaturaRequest, CriarAssinaturaRequestAddress, CriarAssinaturaRequestCity, CriarAssinaturaRequestCustomer } from 'src/app/modelos/safe2pay/criarassinaturarequest';
import { CriarAssinaturaResponse } from 'src/app/modelos/safe2pay/criarassinaturaresponse';
import { CriarPlanoRequest } from 'src/app/modelos/safe2pay/criarplanorequest';
import { CriarPlanoResponse } from 'src/app/modelos/safe2pay/criarplanoresponse';
import { GerarTokenResponse } from 'src/app/modelos/safe2pay/gerartokenresponse';
import { ListarPlanosResponse } from 'src/app/modelos/safe2pay/listarplanosresponse';
import { Pagamento } from 'src/app/modelos/safe2pay/pagamento';
import { PagamentoPixDinamicoResponse } from 'src/app/modelos/safe2pay/pagamentopixdinamicoresponse';
import { PlanFrequence } from 'src/app/modelos/safe2pay/planfrequence';
import { PlanOption } from 'src/app/modelos/safe2pay/planoption';
import { TokenRequest } from 'src/app/modelos/safe2pay/tokenrequest';
import { PeriodoPlano } from 'src/app/modelos/usuario/periodoplano';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { BuscaCepService } from 'src/app/servicos/pagamento/safe2pay/busca-cep.service';
import { BuscaMunicipioService } from 'src/app/servicos/pagamento/safe2pay/busca-municipio.service';
import { CriarAssinaturaService } from 'src/app/servicos/pagamento/safe2pay/criar-assinatura.service';
import { GerarTokenService } from 'src/app/servicos/pagamento/safe2pay/gerar-token.service';
import { ListarPlanosService } from 'src/app/servicos/pagamento/safe2pay/listar-planos.service';
import { PixDinamicoService } from 'src/app/servicos/pagamento/safe2pay/pix-dinamico.service';
import { VerificarPagamentoPixDinamicoService } from 'src/app/servicos/pagamento/safe2pay/verificar-pagamento-pix-dinamico.service';

import { ConfigService } from 'src/app/servicos/ui/config.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pix-checkout',
  templateUrl: './pix-checkout.component.html',
  styleUrl: './pix-checkout.component.scss'
})
export class PixCheckoutComponent implements OnInit, AfterViewInit, AfterViewChecked {


  cartaoMask = createMask('9999 - 9999 - 9999 - 9999');
  codigoMask = createMask('999');
  validadeMask = createMask('99/9999');
  cepMask = createMask('99999-999');
  telefoneMask = createMask('99 99999-9999');
  cpfMask = createMask('999.999.999-99');
  cnpjMask = createMask('99.999.999/9999-99');


  aguardeVisivel: boolean;
  continuarCartaoHabilitado: boolean;
  continuarEnderecoHabilitado: boolean;
  continuarFiscalHabilitado: boolean;

  pixFormVisivel: boolean;
  cartaoFormVisivel: boolean;
  enderecoFormVisivel: boolean;
  fiscalFormVisivel: boolean;

  qrcodeUrl: string;


  cpfVisivel: boolean;


  municipio: BuscaMunicipioResponseMunicipio;
  cep: BuscaCepResponse;
  token: GerarTokenResponse;
  assinaturaRequest: CriarAssinaturaRequest;
  plano: Plano;
  planos: Plano[];
  assinaturaResponse: CriarAssinaturaResponse;




  private stepper: Stepper;
  // criarAssinaturaData: CriarAssinaturaData;
  tokenRequest: TokenRequest;
  customerRequest: CriarAssinaturaRequestCustomer;
  criarAssinaturaDataPix: CriarAssinaturaData;
  tipoDocumento: string;
  voucher: Voucher;
  criarPlanoRequest: CriarPlanoRequest;

  constructor(private location: Location,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private calculeiApiService: CalculeiApiService,
    private listarPlanosService: ListarPlanosService,
    private buscaCepService: BuscaCepService,
    private buscaMunicipioService: BuscaMunicipioService,
    private changeDetector: ChangeDetectorRef,
    private criarAssinaturaService: CriarAssinaturaService,
    private pixDinamicoService: PixDinamicoService,
    private verificarPagamentoPixDinamicoService: VerificarPagamentoPixDinamicoService,
    private router: Router,
    private configService: ConfigService,
    private fb: FormBuilder, private gerarTokenService: GerarTokenService) {


    // this.criarAssinaturaData = new CriarAssinaturaData();

    this.cartaoFormVisivel = false;
    this.enderecoFormVisivel = false;
    this.fiscalFormVisivel = false;

    this.cpfMask.unmaskAsNumber = true;
    this.cpfMask.autoUnmask = true;
    this.cnpjMask.unmaskAsNumber = true;
    this.cnpjMask.autoUnmask = true;

    this.cartaoMask.unmaskAsNumber = true;
    this.cartaoMask.autoUnmask = true;



    this.telefoneMask.unmaskAsNumber = true;
    this.telefoneMask.autoUnmask = true;

    this.cepMask.unmaskAsNumber = true;
    this.cepMask.autoUnmask = true;

    this.cepMask.oncomplete = (() => {

      let cep = this.customerRequest.address.zipCode;


      this.configService.setAguarde(true);
      this.changeDetector.detectChanges();

      this.buscaCepService.buscaCep(cep)
        .then(resultado => {
          // console.log('resultado cep', resultado)
          if (resultado && !resultado.hasOwnProperty('erro')) {

            //flow viacep api

            this.municipio = new BuscaMunicipioResponseMunicipio()
            this.cep = resultado as BuscaCepResponse;
            this.municipio.nome = this.cep.localidade
            this.municipio.codigo_ibge = this.cep.ibge;

            if (this.cep.neighborhood != "") {

              this.customerRequest.address.district = this.cep.bairro;
            }
            if (this.cep.street != "") {

              this.customerRequest.address.street = this.cep.logradouro;
            }
            if (this.cep.city != "") {

              this.customerRequest.address.city.cityName = this.cep.localidade;
            }


            this.customerRequest.address.city = new CriarAssinaturaRequestCity();
            this.customerRequest.address.city.cityName = this.cep.localidade;
            this.customerRequest.address.city.codeIBGE = this.cep.ibge;


            this.configService.setAguarde(false);
            this.changeDetector.detectChanges();

          } else {
            this.configService.setAguarde(false);
            Swal.fire({
              title: 'Não encontramos o CEP',
              text: 'Verifique o CEP e tente novamente',
              icon: 'error',
              iconColor: "black",
              showConfirmButton: false,


            }).then(() => { });

          }

        });
    });


    this.plano = new Plano();

    this.voucher = new Voucher();
    this.voucher.desconto = 0;
    this.configService.getUsuario().subscribe(usuario => this.voucher.usuario = usuario);


    this.customerRequest = new CriarAssinaturaRequestCustomer();
    this.customerRequest.address = new CriarAssinaturaRequestAddress();
    this.customerRequest.address.city = new CriarAssinaturaRequestCity();
    this.configService.getUsuario().subscribe(usuario=>this.customerRequest.email = usuario.email)



    this.assinaturaRequest = new CriarAssinaturaRequest();
    this.assinaturaRequest.paymentMethod = 6;
    this.assinaturaRequest.customer = this.customerRequest;
    this.configService.getUsuario().subscribe(usuario => this.assinaturaRequest.customer.email = usuario.email);


    this.configService.getUsuario().subscribe(usuario => this.assinaturaRequest.customer.name = usuario.nome + ' ' + usuario.sobrenome);
    this.assinaturaRequest.emails = [];
    this.assinaturaRequest.emails.push(this.assinaturaRequest.customer.email);


    this.tipoDocumento = "CPF";
    this.criarAssinaturaDataPix = new CriarAssinaturaData();
    this.configService.getUsuario().subscribe(usuario => this.criarAssinaturaDataPix.usuarioRowkey = usuario.rowKey);
    this.criarAssinaturaDataPix.assinaturaRequest = new CriarAssinaturaRequest();




  }


  proximoPagamentoCartao() {

    this.stepper.next();
  }
  voltarPagamentoPix() {
    this.stepper.previous();
  }

  aplicarVoucher() {
    this.configService.setAguarde(true)
    if (this.voucher.codigo != "") {
      this.calculeiApiService.api("BuscaVoucher", this.voucher)
        .subscribe(resultado => {
          this.configService.setAguarde(false);
          if (resultado) {

            //criar um novo plano com o voucher 
            this.voucher = resultado as Voucher;
            // console.log('resultado voucher', resultado);
            this.criarAssinaturaDataPix.voucher = this.voucher;

          } else {
            Swal.fire({
              title: 'Não encontramos o voucher',
              text: 'Verifique o voucher e tente novamente',
              icon: 'error',
              iconColor: "black",
              showConfirmButton: false,
            }).then(() => { });
          }
        });
    }
  }

  criarAssinatura() {

    this.customerRequest.address.number = this.customerRequest.address.number == "" ? this.customerRequest.address.number : "sn";
    this.assinaturaRequest.customer = this.customerRequest;

    this.criarAssinaturaDataPix.assinaturaRequest = this.assinaturaRequest;
    this.configService.getUsuario().subscribe(usuario => this.criarAssinaturaDataPix.usuarioRowkey = usuario.rowKey);
    this.criarAssinaturaDataPix.plano = this.plano;

    this.configService.setAguarde(true);
    this.calculeiApiService.api("PixDinamico", this.criarAssinaturaDataPix)
      .subscribe(resultadoAssinatura => {


        this.configService.setAguarde(false);
        // console.log('resultado pixdinamico', resultadoAssinatura);
        let resultado: PagamentoPixDinamicoResponse = resultadoAssinatura as PagamentoPixDinamicoResponse;

        if (!resultado.hasError) {


          this.qrcodeUrl = resultado.responseDetail.qrCode;
          this.changeDetector.detectChanges();


          this.verificarPagamentoPixDinamicoService.verificacao(resultado)
            .then(verificacao => {
              // console.log('verificacao finalizada', verificacao);
              Swal.fire({
                title: 'Tudo certo...',
                text: 'Seu pagamento foi confirmado e sua assinatura está pronta!',
                icon: 'success',
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: 'ok',
                confirmButtonColor: '#172b4d'

              }).then(() => {
                this.configService.setUsuarioAtualizarDaBase()
                  .then(() => {
                    this.router.navigateByUrl('/app/calculos/novocalculo');

                  });


              });
            });


          // Swal.fire({
          //   title: 'Tudo certo...',
          //   text: 'Seu pagamento foi confirmado e sua assinatura está pronta!',
          //   icon: 'success',
          //   showCloseButton: false,
          //   showConfirmButton: true,
          //   confirmButtonText: 'ok',
          //   confirmButtonColor: 'black'

          // }).then(() => {
          //   this.configService.setUsuarioAtualizarDaBase()
          //     .then(() => {
          //       this.router.navigateByUrl('/app/calculos/novocalculo');
          //     });
          // });
        } else {
          Swal.fire({
            title: 'Erro na assinatura',
            text: resultado.responseDetail.message,
            icon: 'error',
            iconColor: "black",
            showConfirmButton: false,
          }).then(() => { });
        }
      });
  }


  ngOnInit(): void {


    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {

      switch (event.detail.from) {
        case 0:
          // console.log('saindo passo cartao', event.detail);
          break;
        default:
          // console.log('saindo passo');
          break;
      }
    });

  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("planopix.json", request => {
      this.carregarDados(request as Plano);
      this.customerRequest.address.cityName = "são luís";
      this.customerRequest.address.stateInitials = "MA";
      this.customerRequest.address.countryName= "Brasil";
      this.customerRequest.address.city.codeIBGE = "2111300";
      this.customerRequest.address.street = "rua perdizes";
      // this.customerRequest.address.complement = "sem complemento";
      this.customerRequest.address.district = "renascença";
      this.customerRequest.address.number = "sn";
      this.customerRequest.address.zipCode = "65075340";

      this.customerRequest.identity= "01342408390";
      this.customerRequest.phone = "98992238113";
      this.customerRequest.name = "Felipe Reis"
       
      

      this.stepper.to(3);
    });

    // let planoPix: Plano = new Plano();
    // planoPix.idPlan = 0;
    // planoPix.nome = "Anual PIX";
    // planoPix.descricao = "Anual Pix";
    // planoPix.descricaoPagamento = "Pix";
    // planoPix.vantagensPagamento = "Praticidade Pix";
    // planoPix.valorOriginal = 470;
    // planoPix.desconto = 0;
    // planoPix.descontoMeioPagamento = 0;
    // planoPix.valor = 470;
    // planoPix.data = new Date();
    // planoPix.vencimento = new Date();
    // planoPix.periodo = PeriodoPlano.ANUAL;
    // planoPix.meioPagamento = MeiosPagamento.PIX;
    // planoPix.ativo = true;

    // this.plano = planoPix;

  }



  ngAfterViewInit(): void {

    if (this.location.getState().hasOwnProperty('valor')) {
      this.carregarDados(this.location.getState() as Plano);
    } else {
      if (!environment.production) {
        // this.mock();
      } else {
        this.router.navigateByUrl('/calculei/usuario/planos');
      }
      // this.mock();
      // this.pixFormVisivel = true;

    }


  }

  carregarDados(plano: Plano) {
    this.plano = plano;
    // console.log('plano selecionado', this.plano);

    switch (this.plano.meioPagamento) {
      case MeiosPagamento.CARTAOCREDITO:

        this.pixFormVisivel = false;
        this.cartaoFormVisivel = true;
        break;

      case MeiosPagamento.PIX:
        this.enderecoFormVisivel = true;
        this.cartaoFormVisivel = false;
        this.pixFormVisivel = false;
        break;

      default:
        break;
    }

  }
  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }




  montarCode() {
    switch (this.plano.meioPagamento) {

      case MeiosPagamento.PIX:

        // this.fiscalFormVisivel = false;
        // this.pixFormVisivel = true;


        // this.aguardeVisivel = true;
        // this.changeDetector.detectChanges();

        // this.assinaturaRequest = new CriarAssinaturaRequest();

        // this.assinaturaRequest.Token = this.token.ResponseDetail.Token;
        // this.assinaturaRequest.emails = [];
        // this.assinaturaRequest.emails.push(Global.usuario.email);
        // this.assinaturaRequest.customer = new CriarAssinaturaRequestCustomer();
        // this.assinaturaRequest.customer.address = new CriarAssinaturaRequestAddress();
        // this.assinaturaRequest.customer.address.city = new CriarAssinaturaRequestCity();
        // this.assinaturaRequest.customer.address.city.codeIBGE = this.municipio.codigo_ibge;
        // this.assinaturaRequest.customer.address.city.cityName = this.municipio.nome;
        // this.assinaturaRequest.customer.address.district = this.enderecoForm.get('bairro').value;//this.cep.neighborhood;
        // this.assinaturaRequest.customer.address.number = this.enderecoForm.get('numero').value;
        // this.assinaturaRequest.customer.address.street = this.enderecoForm.get('rua').value;//this.cep.street;
        // this.assinaturaRequest.customer.address.zipCode = this.cep.cep;
        // this.assinaturaRequest.customer.email = Global.usuario.email;
        // this.assinaturaRequest.customer.identity = this.fiscalForm.get('opcaoDocumento').value == 'CPF' ? this.fiscalForm.get('cpf').value : this.fiscalForm.get('cnpj').value;
        // this.assinaturaRequest.customer.name = Global.usuario.nome + " " + Global.usuario.sobrenome;
        // this.assinaturaRequest.customer.phone = this.fiscalForm.get('telefone').value;

        // Global.usuario.telefone = this.fiscalForm.get('telefone').value;
        // this.configService.setUsuarioDados(Global.usuario);





        // this.criarAssinaturaDataPix.assinaturaRequest = this.assinaturaRequest;
        // this.criarAssinaturaDataPix.usuarioRowkey = Global.usuario.rowKey;
        // this.criarAssinaturaDataPix.plano = this.plano;




        //if pix


        this.pixDinamicoService.pixDinamico(this.criarAssinaturaDataPix)
          .then((resultado: PagamentoPixDinamicoResponse) => {


            this.aguardeVisivel = false;


          });

        break;
      default:
        break;
    }
  }



}
