<div *ngIf="entrevista">
    <div class="row py-2">
        <div class="col-4 col-xs-12">

            <p class="h2">
                Cliente
            </p>
            <p class="text-default m-0 p-0">
                {{entrevista.cliente.nome}}
            </p>
            <p class="text-default h6 m-0 p-0">
                {{entrevista.cliente.sobrenome}}
            </p>
        </div>

        <div class="col-4 col-xs-12">

            <p class="h2">
                Ficha
            </p>
            <p class="text-default m-0 p-0">
                {{entrevista.ficha.nome}}
            </p>
            <p class="text-default h6 m-0 p-0">
                Total de perguntas {{entrevista.ficha.perguntas.length}}
            </p>
        </div>


        <div class="col-4 col-xs-12">

            <p class="h2">
                Validade
            </p>
            <p class="text-default m-0 p-0">
                {{entrevista.validade | date : 'dd/MM/yyyy'}}
            </p>
            <p class="text-default h6 m-0 p-0">
                Data limite para preenchimento
            </p>
        </div>


    </div>
</div>