<div *ngIf="calculoLiquidacaoSentencaPrevidenciario && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo">

    <div class="row g-4">
        <div class="col-12">
            <h5 class="clicavel h3 mb-0 linha-quadro">Atualização Monetária</h5>

        </div>
        <div class="col-12">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Critério de Correção Monetária
            </h5>
            <div class="dropdown">
                <button class="btn btn-default btn-lg dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloCorrecaoMonetaria | protocolosCorrecao}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li *ngFor="let tipo of itemsSelectProtocoloCorrecao">
                        <button class="dropdown-item" (click)="editarProtocoloCorrecao(tipo.value)">{{tipo.text}}</button>
                    </li>
                </ul>
            </div>
            <p class="h6">
                Indexadores para correção monetária
            </p>

            <!-- <div class=" w-100">
                <div>
                    <div class="row">
    
                        <div class="col-lg-12">
    
                            <div class="row py-4">
                                <div class="col-lg-12">
                                    <label class="form-control-label"
                                        for="selectCorrecaoMonetaria">Seleção do
                                        Critério de
                                        Indexadores</label>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-xl-12 col-lg-12 ">
                            <div
                                [className]="novaRegraCorrecaoVisivel ? 'visible py-4' : 'invisible'">
                                <app-nova-regra-atualizacao-monetaria
                                    [dataCalculo]="dataCalculo"
                                    [dib]="dib"
                                    (adicionarNovaRegraEvent)="adicionarNovaRegraCorrecao($event)"></app-nova-regra-atualizacao-monetaria>
                            </div>
                            <div class="dataTables_wrapper w-100 py-4">
    
                                <ngx-datatable
                                    class="material striped selection-cell"
                                    [columnMode]="'force'" [headerHeight]="50"
                                    [footerHeight]="'auto'" [rowHeight]="'auto'"
                                    [rows]="linhasRegrasCorrecao"
                                    [messages]="{emptyMessage: 'Use o botão para adicionar indexador por período', totalMessage: 'regras' }">
                                    <ngx-datatable-column name="Indexador"
                                        prop="indice">
    
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Data Inicial"
                                        prop="dataInicial">
                                        <ng-template let-value="value"
                                            let-rowIndex="rowIndex"
                                            ngx-datatable-cell-template>
    
                                            {{value | date : 'dd/MM/yyyy'}}
                                        </ng-template>
                                    </ngx-datatable-column>
    
                                    <ngx-datatable-column name="Data Final"
                                        prop="dataFinal">
                                        <ng-template let-value="value"
                                            let-rowIndex="rowIndex"
                                            ngx-datatable-cell-template>
    
                                            {{value | date : 'dd/MM/yyyy'}}
    
                                        </ng-template>
                                    </ngx-datatable-column>
    
                                </ngx-datatable>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div> -->
        </div>

        <div class="col-12">
            <h5 class=" card-title text-uppercase mb-0 text-default">
                Critério de Aplicação de Juros
            </h5>
            <div class="dropdown">
                <button class="btn btn-default btn-lg dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloAplicacaoJuros | protocolosJuros}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li *ngFor="let tipo of itemsSelectProtocoloJuros">
                        <button class="dropdown-item" (click)="editarProtocoloJuros(tipo.value)">{{tipo.text}}</button>
                    </li>
                </ul>
            </div>
            <p class="h6">
                Como e se serão aplicados juros
            </p>

            <!-- <div class="col-xl-12 col-lg-12 ">
    
                        <div
                            [className]="novaRegraJurosVisivel  ? 'visible py-4' : 'invisible'">
                            <app-nova-regra-aplicacao-juros
                                [dataCitacao]="dataCitacao"
                                [dataCalculo]="dataCalculo"
                                (adicionarNovaRegraJurosEvent)="adicionarRegraJuros($event)"></app-nova-regra-aplicacao-juros>
                        </div>
    
                        <div class="dataTables_wrapper w-100 py-4">
    
                            <ngx-datatable
                                class="material striped selection-cell"
                                [columnMode]="'force'" [headerHeight]="50"
                                [footerHeight]="'auto'" [rowHeight]="'auto'"
                                [rows]="linhasRegrasJuros"
                                [messages]="{emptyMessage: 'Use o botão para adicionar regra de juros por período', totalMessage: 'regras' }">
    
                                <ngx-datatable-column name="Indexador/Alíquota"
                                    prop="descricaoAliquotaIndexador">
                                    <ng-template let-value="value"
                                        let-rowIndex="rowIndex"
                                        ngx-datatable-cell-template>
                                        <span>{{value}}</span>
                                    </ng-template>
                                </ngx-datatable-column>
    
                                <ngx-datatable-column name="Data Inicial"
                                    prop="dataInicial">
                                    <ng-template let-value="value"
                                        let-rowIndex="rowIndex"
                                        ngx-datatable-cell-template>
    
                                        {{value | date : 'dd/MM/yyyy'}}
    
    
                                    </ng-template>
                                </ngx-datatable-column>
    
                                <ngx-datatable-column name="Data Final"
                                    prop="dataFinal">
                                    <ng-template let-value="value"
                                        let-rowIndex="rowIndex"
                                        ngx-datatable-cell-template>
    
                                        {{value | date : 'dd/MM/yyyy'}}
    
    
    
                                    </ng-template>
                                </ngx-datatable-column>
    
                            </ngx-datatable>
                        </div>
                    </div> -->
        </div>

    </div>

</div>