import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Entrevista } from 'src/app/modelos/previdenciario/entrevista';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-acoes-entrevista',
  templateUrl: './acoes-entrevista.component.html',
  styleUrl: './acoes-entrevista.component.scss'
})
export class AcoesEntrevistaComponent {

  @Input() entrevista:Entrevista;
  link:string;

  constructor(private configService:ConfigService, private calculeiApiService:CalculeiApiService, private router:Router,
    private clipboard:Clipboard
  ){}

  gerarLink(){

    // console.log('gerar link entrevista', this.entrevista);
    // console.log(environment.calculeiHost + 'entrevistas/sala?id=' + this.entrevista.id);
    this.link = environment.calculeiHost + 'entrevistas/sala?id=' + this.entrevista.id;
    // this.clipboard.copy(this.link);

    
  }

  testarEntrevista(){
    // this.router.navigateByUrl("/entrevistas/sala?id=" + this.entrevista.id);
    window.open(environment.calculeiHost + 'entrevistas/sala?id=' + this.entrevista.id, '_blank');

  }

  clipboardLink(){
    this.link = environment.calculeiHost + 'entrevistas/sala?id=' + this.entrevista.id;
    this.clipboard.copy(this.link);

  }

  
}
