import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { LeituraJsonBlobService } from 'src/app/servicos/relatorio/leitura-json-blob.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-relatorio-tabela-matriz',
  templateUrl: './relatorio-tabela-matriz.component.html',
  styleUrl: './relatorio-tabela-matriz.component.scss'
})
export class RelatorioTabelaMatrizComponent implements OnInit{

  calculoMatrizPlanejamento:MatrizPlanejamento;

  tetoInss:number = 0;
  sm:number = 0;

  calculoBeneficios: CalculoBeneficios;
  logoUrl: string;
  calculoRowKey: string;
  calculoPartitionKey: string;
  calculoUrl: string;

  resultadoCalculo: ResultadoCalculo;

  


  constructor(private route: ActivatedRoute,
    private listarDadosArquivoDataService:ListarDadosArquivoDataService,
    private leituraJsonBlobService: LeituraJsonBlobService) { }

  ngOnInit(): void {

    if(!environment.production){
      // this.mock();
      // return;
    }

    this.route.queryParams.subscribe(params => {


      this.calculoUrl = params.url;
      this.calculoRowKey = params.rowkey;
      this.calculoPartitionKey = params.partitionkey;

      let resultadoCalculo: ResultadoCalculo = new ResultadoCalculo();

      resultadoCalculo.rowKey = this.calculoRowKey;
      resultadoCalculo.partitionKey = this.calculoPartitionKey;


      let calculo: Calculo = new Calculo();
      calculo.rowKey = this.calculoRowKey;
      calculo.partitionKey = this.calculoPartitionKey;
      calculo.tipo = CalculoTipos.TABELAMATRIZ;


      this.leituraJsonBlobService.ListarJsonBlob(this.calculoUrl, request => {

        // let casoResultado:CalculoPlanejamentoKertzman = request as CalculoPlanejamentoKertzman;
        // console.log(casoResultado);
        this.carregarDados(request as MatrizPlanejamento);

        
      });



    });

   
  }

  carregarDados(request: MatrizPlanejamento) {
    this.calculoMatrizPlanejamento = request;
  }

  mock(){
    this.listarDadosArquivoDataService.ListarArquivo("calculomatriz.json", request => {
      this.calculoMatrizPlanejamento = request as MatrizPlanejamento;
      
    });
  }




  

  


}
