import { CamposMatriz } from './../../../../../modelos/previdenciario/camposmatriz';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Marcador } from 'src/app/modelos/common/marcador';
import { Global } from 'src/app/modelos/global';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { FiltrosMatriz } from 'src/app/modelos/previdenciario/filtrosmatriz';
import { ListaRegras } from 'src/app/modelos/previdenciario/listaregras';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { StatusBeneficio } from 'src/app/modelos/previdenciario/statusbeneficio';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-resultado-matriz',
  templateUrl: './resultado-matriz.component.html',
  styleUrl: './resultado-matriz.component.scss'
})
export class ResultadoMatrizComponent implements OnInit, OnChanges {

  @Input() calculoMatrizPlanejamento: MatrizPlanejamento;
  @Output() atualizacaoMatrizEvent = new EventEmitter<MatrizPlanejamento>();

  tetoInss: number;
  sm: number;

  carregando: boolean;

  filtrosForm = this.fb.group({
    filtroRegraIdadeTransicao: [true],
    filtroRegraPedagio50: [true],
    filtroRegraPedagio100: [true],
    filtroRegraIdadeMinimaProgressiva: [true],
    filtroRegraPontos: [true],
    filtroAliquota11: [true],
    filtroAliquota20: [true],
    filtroSalarioMinimo: [true],
    filtroSalarioTeto: [true],
  });

  camposForm = this.fb.group({
    campoAliquotaSalario: [true],
    campoDataAposentadoria: [true],
    campoRegra: [true],
    campoValorBeneficio: [true],
    campoTotalContribuicoes: [true],
    campoValorContribuicao: [true],
    campoTotalInvestimento: [true],
    campoRoiAcumulado: [true],
    campoTaxaRetorno: [true],
    campoPayback: [true],
    campoGanhoRoi: [true],
    campoPercentualTeto: [true],

  });


  constructor(private notificacoesService: NotificacoesService,
    private fb: FormBuilder,
    private configService: ConfigService,
    private changeDetector: ChangeDetectorRef,
    private calculeiApiService: CalculeiApiService,
    private listarPDFService: ListarPDFService) {


      this.configService.getAguarde().subscribe(status=>this.carregando = status && !(this.calculoMatrizPlanejamento.casos.length > 0));

    this.configService.getSalarioTeto().subscribe(teto => this.tetoInss = teto);
    this.configService.getSalarioMinimo().subscribe(sm => this.sm = sm);









    this.camposForm.get('campoAliquotaSalario').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.aliquotaSalario = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoDataAposentadoria').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.dataAposentadoria = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoRegra').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.regra = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoValorBeneficio').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.valorBeneficio = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoTotalContribuicoes').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.totalContribuicoes = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoValorContribuicao').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.valorContribuicao = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoTotalInvestimento').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.totalInvestimento = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoRoiAcumulado').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.roiAcumulado = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoTaxaRetorno').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.taxaRetorno = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoPayback').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.payback = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoGanhoRoi').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.ganhoRoi = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoPercentualTeto').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.percentualTeto = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });




    this.filtrosForm.get('filtroAliquota11').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.aliquota11 = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.aliquotaContributiva == 0.11) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });

    this.filtrosForm.get('filtroAliquota20').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.aliquota20 = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.aliquotaContributiva == 0.20) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });



    this.filtrosForm.get('filtroRegraPontos').valueChanges.subscribe(change => {
      // console.log('regra pontos valuechanges');
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraPontos = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA1TEMPOCONTRIBUICAOPONTOS]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });

    this.filtrosForm.get('filtroRegraIdadeMinimaProgressiva').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraIdadeMinimaProgressiva = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA2TEMPOCONTRIBUICAOIDADEPROGRESSIVA]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });


    this.filtrosForm.get('filtroRegraPedagio50').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraPedagio50 = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA3TEMPOCONTRIBUICAOPEDAGIO50]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });


    this.filtrosForm.get('filtroRegraPedagio100').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraPedagio100 = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA4TEMPOCONTRIBUICAOPEDAGIO100]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });


    this.filtrosForm.get('filtroRegraIdadeTransicao').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraIdadeTransicao = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA5IDADETRANSICAO]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });





  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log('onchanges resultado matriz', this.calculoMatrizPlanejamento);
  }

  ordenarAlpha(reverse: boolean) {
    this.calculoMatrizPlanejamento.casos = this.calculoMatrizPlanejamento.casos.sort((a, b) => {
      let textA = a.referenciaMatriz.toLowerCase();
      let textB = b.referenciaMatriz.toLowerCase();
      return reverse ? (textA > textB) ? -1 : (textA < textB) ? 1 : 0 : (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }

  ordernarValor(reverse: boolean) {
    this.calculoMatrizPlanejamento.casos = this.calculoMatrizPlanejamento.casos.sort((a, b) => reverse ? a.valorBeneficio - b.valorBeneficio : b.valorBeneficio - a.valorBeneficio)
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }

  marcadoresExibir(toogle: boolean) {
    this.calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores = !this.calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores;
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }

  ngOnInit(): void {

  }

  marcarTodosFiltros() {
    Object.keys(this.filtrosForm.controls).forEach((key) => {
      const control = this.filtrosForm.get(key);
      if (control) {
        control.setValue(true);
        //or if we wanted to change the value
        //control.setValue(value);
      }
    });
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }

  limparTodosFiltros() {
    Object.keys(this.filtrosForm.controls).forEach((key) => {
      const control = this.filtrosForm.get(key);
      if (control) {
        control.setValue(false);

        //or if we wanted to change the value
        //control.setValue(value);
      }
    });
  }
  marcarTodosCampos() {
    Object.keys(this.camposForm.controls).forEach((key) => {
      const control = this.camposForm.get(key);
      if (control) {
        control.setValue(true);
        //or if we wanted to change the value
        //control.setValue(value);
      }
    });
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }
  limparTodosCampos() {
    Object.keys(this.camposForm.controls).forEach((key) => {
      const control = this.camposForm.get(key);
      if (control) {
        control.setValue(false);
        //or if we wanted to change the value
        //control.setValue(value);
      }
    });
  }

  pdfMatriz() {

    // console.log('pdf matriz', this.calculoMatrizPlanejamento);

    // this.aguardeVisivel = true;
    this.configService.setAguarde(true);
    // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    // this.calculoPlanejamentoPrevidenciario.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    // this.calculoPlanejamentoPrevidenciario.usuario = Global.usuario;
    this.calculoMatrizPlanejamento.tipoCalculo = CalculoTipos.TABELAMATRIZ;
    this.configService.getUsuario().subscribe(usuario => this.calculoMatrizPlanejamento.usuario = usuario);

    this.calculeiApiService.apiBlobLx("ListarPDF", this.calculoMatrizPlanejamento)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'Matriz-Planejamento' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.configService.setAguarde(false);

      });

    // this.listarPDFService.ListarPDF(this.calculoMatrizPlanejamento)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'Matriz-Planejamento' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

    //     // this.aguardeVisivel = false;
    //     this.configService.setAguarde(false);

    //   });



  }

  gerarPDFCaso(caso: CasoPlanejamentoPrevidenciario) {
    // console.log('pdf sumario');

    // this.aguardeVisivel = true;
    this.configService.setAguarde(true);
    // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    // this.calculoPlanejamentoPrevidenciario.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    // this.calculoPlanejamentoPrevidenciario.usuario = Global.usuario;
    caso.tipoCalculo = CalculoTipos.CASOPLANEJAMENTO;
    caso.usuario = Global.usuario;

    this.listarPDFService.ListarPDF(caso)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RELATORIO-' + caso.beneficio.qualificacaoDetalhada + '-RESULTADOS-RMI-' + this.calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        // this.aguardeVisivel = false;
        this.configService.setAguarde(false);

      });

  }



  atualizarMatriz(matriz: MatrizPlanejamento) {
    this.atualizacaoMatrizEvent.emit(matriz);
  }





}
