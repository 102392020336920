import { AplicacoesAliquota } from "./aplicacoesaliquota";
import { PerfisContributivos } from "./perfiscontributivos";

export class EdicaoSalarioAliquota {
    salarioPiso:number;
    salarioTeto:number;
    aliquotas:number[];
    salario:number;
    aliquota:number;
    aplicacaoAliquota:AplicacoesAliquota;
    perfilContributivo:PerfisContributivos;
}