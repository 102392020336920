<div *ngIf="opcoesMatriz && opcoesMatriz.listaAliquotas">
    <h5 class=" h3 mb-0 linha-quadro">Salários</h5>

<div class="py-2">
    <div class="row">
        <div class="col-auto">

            <div class="dropdown">
        
                <div data-bs-toggle="dropdown" aria-expanded="false" role="button">
                    <div class="row">
                        <div class="col-auto text-center">
                            <i class="comando bi bi-plus-square-dotted fs-5"></i>
                            <p class="h6">
                                Incluir salário
                            </p>
                        </div>
                    </div>

                </div>

                <div #dropdownMenu class="dropdown-menu">
                    <li><a class="dropdown-item" role="button" (click)="selecionarMultiploSalarioMinimo(1)">Salário mínimo</a></li>
                    <li><a class="dropdown-item" role="button" (click)="selecionarSalarioTeto()">Teto do INSS</a></li>
                    <li><a class="dropdown-item" role="button" (click)="selecionarMultiploSalarioMinimo(2)">2x o salário mínimo</a></li>
                    <li><a class="dropdown-item" role="button" (click)="selecionarMultiploSalarioMinimo(3)">3x o salário mínimo</a></li>

                    <div class="container">
                        <div class="col-12 py-2">
                            <h5 class=" card-title text-uppercase mb-0 text-default">
                                Outro valor
                            </h5>
                            <input [inputMask]="currencyInputMask" [(ngModel)]="novoSalario" placeholder="R$ 0,00" class=" form-control text-left" #inputTitulo type="text" />
                            <p class="h6">
                                Salário de contribuição
                            </p>
                        </div>
                        <div class="col-12 text-right py-2">
                            <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddSalario()"></i>
                            <i class="comando bi bi-arrow-right fs-4" role="button" (click)="addSalario()"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col">

            <div class="row py-2">
                <p>
                    Para cada salário incluído, são calculados cenários de aposentadoria com as combinações possíveis entre as regras de transição, alíquotas e rentabilidade de capitalização privada.<br> Salários incluídos:
                </p>
            </div>
            <div class="row">
                <ng-container *ngFor="let salario of opcoesMatriz.listaSalarios">
                    <div class="col-3 item">
                        <div class="row p-2">
                            <div class="col-12 bg-secondary rounded py-4">
                                <div class="row">
                                    <div class="col-auto">
                                        <p *ngIf="salario == salarioMinimo" class="h2">
                                            Salário mínimo {{salario | currency}}
                                        </p>
                                        <p *ngIf="salario == tetoInss" class="h2">
                                            Teto do INSS {{salario | currency}}
                                        </p>
                                        
                                        <p *ngIf="salario != salarioMinimo && salario != tetoInss" class="h2">
                                            {{salario | currency}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-auto excluir-item">
                                        <i class="bi bi-trash" role="button" (click)="excluirSalario(salario)"></i>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>


    
    

</div>

<h5 class=" h3 mb-0 linha-quadro">Alíquotas</h5>

<div class="py-2">

    <div class="row">
        <div class="col-auto">
            <div class="dropdown">

                <div data-bs-toggle="dropdown" aria-expanded="false" role="button">

                    <div class="row">
                        <div class="col-auto text-center">
                            <i class="comando bi bi-plus-square-dotted fs-5"></i>
                            <p class="h6">
                                Incluir alíquota
                            </p>
                        </div>
                    </div>

                </div>

                <div #dropdownMenu class="dropdown-menu">
                    <li><a class="dropdown-item" role="button" (click)="selecionarAliquota(0.05)">5 %</a></li>
                    <li><a class="dropdown-item" role="button" (click)="selecionarAliquota(0.11)">11 %</a></li>
                    <li><a class="dropdown-item" role="button" (click)="selecionarAliquota(0.20)">20 %</a></li>
                    <li><a class="dropdown-item" role="button" (click)="selecionarAliquota(0.31)">31 %</a></li>

                    <div class="container">
                        <div class="col-12 py-2">
                            <h5 class=" card-title text-uppercase mb-0 text-default">
                                Outro valor
                            </h5>
                            <input [inputMask]="jurosInputMask" [(ngModel)]="novaAliquota" placeholder="00 %" class=" form-control text-left" #inputTitulo type="text" />
                            <p class="h6">
                                Alíquota
                            </p>
                        </div>
                        <div class="col-12 text-right py-2">
                            <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddAliquota()"></i>
                            <i class="comando bi bi-arrow-right fs-4" role="button" (click)="addAliquota()"></i>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div class="col">
            <div class="row py-2">
                <p>
                    Para cada alíquota incluída, são calculados cenários de aposentadoria com as combinações possíveis dos salários incluídos e regras de transição.<br> Alíquotas incluídas:
                </p>
            </div>
        
            <div class="row">
                <ng-container *ngFor="let aliquota of opcoesMatriz.listaAliquotas">
                    <div class="col-3 item">
                        <div class="row p-2">
                            <div class="col-12 bg-secondary rounded py-4">
                                <div class="row">
                                    <div class="col-auto">
                                        <p class="h2">
                                            {{aliquota * 100 | number : '1.0-0'}}%
                                        </p>
                                    </div>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-auto excluir-item">
                                        <i class="bi bi-trash" role="button" (click)="excluirAliquota(aliquota)"></i>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                </ng-container>
            </div>
            
        </div>
    </div>

    
    

</div>

<h5 class=" h3 mb-0 linha-quadro">Produtos Financeiros</h5>

<div class="py-2">

    <div class="row">
        <div class="col-auto">

            <div class="dropdown">

                <div data-bs-toggle="dropdown" aria-expanded="false" role="button">
                    <div class="row">
                        <div class="col-auto text-center">
                            <i class="comando bi bi-plus-square-dotted fs-5"></i>
                            <p class="h6">
                                Incluir <br>rentabilidade<br> anual
                            </p>
                        </div>
                    </div>
                </div>

                <div #dropdownMenu class="dropdown-menu">
                    <li>
                        <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.13)">
                            13 %
                        </button>
                    </li>
                    <li>
                        <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.14)">
                            14 %
                        </button>
                    </li>
                    <li>
                        <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.15)">
                            15 %
                        </button>
                    </li>

                    <div class="container">
                        <div class="col-12 py-2">
                            <h5 class=" card-title text-uppercase mb-0 text-default">
                                Outro valor
                            </h5>
                            <input [inputMask]="jurosInputMask" [(ngModel)]="novaRentabilidade" placeholder="00 %" class=" form-control text-left" #inputTitulo type="text" />
                            <p class="h6">
                                Rentabilidade Anual
                            </p>
                        </div>
                        <div class="col-12 text-right py-2">
                            <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddRentabilidade()"></i>
                            <i class="comando bi bi-arrow-right fs-4" role="button" (click)="addRentabilidade()"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="row py-2">
                <p>
                    Para cada taxa de rentabilidade de capitalização incluída, é calculado o comparativo entre os cenários calculados pelas combinações de salários e alíquotas, em cada regra de transição.<br> Taxas de rentabilidade incluídas:
                </p>
            </div>
        
            <div class="row">
                <ng-container *ngFor="let rentabilidade of opcoesMatriz.listaRentabilidades">
                    <div class="col-3 item">
                        <div class="row p-2">
                            <div class="col-12 bg-secondary rounded py-4">
                                <div class="row">
                                    <div class="col-auto">
                                        <p class="h2">
                                            {{rentabilidade * 100 | number : '1.0-0'}} %
                                        </p>
                                    </div>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-auto excluir-item">
                                        <i class="bi bi-trash" role="button" (click)="excluirRendimento(rentabilidade)"></i>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                </ng-container>
            </div>
            
            
        </div>
    </div>

    

</div>

<h5 class=" h3 mb-0 linha-quadro">Investimento</h5>

<div class="py-4">
    <div class="row">
        <div class="col">
            <p>
                Calcular o imposto de renda das contribuições 
            </p>
            <div class="form-group mb-3 py-2">
                <div class="form-inline d-flex align-items-center">
                    <div class="btn-group w-100" btnRadioGroup (ngModelChange)="aplicarCustoImpostoRenda($event)" [(ngModel)]="opcoesMatriz.aplicarCustoImpostoRenda">
                        <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Não considerar</label>
                        <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Considerar custo de IR</label>

                    </div>
                </div>
            </div>
        </div>

        <div class="col">
            <p>
                Encontrar o melhor investimento pelo método Kertzman de planejamento contributivo previdenciário
            </p>
            <div class="form-group mb-3 py-2">
                <div class="form-inline d-flex align-items-center">
                    <div class="btn-group w-100" btnRadioGroup (ngModelChange)="aplicarOtimizacao($event)" [(ngModel)]="opcoesMatriz.otimizacaoKertzman">
                        <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Não otimizar</label>
                        <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Aplicar otimização Método Kertzman</label>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-editor-enquadramento-contributivo
    #enquadramentoContributivo
    *ngIf="opcoesMatriz"
    [opcoesPlanejamento]="opcoesMatriz.opcoesPlanejamento[0]"
    (atualizarOpcoesEvent)="opcoesPlanejamentoAtualizadas($event)">
</app-editor-enquadramento-contributivo> -->
</div>