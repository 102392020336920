import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { NgxSelectOption } from 'ngx-select-ex';
import { filter } from 'rxjs';

import { AppComponent } from 'src/app/app.component';
import { Global } from 'src/app/modelos/global';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import { ReferenciaLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/referencialiquidacaosentencaprevidenciario';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { ListarClienteService } from 'src/app/servicos/usuario/listar-cliente.service';

@Component({
  selector: 'app-referencia-liquidacao-sentenca-previdenciario',
  templateUrl: './referencia-liquidacao-sentenca-previdenciario.component.html',
  styleUrls: ['./referencia-liquidacao-sentenca-previdenciario.component.scss']
})
export class ReferenciaLiquidacaoSentencaPrevidenciarioComponent implements OnInit {



  @Input() calculoLiquidacaoSentencaPrevidenciario: CalculoLiquidacaoSentencaPrevidenciario;

  numeroProcessoMask = createMask('9999999-99.9999.9.99.9999');
  cpfMask = createMask('999.999.999-99');
  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  tiposProcessoType = TiposProcessoLiquidacaoSentencaPrevidenciario;

  itemsTipoProcesso: any[];



  constructor(private listarClienteService: ListarClienteService, private changeDetector: ChangeDetectorRef) {

    this.itemsTipoProcesso = (Object.keys(TiposProcessoLiquidacaoSentencaPrevidenciario) as Array<keyof typeof TiposProcessoLiquidacaoSentencaPrevidenciario>)
      .filter(chave => isNaN(Number(chave)))
      .map(tipo => {
        return { value: tipo, text: this.textoTipoProcesso(TiposProcessoLiquidacaoSentencaPrevidenciario[tipo]) }
      });
  }

  ngOnInit(): void {
  }



  carregarDados(calculo: CalculoLiquidacaoSentencaPrevidenciario) {
  }


  textoTipoProcesso(tipo: TiposProcessoLiquidacaoSentencaPrevidenciario): string {
    let texto = '';

    switch (tipo) {
      case TiposProcessoLiquidacaoSentencaPrevidenciario.CONCESSAO:
        texto = 'Concessão';
        break;
      case TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO:
        texto = 'Revisão';
        break;
      case TiposProcessoLiquidacaoSentencaPrevidenciario.REESTABELECIMENTO:
        texto = 'Reestabelecimento';
        break;
      default:
        break;
    }
    return texto;
  }


  editarTipoProcesso(tipo: TiposProcessoLiquidacaoSentencaPrevidenciario) {
    // console.log('editar tipo processo liquidacao', tipo);
    this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso = tipo;
  }

}
