import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CriarAssinaturaData } from 'src/app/modelos/safe2pay/criarassinaturadata';
import { CriarPlanoRequest } from 'src/app/modelos/safe2pay/criarplanorequest';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CriarAssinaturaService {

  constructor(private httpClient:HttpClient) { }

  criarAssinatura(request:CriarAssinaturaData){

    return Promise.all([this.apicall(request)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(request:CriarAssinaturaData){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    let data;
    try {
      data = await this.httpClient.post(environment.api as string + 'api/CriarAssinatura',request, {'headers':headers}).toPromise();
      
    } catch (error) {
      
    }
    return data;
  }
}