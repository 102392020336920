
  <div class="row area-top-md">
    <div class="col-md-12">

      <div class="row">
        <div class="col-md-12 bg-lighter">
          <div class="row">
            <div class="col-md-4">
              <img src="assets/img/theme/logo-dark.png" width="100" class="area "/>
            </div>
            <div class="col-md-8 text-right">
              <h1 class="display-5 area-top-md">Calculei Advogados Associados</h1>
              <span >Rua perdizes, n. 10, Jd. Renascença, São Luís - MA</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center area-top-lg">
          <h1 class="display-1">Relatório de Renda Mensal Inicial</h1>
        </div>
          
      </div>
      <div class="row area-top-lg">
        <div class="col-md-12">
          <h1 class="display-4">Assistido</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="row area-top-sm">
            <div class="col-md-6">
              <span>Nome: </span>
              <span>{{relatorioRendaMensalInicial.cnis.identificacaoFiliado.nome}}</span>
            </div>
            <div class="col-md-6">
              <span>CPF: </span>
              <span>{{relatorioRendaMensalInicial.cnis.identificacaoFiliado.cpf}}</span>
            </div>
          </div>
          <div class="row area-top-sm">
            <div class="col-md-6">
              <span>NIT: </span>
              <span>{{relatorioRendaMensalInicial.cnis.identificacaoFiliado.nit}}</span>
            </div>
            <div class="col-md-6">
              <span>Data do Extrato Previdenciário: </span>
              <span>{{relatorioRendaMensalInicial.cnis.identificacaoFiliado.dataExtratoPrevidenciario | date : 'dd/MM/yyyy'}}</span>
            </div>
          </div>
          <div class="row area-top-sm">
            <div class="col-md-6">
              <span>Data Nascimento: </span>
              <span>{{relatorioRendaMensalInicial.cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}</span>
            </div>
            <div class="col-md-6">
              <span>Idade: </span>
              <span>{{relatorioRendaMensalInicial.cnis.identificacaoFiliado.idade.porExtenso}}</span>
            </div>
          </div>
          <div class="row area-top-sm">
            <div class="col-md-6">
              <span>Nome da Mãe: </span>
              <span>{{relatorioRendaMensalInicial.cnis.identificacaoFiliado.nomeMae}}</span>
            </div>
            <div class="col-md-6">
              <span>Sexo: </span>
              <span>{{relatorioRendaMensalInicial.cnis.identificacaoFiliado.sexo}}</span>
            </div>
          </div>
          <div class="row area-top-sm">
            <div class="col-md-12">
              <hr/>
          </div>
        </div>

      </div>
      <div class="col-md-12">
        <div class="row area-top-sm">
          <div class="col-md-4">
            <span>Data do Cálculo: </span>
            <span>{{relatorioRendaMensalInicial.dataCalculo | date : 'dd/MM/yyyy'}}</span>
          </div>
          <div class="col-md-4">
            <span>Benefício: </span>
            <span>{{relatorioRendaMensalInicial.beneficio}}</span>
          </div>
          <div class="col-md-4">
            <span>Tempo de Contribuição: </span>
            <span>{{relatorioRendaMensalInicial.tempoContribuicao}}</span>
          </div>
        </div>
      </div>


      <div class="col-md-12">
        <div class="row area-top-sm">
          <!-- <div class="col-md-4">
            <span>PBC Considerado </span>
            <span>{{relatorioRendaMensalInicial.pbc}}/{{relatorioRendaMensalInicial.pbCConsiderados}}</span>
          </div> -->
          <div class="col-md-4">
            <span>Soma dos Salários: </span>
            <span>{{relatorioRendaMensalInicial.somaSalarios | currency }}</span>
          </div>
          <div class="col-md-4">
            <span>Somatório de Salários (Atualizado): </span>
            <span>{{relatorioRendaMensalInicial.somaSalariosAtualizados | currency }}</span>
          </div>
        </div>
      </div>


      <div class="col-md-12">
        <div class="row area-top-sm">
          <div class="col-md-4">
            <span>Média Salarial: </span>
            <span>{{relatorioRendaMensalInicial.mediaSalarios | currency }}</span>
          </div>
          <div class="col-md-4">
            
          </div>
          <div class="col-md-4">
            <span>Média Salarial (Atualizada): </span>
            <span>{{relatorioRendaMensalInicial.mediaSalariosAtualizados | currency }}</span>
          </div>
        </div>
      </div>


      <div class="col-md-12">
        <div class="row area-top-sm">
          <div class="col-md-4">
            <span>Fator Previdenciário: </span>
            <span>{{relatorioRendaMensalInicial.fatorPrevidenciario}}</span>
          </div>
          <div class="col-md-4">
            
          </div>
          <div class="col-md-4">
            <span>Salário de Benefício: </span>
            <span>{{relatorioRendaMensalInicial.salarioBeneficio | currency}}</span>
          </div>
        </div>
      </div>



      <div class="col-md-12">
        <div class="row area-top-sm">
          <div class="col-md-4">
            <span>Coeficiente: </span>
            <span>{{relatorioRendaMensalInicial.coeficiente}}</span>
          </div>
          <div class="col-md-4">
            
          </div>
          <div class="col-md-4">
            <span>Renda Mensal Inicial: </span>
            <span>{{relatorioRendaMensalInicial.rendaMensalInicial | currency}}</span>
          </div>
        </div>
      </div>


      
    </div>
  </div>
  </div>


<div class="row area-top-lg">
  <div class="col-md-12">
    <ngx-datatable
            class="material striped" 
            [limit]="500" 
            [rows]="rows" 
            [columns]="columns"
            columnMode="force"
            [rowHeight]="'auto'"
            [footerHeight]="50"
            headerHeight="auto">

            <ngx-datatable-column [width]="30" [sortable]="false" name="Competência" prop="competencia">
              <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                <span [ngClass]="relatorioRendaMensalInicial.contribuicoes[rowIndex].relevante ? 'relevante' : 'irrelevante'">{{value | date : "MM/yyyy"}}</span>
              </ng-template>

            </ngx-datatable-column>
            <ngx-datatable-column [width]="30" [sortable]="false" name="Valor" prop="valor">
              <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                
                <span [ngClass]="relatorioRendaMensalInicial.contribuicoes[rowIndex].relevante ? 'relevante' : 'irrelevante'">{{value | currency}}</span>
              </ng-template>

            </ngx-datatable-column>

            <ngx-datatable-column [width]="30" [sortable]="false" name="Indicadores" prop="indicadores">
              <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                
                <span [ngClass]="relatorioRendaMensalInicial.contribuicoes[rowIndex].relevante ? 'relevante' : 'irrelevante'">{{value}}</span>
              </ng-template>

            </ngx-datatable-column>

            <ngx-datatable-column [width]="30" [sortable]="false" name="Índice Acumulado" prop="indiceAcumulado">
              <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                
                <span [ngClass]="relatorioRendaMensalInicial.contribuicoes[rowIndex].relevante ? 'relevante' : 'irrelevante'">{{value}}</span>
              </ng-template>

            </ngx-datatable-column>

            <ngx-datatable-column [width]="30" [sortable]="false" name="Valor Corrigido" prop="valorAtualizado">
              <ng-template let-value="value"let-rowIndex="rowIndex" ngx-datatable-cell-template>
                
                <span [ngClass]="relatorioRendaMensalInicial.contribuicoes[rowIndex].relevante ? 'relevante' : 'irrelevante'">{{value | currency}}</span>
              </ng-template>

            </ngx-datatable-column>
            


<!--             
            <ngx-datatable-column [width]="60" [sortable]="false" name="Origem" prop="origemVinculo">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div >
                  <span (click)="edicaoRelacoesPrevidenciarias[rowIndex +'-origemVinculo']=true"
                    *ngIf="!edicaoRelacoesPrevidenciarias[rowIndex + '-origemVinculo']">{{value}}</span>
                    <input 
                    autofocus
                    class="form-control"
                    (blur)="atualizarValorRelacaoPrevidenciaria($event, 'origemVinculo', rowIndex)"
                    *ngIf="edicaoRelacoesPrevidenciarias[rowIndex + '-origemVinculo']"
                    type="text"
                    [value]="value"/>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="30"  [sortable]="false" name="Vinculo" prop="tipoFiliadoVinculo">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div >
                  <span (click)="edicaoRelacoesPrevidenciarias[rowIndex +'-tipoFiliadoVinculo']=true"
                  *ngIf="!edicaoRelacoesPrevidenciarias[rowIndex + '-tipoFiliadoVinculo']">{{value}}</span>
                  <input 
                  autofocus
                  class="form-control"
                  (blur)="atualizarValorRelacaoPrevidenciaria($event, 'tipoFiliadoVinculo', rowIndex)"
                  *ngIf="edicaoRelacoesPrevidenciarias[rowIndex + '-tipoFiliadoVinculo']"
                  type="text"
                  [value]="value"/>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="25"  [sortable]="false" name="Tipo da Atividade" prop="tipoAtividade">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div >
                  <span (click)="edicaoRelacoesPrevidenciarias[rowIndex +'-tipoAtividade']=true"
                  *ngIf="!edicaoRelacoesPrevidenciarias[rowIndex + '-tipoAtividade']">{{value}}</span>
                  <ngx-select 
                    placeholder="selecione"
                    (select)="tipoAtividadeSelecionado($event, 'tipoAtividade', rowIndex)"
                    *ngIf="edicaoRelacoesPrevidenciarias[rowIndex + '-tipoAtividade']"
                    [items]="tiposAtividade" 
                    >
                  </ngx-select>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="25"  [sortable]="false" name="Carência" prop="carencia">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div >
                  <ui-switch
                  [checked]="value == 0? false : true"
                  switchColor="#5e72e4"
                  (change)="carenciaAtualizada($event, 'carencia', rowIndex)"
                  ></ui-switch>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="30"  [sortable]="false" name="Início" prop="dataInicio">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div >
                  <span (click)="edicaoRelacoesPrevidenciarias[rowIndex +'-dataInicio']=true"
                    *ngIf="!edicaoRelacoesPrevidenciarias[rowIndex + '-dataInicio']">
                    {{value | date : 'dd/MM/yyyy'}}
                  </span>
                  <input
                  autofocus 
                  type="text"
                  
                  *ngIf="edicaoRelacoesPrevidenciarias[rowIndex + '-dataInicio']"
                  [value]="value"
                  placeholder="Selecionar"
                  class="form-control"
                  
                  (bsValueChange)="dataSelecionada($event, 'dataInicio', rowIndex)"
                  bsDatepicker
                  [bsValue]="bsValue"
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', locale:'pt-br', containerClass:'theme-default' }"/>
                  
                </div>
              </ng-template>
              
            </ngx-datatable-column>
            <ngx-datatable-column [width]="30" [sortable]="false" name="Fim" prop="dataFim">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div >
                  <span (click)="edicaoRelacoesPrevidenciarias[rowIndex +'-dataFim']=true"
                    *ngIf="!edicaoRelacoesPrevidenciarias[rowIndex + '-dataFim']">
                    {{value | date : 'dd/MM/yyyy'}}
                  </span>


                  <input
                  autofocus 
                  type="text"
                  
                  *ngIf="edicaoRelacoesPrevidenciarias[rowIndex + '-dataFim']"
                  [value]="value"
                  width="100%"
                  placeholder="Selecionar"
                  class="form-control"
                  
                  (bsValueChange)="dataSelecionada($event, 'dataFim', rowIndex)"
                  bsDatepicker
                  [bsValue]="bsValue"
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', locale:'pt-br', containerClass:'theme-default' }"/>
                  
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="40" [sortable]="false" name="Tempo de Contribuição" prop="tempoContribuicao"></ngx-datatable-column> -->
          </ngx-datatable>
  </div>
</div>