import { Base } from "../base";
import { Resposta } from "./resposta";
import { TiposResposta } from "./tiposResposta";

export class Pergunta extends Base {

    tipoResposta:TiposResposta;
    titulo:string;
    descricao:string;
    instrucao:string;
    link:string;
    respostas:Resposta[]
    opcoes:string[];
    respondida:boolean;


}