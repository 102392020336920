import { ConfigService } from 'src/app/servicos/ui/config.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { Usuario } from 'src/app/modelos/usuario/usuario';

@Component({
  selector: 'app-lista-clientes',
  templateUrl: './lista-clientes.component.html',
  styleUrl: './lista-clientes.component.scss'
})
export class ListaClientesComponent {

  @Output() clienteSelecionadoEvent = new EventEmitter<Cliente>();
  clientes: Cliente[];

  constructor(private configService: ConfigService,
    private calculeiApiService: CalculeiApiService
  ) {
    let usuarioRequest:Usuario;
    this.configService.getUsuario().subscribe(usuario=>usuarioRequest = usuario)
    this.calculeiApiService.api("ListaCliente", usuarioRequest).subscribe(clientes => {
      // console.log('resultado clientes', clientes);
      this.clientes = clientes as Cliente[];
    })
  }

  selecionarCliente(cliente:Cliente){
    this.clienteSelecionadoEvent.emit(cliente);

  }
  public addClienteLista(cliente:Cliente){
    this.clientes.push(cliente);
  }

}
