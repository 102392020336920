<div *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.preAnalise">

  <!-- <app-destaque-pre-analise-cumprimento-requisitos *ngIf="calculoPlanejamentoKertzman.preAnalise" [destaque]="calculoPlanejamentoKertzman.preAnalise"></app-destaque-pre-analise-cumprimento-requisitos> -->

  <app-evolucao-salarios [calculoPlanejamentoKertzman]="calculoPlanejamentoKertzman"></app-evolucao-salarios>

  <app-relatorio-beneficio #relatorioBeneficio *ngIf="calculoPlanejamentoKertzman.preAnalise" [beneficio]="calculoPlanejamentoKertzman.preAnalise"></app-relatorio-beneficio>
    
  <app-quadro-carencia-planejamento *ngIf="calculoPlanejamentoKertzman.preAnalise" [beneficio]="calculoPlanejamentoKertzman.preAnalise"></app-quadro-carencia-planejamento>

  <app-quadro-descartes *ngIf="calculoPlanejamentoKertzman.preAnalise" [beneficio]="calculoPlanejamentoKertzman.preAnalise"></app-quadro-descartes>
  
</div>