import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { CapitalizacaoMensal } from 'src/app/modelos/previdenciario/capitalizacaomensal';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { ListaRegras, ListaRegrasEx } from 'src/app/modelos/previdenciario/listaregras';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';
import { RelatorioPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioplanejamentoprevidenciario';
import { TipoBeneficio } from 'src/app/modelos/previdenciario/tipobeneficio';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { ListarTabelaCapitalizacaoSaqueService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao-saque.service';
import { ListarTabelaCapitalizacaoService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao.service';
import { LogService } from 'src/app/servicos/log.service';
import { ListarRelatorioPlanejamentoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-planejamento-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-caso-planejamento-previdenciario',
  templateUrl: './caso-planejamento-previdenciario.component.html',
  styleUrls: ['./caso-planejamento-previdenciario.component.scss']
})
export class CasoPlanejamentoPrevidenciarioComponent implements OnInit, AfterViewInit, OnChanges {


  @ViewChild('tabelaProgressaoCapitalizacao') tabelaProgressaoCapitalizacao:DatatableComponent;
  @ViewChild('tabelaProgressaoSaque') tabelaProgressaoSaque:DatatableComponent;

  @Input() relatorio:RelatorioKertzman;
  @Input() visualizarProgressaoCapitalizacao:boolean =true;
  @Input() visualizarProgressaoCapitalizacaoTabela:boolean =true;
  @Input() limiteTabelaProgressaoCapitalizacao:number = 10;
  @Output() processandoRelatorioEvent = new EventEmitter<boolean>();

  

  linhasCapitalizacaoMensal:CapitalizacaoMensal[];
  linhasCapitalizacaoMensalSaque:CapitalizacaoMensal[];
  beneficioRelatorio:Beneficio;
  
  
  duracaoPrestacoesMensaisRendimento = 0;

  constructor(private listarRelatorioPlanejamentoPdfService:ListarRelatorioPlanejamentoPdfService,
    private notificacoesService:NotificacoesService,
    private cp:CurrencyPipe,
    private listarTabelaCapitalizacaoSaqueService:ListarTabelaCapitalizacaoSaqueService,
    private listarTabelaCapitalizacaoService:ListarTabelaCapitalizacaoService){}

  ngOnInit(): void {
    
    this.carregarDados();
    
  }

  carregarDados(){
    this.linhasCapitalizacaoMensal = CapitalizacaoMensal.tabelaCapitalizacao(this.relatorio.otimizacao);

    LogService.log('linhas capitalizacao mensal', this.linhasCapitalizacaoMensal);

    // console.log("linhas capitalizacao mensal", this.linhasCapitalizacaoMensal);
    this.linhasCapitalizacaoMensalSaque = CapitalizacaoMensal.tabelaCapitalizacaoSaque(this.relatorio.otimizacao);

    LogService.log('linhas capitalizacao mensal saque', this.linhasCapitalizacaoMensalSaque);

    this.beneficioRelatorio = this.relatorio.otimizacao.beneficio;
    if(this.tabelaProgressaoCapitalizacao!= undefined){
      this.tabelaProgressaoCapitalizacao.recalculate();
    }
    if(this.tabelaProgressaoSaque!= null){
      this.tabelaProgressaoSaque.recalculate();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {


    // this.duracaoPrestacoesMensaisRendimento = CapitalizacaoMensal.capitalizacaoSaque(changes.caso.currentValue).length;
    // this.linhasCapitalizacaoMensal = CapitalizacaoMensal.capitalizacao(changes.caso.currentValue);
    // this.linhasCapitalizacaoMensalSaque = CapitalizacaoMensal.capitalizacaoSaque(changes.caso.currentValue);

    this.carregarDados();

    
  }

  ngAfterViewInit(): void {

  }

  stringToCurrency(value){
    return this.cp.transform(value);
  }
  

  baixarPDF(){

    this.processandoRelatorioEvent.emit(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    

    this.listarRelatorioPlanejamentoPdfService.ListarPDF(this.relatorio)
    .subscribe(blob=>{

      
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = 'RelatorioliquidacaoSentenca.pdf';
      a.click();
      URL.revokeObjectURL(objectUrl);
      this.processandoRelatorioEvent.emit(false);
      this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
    });  
  }

  
}
