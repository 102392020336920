import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from "./tipobeneficioliquidacaosentencaprevidenciario";

export class BeneficioValorCausaPrevidenciario {
    tipoBeneficio:TipoBeneficioLiquidacaoSentencaPrevidenciario;
    
    dib:Date;
    dip:Date;
    rmi:number;
    dib25:Date;
    acrescimo25Dependente:boolean;
}