import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalculosRoutingModule } from './calculos-routing.module';

import { CalculosComponent } from './calculos.component';
import { NovoCalculoComponent } from './novo-calculo/novo-calculo.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PrevidenciarioModule } from './previdenciario/previdenciario.module';
import { CivelModule } from './civel/civel.module';
import { CalculoLayoutBaseComponent } from './calculo-layout-base/calculo-layout-base.component';
import { CompartilhadoModule } from '../compartilhado/compartilhado.module';

@NgModule({
  declarations: [
    CalculosComponent,
    NovoCalculoComponent,
    CalculoLayoutBaseComponent
  ],
  imports: [
    CommonModule,
    CalculosRoutingModule,
    ProgressbarModule,
    PrevidenciarioModule,
    CivelModule,
    CompartilhadoModule
  ]
})
export class CalculosModule { }
