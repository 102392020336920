export enum StatusAdesaoPagseguro{
    INITIATED,
    PENDING,
    ACTIVE,
    PAYMENT_METHOD_CHANGE,
    SUSPENDED,
    CANCELLED,
    CANCELLED_BY_RECEIVER,
    CANCELLED_BY_SENDER,
    EXPIRED,
    STATUSINDISPONIVEL
}