<div class="">
  <div class="row">
    <div class="col">
      <p class="h3">Novo Marcador</p>
    </div>
  </div>
  <div class="row flex d-flex">
    <div class="col-4">
      <h5 class=" card-title text-uppercase mb-0 text-default">
        Cor
      </h5>
      <input type="color" class="form-control form-control-color" #inputCor value="#000000" title="Escolha uma cor">
      <p class="h6">
        Cor do Marcador
      </p>
    </div>

    <div class="col-8">
      <h5 class=" card-title text-uppercase mb-0 text-default">
        Título
      </h5>
      <div class="row">
        <div class="col">
          <input class=" form-control text-left" #inputTitulo type="text" />
        </div>
        <div class="col-auto align-self-center">
          <i class="comando bi bi-arrow-right" role="button" (click)="addMarcador()"></i>
        </div>
      </div>
      
      <p class="h6">
        Título do Marcador
      </p>
    </div>

    
  </div>
</div>