import { Component, Input } from '@angular/core';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';

@Component({
  selector: 'app-validacoes-pontos-atencao',
  templateUrl: './validacoes-pontos-atencao.component.html',
  styleUrl: './validacoes-pontos-atencao.component.scss'
})
export class ValidacoesPontosAtencaoComponent {

  @Input() calculoBeneficios:CalculoBeneficios;

  switchIgnorarValidacao(event, contribuicao:Contribuicao){
    
  }
  switchIgnorarValidacaoSequencia(event, sequencia:RelacaoPrevidenciaria){
    
  }

}
