import { Base } from "../base";
import { Usuario } from "../usuario/usuario";
import { AcessoAsset } from "./acessoasset";
import { Pergunta } from "./pergunta";

export class Ficha extends Base{

    nome:string;
    perguntas:Pergunta[];
    usuario:Usuario;
    exibirLogo:boolean;
    mensagemInicial:string;
    mensagemFinal:string;
    acesso:AcessoAsset;


}