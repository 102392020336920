import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked, AfterViewInit, OnChanges, SimpleChanges, AfterContentInit, AfterViewChecked } from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Calculo } from 'src/app/modelos/common/calculo';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { RequestCalculoBeneficios } from 'src/app/modelos/previdenciario/requestcalculobeneficios';
import { RequestRelatorioRendaMensalInicial } from 'src/app/modelos/previdenciario/requestrelatoriorendamensalinicial';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { GruposSumarioCalculoBeneficiosComponent } from '../compartilhado/grupos-sumario-calculo-beneficios/grupos-sumario-calculo-beneficios.component';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { BarraNavegacaoComponent } from 'src/app/aplicacao/compartilhado/barra-navegacao/barra-navegacao.component';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { ListarRelatorioRendaMensalInicialPdfService } from 'src/app/servicos/relatorio/listar-relatorio-renda-mensal-inicial-pdf.service';
import { EditorCNISComponent } from '../compartilhado/editor-cnis/editor-cnis.component';
import { ImportadorCNISComponent } from '../compartilhado/importador-cnis/importador-cnis.component';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';

import { CalculoAnaliseCnis } from 'src/app/modelos/previdenciario/calculoanalisecnis';
import { ResultadoAnaliseCnisComponent } from '../compartilhado/resultado-analise-cnis/resultado-analise-cnis.component';
import { CnisListagemComponent } from 'src/app/aplicacao/dashboard/compartilhado-dashboard/cnis-listagem/cnis-listagem.component';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';

@Component({
  selector: 'app-auxilio-acidente',
  templateUrl: './auxilio-acidente.component.html',
  styleUrls: ['./auxilio-acidente.component.scss']
})
export class AuxilioAcidenteComponent implements OnInit, AfterViewInit, OnChanges, AfterViewChecked {

  @ViewChild('seletorCnis', { static: false }) seletorCnis?: CnisListagemComponent;
  @ViewChild('tabsCalculoBeneficios', { static: false }) tabs?: TabsetComponent;
  @ViewChild('importadorCNIS') importadorCNIS: ImportadorCNISComponent;
  @ViewChild('editorCNIS') editorCNIS: EditorCNISComponent;

  @ViewChild('barraNavegacao', { static: false }) barraNavegacao?: BarraNavegacaoComponent;
  // @ViewChild('barraNavegacaoInferior', { static: false }) barraNavegacaoInferior?: BarraNavegacaoComponent;

  @ViewChild('resultadoAnaliseCnis') resultadoAnaliseCnis: ResultadoAnaliseCnisComponent;



  calculoAnaliseCnis: CalculoAnaliseCnis;

  beneficioSelecionado: Beneficio;
  aguardeVisivel: boolean = false;
  relatorioVisivel: boolean = true;
  proximoHabilitado: boolean;
  voltarHabilitado: boolean;
  tabDirective: TabDirective;
  // navegacao: Navegacao;
  navegacao: Navegacao;
  voltarSilencioso: Navegacao;

  //usado para atualizar ou nao o grupo sumario depois da navegacao voltar
  //identifica se houve alteracao no cnis para calcular novamente
  cnisEditado: CNIS;
  dataCalculoEditada: Date;
  calculoArquivo: boolean;

  titulo: string;


  constructor(private location: Location, private listarCalculoResultadoService: ListarCalculoResultadoService,
    private datePipe: DatePipe, private changeDetector: ChangeDetectorRef,
    private notificacoesService: NotificacoesService,
    private listarPDfService:ListarPDFService,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService) {



    this.calculoAnaliseCnis = new CalculoAnaliseCnis();
    this.calculoAnaliseCnis.calculoBeneficios = new CalculoBeneficios();

    this.navegacao = new Navegacao(0, false, false);
    this.titulo = "Renda Mensal Inicial";

  }


  ngAfterViewChecked(): void {
    //ng0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    //erro quando coloca retorno do evento edicaocnisfinalizada no objeto calculo
    this.changeDetector.detectChanges();
  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    this.barraNavegacao.setTitulo("CNIS");




    this.tabDirective = this.tabs.tabs.filter(tab => tab.active)[0];


    if (this.location.getState().hasOwnProperty('resultado')) {
      this.aguardeVisivel = true;
      this.selectTab(2);
      this.voltarHabilitado = false;
      this.calculoArquivo = true;
      let calculoResultado: Calculo = this.location.getState() as Calculo;
      this.titulo = "Cálculo de Renda Mensal Inicial para " + calculoResultado.cliente.nome + ", realizado em " + this.datePipe.transform(calculoResultado.data, "dd/MM/yyyy HH:mm");

      this.listarCalculoResultadoService.listarCalculoResultado(calculoResultado)
        .then(calculo => {
          this.carregarDados(calculo as CalculoAnaliseCnis);
          this.selectTab(2);
          this.aguardeVisivel = false;
        });
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {

    // this.mock();
  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculoresultadoanalisecnis.json", request => {
      this.carregarDados(request as CalculoAnaliseCnis);
      this.selectTab(1);
    });
  }

  carregarDados(calculoAnaliseCnis: CalculoAnaliseCnis) {


    this.calculoAnaliseCnis = calculoAnaliseCnis;
    this.cnisEditado = this.calculoAnaliseCnis.calculoBeneficios.leituraCnis.cnis;
    this.dataCalculoEditada = this.calculoAnaliseCnis.calculoBeneficios.dataCalculo;

    // this.resultadoAnaliseCnis.carregarDados(this.calculoAnaliseCnis);



  }

  leituraCNISEvent(leituraCNIS: LeituraCNIS) {


    this.calculoAnaliseCnis.calculoBeneficios.leituraCnis = leituraCNIS;
    this.barraNavegacao.habilitarVoltar(true);
    this.barraNavegacao.habilitarProximo(true);


    if (this.tabs.tabs[0].active) {
      this.barraNavegacao.proximo();
    }
    this.editorCNIS.carregarDados();
    this.aguardeVisivel = false;

    this.barraNavegacao.setMarcadorIncluirCnisHabilitar(true);
    this.barraNavegacao.setExcluirCnisHabilitar(true);


  }
  processandoCNISEvent(processando: boolean) {
    this.aguardeVisivel = processando;
  }

  //editor cnis

  compararEdicaoCNIS(cnisAnterior: CNIS, cnisAtual: CNIS, dataAnterior: Date, dataAtual: Date): boolean {
    let resultado: boolean;
    resultado = (JSON.stringify(cnisAnterior) === JSON.stringify(cnisAtual)) && dataAnterior.toDateString() === dataAtual.toDateString();

    return resultado;
  }

  edicaoCNISFinalizada(calculoBeneficios: CalculoBeneficios) {
    this.barraNavegacao.setMarcadorIncluirCnisHabilitar(false);

    this.calculoAnaliseCnis.calculoBeneficios = calculoBeneficios;
    this.cnisEditado = calculoBeneficios.leituraCnis.cnis;
    this.dataCalculoEditada = calculoBeneficios.dataCalculo;
    this.changeDetector.detectChanges();
    // this.resultadoAnaliseCnis.carregarDados(this.calculoAnaliseCnis);
  }

  sumarioFinalizado(pronto: boolean) {
    this.aguardeVisivel = false;
  }


  incluirMarcadorCnis(incluir: boolean) {


    this.editorCNIS.incluirMarcador();


  }

  excluirCnis() {
    this.aguardeVisivel = true;
    this.editorCNIS.excluirCnis();
  }

  excluirCnisFinalizado(exclui: boolean) {
    // console.log('cnis excluido - beneficios component');
    this.editorCNIS.excluirFinalizado();
    // this.seletorCnis.carregarDados();
    this.selectTab(0)
    this.aguardeVisivel = false;
  }


  selectTab(tab: number) {
    if (this.tabs?.tabs[tab]) {
      this.tabs.tabs[tab].active = true;
      this.changeDetector.detectChanges();
    }
  }


  voltar(navegacao: Navegacao) {
    window.dispatchEvent(new Event('resize'));
    switch (navegacao.indexTabAtiva) {
      case 1:
        //regulariza o tamanho do ngx-datable
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        this.barraNavegacao.habilitarVoltar(true);
        this.barraNavegacao.habilitarProximo(true);
        break;

      default:
        break;
    }
    this.selectTab(navegacao.indexTabAtiva);
  }

  continuar(navegacao: Navegacao) {

    this.selectTab(navegacao.indexTabAtiva);
    //ativar grupossumario
    switch (navegacao.indexTabAtiva) {
      case 2:
        this.barraNavegacao.habilitarProximo(false);
        this.editorCNIS.finalizarEdicaoCNIS();
        break;

      default:
        break;
    }

  }


  tabSelecionada(tabDirective: TabDirective) {
    switch (tabDirective.id) {
      case "tabImportarCnis":
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        this.voltarHabilitado = false;
        this.proximoHabilitado = true;
        this.barraNavegacao.setTitulo("CNIS");
        this.barraNavegacao.setExcluirCnisHabilitar(false);
        this.barraNavegacao.habilitarPDF(false);
        break;
      case "tabEditorCnis":
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        this.voltarHabilitado = true;
        this.proximoHabilitado = true;
        this.barraNavegacao.setTitulo("Ajustes do CNIS");
        this.barraNavegacao.setExcluirCnisHabilitar(true);
        this.barraNavegacao.setMarcadorIncluirCnisHabilitar(true);
        this.barraNavegacao.habilitarPDF(false);
        break;
      case "tabResultado":
        this.voltarHabilitado = true;
        this.proximoHabilitado = false;
        this.barraNavegacao.setTitulo("Resultado");
        this.barraNavegacao.setExcluirCnisHabilitar(false);
        this.barraNavegacao.setMarcadorIncluirCnisHabilitar(false);
        this.barraNavegacao.habilitarPDF(true);
        break;

      default:
        break;
    }
    // this.barraNavegacao.atualizarTabDirective(this.tabDirective);
  }

  voltarSileciosoAcao(navegacao: Navegacao) {


    this.barraNavegacao.habilitarPDF(false);
    this.barraNavegacao.navegacao.manterTabAtivaVoltar = false;

  }


  abandonarDetalhe(tabDirective: TabDirective) {
    this.barraNavegacao.navegacao.manterTabAtivaVoltar = false;

    this.barraNavegacao.habilitarPDF(false);
  }

  baixarPDF(navegacao: Navegacao) {
    this.gerarPDF();
  }

  gerarPDF() {
  }

  atualizarListaCnis(atualizar: boolean) {
    // this.seletorCnis.carregarDados();



  }

}




