import { Base } from "../base";
import { Assinatura } from "../pagamento/assinatura";
import { ConfiguracaoUsuario } from "./configuracaousuario";
import { Endereco } from "./endereco";
import { Permissoes } from "./permissoes";

export class Usuario extends Base{
    
    nome:string;
    email:string;
    telefone:string;
    sobrenome:string;
    endereco:Endereco[];
    identificacaoFiscal:string;
    permissao:Permissoes;
    assinatura:Assinatura;
    assinaturasKeys:string[];
    emailConfirmado:boolean;
    avatarUrl:string;
    logoUrl:string;
    dataConta:Date;
    configuracao:ConfiguracaoUsuario;
    dataUltimoAcesso:Date;
    totalAcessos:number;

    inicioPeriodoTeste:Date;
    fimPeriodoTeste:Date;
    
    prorrogacoesPeriodoTeste:number;

}