import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ImportacaoHiscre } from 'src/app/modelos/previdenciario/importacaohiscre';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportarHiscreService {

  constructor(private httpClient: HttpClient) { }

  importarHiscre(importacaoHiscre:ImportacaoHiscre){
    return Promise.all([this.apiCall(importacaoHiscre)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(importacaoHiscre:ImportacaoHiscre){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ImportarHiscre',importacaoHiscre, {'headers':headers}).toPromise();
    return data;
  }
}
