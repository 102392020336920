import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";


import { AutorizacaoService } from "./servicos/oauth/autorizacao.service";
import { EntrarComponent } from "./paginas/entrar/entrar.component";
import { LoginComponent } from "./paginas/login/login.component";
import { ConfirmacaoEmailComponent } from "./paginas/confirmacao-email/confirmacao-email.component";
import { ConfirmarEmailComponent } from "./paginas/confirmar-email/confirmar-email.component";
import { CriarcontaComponent } from "./paginas/criarconta/criarconta.component";
import { AutorizacaoAdminService } from "./servicos/oauth/autorizacao-admin.service";

const routes: Routes = [
 
  {
    path:'criarconta',
    component:CriarcontaComponent
  },
  {
    path:'entrar',
    component:EntrarComponent
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'confirmacaoemail',
    component:ConfirmacaoEmailComponent
  },
  {
    path:'relatorios',
    loadChildren: () => import('./relatorios/relatorios.module').then(m => m.RelatoriosModule)
  },
  {
    path:'entrevistas',
    loadChildren: () => import('./sala-entrevistas/sala-entrevistas.module').then(m => m.SalaEntrevistasModule)
  },
  {
    path: "calculei",
    canActivate:[AutorizacaoService],
    loadChildren: () => import('./aplicacao/aplicacao.module').then(m => m.AplicacaoModule)
  },
  {
    path: "admin",
    canActivate:[AutorizacaoAdminService],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path:'confirmaremail',
    component:ConfirmarEmailComponent
  },
  {
    path: "**",
    redirectTo: "login"
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled"})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
