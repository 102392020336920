import { Pipe, PipeTransform } from '@angular/core';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';

@Pipe({
  name: 'tipoProcessoLiquidacao'
})
export class TipoProcessoLiquidacaoPipe implements PipeTransform {

  transform(value: TiposProcessoLiquidacaoSentencaPrevidenciario, ...args: unknown[]): string {
    
    let resultado = '';

    switch (value) {
      case TiposProcessoLiquidacaoSentencaPrevidenciario.CONCESSAO:
        resultado = "Concessão";
        
        break;
        case TiposProcessoLiquidacaoSentencaPrevidenciario.REESTABELECIMENTO:
        resultado = "Reestabelecimento";
        
        break;
        case TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO:
        resultado = "Revisão";
        
        break;

    
      default:
        break;
    }
    return resultado;

  }

}
