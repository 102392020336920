import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CriarContaRequest } from 'src/app/modelos/usuario/criarcontarequest';
import { CriarContaResponse } from 'src/app/modelos/usuario/criarcontaresponse';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { CriarContaService } from 'src/app/servicos/usuario/criar-conta.service';

@Component({
  selector: 'app-criarconta',
  templateUrl: './criarconta.component.html',
  styleUrls: ['./criarconta.component.scss']
})
export class CriarcontaComponent implements OnInit {
  test: Date = new Date();
  isCollapsed = true;
  concluido = false;
  aguardeVisivel = false;
  visualizarSenha:boolean;


  continuarHabilitado:boolean;

  criarContaRequest:CriarContaRequest = new CriarContaRequest();


  requestForm = this.fb.group({
    nome:['', Validators.required],
    sobrenome:['', Validators.required],
    email:['', Validators.email],
    senha:['', Validators.required],
    concordancia:[''],
  });


  constructor(private autenticacaoService:AutenticacaoService, 
    private criarContaService:CriarContaService, 
    private fb: UntypedFormBuilder,
    private router:Router) {

      this.requestForm.statusChanges.subscribe(()=>{
        this.continuarHabilitado = this.requestForm.valid;
      });
    }

  ngOnInit() {
    
  }

  login(){
    this.autenticacaoService.login();
  }

  criarConta(){

    this.aguardeVisivel=true;
    
    this.criarContaRequest.nome = this.requestForm.get('nome').value;
    this.criarContaRequest.sobrenome = this.requestForm.get('sobrenome').value;
    this.criarContaRequest.email = this.requestForm.get('email').value;
    this.criarContaRequest.senha = this.requestForm.get('senha').value;
    
    this.criarContaService.criarConta(this.criarContaRequest)
    .then(criarContaResponse=>{

      this.concluido = true;
      // this.router.navigateByUrl('/paginas/confirmacaoemail?id=' + (criarContaResponse as CriarContaResponse).id);

    });
    
  }
  trocarVisualizarSenha(){
    this.visualizarSenha = !this.visualizarSenha;
  }
}
