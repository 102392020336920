





<h5 class=" h3 mb-0 linha-quadro">Imagem do Perfil</h5>



<div class="row">
  <div class="col-lg-12">
      <p class="lead"> Use esse espaço configurar a foto que aparece nas suas atividades de perfil no Calculei.</p>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
      <div class=" form-group">
          <input
            style="display: none"
            type="file"
            #fileInput
            multiple="multiple"
            (change)="selecionarArquivo($event)"
          />
          <button type="button" (click)="showFileDialog()" class="btn btn-outline-black" >
            Selecionar sua imagem de perfil
          </button>
        </div>
  </div>
</div>
<div class="row">
<div class="col-lg-12">
  <span class="avatar avatar-sm p-5 rounded-circle bg-transparent">
    <i *ngIf="!avatarVisivel" class="ni ni-4x ni-single-02 text-default"></i>
  </span>

  <img *ngIf="avatarVisivel" class="pb-4"
            alt="Image placeholder"
            src={{avatarUrl}}
            width="100px"
          />
</div>
</div>