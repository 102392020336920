
<div class="row h-100">
    <div class="col ">
        <ejs-spreadsheet #planilha (created)="created()" (dataSourceChanged)="dataSourceChanged($event)"
        
        [allowSave]="true"
        [scrollSettings]="{isFinite:true, enableVirtualization:true}"
        
        saveUrl='https://services.syncfusion.com/angular/production/api/spreadsheet/save'
        >
            <e-sheets>
               

                
                

               

                <e-sheet name="Originais" [showHeaders]="true" [rowCount]="contribuicoesOriginais.length">
                    <e-rows>
                        <e-row [index]="0">
                            <e-cells>
                                <e-cell [index]="0" value="Sequência"></e-cell>
                                <e-cell [index]="1" value="Competência"></e-cell>
                                <e-cell [index]="2" value="Salário de Contribuição"></e-cell>
                                <e-cell [index]="3" value="Salário Mínimo na Competência"></e-cell>
                                <e-cell [index]="3" value="Complementação Salário Mínimo"></e-cell>
                                <e-cell [index]="4" value="Teto do INSS na Competência"></e-cell>
                                <e-cell [index]="5" [value]="'Índice de Atualização em ' + (dataAtualizacao | date : 'MM/yyyy' )"></e-cell>
                                <e-cell [index]="6" value="Salário de Contribuição Atualizado"></e-cell>
                            </e-cells>
                        </e-row>
                    </e-rows>
                    <e-ranges>
                        <e-range [dataSource]="contribuicoesOriginais" [showFieldAsHeader]="false" startCell="A2"></e-range>
                        
                    </e-ranges>

                    

                    <e-columns>
                        <e-column [width]="100" ></e-column>
                        <e-column [width]="110"></e-column>
                        <e-column [width]="200"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        
                    </e-columns>
                    

                </e-sheet>
                <e-sheet name="Validadas" [showHeaders]="true" [rowCount]="contribuicoesProcessadas.length">
                    <e-rows>
                        <e-row [index]="0">
                            <e-cells>
                                <e-cell [index]="0" value="Sequência"></e-cell>
                                <e-cell [index]="1" value="Competência"></e-cell>
                                <e-cell [index]="2" value="Salário de Contribuição"></e-cell>
                                <e-cell [index]="3" value="Salário Mínimo na Competência"></e-cell>
                                <e-cell [index]="3" value="Complementação Salário Mínimo"></e-cell>
                                <e-cell [index]="4" value="Teto do INSS na Competência"></e-cell>
                                <e-cell [index]="5" [value]="'Índice de Atualização em ' + (dataAtualizacao | date : 'MM/yyyy' )"></e-cell>
                                <e-cell [index]="6" value="Salário de Contribuição Atualizado"></e-cell>
                            </e-cells>
                        </e-row>
                    </e-rows>
                    <e-ranges>
                        <e-range [dataSource]="contribuicoesProcessadas" [showFieldAsHeader]="false" startCell="A2"></e-range>
                    </e-ranges>

                    <e-columns>
                        <e-column [width]="100" ></e-column>
                        <e-column [width]="110"></e-column>
                        <e-column [width]="200"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        
                    </e-columns>

                </e-sheet>


                <e-sheet name="Excedentes" [showHeaders]="true" [rowCount]="contribuicoesExcedentes.length">
                    <e-rows>
                        <e-row [index]="0">
                            <e-cells>
                                <e-cell [index]="0" value="Sequência"></e-cell>
                                <e-cell [index]="1" value="Competência"></e-cell>
                                <e-cell [index]="2" value="Salário de Contribuição"></e-cell>
                                <e-cell [index]="3" value="Teto do INSS na Competência"></e-cell>
                                <e-cell [index]="4" value="Excedente"></e-cell>
                                
                            </e-cells>
                        </e-row>
                    </e-rows>
                    <e-ranges>
                        <e-range [dataSource]="contribuicoesExcedentes" [showFieldAsHeader]="false" startCell="A2"></e-range>
                    </e-ranges>

                    <e-columns>
                        <e-column [width]="100" ></e-column>
                        <e-column [width]="110"></e-column>
                        <e-column [width]="200"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        
                        
                        
                    </e-columns>

                </e-sheet>
                <e-sheet name="Composição de Excedentes" [showHeaders]="true" [rowCount]="contribuicoesComposicaoExcedentes.length">
                    <e-rows>
                        <e-row [index]="0">
                            <e-cells>
                                <e-cell [index]="0" value="Sequência"></e-cell>
                                <e-cell [index]="1" value="Competência"></e-cell>
                                <e-cell [index]="2" value="Salário de Contribuição"></e-cell>
                                <e-cell [index]="3" value="Teto do INSS na Competência"></e-cell>
                                <e-cell [index]="4" value="Percentual do Teto"></e-cell>
                                <e-cell [index]="5" value="Alíquota"></e-cell>
                                <e-cell [index]="6" value="Excedente Teto"></e-cell>
                                <e-cell [index]="7" value="Valor de Restituição"></e-cell>
                                <e-cell [index]="8" value="Corrigido"></e-cell>
                                
                            </e-cells>
                        </e-row>
                        <e-row [index]="contribuicoesComposicaoExcedentes.length + 1">
                            <e-cells>
                                <e-cell [index]="7" value="Total Restituição:" [style]="cellStyle"></e-cell>
                                <e-cell [index]="8" [formula]="'=SUM(I2:I'+ (contribuicoesComposicaoExcedentes.length + 1) +')'" [style]="cellStyle"></e-cell>
                            </e-cells>
                        </e-row>
                    </e-rows>
                    <e-ranges>
                        <e-range [dataSource]="contribuicoesComposicaoExcedentes" [showFieldAsHeader]="false" startCell="A2"></e-range>
                    </e-ranges>

                    <e-columns>
                        <e-column [width]="100" ></e-column>
                        <e-column [width]="110"></e-column>
                        <e-column [width]="200"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        <e-column [width]="250"></e-column>
                        
                        
                        
                    </e-columns>

                </e-sheet>

            </e-sheets>
        </ejs-spreadsheet>
    </div>

</div>

