import { Component, Input } from '@angular/core';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';

@Component({
  selector: 'app-totais-liquidacao-sentenca',
  templateUrl: './totais-liquidacao-sentenca.component.html',
  styleUrl: './totais-liquidacao-sentenca.component.scss'
})
export class TotaisLiquidacaoSentencaComponent {

  @Input() calculoLiquidacaoSentenca:CalculoLiquidacaoSentencaPrevidenciario;

}
