<nav
  id="navbar-main"
  class="navbar navbar-horizontal navbar-main navbar-expand-lg navbar-dark bg-dark">
  <div class="container">
    <a class="navbar-brand" href="https://calculei.app">
      <!-- <img src="assets/img/brand/banner-logo-dark.svg" /> -->
      <img src="assets/img/brand/logo-light-default.svg" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbar-collapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse navbar-custom-collapse collapse"
      id="collapseBasic" [collapse]="isCollapsed">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a href="https://calculei.app">
              <!-- <img src="assets/img/brand/banner-logo-light.svg" /> -->
              <img src="assets/img/brand/logo-light-default.svg" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbar-collapse"
              aria-controls="navbar-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              (click)="isCollapsed = !isCollapsed">
              <span></span> <span></span>
            </button>
          </div>
        </div>
      </div>

      <hr class="d-lg-none" />
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Facebook">
            <i class="fab fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Instagram">
            <i class="fab fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://twitter.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Twitter">
            <i class="fab fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="login()">
            <span class="nav-link-inner--text">Entrar</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class=" container-fluid mt-5">

  <div class=" row">
    <div class=" col-lg-12">
      <div class="  ">

        <div class="pb-4">

          <div class="card">
            <div class="row">
              <div class="col">
                <div class="card-body">

                </div>
              </div>

            </div>

            <div class="row justify-content-center py-2">
              <div *ngIf="!concluido" class="col-12 text-center">
                <span class="h2 ">Criar conta no Calculei</span>
              </div>

              <div *ngIf="concluido" class="col-12 text-center">
                <span class="h2 ">Pronto!</span>
              </div>

            </div>

            <div class="row justify-content-center mt-6 d-flex col-flex">

              

              <div *ngIf="concluido" class="col-12 col-lg-4 py-1 d-flex">

                <div class="card justify-content-center text-center p-4">
                  <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Enviamos um link de confirmação para o email {{criarContaRequest.email}}
                  </span>

                  <span class="p-4">
                    Agora basta clicar no botão de confirmação no e-mail enviado na sua caixa de entrada e seu login estará pronto! Esta tela você pode fechar quando quiser. Obrigado :)
                  </span>

                </div>

              </div>

              <div *ngIf="!concluido" class="col-12 col-lg-4 py-1 d-flex">
                <div class=" card">
                  <div class=" card-body">

                    <form [formGroup]="requestForm">

                      <div class="row py-4">

                        <div class="col-sm-12 col-md-6 py-2">

                          <h5 class=" card-title text-uppercase mb-0 text-default">
                            Nome
                          </h5>

                          <input type="text" class=" form-control text-left"
                            placeholder="Seu nome" formControlName="nome"
                            class="form-control" >

                        </div>

                        <div class="col-sm-12 col-md-6 py-2">

                          <h5 class=" card-title text-uppercase mb-0 text-default">
                            Sobrenome
                          </h5>

                          <input type="text" class=" form-control text-left"
                            placeholder="Seu sobrenome" formControlName="sobrenome"
                            class="form-control" >

                        </div>

                        <div class="col-sm-12 col-md-6 py-2">

                          <h5 class=" card-title text-uppercase mb-0 text-default">
                            E-mail
                          </h5>

                          <input type="text" class=" form-control text-left"
                            placeholder="exemplo@dominio.com" formControlName="email" class="form-control"
                            >

                        </div>

                        <div class="col-sm-12 col-md-6 py-2">

                          <h5 class=" card-title text-uppercase mb-0 text-default">
                            Senha
                          </h5>

                          <div class="input-group">
                            <input [type]="visualizarSenha? 'text' : 'password'" class=" form-control text-left"
                              placeholder="Senha do calculei" formControlName="senha" class="form-control"
                              >

                            <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="fa " [ngClass]="{'fa-eye-slash' : !visualizarSenha, 'fa-eye' : visualizarSenha}" (click)="trocarVisualizarSenha()"></i>
                              </span>
                            </div>

                          </div>

                        </div>

                      </div>

                    </form>

                    <div class="row">
                      <div class="col-12">
                        <div class="col-xl-12 col-md-12 col-lg-12  d-flex flex-row-reverse">
                          <div class="row ">

                            <div class="col-auto "
                              [className]="aguardeVisivel ? 'visible col-auto' : 'invisible col-auto'">
                              <div class="col-1 p-4 mb-5  controlenav  ">
                                <span class=" timeline-step timeline-step-xlg text-default">
                                  <h1 class="display-1">
                                    <i class="fas fa-spinner fa-spin fa-2xl"></i>
                                  </h1>
                                </span>
                              </div>
                            </div>
                            <div class="col-auto "
                              [className]="continuarHabilitado ? 'habilitado col-auto' : 'desabilitado col-auto'"
                              (click)="criarConta()">
                              <div class="col-1 p-4 mb-5 active controlenav ">
                                <span class=" timeline-step timeline-step-xlg  ">
                                  <i class="bi-arrow-right"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>



              <div class="col-12 col-lg-4 py-1 d-flex ">

                <div class="card justify-content-center text-right p-4 w-100">
                  <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Investimento
                  </span>
                  <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Previdenciário
                  </span>
                  
                  <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    [simples]
                  </span>
                </div>

              </div>


            </div>

          </div>

        </div>

      </div>

    </div>
  </div>