
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CriarAssinaturaData } from 'src/app/modelos/safe2pay/criarassinaturadata';
import { CriarPlanoRequest } from 'src/app/modelos/safe2pay/criarplanorequest';
import { Pagamento } from 'src/app/modelos/safe2pay/pagamento';
import { PagamentoPixDinamicoResponse } from 'src/app/modelos/safe2pay/pagamentopixdinamicoresponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerificarPagamentoPixDinamicoService {

  constructor(private httpClient: HttpClient) {

  }

  async verificacao(request: PagamentoPixDinamicoResponse) {
    let verificado: boolean = false;
    while (!verificado) {
      // other code
      // console.log('tentativa verificacao');
      var [data] = await Promise.all([
        // listFiles(nextPageToken).then(requestParents),
        this.apicall(request),
        this.timeout(3000)
      ]);

      if ((data as Pagamento).processado) {
        verificado = true;
        // console.log('pagamento verificado');
      }
      // other code
    }
    return data;
  }

  public async apicall(request: PagamentoPixDinamicoResponse) {
    const headers = { 'Access-Control-Allow-Origin': '*' };
    let data;
    // console.log('nova tentativa verificacao de pagamento')
    data = await this.httpClient.post(environment.api as string + 'api/VerificarPagamentoPixDinamico', request, { 'headers': headers }).toPromise();

    return data;
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}