import { ResponseDetail } from './../../../../../modelos/safe2pay/pagamentopixdinamicoresponse';
import { IdentificacaoFiliado } from './../../../../../modelos/previdenciario/identificacaofiliado';
import { LeituraCNIS } from './../../../../../modelos/previdenciario/leituracnis';
import { Global } from './../../../../../modelos/global';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { NotificacoesService } from './../../../../../servicos/ui/notificacoes.service';
import { ListarPDFService } from './../../../../../servicos/relatorio/listar-pdf.service';
import { CalculoBeneficios } from './../../../../../modelos/previdenciario/calculobeneficios';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, AfterViewInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import Chart from 'chart.js/auto';

import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';

import * as Utils from './utils.js';


import { CNIS } from 'src/app/modelos/previdenciario/cnis'
import { ListartBuracosPeriodoContributivoService } from 'src/app/servicos/calculo/previdenciario/listart-buracos-periodo-contributivo.service';
import { PlotBuracoPeriodoContributivo } from 'src/app/modelos/previdenciario/plotburacoperiodocontributivo.js';
import { DATE_PIPE_DEFAULT_TIMEZONE, DatePipe, formatDate } from '@angular/common';
import * as moment from 'moment';

import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { ptBR } from 'date-fns/locale';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';





@Component({
  selector: 'app-buraco-tempo-contribuicao',
  templateUrl: './buraco-tempo-contribuicao.component.html',
  styleUrls: ['./buraco-tempo-contribuicao.component.scss']
})
export class BuracoTempoContribuicaoComponent implements OnInit, AfterViewInit, OnChanges {

  // @Input() cnis: CNIS;
  @Input() calculoBeneficios: CalculoBeneficios;
  @Input() visualizarPdf: boolean = true;
  chartId: string;

  constructor(private listarBuracosPeriodoContributivoService: ListartBuracosPeriodoContributivoService, private datePipe: DatePipe,
    private listarPDFService: ListarPDFService,
    private calculeiApiService:CalculeiApiService,
    private notificacoesService: NotificacoesService) {


  }
  ngOnChanges(changes: SimpleChanges): void {
    
  }

  data: any;
  buracosPeriodoContributivo: PlotBuracoPeriodoContributivo;
  datasets;
  chart: Chart;
  aguardePdf: boolean;



  ngOnInit(): void {
    this.chartId = uuidv4();
  }



  carregarDados(calculoBeneficios:CalculoBeneficios) {

    // console.log('carregar dados timeinlie');
    

    this.calculoBeneficios = calculoBeneficios;
    

    if (!this.calculoBeneficios || !this.calculoBeneficios.leituraCnis || !this.calculoBeneficios.leituraCnis.cnis) return;


    if (this.chart) { this.chart.destroy(); this.chart = null; }

    if (this.calculoBeneficios.leituraCnis.cnis.plotBuracoPeriodoContributivo) {
      this.buracosPeriodoContributivo = this.calculoBeneficios.leituraCnis.cnis.plotBuracoPeriodoContributivo;
      this.plotar();

    } else {
      this.listarBuracosPeriodoContributivoService.listarBuracosPeriodoContributivo(this.calculoBeneficios.leituraCnis.cnis)
        .then(plot => {
          this.buracosPeriodoContributivo = plot as PlotBuracoPeriodoContributivo;
          this.plotar();
        });

    }


  }

  plotar() {
    this.datasets = this.buracosPeriodoContributivo.datasets.map(dataset => {
      return {
        label: dataset.label,
        backgroundColor: dataset.backgroundColor,
        borderColor: dataset.backgroundColor,
        fill: false,
        data: dataset.data
      };
    });

    this.data = {
      datasets: this.datasets,
    };

    // Chart.register(zoomPlugin);
    // Chart.register(annotationPlugin);
    this.chart = new Chart(document.getElementById('chartBuracoTempoContributivo' + this.chartId) as HTMLCanvasElement, {
      type: 'line',
      data: this.data,
      
      options: {
        animation:{
          duration:0
        },
        onClick: (e, activeEls) => {
          // const canvasPosition = Chart.helpers.getRelativePosition(e, chart);

        },
        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },
        interaction: {
          mode: 'nearest',
        },
        
        plugins: {
          title: {
            display: false,
            text: 'Sequências do CNIS',
            font: {
              size: 16
            }
          },
          legend:{
            display:this.visualizarPdf,
            
            labels:{
              boxHeight:1,
              // usePointStyle:true,
              
              
            }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              // size: 24
            },
            titleFont: {
              // size: 24

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';
                return label;

              },
              title: (tooltipItems) => {


                return new Intl.DateTimeFormat('pt-BR', {
                }).format(new Date(tooltipItems[0].raw['x']));
              },
            }
          },
          // zoom: {
          //   limits: {

          //     x: { min: 'original', max: Date.now().valueOf(), minRange: 10 }
          //   },
          //   zoom: {
          //     wheel: {
          //       enabled: false,
          //     },
          //     pinch: {
          //       enabled: false,
          //     },
          //     drag: {
          //       enabled: false,

          //     },
          //     mode: 'x',
          //   }
          // }
        },
        scales: {
          x: {
            type: 'time',
            // min: new Date(1960,0,1).toJSON(),
            max: new Date(this.calculoBeneficios.dataCalculo).toISOString(),
            display: true,
            title: {
              display: true,
              text: 'Período entre a data da primeira sequência e a data atual'
            },
            adapters: { date: { locale: ptBR } },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              major: {
                enabled: true
              },
              font: function (context) {
                if (context.tick && context.tick.major) {
                  return {
                    weight: 'bold',
                  };
                }
              }
            },
            time: {
              minUnit: 'year',
              displayFormats: {
                quarter: 'MM/YYYY',
                hour: 'HH:mm',
                minute: 'HH:mm',
                second: 'HH:mm:ss',
                month: 'MM'

              }
            }
          },
          y: {
            suggestedMax:0,
            suggestedMin:0,
            beginAtZero:true,
            grace:0,
            display: false,
            ticks:{
              display:false,
              padding:0,
              stepSize:10,
              backdropPadding:0,
              

            },

            title: {
              display: true,
              text: 'value'
            },
            

          }
        }
      },
    });
  }

  removerZoom() {
    this.chart.resetZoom();
  }



  // ngOnChanges(changes: SimpleChanges): void {
  //   if (this.chart) {
  //     this.carregarDados();
  //   }
  // }
  ngAfterViewInit() {

    this.carregarDados(this.calculoBeneficios);
  }


  gerarPDF() {

    this.aguardePdf = true;
    this.calculoBeneficios.tipoCalculo = CalculoTipos.JANELASPERIODOCONTRIBUTIVO;
    this.calculoBeneficios.usuario = Global.usuario;


    this.calculeiApiService.apiBlobLx("ListarPDF", this.calculoBeneficios)
      .subscribe(blob => {

        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'LinhaTempo-Periodo-Contributivo' + this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        this.aguardePdf = false;



      });
  }


}

