<div *ngIf="beneficio" class="row">
    <div class=" col-12">



        <h5 class=" h3 mb-0 linha-quadro">Análise de Contribuições Pré Plano Real - Tempo de Ouro</h5>

        <div class="row py-4">
            <div class="col-12">
                <div class="row">
                    <div class="col-6 py-4 continuo ">


                        <h1 class=" display-2 card-title  mb-0 ">

                            {{beneficio.relatorioRendaMensalInicial.contribuicoesPrePlanoReal.length}}
                            
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Contribuições Pré-Plano Real
                        </span>

                        
                     
                    </div>



                    <div class="col-6 py-4 continuo ">

                        <h1 class=" display-2 card-title  mb-0 ">

                            {{beneficio.relatorioRendaMensalInicial.contribuicoes.length}}
                            
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Contribuições Pós-Plano Real
                        </span>

                        
                    </div>

                    <div class="col-6 py-4 continuo destaque-contraste-ouro">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{beneficio.relatorioRendaMensalInicial.redutorDivisorMinimoPrePlanoReal}}
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Contribuições Pré-Plano Real Redutoras de Divisor
                        </span>
        
        
                    </div>

                  

                </div>
               
            </div>

        </div>
        
        <div class="row py-4">
            <div class="col-12 ">
                <div class="row d-flex justify-content-end">
                    

                    <div class="col-6 py-4 continuo destaque-contraste">
                        <h1 class=" display-2 card-title  mb-0 ">

                            {{beneficio.totalContribuicoesPlanejamento}}
                            
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Contribuições futuras para cumprimento de Carência
                        </span>
                    </div>

                </div>
               
            </div>

        </div>



        
    </div>
</div>