import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';


import Chart from "chart.js/auto";

import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CurrencyPipe, DatePipe } from '@angular/common';

import annotationPlugin from 'chartjs-plugin-annotation';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { StatusBeneficio } from 'src/app/modelos/previdenciario/statusbeneficio';
import { Colors } from 'chart.js';




@Component({
  selector: 'app-ranking-rmi',
  templateUrl: './ranking-rmi.component.html',
  styleUrls: ['./ranking-rmi.component.scss']
})
export class RankingRmiComponent implements OnInit, OnChanges {



  @Output() rmiSelecionadaEvent = new EventEmitter<Beneficio>();
  @Input() calculoBeneficios: CalculoBeneficios;
  @Input() beneficios: Beneficio[];


  chart: Chart;

  public data: any[];
  public dataRevisao: any[];

  dataSetDinamico: any;

  public salesChart;
  public clicked: boolean = true;
  public clicked1: boolean = false;

  barColors: any;
  datasetRmi: any;
  datasetRmiRevisao: any;
  datasets: any;

  constructor(private currency: CurrencyPipe,
    private changeDetector: ChangeDetectorRef, private datePipe: DatePipe) { }

  ngOnChanges(changes: SimpleChanges): void {

    // console.log('data change ranking rmi');
    if (this.chart) {
      this.changeDetector.detectChanges();
      // console.log('update chart');
      this.carregarDados();
      this.changeDetector.detectChanges();
    }
  }

  carregarDados() {


    if (this.chart) { this.chart.destroy(); this.chart = null; }

    this.data = this.beneficios.map(beneficio => { return { nome: beneficio.qualificacao + ' - ' + beneficio.qualificacaoDetalhada, valor: beneficio.relatorioRendaMensalInicial.rendaMensalInicial, beneficio: beneficio }; });

    this.barColors = [];

    // this.data.map(data=>{
    //   console.log(data.beneficio.status);

    //   this.barColors[this.data.indexOf(data)] = '#f5365caa';
    //   if (data.beneficio.status === StatusBeneficio[StatusBeneficio.Apto]) {
    //     this.barColors[this.data.indexOf(data)] = '#2dce89aa';
    //   }
    // });



    this.dataRevisao = this.beneficios.filter(beneficio => beneficio.revisaoVidaToda).map(beneficio => { return { nome: beneficio.qualificacao + ' - ' + beneficio.qualificacaoDetalhada, valor: beneficio.relatorioRendaMensalInicial.rendaMensalInicial, beneficio: beneficio }; });
    this.datasetRmi = { label: "Valor da RMI", backgroundColor: this.barColors, data: this.data.map(beneficio => beneficio.valor), beneficios: this.data.map(beneficio => beneficio) }
    this.datasetRmiRevisao = { label: "Valor da RMI com Revisão da Vida Toda", backgroundColor: '#11cdef', data: this.dataRevisao.map(beneficio => beneficio.valor), beneficios: this.data.map(beneficio => beneficio) }

    this.datasetRmi.data = this.datasetRmi.data.sort((a, b) => b - a);
    this.datasetRmiRevisao.data = this.datasetRmiRevisao.data.sort((a, b) => b - a);



    // console.log('labels');
    // console.log(this.beneficios.filter(beneficio=>beneficio.revisaoVidaToda).map(beneficio=>beneficio.qualificacao ));

    this.dataSetDinamico = {
      labels: this.beneficios.filter(beneficio => beneficio.revisaoVidaToda).map(beneficio => beneficio.qualificacao),
      datasets: [
        {
          data: this.data.filter(beneficio => beneficio.beneficio.revisaoVidaToda).map(beneficio => beneficio.valor),
          label: 'RMI com Revisão da Vida Toda',
          beneficios: this.data.filter(beneficio => beneficio.beneficio.revisaoVidaToda),
          backgroundColor: '#11cdef'
        },
        {
          data: this.data.filter(beneficio => !beneficio.beneficio.revisaoVidaToda).map(beneficio => beneficio.valor),
          label: 'RMI',
          beneficios: this.data.filter(beneficio => !beneficio.beneficio.revisaoVidaToda),
          backgroundColor: '#172b4d'

        }
      ]


    }





    this.datasetRmi = {
      labels: this.beneficios.map(beneficio => beneficio.qualificacao + ' - ' + beneficio.qualificacaoDetalhada),
      datasets: [
        {
          data: this.data.map(beneficio => beneficio.valor),
          label: 'RMI',
          backgroundColor: this.data.map(beneficio => beneficio.beneficio.status === StatusBeneficio[StatusBeneficio.Apto] ? '#2dce89aa' : '#f5365caa'),
          beneficios: this.data,
        }

      ]


    }


    this.datasets = this.beneficios.filter(beneficio => beneficio.revisaoVidaToda).length > 0 ? this.dataSetDinamico : this.datasetRmi;

    Chart.register(Colors);
    Chart.register(annotationPlugin);
    this.chart = new Chart(document.getElementById('chartRankingRmi') as HTMLCanvasElement, {
      type: 'bar',
      data: this.datasets,
      options: {
        onClick: (e, activeEls) => {

          // console.log(this.datasets.datasets[activeEls[0].datasetIndex].beneficios[activeEls[0].index].beneficio);
          this.rmiSelecionadaEvent.emit(this.datasets.datasets[activeEls[0].datasetIndex].beneficios[activeEls[0].index].beneficio as Beneficio)
        },
        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'RMI por regra',
            font: {
              size: 16
            }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              size: 24,
              weight: 'normal',
            },
            titleFont: {
              size: 24,
              weight: 'normal'

            },
            footerFont: {
              size: 12,
              weight: 'normal',

            },
            callbacks: {
              label: (context) => {
                let label = 'Status: ';

                if (label) {
                  label +=  this.data[context.dataIndex].beneficio.status === StatusBeneficio[StatusBeneficio.Apto] ? 'Apto' : 'Não Apto';
                }

                label += ', ';

                label += context.dataset.label;

                label += ' ';


                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.currency.transform(context.parsed.y) + ', data ' + this.datePipe.transform(this.data[context.dataIndex].beneficio.dataCalculo, 'dd/MM/yyyy');
                }
                return label;
              },
              title: (tooltipItens) => {
                return tooltipItens[0].label;
              },
              footer: (tooltipItens) => {
                return 'clique na barra para ver mais'
              }
            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value, index, ticks) => {
                return this.currency.transform(value);
              }
            },
            title: {
              display: true,
              text: 'Valor da RMI'
            },



          },
          x: {

            ticks: {
              display: false,

            },
            title: {
              display: true,
              text: 'Tipos de Benefício'
            },
          }
        }
      }

    });

  }

  ngOnInit() {

    this.carregarDados();






  }

}

