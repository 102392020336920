<h5 class=" h3 mb-0 linha-quadro">Administrador</h5>

<div class="row">
    <div class="col">
        <p>
            Opções de Administrador
        </p>
    </div>
    
</div>

<div class="row">
    <div class="col">
        <p>
            Teste API
        </p>
    </div>

    <div class="col">
        <button class="btn btn-outline-default" (click)="testeApi()">Teste API</button>
    </div>
    
</div>
<div class="row">
    <div class="col">
        <p>
            Sair
        </p>
    </div>

    <div class="col">
        <button class="btn btn-outline-default" (click)="logout()">Sair</button>
    </div>
    
</div>
<app-usuarios></app-usuarios>