import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { CnisListagemComponent } from 'src/app/aplicacao/dashboard/compartilhado-dashboard/cnis-listagem/cnis-listagem.component';

@Component({
  selector: 'app-cnis-geral-modal',
  templateUrl: './cnis-geral-modal.component.html',
  styleUrls: ['./cnis-geral-modal.component.scss']
})
export class CnisGeralModalComponent {

  @ViewChild('longContent') long: HTMLElement;
  @ViewChild('seletorCnis', { static: false }) seletorCnis?: CnisListagemComponent;

  @Output() cnisSelecionadoEvent = new EventEmitter<CalculoBeneficios>();

  modalControle:HTMLElement;

  constructor(private modalService: NgbModal, private configService:ConfigService){
    this.calculoBeneficios = new CalculoBeneficios();
    this.configService.getAguarde().subscribe(aguarde=>this.aguardeVisivel = aguarde);


  }

  
  
  modalRef: NgbModalRef;
  aguardeVisivel:boolean;
  calculoBeneficios:CalculoBeneficios;
  visualizarSeletorCnis:boolean =true;
  visualizarEditorCnis:boolean = false;
  continuarHabilitado:boolean;


  public openModal() {

    this.modalRef = this.modalService.open(this.long, { size: 'xl', scrollable: true, centered: true });
  }

  leituraCNISEvent(leituraCNIS: LeituraCNIS) {
    this.aguardeVisivel = false;
    this.calculoBeneficios = new CalculoBeneficios();
    this.calculoBeneficios.leituraCnis = leituraCNIS;
    this.visualizarSeletorCnis = false;
    this.visualizarEditorCnis = true;
    this.configService.setAguarde(false);
    this.continuarHabilitado = true;
  }

  processandoCNISEvent(processando: boolean) {
    this.aguardeVisivel = processando;
    let element = document.getElementsByClassName("modal-body")[0].scrollTo({top:0,behavior:'smooth'});
    this.configService.setAguarde(false);
    
    
    
  }

  cancelar(){

    this.modalRef.close();

  }
  continuar(){

    if(!this.continuarHabilitado)return;

    this.cnisSelecionadoEvent.emit(this.calculoBeneficios);
    this.cancelar();

  }


}
