import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Entrevista } from 'src/app/modelos/previdenciario/entrevista';

@Component({
  selector: 'app-entrevistas-dashboard',
  templateUrl: './entrevistas-dashboard.component.html',
  styleUrl: './entrevistas-dashboard.component.scss'
})
export class EntrevistasDashboardComponent {

  atualizandoListaEntrevistas:boolean;
  listaEntrevistas:Entrevista[];

  constructor(private router:Router){}

  novaEntrevista(){

  }

  abrirEntrevista(entrevista:Entrevista){


    const navigationPayload: NavigationExtras = {
      state: { entrevista: entrevista }
    }
    this.router.navigate(["/calculei/entrevistas/entrevista"], navigationPayload)
    // console.log('resultado abrir calculobeneficios', calculoBeneficios);
  }

}
