import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestRelatorioRendaMensalInicial } from 'src/app/modelos/previdenciario/requestrelatoriorendamensalinicial';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelatorioRendaMensalInicialService {

  constructor(private httpClient: HttpClient) { }

  relatorio(request:RequestRelatorioRendaMensalInicial){
    return Promise.all([this.apiCall(request)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(request:RequestRelatorioRendaMensalInicial){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/GerarRelatorioRendaMensalInicial',request, {'headers':headers}).toPromise();
    return data;
  }
}
