<div *ngIf="calculoPlanejamento" class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body p-0">
                    <div class="row p-2">
                        <div class="col-md-6">
                            <!-- <img src={{logoUrl}} width="100px"> -->
                        </div>

                        <div class="col-md-6 text-right">
                            <p class="font-weight-bold mb-1 h1">Relatório de Planejamento Contributivo Previdenciário</p>
                            <p class="mb-1 h2">Cliente {{melhorCaso.beneficio.cnis.identificacaoFiliado.nome}}</p>
                        </div>
                    </div>

                    <hr class="my-5">


                    <div class="row py-4 p-2">
                        <div class="col">
                            <span class="display-1">Planejamento Previdenciário</span>
                        </div>
                    </div>

                    <div class="row py-4 p-2">
                        <div class="col">
                            <span class="display-2">Dados do Planejamento</span>
                        </div>
                    </div>

                    <div class="row pt-2 pb-6 p-2">
                        <div class="col-6">
                            <p class="font-weight-bold mb-4 display-3">Cliente</p>
                            <p class="mb-1 h1"><span class="text-muted h1">Nome </span>
                                {{melhorCaso.beneficio.cnis.identificacaoFiliado.nome}}</p>
                            <p class="mb-1 h1"><span class="text-muted h1">CPF </span>
                                {{melhorCaso.beneficio.cnis.identificacaoFiliado.cpf}}</p>
                            <p class="mb-1 h1"><span class="text-muted h1">Sexo </span>
                                {{melhorCaso.beneficio.cnis.identificacaoFiliado.sexo }}</p>
                            <p class="mb-1 h1"><span class="text-muted h1">Data Nascimento </span>
                                {{melhorCaso.beneficio.cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}
                            </p>

                            <p class="mb-1 h1"><span class="text-muted h1">Idade </span>
                                {{melhorCaso.beneficio.cnis.identificacaoFiliado.idade.porExtenso}}</p>

                        </div>

                        <div class="col-6">
                            <p class="font-weight-bold mb-4 display-3">Planejamento</p>
                            <p class="mb-1 h1"><span class="text-muted h1">Data do Planejamento </span>
                                {{melhorCaso.dataPlanejamento | date : 'dd/MM/yyyy'}}</p>

                            <p class="mb-1 h1"><span class="text-muted h1">Cenários Analisados </span> {{grupos.length}}</p>

                            <p class="mb-1 h1">
                                <span class="text-muted h1">Variações de Salário e Alíquota </span><span *ngFor="let enquadramento of grupos[0].enquadramentos" >
                                    {{enquadramento.aliquota * 100}}% sobre {{enquadramento.base | currency}};
                                </span>
                            </p>
                             
                            
                            <p class="mb-1 h1"><span class="text-muted h1">Diretiva para escolha da quantidade de Contribuições </span>
                                {{calculoPlanejamento.opcoesPlanejamento.opcaoNumeroContribuicoes | opcaonumerocontribuicoes}}</p>


                                
                                
                            <p class="mb-1 h1"><span class="text-muted h1">Imposto de Renda </span>
                                {{calculoPlanejamento.opcoesPlanejamento.opcaoImpostoRenda | opcoesimpostorendaplanejamento}}</p>

                        </div>
                       
                    </div>



                    <div class="row py-4 p-2">
                        <div class="col">
                            <span class="display-3">Sumário de Resultados para o perfil contributivo de {{calculoPlanejamento.opcoesPlanejamento.edicaoSalarioAliquota.aliquota * 100}}% sobre Salário de {{calculoPlanejamento.opcoesPlanejamento.edicaoSalarioAliquota.salario | currency}} </span>
                        </div>
                    </div>


                    <div class="row pt-2 pb-6 p-2">
                        <div class="col-4">

                            <p class="font-weight-bold mb-4 display-3">Cenário com valor mais alto </p>
                            <p class="mb-1 h1"><span class="text-muted h1">Tipo </span> {{maiorRMI.beneficio.qualificacao}}
                            </p>
                            <p class="mb-1 h1"><span class="text-muted h1">Data Prevista </span>
                                {{maiorRMI.beneficio.dataCalculo | date : 'dd/MM/yyyy'}}</p>
                            <p class="mb-1 h1"><span class="text-muted h1">Valor </span> {{maiorRMI.valorBeneficio |
                                currency}}</p>
                            <p class="mb-1 h1"><span class="text-muted h1">Meses de Contribuição até a Aposentadoria </span>
                                {{maiorRMI.totalContributivoComplementarMeses}}</p>
                            <p class="mb-1 h1"><span class="text-muted h1">Idade do Cliente na Data Prevista </span>
                                {{maiorRMI.beneficio.idade.porExtenso}}</p>
                                <p class="mb-1 h1"><span class="text-muted h1">Lucro </span> {{maiorRMI.retornoTotalInvestimentoRoi |
                                    currency}}</p>

                        </div>
                        <div class="col-4">
                            <p class="font-weight-bold mb-4 display-3">Cenário com aposentadoria mais rápida  </p>
                                        <p class="mb-1 h1"><span class="text-muted h1">Tipo </span> {{maisRapido.beneficio.qualificacao}}
                                        </p>
                                        <p class="mb-1 h1"><span class="text-muted h1">Data Prevista </span>
                                            {{maisRapido.beneficio.dataCalculo | date : 'dd/MM/yyyy'}}</p>
                                        <p class="mb-1 h1"><span class="text-muted h1">Valor </span> {{maisRapido.valorBeneficio |
                                            currency}}</p>
                                        <p class="mb-1 h1"><span class="text-muted h1">Meses de Contribuição até a Aposentadoria </span>
                                            {{maisRapido.totalContributivoComplementarMeses}}</p>
                                        <p class="mb-1 h1"><span class="text-muted h1">Idade do Cliente na Data Prevista </span>
                                            {{maisRapido.beneficio.idade.porExtenso}}</p>
                                            <p class="mb-1 h1"><span class="text-muted h1">Lucro </span> {{maisRapido.retornoTotalInvestimentoRoi |
                                                currency}}</p>
                        </div>
                        <div class="col-4">

                            <p class="font-weight-bold mb-4 display-3">Cenário com maior lucro</p>
                                    <p class="mb-1 h1"><span class="text-muted h1">Tipo </span>
                                        {{maiorLucro.beneficio.qualificacao}}</p>
                                    <p class="mb-1 h1"><span class="text-muted h1">Data Prevista </span>
                                        {{maiorLucro.beneficio.dataCalculo | date : 'dd/MM/yyyy'}}</p>
                                    <p class="mb-1 h1"><span class="text-muted h1">Valor </span> {{maiorLucro.valorBeneficio |
                                        currency}}</p>
                                    <p class="mb-1 h1"><span class="text-muted h1">Meses de Contribuição até a Aposentadoria
                                        </span> {{maiorLucro.totalContributivoComplementarMeses}}</p>
                                    <p class="mb-1 h1"><span class="text-muted h1">Idade do Cliente na Data Prevista </span>
                                        {{maiorLucro.beneficio.idade.porExtenso}}</p>

                                        <p class="mb-1 h1"><span class="text-muted h1">Lucro </span> {{maiorLucro.retornoTotalInvestimentoRoi |
                                            currency}}</p>

                            
                        </div>


                    </div>

                    <div class="row py-4 p-2">
                        <div class="col">
                            <span class="display-3">Análise dos Cenários</span>
                        </div>
                    </div>

                    <div *ngFor="let grupo of grupos" class="row pt-2 pb-6 p-2">
                        <div class="col-12 py-2">
                            <span class="h3 py-2 display-2 ">{{grupo.qualificacaoBeneficio}}</span>
                            <span class="h3 py-2 display-2"> - Data Prevista {{grupo.casos[0].beneficio.dataCalculo | date :
                                'dd/MM/yyyy'}}</span>
                            <table class="table">
                                <thead style="display: table-header-group;">
                                    <tr>
                                        <th scope="col" class="h1"></th>
                                        <th scope="col" *ngFor="let enquadramento of grupo.enquadramentos"
                                            class="h1">
                                            <span class="h2">{{enquadramento.aliquota * 100}}% sobre {{enquadramento.base | currency}} </span></th>

                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col h2"><p class="h2">Valor do Benefício</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"><p class="h3"> {{caso.valorBeneficio | currency}}</p> </td>
                                    </tr>

                                    <tr>
                                        <th scope="col h2"><p class="h2">Valor da Contribuição</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.valorContribuicaoComplementarMensal | currency}}</p> </td>
                                    </tr>

                                    <tr>
                                        <th scope="col h2"><p class="h2">Quantidade de Contribuições</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.totalContributivoComplementarMeses}} </p></td>
                                    </tr>

                                    <tr>
                                        <th scope="col h2"><p class="h2">Investimento em Contribuições</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.totalInvestimento | currency}}</p>
                                        </td>
                                    </tr>

                                    <!-- <tr>
                                        <th scope="col h2"><p class="h2">Custo Contributivo Mensal de Imposto de Renda</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3"> {{caso.custoContributivoMensalImpostoRenda
                                            | currency}}</p> </td>
                                    </tr> -->

                                    <!-- <tr>
                                        <th scope="col h2"><p class="h2">Custo Contributivo Total de Imposto de Renda</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.custoContributivoTotalImpostoRenda
                                            | currency}}</p> </td>
                                    </tr> -->
                                    <!-- <tr>
                                        <th scope="col h2"><p class="h2">Investimento e Custo Total Contributivo INSS + IRPF</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.totalInvestimento +
                                            caso.custoContributivoTotalImpostoRenda| currency}}</p> </td>
                                    </tr> -->

                                    <tr>
                                        <th scope="col h2"><p class="h2">PAYBACK - Tempo de Retorno do Investimento</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.tempoRetornoInvestimentoMesesAbono | number : '1.0-0'}} meses</p></td>
                                    </tr>

                                    <tr>
                                        <th scope="col h2"><p class="h2">Data no PAYBACK Completo</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.dataPayback | date : 'dd/MM/yyyy'}}</p></td>
                                    </tr>


                                    <tr>
                                        <th scope="col h2"><p class="h2">Idade na Data do PAYBACK</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"><p class="h3">{{caso.idadePayback.porExtenso}}</p> </td>
                                    </tr>


                                    <tr>
                                        <th scope="col h2"><p class="h2">Expectativa de Vida (IBGE)</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.expectativaVida}} anos</p></td>
                                    </tr>

                                    <tr>
                                        <th scope="col h2"><p class="h2">IRPF sobre Benefício Sem Isenção até 65 Anos</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.totalImpostoRendaSemIsencao65 | currency}}</p> </td>
                                    </tr>

                                    <tr>
                                        <th scope="col h2"><p class="h2">IRPF sobre Benefício Com Isenção após 65 Anos</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.totalImpostoRendaComIsencao65 | currency}}</p> </td>
                                    </tr>

                                    <tr>
                                        <th scope="col h2"><p class="h2">Total IRPF sobre Benefício na Expectativa de Vida (IBGE)</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"><p class="h3">{{caso.totalImpostoRenda | currency}}</p>  </td>
                                    </tr>


                                    <tr>
                                        
                                        <th scope="col h2"><p class="h2">ROI - Lucro Líquido</p></th>
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.retornoTotalInvestimentoRoi | currency}}</p> </td>
                                    </tr>

                                    <tr>
                                        
                                        <th scope="col h2"><p class="h2">Expectativa de Taaxa de Inflação</p></th>
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.expectativaInflacao * 100 | number : '1.2-2'}}%</p> </td>
                                    </tr>

                                    <tr>
                                        
                                        <th scope="col h2"><p class="h2">Total da Deflação do Lucro</p></th>
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.totalDeflacao | currency}}</p> </td>
                                    </tr>

                                    <tr>
                                        
                                        <th scope="col h2"><p class="h2">ROI - Lucro Líquido depois da Inflação</p></th>
                                        
                                        <td *ngFor="let caso of grupo.casos"> <p class="h3">{{caso.retornoTotalInvestimentoRoiDeflacionado | currency}}</p> </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>