export enum ProtocolosAplicacaoJurosPrevidenciario{
    SEMJUROS,
    MENSAL05,
    GLOBAL10,
    MENSAL0501200310022003062009005072009ATUAL,
    MENSAL050120031002200306200905072009062012POUPANCA072012ATUAL,
    MENSAL1006200905072009062012POUPANCA072012ATUAL,
    
    MANUALCALCULOJUSTICAFEDERAL,
    CRITERIOESPECIFICO
}