import { Component } from '@angular/core';

@Component({
  selector: 'app-sala-entrevistas',
  templateUrl: './sala-entrevistas.component.html',
  styleUrl: './sala-entrevistas.component.scss'
})
export class SalaEntrevistasComponent {

}
