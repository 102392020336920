import { Base } from "../base";
import { Arquivo } from "../faturas/arquivo";
import { Usuario } from "../usuario/usuario";
import { CNIS } from "./cnis";
import { ImportacaoCNIS } from "./importacaocnis";
import { Tempo } from "./tempo";

export class LeituraCNIS extends Base{
    importacaoCnis:ImportacaoCNIS;
    arquivoCnis:Arquivo;
    cnis:CNIS;
    usuario:Usuario;
    nome:string;
    sumario:string;
    descricao:string;
    tempoContribuicao:Tempo;
    dataCriacao:Date;
    dataUltimaModificacao:Date;
    totalVinculos:number;
}