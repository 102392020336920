<div class="row g-3">
    <div class="col-12">
        <h5 class=" h3 mb-0 linha-quadro">Parte</h5>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Nome
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.referencia.nome}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            CPF
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.referencia.cpf}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Número do Processo
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.referencia.numeroProcesso}}
        </p>
    </div>

</div>

<div class="row g-3">
    <div class="col-12">
        <h5 class=" h3 mb-0 linha-quadro">Parâmetros do Cálculo</h5>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Tipo do Processo
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.referencia.tipoProcesso | tipoProcessoLiquidacao}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Tipo do Benefício
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.beneficio.tipoBeneficio | tipoBeneficioLiquidacao}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Data de Início do Benefício
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.beneficio.dib | date : 'dd/MM/yyyy'}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Data de Implantação
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.beneficio.dip | date : 'dd/MM/yyyy'}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Renda Mensal Inicial
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.beneficio.rmi | currency}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Data de Citação
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.liquidacaoSentenca.referencia.dataCitacao | date : 'dd/MM/yyyy'}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Data do Cálculo
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.data | date : 'dd/MM/yyyy'}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Critério de Correção Monetária
        </p>
        <p class="text-default">
            <span class="d-block" *ngFor="let regraCorrecao of calculoLiquidacaoSentenca.liquidacaoSentenca.processo.regrasCorrecaoMonetaria; index as i">
                {{i+1}}) {{regraCorrecao.indice}} de {{regraCorrecao.dataInicial | date : 'dd/MM/yyyy'}} a
                <span *ngIf="(regraCorrecao.dataFinal | checkDataAtualLimite)">
                    {{calculoLiquidacaoSentenca.data | date : 'dd/MM/yyyy'}}
                </span>

                <span *ngIf="!(regraCorrecao.dataFinal | checkDataAtualLimite)">
                    {{ regraCorrecao.dataFinal | date : 'dd/MM/yyyy'}}
                </span>
            </span>

        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Critério de Juros
        </p>
        <p class="text-default">
            <span class="d-block" *ngFor="let regraJuros of calculoLiquidacaoSentenca.liquidacaoSentenca.processo.regrasAplicacaoJuros; index as i">
                {{i+1}}) {{regraJuros.indice}} de {{regraJuros.dataInicial | date : 'dd/MM/yyyy'}} a
                <span *ngIf="(regraJuros.dataFinal | checkDataAtualLimite)">
                    {{calculoLiquidacaoSentenca.data | date : 'dd/MM/yyyy'}}
                </span>

                <span *ngIf="!(regraJuros.dataFinal | checkDataAtualLimite)">
                    {{ regraJuros.dataFinal | date : 'dd/MM/yyyy'}}
                </span>

            </span>
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Honorários de Sucumbência
        </p>
        <p class="text-default">
            <span class="d-block">
                Percentual {{calculoLiquidacaoSentenca.liquidacaoSentenca.honorarios.valorPercentualSucumbencia | number : '1.2-2'}} %
            </span>
            <span class="d-block">
                Data Limite {{calculoLiquidacaoSentenca.liquidacaoSentenca.honorarios.dataLimite | date : 'dd/MM/yyyy'}}
            </span>
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Honorários Contratuais
        </p>
        <p class="text-default">
            <span class="d-block">
                Percentual {{calculoLiquidacaoSentenca.liquidacaoSentenca.honorarios.valorPercentualContratual | number : '1.2-2'}} %
            </span>
        </p>
    </div>

    

</div>