import { ResultadoCalculo } from "../common/resultadocalculo";
import { Usuario } from "../usuario/usuario";
import { RelatorioValorCausaPrevidenciario } from "./relatoriovalorcausaprevidenciario";
import { ValorCausaPrevidenciario } from "./valorcausaprevidenciario";

export class CalculoValorCausaPrevidenciario extends ResultadoCalculo{
    valorCausa:ValorCausaPrevidenciario;
    resumoValorCausa:ValorCausaPrevidenciario;
    relatorioValorCausa:RelatorioValorCausaPrevidenciario;
    usuario:Usuario;
}