import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';


import Chart from "chart.js/auto";

import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CurrencyPipe, DatePipe } from '@angular/common';

import * as moment from 'moment';
import "chartjs-adapter-moment";
import annotationPlugin from 'chartjs-plugin-annotation';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';


@Component({
  selector: 'app-evolucao-salarios',
  templateUrl: './evolucao-salarios.component.html',
  styleUrls: ['./evolucao-salarios.component.scss']
})
export class EvolucaoSalariosComponent implements OnInit, OnChanges {

  @Output() rmiSelecionadaEvent = new EventEmitter<Beneficio>();
  
  @Input() visualizarPdf: boolean = true;

  @Input() calculoPlanejamentoKertzman: CalculoPlanejamentoKertzman;
  contribuicoes:Contribuicao[] = new Array();

  public data: any[];

  chart:Chart;


  media: number;
  aguardePdf:boolean;

  constructor(private currency: CurrencyPipe,
    private listarPDFService:ListarPDFService, private notificacoesService:NotificacoesService,
    private changeDetector: ChangeDetectorRef, private datePipe: DatePipe) {

  }

  average(ctx) {
    const values = ctx.chart.data.datasets[0].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }


  ngOnChanges(changes: SimpleChanges): void {
    // console.log('data change ranking rmi');
    if(this.chart){
      this.changeDetector.detectChanges();
      // console.log('update chart');
      this.carregarDados();
      this.changeDetector.detectChanges();
    }
    
  }

  ngOnInit() {


    this.carregarDados();


    


  }

  carregarDados(){

    if(this.chart){this.chart.destroy(); this.chart = null;}

    moment.locale('pt-BR');

    
    this.contribuicoes = [...this.calculoPlanejamentoKertzman.preAnalise.relatorioRendaMensalInicial.contribuicoesPrePlanoReal, ...this.calculoPlanejamentoKertzman.preAnalise.relatorioRendaMensalInicial.contribuicoes, ];
    

    this.data = this.contribuicoes.map(contribuicao => { return { competencia: contribuicao.competencia, valor: contribuicao.valorAtualizado }; });
    this.data = this.data.sort((a, b) => +new Date(b.competencia) - +new Date(a.competencia));

    let datasetLabels = this.data.map(contribuicao => contribuicao.competencia);


    let dataMedia = this.data.filter(contribuicao => (new Date(contribuicao.competencia)).toISOString() > (new Date(1994,7,1)).toISOString());
    this.media = dataMedia.map(contribuicao => contribuicao.valor).reduce((soma, competencia) => soma += competencia) / dataMedia.length;


    let dataset = {
      labels: datasetLabels, datasets: [
        {
          data: this.data.map(contribuicao => contribuicao.valor),
          label: 'Salário',
          borderColor: '#000000',
          backgroundColor: '#000000',
        }
      ]
    };



    let chart = document.getElementById('chart') as HTMLCanvasElement;


    Chart.register(annotationPlugin);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      options: {
        onClick: (e, activeEls) => {
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          annotation: {
            annotations: {

              line1: {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                yMin: this.media.toFixed(2),
                yMax: this.media.toFixed(2),
                xMin: new Date(1994,7,1).toISOString(),
                xMax: new Date(datasetLabels[0]).toISOString(),
                borderColor: '#000000',
                borderWidth: 2,
                label: {
                  position: 'center',
                  display: true,
                  // font:{
                  //   size:24,
                  //   weight:'normal',
                  // },
                  content: (ctx, options) => {
                    return 'Média ' + this.currency.transform(this.media.toFixed(2));
                  },
                }

              },
            }
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Salários de Contribuição por Competência',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont:{
              size:24,
              weight:'normal',
            },
            titleFont:{
              size:24,
              weight:'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.currency.transform(context.parsed.y);
                }
                return label;
              },
              title: (tooltipItens) => {
                return "Competência " + this.datePipe.transform(tooltipItens[0].parsed.x, 'MM/yyyy');
              },

            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,

            ticks: {
              callback: (value, index, ticks) => {
                return this.currency.transform(value);
              }
            }

          },
          x: {
            type: 'time',
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Competências'
            },




          }
        }
      }

    });
  }






  gerarPDF() {
    this.aguardePdf = true;
    this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.EVOLUCAOSALARIOSPREANALISEKERTZMAN;
    this.calculoPlanejamentoKertzman.usuario = Global.usuario;

    this.listarPDFService.ListarPDF(this.calculoPlanejamentoKertzman)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'EVOLUCAO-SALARIOS-PREANALISE-' + this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');

        this.aguardePdf = false;


      });
  }





}



