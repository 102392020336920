import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';



import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';



export class ErrorIntercept implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                // retry({count:3, delay:30001}),
                catchError((error: HttpErrorResponse) => {
                   
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        
                        
                    } else {
                        // server-side error
                    }
                    
                    return throwError(()=> error);// throwError(error.message);
                })
            )
    }
}