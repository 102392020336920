<div class="row g-3">

    <div class="col-12">
        <h5 class=" h3 mb-0 linha-quadro">Totais</h5>
    </div>
    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Total dos atrasados
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.totalAtrasadosValor | currency}}
            ({{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.totalAtrasadosMeses}} meses)
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Exercícios Anteriores
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.exerciciosAnterioresValor | currency }}
            ({{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.exerciciosAnterioresMeses}} meses)
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Exercício Atual
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.exercicioCorrenteValor | currency }}
            ({{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.exercicioCorrenteMeses}} meses)
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Total
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.totalAtrasadosValor | currency}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Honorários
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.honorarios | currency }}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Total + Honorários
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.total | currency }}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Total Acordo 90%
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.totalAcordo90 | currency}}
        </p>
    </div>

    <div class="col-xl-2 col-sm-2">
        <p class="h2">
            Total Acordo 95%
        </p>
        <p class="text-default">
            {{calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.totalAcordo95 | currency}}
        </p>
    </div>

</div>