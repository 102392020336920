import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalculosComponent } from './calculos.component';
import { NovoCalculoComponent } from './novo-calculo/novo-calculo.component';

const routes: Routes = [

  {
    path: '',
    component:CalculosComponent,
    children:[
      {
        path:'',
        redirectTo:'novocalculo',
        pathMatch:'full'
      },
      {
        path:'novocalculo',
        component:NovoCalculoComponent
      },
      {
        path: 'previdenciario',
        loadChildren: () => import('./previdenciario/previdenciario.module').then(m => m.PrevidenciarioModule)
      },
      {
        path: 'civel',
        loadChildren: () => import('./civel/civel.module').then(m => m.CivelModule)
      },
      
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalculosRoutingModule { }
