import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';


import { Abatimento } from 'src/app/modelos/previdenciario/abatimento';
import { EvolucaoRMI } from 'src/app/modelos/previdenciario/evolucaormi';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import * as currency from 'currency.js';
import { ListarEvolucaoRmiService } from 'src/app/servicos/calculo/previdenciario/listar-evolucao-rmi.service';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { ValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/valorcausaprevidenciario';
import { AbatimentosValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/abatimentosvalorcausaprevidenciario';
import { CalculoValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/calculovalorcausaprevidenciario';
import { AppComponent } from 'src/app/app.component';
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';

@Component({
  selector: 'app-abatimentos-valor-causa-previdenciario',
  templateUrl: './abatimentos-valor-causa-previdenciario.component.html',
  styleUrls: ['./abatimentos-valor-causa-previdenciario.component.scss']
})
export class AbatimentosValorCausaPrevidenciarioComponent implements OnInit {

  @Output() continuarEvent = new EventEmitter<CalculoValorCausaPrevidenciario>();
  @Output() aguardeVisivelEvent = new EventEmitter<boolean>();
  @Input() calculoValorCausa:CalculoValorCausaPrevidenciario;


  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  dateCompetenciaInputMask = createMask<Date>(AppComponent.dateInputOptions);

  currencyInputMask = createMask(AppComponent.currencyInputOptions);


  continuarHabilitado:boolean=false;
  tipoProcesso:TiposProcessoLiquidacaoSentencaPrevidenciario;
  tipoBeneficio:TipoBeneficioLiquidacaoSentencaPrevidenciario;

  novoAbatimentoFormVisivel:boolean=false;
  novaEvolucaoRMIFormVisivel:boolean=false;
  novoHiscreFormVisivel:boolean=false;

  

  novoAbatimentoForm = new UntypedFormGroup({
    competencia: new UntypedFormControl('', Validators.required),
    valor: new UntypedFormControl('', Validators.required)
  });

  novaEvolucaoRMIForm = new UntypedFormGroup({
    competenciaInicial: new UntypedFormControl('', Validators.required),
    competenciaFinal: new UntypedFormControl('', Validators.required),
    valor: new UntypedFormControl('', Validators.required)
  });


  abatimentos:Abatimento[] = [];
  linhasAbatimentos:Abatimento[];

  

  constructor(private listarEvolucaoRmiService:ListarEvolucaoRmiService) { }



  ngOnInit(): void {

    this.tipoProcesso = this.calculoValorCausa.valorCausa.referencia.tipoProcesso;
    this.tipoBeneficio = this.calculoValorCausa.valorCausa.beneficio.tipoBeneficio;
    this.continuarHabilitado = this.tipoProcesso === TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO ? false : true;

    

    this.linhasAbatimentos = this.abatimentos.map((prop,key)=>{
      return {
        ...prop,
        id: key
      };
    });
  }

  atualizarAbatimentosHiscre(abatimentos: Abatimento[]) {
    this.abatimentos = this.abatimentos.concat(abatimentos);
    this.updateTabelaAbatimentos();
  }

  updateTabelaAbatimentos() {
    this.continuarHabilitado = this.abatimentos.length > 0 ? true : false;
    this.linhasAbatimentos = this.abatimentos.map((prop, key) => {
      return {
        ...prop,
        id: key
      };
    });
  }
  
  submitNovoAbatimento(){

    let novo = new Abatimento();
    novo.competencia = this.novoAbatimentoForm.get('competencia').value;
    novo.valor = currency(this.novoAbatimentoForm.get('valor').value, {separator: '.', decimal: ','}).value;  

    this.abatimentos.push(novo);

    this.updateTabelaAbatimentos();

    this.novoAbatimentoFormVisivel = false;


  }
  submitNovaEvolucaoRMI(){
    let novo = new EvolucaoRMI();
    novo.competenciaInicial = this.novaEvolucaoRMIForm.get('competenciaInicial').value;
    novo.competenciaFinal = this.novaEvolucaoRMIForm.get('competenciaFinal').value;
    novo.valor = currency(this.novaEvolucaoRMIForm.get('valor').value, {separator: '.', decimal: ','}).value; 
    novo.tipoBeneficio = this.calculoValorCausa.valorCausa.beneficio.tipoBeneficio;

    this.listarEvolucaoRmiService.listarEvolucaoRMI(novo)
    .then(abatimentos=>{
      this.abatimentos = this.abatimentos.concat(abatimentos as Abatimento[]);
      this.updateTabelaAbatimentos();
    });

    this.novaEvolucaoRMIFormVisivel = false;
  }
  novoAbatimento(){
    this.novoAbatimentoFormVisivel = true;
  }
  novaEvolucaoRMI(){
    this.novaEvolucaoRMIFormVisivel = true;
  }
  novaImportacaoHiscre(){
    this.novoHiscreFormVisivel = true;
  }




  carregarDados(calculo:CalculoValorCausaPrevidenciario){
    this.calculoValorCausa = calculo;
    this.abatimentos = this.calculoValorCausa.valorCausa.abatimentos.abatimentos;
    this.updateTabelaAbatimentos();
  }

  continuar(){
    let abatimentos = new AbatimentosValorCausaPrevidenciario();
    abatimentos.abatimentos = this.abatimentos;
    

    this.calculoValorCausa.valorCausa.abatimentos = abatimentos;

    this.continuarEvent.emit(this.calculoValorCausa);
  }

  cancelarAbatimentoCompetenciaValor(){
    this.novoAbatimentoFormVisivel=false;
  }
  cancelarAbatimentoEvolucaoRMI(){
    this.novaEvolucaoRMIFormVisivel=false;
  }


  salvarNovaEvolucaoRMI(evolucaoRMI: EvolucaoRMI) {

  }
  salvarAbatimentosNovaEvolucaoRMI(abatimentos: Abatimento[]) {
    this.abatimentos = this.abatimentos.concat(abatimentos);
    this.updateTabelaAbatimentos();

  }
  processandoHiscre(processando: boolean) {
    this.aguardeVisivelEvent.emit(processando);
  }
  processandoNovaEvolucaoRMI(processando: boolean) {
    this.aguardeVisivelEvent.emit(processando);
  }
  salvarNovoAbatimento(abatimento: Abatimento) {
    this.abatimentos.push(abatimento);
    this.updateTabelaAbatimentos();
  }

}

