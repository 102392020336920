<div *ngIf="calculo">

    <div class="row p-2">
        <div class="col-md-6">
            <!-- <img src={{logoUrl}} width="100px"> -->
        </div>

        <div class="col-md-6 text-right">
            <p class="font-weight-bold mb-1 h1">Relatório de Auxílio Por Incapacidade Temporária / Acidente de Trabalho
            </p>
            <p class="mb-1 h2">Cliente {{calculo.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}
            </p>
        </div>
    </div>

    <hr class="my-5">

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1 text-default">Relatório de Auxílio Por Incapacidade Temporária / Acidente de Trabalho</span>
        </div>
    </div>

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-2 text-default">Dados do cálculo</span>
        </div>
    </div>

    <div class="row pt-2 pb-6 p-2">
        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Cliente</p>
            <p class="mb-1 h1"><span class="text-muted h1">Nome </span>
                {{calculo.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">CPF </span>
                {{calculo.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.cpf}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Sexo </span>
                {{calculo.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Data Nascimento </span>
                {{calculo.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataNascimento | date :
                'dd/MM/yyyy'}}
            </p>

            <p class="mb-1 h1"><span class="text-muted h1">Idade </span>
                {{calculo.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.idade.porExtenso}}</p>

        </div>

        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Parâmetros do cálculo</p>

            <p class="mb-1 h1"><span class="text-muted h1">Data do cálculo </span>
                {{calculo.data| date : 'dd/MM/yyyy'}}</p>

            <p class="mb-1 h1"><span class="text-muted h1">Data do CNIS </span>
                {{calculo.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataExtratoPrevidenciario | date : 'dd/MM/yyyy'}}</p>

        </div>

    </div>

    <div class="pb-6 continuo p-2">
        <h5 class=" h3 mb-0 linha-quadro">Resultado</h5>
        <div class="pt-6">
            <app-quadro-beneficio-preview [esconderBotaoVerMais]="true" [beneficio]="calculo.auxilioIncapacidadeTemporaria"></app-quadro-beneficio-preview>
        </div>
    </div>

</div>

<div class="py-6 continuo">
    <app-evolucao-salarios-pos-real #evolucaoSalariosPosReal></app-evolucao-salarios-pos-real>
</div>


<div class="py-6 continuo">
    <app-quadro-beneficio *ngIf="calculo" [beneficio]="calculo.auxilioIncapacidadeTemporaria"></app-quadro-beneficio>
</div>

<!-- <div class="pb-6 continuo">
    <app-destaque-pre-analise-cumprimento-requisitos *ngIf="calculo.auxilioIncapacidadeTemporaria" [destaque]="calculo.auxilioIncapacidadeTemporaria"></app-destaque-pre-analise-cumprimento-requisitos>
</div>

<div class="py-6 p-2">
    <app-relatorio-beneficio #relatorioBeneficio *ngIf="calculo.auxilioIncapacidadeTemporaria" [beneficio]="calculo.auxilioIncapacidadeTemporaria"></app-relatorio-beneficio>
</div> -->