import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalaEntrevistasRoutingModule } from './sala-entrevistas-routing.module';
import { SalaEntrevistasComponent } from './sala-entrevistas.component';
import { SalaComponent } from './sala/sala.component';




@NgModule({
  declarations: [
    SalaEntrevistasComponent,
    SalaComponent
  ],
  imports: [
    CommonModule,
    SalaEntrevistasRoutingModule
  ]
})
export class SalaEntrevistasModule { }
