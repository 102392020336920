import { ErrorHandler, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import Swal from 'sweetalert2';
import { ConfigService } from "../ui/config.service";
import { HttpErrorResponse } from "@angular/common/http";


@Injectable()
export class ErrorHandlerCalculei implements ErrorHandler {

    constructor(private router: Router, private configService: ConfigService) { }
    handleError(error: Error) {
        if (error.message.includes('SalvarLeituraCnis')) {
            this.configService.setAguarde(false);
            Swal.fire({
                title: 'Algo não foi bem..',
                text: 'Não foi possível ler o CNIS. Certifique-se de que o arquivo foi exportado diretamente do portal Meu INSS e tente novamente.',
                // icon: 'error',
                iconHtml:'<i class="bi bi-cloud-drizzle"></i>',
                iconColor: 'black',
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: 'ok',
                confirmButtonColor: '#172b4d'

            }).then(() => { });

        }

        throw new Error(error.message);
    }
}