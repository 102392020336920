import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { BarraNavegacaoComponent } from 'src/app/aplicacao/compartilhado/barra-navegacao/barra-navegacao.component';
import { Calculo } from 'src/app/modelos/common/calculo';
import { Global } from 'src/app/modelos/global';
import { AbatimentosValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/abatimentosvalorcausaprevidenciario';
import { AtualizacaoValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/atualizacaovalorcausaprevidenciario';
import { BeneficioValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/beneficiovalorcausaprevidenciario';
import { CalculoValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/calculovalorcausaprevidenciario';
import { ReferenciaValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/referenciavalorcausaprevidenciario';
import { RelatorioValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/relatoriovalorcausaprevidenciario';
import { ValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/valorcausaprevidenciario';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { ReferenciaValorCausaPrevidenciarioComponent } from '../compartilhado/referencia-valor-causa-previdenciario/referencia-valor-causa-previdenciario.component';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { BeneficioValorCausaPrevidenciarioComponent } from '../compartilhado/beneficio-valor-causa-previdenciario/beneficio-valor-causa-previdenciario.component';
import { AbatimentosValorCausaPrevidenciarioComponent } from '../compartilhado/abatimentos-valor-causa-previdenciario/abatimentos-valor-causa-previdenciario.component';
import { AtualizacaoValorCausaPrevidenciarioComponent } from '../compartilhado/atualizacao-valor-causa-previdenciario/atualizacao-valor-causa-previdenciario.component';
import { ListarRelatorioValorCausaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-relatorio-valor-causa-previdenciario.service';
import { ListarRelatorioValorCausaPrevidenciarioPdfService } from 'src/app/servicos/relatorio/listar-relatorio-valor-causa-previdenciario-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-valor-causa',
  templateUrl: './valor-causa.component.html',
  styleUrls: ['./valor-causa.component.scss']
})
export class ValorCausaComponent implements OnInit, AfterViewChecked {

  @ViewChild('tabsValorCausa', { static: false }) tabs?: TabsetComponent;
  @ViewChild('barraNavegacao', { static: false }) barraNavegacao?: BarraNavegacaoComponent;

  @ViewChild('referencia') referencia?: ReferenciaValorCausaPrevidenciarioComponent;
  @ViewChild('beneficio') beneficio?: BeneficioValorCausaPrevidenciarioComponent;
  @ViewChild('descontos') descontos?: AbatimentosValorCausaPrevidenciarioComponent;
  @ViewChild('atualizacao') atualizacao?: AtualizacaoValorCausaPrevidenciarioComponent;




  calculoValorCausa: CalculoValorCausaPrevidenciario;
  resumoValorCausa: CalculoValorCausaPrevidenciario;


  titulo: string = 'Novo cálculo de Valor da Causa';
  aguardeVisivel: boolean;


  voltarHabilitado: boolean;
  proximoHabilitado: boolean;
  tabDirective: TabDirective;
  navegacao: Navegacao;

  constructor(private location: Location,
    private changeDetector: ChangeDetectorRef,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private listarCalculoResultadoService: ListarCalculoResultadoService,private listarRelatorioValorCausaPrevidenciarioService:ListarRelatorioValorCausaPrevidenciarioService,
    private listarRelatorioValorCausaPrevidenciarioPdfService:ListarRelatorioValorCausaPrevidenciarioPdfService,
    private notificacoesService:NotificacoesService,) {

    this.navegacao = new Navegacao(0, false, false);

    this.calculoValorCausa = new CalculoValorCausaPrevidenciario();
    this.calculoValorCausa.valorCausa = new ValorCausaPrevidenciario();
    this.calculoValorCausa.valorCausa.referencia = new ReferenciaValorCausaPrevidenciario();
    this.calculoValorCausa.usuario = Global.usuario;
  }

  ngOnInit(): void {

    // this.mock();
  }



  ngAfterViewInit() {

    this.tabDirective = this.tabs.tabs.filter(tab => tab.active)[0];

    if (this.location.getState().hasOwnProperty('resultado')) {
      
      let calculoResultado: Calculo = this.location.getState() as Calculo;

      this.calculoValorCausa.valorCausa.beneficio = new BeneficioValorCausaPrevidenciario();
      this.calculoValorCausa.valorCausa.abatimentos = new AbatimentosValorCausaPrevidenciario();

      this.aguardeVisivel = true;


      this.listarCalculoResultadoService.listarCalculoResultado(calculoResultado)
        .then(calculo => {
          this.calculoValorCausa = calculo as CalculoValorCausaPrevidenciario;
          this.referencia.carregarDados(this.calculoValorCausa);
          this.beneficio.carregarDados(this.calculoValorCausa);
          this.descontos.carregarDados(this.calculoValorCausa);
          
          this.calculoValorCausa.relatorioValorCausa = this.calculoValorCausa.relatorioValorCausa;
          this.aguardeVisivel = false;
          this.selectTab(4);
        });
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculovalorcausa.json", request => {
      this.carregarDados(request as CalculoValorCausaPrevidenciario);
      this.selectTab(2);
      

    });
  }
  carregarDados(calculo: CalculoValorCausaPrevidenciario) {
    this.calculoValorCausa = calculo;
  }

  continuarHabilitado(habilitado: boolean) {
    if (this.barraNavegacao != undefined) {
      this.barraNavegacao.habilitarProximo(habilitado);
    }
  }

  referenciaConcluida(calculo: CalculoValorCausaPrevidenciario) {
    this.calculoValorCausa = calculo
    this.calculoValorCausa.valorCausa.beneficio = new BeneficioValorCausaPrevidenciario();
    this.selectTab(1);
  }

  beneficioConcluido(calculo: CalculoValorCausaPrevidenciario) {
    this.calculoValorCausa = calculo
    this.calculoValorCausa.valorCausa.abatimentos = new AbatimentosValorCausaPrevidenciario();
    this.selectTab(2);
  }
  abatimentosConcluido(calculo: CalculoValorCausaPrevidenciario) {
    this.calculoValorCausa = calculo;
    this.calculoValorCausa.valorCausa.atualizacao = new AtualizacaoValorCausaPrevidenciario();
    this.selectTab(3);

  }
  atualizacaoConcluida(calculo: CalculoValorCausaPrevidenciario) {



    this.calculoValorCausa = calculo;
    this.calculoValorCausa.resumoValorCausa = this.calculoValorCausa.valorCausa;
    this.calculoValorCausa.relatorioValorCausa = new RelatorioValorCausaPrevidenciario();
    this.selectTab(4);

  }

  resumoConcluido(calculo: CalculoValorCausaPrevidenciario) {
    this.calculoValorCausa = calculo;
    this.calculoValorCausa.relatorioValorCausa = new RelatorioValorCausaPrevidenciario();
    this.selectTab(5);


  }
  relatorioConcluido(calculo: CalculoValorCausaPrevidenciario) {
    this.calculoValorCausa = calculo;

  }



  processandoRelatorioEvent(processando: boolean) {
    this.aguardeVisivel = processando;
    if (!processando) {
      this.selectTab(5);
    }
  }



  selectTab(tab: number) {
    if (this.tabs?.tabs[tab]) {
      this.tabs.tabs[tab].active = true;
      this.changeDetector.detectChanges();
      this.tabDirective = this.tabs.tabs[tab];
      this.barraNavegacao.atualizarTabDirective(this.tabDirective);

    }

  }

  controleTabs(destabilitado: boolean) {

    if (this.tabs != undefined) {
      this.tabs.tabs.map(tab => tab.disabled = destabilitado);
    }

  }


  voltarSilenciosoAcao(navegacao: Navegacao) {
    // this.sumarioResultadoo.verRelatorio(false);

    this.barraNavegacao.habilitarPDF(false);
    this.barraNavegacao.navegacao.manterTabAtivaVoltar = false;



  }

  voltar(navegacao: Navegacao) {

    window.dispatchEvent(new Event('resize'));


    switch (navegacao.indexTabAtiva) {
      case 1:
        //regulariza o tamanho do ngx-datable
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        this.barraNavegacao.habilitarVoltar(false);
        this.barraNavegacao.habilitarProximo(true);
        break;

      default:
        break;
    }
    this.selectTab(navegacao.indexTabAtiva);
  }

  continuar(navegacao: Navegacao) {

    this.selectTab(navegacao.indexTabAtiva);

    //ativar grupossumario
    switch (navegacao.indexTabAtiva) {
      case 1:
        this.referencia.continuar();
        break;
      case 2:
        this.beneficio.continuar();
        break;
      case 3:
        this.descontos.continuar();
        break;
      case 4:
        this.atualizacao.continuar();
        break;

      default:
        break;
    }

  }



  tabDeSelecionada(tabDirective: TabDirective) {
    switch (tabDirective.id) {
      case "tabImportarCnis":
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        // this.voltarHabilitado = false;
        // this.proximoHabilitado = false;
        break;
      case "tabEditorCnis":
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        // this.voltarHabilitado = false;
        // this.proximoHabilitado = true;
        break;
      case "tabEnquadramento":
        // this.voltarHabilitado = true;
        // this.proximoHabilitado = true;
        break;
      case "tabRelatorio":
        this.barraNavegacao.habilitarPDF(false);
        break;

      default:
        break;
    }
    // this.barraNavegacao.atualizarTabDirective(this.tabDirective);
  }

  tabSelecionada(tabDirective: TabDirective) {
    switch (tabDirective.id) {
      case "tabReferencia":
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        this.voltarHabilitado = false;
        this.proximoHabilitado = true;
        break;
      case "tabBeneficio":
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        this.voltarHabilitado = false;
        this.proximoHabilitado = true;
        break;
      case "tabDescontos":
        this.voltarHabilitado = true;
        this.barraNavegacao.habilitarProximo(true);

        break;

      case "tabAtualizacao":
        this.voltarHabilitado = true;
        this.proximoHabilitado = true;
        break;

        case "tabRelatorio":
        this.voltarHabilitado = true;
        this.proximoHabilitado = false;
        this.barraNavegacao.habilitarPDF(true);
        break;

      default:
        break;
    }
    this.barraNavegacao.atualizarTabDirective(this.tabDirective);
  }
  baixarPDF(navegacao: Navegacao) {


    this.aguardeVisivel = true;
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');
    


    this.listarRelatorioValorCausaPrevidenciarioPdfService.ListarPDF(this.calculoValorCausa.relatorioValorCausa)
    .subscribe(blob=>{

      this.aguardeVisivel = false;
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = 'RelatorioValorCausa.pdf';
      a.click();
      URL.revokeObjectURL(objectUrl);

      this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
    });  





  }

}
