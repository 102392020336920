import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ImportarFaturasPortalService } from 'src/app/servicos/calculo/tributario/importar-faturas-portal.service';
import { ImportarFaturasService } from 'src/app/servicos/calculo/tributario/importar-faturas.service';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { AzureBlobService } from 'src/app/servicos/azure/azure-blob.service';

import { v4 as uuidv4 } from 'uuid';
import { Global } from 'src/app/modelos/global';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-minha-logo',
  templateUrl: './minha-logo.component.html',
  styleUrls: ['./minha-logo.component.scss']
})
export class MinhaLogoComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;

  logoUrl:string;

  constructor(private autenticacaoService:AutenticacaoService,
    private datePipe: DatePipe,
    private azureBlobService:AzureBlobService, private configService:ConfigService) {


      this.configService.getUsuario()
      .subscribe(usuario=>{
        if (usuario.logoUrl) {
          this.logoUrl = usuario.logoUrl;
        }
      });

     }

  ngOnInit(): void {

    
    
  }
  showFileDialog(): void {
    this.fileInput.nativeElement.click();
  }
  selecionarArquivo(event: Event): void {

    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    this.fileInput.nativeElement.value === '';

    this.azureBlobService.upLoadBlob(files[0], 'logo')
    .subscribe(progresso=>{
    });

  }

}
