<div *ngIf="beneficio.opcoesDescarte" class="row py-4">
    <div class="col-12">


        <h5 class=" h3 mb-0 linha-quadro">Análise Regra de Ouro &#167; 6<sup>o</sup> art. 26<sup>o</sup> da EC-103/2019
        </h5>



                
        <div class="row py-4">
            <div class="col-12">
                <div class="row">







                    <div class="col-6 py-4 continuo ">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{relatorioRMI.totalDescartesPossiveis}}
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Contribuições Disponíveis na Carência para Descarte
                        </span>
        
        
                    </div>







                    <div class="col-6 py-4 continuo ">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{12 - relatorioRMI.totalSaiderasProximoAcrescimoCoeficiente}}
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Contribuições necessárias para o próximo acréscimo de 2% no Coeficiente
                        </span>
        
        
                    </div>





                    <div class="col-6 py-4 continuo ">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{totalOpcoesAnalise}}
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Opções de Descarte Analisadas
                        </span>
        
        
                    </div>




                    <div class="col-6 py-4 continuo ">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{menorCoeficiente | number : '1.0-0' : 'pt-BR'}}% ~ {{maiorCoeficiente  | number : '1.0-0' : 'pt-BR'}}%
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Variação entre o Menor e Maior Coeficiente
                        </span>
        
        
                    </div>





                    <div class="col-12 py-4 continuo destaque-contraste">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{menorRMI  | currency}} ~ {{maiorRMI  | currency}}
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Variação entre a Menor e Maior RMI para os coeficientes analisados
                        </span>
        
        
                    </div>






                    

                </div>
               
            </div>

        </div>
        
       




        <div class="row py-4  ">
            <div class="col-12">
                <div class="row d-flex justify-content-end">




                    <div class="col-6 py-4 continuo ">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{relatorioRMI.totalDescartesRealizados}}
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Descartes realizados
                        </span>
        
        
                    </div>





                    <div class="col-6 py-4 continuo ">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{relatorioRMI.coeficiente | number : '1.0-0'}} %
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Coeficiente ideal
                        </span>
        
        
                    </div>




                    

                    

                </div>
               
            </div>

        </div>





        <div class="row py-4  ">
            <div class="col-12">
                <div class="row d-flex justify-content-end">





                    <div class="col-6 py-4 continuo destaque-contraste-ouro">

                        <h1 class=" display-2 card-title  mb-0 ">
        
                            {{relatorioRMI.rendaMensalInicial | currency}}
        
                        </h1>
                        <span class=" display-4 font-weight-lighter  mb-0 ">
                            Resultado da RMI com o coeficiente ideal
                        </span>
        
        
                    </div>
                    
                    

                </div>
               
            </div>

        </div>


        
    </div>
</div>