import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';
import { TipoAtividade } from 'src/app/modelos/previdenciario/tipoatividade';
import { TiposVinculos } from 'src/app/modelos/previdenciario/tiposvinculos';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-canvas-sequencia',
  templateUrl: './canvas-sequencia.component.html',
  styleUrl: './canvas-sequencia.component.scss'
})
export class CanvasSequenciaComponent {

  @Input() sequencia:RelacaoPrevidenciaria;
  @Output() atualizarCnisEvent = new EventEmitter<RelacaoPrevidenciaria>();

  tiposAtividadeType = TipoAtividade;
  tiposVinculosType = TiposVinculos;

  editarTipoVinculo(sequencia: RelacaoPrevidenciaria, tipo: TiposVinculos) {
    sequencia.tipoVinculo = tipo;
    LogService.log("editar tipo vinculo sequencia", sequencia);
    this.atualizarCnisEvent.emit(this.sequencia);
    
  }

  editarTipoAtividade(sequencia: RelacaoPrevidenciaria, tipo: TipoAtividade) {
    switch (tipo) {
      case TipoAtividade.Professor:
        sequencia.tipoAtividade = TipoAtividade.Professor
        break;
      case TipoAtividade.Rural:
        sequencia.tipoAtividade = TipoAtividade.Rural
        break;
      case TipoAtividade.Especial15:
        sequencia.tipoAtividade = TipoAtividade.Especial15
        break;
      case TipoAtividade.Especial20:
        sequencia.tipoAtividade = TipoAtividade.Especial20
        break;
      case TipoAtividade.Especial25:
        sequencia.tipoAtividade = TipoAtividade.Especial25
        break;
      case TipoAtividade.Normal:
        sequencia.tipoAtividade = TipoAtividade.Normal
        break;

      default:
        sequencia.tipoAtividade = TipoAtividade.Normal
        break;


    }

    // console.log('sequencia tipo atividade', sequencia.tipoAtividade);

    this.atualizarCnisEvent.emit(this.sequencia);

    // let editor = <HTMLInputElement>document.getElementById(idEditor);
    // editor.classList.remove('show');
    // editor.classList.add('collapse');


    
  }


}
