import { Component, Input } from '@angular/core';
import { Entrevista } from 'src/app/modelos/previdenciario/entrevista';

@Component({
  selector: 'app-resumo-entrevista',
  templateUrl: './resumo-entrevista.component.html',
  styleUrl: './resumo-entrevista.component.scss'
})
export class ResumoEntrevistaComponent {

  @Input() entrevista:Entrevista;

  constructor(){}

  

}
