import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';
import { Arquivo } from 'src/app/modelos/faturas/arquivo';
import { Global } from 'src/app/modelos/global';
import { CalculoRevisaoTempoServico } from 'src/app/modelos/previdenciario/calculorevisaotemposervico';
import { ExtratoContribuicaoPrisma } from 'src/app/modelos/previdenciario/extratocontribuicaoprisma';
import { ImportacaoExtratoPrisma } from 'src/app/modelos/previdenciario/importacaoextratoprisma';
import { AzureBlobService } from 'src/app/servicos/azure/azure-blob.service';
import { ImportarExtratoPrismaService } from 'src/app/servicos/calculo/previdenciario/importar-extrato-prisma.service';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-importador-extrato-prisma',
  templateUrl: './importador-extrato-prisma.component.html',
  styleUrls: ['./importador-extrato-prisma.component.scss']
})
export class ImportadorExtratoPrismaComponent implements OnInit {

  @Output() processandoImportacaoExtratoPrisma = new EventEmitter<boolean>();
  @Output() importacaoExtratoPrismaEvent = new EventEmitter<CalculoRevisaoTempoServico>();
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;

  @Input() calculoRevisaoTempoServico:CalculoRevisaoTempoServico;

  progressoImportacao = new BehaviorSubject<Number>(0);
  importacaoExtratoPrisma: ImportacaoExtratoPrisma;

  setProgressoImportacao(progresso:Number){
    this.progressoImportacao.next(progresso);
  }
  getProgressoImportacao():Number{
    return this.progressoImportacao.getValue();
  }

  constructor(private autenticacaoService:AutenticacaoService,
    private azureBlobService:AzureBlobService,
    private importarExtratoPrismaService:ImportarExtratoPrismaService) { 

      this.progressoImportacao.subscribe(progresso=>{
        if(progresso == 100){
          setTimeout(() => {
            this.calculoRevisaoTempoServico.importacaoExtrato = this.importacaoExtratoPrisma;
            this.importarExtratoPrismaService.importarExtratoPrisma(this.calculoRevisaoTempoServico)
            .then(calculo=>{
              this.calculoRevisaoTempoServico = calculo as CalculoRevisaoTempoServico;
              this.processandoImportacaoExtratoPrisma.emit(false);
              this.importacaoExtratoPrismaEvent.emit(this.calculoRevisaoTempoServico);
            });
          }, 1000);
        }
      })
    }


  selecionarArquivo(event: Event): void {

    this.processandoImportacaoExtratoPrisma.emit(true);
   
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;


    this.fileInput.nativeElement.value === '';

    let file:File = files[0] as File;

    this.importacaoExtratoPrisma = new ImportacaoExtratoPrisma();
    this.importacaoExtratoPrisma.rowKey = uuidv4();
    this.importacaoExtratoPrisma.partitionKey = Global.usuario.rowKey;
    this.importacaoExtratoPrisma.arquivos = [];
    this.importacaoExtratoPrisma.usuario = Global.usuario;


    let arquivo:Arquivo = new Arquivo();
    arquivo.referenciaLocal =  uuidv4() +".pdf";
    arquivo.referenciaCloud = (Global.usuario.rowKey + "/" + this.importacaoExtratoPrisma.rowKey).toLowerCase();
    arquivo.partitionKey = this.importacaoExtratoPrisma.rowKey;
    arquivo.nomeOriginal = file.name;
    arquivo.descricao = "Extrato de Tempo de Contribuição do Prisma";
    arquivo.rowKey = uuidv4();

    this.importacaoExtratoPrisma.arquivos.push(arquivo);

    this.azureBlobService.upLoadArquivo(arquivo, files[0])
    .subscribe(progress=>{
      this.setProgressoImportacao(progress);
    });
  }

  showFileDialog(): void {
    this.fileInput.nativeElement.click();
  }

  

  ngOnInit(): void {
  }

}
