import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { PopoverInfo } from 'src/app/servicos/ui/popoverInfo';

import { Router } from '@angular/router';

@Component({
  selector: 'app-barra-navegacao',
  templateUrl: './barra-navegacao.component.html',
  styleUrls: ['./barra-navegacao.component.scss']
})
export class BarraNavegacaoComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {

  constructor(private changeDetector: ChangeDetectorRef, private configService:ConfigService,
    private router:Router) {

    this.popoverInfoBaixarPDF = new PopoverInfo();
    this.popoverInfoBaixarPDF.titulo = 'PDF';
    this.popoverInfoBaixarPDF.descricao = 'Baixar relatório em PDF';

    configService.getAguarde().subscribe(habilitar =>{

      this.aguarde = habilitar;
      

    });
   }

  @Input() aguarde: boolean;
  @Input() navegacao: Navegacao;
  @Input() tabDirective: TabDirective;
  @Input() voltarHabilitado: boolean;
  @Input() proximoHabilitado: boolean;
  @Input() titulo: string;
  @Output() proximoEvent = new EventEmitter<Navegacao>();
  @Output() voltarEvent = new EventEmitter<Navegacao>();
  @Output() voltarSilenciosoEvent = new EventEmitter<Navegacao>();
  @Output() baixarPDFEvent = new EventEmitter<Navegacao>();
  @Output() atualizarEvent = new EventEmitter<Navegacao>();
  @Output() incluirCasoMatrizPlanejamentoEvent = new EventEmitter<boolean>();
  @Output() incluirMarcadorCnisEvent = new EventEmitter<boolean>()

  @Output() excluirCnisEvent = new EventEmitter<boolean>()

  @Output() setMarcadorCasoEvent = new EventEmitter<boolean>();

  incluirMarcadorCnisHabilitado:boolean;

  excluirCnisHabilitado:boolean;

  baixarPDFHabilitado: boolean;
  incluirMatrizPlanejamentoHabilitado:boolean;

  index: number;

  popoverInfoBaixarPDF:PopoverInfo;
  popoverBaixarPDFIsOpen:boolean;

  ngOnInit(): void {

  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.tabDirective != undefined && changes.tabDirective.currentValue != undefined) {


    //   this.voltarHabilitado = Navegacao.listarVoltarHabilitado(changes.tabDirective.currentValue) && this.voltarHabilitado;
    //   this.proximoHabilitado = Navegacao.listarProximoHabilitado(changes.tabDirective.currentValue) && this.proximoHabilitado;

    //   this.tabDirective = changes.tabDirective.currentValue;
    // }
  }

  atualizarTabDirective(tabDirective: TabDirective) {
    this.tabDirective = tabDirective;
    
    this.voltarHabilitado = Navegacao.listarVoltarHabilitado(this.tabDirective) && this.voltarHabilitado;
    this.proximoHabilitado = Navegacao.listarProximoHabilitado(this.tabDirective) && this.proximoHabilitado;
  }

  proximo() {
    if (this.proximoHabilitado) {
      this.navegacao.indexTabAtiva = Navegacao.indexTabAtiva(this.tabDirective) + 1;
      this.proximoEvent.emit(this.navegacao);
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    }

  }
  voltar() {
    if (this.voltarHabilitado) {
      if (this.navegacao.manterTabAtivaVoltar) {
        this.navegacao.indexTabAtiva = Navegacao.indexTabAtiva(this.tabDirective);
        this.voltarSilenciosoEvent.emit(this.navegacao)
      } else {
        this.navegacao.indexTabAtiva = Navegacao.indexTabAtiva(this.tabDirective) - 1;
      }

      this.voltarEvent.emit(this.navegacao);
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    }
  }

  baixarPDF() {
    this.baixarPDFEvent.emit(this.navegacao);
  }

  habilitarPDF(habilitar: boolean) {
    this.baixarPDFHabilitado = habilitar;
  }

  habilitarIncluirMatrizPlanejamento(habilitar: boolean) {
    this.incluirMatrizPlanejamentoHabilitado = habilitar;
  }

  habilitarVoltar(habilitar: boolean) {

    // this.voltarHabilitado = Navegacao.listarVoltarHabilitado(this.tabDirective) && habilitar;
    this.voltarHabilitado = habilitar;
  }

  habilitarProximo(habilitar: boolean) {

    // this.proximoHabilitado = Navegacao.listarProximoHabilitado(this.tabDirective) && habilitar;
    this.proximoHabilitado = habilitar;
  }

  setTitulo(titulo:string){
    this.titulo = titulo;
  }

  setPopoverInfoBaixarPDF(popoverInfo:PopoverInfo){
    this.popoverInfoBaixarPDF = popoverInfo;

    // setTimeout(() => {
    //   this.popoverBaixarPDFIsOpen = true;
    // }, 2000);

    // setTimeout(() => {
    //   this.popoverBaixarPDFIsOpen = false;
    // }, 10000);
  }

  incluirCasoMatrizPlanejamento(incluir:boolean){
    this.incluirCasoMatrizPlanejamentoEvent.emit(incluir);
  }

  setMarcadorIncluirCnisHabilitar(habilitar:boolean){
    this.incluirMarcadorCnisHabilitado = habilitar;
  }

  

  incluirMarcadorCnis(){

    this.incluirMarcadorCnisEvent.emit(true);

  }

  iniciarSetMarcadorCaso(){
    this.setMarcadorCasoEvent.emit(true);
  }

  setExcluirCnisHabilitar(habilitar:boolean){
    this.excluirCnisHabilitado = habilitar;
  }

  excluirCnis(){
    this.excluirCnisEvent.emit(true);
  }

  cancelar(){

    this.router.navigateByUrl('calculei/calculos/novocalculo');
  }
  

}
