import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, retry } from 'rxjs';
import { ImportacaoFatura } from 'src/app/modelos/faturas/importacaofatura';
import { ImportacaoCNIS } from 'src/app/modelos/previdenciario/importacaocnis';
import { environment } from 'src/environments/environment';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { Global } from 'src/app/modelos/global';
import { RequestBase } from 'src/app/modelos/common/requestbase';

@Injectable({
  providedIn: 'root'
})
export class GerarCnisService {

  constructor(private httpClient: HttpClient) { }



  gerarCnis(leituraCnis:LeituraCNIS) {

    

    return Promise.all([this.apiCall(leituraCnis)]).then((listaAPI) => {
      return listaAPI[0];
    });
  }

  private async apiCall(leituraCnis: LeituraCNIS) {
    const headers = { 'Access-Control-Allow-Origin': '*' };
    const data = await this.httpClient.post(environment.api as string + 'api/GerarCnis', leituraCnis, { 'headers': headers })
      .pipe(retry({ count: 3, delay: 3000 }))
      .toPromise();
    return data;
  }
}
