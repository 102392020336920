<div class=" header bg-previdenciario pb-6">
    <div class=" container-fluid">
      <div class=" header-body">
        <div class=" row align-items-center py-3">
          <div class=" col-lg-12">
            <h6 class=" h2 text-white d-inline-block mb-0">Relatório de Tempo de Contribuição</h6>
            <nav
              aria-label="breadcrumb"
              class=" d-none d-md-inline-block ml-md-4"
            >
              <!-- <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
                <li class=" breadcrumb-item">
                  <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
                </li>
  
                <li class=" breadcrumb-item">
                  <a href="javascript:void(0)"> Cálculos </a>
                </li>
  
                <li aria-current="page" class=" breadcrumb-item active">
                    Relatório de Tempo de Contribuição
                </li>
              </ol> -->
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class=" container-fluid mt--3">

    <div class=" row">
      <div class=" col-lg-12">
        <div class=" card">
          <div class=" card-header border-dark bg-transparent">
            <h3 class=" mb-0">Novo Relatório de Tempo de Contribuição</h3>
          </div>
          <div class=" card-body">


            <!-- aguarde -->
            <div id="aguarde" [className]="aguardeVisivel ? 'visible' : 'invisible'">
              <div class="container area">
                <div class="row">
                  <div class="col-md-auto">
                    <span class=" timeline-step timeline-step-xlg bg-previdenciario">
                      <i class="fas fa-spinner fa-spin"></i>
                    </span>
                  </div>
                  <div class="col-md-auto">
                    
                    <h1 class="display-2">Aguarde...</h1>
                  </div>
                </div>
              </div>
            </div>

            <!-- passo1 -->
            <div id="passo1" [className]="passo1Visivel? 'visible' : 'invisible'">
              <div class="container ">
                  <div class="row">
                    
                    <div class="col-md-auto">
                      <span class=" timeline-step timeline-step-xlg bg-previdenciario">
                        1
                      </span>
                    </div>
                    <div class="col-md-auto">
                      <h1 class="display-2">Extrato Previdenciário</h1>
                    </div>
                    <div class="col">
                      <p class="lead">
                      Entre os dados das Relações Previdenciárias do Extrato Previdenciário do assistido, estão as Remunerações. Elas vão servir de base para o relatório deste cálculo.
                      Para facilitar, você pode importar o arquivo CNIS (Extrato Previdenciário), gerado no site do INSS, do assistido e o sistema vai extrair automaticamente todas as informações necessárias.
                      Caso você prefira, você também pode entrar com os dados manualmente.
                      </p>
                    </div>
                  </div>
                  <div class="row area">
                      <div class="col-md-12 ">
                          <div class="row ">
                              <div class="col-md-6 text-right">
                                  <app-importador-cnis (leituraCNISEvent)="leituraCNIS($event)" (processandoCNISEvent)="processandoCNIS($event)" ></app-importador-cnis>
                              </div>
                              <div class="col-md-6 text-left">
                                  <button class="btn btn-primary" (click)="preencherFormulario()" > Preencher formulário</button>
                              </div>
                          </div>
                      </div>
                    
                  </div>
                </div>
            </div>




            <!-- passo2 -->
            <div id="passo1" [className]="passo2Visivel? 'visible' : 'invisible'">
              <div class="container ">
                  <div class="row">
                    <div class="col-md-auto">
                      <span class=" timeline-step timeline-step-xlg bg-previdenciario">
                        2
                      </span>
                    </div>
                    <div class="col-md-auto">
                      <h1 class="display-2">Relações Previdenciárias</h1>
                    </div>
                    <div class="col">
                      <p class="lead">
                      Ótimo, as informações do extrato foram lidas automaticamente. Vamos conferir para avaliar se nenhuma informação ficou faltando.
                      </p>
                    </div>
                  </div>
                  <div>
                    
                    <app-editor-cnis *ngIf="cnis" [cnis]="cnis" (requestRelatorioTempoContribuicaoProntaEvent)="gerarRelatorioTempoContribuicao($event)"></app-editor-cnis>
                    <!-- <app-editor-cnis  (requestRelatorioTempoContribuicaoProntaEvent)="gerarRelatorioTempoContribuicao($event)"></app-editor-cnis> -->
                  </div>
                  
                </div>
            </div>


            <!-- passo3-->
            <div id="passo3" [className]="passo3Visivel? 'visible' : 'invisible'">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
              
                  </div>
                  <div class="col-md-auto">
                    <span class=" timeline-step timeline-step-xlg bg-previdenciario">
                      <i class="fas fa-flag-checkered"></i>
                    </span>
                  </div>
                  <div class="col-md-5">
                    <h1 class="display-2">Concluído</h1>
                  </div>
                  <div class="col-md-6">
                    <p class="lead">
                      Perfeito. Seu relatório já está pronto para visualização e download.
                    </p>
                  </div>
                </div>

                <div>
                  
                  <app-quadro-relatorio-tempo-contribuicao *ngIf="requestRelatorioTempoContribuicao" [request]="requestRelatorioTempoContribuicao" (relatorioProntoEvent)="relatorioPronto($event)"></app-quadro-relatorio-tempo-contribuicao>
                </div>

                <div class="row">
                  <div class="col-md-12 area-top-lg text-center">
                    <div class="row">
                      <div class="col-md-auto">
                        <!-- <a [routerLink]="['/aplicacao/calculos/previdenciario/rendamensalinicial']" class="btn btn-primary">Gerar RMI</a> -->
                        <button class="btn btn-primary" (click)="gerarRMI()" >Gerar RMI</button>
                        
                      </div>
                      <div class="col-md-auto">
                        <a [routerLink]="['previdenciario/calculos/novocalculo']" class="btn btn-primary">Novo Cálculo</a>
                      </div>
                      <div class="col-md-auto">
                        <a [routerLink]="['/dashboard/calculos']" class="btn btn-primary">Voltar para o dashboard inicial</a>
                      </div>
                    </div>
                  </div>
                </div>
                    
              </div>
            </div>


            <!-- passo3-->
            <div id="passo3" [className]="passo3Visivel? 'visible' : 'invisible'">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
              
                  </div>
                  <div class="col-md-auto">
                    <span class=" timeline-step timeline-step-xlg bg-previdenciario">
                      <i class="fas fa-flag-checkered"></i>
                    </span>
                  </div>
                  <div class="col-md-5">
                    <h1 class="display-2">Concluído</h1>
                  </div>
                  <div class="col-md-6">
                    <p class="lead">
                      Perfeito. Seu relatório já está pronto para visualização e download.
                    </p>
                  </div>
                </div>

                <div>
                  
                  <app-quadro-relatorio-tempo-contribuicao *ngIf="requestRelatorioTempoContribuicao" [request]="requestRelatorioTempoContribuicao" (relatorioProntoEvent)="relatorioPronto($event)"></app-quadro-relatorio-tempo-contribuicao>
                </div>

                <div class="row">
                  <div class="col-md-12 area-top-lg text-center">
                    <div class="row">
                      <div class="col-md-auto">
                        <!-- <a [routerLink]="['/aplicacao/calculos/previdenciario/rendamensalinicial']" class="btn btn-primary">Gerar RMI</a> -->
                        <button class="btn btn-primary" (click)="gerarRMI()" >Gerar RMI</button>
                        
                      </div>
                      <div class="col-md-auto">
                        <a [routerLink]="['previdenciario/calculos/novocalculo']" class="btn btn-primary">Novo Cálculo</a>
                      </div>
                      <div class="col-md-auto">
                        <a [routerLink]="['/dashboard/calculos']" class="btn btn-primary">Voltar para o dashboard inicial</a>
                      </div>
                    </div>
                  </div>
                </div>
                    
              </div>
            </div>

            <!-- passo4-->
            <!-- <div id="passo4" [className]="passo4Visivel? 'visible' : 'invisible'"> -->
            <div id="passo4">
              <app-quadro-relatorio-renda-mensal-inicial *ngIf="requestRelatorioRendaMensalInicial" [requestRelatorioRendaMensalInicial]="requestRelatorioRendaMensalInicial" [requestRelatorioTempoContribuicao]="requestRelatorioTempoContribuicao"></app-quadro-relatorio-renda-mensal-inicial>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  