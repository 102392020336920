import { Component } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { Cupom } from 'src/app/modelos/common/cupom';
import { Voucher } from 'src/app/modelos/common/voucher';
import { Permissoes } from 'src/app/modelos/usuario/permissoes';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-opcoes-filiado',
  templateUrl: './opcoes-filiado.component.html',
  styleUrl: './opcoes-filiado.component.scss'
})
export class OpcoesFiliadoComponent {

  

}
