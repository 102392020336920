import { Usuario } from './../../../../../modelos/usuario/usuario';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { Sexo } from 'src/app/modelos/previdenciario/sexo';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-quadro-segurado-editor-cnis',
  templateUrl: './quadro-segurado-editor-cnis.component.html',
  styleUrl: './quadro-segurado-editor-cnis.component.scss'
})
export class QuadroSeguradoEditorCnisComponent implements OnInit {

  @Input() calculoBeneficios: CalculoBeneficios;

  @Output() leituraCnisAtualizadaEvent = new EventEmitter<CalculoBeneficios>();
  @Output() habilitarSalvarEdicaoEvent = new EventEmitter<any>();

  usuario: Usuario;

  dataBaseCalculo: Date = new Date();
  sexoEnumType = Sexo;

  dataReforma:Date = new Date(2019,10,13);
  
  constructor(private configService: ConfigService) {
    this.configService.getUsuario().subscribe(resultado => { this.usuario = resultado })
    
  }

  ngOnInit(): void {

  }

  

  editarSexo(sexo: string, idEditor:string) {

    

    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    
    this.habilitarSalvarEdicaoEvent.emit(true);
  }

  edicaoSexo(event){
    // console.log('edicao sexo', event)
    

    switch (event) {
      case "Feminino":
        this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo = Sexo.Feminino;
        break;
      case "Masculino":
        this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo = Sexo.Masculino;
        break;

      default:
        this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo = Sexo.Masculino;
        break;
    }
    // console.log('model sexo', this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo);
  }

  editarDataCalculo(idInput: string, idEditor: string) {

    let edicao: string = (<HTMLInputElement>document.getElementById(idInput)).value;
    // console.log('editar data inicio sequencia', moment(edicao, "DD/MM/YYYY"));

    let editor = <HTMLInputElement>document.getElementById(idEditor);

    // console.log('campo', campo);

    let data: Date = moment(edicao, "DD/MM/YYYY").toDate();

    this.calculoBeneficios.dataCalculo = data;

    editor.classList.remove('show');
    // editor.classList.add('collapse');

    // this.leituraCnisAtualizadaEvent.emit(this.leituraCnis);
    // this.habilitarSalvarEdicaoEvent.emit(true);

    // this.habilitarSalvarEdicaoEvent.emit(true);

  }
  cancelEditarDataCalculo(idEditor: string) {

    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    // editor.classList.add('in');

  }

  changeDataCalculo(event){
    // console.log('change data calculo ', event);

    this.calculoBeneficios.dataCalculo = new Date(event.value);
  }
}
