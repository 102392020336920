<div class="row ">
  <div class="col">
    <div class="card">

      <div class="card-body d-flex flex-row-reverse">
        <button class="btn btn-outline-black m-2" disabled="true">Continuar</button>

      </div>
    </div>
  </div>
</div>







<div class="row">
  <div class="col">
    <div class=" card">
      <div class=" card-header border-dark">
        <h5 class=" h3 mb-0">Seleção do arquivo</h5>
      </div>

      <div class=" card-body">

        <div class="row">
          <div class="col-lg-12">

            <input style="display: none" type="file" #fileInput (change)="selecionarArquivo($event)" />

            <button type="button" (click)="showFileDialog()" class="btn btn-outline-black m-2">
              Selecionar arquivo Extrato de Contribuição do Prisma
            </button>


          </div>
        </div>


      </div>
    </div>
  </div>
</div>



<div class="row ">
  <div class="col">
    <div class="card">

      <div class="card-body d-flex flex-row-reverse">
        <button class="btn btn-outline-black m-2" disabled="true">Continuar</button>

      </div>
    </div>
  </div>
</div>