<div *ngIf="beneficio">
    <div class="row py-4 p-2">
        <div class="col-md-6">
            <!-- <img src={{logoUrl}} width="100px"> -->
        </div>

        <div class="col-md-6 text-right">

            <p class="h2">
                Renda Mensal Inicial
            </p>
            <p class="h2">
                {{beneficio.qualificacaoDetalhada}}
            </p>
            <p class="h6">
                {{beneficio.qualificacao}}
            </p>
        </div>
    </div>

    <div class="my-5"></div>

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1 text-default">Cálculo da RMI de Aposentadoria por Invalidez</span>
        </div>
    </div>



    <div class="py-2 m-2">
        <app-quadro-segurado-relatorio [calculoBeneficios]="calculoBeneficios"></app-quadro-segurado-relatorio>

    </div>
    <div class="py-2 m-2">
        <app-quadro-beneficio-preview *ngIf="beneficio" [beneficio]="beneficio"></app-quadro-beneficio-preview>
    </div>
    <div class="py-2 m-2">
        <app-quadro-beneficio *ngIf="beneficio" [beneficio]="beneficio"></app-quadro-beneficio>
    </div>
    
    <div class="py-2 m-2">
        <app-extrato-beneficio #extratoBeneficio *ngIf="beneficio" [beneficio]="beneficio"></app-extrato-beneficio>
    </div>
</div>

