import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { EdicaoSalarioAliquota } from 'src/app/modelos/previdenciario/edicaosalarioaliquota';
import { OpcaoEnquadramentoSeguradoPlanejamento } from 'src/app/modelos/previdenciario/opcaoenquadramentoseguradoplanejamento';
import { ListarCalculoChaveService } from 'src/app/servicos/calculo/common/listar-calculo-chave.service';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';

@Component({
  selector: 'app-relatorio-planejamento-comparativo',
  templateUrl: './relatorio-planejamento-comparativo.component.html',
  styleUrls: ['./relatorio-planejamento-comparativo.component.scss']
})
export class RelatorioPlanejamentoComparativoComponent implements OnInit {


  calculoRowKey: string;
  calculoPartitionKey: string;

  calculoPlanejamento: CalculoPlanejamentoPrevidenciario;

  melhorCaso: CasoPlanejamentoPrevidenciario;
  maiorLucro: CasoPlanejamentoPrevidenciario;
  maiorRMI: CasoPlanejamentoPrevidenciario;
  maisRapido: CasoPlanejamentoPrevidenciario;

  casos: CasoPlanejamentoPrevidenciario[];
  casosSalarioAliquotaSelecionado: CasoPlanejamentoPrevidenciario[];

  qualificacoesBeneficios:string[];
  grupos: AgrupamentoCenariosRelatorioPlanejamento[];


  logoUrl:string;

  


  constructor(private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private route: ActivatedRoute,
    private listatCalculoChaveService: ListarCalculoChaveService,
    private listarCalculoResultadoService: ListarCalculoResultadoService) { }

  ngOnInit(): void {

    // this.mock();
    this.listarCalculo();
    


  }

  listarCalculo(){
    this.route.queryParams.subscribe(params => {

      if (params.rowKey != '' && params.partitionKey != '') {


        this.calculoRowKey = params.rowkey;
        this.calculoPartitionKey = params.partitionkey;

        let resultadoCalculo: ResultadoCalculo = new ResultadoCalculo();

        resultadoCalculo.rowKey = this.calculoRowKey;
        resultadoCalculo.partitionKey = this.calculoPartitionKey;


        let calculo: Calculo = new Calculo();
        calculo.rowKey = this.calculoRowKey;
        calculo.partitionKey = this.calculoPartitionKey;
        calculo.tipo = CalculoTipos.CASOPLANEJAMENTO;


        this.listatCalculoChaveService.listarCalculo(calculo)
          .then(calculo => {
            this.carregarDados(calculo as CalculoPlanejamentoPrevidenciario);
          });
      }


    });
  }

  carregarDados(request: CalculoPlanejamentoPrevidenciario) {
    this.calculoPlanejamento = request as CalculoPlanejamentoPrevidenciario;
    this.casos = this.calculoPlanejamento.casos;

    this.casos = this.casos.sort((a, b) => a.valorBeneficio  - b.valorBeneficio );

    this.casosSalarioAliquotaSelecionado = this.casos.filter(caso=>caso.enquadramento.aliquota == this.calculoPlanejamento.opcoesPlanejamento.edicaoSalarioAliquota.aliquota && caso.enquadramento.base == this.calculoPlanejamento.opcoesPlanejamento.edicaoSalarioAliquota.salario);


    this.melhorCaso = this.casosSalarioAliquotaSelecionado.sort((a, b) => a.valorBeneficio - b.valorBeneficio )[0];
    this.maiorLucro = this.casosSalarioAliquotaSelecionado.sort((a,b) => a.retornoTotalInvestimentoRoi - b.retornoTotalInvestimentoRoi).reverse()[0];
    this.maiorRMI = this.casosSalarioAliquotaSelecionado.sort((a, b) => a.valorBeneficio - b.valorBeneficio).reverse()[0];
    this.maisRapido = this.casosSalarioAliquotaSelecionado.sort((a, b) => new Date(a.beneficio.dataCalculo).getTime() - new Date(b.beneficio.dataCalculo).getTime())[0];
    this.maisRapido = this.casosSalarioAliquotaSelecionado.filter(caso=>caso.beneficio.dataCalculo == this.maisRapido.beneficio.dataCalculo).sort((a,b)=> a.valorBeneficio - b.valorBeneficio).reverse()[0];
    // this.maisRapido = this.casos.sort(function(a,b){return a.beneficio.dataCalculo.getTime() - b.beneficio.dataCalculo.getTime()})[0];

    


    

    this.qualificacoesBeneficios = [...new Set(this.casos.map((caso) => caso.beneficio.qualificacao + " " + caso.beneficio.qualificacaoDetalhada))].reverse();
    this.grupos = [];
    this.qualificacoesBeneficios.map(qualificacao => {

      let grupo: AgrupamentoCenariosRelatorioPlanejamento = new AgrupamentoCenariosRelatorioPlanejamento();
      grupo.qualificacaoBeneficio = qualificacao;
      grupo.casos = this.casos.filter(caso => caso.beneficio.qualificacao + " - " + caso.beneficio.qualificacaoDetalhada === qualificacao);
      grupo.enquadramentoContributivo = grupo.casos[0].enquadramento;

      this.grupos.push(grupo);

    });



    this.grupos.map(grupo => {

      grupo.enquadramentos = grupo.casos.map(caso => caso.enquadramento);
    });


    if (this.calculoPlanejamento.usuario.logoUrl != null) {
      this.logoUrl = this.calculoPlanejamento.usuario.logoUrl;
    }else{
      this.logoUrl = "../../../assets/img/brand/android-chrome-512x512.png";
    }
    

  }


  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculoplanejamento.json", request => {


      this.carregarDados(request);

     

    });


  }




}

export class AgrupamentoCenariosRelatorioPlanejamento {
  qualificacaoBeneficio: string;
  enquadramentoContributivo: OpcaoEnquadramentoSeguradoPlanejamento;
  casos: CasoPlanejamentoPrevidenciario[];
  enquadramentos: OpcaoEnquadramentoSeguradoPlanejamento[];
}
