


import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import * as currency from 'currency.js';

import { NgxSelectOption } from 'ngx-select-ex';
import { AppComponent } from 'src/app/app.component';
import { BeneficioValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/beneficiovalorcausaprevidenciario';
import { CalculoValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/calculovalorcausaprevidenciario';


import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';
import { TipoBeneficioLiquidacaoSentencaPrevidenciarioExtensions } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciarioextensions';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { ValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/valorcausaprevidenciario';

@Component({
  selector: 'app-beneficio-valor-causa-previdenciario',
  templateUrl: './beneficio-valor-causa-previdenciario.component.html',
  styleUrls: ['./beneficio-valor-causa-previdenciario.component.scss']
})
export class BeneficioValorCausaPrevidenciarioComponent implements OnInit {

  @Output() continuarEvent = new EventEmitter<CalculoValorCausaPrevidenciario>();
  @Output() continuarHabilitadoEvent = new EventEmitter<boolean>();
  @Input() calculoValorCausa: CalculoValorCausaPrevidenciario;

  tipoBeneficioLiquidacaoSentencaPrevidenciarioType = TipoBeneficioLiquidacaoSentencaPrevidenciario;
  tipoProcessoLiquidacaoSentencaPrevidenciarioType = TiposProcessoLiquidacaoSentencaPrevidenciario;

  numeroBeneficioMask = createMask('9999999999');
  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  currencyInputMask = createMask(AppComponent.currencyInputOptions);

  numeroBeneficioValidator = [];
  dataCessacaoValidator = [];
  dibValidator = [];

  tiposBeneficios: any[];
  tipoBeneficioSelecionado: TipoBeneficioLiquidacaoSentencaPrevidenciario

  beneficioForm = new UntypedFormGroup({



    tipoBeneficio: new UntypedFormControl('', Validators.required),
    dib: new UntypedFormControl('', Validators.required),
    dip: new UntypedFormControl(''),
    rmi: new UntypedFormControl('', Validators.required),
    dib25: new UntypedFormControl('')

  });





  constructor() { }

  ngOnInit(): void {

    this.popularTiposBeneficios();




    this.beneficioForm.statusChanges.subscribe(() => {

      if (this.beneficioForm.touched) {
        if (this.beneficioForm.valid) {
          this.continuarHabilitadoEvent.emit(true);
        } else {
          this.continuarHabilitadoEvent.emit(false);
        }
      }
    });


    this.beneficioForm.get('tipoBeneficio').setValue(this.tiposBeneficios[0].text);

  }

  popularTiposBeneficios() {
    this.tiposBeneficios = [];
    for (let item in TipoBeneficioLiquidacaoSentencaPrevidenciario) {
      if (!isNaN(Number(item))) {
        this.tiposBeneficios.push({ value: TipoBeneficioLiquidacaoSentencaPrevidenciario[item], text: TipoBeneficioLiquidacaoSentencaPrevidenciarioExtensions.toString(TipoBeneficioLiquidacaoSentencaPrevidenciario[TipoBeneficioLiquidacaoSentencaPrevidenciario[item]]) })
      }
    }
  }

  selectTipoBeneficioChange(select: NgxSelectOption) {
    this.tipoBeneficioSelecionado = TipoBeneficioLiquidacaoSentencaPrevidenciario[TipoBeneficioLiquidacaoSentencaPrevidenciario[select[0].data.value]];
    this.beneficioForm.get('tipoBeneficio').setValue(TipoBeneficioLiquidacaoSentencaPrevidenciario[select[0].data.value])

  }

  carregarDados(calculo: CalculoValorCausaPrevidenciario) {

    this.popularTiposBeneficios();

    this.calculoValorCausa = calculo;

    // this.tipoBeneficioSelecionado = this.tiposBeneficios.filter(tipo=>tipo.value === this.calculoValorCausa.valorCausa.beneficio.tipoBeneficio)[0].text

    this.beneficioForm.get('tipoBeneficio').setValue(TipoBeneficioLiquidacaoSentencaPrevidenciarioExtensions.toString(this.calculoValorCausa.valorCausa.beneficio.tipoBeneficio));
    this.beneficioForm.get('dib').setValue(new Date(this.calculoValorCausa.valorCausa.beneficio.dib));
    this.beneficioForm.get('dip').setValue(new Date(this.calculoValorCausa.valorCausa.referencia.dataCalculo));
    this.beneficioForm.get('rmi').setValue(this.calculoValorCausa.valorCausa.beneficio.rmi);
    if (this.calculoValorCausa.valorCausa.beneficio.acrescimo25Dependente) {
      this.beneficioForm.get('dib25').setValue(new Date(this.calculoValorCausa.valorCausa.beneficio.dib25));
    }

  }

  continuar() {


    let beneficio = new BeneficioValorCausaPrevidenciario();
    beneficio.tipoBeneficio = this.tipoBeneficioSelecionado;

    beneficio.dib = this.beneficioForm.get('dib').value;
    beneficio.dip = this.calculoValorCausa.valorCausa.referencia.dataCalculo;//this.beneficioForm.get('dip').value;
    beneficio.rmi = currency(this.beneficioForm.get('rmi').value, { separator: '.', decimal: ',' }).value;



    if (this.beneficioForm.get('dib25').value === '') {

      beneficio.dib25 = new Date(); //this.beneficioForm.get('dib25').value;
      beneficio.acrescimo25Dependente = false;

    }
    else {
      beneficio.dib25 = this.beneficioForm.get('dib25').value;

      beneficio.acrescimo25Dependente = true;
    }


    this.calculoValorCausa.valorCausa.beneficio = beneficio;


    this.continuarEvent.emit(this.calculoValorCausa);


  }


}
