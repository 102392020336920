import { Pipe, PipeTransform } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';

@Pipe({
  name: 'moedaCompetencia',
})
export class MoedaCompetenciaPipe implements PipeTransform {

  transform(value: Contribuicao, ...args: any[]): string {

    let prefix = "";

    prefix = AppComponent.moedaPrefix(value);
    return prefix;
  }

}
