import { LiquidacaoSentencaPrevidenciario } from "./liquidacaosentencaprevidenciario";
import { ParcelaLiquidacaoSentencaPrevidenciario } from "./parcelaliquidacaosentencaprevidenciario";
import { ParcelaValorCausaPrevidenciario } from "./parcelavalorcausaprevidenciario";
import { ValorCausaPrevidenciario } from "./valorcausaprevidenciario";

export class RelatorioValorCausaPrevidenciario {
    valorCausaPrevidenciario:ValorCausaPrevidenciario;
    parcelas:ParcelaValorCausaPrevidenciario[];
    totalAtrasadosValor:Number;
    totalAtrasadosMeses:Number; 
    exercicioCorrenteMeses:Number;
    exercicioCorrenteValor:Number;
    exerciciosAnterioresMeses:Number;
    exerciciosAnterioresValor:Number;
    total:Number;
    honorarios:Number;

    totalParcelasVencidas:number;
    totalParcelasVincendas:number;
    totalValorCausa:number;
}