import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { ImpostoRenda } from 'src/app/modelos/common/impostorenda';
import { ProdutoFinanceiro } from 'src/app/modelos/common/produtofinanceiro';
import { EdicaoSalarioAliquota } from 'src/app/modelos/previdenciario/edicaosalarioaliquota';
import { OpcoesMatriz } from 'src/app/modelos/previdenciario/opcoesmatriz';
import { OpcoesNumeroContribuicoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';
import { OpcoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesplanejamento';
import { PerfisContributivos } from 'src/app/modelos/previdenciario/perfiscontributivos';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-editor-opcoes-matriz',
  templateUrl: './editor-opcoes-matriz.component.html',
  styleUrl: './editor-opcoes-matriz.component.scss'
})
export class EditorOpcoesMatrizComponent implements OnInit{

  @Input() opcoesMatriz:OpcoesMatriz;

  @Output() opcoesMatrizAtualizadasEvent = new EventEmitter<OpcoesMatriz>();

  novaAliquota:number;
  // listaAliquotas:number[];

  novoSalario:number;
  // listaSalarios:number[];

  novaRentabilidade:number;
  // listaRentabilidades:number[];

  salarioMinimo:number;
  tetoInss:number;


  currencyInputMask = createMask(AppComponent.currencyInputOptions);
  
  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 0,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0',
    autoUnmask:true,
    unmaskAsNumber:true
  });
  // aliquotaInputMask = createMask({
  //   alias: 'numeric',
  //   groupSeparator: '.',
  //   radixPoint: ',',
  //   digits: 0,
  //   numericInput: true,
  //   digitsOptional: false,
  //   suffix: ' %',
  //   placeholder: '0',
  //   unmaskAsNumber:true,
  //   autoUnmask: true,
  //   onUnMask: (masked, unmasked) =>{
  //     this.novaAliquota = Number.parseInt(unmasked); //currency(masked, {separator: '.', decimal: ','}).value;
  //     return unmasked;
  //   }
  // });
  
  constructor(private configService:ConfigService){
    this.novoSalario = 0;
    this.novaAliquota = 0;
    this.novaRentabilidade = 0;

    this.configService.getSalarioMinimo().subscribe(salarioMinimo=>this.salarioMinimo = salarioMinimo);
    this.configService.getSalarioTeto().subscribe(teto=>this.tetoInss = teto);

    this.currencyInputMask.autoUnmask = true;
    this.currencyInputMask.unmaskAsNumber = true;

    // this.listaAliquotas = new Array();
    

    

  }

  ngOnInit(): void {
    this.montarOpcoes();
  }

  opcoesMatrizAtualizadas(){
    this.opcoesMatrizAtualizadasEvent.emit(this.opcoesMatriz);
  }

  fecharAddSalario(){}
  addSalario(){
    this.opcoesMatriz.listaSalarios.push(this.novoSalario);

    this.montarOpcoes();
  }

  fecharAddAliquota(){}
  addAliquota(){
    this.opcoesMatriz.listaAliquotas.push(this.novaAliquota / 100);

    this.montarOpcoes();
  }
  selecionarAliquota(aliquota:number){
    this.opcoesMatriz.listaAliquotas.push(aliquota);

    this.montarOpcoes();
  }


  fecharAddRentabilidade(){}
  addRentabilidade(){
    this.opcoesMatriz.listaRentabilidades.push(this.novaRentabilidade / 100);

    this.montarOpcoes();
  }

  selecionarRentabilidade(rentabilidade:number){
    // console.log('selecionar rentabilidade', rentabilidade);
    this.opcoesMatriz.listaRentabilidades.push(rentabilidade);

    // this.montarOpcoes();
  }
  aplicarOtimizacao(otimizacao:boolean){
    // console.log('otimizacao selecionada', otimizacao);
    this.opcoesMatriz.otimizacaoKertzman = otimizacao;

    this.montarOpcoes();
  }

  aplicarCustoImpostoRenda(opcao:boolean){
    this.opcoesMatriz.aplicarCustoImpostoRenda = opcao;

    this.montarOpcoes();

  }

  selecionarMultiploSalarioMinimo(multiplo:number){
    this.opcoesMatriz.listaSalarios.push(this.salarioMinimo* multiplo);

    this.montarOpcoes();
  }

  selecionarSalarioTeto(){
    this.opcoesMatriz.listaSalarios.push(this.tetoInss);

    this.montarOpcoes();
  }

  excluirSalario(salario:number){
    let indexSalario = this.opcoesMatriz.listaSalarios.indexOf(salario);
    this.opcoesMatriz.listaSalarios.splice(indexSalario,1);

    this.montarOpcoes();
  }
  excluirAliquota(aliquota:number){
    let index = this.opcoesMatriz.listaAliquotas.indexOf(aliquota);
    this.opcoesMatriz.listaAliquotas.splice(index,1);

    this.montarOpcoes();
  }
  excluirRendimento(rendimento:number){
    let index = this.opcoesMatriz.listaRentabilidades.indexOf(rendimento);
    this.opcoesMatriz.listaRentabilidades.splice(index,1);

    this.montarOpcoes();
  }

  montarOpcoes(){

    let opcoes:OpcoesPlanejamento[] = new Array<OpcoesPlanejamento>();

    this.opcoesMatriz.listaSalarios.forEach(salario=>{
      // console.log('salario', salario);
      this.opcoesMatriz.listaAliquotas.forEach(aliquota=>{
        // console.log('aliquota', aliquota);

        if(aliquota == 0.2 && salario == this.salarioMinimo)return;

          let opcao = new OpcoesPlanejamento();
          opcao.edicaoSalarioAliquota = new EdicaoSalarioAliquota();
          opcao.edicaoSalarioAliquota.aliquota = aliquota;
          opcao.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;
          opcao.edicaoSalarioAliquota.salario = salario;
          opcao.edicaoSalarioAliquota.salarioPiso = this.tetoInss;
          opcao.edicaoSalarioAliquota.salarioPiso = this.salarioMinimo;

          opcao.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.NORMAL;
          opcao.produtoFinanceiro = new ProdutoFinanceiro();
          opcao.produtoFinanceiro.taxaInflacao = 0.049;
          opcao.produtoFinanceiro.taxaRetorno = this.opcoesMatriz.listaRentabilidades.length > 0 ? this.opcoesMatriz.listaRentabilidades[0] : 0.13;
          opcao.produtoFinanceiro.taxaTributacao = 0.15;

          opcao.impostoRenda = new ImpostoRenda();
          opcao.impostoRenda.dependentes = 0;
          opcao.impostoRenda.educacao = 0;
          opcao.impostoRenda.investimento = this.opcoesMatriz.aplicarCustoImpostoRenda;
          opcao.impostoRenda.saude = 0;

          opcoes.push(opcao);


          this.opcoesMatriz.listaRentabilidades.forEach(rentabilidade=>{
          // console.log('rentabilidade', rentabilidade);

          

        });
      });
    });

    this.opcoesMatriz.opcoesPlanejamento = opcoes;
    this.opcoesMatrizAtualizadas();
  }

}
