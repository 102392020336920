import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MeiosPagamento } from 'src/app/modelos/pagamento/meiospagamento';
import { Plano } from 'src/app/modelos/pagamento/plano';
import { ListarPlanosService } from 'src/app/servicos/pagamento/safe2pay/listar-planos.service';

@Component({
  selector: 'app-assinar',
  templateUrl: './assinar.component.html',
  styleUrl: './assinar.component.scss'
})
export class AssinarComponent {

  listaPlanos:Plano[];

  constructor(private router: Router, private listarPlanosService:ListarPlanosService){


    this.listarPlanosService.listarPlanos()
    .then(planos=>{
      this.listaPlanos = (planos as Plano[]).sort((a,b)=> a.valor - b.valor);
    });
    
  }


  checkout(plano:Plano){

    switch (plano.meioPagamento) {
      case MeiosPagamento.CARTAOCREDITO:
        this.router.navigateByUrl('/calculei/usuario/checkout', { state: plano });
        break;
        case MeiosPagamento.PIX:
          this.router.navigateByUrl('/calculei/usuario/checkoutpix', { state: plano });
          break;
    
      default:
        break;
    }
    

  }




}
