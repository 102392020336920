import { ProtocolosCorrecaoPipe } from 'src/app/aplicacao/compartilhado/pipes/protocolos-correcao.pipe';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { NgxSelectOption } from 'ngx-select-ex';
import { AppComponent } from 'src/app/app.component';
import { RegraAplicacaoJuros } from 'src/app/modelos/common/regraaplicacaojuros';
import { RegraCorrecaoMonetaria } from 'src/app/modelos/correcaomonetaria/regracorrecaomonetaria';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { OpcoesProtocolosAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/opcoesprotocolosaplicacaojurosprevidenciario';
import { OpcoesProtocoloCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/opcoesprotocoloscorrecaomonetariaprevidenciario';
import { ProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/processoliquidacaosentencaprevidenciario';
import { ProtocoloAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/protocoloaplicacaojurosprevidenciario';
import { ProtocoloCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocolocorrecaomonetariaprevidenciario';
import { ProtocolosAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/protocolosaplicacaojurosprevidenciario';
import { ProtocolosCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocoloscorrecaomonetariaprevidenciario';
import { ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-aplicacao-juros-previdenciario.service';
import { ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-correcao-monetaria-previdenciario.service';
import { ProtocolosJurosPipe } from 'src/app/aplicacao/compartilhado/pipes/protocolos-juros.pipe';

@Component({
  selector: 'app-atualizacao-liquidacao-sentenca-previdenciario',
  templateUrl: './atualizacao-liquidacao-sentenca-previdenciario.component.html',
  styleUrls: ['./atualizacao-liquidacao-sentenca-previdenciario.component.scss']
})
export class AtualizacaoLiquidacaoSentencaPrevidenciarioComponent {


  @Input() calculoLiquidacaoSentencaPrevidenciario: CalculoLiquidacaoSentencaPrevidenciario;

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);
  currencyInputMask = createMask(AppComponent.currencyInputOptions);
  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0'
  });


  itemsSelectProtocoloCorrecao: any[];
  itemCorrecaoDefault: any;
  itemsSelectProtocoloJuros: any[];
  itemJurosDefault: any;

  linhasRegrasCorrecao: RegraCorrecaoMonetaria[] = new Array<RegraCorrecaoMonetaria>();
  regrasCorrecao: RegraCorrecaoMonetaria[] = new Array<RegraCorrecaoMonetaria>();

  linhasRegrasJuros: RegraAplicacaoJuros[] = new Array<RegraAplicacaoJuros>();;
  regrasJuros: RegraAplicacaoJuros[] = new Array<RegraAplicacaoJuros>();



  opcoesCorrecaoMonetariaPrevidenciario: OpcoesProtocoloCorrecaoMonetariaPrevidenciario;
  protocolosCorrecaoMonetariaPrevidenciario: ProtocoloCorrecaoMonetariaPrevidenciario[] = [];

  opcoesAplicacaoJurosPrevidenciario: OpcoesProtocolosAplicacaoJurosPrevidenciario;
  protocolosAplicacaoJurosPrevidenciario: ProtocoloAplicacaoJurosPrevidenciario[] = [];

  novaRegraCorrecaoVisivel: boolean;
  novaRegraJurosVisivel: boolean;


  dataCitacao: Date;
  dib: Date;
  dataCalculo: Date;




  constructor(private fb: FormBuilder,
    private protocoloCorrecaoPipe:ProtocolosCorrecaoPipe,
    private protocoloJurosPipe:ProtocolosJurosPipe,
    private listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario: ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService,
    private listarOpcoesProtocolosAplicacaoJurosPrevidenciarioService: ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService) {


    this.itemsSelectProtocoloCorrecao = (Object.keys(ProtocolosCorrecaoMonetariaPrevidenciario) as Array<keyof typeof ProtocolosCorrecaoMonetariaPrevidenciario>)
      .filter(chave => isNaN(Number(chave)))
      .map(tipo => { return { value: tipo, text: this.protocoloCorrecaoPipe.transform(ProtocolosCorrecaoMonetariaPrevidenciario[tipo]) } });


    this.itemsSelectProtocoloCorrecao.sort((a, b) => {
      let textA = a.text.toLowerCase();
      let textB = b.text.toLowerCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });


    this.itemsSelectProtocoloJuros = (Object.keys(ProtocolosAplicacaoJurosPrevidenciario) as Array<keyof typeof ProtocolosAplicacaoJurosPrevidenciario>)
      .filter(chave => isNaN(Number(chave)))
      .map(tipo => { return { value: tipo, text: this.protocoloJurosPipe.transform(ProtocolosAplicacaoJurosPrevidenciario[tipo]) } });


    this.itemsSelectProtocoloJuros.sort((a, b) => {
      let textA = a.text.toLowerCase();
      let textB = b.text.toLowerCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    // console.log('items correcao', this.itemsSelectProtocoloCorrecao);
    // console.log('items juros', this.itemsSelectProtocoloJuros);
  }

  carregarDados(calculo: CalculoLiquidacaoSentencaPrevidenciario) {

    this.calculoLiquidacaoSentencaPrevidenciario = calculo;

    // console.log('carregar dados atualizacao');

    this.listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario.listarOpcoes()
      .then((opcoes: OpcoesProtocoloCorrecaoMonetariaPrevidenciario) => {
        // console.log('opcoes protocolos correcao');
        // console.log(opcoes);



        this.opcoesCorrecaoMonetariaPrevidenciario = opcoes;
        this.protocolosCorrecaoMonetariaPrevidenciario = this.opcoesCorrecaoMonetariaPrevidenciario.protocolos;

        this.itemsSelectProtocoloCorrecao = this.protocolosCorrecaoMonetariaPrevidenciario.map((protocolo) => ({ value: ProtocolosCorrecaoMonetariaPrevidenciario[protocolo.protocolo], text: protocolo.texto }));



        if (typeof this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloCorrecaoMonetaria != 'undefined') {

          this.itemCorrecaoDefault = this.itemsSelectProtocoloCorrecao.filter(protocolo => protocolo.value === this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloCorrecaoMonetaria)[0];

          if (this.itemCorrecaoDefault != undefined) {
            // this.formCorrecao.get('protocoloCorrecao').setValue(this.itemCorrecaoDefault.text);
          }

          this.regrasCorrecao = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.regrasCorrecaoMonetaria.filter(regra => new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));   //this.protocolosCorrecaoMonetariaPrevidenciario[this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloCorrecaoMonetaria].regras.filter(regra=> new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));
          this.updateTabelaCorrecaoMonetaria();

        } else {

          this.itemCorrecaoDefault = this.itemsSelectProtocoloCorrecao.filter(protocolo => protocolo.value === ProtocolosCorrecaoMonetariaPrevidenciario.MANUALCALCULOJUSTICAFEDERAL.toString())[0];
          // this.formCorrecao.get('protocoloCorrecao').setValue(this.itemsSelectProtocoloCorrecao[ProtocolosCorrecaoMonetariaPrevidenciario.MANUALCALCULOJUSTICAFEDERAL].text);
          this.regrasCorrecao = this.protocolosCorrecaoMonetariaPrevidenciario[ProtocolosCorrecaoMonetariaPrevidenciario.MANUALCALCULOJUSTICAFEDERAL].regras.filter(regra => new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));
          this.updateTabelaCorrecaoMonetaria();

        }


      });



    this.listarOpcoesProtocolosAplicacaoJurosPrevidenciarioService.listarOpcoes()
      .then((opcoes: OpcoesProtocolosAplicacaoJurosPrevidenciario) => {

        this.opcoesAplicacaoJurosPrevidenciario = opcoes;
        this.protocolosAplicacaoJurosPrevidenciario = this.opcoesAplicacaoJurosPrevidenciario.protocolos;

        this.itemsSelectProtocoloJuros = this.protocolosAplicacaoJurosPrevidenciario.map((protocolo) => ({ value: ProtocolosAplicacaoJurosPrevidenciario[protocolo.protocolo], text: protocolo.texto }));

        if (typeof this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloAplicacaoJuros != 'undefined') {



          this.itemJurosDefault = this.itemsSelectProtocoloJuros.filter(protocolo => protocolo.value === this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloAplicacaoJuros)[0];
          if (this.itemJurosDefault != undefined) {
            // this.formJuros.get('protocoloJuros').setValue(this.itemJurosDefault.text);
          }
          this.regrasJuros = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.regrasAplicacaoJuros.filter(regra => new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib)); //this.protocolosAplicacaoJurosPrevidenciario[this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloAplicacaoJuros].regras.filter(regra=> new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataCitacao));
          this.updateTabelaRegrasJuros();


        } else {


          this.itemJurosDefault = this.itemsSelectProtocoloJuros.filter(protocolo => protocolo.value === ProtocolosAplicacaoJurosPrevidenciario.MANUALCALCULOJUSTICAFEDERAL.toString())[0];
          // this.formJuros.get('protocoloJuros').setValue(this.itemsSelectProtocoloJuros[ProtocolosAplicacaoJurosPrevidenciario.MANUALCALCULOJUSTICAFEDERAL].text);
          this.regrasJuros = this.protocolosAplicacaoJurosPrevidenciario[ProtocolosAplicacaoJurosPrevidenciario.MANUALCALCULOJUSTICAFEDERAL].regras.filter(regra => new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataCitacao));
          this.updateTabelaRegrasJuros();



        }
      });
  }











  selectItemCorrecao(selecao) {

    // this.regras = this.protocolosCorrecaoMonetariaPrevidenciario[Number.parseInt(option.value)].regras.filter(regra=> new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));
    this.updateTabelaCorrecaoMonetaria();
  }

  selectItemCorrecaoChanges(selecao: NgxSelectOption[]) {
    // this.formCorrecao.get('protocoloCorrecao').setValue(selecao[0].data.value);
    this.regrasCorrecao = this.protocolosCorrecaoMonetariaPrevidenciario.filter(protocolo => ProtocolosCorrecaoMonetariaPrevidenciario[protocolo.protocolo] === selecao[0].data.value)[0].regras.filter(regra => new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));
    this.updateTabelaCorrecaoMonetaria();

    this.novaRegraCorrecaoVisivel = selecao[0].data.value === ProtocolosCorrecaoMonetariaPrevidenciario[ProtocolosCorrecaoMonetariaPrevidenciario.CRITERIOESPECIFICO] ? true : false;
  }

  updateTabelaCorrecaoMonetaria() {
    this.linhasRegrasCorrecao = this.regrasCorrecao.map((prop, key) => {
      return {
        ...prop,
        id: key
      };
    });
  }

  adicionarNovaRegraCorrecao(regra: RegraCorrecaoMonetaria) {
    this.regrasCorrecao.push(regra);
    this.updateTabelaCorrecaoMonetaria();
  }


  selectItemJuros(selecao) {

    // this.regrasJuros = 

  }
  selectItemJurosChanges(selecao: NgxSelectOption[]) {

    // this.formJuros.get('protocoloJuros').setValue(selecao[0].data.value);
    this.regrasJuros = this.protocolosAplicacaoJurosPrevidenciario.filter(protocolo => ProtocolosAplicacaoJurosPrevidenciario[protocolo.protocolo] === selecao[0].data.value)[0].regras.filter(regra => new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));

    this.updateTabelaRegrasJuros();

    this.novaRegraJurosVisivel = selecao[0].data.value === ProtocolosAplicacaoJurosPrevidenciario[ProtocolosAplicacaoJurosPrevidenciario.CRITERIOESPECIFICO] ? true : false;
  }

  updateTabelaRegrasJuros() {
    this.linhasRegrasJuros = this.regrasJuros.map((prop, key) => {
      return {
        ...prop,
        id: key
      };
    });
  }

  adicionarRegraJuros(regra: RegraAplicacaoJuros) {
    this.regrasJuros.push(regra);
    this.updateTabelaRegrasJuros();
  }

  editarProtocoloCorrecao(tipo:string) {
    // console.log('editar protocolo correcao', tipo);
    this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloCorrecaoMonetaria = ProtocolosCorrecaoMonetariaPrevidenciario[tipo];

  }

  editarProtocoloJuros(tipo:string){
    // console.log('editar protocolo juros', tipo);
    this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloAplicacaoJuros = ProtocolosAplicacaoJurosPrevidenciario[tipo];

  }


  


}
