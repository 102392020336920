import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { RegraAplicacaoJuros } from 'src/app/modelos/common/regraaplicacaojuros';
import { RegraCorrecaoMonetaria } from 'src/app/modelos/correcaomonetaria/regracorrecaomonetaria';
import { Global } from 'src/app/modelos/global';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { IdentificadorIndice } from 'src/app/modelos/previdenciario/identificadorindice';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import { OpcoesProtocolosAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/opcoesprotocolosaplicacaojurosprevidenciario';
import { OpcoesProtocoloCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/opcoesprotocoloscorrecaomonetariaprevidenciario';
import { ParcelaLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/parcelaliquidacaosentencaprevidenciario';
import { ProtocoloAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/protocoloaplicacaojurosprevidenciario';
import { ProtocoloCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocolocorrecaomonetariaprevidenciario';
import { ProtocolosCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocoloscorrecaomonetariaprevidenciario';

import { ReferenciaLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/referencialiquidacaosentencaprevidenciario';
import { RelatorioLiquidacaoSentencaoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioliquidacaosentencaprevidenciario';
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';
import { TipoBeneficioLiquidacaoSentencaPrevidenciarioExtensions } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciarioextensions';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-aplicacao-juros-previdenciario.service';
import { ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-correcao-monetaria-previdenciario.service';
import { ListarRelatorioLiquidacaoSentencaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-relatorio-liquidacao-sentenca-previdenciario.service';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { ListarRelatorioLiquidacaoSentencaPrevidenciarioPdfService } from 'src/app/servicos/relatorio/listar-relatorio-liquidacao-sentenca-previdenciario-pdf.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { ListarClienteService } from 'src/app/servicos/usuario/listar-cliente.service';

@Component({
  selector: 'app-relatorio-liquidacao-sentenca-previdenciario',
  templateUrl: './relatorio-liquidacao-sentenca-previdenciario.component.html',
  styleUrls: ['./relatorio-liquidacao-sentenca-previdenciario.component.scss']
})
export class RelatorioLiquidacaoSentencaPrevidenciarioComponent implements OnInit {

  @Input() calculoLiquidacaoSentencaPrevidenciario: CalculoLiquidacaoSentencaPrevidenciario;



  protocolosCorrecaoMonetariaType = ProtocolosCorrecaoMonetariaPrevidenciario;

  protocolosCorrecao: ProtocoloCorrecaoMonetariaPrevidenciario[];
  regrasIndexadores: RegraCorrecaoMonetaria[];



  protocolosAplicacaoJuros: ProtocoloAplicacaoJurosPrevidenciario[];
  regrasAplicacaoJuros: RegraAplicacaoJuros[];

  identificadorIndiceType: IdentificadorIndice;


  tiposProcessoType: TiposProcessoLiquidacaoSentencaPrevidenciario;
  tipoProcessoTexto: string = '';
  tipoBeneficioTexto: string;

  relatorio: RelatorioLiquidacaoSentencaoPrevidenciario = new RelatorioLiquidacaoSentencaoPrevidenciario();



  dib25Visivel: boolean;
  honorariosVisivel: boolean;
  valorTotalAbatimentos: number;

  dataCalculo: Date;
  dataCitacao: Date;
  dib: Date;

  rows: ParcelaLiquidacaoSentencaPrevidenciario[];
  columns: any[];
  timeout: any = {};

  selected: any[] = [];
  temp = [];
  activeRow: any;


  aguardandoPdf: boolean = false;
  relatorioVisivel: boolean;


  constructor(private listarRelatorioLiquidacaoSentencaPrevidenciario: ListarRelatorioLiquidacaoSentencaPrevidenciarioService,
    private configService: ConfigService,
    private listarPDFService: ListarPDFService,
    private listarRelatorioLiquidacaoSentencaPrevidenciarioPdf: ListarRelatorioLiquidacaoSentencaPrevidenciarioPdfService,
    private notificacoesService: NotificacoesService,
    private listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario: ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService,
    private listarOpcoesProtocolosAplicacaoJurosPrevidenciario: ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService) {

    
  }

  ngOnInit(): void {




  }

  carregarDados(calculo: CalculoLiquidacaoSentencaPrevidenciario) {




    // this.calculoLiquidacaoSentencaPrevidenciario = calculo;

    // // console.log('carregar dados relatorio');
    // // console.log(this.calculoLiquidacaoSentencaPrevidenciario);

    // this.dataCalculo = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataCalculo;
    // this.dib = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib;
    // this.dataCitacao = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataCitacao;

    // this.regrasAplicacaoJuros = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.regrasAplicacaoJuros;
    // this.regrasIndexadores = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.regrasCorrecaoMonetaria;


    // if (this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos.length > 0) {
    //   this.valorTotalAbatimentos = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos.map(abatimento => abatimento.valor).reduce((soma, valor) => soma + valor);
    // }
    // if (this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorariosInclusos) {
    //   this.honorariosVisivel = true;
    // }



    // this.listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario.listarOpcoes()
    //   .then(opcoes => {
    //     this.protocolosCorrecao = (opcoes as OpcoesProtocoloCorrecaoMonetariaPrevidenciario).protocolos;

    //   });

    // this.listarOpcoesProtocolosAplicacaoJurosPrevidenciario.listarOpcoes()
    //   .then(opcoes => {
    //     this.protocolosAplicacaoJuros = (opcoes as OpcoesProtocolosAplicacaoJurosPrevidenciario).protocolos;

    //   });



    // if (typeof this.calculoLiquidacaoSentencaPrevidenciario.relatorioLiquidacaoSentenca.parcelas === 'undefined') {
    //   this.calcularResultados();
    // }
    // else {
    //   this.regrasAplicacaoJuros = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.regrasAplicacaoJuros;
    //   this.regrasIndexadores = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.regrasCorrecaoMonetaria;
    //   this.popularRelatorio(this.calculoLiquidacaoSentencaPrevidenciario.relatorioLiquidacaoSentenca);

    // }




    // this.tipoBeneficioTexto = TipoBeneficioLiquidacaoSentencaPrevidenciarioExtensions.toString(TipoBeneficioLiquidacaoSentencaPrevidenciario[this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.tipoBeneficio.toString()]);


  }

  calcularResultados() {
    // this.processandoRelatorioEvent.emit(true);

    // console.log('calculo relatorio');
    // console.log(this.calculoLiquidacaoSentencaPrevidenciario);


    // this.listarRelatorioLiquidacaoSentencaPrevidenciario.relatorio(this.calculoLiquidacaoSentencaPrevidenciario)
    //   .then((resultadoCalculo: CalculoLiquidacaoSentencaPrevidenciario) => {


    //     this.relatorio = resultadoCalculo.relatorioLiquidacaoSentenca;

    //     // console.log('calculo relatorio');
    //     // console.log(resultadoCalculo);




    //     let tipoProcesso: TiposProcessoLiquidacaoSentencaPrevidenciario = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso as TiposProcessoLiquidacaoSentencaPrevidenciario;


    //     switch (+TiposProcessoLiquidacaoSentencaPrevidenciario[tipoProcesso]) {
    //       case TiposProcessoLiquidacaoSentencaPrevidenciario.CONCESSAO:
    //         this.tipoProcessoTexto = 'Concessão';
    //         break;
    //       case TiposProcessoLiquidacaoSentencaPrevidenciario.REESTABELECIMENTO:
    //         this.tipoProcessoTexto = 'Reestabelecimento';
    //         break;
    //       case TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO:
    //         this.tipoProcessoTexto = 'Revisão';
    //         break;
    //       default:
    //         break;
    //     }



    //     this.rows = this.relatorio.parcelas;

    //     this.temp = this.rows.map((prop, key) => {
    //       return {
    //         ...prop,
    //         id: key
    //       };
    //     });

    //     this.calculoLiquidacaoSentencaPrevidenciario.relatorioLiquidacaoSentenca = this.relatorio;

    //     // console.log('relatorio pronto');
    //     // console.log(this.relatorio);



    //   });
  }

  popularRelatorio(relatorio: RelatorioLiquidacaoSentencaoPrevidenciario) {

    this.relatorio = relatorio;




    let tipoProcesso: TiposProcessoLiquidacaoSentencaPrevidenciario = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso as TiposProcessoLiquidacaoSentencaPrevidenciario;


    switch (+TiposProcessoLiquidacaoSentencaPrevidenciario[tipoProcesso]) {
      case TiposProcessoLiquidacaoSentencaPrevidenciario.CONCESSAO:
        this.tipoProcessoTexto = 'Concessão';
        break;
      case TiposProcessoLiquidacaoSentencaPrevidenciario.REESTABELECIMENTO:
        this.tipoProcessoTexto = 'Reestabelecimento';
        break;
      case TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO:
        this.tipoProcessoTexto = 'Revisão';
        break;
      default:
        break;
    }



    this.rows = this.relatorio.parcelas;

    this.temp = this.rows.map((prop, key) => {
      return {
        ...prop,
        id: key
      };
    });

    this.calculoLiquidacaoSentencaPrevidenciario.relatorioLiquidacaoSentenca = this.relatorio;
    this.relatorioVisivel = true;


  }

  baixarPDF(desconto: number) {


    this.configService.setAguarde(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    this.calculoLiquidacaoSentencaPrevidenciario.tipoCalculo = CalculoTipos.LIQUIDACAOSENTENCAPREVIDENCIARIO;
    this.calculoLiquidacaoSentencaPrevidenciario.usuario = Global.usuario;

    this.listarPDFService.ListarPDF(this.calculoLiquidacaoSentencaPrevidenciario)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'LIQUIDACAO-SENTENCA' + this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
        this.configService.setAguarde(false);

      });
  }


  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }



}
