<div *ngIf="clientes">
    <div class="row">
        <div class="col">
            <p class="h3">
                Meus Clientes
            </p>
        </div>
    </div>
    
    <div class="row flex d-flex py-2">
        <div class="col-3" *ngFor="let cliente of clientes" >
            <div class="row bg-secondary rounded m-2 p-2" role="button" (click)="selecionarCliente(cliente)">
                <div class="col">
                    <span class="h3 d-block p-0 m-0">
                        {{cliente.nome}}
                    </span>
                    <span class="h6 d-block p-0 m-0">
                        {{cliente.sobrenome}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    
</div>