import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AutenticacaoService } from './autenticacao.service';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ListarUsuarioService } from '../usuario/listar-usuario.service';
import { Global } from 'src/app/modelos/global';
import { ConfigService } from '../ui/config.service';
import { Permissoes } from 'src/app/modelos/usuario/permissoes';

@Injectable({
  providedIn: 'root'
})
export class AutorizacaoAdminService  {

  autorizado: boolean;

  constructor(private autenticacaoService: AutenticacaoService, private router: Router,
    private listarUsuarioService: ListarUsuarioService,
    private configService: ConfigService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {


    if (this.autenticacaoService.oauthService.hasValidAccessToken() && this.autenticacaoService.oauthService.hasValidAccessToken() && this.autenticacaoService.oauthService.hasValidIdToken()) {
      let claims = this.autenticacaoService.oauthService.getIdentityClaims();
      
      let usuarioRequest = new Usuario();
      usuarioRequest.rowKey = claims['oid'];
      usuarioRequest.email = claims['emails'][0];
      


      return this.listarUsuarioService.listarUsuario(usuarioRequest)
      .then(usuario=>{
        let usuarioResponse:Usuario = usuario as Usuario;
        if(usuarioResponse.permissao == Permissoes.Administrador){
          // console.log("setusuario autorizacaoadmin");
          this.configService.setUsuario(usuario as Usuario);
          return true;
        }
        else{
          this.router.navigateByUrl('/previdenciario/calculos/novocalculo');
          return false;
        }
      });
      
    }else{
      this.router.navigateByUrl('/login');
      return false;
    }

    


    // const idToken = this.autenticacaoService.oauthService.getIdToken();
    // const accessToken = this.autenticacaoService.oauthService.getAccessToken();

    // if (accessToken && idToken) {
    //   let claims = this.autenticacaoService.oauthService.getIdentityClaims();

    //   let usuarioRequest = new Usuario();
    //   usuarioRequest.rowKey = claims['oid'];
    //   usuarioRequest.email = claims['emails'][0];

    //   this.listarUsuarioService.listarUsuario(usuarioRequest)
    //     .then(async usuario => {

    //       //controle de usuario e assinatura
    //       this.configService.setUsuario(usuario as Usuario);
          
    //       if (Global.usuario.permissao == Permissoes.Administrador && this.autenticacaoService.oauthService.hasValidAccessToken()) {
    //         return true;
            
    //       }
    //       else {
    //         return false;
    //       }

    //     });
    // }
    // else {
    //   // this.router.navigateByUrl('/login');
    //   this.autenticacaoService.oauthService.initCodeFlow();
    //   return false;

    // }




  }
}