<div class=" header bg-back-dark">

  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-3">
        <div class=" col-lg-12">
          <span class=" text-white inline-block mb-0 h6 fw-lighter">Novo Cálculo de Valor da Causa</span>
          <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">

          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<div class=" container-fluid mt--3  ">

  <div class=" row">
    <div class=" col-lg-12 ">
      <div class=" card ">
        <!-- <div class=" card-header border-dark">
          {{titulo}}
        </div> -->
        <div class=" card-body  ">

          <div class="col-md-12 col-lg-12 col-xxl-12 pb-6 mb-6 ">

            <div class="row ">
              <div class="col-lg-12 col-xl-12 col-md-12  ">
                <app-barra-navegacao *ngIf="navegacao" #barraNavegacao [aguarde]="aguardeVisivel"
                  [navegacao]="navegacao" [voltarHabilitado]="voltarHabilitado" [proximoHabilitado]="proximoHabilitado"
                  (voltarEvent)="voltar($event)" (voltarSilenciosoEvent)="voltarSilenciosoAcao($event)"
                  (proximoEvent)="continuar($event)" [tabDirective]="tabDirective" (baixarPDFEvent)="baixarPDF($event)">
                </app-barra-navegacao>
              </div>
            </div>


            <div class="row ">

              <div class="col-md-12 col-lg-12 col-xxl-12 pb-6 mb-6 ">

                <tabset #tabsValorCausa>
                  <tab (selectTab)="tabSelecionada($event)" id="tabReferencia">
                    <ng-template tabHeading>
                      <div class="col-1 p-4 mb-5">
                        <span class=" timeline-step timeline-step-xlg bg-black ">
                          1
                        </span>
                      </div>
                    </ng-template>

                    <div class="mt-4 mb-4">
                      <!-- <div class="row mb-5">
                                              <div class="col">
                                                  <h1 class="display-4">Importação do CNIS
                                                  </h1>
                                              </div>
                                          </div> -->
                      <div>
                        <app-referencia-valor-causa-previdenciario #referencia *ngIf="calculoValorCausa.valorCausa.referencia"
                          [calculoValorCausa]="calculoValorCausa"
                          (continuarHabilitadoEvent)="continuarHabilitado($event)"
                          (continuarEvent)="referenciaConcluida($event)"></app-referencia-valor-causa-previdenciario>

                      </div>

                    </div>
                  </tab>


                  <tab (selectTab)="tabSelecionada($event)" id="tabBeneficio">
                    <ng-template tabHeading>
                      <div class="col-1 p-4 mb-5">
                        <span class=" timeline-step timeline-step-xlg bg-black ">
                          2
                        </span>
                      </div>
                    </ng-template>

                    <div class="mt-4 mb-4">
                      <!-- <div class="row mb-5">
                                              <div class="col">
                                                  <h1 class="display-4">
                                                      CNIS
                                                  </h1>
                                              </div>
                                          </div> -->


                      <app-beneficio-valor-causa-previdenciario #beneficio *ngIf="calculoValorCausa.valorCausa.beneficio"
                        [calculoValorCausa]="calculoValorCausa"
                        (continuarHabilitadoEvent)="continuarHabilitado($event)"
                        (continuarEvent)="beneficioConcluido($event)"></app-beneficio-valor-causa-previdenciario>
                      <div class="row">
                        <div class="col-md-12 col-lg-12col-xl-12">


                        </div>
                      </div>
                    </div>
                  </tab>






                  <tab (selectTab)="tabSelecionada($event)" id="tabDescontos">
                    <ng-template tabHeading>
                      <div class="col-1 p-4 mb-5">
                        <span class=" timeline-step timeline-step-xlg bg-black ">
                          3
                        </span>
                      </div>
                    </ng-template>

                    <div class="mt-4 mb-4">
                      <!-- <div class="row mb-5">
                                              <div class="col">
                                                  <h1 class="display-4">
                                                      Pré-Análise de Cumprimento de Requisitos
                                                  </h1>
                                              </div>
                                          </div> -->


                      <app-abatimentos-valor-causa-previdenciario #descontos *ngIf="calculoValorCausa.valorCausa.abatimentos"
                        [calculoValorCausa]="calculoValorCausa"
                        (continuarEvent)="abatimentosConcluido($event)"></app-abatimentos-valor-causa-previdenciario>
                      <div class="row">
                        <div class="col-md-12 col-lg-12col-xl-12">


                          <!-- <app-pre-analise-beneficio-kertzman #preAnalise
                                                      *ngIf="calculoPlanejamentoPrevidenciario != undefined"
                                                      [calculoPlanejamentoKertzman]="calculoPlanejamentoKertzman"
                                                      (gruposProntoEvent)="gruposPronto($event)"
                                                      [navegacao]="navegacao">
                                                  </app-pre-analise-beneficio-kertzman> -->





                        </div>
                      </div>
                    </div>
                  </tab>





                  <tab (selectTab)="tabSelecionada($event)" (deselect)="tabDeSelecionada($event)" id="tabAtualizacao">
                    <ng-template tabHeading>
                      <div class="col-1 p-4 mb-5">

                        <span class=" timeline-step timeline-step-xlg bg-default">
                          4
                        </span>
                      </div>
                    </ng-template>

                    <div class="mt-4 mb-4">
                      <!-- <div class="row mb-5">
                                              <div class="col">
                                                  <h1 class="display-4">
                                                      Resultado
                                                  </h1>
                                              </div>
                                          </div> -->

                      <app-atualizacao-valor-causa-previdenciario #atualizacao *ngIf="calculoValorCausa.valorCausa.atualizacao"
                        [calculoValorCausa]="calculoValorCausa"
                        (continuarEvent)="atualizacaoConcluida($event)"></app-atualizacao-valor-causa-previdenciario>
                      <div class="row">

                        <div class="col-md-12 col-lg-12col-xl-12">








                        </div>
                      </div>
                    </div>
                  </tab>


                  <!-- <tab (selectTab)="tabSelecionada($event)" (deselect)="tabDeSelecionada($event)" id="tabResumo">
                    <ng-template tabHeading>
                      <div class="col-1 p-4 mb-5">

                        <span class=" timeline-step timeline-step-xlg bg-default">
                          5
                        </span>
                      </div>
                    </ng-template>

                    <div class="mt-4 mb-4">
                      

                      <app-resumo-valor-causa-previdenciario *ngIf="resumoValorCausa"
                        [calculoValorCausa]="calculoValorCausa"
                        (continuarEvent)="resumoConcluido($event)"></app-resumo-valor-causa-previdenciario>
                      <div class="row">

                        <div class="col-md-12 col-lg-12col-xl-12">








                        </div>
                      </div>
                    </div>
                  </tab> -->




                  <tab (selectTab)="tabSelecionada($event)" (deselect)="tabDeSelecionada($event)" id="tabRelatorio">
                    <ng-template tabHeading>
                      <div class="col-1 p-4 mb-5">

                        <span class=" timeline-step timeline-step-xlg bg-default">
                          <i class="bi-check2"></i>
                        </span>
                      </div>
                    </ng-template>

                    <div class="mt-4 mb-4">

                      <app-relatorio-valor-causa-previdenciario *ngIf="calculoValorCausa.relatorioValorCausa"
                        [calculoValorCausa]="calculoValorCausa" (continuarEvent)="relatorioConcluido($event)"
                        (processandoRelatorioEvent)="processandoRelatorioEvent($event)"></app-relatorio-valor-causa-previdenciario>
                      <div class="row">

                        <div class="col-md-12 col-lg-12col-xl-12">








                        </div>
                      </div>
                    </div>
                  </tab>


                </tabset>
              </div>
            </div>




            <div class="row ">
              <div class="col-lg-12 col-xl-12 col-md-12  ">

              </div>
            </div>





          </div>







        </div>
      </div>
    </div>
  </div>
</div>