import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import { RelatorioLiquidacaoSentencaoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioliquidacaosentencaprevidenciario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListarRelatorioLiquidacaoSentencaPrevidenciarioService {

  constructor(private httpClient: HttpClient) { }

  relatorio(calculo:CalculoLiquidacaoSentencaPrevidenciario){
    return Promise.all([this.apiCall(calculo)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(calculo:CalculoLiquidacaoSentencaPrevidenciario){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ListarRelatorioLiquidacaoSentencaPrevidenciario',calculo, {'headers':headers}).toPromise();
    
    return data;
  }
}
