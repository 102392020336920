import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Ficha } from 'src/app/modelos/previdenciario/ficha';
import { Pergunta } from 'src/app/modelos/previdenciario/pergunta';
import { TipoRespostaPipe } from '../pipes/tipo-resposta.pipe';
import { FormBuilder } from '@angular/forms';
import { TiposResposta } from 'src/app/modelos/previdenciario/tiposResposta';
import { Resposta } from 'src/app/modelos/previdenciario/resposta';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-ficha',
  templateUrl: './add-ficha.component.html',
  styleUrl: './add-ficha.component.scss'
})
export class AddFichaComponent {

  @Output() fichaAdicionadaEvent = new EventEmitter<Ficha>();
  @ViewChild("novaOpcao") novaOpvao: ElementRef;
  @Output() addPerguntaEvent = new EventEmitter<Pergunta>();

  ficha:Ficha;
  pergunta: Pergunta;

  tiposRespostasType = TiposResposta;
  novaPerguntaForm = this.fb.group({
    titulo: [""],
    descricao: [""],
    instrucao: [""],
    link: [""],
  });


  listaNovasOpcoes: string[] = new Array<string>();

  itemsTiposResposta: any[];

  constructor(private fb: FormBuilder,
    private configService:ConfigService, private calculeiApiService:CalculeiApiService,
    private tipoRespostaPipe: TipoRespostaPipe
  ) {
    this.ficha = new Ficha();
    this.ficha.nome = "Nova ficha"
    this.configService.getUsuario().subscribe(usuario=>this.ficha.usuario = usuario);

    this.ficha.perguntas = new Array<Pergunta>();



    this.pergunta = new Pergunta();
    this.pergunta.tipoResposta = TiposResposta.TEXTO;
    this.pergunta.titulo = "";
    this.pergunta.descricao = "";
    this.pergunta.instrucao = "";
    this.pergunta.link = "";
    this.pergunta.respostas = new Array<Resposta>();
    this.pergunta.opcoes = new Array<string>();
    this.pergunta.respondida = false;

    

    this.itemsTiposResposta = (Object.keys(TiposResposta) as Array<keyof typeof TiposResposta>)
      .filter(chave => isNaN(Number(chave)))
      .map(tipo => { return { value: tipo, text: this.tipoRespostaPipe.transform(TiposResposta[tipo]) } });


      this.novaPerguntaForm.valueChanges.subscribe(changes=>{

        this.pergunta.titulo = changes.titulo;
        this.pergunta.descricao = changes.descricao;
        this.pergunta.instrucao = changes.instrucao;
        this.pergunta.link = changes.link;

      })

      
    
  }

  editarTipoResposta(value: string) {
    // console.log('value tipo resposta',value);
    this.pergunta.tipoResposta = TiposResposta[value];
  }


  addFicha(){
    // console.log('add ficha', this.ficha)
    this.fichaAdicionadaEvent.emit(this.ficha);
    
  }

  addOpcao() {
    this.listaNovasOpcoes.push(this.novaOpvao.nativeElement.value);
    // console.log("lista opcoes", this.listaNovasOpcoes);
    this.pergunta.opcoes = this.listaNovasOpcoes;

  }

  addPergunta() {
    // console.log('add pergunta nova pergunta', this.pergunta)
    this.ficha.perguntas.push(JSON.parse(JSON.stringify(this.pergunta)));
  }

  salvarFicha() {

    this.configService.setAguarde(true);

    this.calculeiApiService.api("SalvarFicha", this.ficha).subscribe(resultado => {
      // console.log('resultado salvar ficha', resultado);
      this.configService.setAguarde(false);

      this.fichaAdicionadaEvent.emit(resultado as Ficha);
      
      Swal.fire({
        title: 'Ficha pronta...',
        text: 'sua ficha foi salva e vai aparecer na lista de fichas quando você for criar a próxima entrevista',
        icon: 'success',
        iconColor: "black",
        showCloseButton: false,
        showConfirmButton: false,
        confirmButtonText: 'ok',
        confirmButtonColor: 'black'

      }).then(() => {

      });
    });

  }
}
