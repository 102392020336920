import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { Sexo } from 'src/app/modelos/previdenciario/sexo';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-add-cliente',
  templateUrl: './add-cliente.component.html',
  styleUrl: './add-cliente.component.scss'
})
export class AddClienteComponent {

  @Output() clienteAdicionadoEvent = new EventEmitter<Cliente>();

  cpfMask = createMask('999.999.999-99');
  telefoneMask = createMask('99 99999-9999');
  cepMask = createMask('99999-999');
  cliente: Cliente;
  sexoType = Sexo;

  novoClienteForm = this.fb.group({
    nome: [""],
    sobrenome: [""],
    sexo: [Sexo.Masculino],
    cpf: [""],
    email: [""],
    telefone: [""],
    cep: [""],
    endereco: [""],
    cidade: [""],
    estado: [""],
    pais: [""],
  });

  constructor(private fb: FormBuilder, private configService: ConfigService, private calculeiApiService: CalculeiApiService) {
    this.cliente = new Cliente();
    this.configService.getUsuario().subscribe(usuario => this.cliente.usuario = usuario);

    this.cpfMask.autoUnmask = true;
    this.cpfMask.unmaskAsNumber = true;
    this.cpfMask.placeholder = "000.000.000-00";

    this.cepMask.autoUnmask = true;
    this.cepMask.unmaskAsNumber = true;
    this.cepMask.placeholder = "00000-000";


    this.telefoneMask.autoUnmask = true;
    this.telefoneMask.unmaskAsNumber = true;
    this.telefoneMask.placeholder = "00 00000-0000";

    this.novoClienteForm.valueChanges.subscribe(changes => {

      this.cliente.nome = changes.nome;
      this.cliente.sobrenome = changes.sobrenome;
      this.cliente.sexo = changes.sexo;
      this.cliente.cpf = changes.cpf;
      this.cliente.email = changes.email;
      this.cliente.telefone = changes.telefone;
      this.cliente.cep = changes.cep;
      this.cliente.endereco = changes.endereco;
      this.cliente.cidade = changes.cidade;
      this.cliente.uf = changes.estado;
      this.cliente.pais = changes.pais;


    })
  }

  edicaoSexo(value) {
    // console.log('edicao sexo', value);
  }

  addCliente() {

    this.configService.setAguarde(true);
    this.calculeiApiService.api("SalvarCliente", this.cliente).subscribe(cliente=>{
      this.configService.setAguarde(false);
      this.clienteAdicionadoEvent.emit(cliente as Cliente)
      // console.log('cliente', this.cliente);
    })
    

  }

}
