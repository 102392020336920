import {Pipe, PipeTransform} from '@angular/core';
import { MeiosPagamentoEx } from 'src/app/modelos/pagamento/meiospagamento';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';

@Pipe({name:'meioPagamento'})
export class MeiosPagamentoPipe implements PipeTransform{

    transform(value: any, ...args: any[]): string {
        return MeiosPagamentoEx.meioToString(value);
    }

}