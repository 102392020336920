import { Base } from "../base";

export class CamposMatriz extends Base {
    aliquotaSalario: boolean;
    dataAposentadoria: boolean;
    regra: boolean;
    valorBeneficio: boolean;
    totalContribuicoes: boolean;
    valorContribuicao: boolean;
    totalInvestimento: boolean;
    roiAcumulado: boolean;
    taxaRetorno: boolean;
    payback: boolean;
    ganhoRoi: boolean;
    percentualTeto: boolean;
    acumuladoCapitalizacao: boolean;
}
