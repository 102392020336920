import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ImportacaoFatura } from 'src/app/modelos/faturas/importacaofatura';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { ImportacaoCNIS } from 'src/app/modelos/previdenciario/importacaocnis';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelatorioTempoContribuicaoService {

  constructor(private httpClient: HttpClient) { }

  relatorioTempoContribuicao(requestRelatorioTempoContribuicao:RequestRelatorioTempoContribuicao){
    return Promise.all([this.apiCall(requestRelatorioTempoContribuicao)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(requestRelatorioTempoContribuicao:RequestRelatorioTempoContribuicao){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/GerarRelatorioTempoContribuicao',requestRelatorioTempoContribuicao, {'headers':headers}).toPromise();
    return data;
  }
}
