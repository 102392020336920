export enum OpcoesNumeroContribuicoesPlanejamento{
    NORMAL,
    ESPECIFICOANO,
    ESPECIFICO,
    MANTERQUALIDADESEGURADO,
    CHEIO,
    PERIODOMESES
}

export class OpcoesNumeroContribuicoesPlanejamentoEx{
    static toString(opcao:OpcoesNumeroContribuicoesPlanejamento){

        let texto:string = "";

        switch (opcao) {
            case OpcoesNumeroContribuicoesPlanejamento.ESPECIFICO:
                texto = "Quantidade específica";
                
                break;
                case OpcoesNumeroContribuicoesPlanejamento.ESPECIFICOANO:
                texto = "Quantidade específica por ano durante o tempo necessário para cumprir o requisito de idade";
                
                break;
                case OpcoesNumeroContribuicoesPlanejamento.NORMAL:
                texto = "Apenas a quantidade mínima para o cumprimento dos requisitos e manter a qualidade de segurado quando a carência e tempo de contribuição forem alcançados";
                
                break;
                case OpcoesNumeroContribuicoesPlanejamento.MANTERQUALIDADESEGURADO:
                texto = "Apenas o necessário para manter a qualidade de segurado";
                
                break;

                case OpcoesNumeroContribuicoesPlanejamento.CHEIO:
                texto = "Contribuir até a data da Aposentadoria";
                break;

                case OpcoesNumeroContribuicoesPlanejamento.PERIODOMESES:
                texto = "Fazer uma contribuição a cada 'X' meses";
                break;

        
            default:
                break;
        }

        return texto;

    }
}