import { Component, Input } from '@angular/core';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-aguarde-config',
  templateUrl: './aguarde-config.component.html',
  styleUrl: './aguarde-config.component.scss'
})
export class AguardeConfigComponent {

  @Input() aguarde:boolean;
  
  constructor(private configService:ConfigService){
    this.configService.getAguarde().subscribe(status=>this.aguarde = status);
  }
}
