import { ResultadoCalculo } from "../common/resultadocalculo"
import { CalculoBeneficios } from "./calculobeneficios";
import { LeituraCNIS } from "./leituracnis";
import { ManutencaoQualidadeSegurado } from "./manutencaoqualidadesegurado"

export class CalculoMapaQualidadeSegurado extends ResultadoCalculo{

    manutencao:ManutencaoQualidadeSegurado[];
    manutencaoConsolidada:ManutencaoQualidadeSegurado[];
    calculoBeneficios:CalculoBeneficios;



}