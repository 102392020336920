<div *ngIf="calculo">
    <div class="row py-4 p-2">
        <div class="col-md-6">
            <!-- <img src={{logoUrl}} width="100px"> -->
        </div>

        <div class="col-md-6 text-right">
            
            <p class="h2">
                Processo
            </p>
            <p>
                {{calculo.liquidacaoSentenca.referencia.numeroProcesso}}
            </p>
        </div>
    </div>

    <div class="my-5"></div>

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1 text-default">Mémória de Cálculo de Liquidação de Sentença</span>
        </div>
    </div>
    

    <app-resumo-liquidacao-sentenca [calculoLiquidacaoSentenca]="calculo"></app-resumo-liquidacao-sentenca>
    <app-totais-liquidacao-sentenca [calculoLiquidacaoSentenca]="calculo"></app-totais-liquidacao-sentenca>
    <app-parcelas-liquidacao-sentenca [calculoLiquidacaoSentenca]="calculo"></app-parcelas-liquidacao-sentenca>
</div>