

<div class="  ">

    <div class="pb-4">
      <div class="card">
        <div class="row">
          <div class="col">
            <div class="card-body">
              <span class=" display-3 font-weight-lighter  mb-0 text-default">
                Descontos
              </span>
  
              
            </div>
          </div>
  
        </div>
  
  
        <div class="row">
          <div class="col">



            <div class="row">
                <div class="col-12 col-xxl-6 d-flex">
                   <div class="card w-100">
                       <div class=" card-header border-dark"><h5 class=" h3 mb-0">Hiscre</h5></div>
                       <div class="card-body d-flex flex-column" >
                           <div class="row py-4">
                               <div class="col">
                                   <span class=" h2 font-weight-bold ">Importar HISCRE baixado do "MEU INSS" <i class="bi-question-circle" container="body"   placement="right"  tooltipClass="tooltip-fatorprevidenciario" 
                                       tooltip=" Caso o segurado já tenha recebido algum valor referente ao benefício em questão, os abatimentos podem ser importados a partir do Histórico de Créditos emitido pelo INSS" ></i></span>
                               </div>
                           </div>
                           <div class="row mt-auto" >
                               <div class="col" >
                                   <app-importador-hiscre (abatimentosHiscreEvent)="atualizarAbatimentosHiscre($event)" (processandoHiscreEvent)="processandoHiscre($event)" ></app-importador-hiscre>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>   
               
                <div class="col-12 col-xxl-6 d-flex">
                   <div class="card w-100">
                       <div class=" card-header border-dark"><h5 class=" h3 mb-0">Evolução de RMI</h5></div>
                       <div class="card-body d-flex flex-column">
                           <div class="row py-4">
                               <div class="col">
                                   <span class=" h2 font-weight-bold ">
                                       Gerar valores a serem descontados com base na RMI <i class="bi-question-circle" container="body"   placement="right"  tooltipClass="tooltip-fatorprevidenciario" 
                                       tooltip=" Quando o arquivo do histórico de crédito (Hiscre) não estiver disponível, uma aproximação dos valores pagos pode ser feita com base na DIB, DIP e na RMI. O valores serão gerados com base na evolução da RMI no período, inclusive décimo-terceiros quando aplicável" ></i>
                                   </span>
                               </div>
                           </div>
                           <div class="row mt-auto">
                               <div class="col">
                                   <app-nova-evolucao-rmi *ngIf="calculoValorCausa" [tipoBeneficio]="tipoBeneficio" (salvarAbatimentosNovaEvolucaoRMIEvent)="salvarAbatimentosNovaEvolucaoRMI($event)" (salvarNovaEvolucaoRMIEvent)="salvarNovaEvolucaoRMI($event)" (processandoNovaEvolucaoRMIEvent)="processandoNovaEvolucaoRMI($event)"   ></app-nova-evolucao-rmi>
                                   
                               </div>
                           </div>
                       </div>
                   </div>
                </div>   
               
               
               
                <div class="col-12 col-xxl-6 d-flex">
                   <div class="card w-100">
                       <div class=" card-header border-dark"><h5 class=" h3 mb-0">Formulário valor por competência</h5></div>
                       <div class="card-body d-flex flex-column">
                           <div class="row py-4">
                               <div class="col">
                                   <span class=" h2 font-weight-bold ">Preencher manualmente os valores a serem descontados <i class="bi-question-circle" container="body"   placement="right"  tooltipClass="tooltip-fatorprevidenciario" 
                                       tooltip=" Quando houver poucos valores a serem descontados, ou o HISCRE não estiver disponível, você pode incluir cada valor individualmente através deste formulário" ></i>
                                   </span>
                               </div>
                           </div>
                           <div class="row mt-auto">
                               <div class="col">
                                   <app-novo-valor-competencia (salvarNovoAbatimentoEvent)="salvarNovoAbatimento($event)" ></app-novo-valor-competencia>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>   
               
                
               </div>
               
               
               
                 <div *ngIf="abatimentos.length > 0" class="row">
                   <div class=" col-12">
                       <div class=" card">
                         <div class=" card-header border-dark"><h5 class=" h3 mb-0">Abatimentos</h5></div>
                         <div class=" card-body">
                           <div class="row">
                               <div class="col-lg-12">
                                   <div class="dataTables_wrapper">
                                       <ngx-datatable
                                         class="material striped selection-cell"
                                         [columnMode]="'force'"
                                         [headerHeight]="50"
                                         [footerHeight]="50"
                                         [rowHeight]="'auto'"
                                         [rows]="linhasAbatimentos"
                                       >
                                   
                                           <ngx-datatable-column name="Competência" prop="competencia">
                                               <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                   
                                                   <span>{{ value | date : "dd/MM/yyyy" }}</span>
                                                   <span *ngIf="row.decimoTerceiro" > 13<sup>o</sup> </span>
                                               </ng-template>
                                               
                                           </ngx-datatable-column>
                                           <ngx-datatable-column name="Valor" prop="valor">
                                               
                                               <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                   <span>{{value | currency}}</span>
                                                 </ng-template>
                                           </ngx-datatable-column>
                                           
                                           
                                       </ngx-datatable>
                                   </div>
                               </div>
                           </div>
                         </div>
                       </div>
                     </div>
               </div>
               
            
  
          </div>
        </div>
  
  
      </div>
  
  
  
    </div>
  
  
  </div>



