import { DayOfWeek } from "./dayofweek"
import { DiscountType } from "./discounttype"
import { PlanFrequence } from "./planfrequence"
import { PlanOption } from "./planoption"

export class CriarPlanoRequest{
    planOption: PlanOption;
    planFrequence:PlanFrequence;
    dayOfWeek:DayOfWeek;
    name: string;
    description:string;
    amount:number;
    chargeDay:number;
    subscriptionTax:number;
    subscriptionLimit:number;
    isImmediateCharge:boolean;
    billingCycle:number;
    daysBeforeChargeDateExpiration:number;
    callbackUrl:string;
    daysDue: number;
    
    instruction: string;
    penaltyAmount: number;
    interestAmount: number;
    
    discountType: DiscountType;
    discountDue:Date;
    discountAmount: number;
    isProRata: boolean;
}