import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { RelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/relatoriotempocontribuicao';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';
import { RelatorioTempoContribuicaoService } from 'src/app/servicos/calculo/previdenciario/relatorio-tempo-contribuicao.service';

@Component({
  selector: 'app-quadro-relatorio-tempo-contribuicao',
  templateUrl: './quadro-relatorio-tempo-contribuicao.component.html',
  styleUrls: ['./quadro-relatorio-tempo-contribuicao.component.scss']
})
export class QuadroRelatorioTempoContribuicaoComponent implements OnInit {

  @Input() request:RequestRelatorioTempoContribuicao;
  @Output() relatorioProntoEvent = new EventEmitter<boolean>();

  relatorioTempoContribuicao:RelatorioTempoContribuicao;

  constructor(private relatorioTempoContribuicaoService:RelatorioTempoContribuicaoService) { 

   
  }

  ngOnInit(): void {
    this.relatorioTempoContribuicaoService.relatorioTempoContribuicao(this.request)
    .then(relatorio=>{
      this.relatorioTempoContribuicao = relatorio as RelatorioTempoContribuicao;
      this.relatorioProntoEvent.emit(true);
    });
  }

}
