import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Arquivo } from 'src/app/modelos/faturas/arquivo';
import { Global } from 'src/app/modelos/global';
import { Abatimento } from 'src/app/modelos/previdenciario/abatimento';
import { Hiscre } from 'src/app/modelos/previdenciario/hiscre';
import { ImportacaoHiscre } from 'src/app/modelos/previdenciario/importacaohiscre';
import { LeituraHiscre } from 'src/app/modelos/previdenciario/leiturahiscre';
import { AzureBlobService } from 'src/app/servicos/azure/azure-blob.service';
import { ImportarHiscreService } from 'src/app/servicos/calculo/previdenciario/importar-hiscre.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-importador-hiscre',
  templateUrl: './importador-hiscre.component.html',
  styleUrls: ['./importador-hiscre.component.scss']
})
export class ImportadorHiscreComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;
  @Output() abatimentosHiscreEvent = new EventEmitter<Abatimento[]>();
  @Output() processandoHiscreEvent = new EventEmitter<boolean>();

  progressoImportacao = new BehaviorSubject<Number>(0);
  importacaoHiscre: ImportacaoHiscre;
  aguardeVisivel: boolean;


  constructor(private azureBlobService: AzureBlobService,
    private importarHiscreService: ImportarHiscreService) {
    this.progressoImportacao.subscribe(progresso => {
      if (progresso == 100) {
        setTimeout(() => {
          this.importarHiscreService.importarHiscre(this.importacaoHiscre)
            .then(abatimentos => {
              this.abatimentosHiscreEvent.emit(abatimentos as Abatimento[]);
              this.processandoHiscreEvent.emit(false);
              this.aguardeVisivel = false;
            });

        }, 1000);
      }
    })
  }



  ngOnInit(): void {
  }

  selecionarArquivo(event: Event): void {

    this.processandoHiscreEvent.emit(true);
    this.aguardeVisivel = true;

    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;






    // this.fileInput.nativeElement.value = '';
    let file: File = files[0] as File;


    this.importacaoHiscre = new ImportacaoHiscre();
    this.importacaoHiscre.rowKey = uuidv4();
    this.importacaoHiscre.partitionKey = Global.usuario.rowKey;
    this.importacaoHiscre.arquivos = [];
    this.importacaoHiscre.usuario = Global.usuario;

    let arquivo: Arquivo = new Arquivo();
    arquivo.referenciaLocal = uuidv4() + ".pdf";
    arquivo.referenciaCloud = Global.usuario.rowKey + "/" + this.importacaoHiscre.rowKey;
    arquivo.partitionKey = this.importacaoHiscre.rowKey;
    arquivo.rowKey = uuidv4();

    this.importacaoHiscre.arquivos.push(arquivo);

    this.azureBlobService.upLoadArquivo(arquivo, files[0])
      .subscribe(progress => {
        this.setProgressoImportacao(progress);
      });
    

  }

  showFileDialog(): void {
    this.fileInput.nativeElement.click();
  }

  setProgressoImportacao(progresso: Number) {
    this.progressoImportacao.next(progresso);
  }
  getProgressoImportacao(): Number {
    return this.progressoImportacao.getValue();
  }

}
