import { Component } from '@angular/core';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-meus-dados',
  templateUrl: './meus-dados.component.html',
  styleUrl: './meus-dados.component.scss'
})
export class MeusDadosComponent {

  usuario:Usuario;
  

  constructor(private configService:ConfigService){
    this.configService.getUsuario().subscribe(usuario=>{
      this.usuario = usuario;
    });
  }

}
