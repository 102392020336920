import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { Global } from "./modelos/global";
import { Usuario } from "./modelos/usuario/usuario";
import { AutenticacaoService } from "./servicos/oauth/autenticacao.service";
import { ConfigService } from "./servicos/ui/config.service";
import { createMask, InputmaskOptions } from '@ngneat/input-mask';
import ChartDataLabels from 'chartjs-plugin-datalabels';





import { Chart } from "chart.js/auto";
import Swal from "sweetalert2";


import { Subscription } from "rxjs";
import { StatusAssinatura } from "./modelos/usuario/statusassinatura";
import { Permissoes } from "./modelos/usuario/permissoes";
import { Contribuicao } from "./modelos/previdenciario/contribuicao";

import { L10n, setCulture } from '@syncfusion/ej2-base';

// https://github.com/syncfusion/ej2-locale/blob/master/src/pt-BR.json
// origem da lista

setCulture('pt');

L10n.load({
  "pt": {
    "grid": {
      "EmptyRecord": "Não há registros a serem exibidos",
      "True": "verdadeiro",
      "False": "falso",
      "InvalidFilterMessage": "Dados da filtragem inválidos",
      "GroupDropArea": "Arraste um cabeçalho de coluna aqui para agrupar sua coluna",
      "UnGroup": "Clique aqui para desagrupar",
      "GroupDisable": "O agrupamento está desativado para esta coluna",
      "FilterbarTitle": "célula da barra de filtro",
      "EmptyDataSourceError": "O DataSource não deve estar vazio no carregamento inicial, pois as colunas são geradas a partir do dataSource no AutoGenerate Column Grid",
      "Add": "Adicionar",
      "Edit": "Editar",
      "Cancel": "Cancelar",
      "Update": "Atualizar",
      "Delete": "Excluir",
      "Print": "Imprimir",
      "Pdfexport": "Exportar PDF",
      "Excelexport": "Exportar Excel",
      "Wordexport": "Exportar Word",
      "Csvexport": "Exportar CSV",
      "Search": "Buscar",
      "Columnchooser": "Selecionar Colunas",
      "Save": "Salvar ",
      "Item": "item",
      "Items": "itens",
      "EditOperationAlert": "Nenhum registro selecionado para operação de edição",
      "DeleteOperationAlert": "Nenhum registro selecionado para operação de exclusão",
      "SaveButton": "Salvar ",
      "OKButton": "OK",
      "CancelButton": "Cancelar",
      "EditFormTitle": "Editar registro",
      "AddFormTitle": "Adicionar novo registro",
      "BatchSaveConfirm": "Tem certeza de que deseja salvar as alterações?",
      "BatchSaveLostChanges": "Alterações não salvas serão perdidas. Você tem certeza que quer continuar?",
      "ConfirmDelete": "Tem certeza de que deseja excluir o registro?",
      "CancelEdit": "Tem certeza de que deseja cancelar as alterações?",
      "ChooseColumns": "Escolher colunas",
      "SearchColumns": "Buscar colunas",
      "Matchs": "Nenhuma correspondência encontrada",
      "FilterButton": "Filtrar",
      "ClearButton": "Limpar",
      "StartsWith": "Começa com",
      "EndsWith": "Termina com",
      "Contains": "Contém",
      "Equal": "Igual",
      "NotEqual": "Diferente",
      "LessThan": "Menor que",
      "LessThanOrEqual": "Menor ou igual",
      "GreaterThan": "Maior que",
      "GreaterThanOrEqual": "Maior ou igual",
      "ChooseDate": "Escolha uma data",
      "EnterValue": "Digite o valor",
      "Copy": "Copiar",
      "Group": "Agrupar por esta coluna",
      "Ungroup": "Desagrupar por esta coluna",
      "autoFitAll": "Ajustar automaticamente a todas as colunas",
      "autoFit": "Ajustar automaticamente a esta coluna",
      "Export": "Exportar",
      "FirstPage": "Primeira página",
      "LastPage": "Última página",
      "PreviousPage": "Página anterior",
      "NextPage": "Próxima página",
      "SortAscending": "Classificar em ordem ascendente",
      "SortDescending": "Classificar em ordem decrescente",
      "EditRecord": "Editar registro",
      "DeleteRecord": "Apagar registro",
      "FilterMenu": "Filtro",
      "SelectAll": "Selecionar tudo",
      "Blanks": "Espaços em branco",
      "FilterTrue": "Verdadeiro",
      "FilterFalse": "Falso",
      "NoResult": "Nenhum resultado encontrada",
      "ClearFilter": "Limpar filtro",
      "NumberFilter": "Filtros numéricos",
      "TextFilter": "Filtros de texto",
      "DateFilter": "Filtros de data",
      "DateTimeFilter": "Filtros DateTime",
      "MatchCase": "Caso de compatibilidade",
      "Between": "Entre",
      "CustomFilter": "Filtro customizado",
      "CustomFilterPlaceHolder": "Digite o valor",
      "CustomFilterDatePlaceHolder": "Escolha uma data",
      "AND": "E",
      "OR": "OU",
      "ShowRowsWhere": "Mostrar linhas onde:",
      "NotStartsWith": "Não começa com",
      "Like": "Como",
      "NotEndsWith": "Não termina com",
      "NotContains": "Não contém",
      "IsNull": "Nula",
      "NotNull": "Não nulo",
      "IsEmpty": "Vazia",
      "IsNotEmpty": "Não está vazio",
      "AddCurrentSelection": "Adicionar seleção atual para filtrar",
      "UnGroupButton": "Clique aqui para desagrupar",
      "AutoFitAll": "Ajustar automaticamente todas as colunas",
      "AutoFit": "Ajustar automaticamente esta coluna",
      "Clear": "Clara",
      "FilterMenuDialogARIA": "Caixa de diálogo do menu de filtro",
      "ExcelFilterDialogARIA": "Caixa de diálogo de filtro do Excel",
      "DialogEditARIA": "Caixa de diálogo Editar",
      "ColumnChooserDialogARIA": "Seletor de coluna",
      "ColumnMenuDialogARIA": "Caixa de diálogo do menu da coluna",
      "CustomFilterDialogARIA": "Caixa de diálogo de filtro personalizado",
      "SortAtoZ": "Ordenar de A a Z",
      "SortZtoA": "Ordenar Z a A",
      "SortByOldest": "Classificar por mais antigo",
      "SortByNewest": "Classificar por mais recente",
      "SortSmallestToLargest": "Classificar do menor para o maior",
      "SortLargestToSmallest": "Classificar do maior para o menor",
      "Sort": "Ordenar",
      "FilterDescription": "Pressione Alt para baixo para abrir o menu de filtro",
      "SortDescription": "Pressione Enter para classificar",
      "ColumnMenuDescription": "Pressione Alt para baixo para abrir o menu de colunas",
      "GroupDescription": "Pressione o espaço Ctrl para agrupar",
      "ColumnHeader": " cabeçalho da coluna ",
      "TemplateCell": " é célula modelo",
      "CommandColumnAria": "é o cabeçalho da coluna da coluna de comando ",
      "DialogEdit": "Editar caixa de diálogo",
      "ClipBoard": "prancheta",
      "GroupButton": "Botão de grupo",
      "UnGroupAria": "botão desagrupar",
      "GroupSeperator": "Separador para as colunas agrupadas",
      "UnGroupIcon": "desagrupar a coluna agrupada ",
      "GroupedSortIcon": "classificar a coluna agrupada ",
      "GroupedDrag": "Arraste a coluna agrupada",
      "GroupCaption": " é célula de legenda de grupo",
      "CheckBoxLabel": "caixa de seleção",
      "Expanded": "Expandida",
      "Collapsed": "Desabou",
      "SelectAllCheckbox": "Caixa de seleção Selecionar tudo",
      "SelectRow": "Selecione a linha"
    },
    "pager": {
      "currentPageInfo": "{0} de {1} páginas",
      "totalItemsInfo": "({0} itens)",
      "firstPageTooltip": "Ir para a primeira página",
      "lastPageTooltip": "Ir para a última página",
      "nextPageTooltip": "Ir para a próxima página",
      "previousPageTooltip": "Ir para a página anterior",
      "nextPagerTooltip": "Ir para os próximos itens do pager",
      "previousPagerTooltip": "Ir para os itens anteriores do pager",
      "pagerDropDown": "itens por página",
      "pagerAllDropDown": "Itens",
      "All": "Todos",
      "totalItemInfo": "({0} item)",
      "Container": "Contêiner de pager",
      "Information": "Informações do pager",
      "ExternalMsg": "Mensagem externa do pager",
      "Page": "Página ",
      "Of": " do ",
      "Pages": " Páginas"
    },
    "kanban": {
      "items": "Itens",
      "min": "Mín.",
      "max": "Máx.",
      "cardsSelected": "Cards Selecionados",
      "addTitle": "Adicionar novo card",
      "editTitle": "Editar detalhes do card",
      "deleteTitle": "Excluir card",
      "deleteContent": "Tem certeza de que deseja excluir este card?",
      "save": "Salvar ",
      "delete": "Excluir",
      "cancel": "Cancelar",
      "yes": "Sim",
      "no": "Não",
      "close": "Fechar",
      "noCard": "Nenhum card para exibir",
      "unassigned": "Não atribuído",
      "cards": "carrinho"
    },
    "dropdowns": {
      "noRecordsTemplate": "Nenhum registro foi encontrado",
      "actionFailureTemplate": "A solicitação falhou",
      "overflowCountTemplate": "+${count} mais ..",
      "selectAllText": "Selecionar tudo",
      "unSelectAllText": "Desmarque todos",
      "totalCountTemplate": "${count} selecionado"
    },
    "drop-down-list": {
      "noRecordsTemplate": "Nenhum registro foi encontrado",
      "actionFailureTemplate": "A solicitação falhou"
    },
    "combo-box": {
      "noRecordsTemplate": "Nenhum registro foi encontrado",
      "actionFailureTemplate": "A solicitação falhou"
    },
    "auto-complete": {
      "noRecordsTemplate": "Nenhum registro foi encontrado",
      "actionFailureTemplate": "A solicitação falhou"
    },
    "multi-select": {
      "noRecordsTemplate": "Nenhum registro foi encontrado",
      "actionFailureTemplate": "A solicitação falhou",
      "overflowCountTemplate": "+${count} mais ..",
      "selectAllText": "Selecionar tudo",
      "unSelectAllText": "Desmarque todos",
      "totalCountTemplate": "${count} selecionado"
    },
    "listbox": {
      "noRecordsTemplate": "Nenhum item selecionado",
      "actionFailureTemplate": "A solicitação falhou",
      "selectAllText": "Selecionar tudo",
      "unSelectAllText": "Desmarque todos",
      "moveUp": "Subir",
      "moveDown": "Mover para baixo",
      "moveTo": "Mover para",
      "moveFrom": "Mover-se a partir de",
      "moveAllTo": "Mover tudo para",
      "moveAllFrom": "Mover tudo de"
    },
    "spreadsheet": {
      "InsertingEmptyValue": "O valor de referência não é válido.",
      "FindValue": "Encontrar valor",
      "ReplaceValue": "Substituir valor",
      "FindReplaceTooltip": "Localizar e substituir",
      "ByRow": "Por Linhas",
      "ByColumn": "por colunas",
      "MatchExactCellElements": "Corresponde a todo o conteúdo da célula",
      "EnterCellAddress": "Insira o endereço do celular",
      "FindAndReplace": "Localizar e substituir",
      "ReplaceAllEnd": " correspondências substituídas por",
      "FindNextBtn": "Encontre o próximo",
      "FindPreviousBtn": "Localizar anterior",
      "ReplaceBtn": "Substituir",
      "ReplaceAllBtn": "Substitua tudo",
      "GotoHeader": "Vá para",
      "Sheet": "Folha",
      "SearchWithin": "Pesquisar dentro",
      "SearchBy": "Procurar por",
      "Reference": "Referência",
      "Workbook": "pasta de trabalho",
      "NoElements": "Não encontramos o que você estava procurando.",
      "FindWhat": "Encontre o que",
      "ReplaceWith": "Substituir com",
      "FileName": "Nome do arquivo",
      "ExtendValidation": "A seleção contém algumas células sem validação de dados. Deseja estender a validação para essas células?",
      "Yes": "Sim",
      "No": "Não",
      "PROPER": "Converte um texto para maiúsculas e minúsculas; primeira letra em maiúscula e demais letras em minúsculas.",
      "Cut": "Corte",
      "Copy": "cópia de",
      "Paste": "Colar",
      "PasteSpecial": "Colar especial",
      "All": "Todos",
      "Values": "valores",
      "Formats": "Formatos",
      "Font": "Fonte",
      "FontSize": "Tamanho da fonte",
      "Bold": "Negrito",
      "Italic": "itálico",
      "Underline": "Sublinhado",
      "Strikethrough": "Tachado",
      "TextColor": "Cor do texto",
      "FillColor": "Cor de preenchimento",
      "HorizontalAlignment": "Alinhamento horizontal",
      "AlignLeft": "Alinhar à esquerda",
      "AlignCenter": "Centro",
      "AlignRight": "Alinhar à Direita",
      "VerticalAlignment": "Alinhamento vertical",
      "AlignTop": "Alinhar Topo",
      "AlignMiddle": "Alinhar meio",
      "AlignBottom": "Alinhar Abaixo",
      "MergeCells": "Mesclar Células",
      "MergeAll": "Mesclar tudo",
      "MergeHorizontally": "Mesclar horizontalmente",
      "MergeVertically": "Mesclar verticalmente",
      "Unmerge": "Unmerge",
      "UnmergeCells": "Descombinar Células",
      "SelectMergeType": "Selecione o tipo de mesclagem",
      "MergeCellsAlert": "Mesclar células preservará apenas o valor mais à esquerda (mais alto). Mesclar mesmo assim?",
      "PasteMergeAlert": "Não podemos fazer isso em uma célula de mesclagem.",
      "Borders": "Fronteiras",
      "TopBorders": "Bordas Superiores",
      "LeftBorders": "Bordas Esquerdas",
      "RightBorders": "Bordas Direitas",
      "BottomBorders": "Bordas Inferiores",
      "AllBorders": "Todas as Fronteiras",
      "HorizontalBorders": "Bordas horizontais",
      "VerticalBorders": "Bordas Verticais",
      "OutsideBorders": "Fora das Fronteiras",
      "InsideBorders": "Bordas internas",
      "NoBorders": "Sem Fronteiras",
      "BorderColor": "Cor da borda",
      "BorderStyle": "Estilo de borda",
      "InsertFunction": "Inserir Função",
      "Insert": "Inserir",
      "Delete": "Excluir",
      "DuplicateSheet": "Duplicado",
      "MoveRight": "Mover para a direita",
      "MoveLeft": "Vire à esquerda",
      "Rename": "Renomear",
      "Hide": "Esconder",
      "NameBox": "Caixa de nome",
      "ShowHeaders": "Mostrar Cabeçalhos",
      "HideHeaders": "Ocultar cabeçalhos",
      "ShowGridLines": "Mostrar linhas de grade",
      "HideGridLines": "Ocultar linhas de grade",
      "FreezePanes": "Congelar Painéis",
      "FreezeRows": "Congelar Linhas",
      "FreezeColumns": "Congelar Colunas",
      "UnfreezePanes": "Descongelar Painéis",
      "UnfreezeRows": "Descongelar Linhas",
      "UnfreezeColumns": "Descongelar Colunas",
      "AddSheet": "Adicionar planilha",
      "ListAllSheets": "Listar todas as planilhas",
      "CollapseToolbar": "Recolher barra de ferramentas",
      "ExpandToolbar": "Expandir barra de ferramentas",
      "CollapseFormulaBar": "Recolher barra de fórmulas",
      "ExpandFormulaBar": "Expandir Barra de Fórmulas",
      "File": "Arquivo",
      "Home": "Home",
      "Formulas": "Fórmulas",
      "View": "Visualizar",
      "New": "Novo",
      "Open": "Abrir",
      "SaveAs": "Salvar como",
      "ExcelXlsx": "Microsoft Excel",
      "ExcelXls": "Microsoft Excel 97-2003",
      "CSV": "Valores Separados Por Virgula",
      "FormulaBar": "Barra de Fórmula",
      "Sort": "Ordenar",
      "SortAscending": "Ascendente",
      "SortDescending": "descendente",
      "CustomSort": "Classificação personalizada",
      "AddColumn": "Adicionar coluna",
      "ContainsHeader": "Os dados contêm cabeçalho",
      "CaseSensitive": "Maiúsculas e minúsculas",
      "SortBy": "Ordenar por",
      "ThenBy": "Então por",
      "SelectAColumn": "Selecione uma coluna",
      "SortEmptyFieldError": "Todos os critérios de classificação devem ter uma coluna especificada. Verifique os critérios de classificação selecionados e tente novamente.",
      "SortDuplicateFieldError": " está sendo classificado por valores mais de uma vez. Exclua os critérios de classificação duplicados e tente novamente.",
      "SortOutOfRangeError": "Selecione uma célula ou intervalo dentro do intervalo usado e tente novamente.",
      "MultiRangeSortError": "Isso não pode ser feito em uma seleção de vários intervalos. Selecione um único intervalo e tente novamente.",
      "HideRow": "Ocultar Linha",
      "HideRows": "Ocultar Linhas",
      "UnhideRows": "Mostrar Linhas",
      "HideColumn": "Ocultar coluna",
      "HideColumns": "Ocultar colunas",
      "UnhideColumns": "Exibir colunas",
      "InsertRow": "Inserir Linha",
      "InsertRows": "Inserir Linhas",
      "Above": "Acima de",
      "Below": "Abaixo de",
      "InsertColumn": "Inserir coluna",
      "InsertColumns": "Inserir colunas",
      "Before": "Antes de",
      "After": "Depois de",
      "DeleteRow": "Excluir linha",
      "DeleteRows": "Excluir linhas",
      "DeleteColumn": "Excluir coluna",
      "DeleteColumns": "Excluir colunas",
      "Ok": "OK",
      "Close": "Fechar",
      "MoreOptions": "Mais opções",
      "Cancel": "Cancelar",
      "Apply": "Aplicar",
      "MoreColors": "Mais cores",
      "StandardColors": "Cores padrão",
      "General": "Em geral",
      "Number": "Número",
      "Currency": "Moeda",
      "Accounting": "Contabilidade",
      "ShortDate": "Encontro curto",
      "LongDate": "data longa",
      "Time": "Tempo",
      "Percentage": "Percentagem",
      "Fraction": "Fração",
      "Scientific": "Científico",
      "Text": "Texto",
      "NumberFormat": "Formato numérico",
      "MobileFormulaBarPlaceHolder": "Digite o valor ou a fórmula",
      "PasteAlert": "Você não pode colar isso aqui, porque a área de cópia e a área de colagem não têm o mesmo tamanho. Tente colar em um intervalo diferente.",
      "DestroyAlert": "Tem certeza de que deseja destruir a pasta de trabalho atual sem salvar e criar uma nova pasta de trabalho?",
      "SheetRenameInvalidAlert": "O nome da planilha contém um caractere inválido.",
      "SheetRenameEmptyAlert": "O nome da planilha não pode estar vazio.",
      "SheetRenameAlreadyExistsAlert": "O nome da planilha já existe. Insira outro nome.",
      "DeleteSheetAlert": "Tem certeza de que deseja excluir esta planilha?",
      "DeleteSingleLastSheetAlert": "Uma pasta de trabalho deve conter pelo menos uma planilha visível.",
      "PickACategory": "Escolha uma categoria",
      "Description": "Descrição",
      "UnsupportedFile": "Arquivo não suportado",
      "DataLimitExceeded": "Os dados do arquivo são muito grandes e levam mais tempo para serem processados. Deseja continuar?",
      "FileSizeLimitExceeded": "O tamanho do arquivo é muito grande e leva mais tempo para processar. Deseja continuar?",
      "InvalidUrl": "URL inválida",
      "SUM": "Adiciona uma série de números e/ou células.",
      "SUMIF": "Adiciona as células com base na condição especificada.",
      "SUMIFS": "Adiciona as células com base nas condições especificadas.",
      "ABS": "Retorna o valor de um número sem seu sinal.",
      "RAND": "Retorna um número aleatório entre 0 e 1.",
      "RANDBETWEEN": "Retorna um número inteiro aleatório com base nos valores especificados.",
      "FLOOR": "Arredonda um número para baixo para o múltiplo mais próximo de um determinado fator.",
      "CEILING": "Arredonda um número para cima para o múltiplo mais próximo de um determinado fator.",
      "PRODUCT": "Multiplica uma série de números e/ou células.",
      "AVERAGE": "Calcula a média para a série de números e/ou células excluindo texto.",
      "AVERAGEIF": "Calcula a média para as células com base no critério especificado.",
      "AVERAGEIFS": "Calcula a média para as células com base nas condições especificadas.",
      "AVERAGEA": "Calcula a média das células avaliando VERDADEIRO como 1, texto e FALSO como 0.",
      "COUNT": "Conta as células que contêm valores numéricos em um intervalo.",
      "COUNTIF": "Conta as células com base na condição especificada.",
      "COUNTIFS": "Conta as células com base em condições especificadas.",
      "COUNTA": "Conta as células que contêm valores em um intervalo.",
      "MIN": "Retorna o menor número de argumentos fornecidos.",
      "MAX": "Retorna o maior número de argumentos fornecidos.",
      "DATE": "Retorna a data com base no ano, mês e dia fornecidos.",
      "DAY": "Retorna o dia a partir da data especificada.",
      "DAYS": "Retorna o número de dias entre duas datas.",
      "IF": "Retorna o valor com base na expressão fornecida.",
      "IFS": "Retorna o valor com base nas várias expressões fornecidas.",
      "CalculateAND": "Retorna TRUE se todos os argumentos forem TRUE, caso contrário, retorna FALSE.",
      "CalculateOR": "Retorna VERDADEIRO se algum dos argumentos for VERDADEIRO, caso contrário, retorna FALSO.",
      "IFERROR": "Retorna o valor se nenhum erro for encontrado, senão retornará o valor especificado.",
      "CHOOSE": "Retorna um valor da lista de valores, com base no número do índice.",
      "INDEX": "Retorna um valor da célula em um determinado intervalo com base no número da linha e da coluna.",
      "FIND": "Retorna a posição de uma string dentro de outra string, que diferencia maiúsculas de minúsculas.",
      "CONCATENATE": "Combina duas ou mais strings juntas.",
      "CONCAT": "Concatena uma lista ou um intervalo de strings de texto.",
      "SUBTOTAL": "Retorna o subtotal de um intervalo usando o número da função fornecido.",
      "RADIANS": "Converte graus em radianos.",
      "MATCH": "Retorna a posição relativa de um valor especificado em determinado intervalo.",
      "SLOPE": "Retorna a inclinação da linha da regressão linear dos pontos de dados.",
      "INTERCEPT": "Calcula o ponto da linha de interceptação Y por meio de regressão linear.",
      "UNIQUE": "Retorna valores exclusivos de um intervalo ou matriz",
      "TEXT": "Converte um valor em texto no formato de número especificado.",
      "DefineNameExists": "Este nome já existe, tente um nome diferente.",
      "CircularReference": "Quando uma fórmula se refere a uma ou mais referências circulares, isso pode resultar em um cálculo incorreto.",
      "SORT": "Classifica um intervalo de uma matriz",
      "T": "Verifica se um valor é texto ou não e retorna o texto.",
      "EXACT": "Verifica se duas strings de texto são exatamente iguais e retorna TRUE ou FALSE.",
      "LEN": "Retorna um número de caracteres em uma determinada string.",
      "MOD": "Retorna um resto depois que um número é dividido pelo divisor.",
      "ODD": "Arredonda um número positivo para cima e um número negativo para baixo para o inteiro ímpar mais próximo.",
      "PI": "Retorna o valor de pi.",
      "COUNTBLANK": "Retorna o número de células vazias em um intervalo de células especificado.",
      "EVEN": "Arredonda um número positivo para cima e um número negativo para baixo para o inteiro par mais próximo.",
      "DECIMAL": "Converte uma representação de texto de um número em uma determinada base em um número decimal.",
      "ADDRESS": "Retorna uma referência de célula como texto, dados os números de linha e coluna especificados.",
      "CHAR": "Retorna o caractere do número especificado.",
      "CODE": "Retorna o código numérico do primeiro caractere em uma determinada string.",
      "DOLLAR": "Converte o número em texto formatado em moeda.",
      "SMALL": "Retorna o k-ésimo menor valor em uma determinada matriz.",
      "LARGE": "Retorna o k-ésimo maior valor em uma determinada matriz.",
      "TIME": "Converte horas, minutos, segundos para o texto formatado de hora.",
      "DEGREES": "Converte radianos em graus.",
      "FACT": "Retorna o fatorial de um número.",
      "MEDIAN": "Retorna a mediana do conjunto de números fornecido.",
      "EDATE": "Retorna uma data com determinado número de meses antes ou depois da data especificada.",
      "DATEVALUE": "Converte uma string de data em valor de data.",
      "NOW": "Retorna a data e hora atuais.",
      "HOUR": "Retorna o número de horas em uma string de tempo especificada.",
      "MINUTE": "Retorna o número de minutos em uma string de tempo especificada.",
      "SECOND": "Retorna o número de segundos em uma string de tempo especificada.",
      "MONTH": "Retorna o número de meses em uma string de data especificada.",
      "OR": "OU",
      "AND": "E",
      "CustomFilterDatePlaceHolder": "Escolha uma data",
      "CustomFilterPlaceHolder": "Digite o valor",
      "CustomFilter": "Filtro customizado",
      "Between": "Entre",
      "MatchCase": "Caso de compatibilidade",
      "DateTimeFilter": "Filtros de data e hora",
      "Undo": "Desfazer",
      "Redo": "refazer",
      "ClearAllFilter": "Claro",
      "ReapplyFilter": "Reaplicar",
      "DateFilter": "Filtros de Data",
      "TextFilter": "Filtros de texto",
      "NumberFilter": "Filtros de número",
      "ClearFilter": "Filtro limpo",
      "NoResult": "Nenhuma equivalência encontrada",
      "FilterFalse": "Falso",
      "FilterTrue": "Verdadeiro",
      "Blanks": "Espaços em branco",
      "SelectAll": "Selecionar tudo",
      "GreaterThanOrEqual": "Maior ou igual",
      "GreaterThan": "Maior que",
      "LessThanOrEqual": "Menor ou igual",
      "LessThan": "Menor que",
      "NotEqual": "Não igual",
      "Equal": "Igual",
      "Contains": "contém",
      "NotContains": "Não contém",
      "EndsWith": "Termina com",
      "NotEndsWith": "Não termina com",
      "StartsWith": "Começa com",
      "NotStartsWith": "Não começa com",
      "IsEmpty": "Vazio",
      "IsNotEmpty": "Não está vazio",
      "ClearButton": "Claro",
      "FilterButton": "Filtro",
      "CancelButton": "Cancelar",
      "OKButton": "OK",
      "Search": "Procurar",
      "DataValidation": "Data de validade",
      "CellRange": "Intervalo de Células",
      "Allow": "Permitir",
      "Data": "Dados",
      "Minimum": "Mínimo",
      "Maximum": "Máximo",
      "IgnoreBlank": "Ignorar em branco",
      "WholeNumber": "Número inteiro",
      "Decimal": "Decimal",
      "Date": "Encontro",
      "TextLength": "Comprimento do Texto",
      "List": "Lista",
      "NotBetween": "Não entre",
      "EqualTo": "Igual a",
      "NotEqualTo": "Diferente de",
      "GreaterThanOrEqualTo": "Melhor que ou igual a",
      "LessThanOrEqualTo": "Menos que ou igual a",
      "InCellDropDown": "Lista suspensa na célula",
      "Sources": "Fontes",
      "Value": "Valor",
      "Retry": "Tentar novamente",
      "DialogError": "A origem da lista deve ser uma referência a uma única linha ou coluna.",
      "MinMaxError": "O Máximo deve ser maior ou igual ao Mínimo.",
      "Spreadsheet": "Planilha",
      "MoreValidation": "Esta seleção contém mais de uma validação.",
      "FileNameError": "Um nome de arquivo não pode conter caracteres como \\ / : * ? \" < > [ ] |",
      "ValidationError": "Este valor não corresponde às restrições de validação de dados definidas para a célula.",
      "ListLengthError": "Os valores da lista permitem apenas até 256 caracteres",
      "ProtectSheet": "Folha de proteção",
      "UnprotectSheet": "Desproteger planilha",
      "SelectCells": "Selecione células bloqueadas",
      "SelectUnlockedCells": "Selecione células desbloqueadas",
      "Save": "Salvar",
      "EmptyFileName": "O nome do arquivo não pode estar vazio.",
      "LargeName": "O nome é muito longo.",
      "FormatCells": "Formatar celulas",
      "FormatRows": "Formatar linhas",
      "FormatColumns": "Formatar colunas",
      "InsertLinks": "Inserir links",
      "ProtectContent": "Proteja o conteúdo das células bloqueadas",
      "ProtectAllowUser": " Permita que todos os usuários desta planilha:",
      "EditAlert": "A célula que você está tentando alterar está protegida. Para fazer a alteração, desproteja a folha.",
      "ReadonlyAlert": "Você está tentando modificar uma célula que está no modo somente leitura. Para fazer alterações, desative o status somente leitura.",
      "ClearValidation": "Limpar validação",
      "ISNUMBER": "Retorna true quando o valor é analisado como um valor numérico.",
      "ROUND": "Arredonda um número para um número especificado de dígitos.",
      "GEOMEAN": "Retorna a média geométrica de uma matriz ou intervalo de dados positivos.",
      "POWER": "Retorna o resultado de um número elevado à potência",
      "LOG": "Retorna o logaritmo de um número para a base que você especificar.",
      "TRUNC": "Retorna o valor truncado de um número para um número especificado de casas decimais.",
      "EXP": "Retorna e elevado à potência do número fornecido.",
      "LOOKUP": "Encontra um valor em um intervalo de linhas ou colunas e retorna um valor no mesmo local em um intervalo de linhas ou colunas.",
      "HLOOKUP": "Encontra um valor na linha superior de uma tabela de valores e retorna um valor na mesma coluna da linha especificada da tabela.",
      "VLOOKUP": "Encontra o valor especificado na primeira coluna do intervalo de pesquisa e retorna o valor correspondente na segunda coluna da linha.",
      "NOT": "Retorna o inverso da expressão lógica especificada.",
      "EOMONTH": "Retorna o último dia do mês antes e depois de um número especificado de meses a partir da primeira data de início especificada.",
      "SQRT": "Retorna a raiz quadrada de um número positivo.",
      "ROUNDDOWN": "Arredonde o número para 0.",
      "RSQ": "Retorna o coeficiente de correlação de momento do produto de Pearson ao quadrado com base nos pontos de dados conhecidos_y e conhecidos_x.",
      "HighlightCellsRules": "Destacar Regras das Células",
      "CFEqualTo": "Igual a",
      "TextThatContains": "Texto que contém",
      "ADateOccuring": "Uma data ocorrendo",
      "DuplicateValues": "Valores duplicados",
      "TopBottomRules": "Regras Superiores/Inferiores",
      "Top10Items": "10 principais itens",
      "Top10": "10 melhores",
      "Bottom10Items": "Últimos 10 itens",
      "Bottom10": "10 inferiores",
      "AboveAverage": "Acima da média",
      "BelowAverage": "Abaixo da média",
      "FormatCellsGreaterThan": "Formatar células que são MAIORES QUE:",
      "FormatCellsLessThan": "Formatar células que são MENOS QUE:",
      "FormatCellsBetween": "Formatar células que estão ENTRE:",
      "FormatCellsEqualTo": "Formatar células IGUAL A:",
      "FormatCellsThatContainTheText": "Formate as células que contêm o texto:",
      "FormatCellsThatContainADateOccurring": "Formate as células que contêm uma data ocorrendo:",
      "FormatCellsDuplicate": "Formate as células que contêm:",
      "FormatCellsTop": "Formate as células classificadas no TOP:",
      "FormatCellsBottom": "Formate as células classificadas na PARTE INFERIOR:",
      "FormatCellsAbove": "Formatar células que estão ACIMA DA MÉDIA:",
      "FormatCellsBelow": "Formatar células que estão ABAIXO DA MÉDIA:",
      "With": "com",
      "DataBars": "Barras de Dados",
      "ColorScales": "Escalas de cores",
      "IconSets": "Conjuntos de ícones",
      "ClearRules": "regras claras",
      "SelectedCells": "Limpar Regras das Células Selecionadas",
      "EntireSheet": "Limpar regras de toda a planilha",
      "LightRedFillWithDarkRedText": "Preenchimento vermelho claro com texto vermelho escuro",
      "YellowFillWithDarkYellowText": "Preenchimento amarelo com texto amarelo escuro",
      "GreenFillWithDarkGreenText": "Preenchimento Verde com Texto Verde Escuro",
      "RedFill": "preenchimento vermelho",
      "RedText": "Texto Vermelho",
      "Duplicate": "Duplicado",
      "Unique": "Único",
      "And": "e",
      "WebPage": "Página da Internet",
      "ThisDocument": "Esse documento",
      "DisplayText": "Texto de Exibição",
      "Url": "URL",
      "CellReference": "Referência de Célula",
      "DefinedNames": "Nomes Definidos",
      "EnterTheTextToDisplay": "Digite o texto a ser exibido",
      "EnterTheUrl": "Digite o URL",
      "INT": "Retorna um número para o inteiro mais próximo.",
      "SUMPRODUCT": "Retorna a soma do produto de determinados intervalos de matrizes.",
      "TODAY": "Retorna a data atual como valor de data.",
      "ROUNDUP": "Arredonda um número a partir de zero.",
      "Link": "Ligação",
      "Hyperlink": "hiperlink",
      "EditHyperlink": "Editar hiperlink",
      "OpenHyperlink": "Abrir hiperlink",
      "RemoveHyperlink": "Remover hiperlink",
      "InvalidHyperlinkAlert": "O endereço deste site não é válido. Verifique o endereço e tente novamente.",
      "InsertLink": "Inserir link",
      "EditLink": "Editar link",
      "WrapText": "Quebrar texto",
      "Update": "Atualizar",
      "SortAndFilter": "Classificar e Filtrar",
      "Filter": "Filtro",
      "FilterCellValue": "Filtrar por Valor da Célula Selecionada",
      "FilterOutOfRangeError": "Selecione uma célula ou intervalo dentro do intervalo usado e tente novamente.",
      "ClearFilterFrom": "Limpar filtro de",
      "LN": "Retorna o logaritmo natural de um número.",
      "DefineNameInValid": "O nome que você digitou não é válido.",
      "EmptyError": "Você deve inserir um valor",
      "ClearHighlight": "Limpar destaque",
      "HighlightInvalidData": "Realçar dados inválidos",
      "Clear": "Claro",
      "ClearContents": "Conteúdo limpo",
      "ClearAll": "Limpar tudo",
      "ClearFormats": "Limpar Formatos",
      "ClearHyperlinks": "Limpar hiperlinks",
      "Image": "Imagem",
      "ConditionalFormatting": "Formatação condicional",
      "BlueDataBar": "Barra de Dados Azul",
      "GreenDataBar": "Barra de Dados Verde",
      "RedDataBar": "Barra de dados vermelha",
      "OrangeDataBar": "Barra de Dados Laranja",
      "LightBlueDataBar": "Barra de dados azul claro",
      "PurpleDataBar": "Barra de Dados Roxa",
      "GYRColorScale": "Escala de cores Verde - Amarelo - Vermelho",
      "RYGColorScale": "Escala de cores vermelho - amarelo - verde",
      "GWRColorScale": "Escala de cores Verde - Branco - Vermelho",
      "RWGColorScale": "Escala de cores vermelho - branco - verde",
      "BWRColorScale": "Escala de cores azul - branco - vermelho",
      "RWBColorScale": "Escala de cores vermelho - branco - azul",
      "WRColorScale": "Branco - Escala de Cores Vermelhas",
      "RWColorScale": "Vermelho - Escala de cores brancas",
      "GWColorScale": "Verde - Escala de cores brancas",
      "WGColorScale": "Branco - escala de cores verde",
      "GYColorScale": "Escala de cores Verde - Amarelo",
      "YGColorScale": "Amarelo - escala de cores verde",
      "ThreeArrowsColor": "3 setas (coloridas)",
      "ThreeArrowsGray": "3 Setas (Cinza)",
      "ThreeTriangles": "3 triângulos",
      "FourArrowsColor": "4 Setas (Cinza)",
      "FourArrowsGray": "4 setas (coloridas)",
      "FiveArrowsColor": "5 Flechas (Cinza)",
      "FiveArrowsGray": "5 setas (coloridas)",
      "ThreeTrafficLights1": "3 semáforos (sem aro)",
      "ThreeTrafficLights2": "3 Semáforos (Aro)",
      "ThreeSigns": "3 sinais",
      "FourTrafficLights": "4 semáforos",
      "RedToBlack": "vermelho para preto",
      "ThreeSymbols1": "3 Símbolos (Circulados)",
      "ThreeSymbols2": "3 símbolos (sem círculo)",
      "ThreeFlags": "3 bandeiras",
      "ThreeStars": "3 estrelas",
      "FourRatings": "4 avaliações",
      "FiveQuarters": "5 trimestres",
      "FiveRatings": "5 avaliações",
      "FiveBoxes": "5 caixas",
      "Chart": "Gráfico",
      "Column": "Coluna",
      "Bar": "Barra",
      "Area": "Área",
      "Pie": "Torta",
      "Doughnut": "Rosquinha",
      "PieAndDoughnut": "Torta/Rosquinha",
      "Line": "Linha",
      "Radar": "Radar",
      "Scatter": "dispersão",
      "ChartDesign": "Design gráfico",
      "ClusteredColumn": "Coluna Agrupada",
      "StackedColumn": "coluna empilhada",
      "StackedColumn100": "100% coluna empilhada",
      "ClusteredBar": "Barra Agrupada",
      "StackedBar": "Barra empilhada",
      "StackedBar100": "Barra empilhada 100%",
      "StackedArea": "Área empilhada",
      "StackedArea100": "Área empilhada 100%",
      "StackedLine": "Linha empilhada",
      "StackedLine100": "Linha empilhada 100%",
      "LineMarker": "Linha com marcadores",
      "StackedLineMarker": "Linha empilhada com marcadores",
      "StackedLine100Marker": "Linha empilhada 100% com marcadores",
      "AddChartElement": "Adicionar Elemento Gráfico",
      "Axes": "Eixos",
      "AxisTitle": "Título do Eixo",
      "ChartTitle": "Título do gráfico",
      "DataLabels": "Rótulos de dados",
      "Gridlines": "Linhas de grade",
      "Legends": "Legendas",
      "PrimaryHorizontal": "Horizontal primária",
      "PrimaryVertical": "Principal Vertical",
      "None": "Nenhum",
      "AboveChart": "Gráfico Acima",
      "Center": "Centro",
      "InsideEnd": "Extremidade Interna",
      "InsideBase": "Base interna",
      "OutsideEnd": "Extremidade Externa",
      "PrimaryMajorHorizontal": "Primário Principal Horizontal",
      "PrimaryMajorVertical": "Principal Principal Vertical",
      "PrimaryMinorHorizontal": "Primária Menor Horizontal",
      "PrimaryMinorVertical": "Principal Vertical Menor",
      "Right": "Direita",
      "Left": "Deixei",
      "Bottom": "Inferior",
      "Top": "Principal",
      "SwitchRowColumn": "Alternar linha/coluna",
      "ChartTheme": "Tema do Gráfico",
      "ChartType": "Tipo de Gráfico",
      "Material": "Material",
      "Fabric": "Tecido",
      "Bootstrap": "Bootstrap",
      "HighContrastLight": "Luz de alto contraste",
      "MaterialDark": "Material Escuro",
      "FabricDark": "Tecido escuro",
      "HighContrast": "AltoContraste",
      "BootstrapDark": "Bootstrap escuro",
      "Bootstrap4": "Bootstrap4",
      "Bootstrap5Dark": "Bootstrap5 escuro",
      "Bootstrap5": "Bootstrap5",
      "Tailwind": "Vento de cauda",
      "TailwindDark": "Tailwind escuro",
      "VerticalAxisTitle": "Título do Eixo Vertical",
      "HorizontalAxisTitle": "Título do Eixo Horizontal",
      "EnterTitle": "Digite o título",
      "UnprotectWorksheet": "Desproteger planilha",
      "ReEnterPassword": "Digite novamente a senha para prosseguir",
      "SheetPassword": "Senha para desproteger a planilha:",
      "ProtectWorkbook": "Proteger pasta de trabalho",
      "Password": "Senha (opcional):",
      "EnterThePassword": "Digite a senha",
      "ConfirmPassword": "Confirme a Senha",
      "EnterTheConfirmPassword": "Digite sua senha novamente",
      "PasswordAlert": "A senha de confirmação não é idêntica",
      "UnprotectWorkbook": "Desproteger pasta de trabalho",
      "UnprotectPasswordAlert": "A senha que você forneceu não está correta.",
      "IncorrectPassword": "Não é possível abrir o arquivo ou planilha com a senha fornecida",
      "PasswordAlertMsg": "Por favor insira a senha",
      "ConfirmPasswordAlertMsg": "Por favor, digite a senha de confirmação",
      "IsProtected": "está protegido",
      "PDF": "Documento PDF",
      "AutoFillMergeAlertMsg": "Para fazer isso, todas as células mescladas precisam ter o mesmo tamanho.",
      "Fluent": "Fluente",
      "FluentDark": "Escuro Fluente",
      "Fluent2": "Fluente 2",
      "Fluent2Dark": "Fluente 2 Escuro",
      "Fluent2HighContrast": "Fluente 2 Alto Contraste",
      "Custom": "Personalizado",
      "WEEKDAY": "Retorna o dia da semana correspondente a uma data.",
      "FillSeries": "Série de Preenchimento",
      "CopyCells": "Copiar Células",
      "FillFormattingOnly": "Preencher apenas formatação",
      "FillWithoutFormatting": "Preencher Sem Formatar",
      "CustomFormat": "Formatos de número personalizados",
      "CustomFormatPlaceholder": "Digite ou selecione um formato personalizado",
      "CustomFormatTypeList": "Tipo",
      "CellReferenceTypoError": "Encontramos um erro de digitação na referência do seu celular. Deseja corrigir esta referência da seguinte forma?",
      "AddCurrentSelection": "Adicionar seleção atual ao filtro",
      "ExternalWorkbook": "Um arquivo Excel importado contém uma referência de pasta de trabalho externa. Deseja importar esse arquivo?",
      "Directional": "Direcional",
      "Shapes": "formas",
      "Indicators": "Indicadores",
      "Ratings": "Avaliações",
      "InvalidFormulaError": "Descobrimos que você digitou uma fórmula inválida.",
      "InvalidArguments": "Descobrimos que você digitou uma fórmula com argumentos inválidos.",
      "EmptyExpression": "Descobrimos que você digitou uma fórmula com uma expressão vazia.",
      "MismatchedParenthesis": "Descobrimos que você digitou uma fórmula com um ou mais parênteses de abertura ou fechamento faltando.",
      "ImproperFormula": "Descobrimos que você digitou uma fórmula inadequada.",
      "WrongNumberOfArguments": "Descobrimos que você digitou uma fórmula com um número errado de argumentos.",
      "Requires3Arguments": "Descobrimos que você digitou uma fórmula que requer 3 argumentos.",
      "MismatchedStringQuotes": "Descobrimos que você digitou uma fórmula com aspas incompatíveis.",
      "FormulaCircularRef": "Descobrimos que você digitou uma fórmula com referência circular."
    },
    "filemanager": {
      "NewFolder": "Nova pasta",
      "Upload": "Enviar",
      "Delete": "Excluir",
      "Rename": "Renomear",
      "Download": "Download",
      "Cut": "Recortar",
      "Copy": "Copiar",
      "Paste": "Colar",
      "SortBy": "Ordenar por",
      "Refresh": "Atualizar",
      "Item-Selection": "item selecionado",
      "Items-Selection": "itens selecionados",
      "View": "Visão",
      "Details": "Detalhes",
      "SelectAll": "Selecionar tudo",
      "Open": "Abrir",
      "Tooltip-NewFolder": "Nova pasta",
      "Tooltip-Upload": "Envio",
      "Tooltip-Delete": "Excluir",
      "Tooltip-Rename": "Renomear",
      "Tooltip-Download": "Baixar",
      "Tooltip-Cut": "Recortar",
      "Tooltip-Copy": "Copiar",
      "Tooltip-Paste": "Colar",
      "Tooltip-SortBy": "Ordenar por",
      "Tooltip-Refresh": "Atualizar",
      "Tooltip-Selection": "Seleção clara",
      "Tooltip-View": "Visão",
      "Tooltip-Details": "Detalhes",
      "Tooltip-SelectAll": "Selecionar tudo",
      "Name": "Nome",
      "Size": "Tamanho",
      "DateModified": "Modificado",
      "DateCreated": "Data Criada",
      "Path": "Caminho",
      "Modified": "Modificado",
      "Created": "Criada",
      "Location": "Localização",
      "Type": "Tipo",
      "Permission": "Permissão",
      "Ascending": "Crescente",
      "Descending": "descendente",
      "None": "Nenhum",
      "View-LargeIcons": "Ícones grandes",
      "View-Details": "Detalhes",
      "Search": "Procurar",
      "Button-Ok": "Está bem",
      "Button-Cancel": "Cancelar",
      "Button-Yes": "sim",
      "Button-No": "Não",
      "Button-Create": "Criar",
      "Button-Save": "Salvar ",
      "Header-NewFolder": "Pasta",
      "Content-NewFolder": "Digite o nome da sua pasta",
      "Header-Rename": "Renomear",
      "Content-Rename": "Digite seu novo nome",
      "Header-Rename-Confirmation": "Renomear confirmação",
      "Content-Rename-Confirmation": "Se você alterar uma extensão de nome de arquivo, o arquivo poderá ficar instável. Tem certeza de que deseja alterá-lo?",
      "Header-Delete": "Excluir arquivo",
      "Content-Delete": "Tem certeza de que deseja excluir este arquivo?",
      "Header-Folder-Delete": "Excluir pasta",
      "Content-Folder-Delete": "Tem certeza de que deseja excluir esta pasta?",
      "Header-Multiple-Delete": "Excluir vários arquivos",
      "Content-Multiple-Delete": "Tem certeza de que deseja excluir esses {0} arquivos?",
      "Header-Duplicate": "O arquivo / pasta existe",
      "Content-Duplicate": "{0} já existe. Deseja renomear e colar?",
      "Header-Upload": "Fazer upload de arquivos",
      "Error": "Erro",
      "Validation-Empty": "O nome do arquivo ou pasta não pode estar vazio.",
      "Validation-Invalid": "O nome do arquivo ou pasta {0} contém caracteres inválidos. Por favor, use um nome diferente. Os nomes válidos de arquivos ou pastas não podem terminar com um ponto ou espaço e não podem conter nenhum dos seguintes caracteres: \\ /: *? \"<> |",
      "Validation-NewFolder-Exists": "Um arquivo ou pasta com o nome {0} já existe.",
      "Validation-Rename-Exists": "Não é possível renomear {0} para {1}: o destino já existe.",
      "Folder-Empty": "Esta pasta está vazia",
      "File-Upload": "Arraste os arquivos aqui para fazer o upload",
      "Search-Empty": "Nenhum resultado encontrado",
      "Search-Key": "Tente com palavras-chave diferentes",
      "Filter-Empty": "Nenhum resultado encontrado",
      "Filter-Key": "Tente com filtro diferente",
      "Sub-Folder-Error": "A pasta de destino é a subpasta da pasta de origem.",
      "Same-Folder-Error": "A pasta de destino é igual à pasta de origem.",
      "Access-Denied": "Acesso negado",
      "Access-Details": "Você não tem permissão para acessar esta pasta.",
      "Header-Retry": "O arquivo já existe",
      "Content-Retry": "Um arquivo com esse nome já existe nesta pasta. O que você gostaria de fazer?",
      "Button-Keep-Both": "Manter os dois",
      "Button-Replace": "Substituir",
      "Button-Skip": "Pular",
      "ApplyAll-Label": "Faça isso para todos os itens atuais",
      "KB": "KB",
      "Access-Message": "{0} não está acessível. Você precisa de permissão para executar a ação {1}.",
      "Network-Error": "Falha ao enviar XMLHTTPRequest: falha ao carregar",
      "Server-Error": "Erro de servidor: resposta inválida de"
    },
    "calendar": {
      "today": "Hoje"
    },
    "datepicker": {
      "today": "Hoje",
      "placeholder": "",
      "day": "dia",
      "month": "mês",
      "year": "ano",

    },
    "daterangepicker": {
      "placeholder": "Escolha um período",
      "startLabel": "Data de início",
      "endLabel": "Data final",
      "applyText": "Aplique",
      "cancelText": "Cancelar",
      "selectedDays": "Dias Selecionados",
      "days": "Dias",
      "customRange": "Gama personalizada"
    },
    "timepicker": {
      "placeholder": "Escolha um horário"
    },
    "datetimepicker": {
      "today": "Hoje",
      "placeholder": "Escolha uma data e hora"
    },
    "dialog": {
      "close": "Fechar"
    },
    "toast": {
      "close": "Fechar"
    },
    "tab": {
      "closeButtonTitle": "Fechar"
    },
    "schedule": {
      "day": "Dia",
      "week": "Semana",
      "workWeek": "Semana de trabalho",
      "month": "Mês",
      "year": "Ano",
      "agenda": "Agenda",
      "weekAgenda": "Agenda da semana",
      "workWeekAgenda": "Agenda da Semana de Trabalho",
      "monthAgenda": "Agenda do mês",
      "today": "Hoje",
      "noEvents": "Sem eventos",
      "emptyContainer": "Não há eventos agendados para este dia.",
      "allDay": "Dia todo",
      "start": "Começar",
      "end": "Fim",
      "more": "Mais",
      "close": "Fechar",
      "cancel": "Cancelar",
      "noTitle": "(Sem título)",
      "delete": "Excluir",
      "deleteEvent": "Este evento",
      "deleteMultipleEvent": "Excluir vários eventos",
      "selectedItems": "Itens selecionados",
      "deleteSeries": "Série inteira",
      "edit": "Editar",
      "editSeries": "Série inteira",
      "editEvent": "Este evento",
      "createEvent": "Criar",
      "subject": "Sujeito",
      "addTitle": "Adicionar título",
      "moreDetails": "Mais detalhes",
      "moreEvents": "Mais eventos",
      "save": "Salvar ",
      "editContent": "Você gostaria de mudar o compromisso da série?",
      "deleteContent": "Tem certeza de que deseja excluir este evento?",
      "deleteMultipleContent": "Tem certeza de que deseja excluir os eventos selecionados?",
      "newEvent": "Novo evento",
      "title": "Título",
      "location": "Localização",
      "description": "Descrição",
      "timezone": "Fuso horário",
      "startTimezone": "Iniciar fuso horário",
      "endTimezone": "Fuso horário final",
      "repeat": "Repetir",
      "saveButton": "Salvar ",
      "cancelButton": "Cancelar",
      "deleteButton": "Excluir",
      "recurrence": "Recorrência",
      "wrongPattern": "O padrão de recorrência não é válido.",
      "seriesChangeAlert": "Deseja cancelar as alterações feitas em instâncias específicas desta série e associá-las à série inteira novamente?",
      "createError": "A duração do evento deve ser menor que a frequência com que ele ocorre. Diminua a duração ou altere o padrão de recorrência no editor de eventos de recorrência.",
      "sameDayAlert": "Duas ocorrências do mesmo evento não podem ocorrer no mesmo dia.",
      "occurenceAlert": "Não é possível reagendar uma ocorrência do compromisso recorrente se ele ignorar uma ocorrência posterior do mesmo compromisso.",
      "editRecurrence": "Editar recorrência",
      "repeats": "Repete",
      "alert": "Alerta",
      "startEndError": "A data final selecionada ocorre antes da data de início.",
      "invalidDateError": "O valor da data inserida é inválido.",
      "blockAlert": "Os eventos não podem ser agendados dentro do intervalo de tempo bloqueado.",
      "ok": "Está bem",
      "yes": "sim",
      "no": "Não",
      "occurrence": "Ocorrência",
      "series": "Series",
      "previous": "Anterior",
      "next": "Próximo",
      "timelineDay": "Dia da linha do tempo",
      "timelineWeek": "Semana da Linha do Tempo",
      "timelineWorkWeek": "Semana de trabalho da linha do tempo",
      "timelineMonth": "Mês da linha do tempo",
      "timelineYear": "Ano da Linha do Tempo",
      "editFollowingEvent": "Eventos seguintes",
      "deleteTitle": "Excluir evento",
      "editTitle": "Editar evento",
      "beginFrom": "Começar de",
      "endAt": "Termina em",
      "expandAllDaySection": "Seção de expansão para o dia todo",
      "collapseAllDaySection": "Recolher a seção do dia inteiro",
      "searchTimezone": "Pesquisar fuso horário",
      "noRecords": "Nenhum registro foi encontrado"
    },
    "recurrenceeditor": {
      "none": "Nenhum",
      "daily": "Diariamente",
      "weekly": "Semanal",
      "monthly": "Por mês",
      "month": "Mês",
      "yearly": "Anual",
      "never": "Nunca",
      "until": "Até",
      "count": "Contagem",
      "first": "Primeiro",
      "second": "Segundo",
      "third": "Terceiro",
      "fourth": "Quarto",
      "last": "Último",
      "repeat": "Repetir",
      "repeatEvery": "Repita cada",
      "on": "Repetir em",
      "end": "Fim",
      "onDay": "Dia",
      "days": "Dias)",
      "weeks": "Semana (s)",
      "months": "Mês (es)",
      "years": "Anos)",
      "every": "cada",
      "summaryTimes": "tempo (s)",
      "summaryOn": "em",
      "summaryUntil": "até",
      "summaryRepeat": "Repete",
      "summaryDay": "dias)",
      "summaryWeek": "semana (s)",
      "summaryMonth": "mês (es)",
      "summaryYear": "anos)",
      "monthWeek": "Mês Semana",
      "monthPosition": "Posição do mês",
      "monthExpander": "Expansor do mês",
      "yearExpander": "Expansor do ano",
      "repeatInterval": "Intervalo de repetição"
    },
    "gantt": {
      "emptyRecord": "Não há registros a serem exibidos",
      "id": "Seq.",
      "name": "Nome",
      "startDate": "Data de início",
      "endDate": "Data final",
      "duration": "Duração",
      "progress": "Progresso",
      "dependency": "Dependência",
      "notes": "Notas",
      "baselineStartDate": "Início manutenção QS",
      "baselineEndDate": "Fim manutenção QS",
      "taskMode": "Modo Tarefa",
      "changeScheduleMode": "Alterar modo de programação",
      "subTasksStartDate": "Data de início das subtarefas",
      "subTasksEndDate": "Data de término das subtarefas",
      "scheduleStartDate": "Data de início da programação",
      "scheduleEndDate": "Data de término da programação",
      "auto": "Automárico",
      "manual": "Manual",
      "type": "Tipo",
      "offset": "Deslocamento",
      "resourceName": "Recursos",
      "resourceID": "ID do Recurso",
      "day": "dia",
      "hour": "hora",
      "minute": "minuto",
      "days": "dias",
      "hours": "horas",
      "minutes": "minutos",
      "generalTab": "Geral",
      "customTab": "Colunas personalizadas",
      "writeNotes": "Escrever notas",
      "addDialogTitle": "Nova tarefa",
      "editDialogTitle": "Informações da tarefa",
      "saveButton": "Salvar ",
      "add": "Adicionar",
      "edit": "Editar",
      "update": "Atualizar",
      "delete": "Excluir",
      "cancel": "Cancelar",
      "search": "Procurar",
      "task": " tarefa",
      "tasks": " tarefas",
      "zoomIn": "Ampliar",
      "zoomOut": "Reduzir",
      "zoomToFit": "Zoom para caber",
      "excelExport": "Exportar Excel",
      "csvExport": "Exportar CSV",
      "expandAll": "Expandir todos",
      "collapseAll": "Recolher todos",
      "nextTimeSpan": "Próximo período",
      "prevTimeSpan": "Período anterior",
      "okText": "OK",
      "confirmDelete": "Tem certeza de que deseja excluir o registro?",
      "from": "A partir de",
      "to": "Para",
      "taskLink": "Link de Tarefa",
      "lag": "atraso",
      "start": "Começar",
      "finish": "Terminar",
      "enterValue": "Digite o valor",
      "taskBeforePredecessor_FS": "Você moveu '{0}' para iniciar antes que '{1}' termine e as duas tarefas estejam vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
      "taskAfterPredecessor_FS": "Você mudou '{0}' de '{1}' e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
      "taskBeforePredecessor_SS": "Você moveu '{0}' para iniciar antes de '{1}' iniciar e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
      "taskAfterPredecessor_SS": "Você moveu '{0}' para iniciar após o início de '{1}' e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
      "taskBeforePredecessor_FF": "Você moveu '{0}' para concluir antes que '{1}' termine e as duas tarefas estejam vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
      "taskAfterPredecessor_FF": "Você moveu '{0}' para concluir depois que '{1}' terminar e as duas tarefas estiverem vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
      "taskBeforePredecessor_SF": "Você mudou '{0}' de '{1}' para iniciar e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
      "taskAfterPredecessor_SF": "Você moveu '{0}' para concluir após o início de '{1}' e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
      "taskInformation": "Informações da tarefa",
      "deleteTask": "Excluir tarefa",
      "deleteDependency": "Excluir dependência",
      "convert": "Converter",
      "save": "Salvar ",
      "above": "Acima",
      "below": "Abaixo",
      "child": "Criança",
      "milestone": "Marco histórico",
      "toTask": "Tarefa",
      "toMilestone": "Para Marco",
      "eventMarkers": "Marcadores de evento",
      "leftTaskLabel": "Rótulo de tarefa esquerdo",
      "rightTaskLabel": "Rótulo de tarefa correto",
      "timelineCell": "Célula da linha do tempo",
      "confirmPredecessorDelete": "Tem certeza de que deseja remover o link de dependência?",
      "unit": "Unidade",
      "work": "Trabalhos",
      "taskType": "Tipo de tarefa",
      "unassignedTask": "Tarefa não atribuída",
      "group": "Grupo",
      "indent": "Recuar",
      "outdent": "Outdent",
      "segments": "Segmentos",
      "splitTask": "Tarefa Dividida",
      "mergeTask": "Mesclar Tarefa",
      "left": "Esquerda",
      "right": "Direito"
    },
    "richtexteditor": {
      "alignments": "Alinhamentos",
      "justifyLeft": "Alinhar à esquerda",
      "justifyCenter": "Alinhar ao centro",
      "justifyRight": "Alinhar à direita",
      "justifyFull": "Alinhar justificar",
      "fontName": "Nome da fonte",
      "fontSize": "Tamanho da fonte",
      "fontColor": "Cor da fonte",
      "backgroundColor": "Cor de fundo",
      "bold": "Negrito",
      "italic": "itálico",
      "underline": "Sublinhado",
      "strikethrough": "Tachado",
      "clearFormat": "Limpar formato",
      "clearAll": "Limpar tudo",
      "cut": "Cortar",
      "copy": "Copiar",
      "paste": "Colar",
      "unorderedList": "Lista com marcadores",
      "orderedList": "Lista numerada",
      "indent": "Aumentar recuo",
      "outdent": "Recuar devagar",
      "undo": "Desfazer",
      "redo": "Refazer",
      "superscript": "Sobrescrito",
      "subscript": "Subscrito",
      "createLink": "Insira o hyperlink",
      "openLink": "Abrir Link",
      "editLink": "Editar Link",
      "removeLink": "Remover link",
      "image": "Inserir Imagem",
      "replace": "Substituir",
      "numberFormatList": "Lista de formatos numéricos",
      "bulletFormatList": "Lista de formatos de marcadores",
      "audioReplace": "Substituir",
      "videoReplace": "Substituir",
      "videoAlign": "Alinhar",
      "videoDimension": "Dimensão",
      "audioRemove": "Remover",
      "videoRemove": "Remover",
      "audioLayoutOption": "Opção de layout",
      "videoLayoutOption": "Opção de layout",
      "align": "Alinhar",
      "caption": "Legenda da imagem",
      "remove": "Retirar",
      "insertLink": "Inserir link",
      "display": "Exibição",
      "altText": "Texto Alternativo",
      "dimension": "Alterar tamanho",
      "fullscreen": "Maximizar",
      "maximize": "Maximizar",
      "minimize": "Minimizar",
      "lowerCase": "Minúsculas",
      "upperCase": "Maiúsculas",
      "print": "Impressão",
      "formats": "Formatos",
      "sourcecode": "Visualização de código",
      "preview": "Pré-visualização",
      "viewside": "Vista Lateral",
      "insertCode": "Insira Código",
      "linkText": "Texto de exibição",
      "linkTooltipLabel": "Título",
      "linkWebUrl": "Endereço da Web",
      "linkTitle": "Digite um título",
      "linkurl": "https://example.com",
      "linkOpenInNewWindow": "Abrir link em nova janela",
      "linkHeader": "Inserir link",
      "dialogInsert": "Inserir",
      "dialogCancel": "Cancelar",
      "dialogUpdate": "Atualizar",
      "imageHeader": "Inserir Imagem",
      "audioHeader": "Inserir áudio",
      "videoHeader": "Inserir vídeo",
      "imageLinkHeader": "Você também pode fornecer um link da web",
      "audioLinkHeader": "Você também pode fornecer um link da web",
      "videoLinkHeader": "URL da Web",
      "embedVideoLinkHeader": "URL de incorporação de mídia",
      "mdimageLink": "Forneça um URL para sua imagem",
      "imageUploadMessage": "Solte a imagem aqui ou navegue para fazer upload",
      "audioUploadMessage": "Solte um arquivo de áudio ou navegue para fazer upload",
      "videoUploadMessage": "Solte um arquivo de vídeo ou navegue para fazer upload",
      "imageDeviceUploadMessage": "Clique aqui para fazer o upload",
      "audioDeviceUploadMessage": "Clique aqui para carregar",
      "videoDeviceUploadMessage": "Clique aqui para carregar",
      "imageAlternateText": "Texto alternativo",
      "alternateHeader": "Texto Alternativo",
      "browse": "Buscar",
      "imageUrl": "https://example.com/image.png",
      "audioUrl": "https://example.com/audio.mp3",
      "videoUrl": "https://example.com/video.mp4",
      "webUrl": "URL da Web",
      "embedUrl": "Código embutido",
      "imageCaption": "Rubrica",
      "imageSizeHeader": "Tamanho da imagem",
      "imageHeight": "Altura",
      "imageWidth": "Largura",
      "videoHeight": "Altura",
      "videoWidth": "Largura",
      "textPlaceholder": "Inserir texto",
      "inserttablebtn": "Insira a tabela",
      "tabledialogHeader": "Insira a tabela",
      "tableWidth": "Largura",
      "cellpadding": "Preenchimento da célula",
      "cellspacing": "Espaçamento celular",
      "columns": "Numero de colunas",
      "rows": "Numero de linhas",
      "tableRows": "Linha",
      "tableColumns": "Coluna",
      "tableCellHorizontalAlign": "Alinhamento horizontal da célula da tabela",
      "tableCellVerticalAlign": "Alinhamento Vertical da Tabela",
      "createTable": "Criar a tabela",
      "removeTable": "Remover tabela",
      "tableHeader": "Linha de cabeçalho",
      "tableRemove": "Excluir tabela",
      "tableCellBackground": "Fundo da célula da tabela",
      "tableEditProperties": "Propriedades de edição de tabela",
      "styles": "Estilos",
      "insertColumnLeft": "Inserir coluna à esquerda",
      "insertColumnRight": "Inserir coluna à direita",
      "deleteColumn": "Excluir coluna",
      "insertRowBefore": "Inserir linha antes",
      "insertRowAfter": "Inserir linha após",
      "deleteRow": "Excluir linha",
      "tableEditHeader": "Editar tabela",
      "TableHeadingText": "Título",
      "TableColText": "Col",
      "imageInsertLinkHeader": "Inserir link",
      "editImageHeader": "Editar imagem",
      "alignmentsDropDownLeft": "Alinhar à esquerda",
      "alignmentsDropDownCenter": "Alinhar ao centro",
      "alignmentsDropDownRight": "Alinhar à direita",
      "alignmentsDropDownJustify": "Alinhar justificar",
      "imageDisplayDropDownInline": "Na linha",
      "imageDisplayDropDownBreak": "Pausa",
      "audioLayoutOptionDropDownInline": "Em linha",
      "audioLayoutOptionDropDownBreak": "Quebrar",
      "videoLayoutOptionDropDownInline": "Em linha",
      "videoLayoutOptionDropDownBreak": "Quebrar",
      "tableInsertRowDropDownBefore": "Inserir linha antes",
      "tableInsertRowDropDownAfter": "Inserir linha após",
      "tableInsertRowDropDownDelete": "Excluir linha",
      "tableInsertColumnDropDownLeft": "Inserir coluna à esquerda",
      "tableInsertColumnDropDownRight": "Inserir coluna à direita",
      "tableInsertColumnDropDownDelete": "Excluir coluna",
      "tableVerticalAlignDropDownTop": "Alinhar parte superior",
      "tableVerticalAlignDropDownMiddle": "Alinhar ao meio",
      "tableVerticalAlignDropDownBottom": "Alinhar parte inferior",
      "tableStylesDropDownDashedBorder": "Fronteiras tracejadas",
      "tableStylesDropDownAlternateRows": "Linhas alternativas",
      "pasteFormat": "Colar formato",
      "pasteFormatContent": "Escolha a ação de formatação",
      "plainText": "Texto simples",
      "cleanFormat": "Limpar \\ limpo",
      "keepFormat": "Manter",
      "pasteDialogOk": "Está bem",
      "pasteDialogCancel": "Cancelar",
      "fileManager": "Gerenciador de arquivos",
      "fileDialogHeader": "Navegador de arquivos",
      "formatsDropDownParagraph": "Parágrafo",
      "formatsDropDownCode": "Código",
      "formatsDropDownQuotation": "cotação",
      "formatsDropDownHeading1": "Cabeçallho 1",
      "formatsDropDownHeading2": "Título 2",
      "formatsDropDownHeading3": "Título 3",
      "formatsDropDownHeading4": "Título 4",
      "fontNameSegoeUI": "SegoeUI",
      "fontNameArial": "Arial",
      "fontNameGeorgia": "Georgia",
      "fontNameImpact": "Impact",
      "fontNameTahoma": "Tahoma",
      "fontNameTimesNewRoman": "Times New Roman",
      "fontNameVerdana": "Verdana",
      "formatsOLListNumber": "Número",
      "formatsOLListLowerAlpha": "LowerAlpha",
      "formatsOLListUpperAlpha": "UpperAlpha",
      "formatsOLListLowerRoman": "Roman inferior",
      "formatsOLListUpperRoman": "UpperRoman",
      "formatsOLListLowerGreek": "Grego Inferior",
      "formatsULListDisc": "Disco",
      "formatsULListCircle": "Círculo",
      "formatsULListSquare": "Quadrado",
      "formatsOLListNone": "Nenhum",
      "formatsULListNone": "Nenhum",
      "formatPainter": "Pincel de Formatação",
      "emojiPicker": "Seletor de emojis",
      "embeddedCode": "Código Incorporado",
      "pasteEmbeddedCodeHere": "Cole o código incorporado aqui",
      "emojiPickerTypeToFind": "Digite para encontrar",
      "emojiPickerNoResultFound": "Nenhum resultado encontrado",
      "emojiPickerTrySomethingElse": "tente outra coisa",
      "linkAriaLabel": "Abrir em nova janela",
      "imageLinkAriaLabel": "Abrir em nova janela",
      "unsupportedImage": "Formato de arquivo não suportado",
      "mergecells": "Mesclar células",
      "verticalsplit": "Divisão vertical",
      "horizontalsplit": "Divisão horizontal",
      "numberFormatListLowerAlpha": "Alfa Inferior",
      "numberFormatListUpperAlpha": "Alfa Superior",
      "numberFormatListLowerRoman": "Romano inferior",
      "numberFormatListUpperRoman": "Romano Superior",
      "numberFormatListLowerGreek": "Grego inferior"
    },
    "colorpicker": {
      "Apply": "Aplicar",
      "Cancel": "Cancelar",
      "ModeSwitcher": "Mudar de modo"
    },
    "uploader": {
      "Browse": "Buscar",
      "Clear": "Limpar",
      "Upload": "Envio",
      "dropFilesHint": "Ou solte arquivos aqui",
      "invalidMaxFileSize": "O tamanho do arquivo é muito grande",
      "invalidMinFileSize": "O tamanho do arquivo é muito pequeno",
      "invalidFileType": "Tipo de arquivo não é permitido",
      "uploadFailedMessage": "Falha no upload do arquivo",
      "uploadSuccessMessage": "Arquivo enviado com sucesso",
      "removedSuccessMessage": "Arquivo removido com sucesso",
      "removedFailedMessage": "Não foi possível remover o arquivo",
      "inProgress": "Enviando",
      "readyToUploadMessage": "Pronto para carregar",
      "abort": "Cancelar",
      "remove": "Remover",
      "cancel": "Cancelar",
      "delete": "Excluir",
      "pauseUpload": "Upload de arquivo pausado",
      "pause": "Pausar",
      "resume": "Continuar",
      "retry": "Repetir",
      "fileUploadCancel": "Upload de arquivo cancelado"
    },
    "numerictextbox": {
      "incrementTitle": "Aumentar",
      "decrementTitle": "Diminuir"
    },
    "slider": {
      "incrementTitle": "Aumentar",
      "decrementTitle": "Diminuir"
    },
    "formValidator": {
      "required": "Este campo é obrigatório.",
      "email": "Por favor insira um endereço de e-mail válido.",
      "url": "Por favor, insira um URL válido.",
      "date": "Por favor insira uma data válida.",
      "dateIso": "Digite uma data válida (ISO).",
      "creditcard": "Digite o número do cartão válido",
      "number": "por favor insira um número válido.",
      "digits": "Digite apenas dígitos.",
      "maxLength": "Por favor, não insira mais que {0} caracteres.",
      "minLength": "Digite pelo menos {0} caracteres.",
      "rangeLength": "Digite um valor entre {0} e {1} caracteres.",
      "range": "Digite um valor entre {0} e {1}.",
      "max": "Digite um valor menor ou igual a {0}.",
      "min": "Por favor, insira um valor maior ou igual a {0}.",
      "regex": "Digite um valor correto.",
      "tel": "Por favor insira um número de telefone válido.",
      "pattern": "Digite um valor de padrão correto.",
      "equalTo": "Digite o texto de correspondência válido"
    },
    "treegrid": {
      "Above": "Acima",
      "Below": "Abaixo",
      "AddRow": "Adicionar linha",
      "ExpandAll": "Expandir todos",
      "CollapseAll": "Recolher todos"
    },
    "querybuilder": {
      "StartsWith": "Começa com",
      "EndsWith": "Termina com",
      "Contains": "Contém",
      "NotLike": "contém",
      "Like": "Não parece",
      "Equal": "Igual",
      "NotEqual": "Não igual",
      "LessThan": "Menor que",
      "LessThanOrEqual": "Menor ou igual",
      "GreaterThan": "Maior que",
      "GreaterThanOrEqual": "Maior que ou igual",
      "Between": "Entre",
      "NotBetween": "Não entre",
      "Empty": "Esvaziar",
      "NotEmpty": "Não está vazio",
      "In": "Dentro",
      "NotIn": "Não em",
      "NotContains": "Não contém",
      "Remove": "RETIRAR",
      "SelectField": "Selecione um campo",
      "SelectOperator": "Selecionar operador",
      "DeleteRule": "Remova esta condição",
      "DeleteGroup": "Excluir grupo",
      "AddGroup": "Adicionar grupo",
      "AddCondition": "Adicionar condição",
      "Edit": "EDITAR",
      "ValidationMessage": "Este campo é obrigatório",
      "SummaryViewTitle": "Vista de Resumo",
      "OtherFields": "Outros campos",
      "AND": "E",
      "OR": "OU",
      "SelectValue": "Digite o valor",
      "IsEmpty": "Está vazia",
      "IsNotEmpty": "Não está vazio",
      "IsNull": "É nulo",
      "IsNotNull": "Não é nulo",
      "True": "verdadeiro",
      "False": "falso",
      "DoesNotStartWith": "Não começa com",
      "DoesNotEndWith": "Não termina com",
      "DoesNotContain": "Não contém",
      "AddButton": "Adicionar grupo/condição",
      "CloneGroup": "Clonar grupo",
      "LockGroup": "Bloquear grupo",
      "CloneRule": "Clonar regra",
      "LockRule": "Regra de bloqueio",
      "UnlockRule": "Regra de desbloqueio",
      "UnlockGroup": "Desbloquear grupo"
    },
    "barcode": {},
    "datamatrix": {},
    "qrcode": {},
    "pivotview": {
      "grandTotal": "Total geral",
      "total": "Total",
      "value": "Valor",
      "noValue": "Sem valor",
      "row": "Linha",
      "column": "Coluna",
      "collapse": "Colapsar",
      "expand": "Expandir",
      "rowAxisPrompt": "Soltar linha aqui",
      "columnAxisPrompt": "Solte a coluna aqui",
      "valueAxisPrompt": "Solte o valor aqui",
      "filterAxisPrompt": "Solte o filtro aqui",
      "filter": "Filtro",
      "filtered": "Filtrado",
      "sort": "Ordenar",
      "filters": "Filtros",
      "rows": "Linhas",
      "columns": "Colunas",
      "values": "Valores",
      "close": "Fechar",
      "cancel": "Cancelar",
      "delete": "Excluir",
      "CalculatedField": "Campo calculado",
      "createCalculatedField": "Criar campo calculado",
      "fieldName": "Digite o nome do campo",
      "error": "Erro",
      "invalidFormula": "Fórmula inválida.",
      "dropText": "Exemplo: ('Sum (Order_Count)' + 'Sum (In_Stock)') * 250",
      "dropTextMobile": "Adicione campos e edite a fórmula aqui.",
      "dropAction": "O campo calculado não pode ser colocado em nenhuma outra região, exceto no eixo do valor.",
      "alert": "Alerta",
      "warning": "Atenção",
      "ok": "Está bem",
      "search": "Buscar",
      "drag": "Arrastar",
      "remove": "Retirar",
      "allFields": "Todos os campos",
      "formula": "Fórmula",
      "addToRow": "Adicionar à linha",
      "addToColumn": "Adicionar à coluna",
      "addToValue": "Adicionar ao valor",
      "addToFilter": "Adicionar ao filtro",
      "emptyData": "Não há registros a serem exibidos",
      "fieldExist": "Um campo já existe com este nome. Digite um nome diferente.",
      "confirmText": "Um campo de cálculo já existe neste nome. Quer substituir?",
      "noMatches": "Sem combinações",
      "format": "Resuma os valores por",
      "edit": "Editar",
      "clear": "Limpar",
      "formulaField": "Arraste e solte células para a fórmula",
      "dragField": "Arraste a célula para a fórmula",
      "clearFilter": "Limpar filtro",
      "by": "por",
      "all": "Todos",
      "multipleItems": "Vários itens",
      "member": "Membro",
      "label": "Rótulo",
      "date": "Encontro",
      "enterValue": "Digite o valor",
      "chooseDate": "Digite a data",
      "Before": "Antes",
      "BeforeOrEqualTo": "Antes ou igual a",
      "After": "Depois de",
      "AfterOrEqualTo": "Depois ou igual a",
      "labelTextContent": "Mostrar os itens para os quais o rótulo",
      "dateTextContent": "Mostrar os itens para os quais a data",
      "valueTextContent": "Mostrar os itens para os quais",
      "Equals": "É igual a",
      "DoesNotEquals": "Não é igual",
      "BeginWith": "Começa com",
      "DoesNotBeginWith": "Não começa com",
      "EndsWith": "Termina com",
      "DoesNotEndsWith": "Não termina com",
      "Contains": "Contém",
      "DoesNotContains": "Não contém",
      "GreaterThan": "Maior que",
      "GreaterThanOrEqualTo": "Maior que ou igual a",
      "LessThan": "Menor que",
      "LessThanOrEqualTo": "Menor que ou igual a",
      "Between": "Entre",
      "NotBetween": "Não entre",
      "And": "e",
      "Sum": "Soma",
      "Count": "Contagem",
      "DistinctCount": "Contagem Distinta",
      "Product": "Produto",
      "Avg": "Média",
      "Min": "Mín.",
      "SampleVar": "Amostra Var",
      "PopulationVar": "População Var",
      "RunningTotals": "Totais em Execução",
      "Max": "Máx.",
      "Index": "Índice",
      "SampleStDev": "Amostra StDev",
      "PopulationStDev": "População StDev",
      "PercentageOfRowTotal": "% do total da linha",
      "PercentageOfParentTotal": "% do total pai",
      "PercentageOfParentColumnTotal": "% do total da coluna pai",
      "PercentageOfParentRowTotal": "% do total da linha pai",
      "DifferenceFrom": "Diferença de",
      "PercentageOfDifferenceFrom": "% de diferença de",
      "PercentageOfGrandTotal": "% do total geral",
      "PercentageOfColumnTotal": "% do total da coluna",
      "NotEquals": "Diferente",
      "AllValues": "Todos os valores",
      "conditionalFormatting": "Formatação condicional",
      "apply": "APLIQUE",
      "condition": "Adicionar condição",
      "formatLabel": "Formato",
      "valueFieldSettings": "Configurações do campo Valor",
      "baseField": "Campo base:",
      "baseItem": "Item base:",
      "summarizeValuesBy": "Resuma valores por:",
      "sourceName": "Nome do campo:",
      "sourceCaption": "Legenda do campo:",
      "example": "por exemplo:",
      "editorDataLimitMsg": " mais itens. Procure refinar ainda mais.",
      "details": "Detalhes",
      "manageRecords": "Gerenciar registros",
      "Years": "Anos",
      "Quarters": "Quartos",
      "Months": "Meses",
      "Days": "Dias",
      "Hours": "Horas",
      "Minutes": "Minutos",
      "Seconds": "Segundos",
      "save": "Salvar um relatório",
      "new": "Crie um novo relatório",
      "load": "Carga",
      "saveAs": "Salvar como relatório atual",
      "rename": "Renomear um relatório atual",
      "deleteReport": "Excluir um relatório atual",
      "export": "Exportação",
      "subTotals": "Subtotais",
      "grandTotals": "Totais gerais",
      "reportName": "Nome do relatório:",
      "pdf": "PDF",
      "excel": "Excel",
      "csv": "CSV",
      "png": "PNG",
      "jpeg": "JPEG",
      "svg": "SVG",
      "mdxQuery": "Consulta MDX",
      "showSubTotals": "Mostrar subtotais",
      "doNotShowSubTotals": "Não mostrar subtotais",
      "showSubTotalsRowsOnly": "Mostrar apenas subtotais de linhas",
      "showSubTotalsColumnsOnly": "Mostrar apenas subtotais de colunas",
      "showGrandTotals": "Mostrar totais gerais",
      "doNotShowGrandTotals": "Não mostrar totais gerais",
      "showGrandTotalsRowsOnly": "Mostrar apenas linhas de totais gerais",
      "showGrandTotalsColumnsOnly": "Mostrar apenas colunas do total geral",
      "fieldList": "Mostrar lista de campos",
      "grid": "Mostrar tabela",
      "toolbarFormatting": "Formatação condicional",
      "chart": "Gráfico",
      "reportMsg": "Digite o nome do relatório vaild !!!",
      "reportList": "Lista de relatórios",
      "removeConfirm": "Tem certeza de que deseja excluir este relatório?",
      "emptyReport": "Nenhum relatório encontrado !!",
      "bar": "Barra",
      "line": "Linha",
      "area": "Área",
      "scatter": "Dispersão",
      "polar": "Polar",
      "of": "do",
      "emptyFormat": "Nenhum formato encontrado !!!",
      "emptyInput": "Digite um valor",
      "newReportConfirm": "Deseja salvar as alterações no relatório?",
      "emptyReportName": "Digite um nome para o relatório",
      "qtr": "trimestre",
      "null": "nulo",
      "undefined": "Indefinido",
      "groupOutOfRange": "Fora de alcance",
      "fieldDropErrorAction": "O campo que você está movendo não pode ser colocado nessa área do relatório",
      "MoreOption": "Mais...",
      "aggregate": "Agregar",
      "drillThrough": "Perfurar",
      "ascending": "Crescente",
      "descending": "Descendente",
      "number": "Número",
      "currency": "Moeda",
      "percentage": "Porcentagem",
      "formatType": "Tipo de formato",
      "customText": "Símbolo Monetário",
      "symbolPosition": "Posição do símbolo",
      "left": "Esquerda",
      "right": "Direita",
      "grouping": "Agrupamento",
      "true": "Verdade",
      "false": "Falso",
      "decimalPlaces": "Casas decimais",
      "numberFormat": "Formatação de números",
      "memberType": "Tipo de campo",
      "formatString": "Formatar sequência",
      "expressionField": "Expressão",
      "customFormat": "Digite a string de formato personalizado",
      "selectedHierarchy": "Hierarquia pai",
      "olapDropText": "Exemplo: [Medidas]. [Quantidade do pedido] + ([Medidas]. [Quantidade do pedido] * 0,10)",
      "Percent": "Porcento",
      "Custom": "personalizadas",
      "Measure": "A medida",
      "Dimension": "Dimensão",
      "Standard": "Padrão",
      "blank": "(Em branco)",
      "fieldTooltip": "Arraste e solte campos para criar uma expressão. E, se você deseja editar os campos calculados existentes! Para isso, basta selecionar o campo em 'Membros calculados'.",
      "QuarterYear": "Trimestre do ano",
      "fieldTitle": "Nome do campo",
      "drillError": "Não é possível mostrar os itens brutos dos campos calculados.",
      "caption": "Legenda do campo",
      "copy": "Copiar",
      "defaultReport": "Relatório padrão",
      "customFormatString": "Formato personalizado",
      "invalidFormat": "Formato Inválido.",
      "group": "Grupo",
      "unGroup": "Desagrupar",
      "invalidSelection": "Não é possível agrupar essa seleção.",
      "groupName": "Digite a legenda para exibir no cabeçalho",
      "captionName": "Digite a legenda para o campo de grupo",
      "selectedItems": "Itens selecionados",
      "groupFieldCaption": "Legenda do campo",
      "groupTitle": "Nome do grupo",
      "startAt": "Começando às",
      "endAt": "Terminando às",
      "groupBy": "Intervalo por",
      "selectGroup": "Selecionar grupos",
      "numberFormatString": "Exemplo: C, P, 0000%, ### 0. ## 0 #, etc.",
      "stackingcolumn": "Coluna empilhada",
      "stackingbar": "Barra empilhada",
      "stackingarea": "Área empilhada",
      "stepline": "Linha da etapa",
      "steparea": "Área da etapa",
      "splinearea": "Área do spline",
      "spline": "Spline",
      "stackingcolumn100": "Coluna empilhada 100%",
      "stackingbar100": "Barra 100% empilhada",
      "stackingarea100": "Área 100% empilhada",
      "bubble": "bolha",
      "pareto": "Pareto",
      "radar": "Radar",
      "chartTypeSettings": "Configurações de tipo de gráfico",
      "multipleAxes": "Múltiplos eixos",
      "sortAscending": "Classificar ordem crescente",
      "sortDescending": "Classificar ordem decrescente",
      "sortNone": "Classificar ordem dos dados",
      "clearCalculatedField": "Limpar informações do campo editado",
      "editCalculatedField": "Editar campo calculado",
      "ChartType": "Tipo de Gráfico",
      "yes": "sim",
      "no": "Não",
      "numberFormatMenu": "Formatação de números ...",
      "conditionalFormattingMenu": "Formatação condicional...",
      "removeCalculatedField": "Tem certeza de que deseja excluir este campo calculado?",
      "replaceConfirmBefore": "Um relatório chamado",
      "replaceConfirmAfter": " já existe. Quer substituir?",
      "pie": "Torta",
      "funnel": "Funil",
      "doughnut": "Rosquinha",
      "pyramid": "Pirâmide",
      "showLegend": "Mostrar legenda",
      "exit": "Saída",
      "invalidJSON": "Dados JSON inválidos",
      "invalidCSV": "Dados CSV inválidos",
      "stacked": "Empilhado",
      "single": "solteiro",
      "multipleAxisMode": "Modo de eixo múltiplo",
      "grandTotalPosition": "Posição de totais gerais",
      "top": "Topo",
      "bottom": "Inferior",
      "None": "Nenhum",
      "stackingline": "Linha empilhada",
      "stackingline100": "100% Linha empilhada",
      "rowPage": "pager de linha",
      "rowPerPage": "Linhas por página",
      "columnPage": "pager de coluna",
      "columnPerPage": "Colunas por página",
      "goToFirstPage": "Ir para a primeira página",
      "goToPreviousPage": "Ir para a página anterior",
      "goToNextPage": "Ir para a próxima página",
      "goToLastPage": "Ir para a última página",
      "combined": "Combinado",
      "subTotalPosition": "posição de subtotais",
      "auto": "Automárico",
      "loading": "Carregando...",
      "add": "Adicionar"
    },
    "pivotfieldlist": {
      "staticFieldList": "Lista de campos dinâmicos",
      "fieldList": "Lista de campos",
      "dropFilterPrompt": "Largue o filtro aqui",
      "dropColPrompt": "Solte a coluna aqui",
      "dropRowPrompt": "Soltar linha aqui",
      "dropValPrompt": "Solte o valor aqui",
      "addPrompt": "Adicionar campo aqui",
      "adaptiveFieldHeader": "Escolha o campo",
      "centerHeader": "Arraste os campos entre os eixos abaixo:",
      "add": "adicionar",
      "drag": "Arrasto",
      "filter": "Filtro",
      "filtered": "Filtrado",
      "sort": "Ordenar",
      "remove": "Retirar",
      "filters": "Filtros",
      "rows": "Linhas",
      "columns": "Colunas",
      "values": "Valores",
      "CalculatedField": "Campo calculado",
      "createCalculatedField": "Criar campo calculado",
      "fieldName": "Digite o nome do campo",
      "error": "Erro",
      "invalidFormula": "Fórmula inválida.",
      "dropText": "Exemplo: ('Sum (Order_Count)' + 'Sum (In_Stock)' ') * 250",
      "dropTextMobile": "Adicione campos e edite a fórmula aqui.",
      "dropAction": "O campo calculado não pode ser colocado em nenhuma outra região, exceto no eixo do valor.",
      "search": "Procurar",
      "close": "Fechar",
      "cancel": "Cancelar",
      "delete": "Excluir",
      "alert": "Alerta",
      "warning": "Atenção",
      "ok": "Está bem",
      "allFields": "Todos os campos",
      "formula": "Fórmula",
      "fieldExist": "Um campo já existe neste nome. Digite um nome diferente.",
      "confirmText": "Um campo de cálculo já existe neste nome. Quer substituir?",
      "noMatches": "Sem combinações",
      "format": "Resume os valores por",
      "edit": "Editar",
      "clear": "Limpar",
      "formulaField": "Arraste e solte campos para a fórmula",
      "dragField": "Arraste o campo para a fórmula",
      "clearFilter": "Limpar filtro",
      "by": "por",
      "enterValue": "Digite o valor",
      "chooseDate": "Digite a data",
      "all": "Todos",
      "multipleItems": "Vários itens",
      "Equals": "É igual a",
      "DoesNotEquals": "Não é igual",
      "BeginWith": "Começa com",
      "DoesNotBeginWith": "Não começa com",
      "EndsWith": "Termina com",
      "DoesNotEndsWith": "Não termina com",
      "Contains": "Contém",
      "DoesNotContains": "Não contém",
      "GreaterThan": "Maior que",
      "GreaterThanOrEqualTo": "Melhor que ou igual a",
      "LessThan": "Menor que",
      "LessThanOrEqualTo": "Menos que ou igual a",
      "Between": "Entre",
      "NotBetween": "Não entre",
      "Before": "Antes",
      "BeforeOrEqualTo": "Antes ou igual a",
      "After": "Depois de",
      "AfterOrEqualTo": "Depois ou igual a",
      "member": "Membro",
      "label": "Rótulo",
      "date": "Encontro",
      "value": "Valor",
      "labelTextContent": "Mostrar os itens para os quais o rótulo",
      "dateTextContent": "Mostrar os itens para os quais a data",
      "valueTextContent": "Mostrar os itens para os quais",
      "And": "e",
      "Sum": "Soma",
      "Count": "Contagem",
      "DistinctCount": "Contagem Distinta",
      "Product": "produtos",
      "Avg": "Média",
      "Min": "Mín.",
      "Max": "Máx.",
      "Index": "Índice",
      "SampleStDev": "Amostra StDev",
      "PopulationStDev": "População StDev",
      "SampleVar": "Amostra Var",
      "PopulationVar": "População Var",
      "RunningTotals": "Totais em Execução",
      "DifferenceFrom": "Diferença de",
      "PercentageOfDifferenceFrom": "% de diferença de",
      "PercentageOfGrandTotal": "% do total geral",
      "PercentageOfColumnTotal": "% do total da coluna",
      "PercentageOfRowTotal": "% do total da linha",
      "PercentageOfParentTotal": "% do total pai",
      "PercentageOfParentColumnTotal": "% do total da coluna pai",
      "PercentageOfParentRowTotal": "% do total da linha pai",
      "Years": "Anos",
      "Quarters": "Quartos",
      "Months": "Meses",
      "Days": "Dias",
      "Hours": "Horas",
      "Minutes": "Minutos",
      "Seconds": "Segundos",
      "apply": "Aplicar",
      "valueFieldSettings": "Configurações do campo Valor",
      "sourceName": "Nome do campo:",
      "sourceCaption": "Legenda do campo:",
      "summarizeValuesBy": "Resuma valores por:",
      "baseField": "Campo base:",
      "baseItem": "Item base:",
      "example": "por exemplo:",
      "editorDataLimitMsg": " mais itens. Procure refinar ainda mais.",
      "deferLayoutUpdate": "Adiar atualização de layout",
      "null": "nulo",
      "undefined": "Indefinido",
      "groupOutOfRange": "Fora de alcance",
      "fieldDropErrorAction": "O campo que você está movendo não pode ser colocado nessa área do relatório",
      "MoreOption": "Mais...",
      "memberType": "Tipo de campo",
      "selectedHierarchy": "Hierarquia selecionada",
      "formatString": "Formatar sequência",
      "expressionField": "Expressão",
      "olapDropText": "Exemplo: [Medidas]. [Quantidade do pedido] + ([Medidas]. [Quantidade do pedido] * 0,10)",
      "customFormat": "Digite a string de formato personalizado",
      "Measure": "A medida",
      "Dimension": "Dimensão",
      "Standard": "Padrão",
      "Currency": "Moeda",
      "Percent": "Porcento",
      "Custom": "personalizadas",
      "blank": "(Em branco)",
      "fieldTooltip": "Arraste e solte campos para criar uma expressão. E, se você deseja editar os campos calculados existentes! Você pode conseguir isso simplesmente selecionando o campo em 'Membros calculados'.",
      "fieldTitle": "Nome do campo",
      "QuarterYear": "Trimestre do ano",
      "caption": "Legenda do campo",
      "copy": "Copiar",
      "group": "Grupo",
      "numberFormatString": "Exemplo: C, P, 0000%, ### 0. ## 0 #, etc.",
      "sortAscending": "Classificar ordem crescente",
      "sortDescending": "Classificar ordem decrescente",
      "sortNone": "Classificar ordem dos dados",
      "clearCalculatedField": "Limpar informações do campo editado",
      "editCalculatedField": "Editar campo calculado",
      "selectGroup": "Selecionar grupos",
      "of": "do",
      "removeCalculatedField": "Tem certeza de que deseja excluir este campo calculado?",
      "yes": "sim",
      "no": "Não",
      "None": "Nenhum"
    },
    "pdfviewer": {
      "PdfViewer": "﻿Visualizador de PDF",
      "Cancel": "Cancelar",
      "Download file": "⇬ Fazer download do arquivo",
      "Download": "Baixar",
      "Enter Password": "Este documento é protegido por senha. Por favor insira uma senha.",
      "File Corrupted": "Arquivo corrompido",
      "File Corrupted Content": "O arquivo está corrompido e não pode ser aberto.",
      "Fit Page": "Ajustar página",
      "Fit Width": "Largura de ajuste",
      "Automatic": "Automático",
      "Go To First Page": "Mostrar primeira página",
      "Invalid Password": "Senha incorreta. Por favor, tente novamente.",
      "Next Page": "Mostrar página seguinte",
      "OK": "Está bem",
      "Open": "Abrir arquivo",
      "Page Number": "Número da página atual",
      "Previous Page": "Mostrar página anterior",
      "Go To Last Page": "Mostrar a última página",
      "Zoom": "Ampliação",
      "Zoom In": "Ampliar",
      "Zoom Out": "Reduzir",
      "Page Thumbnails": "Miniaturas de página",
      "Bookmarks": "Favoritos",
      "Print": "Imprimir arquivo",
      "Password Protected": "Senha requerida",
      "Copy": "Copiar",
      "Text Selection": "Ferramenta de seleção de texto",
      "Panning": "Modo panorâmico",
      "Text Search": "Localizar texto",
      "Find in document": "Localizar no documento",
      "Match case": "Caso de compatibilidade",
      "Apply": "Aplique",
      "GoToPage": "Vá para página",
      "No matches": "O visualizador terminou de pesquisar o documento. Não foram encontradas mais correspondências",
      "No Text Found": "Nenhum texto encontrado",
      "Undo": "Desfazer",
      "Redo": "Refazer",
      "Annotation": "Adicionar ou editar anotações",
      "Highlight": "Realçar texto",
      "Underline": "Sublinhar texto",
      "Strikethrough": "Texto tachado",
      "Delete": "Excluir anotação",
      "Opacity": "Opacidade",
      "Color edit": "Mudar cor",
      "Opacity edit": "Alterar opacidade",
      "Highlight context": "Realçar",
      "Underline context": "Sublinhado",
      "Strikethrough context": "Strike through",
      "Server error": "O serviço da Web não está esRecortarando. O PDF Viewer depende do serviço da Web para todos os seus recursos. Por favor, inicie o serviço web para continuar.",
      "Open text": "Abrir texto",
      "First text": "Primeira página",
      "Previous text": "Página anterior",
      "Next text": "Próxima página",
      "Last text": "Última página",
      "Zoom in text": "Ampliar",
      "Zoom out text": "Reduzir",
      "Selection text": "Seleção",
      "Pan text": "Frigideira",
      "Print text": "Impressão",
      "Search text": "Procurar",
      "Annotation Edit text": "Editar anotação",
      "Line Thickness": "Espessura da linha",
      "Line Properties": "Propriedades da linha",
      "Start Arrow": "Seta Inicial",
      "End Arrow": "Seta final",
      "Line Style": "Estilo da linha",
      "Fill Color": "Cor de preenchimento",
      "Line Color": "Cor da linha",
      "None": "Nenhum",
      "Open Arrow": "Aberto",
      "Closed Arrow": "Fechadas",
      "Round Arrow": "Volta",
      "Square Arrow": "Quadrado",
      "Diamond Arrow": "Diamante",
      "Butt": "bunda",
      "Cut": "Cortar",
      "Paste": "Colar",
      "Delete Context": "Excluir",
      "Properties": "Propriedades",
      "Add Stamp": "Adicionar carimbo",
      "Add Shapes": "Adicionar formas",
      "Stroke edit": "Alterar cor do traçado",
      "Change thickness": "Alterar espessura da borda",
      "Add line": "Adicionar linha",
      "Add arrow": "Adicionar seta",
      "Add rectangle": "Adicionar retângulo",
      "Add circle": "Adicionar círculo",
      "Add polygon": "Adicionar polígono",
      "Add Comments": "Adicione comentários",
      "Comments": "Comentários",
      "No Comments Yet": "Ainda não há comentários",
      "Accepted": "Aceitaram",
      "Completed": "Concluído",
      "Cancelled": "Cancelado",
      "Rejected": "Rejeitado",
      "Leader Length": "Comprimento do Líder",
      "Scale Ratio": "Relação de escala",
      "Calibrate": "Calibrar",
      "Calibrate Distance": "Calibrar Distância",
      "Calibrate Perimeter": "Calibrar perímetro",
      "Calibrate Area": "Calibrar área",
      "Calibrate Radius": "Calibrar raio",
      "Calibrate Volume": "Calibrar volume",
      "highlight": "Alterar opacidade",
      "underline": "Destaque",
      "strikethrough": "Sublinhado",
      "Seach text": "Impressão"
    },
    "diagram": {
      "Copy": "Copiar",
      "Cut": "Cortar",
      "Paste": "Colar",
      "Undo": "Desfazer",
      "Redo": "Refazer",
      "SelectAll": "Selecionar tudo",
      "Grouping": "Agrupamento",
      "Group": "Grupo",
      "UnGroup": "Desagrupar",
      "Order": "Ordem",
      "BringToFront": "Enviar para frente",
      "MoveForward": "Enviar um passo a frente",
      "SendToBack": "Enviar para trás",
      "SendBackward": "Enviar um passo a trás"
    },
    "inplace-editor": {
      "save": "Salvar ",
      "cancel": "Cancelar",
      "loadingText": "Carregando...",
      "editIcon": "Clique para editar",
      "editAreaClick": "Clique para editar",
      "editAreaDoubleClick": "Clique duas vezes para editar"
    },
    "drawing": {},
    "drop-down-base": {
      "noRecordsTemplate": "Nenhum registro foi encontrado",
      "actionFailureTemplate": "A solicitação falhou"
    },
    "chart": {
      "Zoom": "Ampliação",
      "ZoomIn": "Ampliar",
      "ZoomOut": "Reduzir",
      "Reset": "Redefinir",
      "Pan": "Frigideira",
      "ResetZoom": "Redefinir zoom"
    },
    "maps": {
      "Zoom": "Ampliação",
      "ZoomIn": "Ampliar",
      "ZoomOut": "Reduzir",
      "Reset": "Redefinir",
      "Pan": "Frigideira",
      "ResetZoom": "Redefinir zoom",
      "ImageNotFound": "Imagem não encontrada"
    },
    "drop-down-tree": {
      "noRecordsTemplate": "Nenhum registro foi encontrado",
      "actionFailureTemplate": "Pedido falhou",
      "overflowCountTemplate": "+ ${count} mais ..",
      "totalCountTemplate": "${count} selecionado"
    },
    "documenteditor": {
      "Table": "Mesa",
      "Row": "Linha",
      "Cell": "Célula",
      "Ok": "OK",
      "Apply": "lenta e facilmente",
      "Cancel": "Cancelar",
      "Size": "Tamanho",
      "Preferred Width": "Largura preferida",
      "Points": "Pontos",
      "Percent": "Porcento",
      "Measure in": "Medida em",
      "Alignment": "Alinhamento",
      "Left": "Esquerda",
      "Center": "Centro",
      "Right": "Direita",
      "Decimal": "A primeira casa decimal",
      "Bar": "metade",
      "Leader": "Administrador do sistema",
      "Tab": "Faturas pendentes",
      "BookMarkList": "Lista de documentos exibidos.",
      "TabMarkList": "Mostra páginas de parágrafos.",
      "Tab stop position": "espaço em disco",
      "Default tab stops": "Kart fantástico",
      "Tab stops to be cleared": "cartão substituível",
      "Tabs": "janela da guia",
      "Set": "usar",
      "Clear": "telefone",
      "Clear All": "Limpar tudo",
      "Justify": "Justificar",
      "Indent from left": "Recuar da esquerda",
      "Borders and Shading": "Bordas e sombreamento",
      "Options": "Opções",
      "Specify height": "Especifique a altura",
      "At least": "Finalmente",
      "Exactly": "Exatamente",
      "Row height is": "A altura da linha é",
      "Allow row to break across pages": "Permitir quebra de linha nas páginas",
      "Repeat as header row at the top of each page": "Repita como linha de cabeçalho na parte superior de cada página",
      "Vertical alignment": "Alinhamento vertical",
      "Top": "Topo",
      "Bottom": "Inferior",
      "Default cell margins": "Margens de célula padrão",
      "Default cell spacing": "Espaçamento de célula padrão",
      "Allow spacing between cells": "Permitir espaçamento entre células",
      "Cell margins": "Margens da célula",
      "Same as the whole table": "Igual à tabela inteira",
      "Borders": "Fronteiras",
      "None": "Nenhum",
      "Style": "Estilo",
      "Width": "Largura",
      "Height": "Altura",
      "Letter": "Carta",
      "Tabloid": "Tablóide",
      "Legal": "Jurídico",
      "Statement": "Declaração",
      "Executive": "Executivo",
      "A3": "A3",
      "A4": "A4",
      "A5": "A5",
      "B4": "B4",
      "B5": "B5",
      "Custom Size": "Tamanho personalizado",
      "Different odd and even": "Diferente e ímpar",
      "Different first page": "Primeira página diferente",
      "From edge": "De borda",
      "Header": "Cabeçalho",
      "Footer": "Rodapé",
      "First Page Header": "Cabeçalho da primeira página",
      "First Page Footer": "Rodapé da primeira página",
      "Even Page Header": "Cabeçalho de página uniforme",
      "Even Page Footer": "Rodapé de página par",
      "Odd Page Header": "Cabeçalho de página ímpar",
      "Odd Page Footer": "Rodapé de página ímpar",
      "Same as Previous": "Igual ao anterior",
      "Section": "Seção",
      "Margin": "Margens",
      "Paper": "Papel",
      "Layout": "Disposição",
      "Orientation": "Orientação",
      "Landscape": "Paisagem",
      "Portrait": "Retrato",
      "Show page numbers": "Mostrar números de página",
      "Right align page numbers": "Alinhar à direita os números das páginas",
      "Nothing": "Nada",
      "Tab leader": "Líder da guia",
      "Show levels": "Mostrar níveis",
      "Use hyperlinks instead of page numbers": "Use hiperlinks em vez de números de página",
      "Build table of contents from": "Crie um índice a partir de",
      "Styles": "Estilos",
      "Available styles": "Estilos disponíveis",
      "TOC level": "Nível TOC",
      "Heading": "Cabeçalho",
      "Heading 1": "Cabeçallho 1",
      "Heading 2": "Título 2",
      "Heading 3": "Título 3",
      "Heading 4": "Título 4",
      "Heading 5": "Título 5",
      "Heading 6": "Título 6",
      "List Paragraph": "Parágrafo da lista",
      "Normal": "Normal",
      "Outline levels": "Níveis de estrutura de tópicos",
      "Table entry fields": "Campos de entrada da tabela",
      "Modify": "Modificar",
      "Color": "Cor",
      "Setting": "Configuração",
      "Box": "Caixa",
      "All": "Todos",
      "Custom": "personalizadas",
      "Preview": "Pré-visualização",
      "Shading": "Sombreamento",
      "Fill": "Preencher",
      "Apply To": "Aplicar a",
      "Table Properties": "Propriedades da tabela",
      "Cell Options": "Opções de célula",
      "Table Options": "Opções de tabela",
      "Insert Table": "Insira a tabela",
      "Number of columns": "Numero de colunas",
      "Number of rows": "Numero de linhas",
      "Text to display": "Texto a ser exibido",
      "ScreenTip text": "Texto da dica de tela",
      "Address": "Endereço",
      "Insert Hyperlink": "Insira o hyperlink",
      "Edit Hyperlink": "Editar hiperlink",
      "Insert": "Inserir",
      "General": "Geral",
      "Indentation": "Indentação",
      "Before text": "Antes do texto",
      "Special": "Especial",
      "First line": "Primeira linha",
      "Hanging": "Suspensão",
      "After text": "Após o texto",
      "By": "Por",
      "Before": "Antes",
      "Line Spacing": "Espaçamento entre linhas",
      "After": "Depois de",
      "At": "No",
      "Multiple": "Múltiplo",
      "Spacing": "Espaçamento",
      "Define new Multilevel list": "Definir nova lista multinível",
      "List level": "Nível da lista",
      "Choose level to modify": "Escolha o nível para modificar",
      "Level": "Nível",
      "Number format": "Formato numérico",
      "Number style for this level": "Estilo numérico para este nível",
      "Enter formatting for number": "Digite a formatação para o número",
      "Start at": "Começa ás",
      "Restart list after": "Reinicie a lista depois",
      "Position": "Posição",
      "Text indent at": "Recuo do texto em",
      "Aligned at": "Alinhado em",
      "Follow number with": "Siga o número com",
      "Tab character": "Caractere de tabulação",
      "Space": "Espaço",
      "Arabic": "árabe",
      "UpRoman": "UpRoman",
      "LowRoman": "baixo-romano",
      "UpLetter": "UpLetter",
      "LowLetter": "LowLetter",
      "Number": "Número",
      "Leading zero": "Zero inicial",
      "Bullet": "Bala",
      "Ordinal": "Ordinal",
      "Ordinal Text": "Texto Ordinal",
      "For East": "Para o leste",
      "No Restart": "Não reiniciar",
      "Font": "Fonte",
      "Font style": "Estilo de fonte",
      "Underline style": "Estilo sublinhado",
      "Font color": "Cor da fonte",
      "Effects": "Efeitos",
      "Strikethrough": "Tachado",
      "Superscript": "Sobrescrito",
      "Subscript": "Subscrito",
      "Double strikethrough": "Tachado duplo",
      "Regular": "Regular",
      "Bold": "Negrito",
      "Italic": "itálico",
      "Cut": "Recortar",
      "Copy": "Copiar",
      "Paste": "Colar",
      "Hyperlink": "Hiperlink",
      "Open Hyperlink": "Abrir hiperlink",
      "Copy Hyperlink": "Copiar hiperlink",
      "Remove Hyperlink": "Remover hiperlink",
      "Paragraph": "Parágrafo",
      "Linked Style": "Vinculado (parágrafo e caractere)",
      "Character": "Personagem",
      "Merge Cells": "Mesclar células",
      "Insert Above": "Inserir acima",
      "Insert Below": "Inserir abaixo",
      "Insert Left": "Inserir à esquerda",
      "Insert Right": "Inserir à direita",
      "Delete": "Excluir",
      "Delete Table": "Excluir tabela",
      "Delete Row": "Excluir linha",
      "Delete Column": "Excluir coluna",
      "File Name": "Nome do arquivo",
      "Format Type": "Tipo de formato",
      "Save": "Salvar ",
      "Navigation": "Navegação",
      "Results": "Resultados",
      "Replace": "Substituir",
      "Replace All": "Substitua tudo",
      "We replaced all": "Nós substituímos todos",
      "Find": "Encontrar",
      "No matches": "Sem combinações",
      "All Done": "Tudo feito",
      "Result": "Resultado",
      "of": "do",
      "instances": "instâncias",
      "with": "com",
      "Click to follow link": "Clique para seguir o link",
      "Continue Numbering": "Continuar numeração",
      "Bookmark name": "Nome do marcador",
      "Close": "Fechar",
      "Restart At": "Reiniciar em",
      "Properties": "Propriedades",
      "Name": "Nome",
      "Style type": "Tipo de estilo",
      "Style based on": "Estilo baseado em",
      "Style for following paragraph": "Estilo para o parágrafo a seguir",
      "Formatting": "Formatação",
      "Numbering and Bullets": "Numeração e marcadores",
      "Numbering": "Numeração",
      "Update Field": "Atualizar campo",
      "Edit Field": "Editar campo",
      "Bookmark": "marca páginas",
      "Page Setup": "Configurações da página",
      "No bookmarks found": "Nenhum marcador encontrado",
      "Number format tooltip information": "Formato numérico de nível único: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Por exemplo, 'Capítulo% 1'. exibirá numeração como </br> Capítulo 1. Item </br> Capítulo 2. Item </br>… </br> Capítulo N. Item </br> </br> Formato de número multinível: </br> [ PREFIXO]% [NÚMERO DE NÍVEL] [SUFIXO] + [PREFIXO]% [NÚMERO DE NÍVEL] [SUFIXO] </br> Por exemplo, '% 1.% 2.' exibirá numeração como </br> 1.1. Item </br> 1.2. Item </br>… </br> 1.N. Item",
      "Format": "Formato",
      "Create New Style": "Criar novo estilo",
      "Modify Style": "Modificar estilo",
      "New": "Novo",
      "Bullets": "Balas",
      "Use bookmarks": "Usar marcadores",
      "Table of Contents": "Índice",
      "AutoFit": "Ajuste automático",
      "AutoFit to Contents": "Ajustar automaticamente ao conteúdo",
      "AutoFit to Window": "Ajustar automaticamente à janela",
      "Fixed Column Width": "Largura fixa da coluna",
      "Reset": "Redefinir",
      "Match case": "Caso de compatibilidade",
      "Whole words": "Palavras inteiras",
      "Add": "Adicionar",
      "Go To": "Vamos para",
      "Search for": "Procurar por",
      "Replace with": "Substituir com",
      "TOC 1": "Índice 1",
      "TOC 2": "Índice 2",
      "TOC 3": "Índice 3",
      "TOC 4": "Índice 4",
      "TOC 5": "Índice 5",
      "TOC 6": "Índice 6",
      "TOC 7": "Índice 7",
      "TOC 8": "Índice 8",
      "TOC 9": "Índice 9",
      "Right-to-left": "Direita para esquerda",
      "Left-to-right": "Esquerda para a direita",
      "Direction": "Direção",
      "Table direction": "Direção da mesa",
      "Indent from right": "Recuar da direita",
      "Contextual Spacing": "Não adicione espaço entre os parágrafos dos mesmos estilos",
      "Password Mismatch": "A senha não corresponde",
      "Restrict Editing": "Restringir edição",
      "Formatting restrictions": "Restrições de formatação",
      "Allow formatting": "Permitir formatação",
      "Editing restrictions": "Restrições de edição",
      "Read only": "Somente leitura",
      "Exceptions Optional": "Exceções (opcional)",
      "Select Part Of Document And User": "Selecione partes do documento e escolha usuários com permissão para editá-los livremente.",
      "Everyone": "Todos",
      "More users": "Mais usuários",
      "Add Users": "Adicionar usuários",
      "Enforcing Protection": "Sim, comece a impor proteção",
      "Start Enforcing Protection": "Comece a impor proteção",
      "Enter User": "Digite o usuário",
      "Users": "Comercial",
      "Enter new password": "Insira a nova senha",
      "Reenter new password to confirm": "Digite novamente a nova senha para confirmar",
      "Your permissions": "Suas permissões",
      "Protected Document": "Este documento está protegido contra edição não intencional. Você pode editar nesta região.",
      "FormFieldsOnly": "Você só pode preencher formulários nesta região.",
      "CommentsOnly": "Você só pode inserir comentários nesta região.",
      "ReadOnlyProtection": "Você pode editar nesta região.",
      "Stop Protection": "Pare a proteção",
      "Password": "Senha",
      "Spelling Editor": "Editor de ortografia",
      "Spelling": "Soletração",
      "Spell Check": "Verificação Ortográfica",
      "Underline errors": "Sublinhar erros",
      "Ignore": "Ignorar",
      "Ignore All": "Ignore tudo",
      "Add to Dictionary": "Adicionar ao dicionário",
      "Change": "mudança",
      "Change All": "Mude tudo",
      "Suggestions": "Sugestões",
      "The password is incorrect": "A senha está incorreta",
      "Error in establishing connection with web server": "Erro ao estabelecer conexão com o servidor web",
      "Highlight the regions I can edit": "Destaque as regiões que posso editar",
      "Show All Regions I Can Edit": "Mostrar todas as regiões que posso editar",
      "Find Next Region I Can Edit": "Encontre a próxima região que posso editar",
      "Keep source formatting": "Mantenha a formatação de origem",
      "Match destination formatting": "Corresponder à formatação do destino",
      "Text only": "Somente texto",
      "Comments": "Comentários",
      "Type your comment": "Digite seu comentário",
      "Post": "Postar",
      "Reply": "Resposta",
      "New Comment": "Novo comentário",
      "Edit": "Editar",
      "Resolve": "Resolver",
      "Reopen": "Reabrir",
      "No comments in this document": "Nenhum comentário neste documento",
      "more": "Mais",
      "Type your comment here": "digite seu comentário aqui",
      "Next Comment": "Próximo Comentário",
      "Previous Comment": "Comentário anterior",
      "Un-posted comments": "Comentários não publicados",
      "Discard Comment": "Comentários adicionados não publicados. Se você continuar, esse comentário será descartado.",
      "Discard": "Descartar",
      "No Headings": "Nenhum título encontrado!",
      "Add Headings": "Este documento não possui cabeçalhos. Adicione títulos e tente novamente.",
      "More Options": "Mais opções",
      "Click to see this comment": "Clique para ver este comentário",
      "Drop Down Form Field": "Campo suspenso do formulário",
      "Drop-down items": "Itens suspensos",
      "Items in drop-down list": "Itens na lista suspensa",
      "ADD": "ADICIONAR",
      "REMOVE": "RETIRAR",
      "Field settings": "Configurações de campo",
      "Tooltip": "Sugestão",
      "Drop-down enabled": "Drop-down ativado",
      "Check Box Form Field": "Campo de formulário da caixa de seleção",
      "Check box size": "Tamanho da caixa de seleção",
      "Auto": "Auto",
      "Default value": "Valor padrão",
      "Not checked": "Não checado",
      "Checked": "Verificado",
      "Check box enabled": "Caixa de seleção ativada",
      "Text Form Field": "Campo de formulário de texto",
      "Type": "Tipo",
      "Default text": "Texto padrão",
      "Maximum length": "Comprimento máximo",
      "Text format": "Formato de texto",
      "Fill-in enabled": "Preenchimento ativado",
      "Default number": "Número padrão",
      "Default date": "Data padrão",
      "Date format": "Formato de data",
      "Merge Track": "Esta ação não será marcada como alteração. Você quer continuar?",
      "UnTrack": "Não pode ser rastreado",
      "Accept": "Aceitar",
      "Reject": "Rejeitar",
      "Previous Changes": "Alterações anteriores",
      "Next Changes": "Próximas alterações",
      "Inserted": "Inserido",
      "Deleted": "Excluído",
      "Move From": "C",
      "Move To": "Por isso",
      "Changes": "Alterar",
      "Accept all": "Aceitar tudo",
      "Reject all": "Rejeitar tudo",
      "No changes": "Sem alterações",
      "Accept Changes": "Aceitar alterações",
      "Reject Changes": "Rejeitar alterações",
      "User": "Do utilizador",
      "View": "Visão",
      "Insertion": "Inserção",
      "Deletion": "Eliminação",
      "All caps": "Tudo em maiúsculas",
      "This region is locked by": "Esta região está bloqueada por",
      "Lock": "Fechadura",
      "Unlock": "Desbloquear",
      "Already locked": "Selecionada ou parte da região já está bloqueada por outro usuário",
      "1, 2, 3, ...": "1, 2, 3, ...",
      "a, b, c, ...": "a, b, c, ...",
      "A, B, C, ...": "A, B, C, ...",
      "I, II, III, ...": "I, II, III, ...",
      "i, ii, iii, ...": "i, ii, iii, ...",
      "Text Form": "Formulário de Texto",
      "Check Box": "Caixa de Seleção",
      "DropDown": "Suspenso",
      "Form Fields": "Campos do Formulário",
      "Fillin enabled": "Preenchimento habilitado",
      "Multiple Comment": "Por favor, poste seu comentário",
      "InsertFootnote": "Inserir nota de rodapé",
      "InsertEndnote": "Inserir nota final",
      "Footnote": "Nota de rodapé",
      "Endnote": "Nota final",
      "Notes Options": "Verifique as opções",
      "Click to View/Edit Footnote": "Clique para ver / editar nota de rodapé",
      "Click to View/Edit Endnote": "Clique para ver / editar nota final",
      "InsertAsRows": "Inserir como novas linhas",
      "InsertAsColumns": "Inserir como novas colunas",
      "OverwriteCells": "Substituir células",
      "NestTable": "Mesa Nest",
      "No suggestions": "Nenhuma sugestão",
      "More Suggestion": "Mais Sugestão",
      "Ignore Once": "Ignore uma vez",
      "Keep With Next": "Manter com o próximo",
      "Keep Lines Together": "Mantenha as linhas juntas",
      "WidowControl": "Controle de viúvas/órfãs",
      "Indents and Spacing": "Recuos e espaçamento",
      "Line and Page Breaks": "Quebras de linha e página",
      "Pagination": "Paginação",
      "Single": "Solteiro",
      "DashSmallGap": "DashSmallGap",
      "DashDot": "traço ponto",
      "Double": "Dobro",
      "ThinThickSmallGap": "FinoGrossoPequenoLacuna",
      "ThickThinSmallGap": "ThickThin SmallGap",
      "ThickThinMediumGap": "GrossoFinoMédioLacuna",
      "ThickThinLargeGap": "GrossoFinoGrande Lacuna",
      "SingleWavy": "ÚnicoOndulado",
      "DoubleWavy": "DoubleWavy",
      "Inset": "Inserir",
      "DashLargeGap": "DashLargeGap",
      "Dot": "Ponto",
      "DashDotDot": "DashDotDot",
      "Triple": "Triplo",
      "ThinThickThinSmallGap": "FinoGrosso FinoPequeno Lacuna",
      "ThinThickThinMediumGap": "FinoGrosso FinoMédio Lacuna",
      "ThinThickThinLargeGap": "FinoGrosso FinoGrande Lacuna",
      "DashDotStroked": "TraçoDotStroked",
      "Engrave3D": "Gravar 3D",
      "Thick": "Espesso",
      "Outset": "Início",
      "Emboss3D": "Relevo 3D",
      "ThinThickLargeGap": "FinoGrossoGrandeLacuna",
      "ThinThickMediumGap": "FinoGrossoMédioGap",
      "Number of rows must be between": "O número de linhas deve estar entre",
      "Number of columns must be between": "O número de colunas deve estar entre",
      "and": "e",
      "Unlimited": "Ilimitado",
      "Regular text": "Texto normal",
      "Date": "Encontro",
      "Uppercase": "Maiúsculas",
      "Lowercase": "Minúsculas",
      "FirstCapital": "Primeira Capital",
      "TitleCase": "Título",
      "Filling in forms": "Preenchimento de formulários",
      "px": "px",
      "Tracked changes": "Mudanças rastreadas",
      "TrackChangesOnly": "Você pode editar nesta região, mas todas as alterações serão rastreadas",
      "RemovedIgnore": "Deseja remover as exceções ignoradas?",
      "RemovedIgnoreExceptions": "Se você fizer essa alteração na proteção do documento, o Word ignorará todas as exceções neste documento.",
      "Information": "Em formação",
      "Yes": "Sim",
      "No": "Não",
      "Page Break": "Quebra de página",
      "Column Break": "Quebra de coluna",
      "Section Break Next Page": "Quebra de Seção (Próxima Página)",
      "Section Break Continuous": "Section Break (Next Page)",
      "Unsupported format": "O formato de arquivo que você selecionou não é compatível. Por favor, escolha um formato válido.",
      "One": "The file format you have selected isn't supported. Please choose valid format.",
      "Two": "1",
      "Three": "Dois",
      "Presets": "Three",
      "Columns": "Presets",
      "Split your text into two or more columns": "Columns",
      "Line between column": "Split your text into two or more columns",
      "Width and Spacing": "Line between column",
      "Equal column width": "Width and Spacing",
      "Column": "Equal column width",
      "Paste Content Dialog": "Devido à política de segurança do navegador, colar da área de transferência do sistema é restrito. Como alternativa, use o atalho de teclado",
      "Paste Content CheckBox": "Não apareça novamente.",
      "The top/bottom margins are too large for the page height in some sections.": "As margens superior/inferior são muito grandes para a altura da página em algumas seções.",
      "Column width cannot be less than 36 pt.": "A largura da coluna não pode ser inferior a 36 pt.",
      "Left and right margins.": "As configurações escolhidas para as margens esquerda e direita, espaçamento de colunas ou recuos de parágrafo são muito grandes para a largura da página nas mesmas seções.",
      "The address of this site is 'not valid. Check the address and try again.": "O endereço deste site não é válido. Verifique o endereço e tente novamente.",
      "Alt Text": "Texto alternativo",
      "Title": "Título",
      "Description": "Descrição",
      "Left Indent": "campo de comunicação",
      "Right Indent": "curso correto",
      "Hanging Indent": "todo mundo tem um papel",
      "First Line Indent": "A primeira é a flexão da linha.",
      "Left Margin": "Yu Zuo",
      "Right Margin": "bom Bom",
      "Top Margin": "sensor",
      "Bottom Margin": "breve",
      "Left Tab": "viver ucho",
      "Right Tab": "orelha direita",
      "Center Tab": "indicador central",
      "Decimal Tab": "abate de animais",
      "Bar Tab": "Komil B",
      "Move Table Column": "Movendo colunas em uma tabela",
      "external": "Remova o controle de conteúdo",
      "Content Control Properties": "Propriedades de controle de conteúdo",
      "Remove content control when contents are edited": "Remova o controle de conteúdo quando o conteúdo for editado",
      "Content control cannot be deleted": "O controle de conteúdo não pode ser excluído",
      "Contents cannot be edited": "O conteúdo não pode ser editado",
      "Plain Text properties": "Propriedades de texto simples",
      "Allow carriage returns": "Permitir retornos de carruagem",
      "Drop_Down List properties": "Propriedades da lista Drop_down",
      "Display Text": "Exibir texto",
      "Locking": "Bloqueio",
      "Tag": "Marcação",
      "Value": "Valor",
      "PictureContentControl": "PictureContentControl",
      "Upload from computer": "Fazer upload de computador",
      "Picture": "Foto",
      "Choose an item": "Escolha um item",
      "Paste CheckBox Content Dialog": "Não mostrar novamente",
      "The address of this site is not valid. Check the address and try again.": "O endereço deste site não é válido. ",
      "Left and right margins": "As configurações escolhidas para as margens esquerda e direita, espaçamento de colunas ou recuos de parágrafo são muito grandes para a largura da página nas mesmas seções.",
      "Number of rows must be between 1 and 32767.": "O número de linhas deve estar entre 1 e 32.767.",
      "Number of columns must be between 1 and 63.": "O número de colunas deve estar entre 1 e 63.",
      "You may format text only with certain styles": "Você pode formatar texto apenas com determinados estilos.",
      "Ignore all": "Ignore tudo"
    },
    "documenteditorcontainer": {
      "New": "Novo",
      "Open": "Abrir",
      "Undo": "Desfazer",
      "Redo": "Refazer",
      "Image": "Imagem",
      "Table": "Mesa",
      "Link": "Ligação",
      "Bookmark": "marca páginas",
      "Table of Contents": "Índice",
      "HEADING - - - - 1": "CABEÇALLHO - - - - 1",
      "HEADING - - - - 2": "CABEÇALLHO - - - - 2",
      "HEADING - - - - 3": "CABEÇALLHO - - - - 3",
      "Header": "Cabeçalho",
      "Footer": "Rodapé",
      "Page Setup": "Configurações da página",
      "Page Number": "Número de página",
      "Break": "Pausa",
      "Find": "Encontrar",
      "Local Clipboard": "Área de transferência local",
      "Restrict Editing": "Restringir edição",
      "Content Control": "Controle de conteúdo",
      "Rich Text Content Control": "Controle de conteúdo de texto rico",
      "Plain Text Content Control": "Controle de conteúdo de texto simples",
      "Picture Content Control": "Controle de conteúdo de imagem",
      "Combo Box Content Control": "Controle de conteúdo da caixa de combinação",
      "Drop-Down List Content Control": "Lista suspensa Controle de conteúdo",
      "Date Picker Content Control": "Controle de conteúdo para seleção de data",
      "Check Box Content Control": "Controle de conteúdo da caixa de seleção",
      "Upload from computer": "Fazer upload do computador",
      "By URL": "Por URL",
      "Page Break": "Quebra de página",
      "Section Break": "Quebra de seção",
      "Header And Footer": "Cabeçalho rodapé",
      "Options": "Opções",
      "Levels": "Níveis",
      "Different First Page": "Primeira página diferente",
      "Different header and footer for odd and even pages": "Cabeçalho e rodapé diferentes para páginas ímpares e pares.",
      "Different Odd And Even Pages": "Páginas ímpares e pares diferentes",
      "Different header and footer for first page": "Cabeçalho e rodapé diferentes para a primeira página.",
      "Position": "Posição",
      "Header from Top": "Cabeçalho de cima",
      "Footer from Bottom": "Rodapé de baixo",
      "Distance from top of the page to top of the header": "Distância da parte superior da página até a parte superior do cabeçalho.",
      "Distance from bottom of the page to bottom of the footer": "Distância da parte inferior da página até a parte inferior do rodapé.",
      "Aspect ratio": "Proporção da tela",
      "W": "C",
      "H": "H",
      "Width": "Largura",
      "Height": "Altura",
      "Text": "Texto",
      "Paragraph": "Parágrafo",
      "Fill": "Preenchimento",
      "Fill color": "Cor de preenchimento",
      "Border Style": "Estilo de borda",
      "Outside borders": "Bordas externas",
      "All borders": "Todas as bordas",
      "Inside borders": "Bordas internas",
      "Left border": "Borda esquerda",
      "Inside vertical border": "Borda vertical interna",
      "Right border": "Borda direita",
      "Top border": "Borda superior",
      "Inside horizontal border": "Borda horizontal interna",
      "Bottom border": "Borda inferior",
      "Border color": "Cor da borda",
      "Border width": "Largura da borda",
      "Cell": "Célula",
      "Merge cells": "Mesclar células",
      "Insert Or Delete": "Inserir / Excluir",
      "Insert columns to the left": "Inserir colunas à esquerda",
      "Insert columns to the right": "Inserir colunas à direita",
      "Insert rows above": "Inserir linhas acima",
      "Insert rows below": "Inserir linhas abaixo",
      "Delete rows": "Excluir linhas",
      "Delete columns": "Excluir colunas",
      "Cell Margin": "Margem celular",
      "Top": "Topo",
      "Bottom": "Inferior",
      "Left": "Esquerda",
      "Right": "Direita",
      "Align Text": "Alinhar texto",
      "Align top": "Alinhar parte superior",
      "Align bottom": "Alinhar parte inferior",
      "Align center": "Alinhar ao centro",
      "Number of heading or outline levels to be shown in table of contents": "Número de níveis de cabeçalho ou estrutura de tópicos a serem mostrados no índice.",
      "Show page numbers": "Mostrar números de página",
      "Show page numbers in table of contents": "Mostrar números de página no índice.",
      "Right align page numbers": "Alinhar à direita os números das páginas",
      "Right align page numbers in table of contents": "Alinhe à direita os números das páginas no sumário.",
      "Use hyperlinks": "Use hiperlinks",
      "Use hyperlinks instead of page numbers": "Use hiperlinks em vez de números de página.",
      "Font": "Fonte",
      "Font Size": "Tamanho da fonte",
      "Font color": "Cor da fonte",
      "Text highlight color": "Cor do realce do texto",
      "Clear all formatting": "Limpar toda a formatação",
      "Bold Tooltip": "Negrito (Ctrl + B)",
      "Italic Tooltip": "Itálico (Ctrl + I)",
      "Underline Tooltip": "Sublinhado (Ctrl + U)",
      "Strikethrough": "Tachado",
      "Superscript Tooltip": "Sobrescrito (Ctrl + Shift ++)",
      "Subscript Tooltip": "Subscrito (Ctrl + =)",
      "Align left Tooltip": "Alinhar à esquerda (Ctrl + L)",
      "Center Tooltip": "Centralizar (Ctrl + E)",
      "Align right Tooltip": "Alinhar à direita (Ctrl + R)",
      "Justify Tooltip": "Justificar (Ctrl + J)",
      "Decrease indent": "Diminuir Recuo",
      "Increase indent": "Aumentar recuo",
      "Line spacing": "Espaçamento entre linhas",
      "Bullets": "Marcadores",
      "Numbering": "Numeração",
      "Styles": "Estilos",
      "Manage Styles": "Gerenciar estilos",
      "Page": "Página",
      "of": "de",
      "Fit one page": "Ajustar uma página",
      "Spell Check": "Verificação Ortográfica",
      "Spelling": "Nick",
      "Underline errors": "Sublinhar erros",
      "Fit page width": "Ajustar largura da página",
      "Update": "Atualizar",
      "Cancel": "Cancelar",
      "Insert": "Inserir",
      "No Border": "Sem borda",
      "Create a new document": "Crie um novo documento.",
      "Open a document": "Abra um documento.",
      "Undo Tooltip": "Desfazer a última operação (Ctrl + Z).",
      "Redo Tooltip": "Refaça a última operação (Ctrl + Y).",
      "Insert inline picture from a file": "Inserir imagem embutida de um arquivo.",
      "Insert a table into the document": "Inserir uma tabela no documento",
      "Create Hyperlink": "Crie um link no seu documento para acesso rápido a páginas e arquivos da Web (Ctrl + K).",
      "Insert a bookmark in a specific place in this document": "Insira um marcador em um local específico neste documento.",
      "Provide an overview of your document by adding a table of contents": "Forneça uma visão geral do seu documento adicionando um índice.",
      "Add or edit the header": "Adicione ou edite o cabeçalho.",
      "Add or edit the footer": "Adicione ou edite o rodapé.",
      "Open the page setup dialog": "Abra a caixa de diálogo de configuração da página.",
      "Add page numbers": "Adicione números de página.",
      "Find Text": "Encontre o texto no documento (Ctrl + F).",
      "Toggle between the internal clipboard and system clipboard": "Alterne entre a área de transferência interna e a área de transferência do sistema. </br> O acesso à área de transferência do sistema por meio de script é negado devido à política de segurança dos navegadores. </br> 1. Você pode ativar a área de transferência interna para Recortar, copiar e colar dentro do componente. 2. Você pode usar os atalhos do teclado (Ctrl + X, Ctrl + C e Ctrl + V) para Recortar , copie e cole com a área de transferência do sistema.",
      "Current Page Number": "O número da página atual no documento. Clique ou toque para navegar em uma página específica.",
      "Read only": "Somente leitura",
      "Protections": "Proteções",
      "Error in establishing connection with web server": "Erro ao estabelecer conexão com o servidor web",
      "Single": "Único",
      "Double": "Duplo",
      "New comment": "Novo comentário",
      "Comments": "Comentários",
      "Print layout": "Layout de impressão",
      "Web layout": "Layout da Web",
      "Text Form": "Formulário de texto",
      "Check Box": "Caixa de verificação",
      "DropDown": "Suspenso",
      "Update Fields": "Campos de atualização",
      "Update cross reference fields": "Atualizar campos de referência cruzada",
      "Hide properties pane": "Ocultar painel de propriedades",
      "Show properties pane": "Mostrar painel de propriedades",
      "Form Fields": "Campos do Formulário",
      "Track Changes": "Acompanhe as alterações feitas no documento",
      "TrackChanges": "Alterações de percurso",
      "AllCaps": "Tudo em maiúsculas",
      "Change case Tooltip": "Mudar maiúsculas e minúsculas",
      "Insert Footnote": "Inserir nota de rodapé",
      "Insert Endnote": "Inserir nota final",
      "Footnote Tooltip": "Inserir nota de rodapé (Alt + Ctrl + F).",
      "Endnote Tooltip": "Inserir nota final (Alt + Ctrl + D).",
      "UPPERCASE": "MAIÚSCULAS",
      "No color": "Sem cor",
      "Top margin": "Margem superior",
      "Bottom margin": "Margem inferior",
      "Left margin": "Margem esquerda",
      "Right margin": "Margem direita",
      "Normal": "Normal",
      "Heading": "Cabeçalho",
      "Heading 1": "Cabeçallho 1",
      "Heading 2": "Título 2",
      "Heading 3": "Título 3",
      "Heading 4": "Título 4",
      "Heading 5": "Título 5",
      "Heading 6": "Título 6",
      "ZoomLevelTooltip": "Nível de zoom. Clique ou toque para abrir as opções de Zoom.",
      "None": "Nenhum",
      "Borders": "Fronteiras",
      "ShowHiddenMarks Tooltip": "Mostre os caracteres ocultos como espaços, tabulação, marcas de parágrafo e quebras.(Ctrl + *)",
      "Columns": "Colunas",
      "Column Break": "Quebra de coluna",
      "Link to Previous": "ligação anterior",
      "Link to PreviousTooltip": "Vincule esta seção ao cabeçalho ou rodapé da seção anterior",
      "Alternate Text": "Texto alternativo",
      "The address of this site is not valid. Check the address and try again.": "O endereço deste site não é válido. ",
      "OK": "OK",
      "Information": "Informação",
      "Page Breaks": "no final da página",
      "Section Breaks": "Esse setor está invadindo",
      "Column": "Compartilhar isso",
      "Next Page": "próxima página",
      "Continuous": "Trabalhar"
    },
    "carousel": {
      "nextSlide": "Próximo slide",
      "of": "de",
      "pauseSlideTransition": "Pausar transição de slides",
      "playSlideTransition": "Reproduzir transição de slides",
      "previousSlide": "Slide anterior",
      "slide": "Deslizar",
      "slideShow": "Apresentação de slides"
    },
    "message": {
      "close": "Perto"
    },
    "image-editor": {
      "0": "0%",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "20": "20%",
      "40": "40%",
      "60": "60%",
      "80": "80%",
      "100": "100%",
      "Crop": "Cortar",
      "CropAndTransform": "Cortar e transformar",
      "ZoomIn": "Mais Zoom",
      "ZoomOut": "Reduzir o zoom",
      "Undo": "Desfazer",
      "Redo": "Refazer",
      "Transform": "Transformar",
      "Annotation": "Anotação",
      "Finetune": "Afinar",
      "Brightness": "Brilho",
      "Contrast": "Contraste",
      "Hue": "Matiz",
      "Saturation": "Saturação",
      "Opacity": "Opacidade",
      "Blur": "Borrão",
      "Sharpen": "Afiado",
      "Exposure": "Exposição",
      "Filter": "Filtro",
      "Default": "Padrão",
      "Chrome": "cromada",
      "Cold": "Frio",
      "Warm": "Esquentar",
      "Grayscale": "Tons de cinza",
      "BlackAndWhite": "Preto e branco",
      "Sepia": "Sépia",
      "Invert": "Invertido",
      "Text": "Adicione texto",
      "Pen": "Caneta",
      "Reset": "Reiniciar",
      "Save": "Salvar",
      "Select": "Selecione",
      "RotateLeft": "Vire à esquerda",
      "RotateRight": "Vire à direita",
      "HorizontalFlip": "Inversão Horizontal",
      "VerticalFlip": "Inversão vertical",
      "OK": "OK",
      "Cancel": "Cancelar",
      "DlgOK": "OK",
      "FillColor": "Cor de preenchimento",
      "StrokeColor": "Cor do traço",
      "StrokeWidth": "Largura do traçado",
      "FontFamily": "Família de fontes",
      "FontStyle": "Estilo de fonte",
      "FontSize": "Tamanho da fonte",
      "FontColor": "Cor da fonte",
      "Pan": "Frigideira",
      "Move": "Mover",
      "Load": "Carregar",
      "Custom": "Personalizado",
      "Square": "Quadrado",
      "Circle": "Círculo",
      "Ellipse": "Elipse",
      "Rectangle": "Retângulo",
      "Line": "Linha",
      "Arrow": "Seta",
      "Path": "Caminho",
      "Bold": "Audacioso",
      "Italic": "itálico",
      "BoldItalic": "Negrito itálico",
      "XSmall": "Extra Pequeno",
      "Small": "Pequeno",
      "Medium": "Médio",
      "Large": "Grande",
      "XLarge": "extra grande",
      "ABC": "abc",
      "Browse": "Navegar",
      "Duplicate": "Duplicado",
      "Remove": "Remover",
      "EditText": "Editar texto",
      "Start": "Começar",
      "End": "Fim",
      "Bar": "Bar",
      "ArrowSolid": "Seta Sólida",
      "CircleSolid": "Círculo Sólido",
      "SquareSolid": "Quadrado Sólido",
      "None": "Nenhum",
      "CropSelection": "Seleção de corte",
      "Image": "Adicionar imagem",
      "Transparency": "Transparência",
      "Height": "Altura",
      "Width": "Largura",
      "AspectRatio": "Manter a proporção",
      "W": "C",
      "H": "H",
      "DragText": "Arraste e solte sua imagem aqui ou",
      "DropText": "Deixe sua imagem aqui ou",
      "BrowseText": "Navegue aqui...",
      "SupportText": "Apoia:",
      "Frame": "Quadro",
      "Mat": "Esteira",
      "Bevel": "Bisel",
      "Inset": "Inserir",
      "Hook": "Gancho",
      "Color": "Cor",
      "Size": "Tamanho",
      "Offset": "Desvio",
      "Radius": "Raio",
      "Amount": "Quantia",
      "Resize": "Redimensionar",
      "Border": "Fronteira",
      "Solid": "Sólido",
      "Dashed": "Tracejadas",
      "Dotted": "Pontilhado",
      "GradientColor": "Cor gradiente",
      "ConfirmDialogHeader": "Confirmar salvar alterações",
      "ConfirmDialogContent": "Deseja salvar as alterações feitas na imagem?",
      "AlertDialogHeader": "Arquivo não suportado",
      "AlertDialogContent": "O arquivo descartado não é compatível.",
      "Yes": "Sim",
      "No": "Não",
      "ImageErrorDialogHeader": "Erro de seleção de imagem",
      "ImageErrorDialogContent": "Basta selecionar a imagem que deseja abrir.",
      "Straighten": "cumprindo o prazo",
      "NoOutline": "Não há disposição.",
      "Clone": "metáfora",
      "Delete": "destruir",
      "SaveAs": "Salvar como",
      "ImageName": "Nome da imagem",
      "Format": "Formatar",
      "Quality": "Qualidade",
      "Download": "Download",
      "Close": "Fechar",
      "ImageSize": "Tamanho da imagem",
      "QualityInfo": "A opção de qualidade da imagem está disponível apenas para formato JPEG",
      "Good": "Bom",
      "Great": "Ótimo",
      "Highest": "Altíssima",
      "BringForward": "Apresentar",
      "SendBackward": "Enviar para trás",
      "SendToBack": "Enviar para trás",
      "BringToFront": "Traga para frente",
      "ZOrder": "Ordem Z"
    },
    "mention": {
      "noRecordsTemplate": "Nenhum registro foi encontrado"
    },
    "inplaceeditor": {
      "save": "Salvar",
      "cancel": "Cancelar",
      "loadingText": "Carregando...",
      "editIcon": "Clique para editar",
      "editAreaClick": "Clique para editar",
      "editAreaDoubleClick": "Clique duas vezes para editar"
    },
    "PdfViewer": {
      "PdfViewer": "Visualizador de PDF",
      "Cancel": "Cancelar",
      "Download file": "⇬ Fazer download do arquivo",
      "Download": "Download",
      "Enter Password": "Este documento é protegido por senha. ",
      "File Corrupted": "Arquivo corrompido",
      "File Corrupted Content": "O arquivo está corrompido e não pode ser aberto.",
      "Fit Page": "Ajustar página",
      "Fit Width": "Ajustar largura",
      "Automatic": "Automático",
      "Go To First Page": "Mostrar primeira página",
      "Invalid Password": "Senha incorreta. ",
      "Next Page": "Mostrar próxima página",
      "OK": "OK",
      "Open": "Abrir arquivo",
      "Page Number": "Número da página atual",
      "Previous Page": "Mostrar página anterior",
      "Go To Last Page": "Mostrar última página",
      "Zoom": "Ampliação",
      "Zoom In": "Mais Zoom",
      "Zoom Out": "Reduzir o zoom",
      "Page Thumbnails": "Miniaturas de páginas",
      "Bookmarks": "Favoritos",
      "Print": "Imprimir arquivo",
      "Organize Pages": "Organizar páginas",
      "Insert Right": "Insira à direita",
      "Insert Left": "Insira à esquerda",
      "Total": "Total",
      "Pages": "Páginas",
      "Rotate Right": "Vire à direita",
      "Rotate Left": "Vire à esquerda",
      "Delete Page": "Excluir página",
      "Delete Pages": "Excluir páginas",
      "Copy Page": "Copiar página",
      "Copy Pages": "Copiar páginas",
      "Save": "Salvar",
      "Save As": "Salvar como",
      "Select All": "Selecionar tudo",
      "Password Protected": "Senha requerida",
      "Copy": "cópia de",
      "Text Selection": "Ferramenta de seleção de texto",
      "Panning": "Modo panorâmico",
      "Text Search": "Encontrar texto",
      "Find in document": "Encontrar no documento",
      "Match case": "Caso de compatibilidade",
      "Apply": "Aplicar",
      "GoToPage": "Vá para página",
      "No matches": "O visualizador concluiu a pesquisa no documento. ",
      "No Text Found": "Nenhum texto encontrado",
      "Undo": "Desfazer",
      "Redo": "Refazer",
      "Annotation": "Adicionar ou editar anotações",
      "FormDesigner": "Adicionar e editar campos de forma",
      "Highlight": "Destacar texto",
      "Underline": "Sublinhar texto",
      "Strikethrough": "Texto tachado",
      "Delete": "Excluir anotação",
      "Opacity": "Opacidade",
      "Color edit": "Mudar cor",
      "Opacity edit": "Alterar opacidade",
      "Highlight context": "Destaque",
      "Underline context": "Sublinhado",
      "Strikethrough context": "Atacar",
      "Server error": "O serviço da Web não está ouvindo. ",
      "Client error": "Erro do lado do cliente é encontrado. Verifique os cabeçalhos personalizados fornecidos na propriedade AjaxRequesttings e métodos de ação na Web na propriedade ServerActionSettings.",
      "Open text": "Abrir",
      "First text": "Primeira página",
      "Previous text": "Página anterior",
      "Next text": "Próxima página",
      "Last text": "Última página",
      "Zoom in text": "Mais Zoom",
      "Zoom out text": "Reduzir o zoom",
      "Selection text": "Seleção",
      "Pan text": "Frigideira",
      "Print text": "Imprimir",
      "Search text": "Procurar",
      "Annotation Edit text": "Editar anotação",
      "FormDesigner Edit text": "Adicionar e editar campos de forma",
      "Line Thickness": "Espessura da linha",
      "Line Properties": "Propriedades da linha",
      "Start Arrow": "Seta inicial",
      "End Arrow": "Seta final",
      "Line Style": "Estilo de linha",
      "Fill Color": "Cor de preenchimento",
      "Line Color": "Cor da linha",
      "None": "Nenhum",
      "Open Arrow": "Abrir",
      "Closed Arrow": "Fechado",
      "Round Arrow": "Redondo",
      "Square Arrow": "Quadrado",
      "Diamond Arrow": "Diamante",
      "Butt": "Bunda",
      "Cut": "Corte",
      "Paste": "Colar",
      "Delete Context": "Excluir",
      "Properties": "Propriedades",
      "Add Stamp": "Adicionar carimbo",
      "Add Shapes": "Adicionar formas",
      "Stroke edit": "Alterar cor do traço",
      "Change thickness": "Alterar espessura da borda",
      "Add line": "Adicionar linha",
      "Add arrow": "Adicionar seta",
      "Add rectangle": "Adicionar retângulo",
      "Add circle": "Adicionar círculo",
      "Add polygon": "Adicionar polígono",
      "Add Comments": "Adicione comentários",
      "Comments": "Comentários",
      "SubmitForm": "Enviar formulário",
      "No Comments Yet": "Ainda sem comentários",
      "Accepted": "Aceitaram",
      "Completed": "Concluído",
      "Cancelled": "Cancelado",
      "Rejected": "Rejeitado",
      "Leader Length": "Comprimento do líder",
      "Scale Ratio": "Razão de escala",
      "Calibrate": "Calibrar",
      "Calibrate Distance": "Calibrar distância",
      "Calibrate Perimeter": "Calibrar perímetro",
      "Calibrate Area": "Calibrar área",
      "Calibrate Radius": "Calibrar Raio",
      "Calibrate Volume": "Calibrar volume",
      "Depth": "Profundidade",
      "Closed": "Fechado",
      "Round": "Redondo",
      "Square": "Quadrado",
      "Diamond": "Diamante",
      "Edit": "Editar",
      "Comment": "Comente",
      "Comment Panel": "Painel de comentários",
      "Set Status": "Definir status",
      "Post": "Publicar",
      "Page": "Página",
      "Add a comment": "Adicione um comentário",
      "Add a reply": "Adicione uma resposta",
      "Import Annotations": "Importar anotações do arquivo JSON",
      "Export Annotations": "Exportar anotação para o arquivo JSON",
      "Export XFDF": "Exportar anotação para o arquivo XFDF",
      "Import XFDF": "Importar anotações do arquivo XFDF",
      "Add": "Adicionar",
      "Clear": "Claro",
      "Bold": "Audacioso",
      "Italic": "itálico",
      "Strikethroughs": "Strikethrough",
      "Underlines": "Sublinhado",
      "Superscript": "Sobrescrito",
      "Subscript": "Subscrito",
      "Align left": "Alinhar à esquerda",
      "Align right": "Alinhar à direita",
      "Center": "Centro",
      "Justify": "Justificar",
      "Font color": "Cor da fonte",
      "Text Align": "Alinhamento de texto",
      "Text Properties": "Estilo de fonte",
      "SignatureFieldDialogHeaderText": "Adicione a assinatura",
      "HandwrittenSignatureDialogHeaderText": "Adicione a assinatura",
      "InitialFieldDialogHeaderText": "Adicione inicial",
      "HandwrittenInitialDialogHeaderText": "Adicione inicial",
      "Draw Ink": "Desenhe tinta",
      "Create": "Criar",
      "Font family": "Família de fontes",
      "Font size": "Tamanho da fonte",
      "Free Text": "Texto livre",
      "Import Failed": "Tipo de arquivo JSON inválido ou nome de arquivo; Selecione um arquivo JSON válido",
      "Import PDF Failed": "Tipo de arquivo PDF inválido ou arquivo PDF não encontrado. Selecione um arquivo PDF válido",
      "File not found": "O arquivo JSON importado não é encontrado no local desejado",
      "Export Failed": "A ação das anotações de exportação falhou; Verifique se as anotações são adicionadas corretamente",
      "of": "de",
      "Dynamic": "Dinâmico",
      "Standard Business": "Negócios padrão",
      "Sign Here": "Assine aqui",
      "Custom Stamp": "Carimbo personalizado",
      "Enter Signature as Name": "Digite seu nome",
      "Draw-hand Signature": "EMPATE",
      "Type Signature": "TIPO",
      "Upload Signature": "CARREGAR",
      "Browse Signature Image": "NAVEGAR",
      "Save Signature": "Salvar assinatura",
      "Save Initial": "Salvar inicial",
      "Textbox": "Caixa de texto",
      "Password": "Senha",
      "Check Box": "Caixa de seleção",
      "Radio Button": "Botao de radio",
      "Dropdown": "Suspenso",
      "List Box": "Caixa de listagem",
      "Signature": "Assinatura",
      "Delete FormField": "Excluir campo de formulário",
      "Textbox Properties": "Propriedades da caixa de texto",
      "Name": "Nome",
      "Tooltip": "Dip de ferramenta",
      "Value": "Valor",
      "Form Field Visibility": "Formulário de visibilidade do campo",
      "Read Only": "Somente leitura",
      "Required": "Obrigatório",
      "Checked": "Verificado",
      "Show Printing": "Mostre impressão",
      "Formatting": "Formatar",
      "Fill": "Preencher",
      "Border": "Fronteira",
      "Border Color": "Cor da borda",
      "Thickness": "Grossura",
      "Max Length": "Comprimento máximo",
      "List Item": "Nome do item",
      "Export Value": "Valor do item",
      "Dropdown Item List": "Lista de itens suspensos",
      "List Box Item List": "Lista de itens da caixa de lista",
      "General": "EM GERAL",
      "Appearance": "APARÊNCIA",
      "Options": "OPÇÕES",
      "Delete Item": "Excluir",
      "Up": "Acima",
      "Down": "Abaixo",
      "Multiline": "Multilina",
      "Revised": "Revisado",
      "Reviewed": "Revisado",
      "Received": "Recebido",
      "Confidential": "Confidencial",
      "Approved": "Aprovado",
      "Not Approved": "Não aprovado",
      "Witness": "Testemunha",
      "Initial Here": "Inicial aqui",
      "Draft": "Rascunho",
      "Final": "Final",
      "For Public Release": "Para liberação pública",
      "Not For Public Release": "Não para lançamento público",
      "For Comment": "Para comentar",
      "Void": "Vazio",
      "Preliminary Results": "Resultados preliminares",
      "Information Only": "Apenas informação",
      "in": "em",
      "m": "eu",
      "ft_in": "ft_in",
      "ft": "pés",
      "p": "p",
      "cm": "cm",
      "mm": "milímetros",
      "pt": "ponto",
      "cu": "cu",
      "sq": "quadrado",
      "Initial": "Inicial"
    },
    "stepper": {
      "optional": "Opcional"
    }
  }
});


import { loadCldr } from "@syncfusion/ej2-base";

declare var require: any;

loadCldr(
  require("cldr-data/main/pt/numbers.json"),
  require("cldr-data/main/pt/ca-gregorian.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/pt/timeZoneNames.json"),
  // require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

// import * as numberingSystems from "cldr-data/main/pt/numbers.json"

// loadCldr(numberingSystems, gregorian, numbers, detimeZoneNames);


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnDestroy, OnInit {

  @HostListener("window:resize", [])
  private onResize() {
  }

  currentRoute: string;

  contagemDiasTesteSubscription: Subscription;

  static dateInputOptions: InputmaskOptions<Date> = {
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    placeholder: '_',
    jitMasking: false,
    showMaskOnHover: false,
    showMaskOnFocus: true,
    greedy: false,
    prefillYear: false,
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const day = +values[0];
      return new Date(year, month, day);
    }
  };

  static dateInputOptionsDib: InputmaskOptions<Date> = {
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    placeholder: '_',
    jitMasking: false,
    showMaskOnHover: false,
    showMaskOnFocus: true,
    greedy: false,
    prefillYear: false,
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const day = +values[0];
      return new Date(year, month, day);
    }
  };


  // 1/11/1942-12/2/1967 cruzeiro 
  // 13/2/1967-14/5/1970 cruzeiro novo
  // 15/5/1970-27/2/1986 cruzeiro
  // 28/2/1986-15/1/1989 cruzado
  // 16/1/1989-15/3/1990 cruzado novo
  // 16/3/1990-31/7/1993 cruzeiro
  // 1/8/1993-30/6/1994 cruzeiro real
  // 1/7/1994 - real

  moedaMaskOptions(data: Date) {


    let result;

    let inicioCruzeiroA = new Date(1924, 11, 1);
    let fimCruzeiroA = new Date(1967, 2, 12);
    let inicioCruzeiroNovo = new Date(1967, 2, 13);
    let fimCruzeiroNovo = new Date(1970, 5, 14);
    let inicioCruzeiroB = new Date(1970, 5, 15);
    let fimCruzeiroB = new Date(1986, 2, 27);
    let inicioCruzado = new Date(1986, 2, 28);
    let fimCruzado = new Date(1989, 1, 15);
    let inicioCruzadoNovo = new Date(1989, 1, 16);
    let fimCruzadoNovo = new Date(1990, 3, 15);
    let inicioCruzeiroC = new Date(1990, 3, 16);
    let fimCruzeiroC = new Date(1993, 7, 13);
    let inicioCruzeiroReal = new Date(1993, 8, 1);
    let fimCruzeiroReal = new Date(1994, 6, 30);
    let inicioReal = new Date(1994, 7, 1);
    if ((data.getTime() >= inicioCruzeiroA.getTime() && data.getTime() < fimCruzeiroA.getTime()) ||
      (data.getTime() >= inicioCruzeiroB.getTime() && data.getTime() < fimCruzeiroB.getTime()) ||
      (data.getTime() >= inicioCruzeiroC.getTime() && data.getTime() < fimCruzeiroC.getTime())) {
      result = AppComponent.currencyInputOptionsCruzeiro;
    }

    if (data.getTime() >= inicioCruzeiroNovo.getTime() && data.getTime() < fimCruzeiroNovo.getTime()) {
      result = AppComponent.currencyInputOptionsCruzeiroNovo;
    }

    if (data.getTime() >= inicioCruzado.getTime() && data.getTime() < fimCruzado.getTime()) {
      result = AppComponent.currencyInputOptionsCruzado;
    }

    if (data.getTime() >= inicioCruzadoNovo.getTime() && data.getTime() < fimCruzadoNovo.getTime()) {
      result = AppComponent.currencyInputOptionsCruzadoNovo;
    }

    if (data.getTime() >= inicioCruzeiroReal.getTime() && data.getTime() < fimCruzeiroReal.getTime()) {
      result = AppComponent.currencyInputOptionsCruzeiroReal;
    }

    if (data.getTime() >= inicioReal.getTime()) {
      result = AppComponent.currencyInputOptions;
    }
  }


  static moedaPrefix(contribuicao: Contribuicao) {


    let result;
    let data: Date = new Date(contribuicao.competencia);

    let inicioCruzeiroA = new Date(1924, 11, 1);
    let fimCruzeiroA = new Date(1967, 2, 12);
    let inicioCruzeiroNovo = new Date(1967, 2, 13);
    let fimCruzeiroNovo = new Date(1970, 5, 14);
    let inicioCruzeiroB = new Date(1970, 5, 15);
    let fimCruzeiroB = new Date(1986, 2, 27);
    let inicioCruzado = new Date(1986, 2, 28);
    let fimCruzado = new Date(1989, 1, 15);
    let inicioCruzadoNovo = new Date(1989, 1, 16);
    let fimCruzadoNovo = new Date(1990, 3, 15);
    let inicioCruzeiroC = new Date(1990, 3, 16);
    let fimCruzeiroC = new Date(1993, 7, 13);
    let inicioCruzeiroReal = new Date(1993, 8, 1);
    let fimCruzeiroReal = new Date(1994, 6, 30);
    let inicioReal = new Date(1994, 7, 1);


    if ((data.getTime() >= inicioCruzeiroA.getTime() && data.getTime() < fimCruzeiroA.getTime()) ||
      (data.getTime() >= inicioCruzeiroB.getTime() && data.getTime() < fimCruzeiroB.getTime()) ||
      (data.getTime() >= inicioCruzeiroC.getTime() && data.getTime() < fimCruzeiroC.getTime())) {
      result = AppComponent.cruzeiroPrefix;
    }

    if (data.getTime() >= inicioCruzeiroNovo.getTime() && data.getTime() < fimCruzeiroNovo.getTime()) {
      result = AppComponent.cruzeiroNovoPrefix;
    }

    if (data.getTime() >= inicioCruzado.getTime() && data.getTime() < fimCruzado.getTime()) {
      result = AppComponent.cruzadoPrefix;
    }

    if (data.getTime() >= inicioCruzadoNovo.getTime() && data.getTime() < fimCruzadoNovo.getTime()) {
      result = AppComponent.cruzadoNovoPrefix;
    }

    if (data.getTime() >= inicioCruzeiroReal.getTime() && data.getTime() < fimCruzeiroReal.getTime()) {
      result = AppComponent.cruzeiroRealPrefix;
    }

    if (data.getTime() >= inicioReal.getTime()) {
      result = AppComponent.realPrefix;
    }

    return result;
  }

  static cruzeiroPrefix = "Cr$ ";
  static cruzeiroRealPrefix = "CR$ ";
  static cruzeiroNovoPrefix = "NCr$ ";
  static cruzadoPrefix = "Cz$ ";
  static cruzadoNovoPrefix = "NCz$  "
  static realPrefix = "R$ "

  static currencyInputOptionsSemPrefix = {
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    prefix: '',
    placeholder: '0'
  };

  static currencyInputOptions = {
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    prefix: 'R$ ',
    placeholder: '0'
  };

  // static  aliquotaInputMask = {
  //   alias: 'numeric',
  //   groupSeparator: '.',
  //   radixPoint: ',',
  //   digits: 0,
  //   numericInput: true,
  //   digitsOptional: false,
  //   suffix: ' %',
  //   placeholder: '0',
  //   unmaskAsNumber: true,
  //   autoUnmask: true,
  // }

  static aliquotaInputMask = {
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: true,
    suffix: ' %',
    placeholder: '0',
    unmaskAsNumber: true,
    autoUnmask: true,
    onBeforeMask(initialValue, opts) {
      return !isNaN(Number.parseFloat(initialValue)) ? (Number.parseFloat(initialValue) * 10000).toString() : "4"
    },
    onUnMask: (masked, unmasked) => {
      // this.valorAliquotaSelecionada = Number.parseInt(unmasked); //currency(masked, {separator: '.', decimal: ','}).value;
      return unmasked;
    }
  }

  static currencyInputOptionsCruzeiroReal = {
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    prefix: 'CR$ ',
    placeholder: '0'
  };
  static currencyInputOptionsCruzeiro = {
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    prefix: 'Cr$ ',
    placeholder: '0'
  };

  static currencyInputOptionsCruzadoNovo = {
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    prefix: 'NCz$ ',
    placeholder: '0'
  };

  static currencyInputOptionsCruzado = {
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    prefix: 'Cz$ ',
    placeholder: '0'
  };

  static currencyInputOptionsCruzeiroNovo = {
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    prefix: 'NCr$ ',
    placeholder: '0'
  };

  static jurosInputOptions = {

    alias: 'numeric',
    groupSeparator: ',',
    radixPoint: '.',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: '',
    placeholder: '0',


  };


  @HostListener('window:unload', ['$event'])
  onUnload(event) {
    localStorage.setItem('usuario', JSON.stringify(Global.usuario));
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private autenticacaoService: AutenticacaoService,
    private configService: ConfigService) {

    this.router.events.subscribe(async (event) => {

      if (event instanceof NavigationStart) {



        if (Global.usuario == null) {
          if (localStorage.getItem('usuario') != 'undefined') {
            let usuario: Usuario = JSON.parse(localStorage.getItem('usuario'));
            if (usuario != null && Object.keys(usuario) != null && !(Object.keys(usuario).length === 0)) {
              //controle de usuario e assinatura
              // Global.usuario = usuario;
              // console.log("set usuario app component");
              await this.configService.setUsuario(usuario);
              // this.configService.setAssinatura(Global.usuario.assinatura);
              // await this.configService.setAssinaturaStatus();
            }
          }
        }
        else {
        }
      }
    });



    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {


        this.configService.setSalarioMinimo();




        // Show loading indicator
        //  window.scrollTo(0,0);
      }

      if (event instanceof NavigationEnd) {

        this.currentRoute = event.url;


        this.configService.getUsuario().subscribe(usuario => {

          //cotrole usuario null
          if (!usuario) {
            return;
          }
          switch (usuario.permissao) {

            case Permissoes.Membro:

              if (!this.currentRoute.includes('/calculei/usuario/planos') && !this.currentRoute.includes('/calculei/usuario/checkout')) {
                let usuarioCheck: Usuario;
                let diasRestantes: number;
                this.configService.getUsuario().subscribe(usuario => usuarioCheck = usuario);
                this.configService.getDuracaoDiasTesteRestante().subscribe(dias => diasRestantes = dias);
                let assinaturaAtiva: StatusAssinatura;

                if (usuarioCheck != null && usuarioCheck.assinatura != null) {
                  assinaturaAtiva = usuarioCheck.assinatura.statusAssinatura;
                }
                if (diasRestantes < 0 && assinaturaAtiva != StatusAssinatura.ATIVA) {

                  this.router.navigateByUrl("/calculei/usuario/teste");
                }
              }

              break;
            case Permissoes.Administrador:


              break;

            default:
              break;
          }
        });





      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
      }


    });


    Chart.defaults.font.family = "tt-commons-pro";
    Chart.defaults.scale.grid.display = false;
    Chart.defaults.responsive = true;
    Chart.defaults.maintainAspectRatio = false;
    Chart.defaults.elements.point.radius = 6;
    Chart.defaults.elements.point.hoverRadius = 10;
    Chart.defaults.elements.line.borderWidth = 6;
    Chart.defaults.devicePixelRatio = 1.5;
    Chart.register(ChartDataLabels);
    Chart.defaults.plugins.datalabels.display = false;





    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    };


  }




  ngOnInit(): void {




  }

  ngOnDestroy() {
  }
}
