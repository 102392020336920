import { Cliente } from "../usuario/cliente";
import { ExtratoContribuicaoPrisma } from "./extratocontribuicaoprisma";
import { ImportacaoExtratoPrisma } from "./importacaoextratoprisma";
import { RelatorioRevisaoTempoServico } from "./relatoriorevisaotemposervico";

export class CalculoRevisaoTempoServico{
    importacaoExtrato:ImportacaoExtratoPrisma;
    extratoPrisma:ExtratoContribuicaoPrisma;
    relatorio:RelatorioRevisaoTempoServico;
    cliente:Cliente;
}