<div class="">
    <div class="row ">
        <div class="col">
            <h5 class=" h3 mb-0 linha-quadro">
                Matriz
                <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpMatriz" aria-expanded="false" aria-controls="helpMatriz"></i>
            </h5>

            <div class="collapse" id="helpMatriz">

                <div class="row d-flex ">
                    <div class="col">
                        <p>
                            Lucro (ROI) de cada cenário por alíquota e salário 
                        </p>
                    </div>

                </div>

                <div class="row d-flex ">

                    <div class="col-3 py-2 d-flex">
                        <div class="row bg-secondary rounded m-2 w-100">
                            <div class="col d-flex p-2">

                                <p class=" p-2 lh-1 text-justify">
                                    A matriz mostra como vai variar o ROI para cada cenário escolhido.
                                </p>

                            </div>
                            <div class="mt-auto pb-2 text-right">
                                <i class="comando bi bi-grid-3x3-gap-fill fs-3"></i>
                                
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>


    <div class="row py-2">

        <div class="col">
            <span class="d-block">Referência</span>
            <div class="dropdown">
                <button class="btn btn-outline-default dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{referenciaMatrizOpcao}}
                </button>
                <ul class="dropdown-menu">
                    <li>
                        <button class="dropdown-item" (click)="selecaoReferenciaMatriz('roi')">Lucro acumulado (ROI)</button>
                    </li>
                    <li>
                        <button class="dropdown-item" (click)="selecaoReferenciaMatriz('beneficio')">Valor mensal do benefício</button>
                    </li>
                    <!-- <li>
                        <button class="dropdown-item" (click)="selecaoReferenciaMatriz('taxa')">Taxa de retorno do investimento</button>
                    </li> -->
                    

                </ul>
            </div>

        </div>

    </div>
    <div class="row py-2">
        <div class="col">
            <ejs-heatmap [dataSource]="dataSource"
            id="heatmap-container"
            [paletteSettings]="paletteSettings"
            [cellSettings]="cellSettings"
            [legendSettings]="legendSettings"
            [tooltipSettings]="tooltipSettings"
            [xAxis]="xAxis"
            [yAxis]="yAxis"></ejs-heatmap>
        </div>
    </div>
</div>