import { Component, Input } from '@angular/core';
import { Ficha } from 'src/app/modelos/previdenciario/ficha';

@Component({
  selector: 'app-listagem-perguntas',
  templateUrl: './listagem-perguntas.component.html',
  styleUrl: './listagem-perguntas.component.scss'
})
export class ListagemPerguntasComponent {


  @Input() ficha:Ficha;

  constructor(){}
}
