<app-cabecalho [titulo]="'Minha Conta'"></app-cabecalho>
<div class="container-fluid mt--3">
  <div class="card-deck flex-column">
    <div class="pb-4">
      <div class="card">
        <div class="card-body">
          <!-- <div class="row">
            <div class="col pb-4">
              <span class="display-3 font-weight-lighter mb-0 text-default">
                Identidade Visual
              </span>
            </div>
          </div> -->


          <app-meus-dados></app-meus-dados>
          <app-minha-logo></app-minha-logo>

          <div *ngIf="assinante || administrador">
            <app-minha-assinatura></app-minha-assinatura>
          </div>

          <div *ngIf="!assinante || administrador">
            <app-assinar></app-assinar>
          </div>

          <div *ngIf="filiado || administrador">
            <app-opcoes-filiado></app-opcoes-filiado>
          </div>

          <div *ngIf="administrador">
            <app-opcoes-administrador></app-opcoes-administrador>
          </div>

          
          
          

          <!-- <app-meu-avatar></app-meu-avatar> -->

        </div>
      </div>
    </div>
  </div>
</div>
