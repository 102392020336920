import {Pipe, PipeTransform} from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { OpcoesNumeroContribuicoesPlanejamentoEx } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';

@Pipe({name:'opcaonumerocontribuicoes'})
export class OpcaoNumeroContribuicoesPipe implements PipeTransform{

    transform(value: any, ...args: any[]): string {
        return OpcoesNumeroContribuicoesPlanejamentoEx.toString(value);
    }

}