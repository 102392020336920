import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'posteriorPlanoReal',
})
export class PosteriorPlanoRealPipe implements PipeTransform {

  dataPlanoReal:Date = new Date(1994,7,1);

  transform(value: Date, ...args: unknown[]): boolean {


    
    return (new Date(value)).getTime() > this.dataPlanoReal.getTime();
  }

}
