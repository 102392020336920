import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AposentadoriaIdadeComponent } from './aposentadoria-idade/aposentadoria-idade.component';
import { AposentadoriaTempoContribuicaoComponent } from './aposentadoria-tempo-contribuicao/aposentadoria-tempo-contribuicao.component';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { LiquidacaoSentencaComponent } from './liquidacao-sentenca/liquidacao-sentenca.component';
import { PrevidenciarioComponent } from './previdenciario.component';
import { RelatorioTempoContribuicaoComponent } from './relatorio-tempo-contribuicao/relatorio-tempo-contribuicao.component';
import { SumarioCalculoBeneficiosComponent } from './sumario-calculo-beneficios/sumario-calculo-beneficios.component';
import { ValorCausaComponent } from './valor-causa/valor-causa.component';

import { RevisaoTempoServicoComponent } from './revisao-tempo-servico/revisao-tempo-servico.component';
import { PlanejamentoComponent } from './planejamento/planejamento.component';
import { PlanejamentoKertzmanComponent } from './planejamento-kertzman/planejamento-kertzman.component';
import { EdicaoCNISComponent } from './edicao-cnis/edicao-cnis.component';
import { QualidadeSeguradoComponent } from './qualidade-segurado/qualidade-segurado.component';
import { PreparacaoCnisComponent } from './preparacao-cnis/preparacao-cnis.component';
import { AuxilioAcidenteComponent } from './auxilio-acidente/auxilio-acidente.component';
import { IncapacidadeTemporariaComponent } from './incapacidade-temporaria/incapacidade-temporaria.component';
import { MatrizComponent } from './matriz/matriz.component';
import { InvalidezComponent } from './invalidez/invalidez.component';

const routes: Routes = [
  {
    path:'',
    component:PrevidenciarioComponent,
    children:[
      {
        path:'',
        redirectTo:'relatoriotempocontribuicao',
        pathMatch:'full'
      },
      {
        path:'relatoriotempocontribuicao',
        component:RelatorioTempoContribuicaoComponent,
      },
      {
        path:'aposentadoriaidade',
        component:AposentadoriaIdadeComponent,
      },
      {
        path:'beneficios',
        component:BeneficiosComponent,
      },
      {
        path:'sumariocalculobeneficios',
        component:SumarioCalculoBeneficiosComponent,
      },
      {
        path:'liquidacaosentenca',
        component:LiquidacaoSentencaComponent,
      },

      {
        path:'valorcausa',
        component:ValorCausaComponent,
      },

      

      {
        path:'revisaotemposervico',
        component:RevisaoTempoServicoComponent,
      },
      {
        path:'simulacao',
        component:PlanejamentoComponent,
      },
      {
        path:'kertzman',
        component:PlanejamentoKertzmanComponent,
      },
      {
        path:'edicaocnis',
        component:EdicaoCNISComponent,
      },
      {
        path:'mapaqualidadesegurado',
        component:QualidadeSeguradoComponent,
      },

      {
        path:'analisecnis',
        component:PreparacaoCnisComponent,
      },
      {
        path:'auxilioacidente',
        component:AuxilioAcidenteComponent,
      },
      {
        path:'incapacidadetemporaria',
        component:IncapacidadeTemporariaComponent,
      },
      {
        path:'matriz',
        component:MatrizComponent,
      },

      {
        path:'invalidez',
        component:InvalidezComponent,
      },

      
      
    ]

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrevidenciarioRoutingModule { }
