<div class="row">
    <div class="col">
        <p class="h3">
            Novo Cliente
        </p>
        <i class="comando bi bi-plus-square-dotted fs-5" data-bs-toggle="collapse" href="#novoclienteeditarea" role="button" aria-expanded="false" aria-controls="novoclienteeditarea" role="button"></i>
    </div>
</div>


<!-- <div class="collapse" id="collapseExample">
    <div class="card card-body">
        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
    </div>
</div> -->

<div class="collapse" id="novoclienteeditarea">
    <form [formGroup]="novoClienteForm">

        <div class="row py-2">
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    Nome
                </h5>
                <input class=" form-control text-left" type="text" formControlName="nome" />
                <p class="h6">
                    Nome do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    Sobrenome
                </h5>
                <input class=" form-control text-left" type="text" formControlName="sobrenome" />
                <p class="h6">
                    Sobrenome do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    Sexo
                </h5>
                <div class="form-group">
                    <div class="form-inline d-flex align-items-center">
                        <div class="btn-group w-100" data-toggle="buttons-radio" btnRadioGroup (ngModelChange)="edicaoSexo($event)" formControlName="sexo">
                            <label class="btn  btn-outline-black mb-0" [btnRadio]="sexoType.Masculino">Masculino</label>
                            <label class="btn  btn-outline-black mb-0" [btnRadio]="sexoType.Feminino">Feminino</label>
                        </div>
                        <p class="h6">
                            Sexo do cliente
                        </p>
                    </div>
                </div>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    CPF
                </h5>
                <input [inputMask]="cpfMask" class=" form-control text-left" type="text" formControlName="cpf" />
                <p class="h6">
                    CPF do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    Email
                </h5>
                <input class=" form-control text-left" type="text" formControlName="email" />
                <p class="h6">
                    Email do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    Telefone
                </h5>
                <input [inputMask]="telefoneMask" class=" form-control text-left" type="text" formControlName="telefone" />
                <p class="h6">
                    Telefone do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    CEP
                </h5>
                <input [inputMask]="cepMask" class=" form-control text-left" type="text" formControlName="cep" />
                <p class="h6">
                    CEP do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    Endereço
                </h5>
                <input class=" form-control text-left" type="text" formControlName="endereco" />
                <p class="h6">
                    Endereço do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    Cidade
                </h5>
                <input class=" form-control text- left" type="text" formControlName="cidade" />
                <p class="h6">
                    Cidade do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    Estado
                </h5>
                <input class=" form-control text-left" type="text" formControlName="estado" />
                <p class="h6">
                    Estado do cliente
                </p>
    
            </div>
    
            <div class="col-3">
                <h5 class="  text-uppercase mb-0 text-default">
                    País
                </h5>
                <input class=" form-control text-left" type="text" formControlName="pais" />
                <p class="h6">
                    País do cliente
                </p>
    
            </div>
    
            <div class="col-auto text-right align-self-center">
                <i class="comando bi bi-plus-lg fs-4 " role="button" (click)="addCliente()"></i>
            </div>
        </div>
    
    </form>
</div>
