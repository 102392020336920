import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Marcador } from 'src/app/modelos/common/marcador';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { AtualizarLeituraCnisService } from 'src/app/servicos/calculo/previdenciario/atualizar-leitura-cnis.service';
import { SalvarLeituraCnisEdicaoService } from 'src/app/servicos/calculo/previdenciario/salvar-leitura-cnis-edicao.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-link-marcador-leitura-cnis',
  templateUrl: './link-marcador-leitura-cnis.component.html',
  styleUrls: ['./link-marcador-leitura-cnis.component.scss']
})
export class LinkMarcadorLeituraCnisComponent {


  @ViewChild('longContent') long;
  


  @Output() marcadorIncluidoLeituraCnisEvent = new EventEmitter<Marcador[]>();

  marcadoresSelecionados:Marcador[];

  leituraCnis:LeituraCNIS;
  modalRef: NgbModalRef;
  aguardeVisivel:boolean;
  continuarHabilitado:boolean;

  constructor(private modalService: NgbModal,
    private configService:ConfigService,
    private atualizarLeituraCnisService: AtualizarLeituraCnisService){}


  marcadorSelecionado(selecao:Marcador[]){

    // console.log(selecao);
    this.marcadoresSelecionados = selecao;

    if(selecao.length > 0){
      this.continuarHabilitado = true;
    }else{
      this.continuarHabilitado = false;
    }

  }

  continuar(){

    this.configService.setAguarde(true);

    this.aguardeVisivel = true;

    this.leituraCnis.marcadores = this.marcadoresSelecionados;
    this.atualizarLeituraCnisService.atualizarLeituraCnis(this.leituraCnis)
    .then((leituraCnis:LeituraCNIS)=>{

      this.marcadorIncluidoLeituraCnisEvent.emit(this.marcadoresSelecionados)
      this.configService.setAguarde(false);
      this.aguardeVisivel = false;
      this.cancelar();

    });
    
    
  }
  cancelar(){


    this.modalRef.close();
    
  }

  carregarDados(leituraCnis:LeituraCNIS){
    this.leituraCnis = leituraCnis;
  }

  public openModal(leituraCnis:LeituraCNIS) {

    this.modalRef = this.modalService.open(this.long, { size: 'xl', scrollable: true, centered: true });
    this.carregarDados(leituraCnis);
    
  }

}
