import { Base } from "../base";
import { Arquivo } from "../faturas/arquivo";
import { Usuario } from "../usuario/usuario";

export class ImportacaoExtratoPrisma extends Base{
    arquivos:Arquivo[];
    arquivosKeys:string[];
    extratoPrismaKeys:string[];
    dataImportacao:Date;
    usuario:Usuario;
}