import { Abatimento } from "./abatimento";
import { AbatimentosValorCausaPrevidenciario } from "./abatimentosvalorcausaprevidenciario";
import { AtualizacaoValorCausaPrevidenciario } from "./atualizacaovalorcausaprevidenciario";
import { BeneficioValorCausaPrevidenciario } from "./beneficiovalorcausaprevidenciario";
import { ReferenciaValorCausaPrevidenciario } from "./referenciavalorcausaprevidenciario";

export class ValorCausaPrevidenciario{
    referencia:ReferenciaValorCausaPrevidenciario;
    beneficio:BeneficioValorCausaPrevidenciario;
    abatimentos:AbatimentosValorCausaPrevidenciario;
    atualizacao:AtualizacaoValorCausaPrevidenciario;
}