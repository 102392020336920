import { Component, OnInit, ViewChild } from '@angular/core';

import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { MontagemRelacaoPrevidenciariaComponent } from '../montagem-relacao-previdenciaria/montagem-relacao-previdenciaria.component';

@Component({
  selector: 'app-montagem-cnis',
  templateUrl: './montagem-cnis.component.html',
  styleUrls: ['./montagem-cnis.component.scss']
})
export class MontagemCNISComponent implements OnInit {

  @ViewChild('montagemRelacaoPrevidenciaria') montagem:MontagemRelacaoPrevidenciariaComponent;
  

  leituraCnis:LeituraCNIS;

  relacaoPrevidenciariaMontagem:RelacaoPrevidenciaria;

  public data:RelacaoPrevidenciaria[];
  

  constructor(private listarDadosArquivoDataService:ListarDadosArquivoDataService){}


  ngOnInit(): void {

    // this.mock();
    
  }

  mock(){
    this.listarDadosArquivoDataService.ListarArquivo("leituracnis.json", request =>{
      this.carregarDados(request as LeituraCNIS);
    });
  }

  carregarDados(request:LeituraCNIS){

    this.leituraCnis = request;
    this.data = this.leituraCnis.cnis.relacoesPrevidenciarias as RelacaoPrevidenciaria[];
    
    this.relacaoPrevidenciariaMontagem = this.data[0];



  }

  cardClick(event){
    this.relacaoPrevidenciariaMontagem = event.data as RelacaoPrevidenciaria;
    this.montagem.openModal();
  }

}
