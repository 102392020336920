import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OauthModule } from './oauth/oauth.module';
import { UsuarioModule } from './usuario/usuario.module';
import { AzureModule } from './azure/azure.module';
import { FaturaModule } from './fatura/fatura.module';
import { CalculoModule } from './calculo/calculo.module';
import { PagamentoModule } from './pagamento/pagamento.module';
import { UiModule } from './ui/ui.module';
import { BlogModule } from './blog/blog.module';
import { StrapiModule } from './strapi/strapi.module';
import { ContaModule } from './conta/conta.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OauthModule,
    UsuarioModule,
    AzureModule,
    FaturaModule,
    
    PagamentoModule,
    UiModule,
    BlogModule,
    StrapiModule,
    ContaModule
  ]
})
export class ServicosModule { }
