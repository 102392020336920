import { Base } from "../base";
import { Cliente } from "../usuario/cliente";
import { Usuario } from "../usuario/usuario";
import { Ficha } from "./ficha";
import { Pergunta } from "./pergunta";

export class Entrevista extends Base{

    cliente:Cliente;
    ficha:Ficha;
    usuario:Usuario;
    validade:Date;
    dataCriacao:Date;
    link:string;
    respondida:boolean;
    pontoContinuar:Pergunta;
    nome:string;
    mensagemInicial:string;
    mensagemFinal:string;
    
    
}