import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { StatusBeneficio } from 'src/app/modelos/previdenciario/statusbeneficio';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-quadro-beneficio-preview',
  templateUrl: './quadro-beneficio-preview.component.html',
  styleUrls: ['./quadro-beneficio-preview.component.scss']
})
export class QuadroBeneficioPreviewComponent implements OnInit{


  

  @Input() beneficio:Beneficio;
  @Input() badgeMelhor:boolean;
  @Input() esconderBotaoVerMais:boolean = false;
  @Output() relatorioCompletoEvent = new EventEmitter<Beneficio>();

  
  statusBeneficioType = StatusBeneficio;
  
  


  ngOnInit(): void {

    LogService.log('beneficio quadro beneficio preview', this.beneficio)
    
  }

  verRelatorioCompleto(){
    this.relatorioCompletoEvent.emit(this.beneficio);
  }

}
