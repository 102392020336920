import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { CalculoRevisaoTempoServico } from 'src/app/modelos/previdenciario/calculorevisaotemposervico';


@Injectable({
  providedIn: 'root'
})
export class ListarRelatorioRevisaoTempoServicoPdfService {

  constructor(private httpClient:HttpClient) { }


  ListarPDF(calculo:CalculoRevisaoTempoServico) :Observable<Blob>{
    return this.httpClient.post(environment.api as string + 'api/RelatorioRevisaoTempoServicoPDF', calculo, {responseType: 'blob'});
  }
 
}
