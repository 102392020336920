import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListarDadosArquivoDataService {

  constructor() { }

  ListarArquivo(nome:string, cb) {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/data/` + nome);

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }
}
