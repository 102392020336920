import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { CustoOportunidadeCaso } from 'src/app/modelos/previdenciario/custooportunidadecaso';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
declare var bootstrap: any;

@Component({
  selector: 'app-tabela-custo-oportunidade',
  templateUrl: './tabela-custo-oportunidade.component.html',
  styleUrl: './tabela-custo-oportunidade.component.scss'
})
export class TabelaCustoOportunidadeComponent implements OnInit, AfterViewInit {

  // https://ej2.syncfusion.com/angular/documentation/api/list-box/

  @Input() calculoMatrizPlanejamento: MatrizPlanejamento;
  @ViewChild('controleListaDisponiveis') controleListaDisponiveis: ListBoxComponent;

  listaCenariosDisponiveis: object[] = new Array();
  listaCenariosSelecionados: object[] = new Array();




  public toolbar = { items: ['moveTo', 'moveFrom'] }


  constructor() { }


  ngAfterViewInit(): void {
    // this.controleListaDisponiveis.dataSource = this.calculoMatrizPlanejamento.casos.map(caso => {
    //   if(this.listaCenariosSelecionados.filter(item=>item['id'] == caso.rowKey).length == 0){
    //     return {
    //       text: caso.referenciaMatriz, id: caso.rowKey
    //     }
    //   }
    // });
  }


  ngOnInit(): void {

    // console.log('matriz custo beneficio tabela', this.calculoMatrizPlanejamento.matrizCustoOportunidade);
    this.listaCenariosDisponiveis = this.calculoMatrizPlanejamento.casos.map(caso => { return { text: caso.referenciaMatriz, id: caso.rowKey } })
    // console.log('cenários disponiveis', this.listaCenariosDisponiveis);
    this.listaCenariosSelecionados = new Array<object>();



  }

  dropDisponiveis(event) {

    // console.log('data selecionados', event.destination.currentData);

    let selecionadosData = event.destination.currentData as any[];

    selecionadosData.map(selecionado => {
      // console.log(selecionado.id)

      let indexCusto = this.calculoMatrizPlanejamento.casos.indexOf( this.calculoMatrizPlanejamento.casos.filter(caso => caso.rowKey == selecionado.id)[0]);

      // console.log('index ', indexCusto);

      this.calculoMatrizPlanejamento.matrizCustoOportunidade.map(lista => {
        lista[indexCusto].filtrarMatriz = true;
      });

    });

  }

  dropSelecionados(event) {
    // console.log('selecao change event selecionados', event);
    //pegar selecionado remover de selecionados e inserir em disponiveis
    // console.log('lista selecionados', this.listaCenariosSelecionados);
    // console.log('lista disponiveis', this.listaCenariosDisponiveis);

    let indexCustoRemover = this.calculoMatrizPlanejamento.casos.indexOf(this.calculoMatrizPlanejamento.casos.filter(caso => caso.rowKey == event.items[0].id)[0]);
    this.calculoMatrizPlanejamento.matrizCustoOportunidade.map(lista => {
      lista[indexCustoRemover].filtrarMatriz = false;
    });


  }

  selecaoChange(event) {
    // console.log('selecao change event name', event);

    switch (event.eventName) {
      case 'moveTo':
        let indexCusto = this.calculoMatrizPlanejamento.casos.indexOf(this.calculoMatrizPlanejamento.casos.filter(caso => caso.referenciaMatriz == event.items[0].text)[0]);

        this.calculoMatrizPlanejamento.matrizCustoOportunidade.map(lista => {
          lista[indexCusto].filtrarMatriz = true;
        });

        break;

      case 'moveFrom':
        // console.log('evento move From', event)

        let indexCustoRemover = this.calculoMatrizPlanejamento.casos.indexOf(this.calculoMatrizPlanejamento.casos.filter(caso => caso.referenciaMatriz == event.items[0].text)[0]);
        this.calculoMatrizPlanejamento.matrizCustoOportunidade.map(lista => {
          lista[indexCustoRemover].filtrarMatriz = false;
        });
        break;

      default:



        break;
    }
  }





}
