import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { ExtratoBeneficioComponent } from '../extrato-beneficio/extrato-beneficio.component';

@Component({
  selector: 'app-relatorio-caso-planejamento',
  templateUrl: './relatorio-caso-planejamento.component.html',
  styleUrl: './relatorio-caso-planejamento.component.scss'
})
export class RelatorioCasoPlanejamentoComponent implements OnInit, AfterViewInit{

  @Input() caso:CasoPlanejamentoPrevidenciario;

  @ViewChild('extratoBeneficio') extratoBeneficio:ExtratoBeneficioComponent;
  
  
  constructor() {
    
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    this.carregarDados();
    
  }

  carregarDados(){

    if(this.extratoBeneficio != undefined){
      this.extratoBeneficio.carregarDados();
    }

  }

}
