import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';

@Component({
  selector: 'app-extrato-beneficio',
  templateUrl: './extrato-beneficio.component.html',
  styleUrls: ['./extrato-beneficio.component.scss']
})
export class ExtratoBeneficioComponent implements OnInit, AfterViewInit {

  

  @ViewChild('tabelaContribuicoes') tabelaContribuicoes: DatatableComponent;

  rows:Contribuicao[];
  columns:any[];
  timeout:any = {};

  @Input() entries: number = 12;
  selected: any[] = [];
  temp = [];
  activeRow: any;
  


  @Input() beneficio:Beneficio;

  constructor() { }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.carregarDados()
    
  }


  entriesChange($event) {
    this.entries = $event.target.value;
  }
  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.rows.filter(function(d) {
    
      for (var key in d) {
        if (d[key].toString().indexOf(val) !== -1) {
          return true;
        }
      }
      return false;
    });
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  onActivate(event) {

    if(event.type == 'click'){
    }
    this.activeRow = event.row;
    
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  carregarDados(){

    this.rows = this.beneficio.relatorioRendaMensalInicial.contribuicoes;

    this.temp = this.rows.map((prop,key)=>{
      return {
        ...prop,
        id: key
      };
    });

    if (this.tabelaContribuicoes != undefined) {
      this.tabelaContribuicoes.recalculate();
    }
    
  }

}
