import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compararCenarios',
})
export class CompararCenariosPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): boolean {
    // console.log('argumentos comparar cenario pipe', args[0])
    return true;
  }

}
