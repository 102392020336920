import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EntrevistasComponent } from './entrevistas.component';
import { NovaEntrevistaComponent } from './nova-entrevista/nova-entrevista.component';
import { NovaFichaComponent } from './nova-ficha/nova-ficha.component';

const routes: Routes = [

  {
    path: '',
    component:EntrevistasComponent,
    children:[
      {
        path:'',
        redirectTo:'ficha',
        pathMatch:'full'
      },
      {
        path:'entrevista',
        component:NovaEntrevistaComponent
      },
      {
        path:'ficha',
        component:NovaFichaComponent
      },
      
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EntrevistasRoutingModule { }
