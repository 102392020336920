import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { createMask } from '@ngneat/input-mask';
import * as currency from 'currency.js';

import { AppComponent } from 'src/app/app.component';
import { Abatimento } from 'src/app/modelos/previdenciario/abatimento';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { EvolucaoRMI } from 'src/app/modelos/previdenciario/evolucaormi';
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';
import { ListarEvolucaoRmiService } from 'src/app/servicos/calculo/previdenciario/listar-evolucao-rmi.service';

@Component({
  selector: 'app-nova-evolucao-rmi',
  templateUrl: './nova-evolucao-rmi.component.html',
  styleUrls: ['./nova-evolucao-rmi.component.scss']
})
export class NovaEvolucaoRmiComponent {

  @Output() salvarNovaEvolucaoRMIEvent = new EventEmitter<EvolucaoRMI>();
  @Output() salvarAbatimentosNovaEvolucaoRMIEvent = new EventEmitter<Abatimento[]>();
  @Output() processandoNovaEvolucaoRMIEvent = new EventEmitter<boolean>();
  @Input() tipoBeneficio:TipoBeneficioLiquidacaoSentencaPrevidenciario;

  focus:boolean;
  closeResult: string;
  aguardeVisivel:boolean;

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  dateCompetenciaInputMask = createMask<Date>(AppComponent.dateInputOptions);

  currencyInputMask = createMask(AppComponent.currencyInputOptions);

  novaEvolucaoRMIForm = new UntypedFormGroup({
    competenciaInicial: new UntypedFormControl('', Validators.required),
    competenciaFinal: new UntypedFormControl('', Validators.required),
    valor: new UntypedFormControl('', Validators.required)
  });

  constructor(private modalService: NgbModal, private listarEvolucaoRmiService:ListarEvolucaoRmiService) {}

  novaEvolucaoRMI(content){
    this.openModal(content, '', '');
  }
  openModal(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
        this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else {

        this.modalService.open(content,{ centered: true, size: 'lg' }).shown.subscribe(ref=>{
        });
    }
  }
  submitNovaEvolucaoRMI(){
    this.aguardeVisivel =true;
    this.processandoNovaEvolucaoRMIEvent.emit(true);
    let novo = new EvolucaoRMI();
    novo.competenciaInicial = this.novaEvolucaoRMIForm.get('competenciaInicial').value;
    novo.competenciaFinal = this.novaEvolucaoRMIForm.get('competenciaFinal').value;
    novo.valor = currency(this.novaEvolucaoRMIForm.get('valor').value, {separator: '.', decimal: ','}).value; 
    novo.tipoBeneficio = this.tipoBeneficio;
    this.listarEvolucaoRmiService.listarEvolucaoRMI(novo)
    .then(abatimentos=>{
      this.salvarAbatimentosNovaEvolucaoRMIEvent.emit(abatimentos as Abatimento[]);
      this.salvarNovaEvolucaoRMIEvent.emit(novo);
      this.aguardeVisivel = false;
      this.processandoNovaEvolucaoRMIEvent.emit(false);
      this.modalService.dismissAll();

    });

    
  }
  cancelarAbatimentoEvolucaoRMI(){
    
    this.modalService.dismissAll();
  }

}
