import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AplicacaoRoutingModule } from './aplicacao-routing.module';
import { AplicacaoComponent } from './aplicacao.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { UsuarioModule } from './usuario/usuario.module';
import { CalculosModule } from './calculos/calculos.module';

import { CompartilhadoModule } from './compartilhado/compartilhado.module';


import { PaginaInicialComponent } from './pagina-inicial/pagina-inicial.component';
import { EntrevistasModule } from './entrevistas/entrevistas.module';



@NgModule({
  declarations: [
    AplicacaoComponent,
    PaginaInicialComponent
  ],
  imports: [
    CommonModule,
    AplicacaoRoutingModule,
    DashboardModule,
    UsuarioModule,
    CalculosModule,
    CompartilhadoModule,
    
    EntrevistasModule,
    
  ]
})
export class AplicacaoModule { }
