import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { Colors } from 'chart.js';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';


@Component({
  selector: 'app-variacao-lucro-simulacao-kertzman',
  templateUrl: './variacao-lucro-simulacao-kertzman.component.html',
  styleUrls: ['./variacao-lucro-simulacao-kertzman.component.scss']
})
export class VariacaoLucroSimulacaoKertzmanComponent implements OnInit, OnChanges {

  @Input() visualizarPdf: boolean = true;
  @Input() relatorio: RelatorioKertzman;


  data: any[];
  dataPrivado: any[];
  chart: Chart;
  aguardePdf:boolean;


  constructor(private currency: CurrencyPipe, private datePipe: DatePipe,
    private listarPDFService: ListarPDFService,
    private notificacoesService: NotificacoesService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart)
      this.carregarDados();
  }




  ngOnInit(): void {
    this.carregarDados()

  }

  carregarDados() {
    if (this.chart) { this.chart.destroy(); this.chart = null; }

    
    
    this.data = this.relatorio.variacaoLucroPrevidencia;
    // console.log('variacao lucro inss', this.data);
    this.dataPrivado =this.relatorio.variacaoLucroPrivado;
    // console.log('variacao lucro privado', this.dataPrivado);

    // console.log('lucro');
    // console.log(JSON.stringify(this.simulacoes.map(caso=>caso.totalContributivoCapitalizadoGanhoReal)));


    let datasetLabels = this.relatorio.variacaoSubstituicoesTeto;

    let colors = datasetLabels.map(variacao=>variacao === this.relatorio.otimizacao.totalSubstituicoesTeto ? "#ffd600" : "#333333" )
    let dataset = {
      labels: datasetLabels,
      datasets: [
        {
          data: this.data,
          label: 'Lucro da Previdência Social',
          backgroundColor: colors,

        },
        {
          data: this.dataPrivado,
          label: 'Lucro do Produto Financeiro',
          backgroundColor: '#11cdef',//tributario#f5365c default #172b4d previdenciario #11cdef
        }

      ]
    };



    let chart = document.getElementById('variacaoLucroChart') as HTMLCanvasElement;


    Chart.register(annotationPlugin);
    Chart.register(Colors);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      options: {
        onClick: (e, activeEls) => {
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {
          colors: {
            enabled: true
          },

          annotation: {
            annotations: {
            }
          },
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: 'Variação do Lucro por Total de Contribuições de Otimização',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              size: 24,
              weight: 'normal',
            },
            titleFont: {
              size: 24,
              weight: 'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.currency.transform(context.parsed.y);
                }
                return label;
              },
              title: (tooltipItens) => {
                return tooltipItens[0].parsed.x + " Contribuições de Otimização";
              },

            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,
            min: 0,

            title: {
              display: true,
              text: 'Total de Lucro'
            },

            ticks: {
              callback: (value, index, ticks) => {
                return this.currency.transform(value);
              }
            }

          },
          x: {
            type: 'category',
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Total de contribuições de otimização'
            },




          }
        }
      }

    });
  }

  gerarPDF() {

    // this.aguardePdf = true;
    // this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.VARIACAOLUCRO;
    // this.calculoPlanejamentoKertzman.usuario = Global.usuario;

    // this.listarPDFService.ListarPDF(this.calculoPlanejamentoKertzman)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'VARIACAO-LUCRO-PLANEJAMENTO-CONTRIBUTIVO-KERTZMAN-' + this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');


    //     this.aguardePdf = false;

    //   });
  }

}
