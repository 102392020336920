import { Component, Input } from '@angular/core';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';

@Component({
  selector: 'app-parcelas-liquidacao-sentenca',
  templateUrl: './parcelas-liquidacao-sentenca.component.html',
  styleUrl: './parcelas-liquidacao-sentenca.component.scss'
})
export class ParcelasLiquidacaoSentencaComponent {

  
  @Input() calculoLiquidacaoSentenca:CalculoLiquidacaoSentencaPrevidenciario;

  

}
