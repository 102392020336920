import { Pipe, PipeTransform } from '@angular/core';
import { ProtocolosCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocoloscorrecaomonetariaprevidenciario';

@Pipe({
  name: 'protocolosCorrecao',
  
})
export class ProtocolosCorrecaoPipe implements PipeTransform {

  transform(value: ProtocolosCorrecaoMonetariaPrevidenciario, ...args: unknown[]): string {


    return this.textoProtocoloCorrecao(value);
  }

  textoProtocoloCorrecao(protocolo: ProtocolosCorrecaoMonetariaPrevidenciario) {
    let texto: string = '';


    switch (protocolo) {
      case ProtocolosCorrecaoMonetariaPrevidenciario.IGPDI032006INPC042006062009TR07200925032015IPCAE25032015ATUAL:
        texto = "IGP-DI até 03/2006, INPC de 04/2006 a 06/2009, TR de 07/2009 a 25/03/2015 e IPCA-E a partir de 25/03/2015";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.IGPDI032006INPC042006062009TR072009092017IPCAE102017ATUAL:
        texto = "IGP-DI até 03/2006, INPC de 04/2006 a 06/2009, TR de 07/2009 a 09/2017 e IPCA-E a partir de 10/2017";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.TEMA810STF:
        texto = "Tema 810 STF: IGP-DI até 03/2006, INPC de 04/2006 a 06/2009 e IPCA-E a partir de 07/2009";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.IGPDI032006INPC042006062009TR072009ATUAL:
        texto = "IGP-DI até 03/2006, INPC de 04/2006 a 06/2009 e TR a partir de 07/2009";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.TEMA905STJ:
        texto = "Tema 905 STJ: IGP-DI até 03/2006, INPC a partir de 04/2006";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.IGPM:
        texto = "IGP-M";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.INSSATUALIZACAOSALARIO:
        texto = "Índice de Correção dos Salários de Contribuição - INSS";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.TR:
        texto = "TR";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.IPCAE:
        texto = "IPCA-E";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.INPC:
        texto = "INPC";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.SELICMENSAL:
        texto = "SELIC Mensal";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.POUPANCA:
        texto = "Poupança";
        break;
      case ProtocolosCorrecaoMonetariaPrevidenciario.MANUALCALCULOJUSTICAFEDERAL:
        texto = "Conforme manual de orientação de procedimentos para os cálculos na Justiça Federal";
        break;

      case ProtocolosCorrecaoMonetariaPrevidenciario.CRITERIOESPECIFICO:
        texto = "Critério específico, aplicar regras manualmente";
        break;
      default:
        break;
    }

    return texto;
  }

}
