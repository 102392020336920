import { Contribuicao } from "./contribuicao";
import { PagamentoContribuicao } from "./pagamentocontribuicao";
import { Regime } from "./regime";
import { Remuneracao } from "./remuneracao";
import { TipoRelacao } from "./tiporelacao";
import { TipoAtividade } from "./tipoatividade";
import { Carencia } from "./carencia";
import { Base } from "../base";
import { IndicadorCNIS } from "./indicadorcnis";
import { PontoAtencaoCNIS } from "./pontoatencaocnis";
import { TiposVinculos } from "./tiposvinculos";
import { Observacao } from "./observacao";
import { Tempo } from "./tempo";

export class RelacaoPrevidenciaria extends Base{
    sequencia:number;
    nit:string;
    numeroCNPJBeneficio:string;
    regimes:Regime[];
    tipoAtividade:TipoAtividade;
    carencia:Carencia;
    tipoRelacao:TipoRelacao;
    origemVinculo:string;
    tipoFiliadoVinculo:string;
    dataInicio:Date;
    dataFim:Date;
    ultimaRemuneracao:Date;
    indicadores:string[];
    indicadoresCnis:IndicadorCNIS[];
    contribuicoes:Contribuicao[];
    especie:string;
    situacao:string;
    tempoContribuicao:string;
    
    periodo:string;
    listagemIndicadores:string;
    dataFimIndeterminada:boolean;
    planejamento:boolean;
    
    concomitantes:Contribuicao[];
    pontosAtencaoCNIS:PontoAtencaoCNIS[];
    tipoVinculo:TiposVinculos;
    dataFimDeducao:boolean;
    ignorarValidacao:boolean;

    


    

    sequenciaOrigem:RelacaoPrevidenciaria;


    tipoVinculoSubEspecie:TiposVinculos;

    valida:boolean;

    
    

    observacoes:Observacao[];

    sequenciaInativa:boolean;

    
    totalDiasBruto:number;

    

    




    tempoContribuicaoBruto:Tempo;
    tempoContribuicaoIdade:Tempo;
    totalTempoContribuicaoIdadeDias:number;
    tempoContribuicaoTempoContribuicao:Tempo;
    totalTempoContribuicaoTempoContribuicaoDias:number;
    totalCarenciaIdade:number;
    totalCarenciaTempoContribuicao:number;
    tempoCalculado:string;

    public static tipoAtividadeParseString(tipo:string): TipoAtividade{

        let resultado:TipoAtividade;

        switch (tipo) {
            case 'Normal':
                resultado = TipoAtividade.Normal;
                break;
            case 'Professor':
                resultado = TipoAtividade.Professor;
                break;
            case 'Rural':
                resultado = TipoAtividade.Rural;
                break;
            case 'Especial 15':
                resultado = TipoAtividade.Especial15;
                break;
            case 'Especial 20':
                resultado = TipoAtividade.Especial20;
                break;
            case 'Especial 25':
                resultado = TipoAtividade.Especial25;
                break;
            default:
                break;
        }

        return resultado;

    }
}