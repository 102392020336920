<!-- <app-tabela-matriz [calculoMatrizPlanejamento]="calculoMatrizPlanejamento"></app-tabela-matriz> -->

<div *ngIf="calculoMatrizPlanejamento &&  calculoMatrizPlanejamento.casos">
  <!-- <div class="upright">
    <span class="display-1">
      Matriz de Investimento
    </span>
  </div> -->
  <div class="left">
    <div class="row m-2">
      <div class="col-auto">
        <p class="display-4 text-default">
          Matriz de Investimento
        </p>
        <p class="h4">
          {{calculoMatrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}

        </p>
      </div>
    </div>
    <div class="row m-2 py-4">
      <div class="col-12">
        <div class="">
          <table class="table table-bordered table-sm  table-striped  table-hover align-middle ">
            <thead>

              <tr>
                <th></th>

                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">
                    <td>

                      <div class="row justify-content-center">
                        <div class="col ">
                          {{caso.referenciaMatriz}}
                        </div>
                      </div>

                      <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <div class="col-12" *ngFor="let marcador of caso.marcadores">
                          <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                        </div>
                      </div>

                    </td>

                  </ng-container>
                </ng-container>

              </tr>

            </thead>
            <tbody>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.aliquotaSalario">

                  <th class="">Alíquota / Salário</th>

                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row ">
                          <div class="col">

                            <div [ngSwitch]="caso.baseContributiva" class="p-0 m-0">
                              <div *ngSwitchCase="calculoMatrizPlanejamento.tetoInss">
                                {{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / Teto

                              </div>
                              <div *ngSwitchCase="calculoMatrizPlanejamento.salarioMinimo">
                                {{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / Sal. Mín
                              </div>
                              <div *ngSwitchDefault>
                                {{caso.aliquotaContributiva * 100 | number : '1.0-0'}}% / {{caso.baseContributiva | currency}} 

                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12 m-0 p-0" *ngFor="let marcador of caso.aliquotaContributivaMarcadores">
                            <span class="badge rounded-pill m-0" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.dataAposentadoria">
                  <th class="text-default">Data da Aposentadoria</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.dataAposentadoriaMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.regra">
                  <th class="text-default">Regra</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.qualificacaoReferencia}}
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.qualificacaoReferenciaMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>
                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.valorBeneficio">
                  <th class="text-default">Valor do Benefício</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.valorBeneficio | currency}}
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.valorBeneficioMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalContribuicoes">
                  <th class="text-default">Total de Contribuições</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.totalContributivoComplementarMeses}}
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.totalContributivoComplementarMesesMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.valorContribuicao">
                  <th class="text-default">Valor da Contribuição</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.valorContribuicaoComplementarMensal | currency}}
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.valorContribuicaoComplementarMensalMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalInvestimento">
                  <th class="text-default">Total do Investimento</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.totalInvestimento | currency}}
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.totalInvestimentoMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.roiAcumulado">
                  <th class="text-default">ROI Acumulado </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.retornoTotalInvestimentoRoi | currency}}
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.retornoTotalInvestimentoRoiMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.taxaRetorno">
                  <th class="text-default">Taxa de Retorno</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.taxaRetornoPlanejamentoContributivo * 100 | number : '1.2-2'}} %
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.taxaRetornoPlanejamentoContributivoMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.payback">
                  <th class="text-default">Payback</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.tempoRetornoInvestimentoMesesAbono | number : '1.0-0'}} meses
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.tempoRetornoInvestimentoMesesAbonoMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.ganhoRoi">
                  <th class="text-default">Ganho no Roi</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            {{caso.lucroPlanejamentoContributivo | currency}}
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.lucroPlanejamentoContributivoMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.percentualTeto">
                  <th class="text-default">% Teto do INSS</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            
                              {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}%
                            
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.percentualTetoInssMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>



              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.acumuladoCapitalizacao">
                  <th class="text-default">Acumulado Capitalização</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
                      <td>

                        <div class="row">
                          <div class="col ">
                            
                              {{caso.taxaRetornoPrivadoAno * 100 | number :'1.2-2'}}% / {{caso.totalContributivoCapitalizadoGanhoReal | currency }}
                            
                          </div>
                        </div>

                        <div class="row justify-content-end" *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <div class="col-12" *ngFor="let marcador of caso.acumuladoCapitalizacaoMarcadores">
                            <span class="badge rounded-pill" [style.backgroundColor]="marcador.cor">{{marcador.nome}} </span>
                          </div>
                        </div>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>
