import { RegraAplicacaoJuros } from "../common/regraaplicacaojuros";
import { RegraCorrecaoMonetaria } from "../correcaomonetaria/regracorrecaomonetaria";
import { ProtocoloCorrecaoMonetariaPrevidenciario } from "./protocolocorrecaomonetariaprevidenciario";
import { ProtocolosAplicacaoJurosPrevidenciario } from "./protocolosaplicacaojurosprevidenciario";
import { ProtocolosCorrecaoMonetariaPrevidenciario } from "./protocoloscorrecaomonetariaprevidenciario";

export class ProcessoLiquidacaoSentencaPrevidenciario{
    
    protocoloCorrecaoMonetaria:ProtocolosCorrecaoMonetariaPrevidenciario;
    regrasCorrecaoMonetaria:RegraCorrecaoMonetaria[];
    protocoloAplicacaoJuros:ProtocolosAplicacaoJurosPrevidenciario;
    regrasAplicacaoJuros:RegraAplicacaoJuros[];

}