<div class=" header bg-back-dark">
    <div class=" container-fluid">
      <div class=" header-body">
        <div class=" row align-items-center py-3">
          <div class=" col-lg-12">
            <h6 class=" h2 text-white d-inline-block mb-0">Planos de Assinatura Calculei</h6>
            <nav
              aria-label="breadcrumb"
              class=" d-none d-md-inline-block ml-md-4"
            >
              
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class=" container-fluid mt--3">
  
    <div class=" row">
      <div class=" col-lg-12">
        <div class="  ">

            <div class="pb-4">
              <div class="card">
                <div class="row">
                  <div class="col">
                    <div class="card-body">
                      <span class=" display-3 font-weight-lighter  mb-0 text-default">
                        Planos
                      </span>
                    </div>
                  </div>
          
                </div>

                <div class="row justify-content-center py-2" >
                    <div class="col-6 text-center">
                        <span class="h2 ">Escolha como você prefere assinar o calculei</span>
                    </div>

                    
                </div>
          
          
                <div class="row justify-content-center py-2" >
                    <div class="col-4 py-4 d-flex" *ngFor="let plano of listaPlanos">

                        <div class="card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-12 text-center py-4">
                                        <h3 class=" h3 mb-0">{{plano.nome}}</h3>
                                    </div>

                                    <!-- <div class="col  text-center py-2">
                                        <span> de </span><span class="text-decoration-line-through">{{plano.valorOriginal | currency}}</span><span> por</span>
                                    </div> -->

                                    <div class="row align-items-end  py-2">
                                        <div class="col  text-center">
                                            <span class=" display-1  ">{{plano.valor | currency}}</span><span >/ {{plano.periodo | periodo}}</span>
                                        </div>
                                    </div>




                                    <div class="col  text-center py-4">
                                        <span>{{plano.meioPagamento | meioPagamento}}</span>
                                    </div>
                                    

                                    <div class="col-12 text-center py-4">
                                        <button class="btn btn-default w-75" (click)="checkout(plano)">Assinar</button>
                                    </div>
                                </div>

                            </div>
                        </div>
      
                      </div>
                </div>
          
          
              </div>
          
          
          
            </div>
          
          
          </div>
        
      </div>
    </div>
  









