import { Base } from "../base";
import { Sexo } from "./sexo";
import { Tempo } from "./tempo";

export class IdentificacaoFiliado extends Base{
    nit:string;
    cpf:string;
    nome:string;
    dataExtratoPrevidenciario:Date;
    dataNascimento:Date;
    nomeMae:string;
    idade:Tempo;
    sexo:Sexo;
}