import { Pipe, PipeTransform } from '@angular/core';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';

@Pipe({
  name: 'totalDiasSequencia',
})
export class TotalDiasSequenciaPipe implements PipeTransform {

  transform(value: RelacaoPrevidenciaria, ...args: unknown[]): number {

    const oneDay = 1000 * 60 * 60 * 24; // Milissegundos em um dia
    const date1Ms = (new Date(value.dataInicio)).getTime();
    const date2Ms = (new Date(value.dataFim)).getTime();

    // Calcule a diferença em milissegundos
    const differenceMs = Math.abs(date2Ms - date1Ms);

    // Converta a diferença de volta para dias e retorne
    return Math.floor(differenceMs / oneDay);
    
  }

}
