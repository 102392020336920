import * as currency from "currency.js";
import { ImpostoRenda } from "../common/impostorenda";
import { ProdutoFinanceiro } from "../common/produtofinanceiro";

import { ContribuicaoEspecificaPlanejamento } from "./contribuicaoespecificaplanejamento";
import { EdicaoSalarioAliquota } from "./edicaosalarioaliquota";
import { OpcoesDataLimitePlanejamento } from "./opcoesdatalimiteplanejamento";
import { OpcoesImpostoRendaPlanejamento } from "./opcoesimpostorendaplanejamento";
import { OpcoesNumeroContribuicoesPlanejamento } from "./opcoesnumerocontribuicoesplanejamento";
import { PerfisContributivos } from "./perfiscontributivos";
import { Atividade } from "./atividade";

export class OpcoesPlanejamento {
    edicaoSalarioAliquota: EdicaoSalarioAliquota;
    opcaoNumeroContribuicoes: OpcoesNumeroContribuicoesPlanejamento;
    numeroContribuicoes: number;
    periodoMeses: number;
    opcaoImpostoRenda: OpcoesImpostoRendaPlanejamento;
    contribuicoesEspecificasPlanejamento: ContribuicaoEspecificaPlanejamento[];
    impostoRenda: ImpostoRenda;
    produtoFinanceiro: ProdutoFinanceiro;
    atividadesSecundarias:Atividade[];



    public static NovaOpcaoPlanejamentoMatriz(salario: number, aliquota: number): OpcoesPlanejamento {
        let opcoesPlanejamento = new OpcoesPlanejamento();

        opcoesPlanejamento = new OpcoesPlanejamento();
        opcoesPlanejamento.edicaoSalarioAliquota = new EdicaoSalarioAliquota();
        opcoesPlanejamento.edicaoSalarioAliquota.aliquota = aliquota;
        opcoesPlanejamento.edicaoSalarioAliquota.salario = salario;
        opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;

        opcoesPlanejamento.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.NORMAL;
        opcoesPlanejamento.produtoFinanceiro = new ProdutoFinanceiro();
        opcoesPlanejamento.produtoFinanceiro.taxaInflacao = 4.9;
        opcoesPlanejamento.produtoFinanceiro.taxaRetorno = 13;
        opcoesPlanejamento.produtoFinanceiro.taxaTributacao = 15;

        opcoesPlanejamento.impostoRenda = new ImpostoRenda();
        opcoesPlanejamento.impostoRenda.dependentes = 0;
        opcoesPlanejamento.impostoRenda.educacao = 0;
        opcoesPlanejamento.impostoRenda.investimento = false;
        opcoesPlanejamento.impostoRenda.saude = 0;
        opcoesPlanejamento.atividadesSecundarias = new Array<Atividade>();

        return opcoesPlanejamento;
    }


    public static sanitizeCurrency(opcoesPlanejamento:OpcoesPlanejamento){

        opcoesPlanejamento.edicaoSalarioAliquota.aliquota = currency(opcoesPlanejamento.edicaoSalarioAliquota.aliquota, { separator: '.', decimal: ',' }).value; 
        opcoesPlanejamento.edicaoSalarioAliquota.salario = currency(opcoesPlanejamento.edicaoSalarioAliquota.salario, { separator: '.', decimal: ',' }).value;
        opcoesPlanejamento.impostoRenda.educacao = currency(opcoesPlanejamento.impostoRenda.educacao, { separator: '.', decimal: ',' }).value;
        opcoesPlanejamento.impostoRenda.saude = currency(opcoesPlanejamento.impostoRenda.saude, { separator: '.', decimal: ',' }).value;


        opcoesPlanejamento.produtoFinanceiro.taxaInflacao = currency(opcoesPlanejamento.produtoFinanceiro.taxaInflacao, { separator: '.', decimal: ',' }).value;
        opcoesPlanejamento.produtoFinanceiro.taxaRetorno = currency(opcoesPlanejamento.produtoFinanceiro.taxaRetorno, { separator: '.', decimal: ',' }).value;
        opcoesPlanejamento.produtoFinanceiro.taxaTributacao = currency(opcoesPlanejamento.produtoFinanceiro.taxaTributacao, { separator: '.', decimal: ',' }).value;

    }

}