import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListaClientesComponent } from '../lista-clientes/lista-clientes.component';

@Component({
  selector: 'app-selecionar-cliente',
  templateUrl: './selecionar-cliente.component.html',
  styleUrl: './selecionar-cliente.component.scss'
})
export class SelecionarClienteComponent {

  @Output() clienteSelecionadoEvent = new EventEmitter<Cliente>();

  @ViewChild('listaClientes') listaClientes: ListaClientesComponent;

  constructor(){}

  clienteSelecionado(cliente:Cliente){
    this.clienteSelecionadoEvent.emit(cliente);
  }

  clienteAdicionado(cliente:Cliente){
    this.listaClientes.addClienteLista(cliente);

  }

}
