import { TiposProcessoLiquidacaoSentencaPrevidenciario } from "./tiposprocessoliquidacaosentencaprevidenciario";

export class ReferenciaLiquidacaoSentencaPrevidenciario {
    nome:string;
    cpf:string;
    dataCalculo:Date;
    tipoProcesso:TiposProcessoLiquidacaoSentencaPrevidenciario;
    numeroProcesso:string;
    dataCitacao:Date;
    dataAjuizamento:Date;
    naoAplicarPrescricao:boolean;
}