import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Marcador } from 'src/app/modelos/common/marcador';
import { TiposMarcador } from 'src/app/modelos/common/tiposmarcador';

@Component({
  selector: 'app-add-tinta',
  templateUrl: './add-tinta.component.html',
  styleUrl: './add-tinta.component.scss'
})
export class AddTintaComponent {

  @Input() texto: string;
  @Output() addTintaEvent = new EventEmitter<Marcador>();

  
  @ViewChild("inputCor") inputCor: ElementRef;
  @ViewChild("dropdown") dropdown: ElementRef;
  @ViewChild("dropdownMenu") dropdownMenu: ElementRef;

  constructor() { }

  addTinta() {
    let marcador: Marcador = new Marcador();
    marcador.cor = this.inputCor.nativeElement.value;
    // marcador.nome = this.inputTitulo.nativeElement.value;
    marcador.tipoMarcador = TiposMarcador.TINTAFUNDO;

    // console.log('novo marcador', marcador);
    this.addTintaEvent.emit(marcador);
    // this.fecharAddMarcador();

  }

  abrirAddMarcador() {
    // this.dropdown.nativeElement.classList.add('show');
    this.dropdownMenu.nativeElement.classList.add('show');
  }
  fecharAddMarcador() {
    this.dropdownMenu.nativeElement.classList.remove('show');
  }

}
