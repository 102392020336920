export enum TipoBeneficio{
    AposentadoriaIdade,
    AposentadoriaTempoContribuicao,
    AposentadoriaIncapacidadePermanente,
    AposentadoriaInvalidez,
    AposentadoriaEspecial,
    AposentadoriaEspecial15,
    AposentadoriaEspecial20,
    AposentadoriaEspecial25,
    AuxilioDoenca,
    AuxilioAcidente,
    AuxilioReclusao,
    PensaoMorte,
    SalarioMaternidade,
    Capitalizacao
}