export const environment = {
  production: true,
  api: 'https://calculeiappapi.azurewebsites.net/',
  apiLx: 'https://calculeiservices.azurewebsites.net/',
  apiServices: 'https://calculeiservices.azurewebsites.net/',
  apiPaginas: 'https://calculeipaginasapi.azurewebsites.net/',
  
  
  safe2pay: {
    sandboxToken: '89E515980DAB4246A8BB5B6A79C75CBC',
    token: 'E150ADD35D764A758F1EFBE6FCD2B583',
    secretKey: 'C8C300E2AA854F9FA9C1ADDEA814BC099474EFB7E7F64581B90B6BFD5208A1D4',
    secretKeySandbox: '56EAE60DE795446E87D5ADE2EC2E3562A4B34E53F3C242EC80938E3E88DB74EF',
  },

  calculeiHost: 'https://app.calculei.app',

  calculeiappB2c:{
    secret: "FKt8Q~YPCv4mEAKgf41QawMQ7_iQH~izvhNpBcdA"
  }

};
