import { Component, Input } from '@angular/core';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';

@Component({
  selector: 'app-dados-segurado',
  templateUrl: './dados-segurado.component.html',
  styleUrls: ['./dados-segurado.component.scss']
})
export class DadosSeguradoComponent {


  @Input() cnis:CNIS;

  @Input() anonimo:boolean;

  dataAnalise:Date;
  constructor(){
    this.dataAnalise = new Date();
  }

  carregarDados(cnis:CNIS){
    this.cnis = cnis;
    
  }

}
