import { Marcador } from "../common/marcador";
import { ResultadoCalculo } from "../common/resultadocalculo";
import { CalculoBeneficios } from "./calculobeneficios";
import { CamposMatriz } from "./camposmatriz";
import { CasoPlanejamentoPrevidenciario } from "./casoplanejamentoprevidenciario";
import { CustoOportunidadeCaso } from "./custooportunidadecaso";
import { FiltrosMatriz } from "./filtrosmatriz";
import { LeituraCNIS } from "./leituracnis";
import { OpcoesMatriz } from "./opcoesmatriz";
import { OpcoesPlanejamento } from "./opcoesplanejamento";

export class MatrizPlanejamento extends ResultadoCalculo{
    casos:CasoPlanejamentoPrevidenciario[];
    nome:string;
    marcadorReferencia:Marcador;
    casoReferencia:CasoPlanejamentoPrevidenciario;
    calculoBeneficios:CalculoBeneficios;
    opcoesMatriz:OpcoesMatriz;
    tetoInss:number;
    salarioMinimo:number;
    matrizCustoOportunidade:CustoOportunidadeCaso[][];
    
    
}