import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkDataAtualLimite',
  
})
export class CheckDataAtualLimitePipe implements PipeTransform {

  hoje:Date = new Date

  transform(value: Date, ...args: unknown[]): boolean {
    return (new Date(value)).getTime() > this.hoje.getTime();
  }

}
