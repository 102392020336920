import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NovoPlanoComponent } from './novo-plano/novo-plano.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { InputMaskModule } from '@ngneat/input-mask';
import { PlanosComponent } from './planos/planos.component';
import { CompartilhadoModule } from 'src/app/aplicacao/compartilhado/compartilhado.module';
import { PlanosCriadosComponent } from './planos-criados/planos-criados.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SimuladorDescontoComponent } from './simulador-desconto/simulador-desconto.component';





@NgModule({
  declarations: [
    NovoPlanoComponent,
    PlanosComponent,
    PlanosCriadosComponent,
    SimuladorDescontoComponent,
    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSelectModule,
    FormsModule,
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    InputMaskModule.forRoot({inputSelector: 'input', isAsync: true}),
    CompartilhadoModule,
    NgxDatatableModule,
    
    
  ],
  exports:[
    NovoPlanoComponent,
    PlanosComponent,
    PlanosCriadosComponent,
    SimuladorDescontoComponent,
    
  ]
})
export class CompartilhadoAdminModule { }
