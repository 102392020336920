import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginasRoutingModule } from './paginas-routing.module';
import { PaginasComponent } from './paginas.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CriarcontaComponent } from './criarconta/criarconta.component';
import { EntrarComponent } from './entrar/entrar.component';
import { TermodeusoComponent } from './termodeuso/termodeuso.component';
import { TermodeprivacidadeComponent } from './termodeprivacidade/termodeprivacidade.component';
import { TermoPrivacidadeComponent } from './termo-privacidade/termo-privacidade.component';
import { TermoUsoComponent } from './termo-uso/termo-uso.component';
import { ConfirmacaoEmailComponent } from './confirmacao-email/confirmacao-email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmarEmailComponent } from './confirmar-email/confirmar-email.component';
import { FalecomnossotimeComponent } from './falecomnossotime/falecomnossotime.component';
import { PrecosComponent } from './precos/precos.component';
import { PerguntasfrequentesComponent } from './perguntasfrequentes/perguntasfrequentes.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CompartilhadoComponent } from '../aplicacao/calculos/previdenciario/compartilhado/compartilhado.component';
import { CompartilhadoModule } from '../aplicacao/calculos/previdenciario/compartilhado/compartilhado.module';


@NgModule({
  declarations: [
    PaginasComponent,
    HomeComponent,
    LoginComponent,
    CriarcontaComponent,
    EntrarComponent,
    TermodeusoComponent,
    TermodeprivacidadeComponent,
    TermoPrivacidadeComponent,
    TermoUsoComponent,
    ConfirmacaoEmailComponent,
    ConfirmarEmailComponent,
    FalecomnossotimeComponent,
    PrecosComponent,
    PerguntasfrequentesComponent
  ],
  imports: [
    CommonModule,
    PaginasRoutingModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    ButtonsModule.forRoot(),
    CompartilhadoModule

  ],
  exports:[
    FormsModule,
    ReactiveFormsModule
  ]
})
export class PaginasModule { }
