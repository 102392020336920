export enum ProtocolosCorrecaoMonetariaPrevidenciario{

    IGPDI032006INPC042006062009TR07200925032015IPCAE25032015ATUAL,
    IGPDI032006INPC042006062009TR072009092017IPCAE102017ATUAL,
    TEMA810STF,
    IGPDI032006INPC042006062009TR072009ATUAL,
    TEMA905STJ,
    IGPM,
    INSSATUALIZACAOSALARIO,
    TR,
    IPCAE,
    INPC,
    SELICMENSAL,
    POUPANCA,
    MANUALCALCULOJUSTICAFEDERAL,
    CRITERIOESPECIFICO

}