import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import * as moment from 'moment';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';

@Component({
  selector: 'app-radar-matriz',
  templateUrl: './radar-matriz.component.html',
  styleUrls: ['./radar-matriz.component.scss']
})
export class RadarMatrizComponent implements OnInit {

  @Input() matriz: MatrizPlanejamento;

  @Output() casoSelecionadoEvent = new EventEmitter<CasoPlanejamentoPrevidenciario>();


  public data: any[];

  chart: Chart;
  chartBarra: Chart;

  constructor(private currency: CurrencyPipe,
    private changeDetector: ChangeDetectorRef, private datePipe: DatePipe,
    private decimalPipe: DecimalPipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('data change ranking rmi');
    if (this.chart) {
      this.changeDetector.detectChanges();
      // console.log('update chart');
      this.carregarDados();
      this.changeDetector.detectChanges();
    }

  }

  ngOnInit() {
    this.carregarDados();
  }

  carregarDados() {

    if (this.chart) { this.chart.destroy(); this.chart = null; }
    if (this.chartBarra) { this.chartBarra.destroy(); this.chartBarra = null; }

    if (this.matriz.casos.length == 0) return;

    moment.locale('pt-BR');


    //filtro casos por marcador
    // console.log('marcador referencia na variacao lucro');
    // console.log(this.matriz.marcadorReferencia);
    // console.log(this.matriz.casos.map(caso=>caso.marcador.nome));
    this.data = this.matriz.marcadorReferencia != undefined ? this.matriz.casos.filter(caso => caso.marcador.rowKey == this.matriz.marcadorReferencia.rowKey) : this.matriz.casos;
    this.data = this.data.sort((a, b) => b.beneficio.dataCalculo - a.beneficio.dataCalculo);

    let datasetLabels = this.data.map(caso => caso.nome);

    const MSTOM = 2629746000;

    // console.log('maior lucro');
    let maiorLucro = this.data.sort((a, b) => a.retornoTotalInvestimentoRoi - b.retornoTotalInvestimentoRoi).reverse()[0].retornoTotalInvestimentoRoi;
    // console.log(maiorLucro);


    // console.log('mais rapida')
    let maisRapida: CasoPlanejamentoPrevidenciario = this.data.sort((a, b) => (new Date(a.beneficio.dataCalculo).getTime() - new Date().getTime()) - (new Date(b.beneficio.dataCalculo).getTime() - new Date().getTime()))[0];
    // console.log(((new Date(maisRapida.beneficio.dataCalculo)).getTime() - new Date().getTime()) / MSTOM);


    // console.log('maior RMI');
    let maiorRmi = this.data.sort((a, b) => a.valorBeneficio - b.valorBeneficio).reverse()[0].valorBeneficio;
    // console.log(maiorRmi);


    // console.log('menor Investimento');
    let maiorInvestimento = this.data.sort((a, b) => a.totalInvestimento - b.totalInvestimento).reverse()[0].totalInvestimento;
    // console.log(menorInvesimento);



    // console.log('taxas de retorno');
    // console.log(this.data.map(caso =>{return (caso.marcador.cor as string).length == 9? (caso.marcador.cor as string).slice(0,-2) + '33' : (caso.marcador.cor as string).concat('33')}));

    // console.log('map cores');

    // console.log(this.data.map(caso=>caso.marcador.cor));

    let dataset = {
      labels: [
        'Maior Lucro %',
        'Mais Cedo %',
        'Maior Valor de Benefício %',
        'Menor Investimento %',

      ],
      datasets:
        this.data.map(caso => {





          return {
            label: caso.nome,
            marcador: caso.marcador,
            data: [
              (caso.retornoTotalInvestimentoRoi * 100 / maiorLucro),
              // ((new Date(caso.beneficio.dataCalculo).getTime() - new Date().getTime()) / MSTOM) * 100  /   (((new Date(maisRapida.beneficio.dataCalculo)).getTime() - new Date().getTime()) / MSTOM), 
              (((new Date(maisRapida.beneficio.dataCalculo)).getTime() - new Date().getTime()) / MSTOM) / ((new Date(caso.beneficio.dataCalculo).getTime() - new Date().getTime()) / MSTOM) * 100,
              (caso.valorBeneficio * 100 / maiorRmi),
              ((caso.totalInvestimento * 100 / maiorInvestimento) - 100) * -1,
            ],
            fill: true,
            backgroundColor: (caso.marcador.cor as string).length == 9 ? (caso.marcador.cor as string).slice(0, -2) + '33' : (caso.marcador.cor as string).concat('33'),
            borderColor: (caso.marcador.cor as string).length == 9 ? (caso.marcador.cor as string).slice(0, -2) + '33' : (caso.marcador.cor as string).concat('33'),
            pointBackgroundColor: (caso.marcador.cor as string).length == 9 ? (caso.marcador.cor as string).slice(0, -2) + '33' : (caso.marcador.cor as string).concat('33'),
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'

          }
        }),
    };

    let dataSetScore = dataset.datasets.map(dataset => { return { nome: dataset.label, marcador:dataset.marcador, score: dataset.data.reduce((total, item) => total + item) } });
    let maiorScore = 400; //dataSetScore.sort((a, b) => a.score - b.score).reverse()[0].score;
    let dataSetScorePercentual = dataSetScore.map(score => { return { nome: score.nome, marcador:score.marcador, scorePercentual: 100 * score.score / maiorScore } });

    let chart = document.getElementById('chartRadarMatriz') as HTMLCanvasElement;
    let chartBarra = document.getElementById('chartBarraMatriz') as HTMLCanvasElement;



    const labels = dataSetScorePercentual.map(score => score.nome);
    const dataBase = {
      labels: labels,
      datasets: [
        {
          
          data: dataSetScorePercentual.map(score=>score.scorePercentual),
          backgroundColor: dataSetScorePercentual.map(score=>score.marcador.cor)
        },
      ]
    };


    Chart.register(annotationPlugin);

    this.chartBarra = new Chart(chartBarra, {
      type: 'bar',
      data: dataBase,
      options: {
        onClick: (e, activeEls) => {
          if (activeEls[0])
            this.casoSelecionadoEvent.emit(this.data.sort((a, b) => b.valorBeneficio - a.valorBeneficio)[activeEls[0].index]);
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          datalabels:{
            display:true,
            color:"white",
            font:{
              
              weight: 'bold',

            },
            formatter: (value, context) => {
                return this.decimalPipe.transform(value, '1.0-0') + " %";
            },
            
          },

          annotation: {
            annotations: {

              // line1: {
              //   drawTime: 'afterDatasetsDraw',
              //   type: 'line',
              //   yMin: this.media.toFixed(2),
              //   yMax: this.media.toFixed(2),
              //   xMin: new Date(1994,7,1).toISOString(),
              //   xMax: new Date(datasetLabels[0]).toISOString(),
              //   borderColor: '#000000',
              //   borderWidth: 2,
              //   label: {
              //     position: 'center',
              //     display: true,
              //     // font:{
              //     //   size:24,
              //     //   weight:'normal',
              //     // },
              //     content: (ctx, options) => {
              //       return 'Média ' + this.currency.transform(this.media.toFixed(2));
              //     },
              //   }

              // },
            }
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Consolidação em % do aproveitamento multi-qualitativo por cenário considerando: maior valor de benefício; atecipação do benefício; menor investimento; e maior lucro, simultâneamente',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              size: 24,
              weight: 'normal',
            },
            titleFont: {
              size: 24,
              weight: 'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.decimalPipe.transform(context.parsed.y, "1.0-0") + " %";
                }
                return label;
              },
              title: (tooltipItens) => {
                return "Cenário " + tooltipItens[0].label;
              },

            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,

            ticks: {
              callback: (value, index, ticks) => {
                return this.decimalPipe.transform(value, "1.0-0") + " %";
              }
            }

          },
          x: {

            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Cenários'
            },




          }
        }
      }

    });












    this.chart = new Chart(chart, {
      type: 'radar',
      data: dataset,
      options: {

        elements: {
          line: {
            borderWidth: 1
          }
        },

        plugins: {


          title: {
            display: true,
            text: 'Análise Múlti-Qualitativa de Cenários de Aposentadoria'
          },

          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                // console.log('log context radar tooltip');
                // console.log(context);

                return this.decimalPipe.transform(context.formattedValue, '1.0-0') + "%";
              }
            }

          }


        },
        scales: {
          r: {

            grid: {
              display: true,

            },
            ticks: {

            },
            angleLines: {
              display: true,
            },
            suggestedMin: 0,
            suggestedMax: 100,
            max: 100,


          }
        }
      },

    });
  }



}
