import { CompartilhadoModule as PrevidenciarioCompartilhadoModule } from './../calculos/previdenciario/compartilhado/compartilhado.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsuarioRoutingModule } from './usuario-routing.module';
import { UsuarioComponent } from './usuario.component';
import { MinhaContaComponent } from './minha-conta/minha-conta.component';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { ClientesComponent } from './clientes/clientes.component';
import { NovoClienteComponent } from './novo-cliente/novo-cliente.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { DxTabsComponent, DxTabsModule, DxVectorMapComponent, DxVectorMapModule } from 'devextreme-angular';
import { DxoTabPanelOptionsModule } from 'devextreme-angular/ui/nested';
import { TabsModule } from 'ngx-bootstrap/tabs';


import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { CompartilhadoModule } from './compartilhado/compartilhado.module';
import { CompartilhadoModule as GeralCompartilhadoModule } from '../compartilhado/compartilhado.module';


import { CheckoutComponent } from './checkout/checkout.component';
import { PlanosComponent } from 'src/app/admin/compartilhado-admin/planos/planos.component';
import { SelecionarPlanoComponent } from './selecionar-plano/selecionar-plano.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { InputMaskModule } from '@ngneat/input-mask';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { QuadroUsuarioComponent } from './quadro-usuario/quadro-usuario.component';
import { TesteComponent } from './teste/teste.component';
import { NovoFiliadoComponent } from './novo-filiado/novo-filiado.component';
import { EditorUsuarioComponent } from './editor-usuario/editor-usuario.component';
import { PixCheckoutComponent } from './pix-checkout/pix-checkout.component';
import { AssinarComponent } from './assinar/assinar.component';
import { OpcoesAdministradorComponent } from './opcoes-administrador/opcoes-administrador.component';
import { MinhaAssinaturaComponent } from './minha-assinatura/minha-assinatura.component';
import { ContasModule } from '../contas/contas.module';
import { MeusDadosComponent } from './meus-dados/meus-dados.component';




@NgModule({
  declarations: [
    UsuarioComponent,
    MinhaContaComponent,
    ConfiguracoesComponent,
    ClientesComponent,
    NovoClienteComponent,
    CheckoutComponent,
    SelecionarPlanoComponent,
    QuadroUsuarioComponent,
    TesteComponent,
    NovoFiliadoComponent,
    EditorUsuarioComponent,
    PixCheckoutComponent,
    AssinarComponent,
    OpcoesAdministradorComponent,
    MinhaAssinaturaComponent,
    MeusDadosComponent
    
    
  ],
  imports: [
    CommonModule,
    UsuarioRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    ProgressbarModule,
    CompartilhadoModule,
    GeralCompartilhadoModule,
    PopoverModule,
    InputMaskModule.forRoot({inputSelector: 'input', isAsync: true}),
    ButtonsModule,
    PrevidenciarioCompartilhadoModule,
    ContasModule
    
    
    
    
    
    
    
    
  ],
  exports:[
    QuadroUsuarioComponent,
    NovoFiliadoComponent,
    EditorUsuarioComponent
    
  ],
  providers:[BsModalService]
})
export class UsuarioModule { }
