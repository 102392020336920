


<div class="row">
  <div class="col-12">
    <h5 class=" h3 mb-0 linha-quadro">Variação de Lucro</h5>
  </div>

  <div  class="col-12 d-flex flex-row-reverse">
    <i class="bi-filetype-pdf h1 mb-0 clicavel" ></i>
  </div>
</div>
<div class="row" >
      
  <!-- Chart wrapper -->
  <div class="col-lg-12" style="height: 300px;">
    <canvas id="chartVariacaoLucroMatriz"></canvas>
  </div>
</div>


