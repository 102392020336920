import { TabDirective } from "ngx-bootstrap/tabs";
import { Fluxo } from "./fluxo";
import { FasesPlanejamento } from "./fasesplanejamento";
import { FasesPlanejamentoContributivo } from "./fasesplanejamentocontributivo";

export class Navegacao {
    indexTabAtiva: number;
    totalTabs: number;
    manterTabAtivaVoltar:boolean;
    voltarHabilitado: boolean;
    proximoHabilitado: boolean;
    aguardeVisivel: boolean;

    constructor(tabAtiva:number, voltarHabilitado:boolean, proximoHabilitado:boolean){
        this.indexTabAtiva = tabAtiva;
        this.voltarHabilitado = voltarHabilitado;
        this.proximoHabilitado = proximoHabilitado;
    }
    

    public static indexTabAtiva(tabDirective: TabDirective): number {
        return tabDirective.tabset.tabs.indexOf(tabDirective.tabset.tabs.filter(tab => tab.active)[0]);
    }

    public static listarTotalTabs(tabDirective:TabDirective):number{
        return tabDirective.tabset.tabs.length;
    }

    public static listarVoltarHabilitado(tabDirective:TabDirective):boolean{
        return Navegacao.indexTabAtiva(tabDirective) >0;
    }

    public static listarProximoHabilitado(tabDirective:TabDirective):boolean{
        return !(Navegacao.indexTabAtiva(tabDirective) >= Navegacao.listarTotalTabs(tabDirective)-1);
    }
}