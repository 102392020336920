import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import * as currency from 'currency.js';

import { NgxSelectOption } from 'ngx-select-ex';
import { AppComponent } from 'src/app/app.component';
import { BeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/beneficioliquidacaosentencaprevidenciario';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import { TipoBeneficio } from 'src/app/modelos/previdenciario/tipobeneficio';
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';

@Component({
  selector: 'app-beneficio-liquidacao-sentenca-previdenciario',
  templateUrl: './beneficio-liquidacao-sentenca-previdenciario.component.html',
  styleUrls: ['./beneficio-liquidacao-sentenca-previdenciario.component.scss']
})
export class BeneficioLiquidacaoSentencaPrevidenciarioComponent implements OnInit {

  @Input() calculoLiquidacaoSentencaPrevidenciario: CalculoLiquidacaoSentencaPrevidenciario;

  tipoBeneficioLiquidacaoSentencaPrevidenciarioType = TipoBeneficioLiquidacaoSentencaPrevidenciario;
  tipoProcessoLiquidacaoSentencaPrevidenciarioType = TiposProcessoLiquidacaoSentencaPrevidenciario;

  numeroBeneficioMask = createMask('9999999999');
  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);
  dateInputMaskDib = createMask<Date>(AppComponent.dateInputOptionsDib);
  currencyInputMask = createMask(AppComponent.currencyInputOptions);




  habilitarRmi: boolean;


  itemTipoBeneficio: any;
  itemsTipoBeneficio: any[];

  calculoBeneficiosSelecaoCnis: CalculoBeneficios;





  constructor(private calculeiApiService:CalculeiApiService,
    private changeDetector:ChangeDetectorRef
  ) { 
    this.itemsTipoBeneficio = (Object.keys(TipoBeneficioLiquidacaoSentencaPrevidenciario) as Array<keyof typeof TipoBeneficioLiquidacaoSentencaPrevidenciario>)
      .filter(chave => isNaN(Number(chave)))
      .map(tipo => { return { value: tipo, text: this.textoTipoBeneficio(TipoBeneficioLiquidacaoSentencaPrevidenciario[tipo]) } });


    this.itemsTipoBeneficio.sort((a, b) => {
      let textA = a.text.toLowerCase();
      let textB = b.text.toLowerCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    this.dateInputMaskDib.oncomplete = (()=>{

      this.calculeiApiService.api("ListarSalarioMinimoCompetencia", this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib)
      .subscribe(resultado=>{
        // console.log('resultado salario minimo competencia beneficio liquidacao', resultado);
        this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.rmi = resultado['salarioMinimo'];
        this.changeDetector.detectChanges();
      })


    });
  }

  ngOnInit(): void {




    

  }


  carregarDados(calculo: CalculoLiquidacaoSentencaPrevidenciario) {
    // // console.log('beneficio init');
    // // console.log(this.calculoLiquidacaoSentencaPrevidenciario);
    // if (calculo.liquidacaoSentenca.beneficio && calculo.liquidacaoSentenca.beneficio.tipoBeneficio) {
    //   this.calculoLiquidacaoSentencaPrevidenciario = calculo;
    //   this.beneficioForm.get('numeroBeneficio').setValue(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.numeroBeneficio);
    //   this.beneficioForm.get('dataCessacao').setValue(new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dataCessacao));
    //   this.beneficioForm.get('dib').setValue(new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));

    //   // console.log('dib 25');
    //   // console.log(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib25);

    //   if (this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib25) {
    //     this.beneficioForm.get('dib25').setValue(new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib25));
    //   }

    //   this.beneficioForm.get('dip').setValue(new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dip));
    //   this.beneficioForm.get('rmi').setValue(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.rmi);

    //   this.beneficioForm.get('tipoBeneficio').setValue(this.textoTipoBeneficio(TipoBeneficioLiquidacaoSentencaPrevidenciario[this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.tipoBeneficio.toString()]));
    // }
    // else {

    //   this.calculoLiquidacaoSentencaPrevidenciario = calculo;
    //   this.itemTipoBeneficio = this.itemsTipoBeneficio[TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADE].text;
    //   this.beneficioForm.get('tipoBeneficio').setValue(this.itemTipoBeneficio);
    //   this.beneficioForm.get('dip').setValue(new Date());
    // }


    // switch (+this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso) {
    //   case TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO:
    //     this.numeroBeneficioValidator.push(Validators.required);
    //     this.dibValidator.push(Validators.required);
    //     break;
    //   case TiposProcessoLiquidacaoSentencaPrevidenciario.REESTABELECIMENTO:
    //     this.dataCessacaoValidator.push(Validators.required);
    //     this.numeroBeneficioValidator.push(Validators.required);
    //     break;
    //   default:
    //     this.dibValidator.push(Validators.required);
    //     break;
    // }


  }

  selectTipoBeneficio(select) {

  }
  selectTipoBeneficioChanges(selecao: NgxSelectOption[]) {
    // this.itemTipoBeneficio = selecao[0].data.value;
    // this.beneficioForm.get('tipoBeneficio').setValue(selecao[0].data.value);

    // let tipoBeneficioSelecionado: TipoBeneficioLiquidacaoSentencaPrevidenciario = TipoBeneficioLiquidacaoSentencaPrevidenciario[selecao[0].data.value as string];

    // // console.log('tipo beneficio selecionado', tipoBeneficioSelecionado);

    // switch (tipoBeneficioSelecionado) {
    //   case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINVALIDEZDECORRENTEACIDENTETRABALHO:
    //     this.beneficioForm.get('rmi').setValue(0);


    //     this.habilitarRmi = false;


    //     break;

    //   default:
    //     this.habilitarRmi = true;

    //     break;
    // }

  }

  editarTipoBeneficio(tipo:string){

    // console.log('editar tipo beneficio', tipo);
    this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.tipoBeneficio = this.tipoBeneficioLiquidacaoSentencaPrevidenciarioType[tipo];

  }

  textoTipoBeneficio(tipo: TipoBeneficioLiquidacaoSentencaPrevidenciario): string {
    let texto = '';

    switch (tipo) {
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIARURALVELHICE:
        texto = 'Aposentadoria Rural (Velhice)';
        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAO:
        texto = 'Aposentadoria por Tempo de Contribuição';
        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPONTOS:
        texto = 'Aposentadoria por Tempo de Contribuição (Regra de Pontos)';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADERURAL:
        texto = 'Aposentadoria por Idade (Rural)';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPROFESSOR:
        texto = 'Aposentadoria por Tempo de Contribuição (Professor)';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPROFESSORPONTOS:
        texto = 'Aposentadoria por Tempo de Contribuição (Professor) (Regra de Pontos)';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADEPESSOADECIFICIENCIA:
        texto = 'Aposentadoria por Idade da Pessoa com Deficiência';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADE:
        texto = 'Aposentadoria por Idade';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAESPECIAL:
        texto = 'Aposentadoria Especial';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINVALIDEZ:
        texto = 'Aposentadoria por Invalidez (Incapacidade Permanente)';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINCAPACIDADEPERMANENTE:
        texto = 'Aposentadoria por Incapacidade Permanente';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIODOENCA:
        texto = 'Auxílio-Doença';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIOACIDENTE:
        texto = 'Auxílio-Acidente';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.BENEFICIOPRESTACAOCONTINUADAASSISTENCIALLOAS:
        texto = 'Benefício de Prestação Continuada (Assistencial) - LOAS';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.PENSAOMORTE:
        texto = 'Pensão por Morte';
        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIORECLUSAO:
        texto = 'Auxílio Reclusão';
        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.SALARIOMATERNIDADE:
        texto = 'Salário Maternidade';
        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINVALIDEZDECORRENTEACIDENTETRABALHO:
        texto = 'Aposentadoria por Invalidez Decorrente de Acidente de Trabalho';
        break;




      default:
        break;
    }
    return texto;
  }


  cnisGeralModalSelecaoConcluida(calculoBeneficios: CalculoBeneficios) {
    // console.log('selecao cnis concluida beneficio liquidacao', calculoBeneficios);
    this.calculoBeneficiosSelecaoCnis = calculoBeneficios;

  }

  continuar() {


    // let beneficioLiquidacaoSentenca = new BeneficioLiquidacaoSentencaPrevidenciario();


    // beneficioLiquidacaoSentenca.tipoBeneficio = this.itemTipoBeneficio;
    // beneficioLiquidacaoSentenca.dib = this.beneficioForm.get('dib').value;
    // beneficioLiquidacaoSentenca.dip = this.beneficioForm.get('dip').value;
    // beneficioLiquidacaoSentenca.rmi = currency(this.beneficioForm.get('rmi').value, { separator: '.', decimal: ',' }).value;


    // beneficioLiquidacaoSentenca.calculoBeneficios = this.calculoBeneficiosSelecaoCnis;

    // switch (+this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso) {
    //   case TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO:
    //     beneficioLiquidacaoSentenca.numeroBeneficio = this.beneficioForm.get('numeroBeneficio').value;
    //     break;

    //   case TiposProcessoLiquidacaoSentencaPrevidenciario.REESTABELECIMENTO:
    //     beneficioLiquidacaoSentenca.dataCessacao = this.beneficioForm.get('dataCessacao').value;
    //     beneficioLiquidacaoSentenca.numeroBeneficio = this.beneficioForm.get('numeroBeneficio').value;
    //     beneficioLiquidacaoSentenca.dib = this.beneficioForm.get('dataCessacao').value;
    //     break;

    //   default:
    //     break;
    // }


    // if (this.beneficioForm.get('dib25').value === '') {
    //   // beneficioLiquidacaoSentenca.dib25 = new Date(); //this.beneficioForm.get('dib25').value;
    //   beneficioLiquidacaoSentenca.dib25 = undefined;
    // }
    // else {
    //   beneficioLiquidacaoSentenca.dib25 = this.beneficioForm.get('dib25').value;
    // }

    // this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio = beneficioLiquidacaoSentenca;


    // this.continuarEvent.emit(this.calculoLiquidacaoSentencaPrevidenciario);
  }
}
