import { TipoBeneficio } from "./tipobeneficio";
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from "./tipobeneficioliquidacaosentencaprevidenciario";

export class TipoBeneficioLiquidacaoSentencaPrevidenciarioExtensions{

    static toString(tipo:TipoBeneficioLiquidacaoSentencaPrevidenciario):string{

        
        
        let texto:string = '';
        
        

        switch (tipo) {
            case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIARURALVELHICE:
					texto = "Aposentadoria Rural (Velhice)";
					break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAESPECIAL:
                    texto = "Aposentadoria Especial";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADE:
                    texto = "Aposentadoria Idade";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADEPESSOADECIFICIENCIA:
                    texto = "Aposentadoria por Idade de Pessoa com Deficiência";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADERURAL:
                    texto = "Aposentadoria por Idade (Rural)";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINCAPACIDADEPERMANENTE:
                    texto = "Aposentadoria por Incapacidade Permanente";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINVALIDEZ:
                    texto = "Aposentadoria por Invalidez (Incapacidade Permanente)";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAO:
                    texto = "Aposentadoria por Tempo de Contribuição";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPONTOS:
                    texto = "Aposentadoria por Tempo de Contribuição (Regra de Pontos)";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPROFESSOR:
                    texto = "Aposentadoria por Tempo de Contribuição (Professor)";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPROFESSORPONTOS:
                    texto = "Aposentadoria por Tempo de Contribuição (Professor) (Regra de Pontos)";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIOACIDENTE:
                    texto = "Auxílio-Acidente";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIODOENCA:
                    texto = "Auxílio-Doença";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIORECLUSAO:
                    texto = "Auxílio-Reclusão";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.BENEFICIOPRESTACAOCONTINUADAASSISTENCIALLOAS:
                    texto = "Benefício de Prestação Contínuada (Assistencial) - LOAS";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.PENSAOMORTE:
                    texto = "Pensão por Morte";
                    break;
                case TipoBeneficioLiquidacaoSentencaPrevidenciario.SALARIOMATERNIDADE:
                    texto = "Salário Maternidade";
                    break;
                default:
                    
					break;
        }

        return texto;
    }
}