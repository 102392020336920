import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SalaEntrevistasComponent } from './sala-entrevistas.component';
import { SalaComponent } from './sala/sala.component';


const routes: Routes = [
  {
    path:'',
    component:SalaEntrevistasComponent,
    children:[
      {
        path:'',
        redirectTo:'sala',
        pathMatch:'full'
      },
      {
        path:'sala',
        component:SalaComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalaEntrevistasRoutingModule { }
