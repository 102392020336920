
<div *ngIf="calculoBeneficios" class="pb-6">
    <div class="row p-2">
        <div class="col-md-6">
            <!-- <img src={{logoUrl}} width="100px"> -->
        </div>

        <div class="col-md-6 text-right">
            <p class="font-weight-bold mb-1 h1">Sumário de Resultados de Renda Mensal Inicial
            </p>
            <p class="mb-1 h2">Cliente {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}
            </p>
        </div>
    </div>

    <hr class="my-5">

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1 text-default">Sumário de Resultados de Renda Mensal Inicial</span>
        </div>
    </div>

    <div class="row pt-2 pb-6 p-2">
        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Cliente</p>
            <p class="mb-1 h1"><span class="text-muted h1">Nome </span>
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">CPF </span>
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.cpf}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Sexo </span>
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Data Nascimento </span>
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataNascimento | date :
                'dd/MM/yyyy'}}
            </p>

            <p class="mb-1 h1"><span class="text-muted h1">Idade </span>
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.idade.porExtenso}}</p>

        </div>

        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Parâmetros de Cálculo</p>

            <p class="mb-1 h1"><span class="text-muted h1">Data do cálculo </span>
                {{calculoBeneficios.data| date : 'dd/MM/yyyy'}}</p>

        </div>

    </div>

</div>

<div *ngIf="calculoBeneficios" class=" card-deck flex-column flex-xl-row">

    <div class="pb-6">
        <div class="row mx-1">
            <h5 class=" h3 ml-2 mb-0 linha-quadro">Resultados</h5>

        </div>
        <div *ngFor="let beneficio of calculoBeneficios.beneficios">

            <div class="row continuo">
                <div class="col-12 pt-6">
                    <div class="card shadow-none border h-100">

                        <div class="card-header text-sm-left text-center pb-3 no-bottom-border">

                            <div class="row">
                                <div class="col-12">
                                    <p class="h2">
                                        {{beneficio.qualificacao}} {{beneficio.qualificacaoDetalhada}}
                                         
                                    </p>
                                </div>
                            </div>

                            

                            <app-quadro-beneficio-preview [beneficio]="beneficio" [esconderBotaoVerMais]="true" (relatorioCompletoEvent)="verRelatorioCompleto($event)"></app-quadro-beneficio-preview>

                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="row mx-1 continuo">
            <div class="py-6">
                <app-ranking-rmi *ngIf="calculoBeneficios.beneficios" [beneficios]="calculoBeneficios.beneficios"
                    (rmiSelecionadaEvent)="verRelatorioCompleto($event)"></app-ranking-rmi>
            </div>
        </div>
    
        <div class="row mx-1 continuo">
            <div class="py-6">
                <app-evolucao-salarios-pos-real *ngIf="calculoBeneficios.beneficios"
                    [beneficio]="calculoBeneficios.beneficios[0]"></app-evolucao-salarios-pos-real>
            </div>
        </div>



    </div>

   

</div>