<div class=" header bg-back-dark">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-3">
        <div class=" col-lg-12">
          <span class=" text-white inline-block mb-0 h6 fw-lighter">Entrevista</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class=" container-fluid mt--3  ">

  <div class=" row">
    <div class=" col-12">
      <div class="  ">
        <div class="pb-4">

          <div class=" card ">

            <div class="row">
              <div class="col-12">

                <div class=" card-body  ">

                  <div class="row">
                    <div class="col-12">

                      <div class="row">
                        <div class="col-12">
                          <app-barra-comandos *ngIf="entrevista" [(titulo)]="entrevista.nome" (editarTituloEvent)="editarTitulo($event)" (proximoEvent)="proximo($event)" (anteriorEvent)="anterior($event)" (cancelarEvent)="cancelar($event)"></app-barra-comandos>
                        </div>
                      </div>
                      <div id="stepper1" class="bs-stepper .vertical">
                        <div class="row">
                          <div class="col-12">
                            <div class="bs-stepper-header">
                              <div class="step" data-target="#test-l-1">
                                <button class="step-trigger">
                                  <span class="bs-stepper-circle fw-normal"><i class="bi bi-person-raised-hand"></i></span>
                                  <span class="bs-stepper-label fw-normal">Cliente</span>
                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-2">
                                <button class="step-trigger">
                                  <span class="bs-stepper-circle fw-normal"><i class="bi bi-card-list"></i></span>
                                  <span class="bs-stepper-label fw-normal">Ficha </span>
                                </button>
                              </div>

                              <div class="line"></div>
                              <div class="step" data-target="#test-l-3">
                                <button class="step-trigger">
                                  <span class="bs-stepper-circle fw-normal"><i class="bi bi-ui-checks-grid"></i></span>
                                  <span class="bs-stepper-label fw-normal">Opções </span>
                                </button>
                              </div>
                              <div class="line"></div>
                              <div class="step" data-target="#test-l-4">
                                <button class="step-trigger">
                                  <!-- <div class="spinner-grow" role="status" [ngClass]="{'show': aguardeResultado, 'collapse': !aguardeResultado}"></div> -->

                                  <span class="bs-stepper-circle fw-normal"><i class="bi bi-share"></i></span>
                                  <span class="bs-stepper-label fw-normal">Compartilhar</span>

                                </button>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div class="bs-stepper-content m-0 p-0">

                          <!-- selecionar Cliente -->
                          <div id="test-l-1" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-selecionar-cliente (clienteSelecionadoEvent)="clienteSelecionado($event)"></app-selecionar-cliente>
                              </div>
                            </div>
                          </div>

                          <!-- Selecionar Ficha -->
                          <div id="test-l-2" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-selecionar-ficha (fichaSelecionadaEvent)="fichaSelecionada($event)"></app-selecionar-ficha>

                              </div>
                            </div>
                          </div>

                          <!-- Opções -->
                          <div id="test-l-3" class="content">

                            <div class="row">
                              <div class="col-12">
                                <app-editor-opcoes-entrevista *ngIf="entrevista" [entrevista]="entrevista" ></app-editor-opcoes-entrevista>
                              </div>
                            </div>

                          </div>
                          <!-- Compartilhar -->
                          <div id="test-l-4" class="content">

                            <div class="row">
                              <div class="col-12">

                                <app-acoes-entrevista *ngIf="entrevista" [entrevista]="entrevista"></app-acoes-entrevista>

                              </div>
                            </div>

                          </div>

                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

</div>