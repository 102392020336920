import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { Global } from 'src/app/modelos/global';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ListarUsuariosService } from 'src/app/servicos/conta/listar-usuarios.service';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  usuario:Usuario;
  aguardeVisivel:boolean = true;

  entries: number = 10;
  selected: any[] = [];
  temp = [];
  activeRow: any;
  rows = [];
  rowsMap=[];
  


  constructor(private listarUsuariosService:ListarUsuariosService, private datePipe:DatePipe) {

    

    let requestBase:RequestBase = new RequestBase();
    requestBase.usuario = Global.usuario;
    this.listarUsuariosService.listarUsuario(requestBase)
    .then(usuarios=>{


      

      this.rows = usuarios as Usuario[];

      this.rows.sort((a,b)=>{return new Date(a.dataUltimoAcesso).getTime() - new Date(b.dataUltimoAcesso).getTime()}).reverse();
      this.rowsMap = this.rows.map((prop)=> {return {rowKey:prop.rowKey, nome:prop.nome, email:prop.email, dataConta:prop.dataConta, dataUltimoAcesso:prop.dataUltimoAcesso, totalAcessos:prop.totalAcessos, emailConfirmado:prop.emailConfirmado };});
      this.temp = this.rowsMap.map((prop, key) => {
        return {
          ...prop,
          id: key
        };
      });

      this.aguardeVisivel = false;
    });
  }
  entriesChange($event) {
    this.entries = $event.target.value;
  }
  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.rowsMap.filter(function(d) {
    
      for (var key in d) {
        if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
          return true;
        }
      }
      return false;
    });
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  onActivate(event) {
    
    if(event.type === "click"){
      this.activeRow = event.row;
      this.usuario = this.rows.filter(row=>row.rowKey===this.activeRow.rowKey)[0];
    }
    
  }

  ngOnInit() {
    
  }

}


