import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';



import Chart from "chart.js/auto";

import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CurrencyPipe, DatePipe } from '@angular/common';

import * as moment from 'moment';
import "chartjs-adapter-moment";
import annotationPlugin from 'chartjs-plugin-annotation';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import {ptBR} from 'date-fns/locale';



@Component({
  selector: 'app-evolucao-salarios-pos-real',
  templateUrl: './evolucao-salarios-pos-real.component.html',
  styleUrls: ['./evolucao-salarios-pos-real.component.scss']
})
export class EvolucaoSalariosPosRealComponent implements OnChanges {

  @Output() rmiSelecionadaEvent = new EventEmitter<Beneficio>();
  @Input() beneficio: Beneficio;


  public data: Contribuicao[];
  public dataPreReal: any[];
  public salesChart;
  public clicked: boolean = true;
  public clicked1: boolean = false;

  mediaPosReal:number = 0;
  mediaPreReal:number = 0;

  chart:Chart;

  constructor(private currency: CurrencyPipe,
    private changeDetector: ChangeDetectorRef, private datePipe: DatePipe) { }

  average(ctx) {
    const values = ctx.chart.data.datasets[0].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(this.chart){
      this.carregarDados(this.beneficio);
    }
  }

  ngOnInit() {
    this.carregarDados(this.beneficio);
  }

  carregarDados(beneficio:Beneficio){

    this.beneficio = beneficio;

    if(this.beneficio === undefined) return;
    if(this.chart){this.chart.destroy(); this.chart = null;}
    
    let dataPlanoReal = new Date(1994, 7, 1);

    // console.log('contribuicoes');
    // console.log(this.beneficio.relatorioRendaMensalInicial.contribuicoes.length);

    // console.log('contribuicoes pre real');
    // console.log(this.beneficio.relatorioRendaMensalInicial.contribuicoesPrePlanoReal.length);

    this.data = new Array<Contribuicao>();


    this.data.push(... this.beneficio.relatorioRendaMensalInicial.contribuicoesPrePlanoReal);
    this.data.push(... this.beneficio.relatorioRendaMensalInicial.contribuicoes);
    

    // this.data = this.beneficio.relatorioRendaMensalInicial.contribuicoes.concat(this.beneficio.relatorioRendaMensalInicial.contribuicoesPrePlanoReal);

    


    this.data = this.data.sort((a,b)=> (new Date(a.competencia)).getTime() - (new Date(b.competencia)).getTime());
    

    let datasetLabels = this.data.map(contribuicao=>contribuicao.competencia);
    let dataset = {
      labels: datasetLabels, datasets: [
        {
          data: this.data.map(contribuicao=>(new Date(contribuicao.competencia)) < dataPlanoReal ? contribuicao.valorAtualizado : 0),
          label: 'Salários de Contribuição Anteriores ao Plano Real',
          barThickness: 1.5,
          backgroundColor: '#11cdef',
        },

        {
          data: this.data.map(contribuicao=>(new Date(contribuicao.competencia)) > dataPlanoReal ? contribuicao.valorAtualizado : 0),
          label: 'Salários de Contribuição Pós Plano Real',
          backgroundColor: '#172b4d',
          barThickness: 1.5,
        },

        
      ]
    };



    let chart = document.getElementById('evolucaoSalarioPosRealChart') as HTMLCanvasElement;

    moment.locale('pt-BR');
    
    Chart.register(annotationPlugin);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      options: {
        onClick: (e, activeEls) => {
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          annotation: {
            // annotations: {

            //   line1: datasetContribuicoesLabels.length >0 ? {
            //     drawTime: 'afterDatasetsDraw',
            //     type: 'line',
            //     yMin: this.mediaPreReal.toFixed(2),  //this.grupos.mediaContribuicoesPreReforma.toFixed(2),
            //     yMax: this.mediaPreReal.toFixed(2),
            //     xMin: (new Date(datasetContribuicoesLabels[0])).toISOString(),
            //     xMax: (new Date(1994, 7, 1)).toISOString(),
            //     borderColor: '#11cdef',
            //     borderWidth: 2,
            //     label: {
            //       position: 'center',
            //       display: true,
            //       font:{
            //         size:24,
            //         weight:'normal',
            //       },
            //       content: (ctx, options) => {
            //         return 'Média ' + this.currency.transform(this.mediaPreReal.toFixed(2));
            //       },
            //     }

            //   }: null,
            //   line2: {
            //     drawTime: 'afterDatasetsDraw',
            //     type: 'line',
            //     yMin: this.mediaPosReal.toFixed(2),
            //     yMax: this.mediaPosReal.toFixed(2),
            //     xMin : (new Date(datasetPosRealLabels[0])).toISOString(),
            //     xMax: (new Date(datasetPosRealLabels.reverse()[0])).toISOString(),
            //     borderColor: '#000000',
            //     borderWidth: 2,

            //     label: {
            //       position: 'center',
            //       display: true,
            //       font:{
            //         size:24,
            //         weight:'normal',
            //       },
            //       content: (ctx, options) => {
            //         return 'Média ' + this.currency.transform(this.mediaPosReal.toFixed(2));
            //       },
            //     }
            //   }

            // }
          },
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: 'Salários de Contribuição',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont:{
              size:24,
              weight:'normal',
            },
            titleFont:{
              size:24,
              weight:'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.currency.transform(context.parsed.y);
                }
                return label;
              },
              title: (tooltipItens) => {
                return "Competência "+ this.datePipe.transform(tooltipItens[0].parsed.x, 'MM/yyyy');
              },

            }
          }
        },



        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value, index, ticks) => {
                return this.currency.transform(value);
              }
            }

          },
          x: {
            type: 'time',
            
            adapters:{date:{locale: ptBR}},
            ticks: {
              // autoSkip:true,
              // callback(tickValue, index, ticks) {

              //   return new Intl.DateTimeFormat('pt-BR', {
              //     // dateStyle: 'short',
              //     month: 'short',
              //     year: 'numeric',
              //   }).format(new Date(tickValue));
              //   // return (new Date(tickValue)).toString();
              // },
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Competência'
            },




          }
        }
      }

    });
  }





}



