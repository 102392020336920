<div class="row ">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">Timeline de Remunerações
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroTimeline" aria-expanded="false" aria-controls="helpQuadroTimeline"></i>
        </h5>

        <div class="collapse" id="helpQuadroTimeline">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 text-justify">
                                Salários de contribuição
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-cash-coin fs-3"></i>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div class="py-2 flex text-right">
    <div class="dropdown">
        <button class="btn btn-outline-default dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{opcao}}
        </button>
        <ul class="dropdown-menu">
            <li><button class="dropdown-item" (click)="selecionarOpcao('salariosContribuicao')">Salários de Contribuição</button></li>
            <li><button class="dropdown-item" (click)="selecionarOpcao('salariosContribuicaoLimiteTetoInss')">Salários de Contribuição Limitados ao Teto do INSS</button></li>
            <li><button class="dropdown-item" (click)="selecionarOpcao('todos')">Outras Opcões</button></li>

        </ul>
    </div>
</div>

<div>
    <canvas id="canvasSalariosPreview" style="max-height: 250px;">
        {{chart}}
    </canvas>
</div>