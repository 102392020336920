import { Base } from "../base";
import { Usuario } from "../usuario/usuario";

export class Voucher extends Base{
    codigo:string;
    nome:string;
    dataValidade:Date;
    desconto:number;
    usuario:Usuario;
    habilitado:boolean;
    
}