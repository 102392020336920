import { Base } from "../base";
import { PeriodoPlano } from "../usuario/periodoplano";
import { MeiosPagamento } from "./meiospagamento";


export class Plano extends Base{


    idPlan:number;
    nome:string;
    descricao:string;
    descricaoPagamento:string;
    vantagensPagamento:string;
    valor:number;
    desconto:number;
    descontoMeioPagamento:number;
    valorOriginal:number;
    data:Date;
    vencimento:Date;
    periodo:PeriodoPlano;
    meioPagamento:MeiosPagamento;
    ativo:boolean;

    
}