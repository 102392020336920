
import { CalculoTipos } from "../common/calculotipos";
import { ResultadoCalculo } from "../common/resultadocalculo"
import { Beneficio } from "./beneficio";
import { CalculoBeneficios } from "./calculobeneficios";
import { CasoPlanejamentoPrevidenciario } from "./casoplanejamentoprevidenciario";
import { EdicaoSalarioAliquota } from "./edicaosalarioaliquota";
import { EdicaoSalarioAliquotaKertzman } from "./edicaosalarioaliquotakertzman";
import { LeituraCNIS } from "./leituracnis"
import { OpcaoEnquadramentoPadraoKertzman } from "./opcaoenquadramentopadraokertzman";
import { OpcoesPlanejamento } from "./opcoesplanejamento";
import { RelatorioKertzman } from "./relatoriokertzman";
import { RelatorioPlanejamentoPrevidenciario } from "./relatorioplanejamentoprevidenciario";
import { RelatorioRMI } from "./relatoriormi";

export class CalculoPlanejamentoKertzman extends ResultadoCalculo {

    calculoBeneficios: CalculoBeneficios;
    otimizacaoEspecifica: boolean;
    indicativoOtimizacoes: number;
    casos: CasoPlanejamentoPrevidenciario[];
    simulacoes: CasoPlanejamentoPrevidenciario[];
    preAnalise: Beneficio;
    opcoesPlanejamento: OpcoesPlanejamento;
    otimizacao: CasoPlanejamentoPrevidenciario;
    matriz: boolean;
    relatorio:RelatorioKertzman;

    public static novoCalculoMatriz(salario: number, aliquota: number, calculoBeneficios: CalculoBeneficios): CalculoPlanejamentoKertzman {

        let calculo: CalculoPlanejamentoKertzman = new CalculoPlanejamentoKertzman();
        
        calculo.calculoBeneficios = calculoBeneficios;
        calculo.nomeCalculo = "Novo cálculo Método Kertzman Matriz";
        calculo.tipoCalculo = CalculoTipos.KERTZMAN;
        calculo.data = new Date();
        calculo.matriz = true;
        calculo.opcoesPlanejamento = OpcoesPlanejamento.NovaOpcaoPlanejamentoMatriz(salario, aliquota);

        return calculo;

    }

}