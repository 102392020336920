import { Component, Input, OnInit } from '@angular/core';
import { CalculoRevisaoTempoServico } from 'src/app/modelos/previdenciario/calculorevisaotemposervico';
import { PendenciaSequenciaDocumentoExtratoContribuicaoPrisma } from 'src/app/modelos/previdenciario/pendenciasequenciadocumentoextratocontribuicaoprisma';
import { RelatorioExtratoPrisma } from 'src/app/modelos/previdenciario/relatorioextratoprisma';
import { ListarRelatorioRevisaoTempoServicoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-revisao-tempo-servico-pdf.service';

import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-relatorio-extrato-prisma',
  templateUrl: './relatorio-extrato-prisma.component.html',
  styleUrls: ['./relatorio-extrato-prisma.component.scss']
})
export class RelatorioExtratoPrismaComponent implements OnInit {

  @Input() calculoRevisaoTempoServico:CalculoRevisaoTempoServico;

  processandoPdf:boolean;

  relatorio:RelatorioExtratoPrisma;
  pendenciasExtrato:PendenciaSequenciaDocumentoExtratoContribuicaoPrisma[];
  rowsPendencias:any[];

  constructor(private notificacoesService:NotificacoesService,
    private listarRelatorioRevisaoTempoServicoPdfService:ListarRelatorioRevisaoTempoServicoPdfService)
    {}
  

    ngOnInit(): void {


      this.pendenciasExtrato = this.calculoRevisaoTempoServico.extratoPrisma.documentos.flatMap(documento=>documento.sequencias).filter(sequencia=>typeof sequencia === 'undefined').map(sequencia=>sequencia.pendencia);
      

      this.rowsPendencias = this.pendenciasExtrato.map((prop,key)=>{
        return {
          ...prop,
          id: key
        };
      });
    }

  baixarPDF(){

    
    // this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');
    // this.processandoPdf = true;


    // this.listarRelatorioRevisaoTempoServicoPdfService.ListarPDF(this.calculoRevisaoTempoServico)
    // .subscribe(blob=>{

    //   this.processandoPdf = false;
    //   const a = document.createElement('a');
    //   const objectUrl = URL.createObjectURL(blob);
    //   a.href = objectUrl;
    //   a.download = 'RelatorioExtratoPrisma.pdf';
    //   a.click();
    //   URL.revokeObjectURL(objectUrl);

    //   this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
    // });  
  }


}
