import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CapitalizacaoMensal } from 'src/app/modelos/previdenciario/capitalizacaomensal';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { RelatorioPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioplanejamentoprevidenciario';
import { FasesPlanejamento } from 'src/app/modelos/ui/fasesplanejamento';
import { FasesPlanejamentoKertzman } from 'src/app/modelos/ui/fasesplanejamentokertzman';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { ListarCalculoChaveService } from 'src/app/servicos/calculo/common/listar-calculo-chave.service';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ListarTabelaCapitalizacaoSaqueService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao-saque.service';
import { ListarTabelaCapitalizacaoService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao.service';
import { LeituraJsonBlobService } from 'src/app/servicos/relatorio/leitura-json-blob.service';
import { ListarRelatorioPlanejamentoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-planejamento-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-relatorio-matriz-planejamento',
  templateUrl: './relatorio-matriz-planejamento.component.html',
  styleUrls: ['./relatorio-matriz-planejamento.component.scss']
})
export class RelatorioMatrizPlanejamentoComponent implements OnInit, AfterViewInit {

  constructor(private location: Location, private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private leituraJsonBlobService:LeituraJsonBlobService,
    private listarTabelaCapitalizacaoSaqueService: ListarTabelaCapitalizacaoSaqueService,
    private listarTabelaCapitalizacaoService: ListarTabelaCapitalizacaoService,
    private route: ActivatedRoute,
    private listatCalculoChaveService: ListarCalculoChaveService,
    private listarCalculoResultadoService: ListarCalculoResultadoService) { }


  caso: CasoPlanejamentoPrevidenciario;

  logoUrl: string;

  calculoRowKey: string;
  calculoPartitionKey: string;
  calculoUrl: string;

  resultadoCalculo: ResultadoCalculo;

  matriz:MatrizPlanejamento;

  carregarDados(request: MatrizPlanejamento) {

    this.matriz = request

    // this.caso = this.matriz.casoReferencia;
    this.caso = this.matriz.casos[0];


    this.logoUrl = this.matriz.usuario.logoUrl;
  }

  ngAfterViewInit(): void {
   
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {


      this.calculoUrl = params.url;
      this.calculoRowKey = params.rowkey;
      this.calculoPartitionKey = params.partitionkey;

      let resultadoCalculo: ResultadoCalculo = new ResultadoCalculo();

      resultadoCalculo.rowKey = this.calculoRowKey;
      resultadoCalculo.partitionKey = this.calculoPartitionKey;


      let calculo: Calculo = new Calculo();
      calculo.rowKey = this.calculoRowKey;
      calculo.partitionKey = this.calculoPartitionKey;
      calculo.tipo = CalculoTipos.MATRIZPLANEJAMENTO;


      this.leituraJsonBlobService.ListarJsonBlob(this.calculoUrl, request =>{

        let calculo:MatrizPlanejamento = request as MatrizPlanejamento;
        // console.log(casoResultado);
        this.carregarDados(request as MatrizPlanejamento);
      });
      
    });

  }


}
