
import { CalculoTipos } from "../common/calculotipos";
import { ResultadoCalculo } from "../common/resultadocalculo"
import { CalculoBeneficios } from "./calculobeneficios";
import { CasoPlanejamentoPrevidenciario } from "./casoplanejamentoprevidenciario";
import { EdicaoSalarioAliquota } from "./edicaosalarioaliquota";
import { LeituraCNIS } from "./leituracnis"
import { OpcoesPlanejamento } from "./opcoesplanejamento";
import { RelatorioPlanejamentoPrevidenciario } from "./relatorioplanejamentoprevidenciario";

export class CalculoPlanejamentoPrevidenciario extends ResultadoCalculo {


    calculoBeneficios: CalculoBeneficios;
    casos: CasoPlanejamentoPrevidenciario[];
    opcoesPlanejamento: OpcoesPlanejamento;
    simulacoes: CasoPlanejamentoPrevidenciario[];
    matriz:boolean;

    static NovoCalculoMatriz(salario: number, aliquota: number, calculoBeneficios:CalculoBeneficios): CalculoPlanejamentoPrevidenciario {
        let calculo = new CalculoPlanejamentoPrevidenciario();
        calculo.calculoBeneficios = calculoBeneficios;
        calculo.nomeCalculo = "Novo cálculo simulação";
        calculo.data = new Date();
        calculo.tipoCalculo = CalculoTipos.CALCULOPLANEJAMENTOPREVIDENCIARIO;
        calculo.calculoBeneficios.dataCalculo = new Date();
        calculo.matriz = true;
        calculo.opcoesPlanejamento = OpcoesPlanejamento.NovaOpcaoPlanejamentoMatriz(salario, aliquota);

        return calculo;
    }

}