import { Pipe, PipeTransform } from '@angular/core';
import { Marcador } from 'src/app/modelos/common/marcador';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { ListarMarcadoresService } from 'src/app/servicos/usuario/listar-marcadores.service';

@Pipe({ name: 'marcador' })
export class MarcadorPipe implements PipeTransform {

    listaMarcadores: Marcador[];

    constructor(private configService:ConfigService) {
    }

    transform(value: any, ...args: any[]): string {

        this.configService.getListaMarcadores().subscribe((listaMarcadores:Marcador[])=>{this.listaMarcadores = listaMarcadores;});
        let marcador: Marcador = this.listaMarcadores.filter(marcador => marcador.rowKey == value)[0];
        // console.log('marcador do pipe');
        // console.log(marcador);
        return marcador.cor




    }

}