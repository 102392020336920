import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MeiosPagamento } from 'src/app/modelos/pagamento/meiospagamento';
import { Plano } from 'src/app/modelos/pagamento/plano';
import { PeriodoPlano } from 'src/app/modelos/usuario/periodoplano';
import { ListarPlanosService } from 'src/app/servicos/pagamento/safe2pay/listar-planos.service';

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.scss']
})
export class PlanosComponent {

  listaPlanos:Plano[];

  constructor(private router: Router, private listarPlanosService:ListarPlanosService){


    this.listarPlanosService.listarPlanos()
    .then((planos:Plano[])=>{

       //add pix teste plano

      //  let planoPix:Plano = new Plano();
      //  planoPix.id = 0;
      //  planoPix.nome = "Anual PIX";
      //  planoPix.descricao = "Anual Pix";
      //  planoPix.descricaoPagamento = "Pix";
      //  planoPix.vantagensPagamento = "Praticidade Pix";
      //  planoPix.valorOriginal = 470;
      //  planoPix.desconto = 0;
      //  planoPix.descontoMeioPagamento = 0;
      //  planoPix.valor = 470;
      //  planoPix.data = new Date();
      //  planoPix.vencimento = new Date();
      //  planoPix.periodo = PeriodoPlano.ANUAL;
      //  planoPix.meioPagamento = MeiosPagamento.PIX;
      //  planoPix.ativo = true;
 
      //  planos.push(planoPix)

     
      this.listaPlanos = planos as Plano[];
    });
    // this.listaPlanos = [
    //   {
    //     id: 0,
    //     nome: "Mensal Cartão",
    //     descricao:"Calculei completo com pagamentos mensais no cartão de crédito",
    //     descricaoPagamento: "Lançamentos mensais no cartão de crédito",
    //     vantagensPagamento: "Praticidade",
    //     valor: 122.5 * 0.4,
    //     valorOriginal: 122.5,
    //     desconto: 0.6,
    //     descontoMeioPagamento:0,
    //     data: new Date(),
    //     vencimento: new Date(),
    //     periodo: PeriodoPlano.MENSAL,
    //     meioPagamento: MeiosPagamento.CARTAOCREDITOVISTA,
    //     ativo:true
        
    //   },{
    //     id: 0,
    //     nome: "Anual PIX",
    //     descricao:"Calculei completo por 1 ano com apenas um PIX cheio de desconto",
    //     descricaoPagamento: "1 Pix, 1 ano de Calculei",
    //     vantagensPagamento: "Praticidade com desconto",
    //     valor: 122.5 * 12 *  0.75 * 0.4,
    //     valorOriginal: 122.5 * 12,
    //     desconto:0.6,
    //     descontoMeioPagamento:0.25,
    //     data: new Date(),
    //     vencimento: new Date(),
    //     periodo: PeriodoPlano.ANUAL,
    //     meioPagamento: MeiosPagamento.PIX,
    //     ativo:true
        
    //   },
    //   {
    //     id: 0,
    //     nome: "Anual Cartão à Vista",
    //     descricao:"",
    //     descricaoPagamento: "lançamentos mensais no cartão de crédito",
    //     vantagensPagamento: "lançamentos mensais no cartão de crédito",
    //     valor: 122.5 * 12 * 0.8 * 0.4,
    //     valorOriginal: 122.5 * 12,
    //     desconto: 0.6,
    //     descontoMeioPagamento:0.2,
    //     data: new Date(),
    //     vencimento: new Date(),
    //     periodo: PeriodoPlano.ANUAL,
    //     meioPagamento: MeiosPagamento.CARTAOCREDITOVISTA,
    //     ativo:true
        
    //   },
    //   {
    //     id: 0,
    //     nome: "Anual Cartão Parcelado 12X",
    //     descricao:"",
    //     descricaoPagamento: "lançamentos mensais no cartão de crédito",
    //     vantagensPagamento: "lançamentos mensais no cartão de crédito",
    //     valor: 122.5 * 12 * 0.85 * 0.4,
    //     valorOriginal: 122.5 * 12,
    //     desconto: 0.6,
    //     descontoMeioPagamento:0.15,
    //     data: new Date(),
    //     vencimento: new Date(),
    //     periodo: PeriodoPlano.ANUAL,
    //     meioPagamento: MeiosPagamento.CARTAOCREDITO12,
    //     ativo:true
        
    //   }

    // ]
  }


  checkout(plano:Plano){
    this.router.navigateByUrl('/calculei/usuario/checkout', { state: plano });

  }




}
