import { ResultadoCalculo } from "../common/resultadocalculo";
import { Usuario } from "../usuario/usuario";
import { Beneficio } from "./beneficio";
import { CNIS } from "./cnis";
import { LeituraCNIS } from "./leituracnis";
import { RequestCalculoBeneficios } from "./requestcalculobeneficios";

export class CalculoBeneficios extends ResultadoCalculo {
    
    leituraCnis:LeituraCNIS;
    beneficios:Beneficio[];
    revisaoVidaToda:boolean;
    planejamento:boolean;
    dataCalculo:Date;
}