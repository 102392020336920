<h5 class=" h3 mb-0 linha-quadro">Gerador de Voucher</h5>

<div class="row py-4">
  <div class="col-3">
    <h5 class=" card-title text-uppercase mb-0 text-default">
      Nome
    </h5>
    <input class=" form-control text-left" type="text" [(ngModel)]="voucher.nome" />
    <p class="h6">
      Referência do voucher
    </p>
  </div>

  <div class="col-3">
    <h5 class=" card-title text-uppercase mb-0 text-default">
      Código
    </h5>
    <input class=" form-control text-uppercase text-left" type="text" [(ngModel)]="voucher.codigo" />
    <p class="h6">
      Texto que deverá ser informado no formulário para aplicar o desconto do vouher
    </p>
  </div>

  <div class="col-3">
    <h5 class=" card-title text-uppercase mb-0 text-default">
      Data de validade
    </h5>
    <input [inputMask]="dateInputMask" class=" form-control text-left" type="text" [(ngModel)]="voucher.dataValidade" />
    <p class="h6">
      Data validade
    </p>
  </div>

  <div class="col-3">
    <h5 class=" card-title text-uppercase mb-0 text-default">
      Desconto
    </h5>
    <div class="form-inline d-flex align-items-center">
      <div class="btn-group w-100" btnRadioGroup [(ngModel)]="voucher.desconto">
        <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="10">10%</label>
        <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="15">15%</label>
        <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="20">20%</label>
        <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="30">30%</label>
      </div>

    </div>

    <p class="h6">
      Desconto percentual que será aplicado no valor total da assinatura no checkout
    </p>
  </div>
  
</div>
<div class="row flex d-flex flex-row-reverse">
  <div class="col-auto text-center">
    <i role="button" (click)="gerarVoucher()" class="comando bi bi-arrow-right fs-1"></i>
    <p class="h6">
      Criar Voucher
    </p>
  </div>
</div>