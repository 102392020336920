<div *ngIf="false" class="row py-5 continuo">

    <div class="col-12" *ngIf="relatorio && relatorio.otimizacao && relatorio.otimizacao.totalSubstituicoesTeto >0">

        <h5 class=" h3 mb-0 linha-quadro">Taxa de Retorno sobre o Investimento das Contribuições opcionais de
            otimização</h5>

        <div class="row">

            <div class=" col-6 col-xxl-4 pt-5 continuo">
                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.ganhoBeneficio | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Ganho no Benefício em relação ao Cenário Base
                </span>
            </div>

            <div class="col-6 col-xxl-4 pt-5 continuo" popoverTitle="Substituições Contributivas pelo Teto"
                placement="bottom"
                [popover]="'Número de contribuições ideal para chegar ao valor de benefício com a melhor relação Investimento x Taxa de Payback'"
                triggers="mouseenter:mouseleave">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalSubstituicoesTeto }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Contribuições opcionais de otimização
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo" popoverTitle=" Total do Investimento" placement="bottom"
                [popover]="'É a soma das ' + relatorio.otimizacao.totalSubstituicoesTeto + ' contribuições no valor de ' + stringToCurrency(relatorio.otimizacao.valorContribuicaoComplementarMensal) + ' cada, totalizando ' + stringToCurrency(relatorio.otimizacao.totalInvestimentoSubstituicoes)"
                triggers="mouseenter:mouseleave">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalInvestimentoSubstituicoes |
                    currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Total do Investimento
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">
                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.tempoRetornoInvestimentoMesesAbono |
                    number : '1.0-0'}} meses

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Payback do Investimento
                </span>
            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title  mb-0 text-default">

                    {{relatorio.otimizacao.taxaRetornoPlanejamentoContributivo *
                    100 | number : '1.2-2'}} % a. m.
                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Taxa de Retorno do Investimento ao mês
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.lucroPlanejamentoContributivo |
                    currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Ganho no Lucro em em relação ao Cenário Base
                </span>

            </div>

        </div>

    </div>

</div>

<div class="row py-5 continuo">
    <div class="col-12">

        <h5 class=" h3 mb-0 linha-quadro">Retorno Financeiro do Benefício</h5>

        <div class=" row  ">

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.valorBeneficio |
                    currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Valor do Benefício
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo" >

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.valorBeneficioAbonoAnual |
                    currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Valor do Benefício com 13<sup>0</sup> salário
                </span>
                <h5 class="text-uppercase text-muted mb-0">

                </h5>
                <span class=" h2 font-weight-bold mb-0 "></span>
            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.expectativaVida | number : '1.1-1'}}
                    anos / {{relatorio.otimizacao.expectativaVida * 12 | number : '1.0-0'}} meses

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Expectativa de sobrevida após a data da aposentadoria (IBGE)
                </span>
                <h5 class="text-uppercase text-muted mb-0">

                </h5>
                <span class=" h2 font-weight-bold mb-0 "></span>
            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">
                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.valorBeneficioAbonoAnual * relatorio.otimizacao.expectativaVida * 12 |
                    currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Valor Bruto da Aposentadoria
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalInvestimento |
                    currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Custo das contribuições futuras ao INSS
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo" >

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.retornoTotalInvestimentoRoi |
                    currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Lucro Líquido da Aposentadoria (ROI)
                </span>
                <h5 class="text-uppercase text-muted mb-0">

                </h5>
                <span class=" h2 font-weight-bold mb-0 "></span>
            </div>

        </div>

    </div>

</div>

<div class="row py-5 ">
    <div class="col-12" *ngIf="relatorio.otimizacao && relatorio.otimizacao.totalContributivoCapitalizadoGanhoReal > 0">

        <h5 class=" h3 mb-0 linha-quadro">
            Comparativo de Taxas de Retorno da Previdência e Aplicação Privada a {{relatorio.otimizacao.taxaRetornoPrivadoAno * 100 | number : '1.2-2'}}% a. a.
        </h5>

        <div class=" row">
            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title  mb-0 text-default">

                    {{relatorio.otimizacao.taxaRetornoPrivadoAno * 100 | number :
                    '1.2-2'}}
                    % a. a.

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Taxa de Retorno ao ano da Aplicação
                </span>

            </div>
            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.taxaTributacaoDescontada * 100 | number
                    : '1.2-2'}}
                    %

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Tributação
                </span>

            </div>
            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title  mb-0 text-default">

                    {{relatorio.otimizacao.taxaRentabilidadePrivadaLiquida * 100 |
                    number : '1.2-2'}}
                    % a. a.

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Taxa de Rentabilidade Líquida
                </span>

            </div>
        </div>

        <div class=" row">

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.taxaInflacaoProjetada * 100 | number :
                    '1.2-2' }}
                    %

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Inflação
                </span>
            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title  mb-0 text-default">

                    {{relatorio.otimizacao.taxaGanhoRealPrivadaAno * 100 | number :
                    '1.2-2'}}
                    % a. a.

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Ganho Real
                </span>
                <h5 class="text-uppercase text-muted mb-0">

                </h5>
                <span class=" h2 font-weight-bold mb-0 "></span>
            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title  mb-0 text-default">

                    {{relatorio.otimizacao.taxaRentabilidadePrivadaMensalCapitalizada
                    * 100 | number : '1.2-2'}}
                    % a. m.

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Rentabilidade Mensal Capitalizada
                </span>
                <h5 class="text-uppercase text-muted mb-0">

                </h5>
                <span class=" h2 font-weight-bold mb-0 "></span>
            </div>

        </div>

        <div class=" row">

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalContributivoCapitalizadoGanhoReal |
                    currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Investimento Capitalizado Acumulado
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalContributivoCapitalizadoGanhoReal - relatorio.otimizacao.totalInvestimento | currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Total do Rendimento
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.expectativaVida | number : '1.1-1'}}
                    anos / {{relatorio.otimizacao.duracaoSaqueCapitalizacaoMeses | number
                    : '1.0-0'}}
                    meses

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Expectativa de Sobrevida
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.valorSaqueCapitalizacao | currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Saque mensal durante {{relatorio.otimizacao.expectativaVida | number : '1.1-1'}}
                    anos
                </span>

            </div>

        </div>

        <div *ngIf="visualizarProgressaoCapitalizacao" class="row d-flex py-4 d-flex-row">

            <div class="col-12 col-xxl-6 pt-5">
                <div class=" col-12 text-center py-2">

                    <span class=" display-4 font-weight-lighter   mb-0 text-default">
                        Progressão da Capitalização
                    </span>

                    <app-progressao-capitalizacao-chart *ngIf="linhasCapitalizacaoMensal"
                        [linhasCapitalizacaoMensal]="linhasCapitalizacaoMensal"></app-progressao-capitalizacao-chart>

                </div>


                <div *ngIf="visualizarProgressaoCapitalizacaoTabela" class=" col-xl-12 col-md-12  py-2">

                    <div class="table-responsive">
                        <table class="table table-bordered table-sm  table-striped  table-hover align-middle overflow-auto">
                            <thead>
                                <tr>
                                   
                                    <th scope="col">
                                        <span>
                                            Parcela
                                        </span>
                                    </th>

                                    <th scope="col">
                                        <span>
                                            Investimento Acumulado
                                        </span>
                                    </th>

                                    <th scope="col">
                                        <span>
                                            Taxa Real
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span>
                                            Rendimento no Mês
                                        </span>
                                    </th>
                                </tr>
            
                            </thead>
                            <tbody>
                                <ng-container class *ngFor="let linha of linhasCapitalizacaoMensal; index as index">
            
                                    <tr>
                                       
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.mes}}</span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.investimentoAcumulado | currency}}</span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.taxaReal  | number : '1.2-2'}} % </span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.rendimentoMes | currency}}</span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
            
                                      
                                    </tr>
            
                                </ng-container>
            
                            </tbody>
                        </table>
            
                    </div>

                </div>
            </div>

            <div class="col-12 col-xxl-6 pt-5">
                <div class=" col-12 text-center py-2">

                    <span class=" display-4 font-weight-lighter text-center mb-0 text-default">
                        Progressão de Saques Mensais de
                        {{relatorio.otimizacao.valorSaqueCapitalizacao | currency }}
                    </span>

                    <app-duracao-saque-capitalizacao-chart *ngIf="linhasCapitalizacaoMensalSaque"
                        [linhasCapitalizacaoMensalSaque]="linhasCapitalizacaoMensalSaque"></app-duracao-saque-capitalizacao-chart>
                </div>


                <div *ngIf="visualizarProgressaoCapitalizacaoTabela" class=" col-xl-12 col-md-12 py-2">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm  table-striped  table-hover align-middle overflow-auto">
                            <thead>
                                <tr>
                                   
                                    <th scope="col">
                                        <span>
                                            Parcela
                                        </span>
                                    </th>

                                    <th scope="col">
                                        <span>
                                            Investimento Acumulado
                                        </span>
                                    </th>

                                    <th scope="col">
                                        <span>
                                            Taxa Real
                                        </span>
                                    </th>

                                    <th scope="col">
                                        <span>
                                            Rendimento
                                        </span>
                                    </th>

                                    <th scope="col">
                                        <span>
                                            Saque no Mês
                                        </span>
                                    </th>
                                </tr>
            
                            </thead>
                            <tbody>
                                <ng-container class *ngFor="let linha of linhasCapitalizacaoMensalSaque; index as index">
            
                                    <tr>
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.mes}}</span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.investimentoAcumulado | currency}}</span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.taxaReal * 100 | number : '1.2-2'}}%</span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.rendimentoMes | currency}}</span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
                                        <!-- competencia  -->
                                        <td>
                                            <div>
                                                <span>{{linha.saque | currency}}</span>
                                            </div>
                                            
                                            <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                        </td>
                                       
            
                                      
                                    </tr>
            
                                </ng-container>
            
                            </tbody>
                        </table>
            
                    </div>

                </div>
                
                


                <div *ngIf="false && visualizarProgressaoCapitalizacaoTabela" class=" col-xl-12 col-md-12 py-2">
                    <div class="dataTables_wrapper py-4">
                        <ngx-datatable #tabelaProgressaoSaque class="material striped selection-cell" [columnMode]="'flex'" [headerHeight]="50"
                            [footerHeight]="'auto'" [rowHeight]="'auto'" [rows]="linhasCapitalizacaoMensalSaque"
                            [limit]="limiteTabelaProgressaoCapitalizacao"
                            [messages]="{emptyMessage: 'Nenhum registro encontrado', totalMessage: 'meses' }">

                            <ngx-datatable-column name="Mês" prop="mes" [sortable]="false" [draggable]="false"
                                [flexGrow]="15">
                                <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span>{{value }}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Investimento Acumulado" prop="investimentoAcumulado"
                                [sortable]="false" [draggable]="false" [flexGrow]="30">
                                <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span>{{value | currency}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Taxa Real" prop="taxaReal" [sortable]="false"
                                [draggable]="false" [flexGrow]="15">
                                <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span>{{value * 100 | number : '1.2-2'}} % </span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Rendimento " prop="rendimentoMes" [sortable]="false"
                                [draggable]="false" [flexGrow]="25">
                                <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span>{{value | currency}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Saque no Mês" prop="saque" [sortable]="false"
                                [draggable]="false" [flexGrow]="25">
                                <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span>{{value | currency}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                        </ngx-datatable>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>

<div *ngIf="true" class="row py-5 continuo">
    <div class="col-12">

        <h5 class=" h3 mb-0 linha-quadro">Imposto de Renda como Contribuinte</h5>

        <div class="row">
            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.valorContribuicaoComplementarMensal |
                    currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Valor da Contribuição
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalContributivoComplementarMeses}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Total de Contribuições
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.custoContributivoMensalImpostoRenda |
                    currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Custo mensal de Imposto de Renda
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.deducaoTotalImpostoRendaSaude |
                    currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Dedução de Imposto de Renda - Saúde
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.deducaoTotalImpostoRendaEducacao |
                    currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Dedução de Imposto de Renda - Educação
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 *ngIf="(relatorio.otimizacao.totalSubstituicoesTeto > 0)" class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{( relatorio.otimizacao.totalSubistituicoesTeto > 0 ? (relatorio.otimizacao.custoContributivoTotalImpostoRenda / relatorio.otimizacao.totalSubstituicoesTeto) : 0) | currency}}

                </h1>

                <h1 *ngIf="!(relatorio.otimizacao.totalSubstituicoesTeto > 0)" class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{ (relatorio.otimizacao.totalContributivoComplementarMeses > 0 ? (relatorio.otimizacao.custoContributivoTotalImpostoRenda / relatorio.otimizacao.totalContributivoComplementarMeses) : 0) | currency}}

                </h1>

                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Custo mensal de Imposto de Renda depois das deduções
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.custoContributivoTotalImpostoRenda |
                    currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Custo Total de Imposto de Renda
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalInvestimento +
                    relatorio.otimizacao.custoContributivoTotalImpostoRenda | currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Total Investimento + Custo Imposto de Renda
                </span>

            </div>
        </div>
    </div>

</div>

<div *ngIf="true" class="row py-5 continuo">

    <div class="col-12">

        <h5 class=" h3 mb-0 linha-quadro">Imposto de Renda como Beneficiário</h5>

        <div class="row">
            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.parcelasImpostoRendaSemIsencao65 |
                    number : '1.0-0'}} meses

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Parcelas Imposto de Renda Sem Isenção Até 65 anos
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.impostoRendaSemIsencao65 | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Imposto de Renda Mensal Sem Isenção Até 65 Anos
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalImpostoRendaSemIsencao65 |
                    currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Valor Total em Imposto de Renda Sem Isenção até 65 Anos
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.parcelasImpostoRendaComIsencao65 |
                    number : '1.0-0' }} meses

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Parcelas Imposto de Renda Com Isenção acima 65 anos
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.impostoRendaComIsencao65 | currency }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Imposto de Renda Mensal Com Isenção acima 65 Anos
                </span>

            </div>

            <div class=" col-6 col-xxl-4 pt-5 continuo">

                <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                    {{relatorio.otimizacao.totalImpostoRendaComIsencao65 | currency
                    }}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 text-default">
                    Valor Total em Imposto de Renda Com Isenção acima de 65 Anos
                </span>

            </div>

        </div>

    </div>

</div>