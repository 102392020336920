
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';

@Injectable({
  providedIn: 'root'
})
export class ListartBuracosPeriodoContributivoService {

  constructor(private httpClient: HttpClient) { }

  listarBuracosPeriodoContributivo(cnis:CNIS){
    return Promise.all([this.apiCall(cnis)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(cnis:CNIS){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ListarBuracosPeriodoContributivo',cnis, {'headers':headers}).toPromise();
    return data;
  }
}
