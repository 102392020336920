import { Component, EventEmitter, input, Input, Output, ViewChild } from '@angular/core';
import { CalculoMapaQualidadeSegurado } from 'src/app/modelos/previdenciario/calculomapaqualidadesegurado';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { ListarCalculoMapaQualidadeSeguradoService } from 'src/app/servicos/calculo/previdenciario/listar-calculo-mapa-qualidade-segurado.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { MapaQualidadeSeguradoComponent } from '../mapa-qualidade-segurado/mapa-qualidade-segurado.component';
import { MapaConsolidadoQualidadeSeguradoComponent } from '../mapa-consolidado-qualidade-segurado/mapa-consolidado-qualidade-segurado.component';

@Component({
  selector: 'app-resultado-qualidade-segurado',
  templateUrl: './resultado-qualidade-segurado.component.html',
  styleUrls: ['./resultado-qualidade-segurado.component.scss']
})
export class ResultadoQualidadeSeguradoComponent {

  @Input() visualizarPdf:boolean = true;
  @Input() calculoMapaQualidadeSegurado:CalculoMapaQualidadeSegurado;

  @Output() relatorioProntoEvent = new EventEmitter<CalculoMapaQualidadeSegurado>();

  @ViewChild('mapa') mapa:MapaQualidadeSeguradoComponent;
  @ViewChild('mapaConsolidado') mapaConsolidado:MapaConsolidadoQualidadeSeguradoComponent;

  

  constructor(private configService:ConfigService,
    private listarCalculoMapaQualidadeSeguradoService:ListarCalculoMapaQualidadeSeguradoService){}


  gerarRelatorio(calculoMapaQualidadeSegurado:CalculoMapaQualidadeSegurado){
    
    this.listarCalculoMapaQualidadeSeguradoService.listarCalculo(calculoMapaQualidadeSegurado)
    .then((calculo:CalculoMapaQualidadeSegurado)=>{
      // console.log('gerar relatorio mapa qualidade segurado pronto');
      // console.log(calculo);
      this.calculoMapaQualidadeSegurado = calculo;
      this.relatorioProntoEvent.emit(this.calculoMapaQualidadeSegurado);
    });
    this.configService.setAguarde(false);
    


  }

  carregarDados(calculoMapaQualidadeSegurado:CalculoMapaQualidadeSegurado){

    this.calculoMapaQualidadeSegurado = calculoMapaQualidadeSegurado;

  }
  

}
