import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { GerarCnisService } from 'src/app/servicos/calculo/previdenciario/gerar-cnis.service';
import { MontagemLeituraCnisComponent } from '../montagem-leitura-cnis/montagem-leitura-cnis.component';
import { FormBuilder, Validators } from '@angular/forms';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { IdentificacaoFiliado } from 'src/app/modelos/previdenciario/identificacaofiliado';
import { Sexo } from 'src/app/modelos/previdenciario/sexo';
import { AppComponent } from 'src/app/app.component';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-gerador-cnis',
  templateUrl: './gerador-cnis.component.html',
  styleUrls: ['./gerador-cnis.component.scss']
})
export class GeradorCnisComponent implements OnInit {

  @ViewChild('montagemLeituraCnis') montagemLeituraCnis: MontagemLeituraCnisComponent;
  @Output() gerarLeituraCnisFinalizadaEvent = new EventEmitter<LeituraCNIS>();

  constructor(){}

  leituraCnis:LeituraCNIS;

  ngOnInit(): void {
    
  }

  gerarLeituraCnisMontagemFinalizada(leituraCnis:LeituraCNIS){
    this.leituraCnis = leituraCnis;
    this.gerarLeituraCnisFinalizadaEvent.emit(this.leituraCnis);

  }

  gerarCnis() {

    

    this.montagemLeituraCnis.openModal();
    // console.log(this.leituraCnis);

    // this.gerarCnisService.gerarCnis()
    // .then(leituraCnis=>{
    //   this.leituraCnis = leituraCnis as LeituraCNIS;



    // });

  }
}
