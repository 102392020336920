import { Pipe, PipeTransform } from '@angular/core';
import { StatusAssinatura } from 'src/app/modelos/usuario/statusassinatura';

@Pipe({
  name: 'statusAssinatura',
  
})
export class StatusAssinaturaPipe implements PipeTransform {

  transform(value: StatusAssinatura, ...args: unknown[]): string {

    let resultado:string = 'status';

    switch (value) {
      case StatusAssinatura.ATIVA:
        resultado = "Ativa";
        break;
        case StatusAssinatura.CANCELADA:
        resultado = "Cancelada";
        break;
        case StatusAssinatura.EXPIRADA:
        resultado = "Expirada";
        break;
        case StatusAssinatura.SUSPENSA:
        resultado = "Suspensa";
        break;
        case StatusAssinatura.INADIMPLENTE:
        resultado = "Inadimplente";
        break;
    
      default:
        break;
    }
    return resultado;
  }

}
