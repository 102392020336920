import { Pipe, PipeTransform } from '@angular/core';
import { TipoContribuicao } from 'src/app/modelos/previdenciario/tipocontribuicao';

@Pipe({
  name: 'tipoContribuicao',
})
export class TipoContribuicaoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {

    let texto: string = '';

    // console.log('tipo contribuicao pipe', value)

    switch (value) {
      case 'RECOLHIMENTO':
        texto = "Recolhimento";
        break;
      case 'SALARIO':
        texto = "Salário";
        break;
      case 'REMUNERACAO':
        texto = "Salário";
        break;
      case 'CONTRIBUICAO':
        texto = "Recolhimento";
        break;

      default:
        break;
    }
    return texto;
  }

}
