import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Permissoes } from 'src/app/modelos/usuario/permissoes';
import { StatusAssinatura } from 'src/app/modelos/usuario/statusassinatura';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';



@Component({
  selector: 'app-minha-conta',
  templateUrl: './minha-conta.component.html',
  styleUrls: ['./minha-conta.component.scss']
})
export class MinhaContaComponent implements OnInit {

  filiado: boolean;
  administrador: boolean;
  assinante: boolean;
  usuario: Usuario;

  constructor(private configService: ConfigService) {
    this.configService.getUsuario().subscribe(usuario => {
      
      this.usuario = usuario;

      // console.log('assinatura usuario', this.usuario.assinatura);
      

      // console.log('usuario minha conta', this.usuario);
      switch (this.usuario.permissao) {
        case Permissoes.Administrador:
          this.administrador = true;
          this.filiado = true;
          break;
        case Permissoes.Filiado:
        case Permissoes.Socio:
          this.filiado = true;
          break;

        default:
          break;
      }

      if (this.usuario.assinatura) {

        switch (this.usuario.assinatura.statusAssinatura) {
          case StatusAssinatura.ATIVA:
            // console.log('assinatura ativa');
            this.assinante = true;
            break;
        
          default:
            break;
        }

      }
    });

  }

  ngOnInit(): void {



  }





}
