import { Location } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import Stepper from 'bs-stepper';

import { Entrevista } from 'src/app/modelos/previdenciario/entrevista';
import { Ficha } from 'src/app/modelos/previdenciario/ficha';
import { Pergunta } from 'src/app/modelos/previdenciario/pergunta';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nova-entrevista',
  templateUrl: './nova-entrevista.component.html',
  styleUrl: './nova-entrevista.component.scss'
})
export class NovaEntrevistaComponent {

  entrevista: Entrevista;
  autoSaveEntrevista:boolean;

  private stepper: Stepper;


  constructor(private configService: ConfigService,
    private calculeiApiService: CalculeiApiService,
    private location: Location,
    private changeDetector: ChangeDetectorRef,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private router: Router
  ) { }

  ngAfterViewChecked(): void {
    //ng0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    //erro quando coloca retorno do evento edicaocnisfinalizada no objeto calculo
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.



    if (this.location.getState().hasOwnProperty('entrevista')) {
      this.configService.setAguarde(true);
      this.entrevista = this.location.getState()['entrevista'] as Entrevista;
      // console.log('entrevista state', this.entrevista);
      this.configService.setAguarde(false);
      this.stepper.to(4);


    }
    else {
      this.entrevista = new Entrevista();
      this.entrevista.cliente = new Cliente();
      this.entrevista.nome = "Nova entrevista";
      this.entrevista.validade = new Date((new Date()).setMonth((new Date()).getMonth() + 1));
      this.entrevista.ficha = new Ficha();
      this.entrevista.ficha.perguntas = new Array<Pergunta>();
      this.configService.getUsuario().subscribe(usuario => this.entrevista.usuario = usuario);

      this.autoSaveEntrevista = true;
    }
  }


  ngOnInit(): void {

    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {
      // You can call preventDefault to stop the rendering of your step
      // console.warn('stepper show', event.detail);

      switch (event.detail.to) {
        case 0:
          // console.log('entrar no passo seleção CNIS');
          break;
        case 1:

          break;
        case 2:
          break;
        case 3:
          if(this.autoSaveEntrevista){
            this.salvarEntrevista();
          }
          break;


        default:
          break;
      }

      //controle salvar cnis

      switch (event.detail.from) {
        case 1:

          break;

        default:
          break;
      }
    });

    stepperElement.addEventListener('shown.bs-stepper', (event: CustomEvent) => {
      // console.warn('step shown mostrando', event.detail);

      // console.log('passo ', event.detail.indexStep);
      switch (event.detail.indexStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }

    });



    if (!environment.production) {
      // this.mock();
    };
  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("entrevista.json", request => {
      this.carregarDados(request as Entrevista);
    });
    this.stepper.to(4);
  }

  carregarDados(entrevista: Entrevista) {

    this.entrevista = entrevista;


  }





  gerarPDF() {

  }



  proximoStepper() {
    // console.log('proximo stepper');
    this.stepper.next();
  }
  anteriorStepper() {
    // console.log('anterior stepper');
    this.stepper.previous();
  }

  proximo(event) {
    this.proximoStepper()
  }
  anterior(event) {
    this.anteriorStepper()
  }
  cancelar(event) {
    // console.log('cancelar');
    this.router.navigateByUrl('calculei/home');

  }

  clienteSelecionado(cliente: Cliente) {
    this.entrevista.cliente = cliente;
    this.proximo(true);
  }

  fichaSelecionada(ficha: Ficha) {
    this.entrevista.ficha = ficha;
    this.proximo(true);
  }

  salvarEntrevista() {
    this.configService.setAguarde(true);
    this.calculeiApiService.api("SalvarEntrevista", this.entrevista).subscribe(entrevista => {
      this.configService.setAguarde(false);
      this.entrevista = entrevista as Entrevista;
      Swal.fire({
        title: 'Entrevista pronta...',
        text: 'você já pode copiar e compartilhar o link da entrevista com o seu cliente',
        icon: 'success',
        iconColor: "black",
        showCloseButton: false,
        showConfirmButton: false,
        confirmButtonText: 'ok',
        confirmButtonColor: 'black'

      }).then(() => {
      });
      // console.log('resultado salvar entrevista', entrevista);

    });
  }

}
