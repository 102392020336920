import { RelatorioKertzman } from './../../../../../modelos/previdenciario/relatoriokertzman';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-destaque-kertzman',
  templateUrl: './destaque-kertzman.component.html',
  styleUrls: ['./destaque-kertzman.component.scss']
})
export class DestaqueKertzmanComponent implements OnInit{

  @Input() relatorio:RelatorioKertzman;
  @Input() visualizarPdf:boolean;
  @Output() gerarPdfEvent = new EventEmitter<boolean>();
  
  constructor(private configService:ConfigService, 
    private calculeiApiService:CalculeiApiService, private notificacoesService:NotificacoesService){}


  
  
  ngOnInit(): void {
    
  }


  carregarDados(calculo:CalculoPlanejamentoKertzman){
    
  }

  gerarPdf(){

     // console.log(this.casoMelhor);
    // console.log('gerar pdf kertzman');

    this.gerarPdfEvent.emit(true);

    // this.configService.setAguarde(true);
    // this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.KERTZMAN;
    // this.calculoPlanejamentoKertzman.usuario = Global.usuario;

    // this.casoMelhor.tipoCalculo = CalculoTipos.CASOPLANEJAMENTOKERTZMAN;
    // this.casoMelhor.usuario = Global.usuario;

    // this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.KERTZMAN;

    // let requestClean:CalculoPlanejamentoKertzman = JSON.parse(JSON.stringify(this.calculoPlanejamentoKertzman));
    // requestClean.simulacoes = [];

    // this.calculeiApiService.apiBlobLx("ListarPDF", this.calculoPlanejamentoKertzman)
    //   .subscribe(blob => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = 'RELATORIO-PLANEJAMENTO-CONTRIBUTIVO-KERTZMAN-' + this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);

    //     this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
    //     this.configService.setAguarde(false);



    //   });
  }

}
