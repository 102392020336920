<div *ngIf="caso">
    <div class="row p-2">
        <div class="col-md-6">
            <!-- <img src={{logoUrl}} width="100px"> -->
        </div>

        <div class="col-md-6 text-right">
            <p class="font-weight-bold mb-1 h1">Relatório de Simulação de Renda Mensal Inicial
            </p>
            <p class="mb-1 h2">Cliente {{caso.beneficio.cnis.identificacaoFiliado.nome}}</p>
        </div>
    </div>

    <hr class="my-5">

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1 text-default">Relatório de Simulação de Renda Mensal Inicial</span>
        </div>
    </div>

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-2 text-default">Dados da Simulação</span>
        </div>
    </div>

    <div class="row pt-2 pb-6 p-2">
        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Cliente</p>
            <p class="mb-1 h1"><span class="text-muted h1">Nome </span>
                {{caso.beneficio.cnis.identificacaoFiliado.nome}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">CPF </span>
                {{caso.beneficio.cnis.identificacaoFiliado.cpf}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Sexo </span>
                {{caso.beneficio.cnis.identificacaoFiliado.sexo}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Data Nascimento </span>
                {{caso.beneficio.cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}
            </p>

            <p class="mb-1 h1"><span class="text-muted h1">Idade </span>
                {{caso.beneficio.cnis.identificacaoFiliado.idade.porExtenso}}</p>

        </div>

        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Resumo da Simulação</p>

            <div class>

                <span class="h1 text-default">
                    {{caso.beneficio.qualificacao}} - {{caso.beneficio.qualificacaoDetalhada}}
                </span>

            </div>

            <div *ngIf="caso.totalContributivoComplementarMeses > 0" class=" ">
                <span class="h2 text-default">
                    {{caso.totalContributivoComplementarMeses}} contribuições de
                    {{caso.valorContribuicaoComplementarMensal | currency}} para cumprimento de carência / tempo de contribuição
                </span>
            </div>
            <div *ngFor="let contribuicaoEspecifica of caso.contribuicoesEspecificas" class>
                <span class="h2 text-default">
                    {{contribuicaoEspecifica.quantidade}} contribuições de
                    {{contribuicaoEspecifica.edicaoSalarioAliquota.salario *
                    contribuicaoEspecifica.edicaoSalarioAliquota.aliquota | currency}} específicas das opcões de simulação
                </span>
            </div>
            <div *ngIf="caso.totalContributivoComplementarMeses <= 0" class>
                <span class="h2 text-default">
                    0 contribuições. Carência e Tempo de Contribuição cumpridos.
                </span>
            </div>
            <div *ngIf="caso.totalContributivoQualidadeSeguradoMeses > 0" class=" ">
                <span class="h2 text-default">
                    {{caso.totalContributivoQualidadeSeguradoMeses}} contribuições de
                    {{caso.valorContribuicaoQualidadeSegurado | currency}} para manutenção da qualidade
                    de segurado
                </span>
            </div>
            <div class="pt-3">
                <span class="h3 text-default">
                    Total do Investimento {{caso.totalInvestimento | currency}}
                </span>
            </div>

            <div class>
                <span class="h3 text-default">
                    Lucro líquido {{caso.retornoTotalInvestimentoRoi | currency}}
                </span>
            </div>

        </div>

    </div>

    <div class="row">
        <div class="col-12">

            <app-caso-planejamento-previdenciario [caso]="caso" [visualizarProgressaoCapitalizacaoTabela]="false"></app-caso-planejamento-previdenciario>

            <app-relatorio-beneficio [beneficio]="caso.beneficio"></app-relatorio-beneficio>

        </div>
    </div>

</div>