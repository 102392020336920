import {Pipe, PipeTransform} from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { OpcoesImpostoRendaPlanejamentoEx } from 'src/app/modelos/previdenciario/opcoesimpostorendaplanejamento';

@Pipe({name:'opcoesimpostorendaplanejamento'})
export class OpcoesImpostRendaPlanejamentoPipe implements PipeTransform{

    transform(value: any, ...args: any[]): string {
        return OpcoesImpostoRendaPlanejamentoEx.toString(value);
    }

}