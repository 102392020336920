import { LiquidacaoSentencaPrevidenciario } from "./liquidacaosentencaprevidenciario";
import { ParcelaLiquidacaoSentencaPrevidenciario } from "./parcelaliquidacaosentencaprevidenciario";

export class RelatorioLiquidacaoSentencaoPrevidenciario {
    liquidacaoSentenca:LiquidacaoSentencaPrevidenciario;
    parcelas:ParcelaLiquidacaoSentencaPrevidenciario[];
    totalAtrasadosValor:Number;
    totalAtrasadosMeses:Number; 
    exercicioCorrenteMeses:Number;
    exercicioCorrenteValor:Number;
    exerciciosAnterioresMeses:Number;
    exerciciosAnterioresValor:Number;
    total:Number;
    honorarios:Number;
    ApresentarAcordo:boolean;
    porcentagemAcordo:number;
    totalAcordo:number;
    totalAcordo90:number;
    totalAcordo95:number;
    textoJuros:string;
    textoIndexadores:string;
    decimoTerceiroSemAntecipacao:boolean;
    honorariosSucumbencia:number;
    honorariosContratuais:number;

}