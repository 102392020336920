<div *ngIf="calculoAnaliseCnis && calculoAnaliseCnis.relatorioAnaliseCnis">
    <div class="pb-2 continuo">
        <app-dados-segurado [cnis]="calculoAnaliseCnis.calculoBeneficios.leituraCnis.cnis" [anonimo]="usuario.configuracao.anonimo"></app-dados-segurado>
    </div>
    <div class="py-2 continuo">
        <app-evolucao-salarios-pos-real [beneficio]="calculoAnaliseCnis.relatorioAnaliseCnis.beneficioBase"></app-evolucao-salarios-pos-real>
    </div>
    <div class="py-2 continuo">
        <app-buraco-tempo-contribuicao [calculoBeneficios]="calculoAnaliseCnis.calculoBeneficios"></app-buraco-tempo-contribuicao>
    </div>
    <div class="pb-2 continuo">
        <app-validacoes-pontos-atencao *ngIf="calculoAnaliseCnis && calculoAnaliseCnis.calculoBeneficios" [calculoBeneficios]="calculoAnaliseCnis.calculoBeneficios"></app-validacoes-pontos-atencao>
    </div>
    

    <div class="pb-2 continuo">
        <app-validacao-tempo-contribuicao-carencia *ngIf="calculoAnaliseCnis && calculoAnaliseCnis.calculoBeneficios" [calculoBeneficios]="calculoAnaliseCnis.calculoBeneficios"></app-validacao-tempo-contribuicao-carencia>
    </div>

    <div class="pb-2 continuo">
        <app-canvas-indicadores *ngIf="calculoAnaliseCnis && calculoAnaliseCnis.calculoBeneficios" [calculoBeneficios]="calculoAnaliseCnis.calculoBeneficios"></app-canvas-indicadores>
    </div>

    

    
</div>