import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResultadoAnaliseCnisComponent } from 'src/app/aplicacao/calculos/previdenciario/compartilhado/resultado-analise-cnis/resultado-analise-cnis.component';
import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';
import { CalculoAnaliseCnis } from 'src/app/modelos/previdenciario/calculoanalisecnis';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { LeituraJsonBlobService } from 'src/app/servicos/relatorio/leitura-json-blob.service';

@Component({
  selector: 'app-relatorio-liquidacao-sentenca',
  templateUrl: './relatorio-liquidacao-sentenca.component.html',
  styleUrl: './relatorio-liquidacao-sentenca.component.scss'
})
export class RelatorioLiquidacaoSentencaComponent {

  // @ViewChild('relatorioLiquidacao') res:ResultadoAnaliseCnisComponent;
  calculo: CalculoLiquidacaoSentencaPrevidenciario;
  logoUrl: string;


  calculoRowKey: string;
  calculoPartitionKey: string;
  calculoUrl: string;

  resultadoCalculo: ResultadoCalculo;


  constructor(private route: ActivatedRoute,
    private leituraJsonBlobService: LeituraJsonBlobService,) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {


      this.calculoUrl = params.url;
      this.calculoRowKey = params.rowkey;
      this.calculoPartitionKey = params.partitionkey;

      let resultadoCalculo: ResultadoCalculo = new ResultadoCalculo();

      resultadoCalculo.rowKey = this.calculoRowKey;
      resultadoCalculo.partitionKey = this.calculoPartitionKey;


      let calculo: Calculo = new Calculo();
      calculo.rowKey = this.calculoRowKey;
      calculo.partitionKey = this.calculoPartitionKey;
      calculo.tipo = CalculoTipos.LIQUIDACAOSENTENCAPREVIDENCIARIO;


      this.leituraJsonBlobService.ListarJsonBlob(this.calculoUrl, request => {

        let casoResultado:CalculoLiquidacaoSentencaPrevidenciario = request as CalculoLiquidacaoSentencaPrevidenciario;
        // console.log('relatorio liquidacao sentenca',casoResultado);
        this.carregarDados(request as CalculoLiquidacaoSentencaPrevidenciario);
      });



    });
  }

  carregarDados(request: CalculoLiquidacaoSentencaPrevidenciario) {
    this.calculo = request;

    // this.resultadoAnaliseCnis.carregarDados(this.calculo);
  }

}
