import { Component, ElementRef, EventEmitter, Input, input, Output, ViewChild } from '@angular/core';
import { Marcador } from 'src/app/modelos/common/marcador';
import { TiposMarcador } from 'src/app/modelos/common/tiposmarcador';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';

@Component({
  selector: 'app-add-marcador',
  templateUrl: './add-marcador.component.html',
  styleUrl: './add-marcador.component.scss'
})
export class AddMarcadorComponent {

  @Input() texto: string;
  @Output() addMarcadorEvent = new EventEmitter<Marcador>();

  @ViewChild("inputTitulo") inputTitulo: ElementRef;
  @ViewChild("inputCor") inputCor: ElementRef;
  @ViewChild("dropdown") dropdown: ElementRef;
  @ViewChild("dropdownMenu") dropdownMenu: ElementRef;

  constructor() { }

  addMarcador() {
    let marcador: Marcador = new Marcador();
    marcador.cor = this.inputCor.nativeElement.value;
    marcador.nome = this.inputTitulo.nativeElement.value;
    marcador.tipoMarcador = TiposMarcador.MARCADOR;

    // console.log('novo marcador', marcador);
    this.addMarcadorEvent.emit(marcador);
    // this.fecharAddMarcador();

  }

  abrirAddMarcador() {
    // this.dropdown.nativeElement.classList.add('show');
    this.dropdownMenu.nativeElement.classList.add('show');
  }
  fecharAddMarcador() {
    this.dropdownMenu.nativeElement.classList.remove('show');
  }


}
