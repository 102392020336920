


<div class="row">
    <div class="col-12">
      <h5 class=" h3 mb-0 linha-quadro">Análise Multi-Qualitativa dos Cenários</h5>
    </div>
  
    <div  class="col-12 d-flex flex-row-reverse">
      <i class="bi-filetype-pdf h1 mb-0 clicavel" ></i>
    </div>
  </div>
  <div class="row py-4" >
          
    <!-- Chart wrapper -->
    <div class="col-lg-12" style="height: 600px;">
      <canvas id="chartRadarMatriz"></canvas>
    </div>
  </div>

  <div class="row py-4" >
          
    <!-- Chart wrapper -->
    <div class="col-lg-12" style="height: 600px;">
      <canvas id="chartBarraMatriz"></canvas>
    </div>
  </div>
  
  
  