import { Component, Input, OnInit } from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';

@Component({
  selector: 'app-quadro-carencia-planejamento',
  templateUrl: './quadro-carencia-planejamento.component.html',
  styleUrls: ['./quadro-carencia-planejamento.component.scss']
})
export class QuadroCarenciaPlanejamentoComponent implements OnInit {

  @Input() beneficio:Beneficio;

  

  constructor(){}

  ngOnInit(): void {
    
  }

}
