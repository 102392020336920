<ngx-datatable
        
        [rows]="rows"
        columnMode="force"
        [headerHeight]="0"
        [rowHeight]="200"
      >
    <ngx-datatable-column name="Origem" prop="origemVinculo" >
        <ng-template  ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            
            <div class="row caixa">
                <div class="col-md-12">
                    <div class="row">

                        <h1 class="display-4">{{relacoesPrevidenciarias[rowIndex].origemVinculo}}</h1>
                        
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <span class="lead">{{relacoesPrevidenciarias[rowIndex].tempoContribuicao}}</span>
                            </div>
                            <div class="row">
                                Tempo de contribuição
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <span class="lead">{{relacoesPrevidenciarias[rowIndex].tempoCalculado}}</span>
                                
                            </div>
                            <div class="row">
                                Tempo de calculado
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <span class="lead">{{relacoesPrevidenciarias[rowIndex].periodo}}</span>
                                
                            </div>
                            <div class="row">
                                Período
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
           
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="true">
        <ng-template ngx-datatable-footer-template></ng-template>
    </ngx-datatable-footer>
    
</ngx-datatable>