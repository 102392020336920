import {Pipe, PipeTransform} from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { PeriodoPlano, PeriodoPlanoEx } from 'src/app/modelos/usuario/periodoplano';

@Pipe({name:'periodo'})
export class PeriodoPipe implements PipeTransform{

    transform(value: any, ...args: any[]): string {
        
        
        
        return PeriodoPlanoEx.periodoToString(value);
    }

}