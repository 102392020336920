import { Component, OnInit } from '@angular/core';
import { Voucher } from 'src/app/modelos/common/voucher';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-listagem-voucher',
  templateUrl: './listagem-voucher.component.html',
  styleUrl: './listagem-voucher.component.scss'
})
export class ListagemVoucherComponent implements OnInit {

  listaVouchers: Voucher[];

  constructor(private configService: ConfigService,
    private calculeiApiService: CalculeiApiService) {

  }
  ngOnInit(): void {
    this.carregarDados();

  }
  carregarDados() {
    let usuarioRequest: Usuario;
    this.configService.getUsuario().subscribe(usuario => usuarioRequest = usuario)
    this.calculeiApiService.api("ListarVouchers", usuarioRequest)
      .subscribe(resultado => {
        // console.log('listagem vouchers', resultado);
        this.listaVouchers = resultado as Voucher[];
      });
  }

  excluirVoucher(voucher:Voucher){
    // console.log('excluir voucher', voucher);

    this.calculeiApiService.api("ExcluirVoucher", voucher)
    .subscribe(resultado=>{
      this.carregarDados();
    });
  }

}
