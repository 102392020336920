import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { environment } from 'src/environments/environment';
import { RelatorioCalculoRemocaoTusdBaseIcms } from 'src/app/modelos/relatorios/relatoriocalculoremocaotusdbaseicms';
import { RelatorioLiquidacaoSentencaoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioliquidacaosentencaprevidenciario';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';


@Injectable({
  providedIn: 'root'
})
export class ListarPDFService {

  constructor(private httpClient:HttpClient) { }


  ListarPDF(resultadoCalculo:ResultadoCalculo) :Observable<Blob>{

    const headers = { 'Access-Control-Allow-Origin': '*'};
    return this.httpClient.post(environment.api as string + 'api/ListarPDF', resultadoCalculo, {responseType: 'blob', headers:headers});
  }
 
}
