import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Entrevista } from 'src/app/modelos/previdenciario/entrevista';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-sala',
  templateUrl: './sala.component.html',
  styleUrl: './sala.component.scss'
})
export class SalaComponent implements OnInit{

  
  entrevista:Entrevista;

  constructor(private route: ActivatedRoute, private configService:ConfigService,
    private calculeiApiService:CalculeiApiService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.entrevista = new Entrevista();
      this.entrevista.id = params.id;
      this.calculeiApiService.apiPaginas("AbrirEntrevista",this.entrevista)
      .subscribe(entrevista=>{
        this.entrevista = entrevista as Entrevista;
      });
      
    });
  }

}
