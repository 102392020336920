import { Pipe, PipeTransform } from '@angular/core';
import { GruposIndicadoresCNIS } from 'src/app/modelos/previdenciario/gruposindicadorescnis';

@Pipe({
  name: 'grupoIndicador',
})
export class GrupoIndicadorPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {

    let texto: string = '';

    switch (GruposIndicadoresCNIS[value]) {
      case "AJUSTESEC103OUTROSINDICADORES":
        texto = "AJUSTES EC103 - OUTROS INDICADORES";
        break;
      case "CONTRIBUICOES":
        texto = "CONTRIBUIÇÕES";
        break;
      case "CONTRIBUICOESVINCULOSREMUNERACOES":
        texto = "CONTRIBUIÇÕES/VÍNCULOS E REMUNERAÇÕES";
        break;

      case "GERAISNITDADOSCADASTRAIS":
        texto = "GERAIS DO NIT OU DE DADOS CADASTRAIS";
        break;

      case "SEGURADOESPECIAL":
        texto = "SEGURADO ESPECIAL";
        break;
      case "VINCULOSREMUNERACOES":
        texto = "VÍNCULOS E REMUNERAÇÕES";
        break;
      case "AJUSTESEC103AGRUPAMENTO":
        texto = "AJUSTES EC103 - AGRUPAMENTO";
        break;
      case "AJUSTESEC103COMPLEMENTACAO":
        texto = "AJUSTES EC103 - COMPLEMENTAÇÃO";
        break;
      case "AJUSTESEC103UTILIZACAO":
        texto = "AJUSTES EC103 - UTILIZAÇÃO";
        break;
      case "DARFEVENTOS":
        texto = "DARF - EVENTOS";
        break;
      case "DARFERROSPROCESSAMENTO":
        texto = "DARF - ERROS DE PROCESSAMENTO";
        break;

      default:
        break;
    }
    return texto;
  }

}

