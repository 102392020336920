import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';

import { RegraCorrecaoMonetaria } from 'src/app/modelos/correcaomonetaria/regracorrecaomonetaria';

import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import { OpcoesProtocoloCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/opcoesprotocoloscorrecaomonetariaprevidenciario';
import { ProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/processoliquidacaosentencaprevidenciario';
import { ProtocolosCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocoloscorrecaomonetariaprevidenciario';
import { ProtocoloCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocolocorrecaomonetariaprevidenciario'
import { ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-correcao-monetaria-previdenciario.service';
import { ListarOpcoesIndicesCorrecaoMonetariaService } from 'src/app/servicos/calculo/common/listar-opcoes-indices-correcao-monetaria.service';
import { OpcoesIndicesCorrecaoMonetaria } from 'src/app/modelos/common/opcoesindicescorrecaomonetaria';
import { IdentificadorIndice } from 'src/app/modelos/previdenciario/identificadorindice';
import { OpcaoIndice } from 'src/app/modelos/common/opcaoindice';
import { ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-aplicacao-juros-previdenciario.service';
import { OpcoesProtocolosAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/opcoesprotocolosaplicacaojurosprevidenciario';
import { ProtocoloAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/protocoloaplicacaojurosprevidenciario';
import { ProtocolosAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/protocolosaplicacaojurosprevidenciario';
import { RegraAplicacaoJuros } from "src/app/modelos/common/regraaplicacaojuros";
import * as currency from 'currency.js';
import { ValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/valorcausaprevidenciario';
import { AtualizacaoValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/atualizacaovalorcausaprevidenciario';
import { CalculoValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/calculovalorcausaprevidenciario';
import { AppComponent } from 'src/app/app.component';
import { DatePipe } from '@angular/common';
import { NgxSelectOption } from 'ngx-select-ex';


@Component({
  selector: 'app-atualizacao-valor-causa-previdenciario',
  templateUrl: './atualizacao-valor-causa-previdenciario.component.html',
  styleUrls: ['./atualizacao-valor-causa-previdenciario.component.scss']
})
export class AtualizacaoValorCausaPrevidenciarioComponent implements OnInit {

  @Output() continuarEvent = new EventEmitter<CalculoValorCausaPrevidenciario>();
  @Input() calculoValorCausa: CalculoValorCausaPrevidenciario;





  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  currencyInputMask = createMask(AppComponent.currencyInputOptions);

  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0'
  });

  processoForm = new UntypedFormGroup({
    protocoloCorrecaoMonetaria: new UntypedFormControl('', Validators.required),
    protocoloAplicacaoJuros: new UntypedFormControl('', Validators.required),
  });


  itemsSelectProtocoloCorrecao: any[];
  itemCorrecaoDefault: any;
  itemsSelectProtocoloJuros: any[];
  itemJurosDefault: any;




  linhasRegras: RegraCorrecaoMonetaria[] = [];
  regras: RegraCorrecaoMonetaria[] = [];

  

  opcoesCorrecaoMonetariaPrevidenciario: OpcoesProtocoloCorrecaoMonetariaPrevidenciario;
  protocolosCorrecaoMonetariaPrevidenciario: ProtocoloCorrecaoMonetariaPrevidenciario[] = [];

  opcoesAplicacaoJurosPrevidenciario: OpcoesProtocolosAplicacaoJurosPrevidenciario;
  

  protocoloCorrecaoSelecionado:ProtocolosCorrecaoMonetariaPrevidenciario;

  novaRegraCorrecaoVisivel: boolean;
  

  dataCitacao: Date;
  dib: Date;
  dataCalculo: Date;









  novaRegraForm = new UntypedFormGroup({

    dataInicial: new UntypedFormControl('', Validators.required),
    dataFinal: new UntypedFormControl('', Validators.required),
    indice: new UntypedFormControl('', Validators.required)
  });

  novaRegraFormVisivel: boolean = false;



  

  opcoesIndicesCorrecaoMonetaria: OpcoesIndicesCorrecaoMonetaria;



  constructor(private listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario: ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService,
    private listarOpcoesIndicesCorrecaoMonetariaService: ListarOpcoesIndicesCorrecaoMonetariaService,
    private datePipe: DatePipe, private changeDetector: ChangeDetectorRef) { }

  ngAfterViewInit(): void {

  }

  ngAfterViewChecked(): void {


    this.changeDetector.detectChanges();

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);

  }

  ngOnInit(): void {
    
    this.dataCalculo = this.calculoValorCausa.valorCausa.referencia.dataCalculo;
    this.dib = this.calculoValorCausa.valorCausa.beneficio.dib;


    this.listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario.listarOpcoes()
      .then((opcoes: OpcoesProtocoloCorrecaoMonetariaPrevidenciario) => {

        this.opcoesCorrecaoMonetariaPrevidenciario = opcoes;
        this.protocolosCorrecaoMonetariaPrevidenciario = this.opcoesCorrecaoMonetariaPrevidenciario.protocolos;

        this.itemsSelectProtocoloCorrecao = this.protocolosCorrecaoMonetariaPrevidenciario.map((protocolo) => ({ value: ProtocolosCorrecaoMonetariaPrevidenciario[protocolo.protocolo], text: protocolo.texto }));



        if (typeof this.calculoValorCausa.valorCausa.atualizacao.protocoloCorrecaoMonetaria != 'undefined') {

          this.itemCorrecaoDefault = this.itemsSelectProtocoloCorrecao.filter(protocolo => protocolo.value === ProtocolosCorrecaoMonetariaPrevidenciario[this.calculoValorCausa.valorCausa.atualizacao.protocoloCorrecaoMonetaria])[0];

          if(this.itemCorrecaoDefault == undefined){
            this.itemCorrecaoDefault = this.itemsSelectProtocoloCorrecao.filter(protocolo => protocolo.value === this.calculoValorCausa.valorCausa.atualizacao.protocoloCorrecaoMonetaria)[0];
          }
          this.protocoloCorrecaoSelecionado = ProtocolosCorrecaoMonetariaPrevidenciario[ProtocolosCorrecaoMonetariaPrevidenciario[this.calculoValorCausa.valorCausa.atualizacao.protocoloCorrecaoMonetaria]];

          if(this.itemCorrecaoDefault!= undefined){
            this.processoForm.get('protocoloCorrecaoMonetaria').setValue(this.itemCorrecaoDefault.text);

          }
          
          this.regras = this.calculoValorCausa.valorCausa.atualizacao.regrasCorrecaoMonetaria.filter(regra => new Date(regra.dataFinal) > new Date(this.calculoValorCausa.valorCausa.beneficio.dib));   //this.protocolosCorrecaoMonetariaPrevidenciario[this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloCorrecaoMonetaria].regras.filter(regra=> new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));
          this.updateTabelaCorrecaoMonetaria();


        } else {

          this.itemCorrecaoDefault = this.itemsSelectProtocoloCorrecao.filter(protocolo => protocolo.value === ProtocolosCorrecaoMonetariaPrevidenciario.MANUALCALCULOJUSTICAFEDERAL.toString())[0];
          this.processoForm.get('protocoloCorrecaoMonetaria').setValue(this.itemsSelectProtocoloCorrecao[ProtocolosCorrecaoMonetariaPrevidenciario.MANUALCALCULOJUSTICAFEDERAL].text);
          this.regras = this.protocolosCorrecaoMonetariaPrevidenciario[ProtocolosCorrecaoMonetariaPrevidenciario.MANUALCALCULOJUSTICAFEDERAL].regras.filter(regra => new Date(regra.dataFinal) > new Date(this.calculoValorCausa.valorCausa.beneficio.dib));
          this.updateTabelaCorrecaoMonetaria();


        }
      });

  }

  updateTabelaCorrecaoMonetaria() {
    this.linhasRegras = this.regras.map((prop, key) => {
      return {
        ...prop,
        id: key
      };
    });
  }

 


  toIso(value: Date) {
    return new Date(value).toISOString();

  }

  adicionarNovaRegraCorrecao(regra: RegraCorrecaoMonetaria) {


    // regra.dataInicial = new Date(regra.dataInicial);
    // regra.dataInicial = new Date(new Date(regra.dataInicial).toISOString());

    this.regras.push(regra);
    this.updateTabelaCorrecaoMonetaria();
  }


  submitNovaRegra() {

    if (!this.novaRegraForm.valid) return;

    let nova = new RegraCorrecaoMonetaria();
    nova.dataInicial = this.novaRegraForm.get('dataInicial').value;
    nova.dataFinal = this.novaRegraForm.get('dataFinal').value;
    nova.indice = this.opcoesIndicesCorrecaoMonetaria.opcoesIndices[Number.parseInt(this.novaRegraForm.get('indice').value)].indice;


    this.regras.push(nova);
    this.updateTabelaCorrecaoMonetaria();

    this.novaRegraFormVisivel = false;


  }

  novaRegra() {

    this.novaRegraFormVisivel = true;
  }

  selectItemCorrecao(selecao) {

    // this.regras = this.protocolosCorrecaoMonetariaPrevidenciario[Number.parseInt(option.value)].regras.filter(regra=> new Date(regra.dataFinal) > new Date(this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib));
    this.updateTabelaCorrecaoMonetaria();
  }

  selectItemCorrecaoChanges(selecao: NgxSelectOption[]) {


    this.protocoloCorrecaoSelecionado = ProtocolosCorrecaoMonetariaPrevidenciario[ProtocolosCorrecaoMonetariaPrevidenciario[selecao[0].data.value]];
    this.processoForm.get('protocoloCorrecaoMonetaria').setValue(selecao[0].data.value);
    this.regras = this.protocolosCorrecaoMonetariaPrevidenciario.filter(protocolo => ProtocolosCorrecaoMonetariaPrevidenciario[protocolo.protocolo] === selecao[0].data.value)[0].regras.filter(regra => new Date(regra.dataFinal) > new Date(this.dib));
    this.updateTabelaCorrecaoMonetaria();

    this.novaRegraCorrecaoVisivel = selecao[0].data.value === ProtocolosCorrecaoMonetariaPrevidenciario[ProtocolosCorrecaoMonetariaPrevidenciario.CRITERIOESPECIFICO] ? true : false;
  }



  continuar() {

    let atualizacaoValorCausaPrevidenciario = new AtualizacaoValorCausaPrevidenciario();
    atualizacaoValorCausaPrevidenciario.protocoloCorrecaoMonetaria = this.protocoloCorrecaoSelecionado;

    atualizacaoValorCausaPrevidenciario.regrasCorrecaoMonetaria = this.regras;


    this.calculoValorCausa.valorCausa.atualizacao = atualizacaoValorCausaPrevidenciario;

    this.continuarEvent.emit(this.calculoValorCausa);


  }

}
