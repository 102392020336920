
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { RegraCorrecaoMonetaria } from 'src/app/modelos/correcaomonetaria/regracorrecaomonetaria';
import { Global } from 'src/app/modelos/global';
import { CalculoValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/calculovalorcausaprevidenciario';
import { IdentificadorIndice } from 'src/app/modelos/previdenciario/identificadorindice';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import { OpcoesProtocolosAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/opcoesprotocolosaplicacaojurosprevidenciario';
import { OpcoesProtocoloCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/opcoesprotocoloscorrecaomonetariaprevidenciario';
import { ParcelaLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/parcelaliquidacaosentencaprevidenciario';
import { ParcelaValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/parcelavalorcausaprevidenciario';
import { ProtocoloAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/protocoloaplicacaojurosprevidenciario';
import { ProtocoloCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocolocorrecaomonetariaprevidenciario';
import { ProtocolosCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocoloscorrecaomonetariaprevidenciario';

import { ReferenciaLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/referencialiquidacaosentencaprevidenciario';
import { RelatorioLiquidacaoSentencaoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioliquidacaosentencaprevidenciario';
import { RelatorioValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/relatoriovalorcausaprevidenciario';
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';
import { TipoBeneficioLiquidacaoSentencaPrevidenciarioExtensions } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciarioextensions';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { ValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/valorcausaprevidenciario';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-aplicacao-juros-previdenciario.service';
import { ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-correcao-monetaria-previdenciario.service';
import { ListarRelatorioLiquidacaoSentencaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-relatorio-liquidacao-sentenca-previdenciario.service';
import { ListarRelatorioValorCausaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-relatorio-valor-causa-previdenciario.service';
import { ListarRelatorioLiquidacaoSentencaPrevidenciarioPdfService } from 'src/app/servicos/relatorio/listar-relatorio-liquidacao-sentenca-previdenciario-pdf.service';
import { ListarRelatorioValorCausaPrevidenciarioPdfService } from 'src/app/servicos/relatorio/listar-relatorio-valor-causa-previdenciario-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { ListarClienteService } from 'src/app/servicos/usuario/listar-cliente.service';

@Component({
  selector: 'app-relatorio-valor-causa-previdenciario',
  templateUrl: './relatorio-valor-causa-previdenciario.component.html',
  styleUrls: ['./relatorio-valor-causa-previdenciario.component.scss']
})
export class RelatorioValorCausaPrevidenciarioComponent implements OnInit {

  @Output() continuarEvent = new EventEmitter<CalculoValorCausaPrevidenciario>();
  @Output() processandoRelatorioEvent = new EventEmitter<boolean>();
  @Input() calculoValorCausa: CalculoValorCausaPrevidenciario;



  protocolosCorrecaoMonetariaType = ProtocolosCorrecaoMonetariaPrevidenciario;
  protocoloCorrecaoMonetariaTexto: string;
  protocolosCorrecao: ProtocoloCorrecaoMonetariaPrevidenciario[];


  protocoloAplicacaoJurosTexto: string;
  protocolosAplicacaoJuros: ProtocoloAplicacaoJurosPrevidenciario[];

  identificadorIndiceType: IdentificadorIndice;


  tiposProcessoType: TiposProcessoLiquidacaoSentencaPrevidenciario;
  tipoProcessoTexto: string = '';
  tipoBeneficioTexto: string;


  regrasIndexadores: RegraCorrecaoMonetaria[];


  protocoloCorrecao: ProtocoloCorrecaoMonetariaPrevidenciario;

  rows: ParcelaValorCausaPrevidenciario[];
  columns: any[];
  timeout: any = {};

  selected: any[] = [];
  temp = [];
  activeRow: any;


  aguardandoPdf: boolean = false;


  constructor(private listarRelatorioValorCausaPrevidenciarioService: ListarRelatorioValorCausaPrevidenciarioService,
    private listarRelatorioValorCausaPrevidenciarioPdfService: ListarRelatorioValorCausaPrevidenciarioPdfService,
    private notificacoesService: NotificacoesService,
    private listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario: ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService,
    private listarOpcoesProtocolosAplicacaoJurosPrevidenciario: ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService) {

  }

  ngOnInit(): void {

    this.processandoRelatorioEvent.emit(true);

    this.listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario.listarOpcoes()
      .then(opcoes => {
        this.protocolosCorrecao = (opcoes as OpcoesProtocoloCorrecaoMonetariaPrevidenciario).protocolos;

        this.protocoloCorrecao = this.protocolosCorrecao.filter(protocolo => protocolo.protocolo == ProtocolosCorrecaoMonetariaPrevidenciario[this.calculoValorCausa.valorCausa.atualizacao.protocoloCorrecaoMonetaria.toString()])[0];

        if (this.protocoloCorrecao == undefined) {

          this.protocoloCorrecao = this.protocolosCorrecao.filter(protocolo => protocolo.protocolo == ProtocolosCorrecaoMonetariaPrevidenciario[ProtocolosCorrecaoMonetariaPrevidenciario[this.calculoValorCausa.valorCausa.atualizacao.protocoloCorrecaoMonetaria]])[0];

        }
        this.protocoloCorrecaoMonetariaTexto = this.protocoloCorrecao.texto;


        this.regrasIndexadores = this.protocoloCorrecao.regras.filter(regra => new Date(regra.dataFinal) >= this.calculoValorCausa.valorCausa.beneficio.dib)
          .map(regra => {
            let transformacaoRegra = new RegraCorrecaoMonetaria();
            transformacaoRegra.dataInicial = new Date(regra.dataInicial) < new Date(this.calculoValorCausa.valorCausa.beneficio.dib) ? this.calculoValorCausa.valorCausa.beneficio.dib : regra.dataInicial;
            transformacaoRegra.dataFinal = new Date(regra.dataFinal) > new Date(this.calculoValorCausa.valorCausa.referencia.dataCalculo) ? this.calculoValorCausa.valorCausa.referencia.dataCalculo : regra.dataFinal;
            transformacaoRegra.indice = regra.indice;
            return transformacaoRegra;
          });

        if (this.regrasIndexadores.length == 0) {
          this.regrasIndexadores = this.protocoloCorrecao.regras.filter(regra => new Date(regra.dataFinal) >= new Date(this.calculoValorCausa.valorCausa.beneficio.dib))
            .map(regra => {
              let transformacaoRegra = new RegraCorrecaoMonetaria();
              transformacaoRegra.dataInicial = new Date(regra.dataInicial) < new Date(this.calculoValorCausa.valorCausa.beneficio.dib) ? this.calculoValorCausa.valorCausa.beneficio.dib : regra.dataInicial;
              transformacaoRegra.dataFinal = new Date(regra.dataFinal) > new Date(this.calculoValorCausa.valorCausa.referencia.dataCalculo) ? this.calculoValorCausa.valorCausa.referencia.dataCalculo : regra.dataFinal;
              transformacaoRegra.indice = regra.indice;
              return transformacaoRegra;
            });
        }

      });


    if (this.calculoValorCausa.relatorioValorCausa.parcelas == undefined) {
      this.listarRelatorioValorCausaPrevidenciarioService.relatorio(this.calculoValorCausa)
        .then((relatorio: RelatorioValorCausaPrevidenciario) => {

          this.calculoValorCausa.relatorioValorCausa = relatorio;
          this.popularRelatorio(relatorio);
        });
    }
    else {
      this.popularRelatorio(this.calculoValorCausa.relatorioValorCausa);

    }

    this.tipoBeneficioTexto = TipoBeneficioLiquidacaoSentencaPrevidenciarioExtensions.toString(TipoBeneficioLiquidacaoSentencaPrevidenciario[TipoBeneficioLiquidacaoSentencaPrevidenciario[Object.values(TipoBeneficioLiquidacaoSentencaPrevidenciario).indexOf(this.calculoValorCausa.valorCausa.beneficio.tipoBeneficio)]]);

  }

  popularRelatorio(relatorio: RelatorioValorCausaPrevidenciario) {


    this.calculoValorCausa.relatorioValorCausa = relatorio;

    switch (TiposProcessoLiquidacaoSentencaPrevidenciario[TiposProcessoLiquidacaoSentencaPrevidenciario[this.calculoValorCausa.valorCausa.referencia.tipoProcesso]]) {
      case TiposProcessoLiquidacaoSentencaPrevidenciario.CONCESSAO:
        this.tipoProcessoTexto = 'Concessão';
        break;
      case TiposProcessoLiquidacaoSentencaPrevidenciario.REESTABELECIMENTO:
        this.tipoProcessoTexto = 'Reestabelecimento';
        break;
      case TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO:
        this.tipoProcessoTexto = 'Revisão';
        break;
      default:
        break;
    }



    this.rows = this.calculoValorCausa.relatorioValorCausa.parcelas;

    this.temp = this.rows.map((prop, key) => {
      return {
        ...prop,
        id: key
      };
    });

    this.processandoRelatorioEvent.emit(false);
    this.continuarEvent.emit(this.calculoValorCausa);
  }

  baixarPDF() {
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');
    this.aguardandoPdf = true;


    this.listarRelatorioValorCausaPrevidenciarioPdfService.ListarPDF(this.calculoValorCausa.relatorioValorCausa)
      .subscribe(blob => {

        this.aguardandoPdf = false;
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'RelatorioValorCausa.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
      });
  }


  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }



}
