

import { AbatimentoLiquidacaoSentencaPrevidenciario } from "./abatimentosliquidacaosentencaprevidenciario";
import { BeneficioLiquidacaoSentencaPrevidenciario } from "./beneficioliquidacaosentencaprevidenciario";
import { HonorariosLiquidacaoSentencaPrevidenciario } from "./honorariosliquidacaosentencaprevidenciario";
import { PreliminaresLiquidacaoSentencaPrevidenciario } from "./preliminaresliquidacaosentencaprevidenciario";
import { ProcessoLiquidacaoSentencaPrevidenciario } from "./processoliquidacaosentencaprevidenciario";
import { ReferenciaLiquidacaoSentencaPrevidenciario } from "./referencialiquidacaosentencaprevidenciario";

export class LiquidacaoSentencaPrevidenciario {
    referencia:ReferenciaLiquidacaoSentencaPrevidenciario;
    beneficio:BeneficioLiquidacaoSentencaPrevidenciario;
    abatimentos:AbatimentoLiquidacaoSentencaPrevidenciario;
    processo:ProcessoLiquidacaoSentencaPrevidenciario;
    honorarios:HonorariosLiquidacaoSentencaPrevidenciario;
    honorariosInclusos:boolean;
    decimoTerceiroSemAntecipacao:boolean;
}