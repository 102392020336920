import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Global } from 'src/app/modelos/global';
import { AzureBlobService } from 'src/app/servicos/azure/azure-blob.service';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-meu-avatar',
  templateUrl: './meu-avatar.component.html',
  styleUrls: ['./meu-avatar.component.scss']
})
export class MeuAvatarComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;
  avatarUrl:string;
  avatarVisivel:boolean;

  constructor(private autenticacaoService:AutenticacaoService,
    private datePipe: DatePipe,
    private azureBlobService:AzureBlobService,
    private configService:ConfigService) {


    

    }

  ngOnInit(): void {


    this.configService.getUsuario()
    .subscribe(usuario=>{ 
      if (usuario.avatarUrl) {


        this.avatarVisivel = true;
        this.avatarUrl = usuario.avatarUrl;
        
      }
    });

    
  }
  showFileDialog(): void {
    this.fileInput.nativeElement.click();
  }
  selecionarArquivo(event: Event): void {

    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    this.fileInput.nativeElement.value === '';


    this.azureBlobService.upLoadBlob(files[0], 'avatar')
    .subscribe(progresso=>{
    });
  }


}
