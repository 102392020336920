

<div *ngIf="calculoLiquidacaoSentencaPrevidenciario && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios" class="row">

  <div class="row">
    <div class="col-12">
      <h5 class=" h3 mb-0 linha-quadro">Honorários</h5>

      <div class="row py-4 flex d-flex g-4">

        <div class="col-3">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Honoários de Sucumbência
          </h5>
          <input [inputMask]="jurosInputMask" class=" form-control text-left" type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.valorPercentualSucumbencia" />
          <p class="h6">
            Percentual em honorários de sucumbência quando houver
          </p>
        </div>

        <div class="col-3">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Data limite
          </h5>
          <input [inputMask]="dateInputMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.dataLimite" />
          <p class="h6">
            Data limite honorários de sucumbência
          </p>
        </div>

      </div>

      <div class="row py-4 flex d-flex g-4">
        <div class="col-3">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Honorários Contratuais
          </h5>
          <input [inputMask]="jurosInputMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.valorPercentualContratual" />
          <p class="h6">
            Percentual em honorários contratuais quando houver
          </p>
        </div>

      </div>

    </div>

  </div>

</div>

<!-- <form [formGroup]="honorariosForm">

  <div class="row">

    <div class=" col-12 ">

      <h5 (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" class="clicavel h3 mb-0 linha-quadro">Honorários
        <i class="bi-question-circle"></i>
      </h5>

      <div id="collapseBasic" class=" py-4" [collapse]="isCollapsed" [isAnimated]="true">
        <h5 class=" h3 mb-0 ">Honorários de sucumbência. Se não houver clique em <i
            class="bi-arrow-right"></i> para continuar.
        </h5>

      </div>

      <div class="row py-4">
        <div class="col">

          <h5 class=" card-title text-uppercase mb-0 text-default">
            Valor Percentual
          </h5>
          <input [inputMask]="jurosInputMask" class=" form-control text-left" 
            placeholder="0,00 %" type="text" formControlName="valorPercentual" />
        </div>
      </div>

      <div class="row py-4">
        <div class="col">

          <h5 class=" card-title text-uppercase mb-0 text-default">
            Data Final
          </h5>
          <input [inputMask]="dateInputMask" class=" form-control" 
            placeholder="dd/mm/aaaa" type="text" formControlName="dataLimite" />
        </div>
      </div>

    </div>

  </div>

</form> -->