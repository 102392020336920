import { Component, EventEmitter, Output } from '@angular/core';
import { Ficha } from 'src/app/modelos/previdenciario/ficha';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-lista-fichas',
  templateUrl: './lista-fichas.component.html',
  styleUrl: './lista-fichas.component.scss'
})
export class ListaFichasComponent {

  @Output() fichaSelecionadaEvent = new EventEmitter<Ficha>();

  fichas:Ficha[];

  constructor(private configService:ConfigService, private calculeiApiService:CalculeiApiService){
    let usuarioRequest:Usuario;
    this.configService.getUsuario().subscribe(usuario=>usuarioRequest = usuario);
    this.calculeiApiService.api("ListarFichas",usuarioRequest).subscribe(fichas=>{
      this.fichas = fichas as Ficha[];
    })
  }

  selecionarFicha(ficha:Ficha){
    // console.log('ficha selecionada', ficha)
    this.fichaSelecionadaEvent.emit(ficha);
  }

  public addFichaLista(ficha:Ficha){
    this.fichas.push(ficha);
  }

}
