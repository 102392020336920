import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientesComponent } from './clientes/clientes.component';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { MinhaContaComponent } from './minha-conta/minha-conta.component';


import { NovoClienteComponent } from './novo-cliente/novo-cliente.component';

import { UsuarioComponent } from './usuario.component';

import { CheckoutComponent } from './checkout/checkout.component';
import { SelecionarPlanoComponent } from './selecionar-plano/selecionar-plano.component';
import { TesteComponent } from './teste/teste.component';
import { PixCheckoutComponent } from './pix-checkout/pix-checkout.component';

const routes: Routes = [
  {
    path:'',
    component:UsuarioComponent,
    children:[
      {
        path:'',
        redirectTo:'minhaconta',
        pathMatch:'full'
      },
      {
        path:'minhaconta',
        component:MinhaContaComponent,
      },

      {
        path:'planos',
        component:SelecionarPlanoComponent,
      },

      {
        path:'checkout',
        component:CheckoutComponent,
      },
      {
        path:'configuracoes',
        component:ConfiguracoesComponent,
      },
      {
        path:'novocliente',
        component:NovoClienteComponent,
      },
      {
        path:'clientes',
        component:ClientesComponent,
      },
      {
        path:'teste',
        component:TesteComponent,
      },
      {
        path:'checkoutpix',
        component:PixCheckoutComponent,
      },

    ]

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuarioRoutingModule { }
