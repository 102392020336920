<div class=" header bg-back-dark">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-3">
        <div class=" col-lg-12">
          <h6 class=" h2 text-white d-inline-block mb-0">Planos de assinatura Calculei</h6>
        </div>
      </div>
    </div>
  </div>
</div>
<div class=" container-fluid mt--3">

  <div class=" row">
    <div class=" col-lg-12">
      <div class="  ">

        <div class="pb-4">
          <div class="card">
            <div class="row">
              <div class="col">
                <div class="card-body">
                  <span class=" display-3 font-weight-lighter  mb-0 text-default">
                    Pagamento
                  </span>
                </div>
              </div>

            </div>

            <div class="row justify-content-center py-2">
              <div class="col-4 py-4 d-flex">

                <div class="card">
                  <div *ngIf="plano" class="card-body">

                    <div class="row align-items-center">

                      <div class="col-12 text-center py-4">
                        <h3 class=" h3 mb-0">Plano</h3>
                      </div>

                      <div class="col-12 text-center py-4">
                        <h3 class=" h3 mb-0">{{plano.nome}}</h3>
                      </div>

                      <!-- <div class="col  text-center py-2">
                        <span> de </span><span class="text-decoration-line-through">{{plano.valorOriginal |
                          currency}}</span><span> por</span>
                      </div> -->

                      <div class="row align-items-end  py-2">
                        <div class="col  text-center">
                          <span class=" display-1  ">{{plano.valor | currency}}</span><span>/ {{plano.periodo |
                            periodo}}</span>
                        </div>
                      </div>

                      <div class="col  text-center py-4">
                        <span>{{plano.meioPagamento | meioPagamento}}</span>
                      </div>

                    </div>

                    <div class="row">
                      <div class="col  text-center py-4">
                        <span> {{plano.descricao }}</span>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <div class="col-4 py-4 d-flex">

                <div *ngIf="pixFormVisivel" class=" card">
                  <div class=" card-body">

                    <form [formGroup]="pixForm">

                      <div class="row py-4">

                        <div class="col-12 py-4 justify-content-center">

                          <div *ngIf="!qrcodeUrl" class="col-1 p-4 mb-5  controlenav  ">
                            <div class="spinner-border spinner-border-lg" role="status"></div>
                          </div>
                          <img *ngIf="qrcodeUrl" class="qrcodepix" [src]="qrcodeUrl">

                        </div>

                      </div>

                    </form>
                  </div>
                </div>

                <div class="card">
                  <div class=" card-body">
                    <div id="stepper1" class="bs-stepper .vertical">
                      <div class="row">
                        <div class="col-12">
                          <div class="bs-stepper-header">
                            <div class="step" data-target="#test-l-1">
                              <button class="step-trigger">
                                <span class="bs-stepper-circle fw-normal"><i class="bi bi-credit-card"></i></span>
                                <!-- <span class="bs-stepper-label fw-normal">Cartão de Crédito</span> -->
                              </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#test-l-2">
                              <button class="step-trigger">
                                <span class="bs-stepper-circle fw-normal"><i class="bi bi-house"></i></span>
                                <!-- <span class="bs-stepper-label fw-normal">Endereço</span> -->
                              </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#test-l-3">
                              <button class="step-trigger">
                                <!-- <div class="spinner-grow" role="status" [ngClass]="{'show': aguardeResultado, 'collapse': !aguardeResultado}"></div> -->

                                <span class="bs-stepper-circle fw-normal"><i class="bi bi-receipt"></i></span>
                                <!-- <span class="bs-stepper-label fw-normal">Nota Fiscal</span> -->

                              </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#test-l-4">
                              <button class="step-trigger">
                                <!-- <div class="spinner-grow" role="status" [ngClass]="{'show': aguardeResultado, 'collapse': !aguardeResultado}"></div> -->

                                <span class="bs-stepper-circle fw-normal"><i class="bi bi-check"></i></span>
                                <!-- <span class="bs-stepper-label fw-normal">Nota Fiscal</span> -->

                              </button>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div class="bs-stepper-content m-0 p-0">

                        <!-- Cartão -->
                        <div id="test-l-1" class="content">
                          <h5 class=" h3 mb-0 linha-quadro">Cartão de crédito</h5>
                          <div class="row py-4">

                            <div class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Nome
                              </h5>
                              <input class=" form-control text-left" type="text" [(ngModel)]="tokenRequest.Holder" />
                              <p class="h6">
                                Nome como aparece no cartão de crédito
                              </p>

                            </div>

                            <div class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Número
                              </h5>
                              <input placeholder="9999 - 9999 - 9999 - 9999" [inputMask]="cartaoMask" class=" form-control text-left" type="text" [(ngModel)]="tokenRequest.CardNumber" />
                              <p class="h6">
                                Número do cartão de crédito
                              </p>

                            </div>
                            <div class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Válido até
                              </h5>
                              <input placeholder="MM/AAAA" [inputMask]="validadeMask" class=" form-control text-left" type="text" [(ngModel)]="tokenRequest.ExpirationDate" />
                              <p class="h6">
                                Validade do cartão de crédito mês/ano
                              </p>

                            </div>

                            <div class="col-6">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Código de segurança
                              </h5>
                              <input [inputMask]="codigoMask" placeholder="CVV" class=" form-control text-left" type="text" [(ngModel)]="tokenRequest.SecurityCode" />
                              <p class="h6">
                                Número do código de segurança 3 digitos
                              </p>

                            </div>
                          </div>

                          <div class="row flex d-flex flex-row-reverse align-self-end">
                            <div class="col-auto">

                              <i class="comando bi bi-arrow-left fs-4" (click)="voltarPagamentoCartao()" role="button"></i>
                              <i class="comando bi bi-arrow-right fs-1" (click)="proximoPagamentoCartao()" role="button"></i>
                            </div>
                            <div class="col-auto">
                              <app-aguarde-config></app-aguarde-config>
                            </div>

                          </div>

                        </div>

                        <!-- Endereço -->
                        <div id="test-l-2" class="content">
                          <h5 class=" h3 mb-0 linha-quadro">Endereço</h5>
                          <div class="row py-4">

                            <div class="col-6">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                CEP
                              </h5>
                              <input [inputMask]="cepMask" class=" form-control text-left" type="text" [(ngModel)]="customerRequest.address.zipCode" />
                              <p class="h6">
                                Nome como aparece no cartão de crédito
                              </p>
                            </div>

                            <div class="col-8">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Rua
                              </h5>
                              <input class=" form-control text-left" type="text" [(ngModel)]="customerRequest.address.street" />
                              <p class="h6">
                                Nome como aparece no cartão de crédito
                              </p>
                            </div>
                            <div class="col-4">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Número
                              </h5>
                              <input class=" form-control text-left" type="text" [(ngModel)]="customerRequest.address.number" />
                              <p class="h6">
                                Nome como aparece no cartão de crédito
                              </p>
                            </div>

                            <div class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Bairro
                              </h5>
                              <input class=" form-control text-left" type="text" [(ngModel)]="customerRequest.address.district" />
                              <p class="h6">
                                Nome como aparece no cartão de crédito
                              </p>
                            </div>

                            <div class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Cidade
                              </h5>
                              <input class=" form-control text-left" type="text" [(ngModel)]="customerRequest.address.city.cityName" />
                              <p class="h6">
                                Nome como aparece no cartão de crédito
                              </p>
                            </div>

                          </div>

                          <div class="row flex d-flex flex-row-reverse align-self-end">
                            <div class="col-auto">

                              <i class="comando bi bi-arrow-left fs-4" (click)="voltarPagamentoCartao()" role="button"></i>
                              <i class="comando bi bi-arrow-right fs-1" (click)="proximoPagamentoCartao()" role="button"></i>
                            </div>
                            <div class="col-auto">
                              <app-aguarde-config></app-aguarde-config>
                            </div>

                          </div>

                        </div>

                        <!-- Nota Fiscal -->
                        <div id="test-l-3" class="content">
                          <h5 class=" h3 mb-0 linha-quadro">Nota Fiscal</h5>
                          <div class="row py-4">

                            <div class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Tipo de documento
                              </h5>
                              <div class="btn-group" btnRadioGroup [(ngModel)]="tipoDocumento">
                                <label btnRadio="CPF" class="btn btn-lg btn-outline-black mb-0">CPF</label>
                                <label btnRadio="CNPJ" class="btn btn-lg btn-outline-black mb-0">CNPJ</label>
                              </div>
                              <p class="h6">
                                Escolha entre CPF e CNPJ
                              </p>
                            </div>

                            <div *ngIf="tipoDocumento === 'CPF'" class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                CPF
                              </h5>
                              <input placeholder="000.000.000-00" [inputMask]="cpfMask" class=" form-control text-left" type="text" [(ngModel)]="assinaturaRequest.customer.identity" />
                              <p class="h6">
                                CPF
                              </p>
                            </div>

                            <div *ngIf="tipoDocumento === 'CNPJ'" class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                CNPJ
                              </h5>
                              <input placeholder="00.000.000/0000-00" [inputMask]="cnpjMask" class=" form-control text-left" type="text" [(ngModel)]="assinaturaRequest.customer.identity" />
                              <p class="h6">
                                CNPJ
                              </p>
                            </div>

                            <div class="col-12">
                              <h5 class=" card-title text-uppercase mb-0 text-default">
                                Telefone
                              </h5>
                              <input placeholder="(99) 99999-9999" [inputMask]="telefoneMask" class=" form-control text-left" type="text" [(ngModel)]="assinaturaRequest.customer.phone" />
                              <p class="h6">
                                Telefone para contato
                              </p>
                            </div>

                          </div>

                          <div class="row flex d-flex flex-row-reverse align-self-end">
                            <div class="col-auto">

                              <i class="comando bi bi-arrow-left fs-4" (click)="voltarPagamentoCartao()" role="button"></i>
                              <i class="comando bi bi-arrow-right fs-1" (click)="proximoPagamentoCartao()" role="button"></i>
                            </div>
                            <div class="col-auto">
                              <app-aguarde-config></app-aguarde-config>
                            </div>

                          </div>

                        </div>

                        <!-- finalizar -->
                        <div id="test-l-4" class="content">
                          <h5 class=" h3 mb-0 linha-quadro">Finalizar</h5>

                          <div class="row py-4">
                            <div class="col-12 d-flex justify-content-between">
                              <span class> Valor do plano</span>
                              <span class>{{plano.valor | currency}}</span>
                            </div>

                            <div *ngIf="voucher.desconto > 0" class="col-12 d-flex justify-content-between text-danger">
                              <span class> Desconto do voucher</span>
                              <span class>{{voucher.desconto /100 * plano.valor | currency}}</span>
                            </div>

                            <div class="col-12 d-flex justify-content-between">
                              <span class>Pagamento recorrente</span>
                              <span class>{{plano.periodo}}</span>
                            </div>

                            <div class="col-12 d-flex justify-content-between">
                              <span class>Forma de pagamento</span>
                              <span class> Cartão final {{tokenRequest.CardNumber | slice : 12}}</span>
                            </div>

                            <div class=" pt-6 col-12  d-flex justify-content-between">
                              <span class>Total por cobrança</span>
                              <span *ngIf="voucher.desconto > 0" class>{{plano.valor - (voucher.desconto /100 * plano.valor) | currency}}</span>
                              <span *ngIf="voucher.desconto == 0" class>{{plano.valor | currency}}</span>
                            </div>
                          </div>

                          <div class="row py-4">

                            <div class="col-12 collapse show " id="voucherEdit">
                              <a class="text-default h6" data-bs-toggle="collapse" href="#voucherEdit" role="button" aria-expanded="false" aria-controls="voucherEdit">possui um voucher?</a>
                            </div>

                            <div class="row align-items-center collapse" id="voucherEdit">
                              <div class="col-6 ">
                                <h5 class=" card-title text-uppercase mb-0 text-default">
                                  Voucher
                                </h5>
                                <input class=" form-control text-left" type="text" [(ngModel)]="voucher.codigo" />
                                <p class="h6">
                                  Código do voucher
                                </p>
                              </div>
                              <div class="col-6">
                                <button class="btn btn-outline-default" (click)="aplicarVoucher()">aplicar voucher</button>
                              </div>
                            </div>

                          </div>

                          <div class="row flex d-flex flex-row-reverse align-self-end">
                            <div class="col-auto">

                              <i class="comando bi bi-arrow-left fs-4" (click)="voltarPagamentoCartao()" role="button"></i>
                              <i class="comando bi bi-check fs-1" (click)="criarAssinatura()" role="button"></i>
                            </div>
                            <div class="col-auto">
                              <app-aguarde-config></app-aguarde-config>
                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>
  </div>