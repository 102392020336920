import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CapitalizacaoMensal } from 'src/app/modelos/previdenciario/capitalizacaomensal';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';

@Component({
  selector: 'app-progressao-capitalizacao-chart',
  templateUrl: './progressao-capitalizacao-chart.component.html',
  styleUrls: ['./progressao-capitalizacao-chart.component.scss']
})
export class ProgressaoCapitalizacaoChartComponent implements OnInit, OnChanges{

  
  @Input() linhasCapitalizacaoMensal:CapitalizacaoMensal[];

  constructor(private currency:CurrencyPipe, private datePipe:DatePipe){}

  datasetInvestimentoAcumuladoMes:any;
  datasetRendimentoMes:any;
  data:any;
  chart:any;

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.linhasCapitalizacaoMensal != undefined && changes.linhasCapitalizacaoMensal.currentValue != undefined) {
      this.linhasCapitalizacaoMensal = changes.linhasCapitalizacaoMensal.currentValue;
      if(this.chart != undefined){
        this.chart.destroy();
        this.popularChart();
      }
      

    }
    
  }

  ngOnInit(): void {

    this.popularChart();
    
    
  }

  popularChart(){
    this.datasetInvestimentoAcumuladoMes = {label:'Investimento Acumulado no Mês',backgroundColor: '#172b4d',data: this.linhasCapitalizacaoMensal.map(linha=>linha.investimentoAcumulado - linha.rendimentoMes)};  //this.linhasCapitalizacaoMensal.map(linha=>{return {label:linha.mes, data:linha.investimentoAcumulado}});
    // this.datasetRendimentoMes = {label:'Rendimento no Mês', data: this.linhasCapitalizacaoMensal.map(linha=>linha.rendimentoMes)};
    this.data = {labels:this.linhasCapitalizacaoMensal.map(linha=>linha.mes), datasets:[this.datasetInvestimentoAcumuladoMes]};

    
    Chart.register(annotationPlugin);
    this.chart = new Chart(document.getElementById('chartProgressaoCapitalizacao') as HTMLCanvasElement, {
      type: 'bar',
      data:this.data,
      options: {
        onClick: (e, activeEls)=>{
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },
        
        onHover: (e, activeEls)=> {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },

        
        

        plugins: {
          legend:{
            display:true,
          },
          title: {
            display: false,
            text: 'RMI por regra',
            font:{
              size:16
            }
          },
          tooltip: {
            displayColors:false,
            bodyFont:{
              size:24,
              weight:'normal',
            },
            titleFont:{
              size:24,
              weight:'normal'

            },
            footerFont:{
              size:12,
              weight:'normal',

            },
            callbacks: {
                label: (context) => {
                    let label = context.dataset.label || '';

                    if (label) {
                        label += ': ';
                    }
                    if (context.parsed.y !== null) {
                        label += this.currency.transform(context.parsed.y) ;
                    }
                    return label;
                },
                title: (tooltipItens) => {
                  return tooltipItens[0].label;
                },
                footer: (tooltipItens)=>{
                  return ''
                }
            }
          }
        },
         
        scales: {
          y: {
            beginAtZero: true,
            ticks:{
                callback: (value, index, ticks)=>{
                  return  this.currency.transform(value);
                }
              }

          },
          x:{
            stacked: true,
            ticks:{
              display: false,
              
            },
            title: {
              display: true,
              text: 'Meses'
            },
            
            


          }
        }
      }

    });
  }
}
