export enum GruposIndicadoresCNIS {
    AJUSTESEC103OUTROSINDICADORES,
    CONTRIBUICOES,
    CONTRIBUICOESVINCULOSREMUNERACOES,
    GERAISNITDADOSCADASTRAIS,
    SEGURADOESPECIAL,
    VINCULOSREMUNERACOES,
    AJUSTESEC103AGRUPAMENTO,
    AJUSTESEC103COMPLEMENTACAO,
    AJUSTESEC103UTILIZACAO,
    DARFEVENTOS,
    DARFERROSPROCESSAMENTO
}