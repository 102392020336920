
<div class="row py-4">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">
            Checklist de Pontos de Atenção
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#helpChecklist" aria-expanded="false" aria-controls="helpChecklist">

            </i>
            <div class="collapse" id="helpChecklist">
                <p class="lh-1 py-2">
                    O checklist de carência, é o conjunto de fases de validação das contribuições. Essa validação determina quais contribuições vão ser utilizadas para contagem de carência.
                </p>

            </div>
        </h5>
    </div>
</div>

<div class="row py-2  flex d-flex justify-content-between">

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
        </div>

    </div>

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
            <!-- <i class="comando bi bi-toggle-off fs-5 " role="button" container="body" ngbTooltip="Ignorar validação de todas as contribuições invalidadas" triggers="hover"></i> -->
        </div>

    </div>

</div>

<div class="row py-2">
    <div class="col-12">

        <div class="table-responsive">
            <table class="table table-bordered table-sm  table-striped  table-hover align-middle">
                <thead>

                    <tr>
                        <th scope="col" class=" alignt-middle text-center" width="30%">
                            <span>
                                Fase de Validação
                            </span>
                        </th>

                        <th scope="col" class="  alignt-middle text-center">
                            <span>
                                Resultado
                            </span>
                        </th>

                    </tr>

                </thead>
                <tbody>
                    <ng-container class *ngFor="let passo of calculoBeneficios.leituraCnis.cnis.fluxoPontosAtencao.passos; index as index;">
                        <tr>
                            <td class=" text-center">
                                <p class="fw-bold">
                                    {{passo.titulo}}
                                </p>
                                <p class="text-justify lh-1">
                                    {{passo.descricao}}
                                </p>

                            </td>

                            <td>
                               

                                <div class="py-2">
                                    <span class="fw-bold">Pontos de Atenção</span>
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-sm  table-striped  table-hover align-middle overflow-auto">
                                            <thead>

                                                <tr>
                                                    <th class=" alignt-middle text-center" scope="col" width="10%">
                                                        <span>
                                                            Título
                                                        </span>
                                                    </th>

                                                    <th class=" alignt-middle text-center" scope="col" width="20%">
                                                        <span>
                                                            Origem
                                                        </span>
                                                    </th>
                                                    <th class=" alignt-middle text-center" scope="col" >
                                                        <span>
                                                            Providências
                                                        </span>
                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <ng-container *ngIf="passo.pontosAtencao.length>0">
                                                    <ng-container class *ngFor="let ponto of passo.pontosAtencao; index as index;">

                                                        <tr>
                                                            <td class="align-middle text-center" width="10%">
                                                                {{ponto.titulo }}
                                                            </td>

                                                            <td>
                                                                <div class="container-sm"  >
                                                                    {{ponto.origem}}
                                                                </div>

                                                            </td>

                                                            <td>
                                                                <div class="container-sm">
                                                                    {{ponto.encaminhamento}}
                                                                </div>

                                                            </td>
                                                            

                                                        </tr>

                                                    </ng-container>

                                                </ng-container>
                                                <ng-container *ngIf="passo.pontosAtencao.length==0">
                                                    <tr>
                                                        <td colspan="3" class="align-middle text-center">
                                                            <span class="fw-lighter"> Nenhuma ponto de atenção nesta fase de validação</span>
                                                        </td>
                                                    </tr>

                                                </ng-container>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                

                            </td>

                        </tr>

                    </ng-container>

                </tbody>
            </table>

        </div>

    </div>
</div>