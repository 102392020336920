import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  OAuthErrorEvent,
  OAuthEvent,
  OAuthService,
  NullValidationHandler,
} from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { authCodeFlowConfig, DiscoveryDocumentConfig } from './auth.config';
import { LogService } from '../log.service';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  private _usuario = new BehaviorSubject<Usuario>(new Usuario());

  setUsuario(usuario:Usuario):void{
    this._usuario.next(usuario);
    localStorage.setItem('usuario', JSON.stringify(this.usuario()));
  }

  getUsuario():Observable<Usuario>{
    return this._usuario.asObservable();
  }

  usuario(){
    return this._usuario.value;
  }

  
  constructor(readonly oauthService: OAuthService, private router: Router) {

    
    this.configure();
    
    this.oauthService.setupAutomaticSilentRefresh();
  }
  private configure() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.strictDiscoveryDocumentValidation = false;
    this.oauthService.loadDiscoveryDocument(DiscoveryDocumentConfig.url);
    
    
  }

  refresh() {
    this.oauthService.refreshToken();
  }
  login(){
    this.oauthService.initCodeFlow();
    
  }

  logout() {
    localStorage.clear();
    this.oauthService.logOut();
  }

  isLoggedIn(): boolean {
    const identityClaim = this.oauthService.getIdentityClaims();
    LogService.log("claims", identityClaim);
    return identityClaim && identityClaim['name'] ? true : false;
  }
}
