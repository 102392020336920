
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CriarContaRequest } from 'src/app/modelos/usuario/criarcontarequest';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { environment } from 'src/environments/environment';
import { ConfirmarEmailRequest } from 'src/app/modelos/usuario/confirmaremailrequest';

@Injectable({
  providedIn: 'root'
})
export class EnviarEmailConfirmacaoService {

  constructor(private httpClient:HttpClient) { }

  enviarEmailConfirmacao(confirmarEmail:ConfirmarEmailRequest){

    return Promise.all([this.apicall(confirmarEmail)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(confirmarEmailRequest:ConfirmarEmailRequest){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.apiPaginas as string + 'api/EnviarEmailConfirmacao?code=bc-57VXflM4l8Lm1GNTZslvGuCzDgKPpssFYC7dznnJLAzFuKg9lYQ==',confirmarEmailRequest, {'headers':headers}).toPromise();
    return data;
  }
}
