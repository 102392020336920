import { Pipe, PipeTransform } from '@angular/core';
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';

@Pipe({
  name: 'tipoBeneficioLiquidacao'

})
export class TipoBeneficioLiquidacaoPipe implements PipeTransform {

  transform(value: TipoBeneficioLiquidacaoSentencaPrevidenciario, ...args: unknown[]): string {

    let resultado = '';

    switch (value) {
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAESPECIAL:
        resultado = "Aposentadoria Especial";

        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADE:
        resultado = "Aposentadoria por Idade";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADEPESSOADECIFICIENCIA:
        resultado = "Aposentadoria por Idade da Pessoa Com Deficiência";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADERURAL:
        resultado = "Aposentadoria por Idade Rural";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINCAPACIDADEPERMANENTE:
        resultado = "Aposentadoria por Incapacidade Permanente";

        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINVALIDEZ:
        resultado = "Aposentadoria por Invalidez";

        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAINVALIDEZDECORRENTEACIDENTETRABALHO:
        resultado = "Aposentadoria por Invalidez Decorrente de Acidente de Trabalho";

        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIARURALVELHICE:
        resultado = "Aposentadoria Rural Velhice";

        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAO:
        resultado = "Aposentadoria por Tempo de Contribuição";

        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPONTOS:
        resultado = "Aposentadoria por Tempo de Contribuição por Pontos";

        break;
      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPROFESSOR:
        resultado = "Aposentadoria por Tempo de Contribuição do Professor";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIATEMPOCONTRIBUICAOPROFESSORPONTOS:
        resultado = "Aposentadoria por Tempo de Contribuição do Professor por Pontos";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIOACIDENTE:
        resultado = "Auxílio Acidente";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIODOENCA:
        resultado = "Auxílio Doença";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.AUXILIORECLUSAO:
        resultado = "Auxílio Reclusão";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.BENEFICIOPRESTACAOCONTINUADAASSISTENCIALLOAS:
        resultado = "BPC LOAS";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.PENSAOMORTE:
        resultado = "Pensão por Morte";

        break;

      case TipoBeneficioLiquidacaoSentencaPrevidenciario.SALARIOMATERNIDADE:
        resultado = "Salário Maternidade";

        break;


      default:
        break;
    }
    return resultado;
  }

}
