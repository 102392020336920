import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { CalculosDashboardComponent } from './calculos-dashboard/calculos-dashboard.component';
import { DashboardComponent } from './dashboard.component';
import { ClientesDashboardComponent } from './clientes-dashboard/clientes-dashboard.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CompartilhadoModule as CompartilhadoLocalModule } from '../compartilhado/compartilhado.module';
import { CompartilhadoDashboardModule } from './compartilhado-dashboard/compartilhado-dashboard.module';
import { CompartilhadoModule } from '../calculos/previdenciario/compartilhado/compartilhado.module';
import { MatrizDashboardComponent } from './matriz-dashboard/matriz-dashboard.component';
import { EntrevistasDashboardComponent } from './entrevistas-dashboard/entrevistas-dashboard.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EntrevistasModule } from '../entrevistas/entrevistas.module';


@NgModule({
  declarations: [
    CalculosDashboardComponent,
    DashboardComponent,
    ClientesDashboardComponent,
    MatrizDashboardComponent,
    EntrevistasDashboardComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    NgxDatatableModule,
    CompartilhadoModule,
    CompartilhadoDashboardModule,
    CompartilhadoLocalModule,
    FormsModule,
    RouterModule,
    EntrevistasModule
    
  ]
})
export class DashboardModule { }
