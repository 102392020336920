import { Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-liquidacao-sentenca-civel',
  templateUrl: './liquidacao-sentenca-civel.component.html',
  styleUrls: ['./liquidacao-sentenca-civel.component.scss']
})
export class LiquidacaoSentencaCivelComponent implements OnInit {

  @ViewChild('tabsLiquidacaoSentencaCivel', {static: false}) tabs?: TabsetComponent;

  constructor() { }

  aguardeVisivel:boolean;

  ngAfterViewInit():void{
    
  }
  
  ngOnInit(): void {
  }

}
