import { Pipe, PipeTransform } from '@angular/core';
import { ProtocolosAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/protocolosaplicacaojurosprevidenciario';

@Pipe({
  name: 'protocolosJuros',

})
export class ProtocolosJurosPipe implements PipeTransform {

  transform(value: ProtocolosAplicacaoJurosPrevidenciario, ...args: unknown[]): string {
    return this.textoProtocoloJuros(value);
  }

  textoProtocoloJuros(protocolo: ProtocolosAplicacaoJurosPrevidenciario) {
    let texto: string = '';


    switch (protocolo) {
      case ProtocolosAplicacaoJurosPrevidenciario.SEMJUROS:
        texto = "Sem juros";
        break;
      case ProtocolosAplicacaoJurosPrevidenciario.CRITERIOESPECIFICO:
        texto = "Critério específico, aplicar regra manualmente";
        break;
      case ProtocolosAplicacaoJurosPrevidenciario.GLOBAL10:
        texto = "1% em todas as parcelas";
        break;
      case ProtocolosAplicacaoJurosPrevidenciario.MENSAL05:
        texto = "0.5% ao mês";
        break;
      case ProtocolosAplicacaoJurosPrevidenciario.MENSAL0501200310022003062009005072009ATUAL:
        texto = "0,5% a.m. até 01/2003; 1,0% a.m. entre 02/2003 e 06/2009; 0,5% a.m. a partir de 07/2009";
        break;
      case ProtocolosAplicacaoJurosPrevidenciario.MENSAL050120031002200306200905072009062012POUPANCA072012ATUAL:
        texto = "0,5% a.m. até 01/2003; 1,0% a.m. entre 02/2003 e 06/2009; 0,5% a.m. de 07/2009 a 06/2012; juros da Poupança a partir de 07/2012";
        break;
      case ProtocolosAplicacaoJurosPrevidenciario.MENSAL1006200905072009062012POUPANCA072012ATUAL:
        texto = "1,0% a.m. até 06/2009; 0,5% a.m. de  07/2009 a 06/2012; juros de poupança a partir de 07/2012.";
        break;
      case ProtocolosAplicacaoJurosPrevidenciario.MANUALCALCULOJUSTICAFEDERAL:
        texto = "Conforme manual de orientação de procedimentos para os cálculos na Justiça Federal";
        break;
      default:
        break;
    }

    return texto;
  }

}
