import { Component, Input, OnInit } from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { RelatorioRMI } from 'src/app/modelos/previdenciario/relatoriormi';

@Component({
  selector: 'app-quadro-descartes',
  templateUrl: './quadro-descartes.component.html',
  styleUrls: ['./quadro-descartes.component.scss']
})
export class QuadroDescartesComponent implements OnInit {

  @Input() beneficio: Beneficio;

  
  relatorioRMI: RelatorioRMI;


  menorCoeficiente:number;
  maiorCoeficiente:number;

  menorRMI:number;
  maiorRMI:number;

  totalOpcoesAnalise:number;


  constructor(){
    
  }



  ngOnInit(): void {

    this.carregarDados();

  }


  carregarDados() {

    if (this.beneficio != undefined) {
      this.relatorioRMI = this.beneficio.relatorioRendaMensalInicial;
      

      this.menorCoeficiente = this.beneficio.opcoesDescarte.map(relatorioOpcao=>relatorioOpcao.coeficiente).sort((a,b)=> a - b)[0];
      this.maiorCoeficiente = this.beneficio.opcoesDescarte.map(relatorioOpcao=>relatorioOpcao.coeficiente).sort((a,b)=> b - a)[0];


      this.menorRMI = this.beneficio.opcoesDescarte.map(relatorioOpcao=>relatorioOpcao.rendaMensalInicial).sort((a,b)=> a - b)[0];
      this.maiorRMI = this.beneficio.opcoesDescarte.map(relatorioOpcao=>relatorioOpcao.rendaMensalInicial).sort((a,b)=> b - a)[0];


      this.totalOpcoesAnalise = this.beneficio.opcoesDescarte.length;
    }
  }

}
