import { Component, OnInit } from '@angular/core';

import { RequestBase } from 'src/app/modelos/common/requestbase';
import { Global } from 'src/app/modelos/global';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ListarUsuariosService } from 'src/app/servicos/conta/listar-usuarios.service';

@Component({
  selector: 'app-editor-usuario',
  templateUrl: './editor-usuario.component.html',
  styleUrls: ['./editor-usuario.component.scss']
})
export class EditorUsuarioComponent implements OnInit{

 

  ngOnInit() {

   
  }

  

}
