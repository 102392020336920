import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perguntasfrequentes',
  templateUrl: './perguntasfrequentes.component.html',
  styleUrls: ['./perguntasfrequentes.component.scss']
})
export class PerguntasfrequentesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
