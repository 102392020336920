import { Pipe, PipeTransform } from '@angular/core';
import { TiposResposta } from 'src/app/modelos/previdenciario/tiposResposta';

@Pipe({
  name: 'tipoResposta',
})
export class TipoRespostaPipe implements PipeTransform {

  transform(value: TiposResposta, ...args: unknown[]): string {

    let resultado: string = "";

    switch (value) {
      case TiposResposta.TEXTO:

        resultado = "Texto";
        break;
      case TiposResposta.ARQUIVO:
        resultado = "Upload de arquivo";
        break;

      case TiposResposta.SELECAO:
        resultado = "Selecionar opção";
        break;
      case TiposResposta.SELECAOMULTIPLA:
        resultado = "Selecionar múltiplas opções";
        break;

      default:
        break;
    }
    return resultado;
  }

}
