import { Component } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { Cupom } from 'src/app/modelos/common/cupom';
import { Voucher } from 'src/app/modelos/common/voucher';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-gerador-voucher',
  templateUrl: './gerador-voucher.component.html',
  styleUrl: './gerador-voucher.component.scss'
})
export class GeradorVoucherComponent {

  voucher:Voucher;
  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  constructor(private configService:ConfigService,
    private calculeiApiService:CalculeiApiService
  ){
    this.voucher = new Voucher();
    this.voucher.dataValidade = new Date();
    this.voucher.desconto = 10;
    this.configService.getUsuario().subscribe(usuario=> this.voucher.usuario = usuario);
  }

  gerarVoucher(){
    this.calculeiApiService.api("CriarVoucher", this.voucher)
    .subscribe(resultado=>{
      // console.log('voucher criado', resultado);

    });
  }

}
