import { Pipe, PipeTransform } from '@angular/core';
import { TiposIndicadoresCNIS } from 'src/app/modelos/previdenciario/tiposindicadorescnis';

@Pipe({
  name: 'tipoIndicador',
})
export class TipoIndicadorPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {

    let texto:string = '';
    switch (TiposIndicadoresCNIS[value]) {
      case "CSPENDENCIA":
        texto = "INDICADORES DE PENDÊNCIA (CSPENDENCIA)";
        
        break;

        case "CSACERTO":
        texto = "INDICADORES DE ACERTO (CSACERTO)";
        break;

        case "CSINDICADOR":
          texto = "INDICADORES DE ALERTA (CSINDICADOR)";
        
        break;
    
      default:
        break;
    }
    return texto;
  }

}