import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NovoFiliadoComponent } from 'src/app/aplicacao/usuario/novo-filiado/novo-filiado.component';




@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    
  ],
  exports:[
    
    
  ],
  
})
export class UsuarioModule { }
