import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelatoriosRoutingModule } from './relatorios-routing.module';
import { RelatoriosComponent } from '../relatorios/relatorios.component';
import { RelatorioPlanejamentoKertzmanComponent } from './relatorio-planejamento-kertzman/relatorio-planejamento-kertzman.component';
import { CompartilhadoModule } from '../aplicacao/calculos/previdenciario/compartilhado/compartilhado.module';
import { CompartilhadoModule as CompartilhadoModuleAplicacao} from '../aplicacao/compartilhado/compartilhado.module';
import { RelatorioPlanejamentoComponent } from './relatorio-planejamento/relatorio-planejamento.component';
import { RelatorioPlanejamentoComparativoComponent } from './relatorio-planejamento-comparativo/relatorio-planejamento-comparativo.component';

import { RelatorioVariacaoRmiComponent } from './relatorio-variacao-rmi/relatorio-variacao-rmi.component';
import { RelatorioVariacaoPaybackComponent } from './relatorio-variacao-payback/relatorio-variacao-payback.component';
import { RelatorioVariacaoTaxaComponent } from './relatorio-variacao-taxa/relatorio-variacao-taxa.component';
import { RelatorioVariacaoLucroKertzmanComponent } from './relatorio-variacao-lucro-kertzman/relatorio-variacao-lucro-kertzman.component';
import { RelatorioVariacaoContribuicoesOriginaiSimulacaoComponent } from './relatorio-variacao-contribuicoes-originai-simulacao/relatorio-variacao-contribuicoes-originai-simulacao.component';
import { RelatorioSumarioSimulacaoRmiComponent } from './relatorio-sumario-simulacao-rmi/relatorio-sumario-simulacao-rmi.component';
import { RelatorioMatrizPlanejamentoComponent } from './relatorio-matriz-planejamento/relatorio-matriz-planejamento.component';
import { RelatorioJanelasPeriodoContributivoComponent } from './relatorio-janelas-periodo-contributivo/relatorio-janelas-periodo-contributivo.component';
import { RelatorioEvolucaoSalariosPreAnaliseComponent } from './relatorio-evolucao-salarios-pre-analise/relatorio-evolucao-salarios-pre-analise.component';
import { RelatorioCalculoBeneficiosSumarioComponent } from './relatorio-calculo-beneficios-sumario/relatorio-calculo-beneficios-sumario.component';
import { RelatorioPreparacaoAnaliseCnisComponent } from './relatorio-preparacao-analise-cnis/relatorio-preparacao-analise-cnis.component';
import { RelatorioIncapacidadeTemporariaComponent } from './relatorio-incapacidade-temporaria/relatorio-incapacidade-temporaria.component';

import { RelatorioMapaQualidadeSeguradoComponent } from './relatorio-mapa-qualidade-segurado/relatorio-mapa-qualidade-segurado.component';
import { RelatorioMapaQualidadeSeguradoConsolidadoComponent } from './relatorio-mapa-qualidade-segurado-consolidado/relatorio-mapa-qualidade-segurado-consolidado.component';
import { RelatorioQualidadeSeguradoComponent } from './relatorio-qualidade-segurado/relatorio-qualidade-segurado.component';
import { RelatorioLiquidacaoSentencaComponent } from './relatorio-liquidacao-sentenca/relatorio-liquidacao-sentenca.component';

import { RelatorioRmiBeneficioComponent } from './relatorio-rmi-beneficio/relatorio-rmi-beneficio.component';
import { RelatorioTabelaMatrizComponent } from './relatorio-tabela-matriz/relatorio-tabela-matriz.component';
import { RelatorioAposentadoriaInvalidezComponent } from './relatorio-aposentadoria-invalidez/relatorio-aposentadoria-invalidez.component'




@NgModule({
  declarations: [
    RelatoriosComponent,
    RelatorioPlanejamentoKertzmanComponent,
    RelatorioPlanejamentoComponent,
    RelatorioPlanejamentoComparativoComponent,
    RelatorioVariacaoRmiComponent,
    RelatorioVariacaoPaybackComponent,
    RelatorioVariacaoTaxaComponent,
    RelatorioVariacaoLucroKertzmanComponent,
    RelatorioVariacaoContribuicoesOriginaiSimulacaoComponent,
    RelatorioSumarioSimulacaoRmiComponent,
    RelatorioMatrizPlanejamentoComponent,
    RelatorioJanelasPeriodoContributivoComponent,
    RelatorioEvolucaoSalariosPreAnaliseComponent,
    RelatorioCalculoBeneficiosSumarioComponent,
    RelatorioPreparacaoAnaliseCnisComponent,
    RelatorioIncapacidadeTemporariaComponent,
    
    RelatorioMapaQualidadeSeguradoComponent,
         RelatorioMapaQualidadeSeguradoConsolidadoComponent,
         RelatorioQualidadeSeguradoComponent,
         RelatorioLiquidacaoSentencaComponent,
         RelatorioRmiBeneficioComponent,
         RelatorioTabelaMatrizComponent,
         RelatorioAposentadoriaInvalidezComponent,
    
  ],
  imports: [
    CommonModule,
    RelatoriosRoutingModule,
    CompartilhadoModule,
    CompartilhadoModuleAplicacao,
    
  ],
  providers:[]
})
export class RelatoriosModule { }
