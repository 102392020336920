import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import * as currency from 'currency.js';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-simulador-desconto',
  templateUrl: './simulador-desconto.component.html',
  styleUrls: ['./simulador-desconto.component.scss']
})
export class SimuladorDescontoComponent {



  simuladorForm = this.fb.group({
    valorCheio: [''],
    valorFinal: [''],
    desconto: [''],
    descontoMeioPagamento: [''],
  });

  currencyInputMask = createMask(AppComponent.currencyInputOptions);
  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0'
  });


  valorCheio: number;
  valorFinal: number;
  desconto: number;
  descontoMeioPagamento: number;

  constructor(private fb: FormBuilder) {


    this.simuladorForm.get('valorCheio').valueChanges.subscribe(value => {
      this.valorCheio = currency(value, { separator: '.', decimal: ',' }).value;
      this.simuladorForm.get('valorFinal').setValue(value);
    });


    this.simuladorForm.get('desconto').valueChanges.subscribe(value => {
      this.desconto = currency(value, { separator: '.', decimal: ',' }).value;


      this.valorFinal = (this.valorCheio * (100 - this.desconto)) / 100;

      this.simuladorForm.get('valorFinal').setValue(this.valorFinal.toString());
    });


    this.simuladorForm.get('descontoMeioPagamento').valueChanges.subscribe(value => {
      this.descontoMeioPagamento = currency(value, { separator: '.', decimal: ',' }).value;

      


      this.simuladorForm.get('valorFinal').setValue(((this.valorFinal * (100 - this.descontoMeioPagamento)) / 100).toString());
    });


  }

}
