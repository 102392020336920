import { Component, Input, OnInit } from '@angular/core';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { RelatorioRendaMensalInicial } from 'src/app/modelos/previdenciario/relatoriorendamensalinicial';
import { RelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/relatoriotempocontribuicao';
import { RequestRelatorioRendaMensalInicial } from 'src/app/modelos/previdenciario/requestrelatoriorendamensalinicial';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';
import { RelatorioRendaMensalInicialService } from 'src/app/servicos/calculo/previdenciario/relatorio-renda-mensal-inicial.service';

@Component({
  selector: 'app-quadro-relatorio-renda-mensal-inicial',
  templateUrl: './quadro-relatorio-renda-mensal-inicial.component.html',
  styleUrls: ['./quadro-relatorio-renda-mensal-inicial.component.scss']
})
export class QuadroRelatorioRendaMensalInicialComponent implements OnInit {

  @Input() requestRelatorioRendaMensalInicial:RequestRelatorioRendaMensalInicial;
  @Input() requestRelatorioTempoContribuicao:RequestRelatorioTempoContribuicao;
  
  relatorioRendaMensalInicial:RelatorioRendaMensalInicial;




  rows:Contribuicao[];
  rowHeight:100;
  
  columns:any[];

  constructor(private relatorioRendaMensalInicialService:RelatorioRendaMensalInicialService) { 
    
  }

  ngOnInit(): void {
    this.requestRelatorioRendaMensalInicial.dataCalculo = this.requestRelatorioTempoContribuicao.dataCalculo;
    this.relatorioRendaMensalInicialService.relatorio(this.requestRelatorioRendaMensalInicial)
    .then(relatorio=>{

      this.relatorioRendaMensalInicial = relatorio as RelatorioRendaMensalInicial;
      this.rows = this.relatorioRendaMensalInicial.contribuicoes;
    });
  }
  

}
