import { Base } from "../base";

export class FiltrosMatriz extends Base{
    regraIdadeTransicao: boolean;
    regraPedagio50: boolean;
    regraPedagio100: boolean;
    regraIdadeMinimaProgressiva: boolean;
    regraPontos: boolean;
    aliquota11: boolean;
    aliquota20: boolean;
    salarioMinimo: boolean;
    salarioTeto: boolean;
}