import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ConfigService } from './../../../../../servicos/ui/config.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CalculoAnaliseCnis } from 'src/app/modelos/previdenciario/calculoanalisecnis';
import { ListarAnaliseCnisService } from 'src/app/servicos/calculo/previdenciario/listar-analise-cnis.service';
import { DadosSeguradoComponent } from '../dados-segurado/dados-segurado.component';
import { AnaliseCnisComponent } from '../analise-cnis/analise-cnis.component';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { BuracoTempoContribuicaoComponent } from '../buraco-tempo-contribuicao/buraco-tempo-contribuicao.component';
import { EvolucaoSalariosPosRealComponent } from '../evolucao-salarios-pos-real/evolucao-salarios-pos-real.component';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';

@Component({
  selector: 'app-resultado-analise-cnis',
  templateUrl: './resultado-analise-cnis.component.html',
  styleUrls: ['./resultado-analise-cnis.component.scss']
})
export class ResultadoAnaliseCnisComponent implements OnInit{

  @Input() calculoAnaliseCnis:CalculoAnaliseCnis;

  @ViewChild('dadosSegurado') dadosSegurado:DadosSeguradoComponent;
  @ViewChild('analiseCnis') analiseCnis:AnaliseCnisComponent;
  @ViewChild('buracoTempoContribuicao') buracoTempoContribuicao:BuracoTempoContribuicaoComponent;
  @ViewChild('evolucaoSalariosPosReal') evolucaoSalariosPosReal:EvolucaoSalariosPosRealComponent;

  
  contribuicoes:Contribuicao[];
  usuario:Usuario;

  constructor(private configService:ConfigService, private listarAnaliseCnisService:ListarAnaliseCnisService,
    private listarPDFService:ListarPDFService,
    private notificacoesService:NotificacoesService){

      this.configService.getUsuario().subscribe(usuario=>this.usuario = usuario);
    
  };

  ngOnInit(): void {
    // if(this.calculoAnaliseCnis && this.calculoAnaliseCnis.relatorioAnaliseCnis)this.carregarDados();
  }


  carregarDados(){
    // this.evolucaoSalariosPosReal.carregarDados(this.calculoAnaliseCnis.relatorioAnaliseCnis.beneficioBase)

    // // this.analiseCnis.carregarDados(calculo.calculoBeneficios.leituraCnis.cnis);
    
    // this.dadosSegurado.carregarDados(this.calculoAnaliseCnis.calculoBeneficios.leituraCnis.cnis);
    
    // this.buracoTempoContribuicao.carregarDados(this.calculoAnaliseCnis.calculoBeneficios);

  }

  
}
