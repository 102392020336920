import { Component } from '@angular/core';

@Component({
  selector: 'app-edicao-cnis',
  templateUrl: './edicao-cnis.component.html',
  styleUrls: ['./edicao-cnis.component.scss']
})
export class EdicaoCNISComponent {

}
