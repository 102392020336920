<div class=" header bg-civel pb-6">
    <div class=" container-fluid">
      <div class=" header-body">
        <div class=" row align-items-center py-3">
          <div class=" col-lg-12">
            <h6 class=" h2 text-white d-inline-block mb-0">Liquidação de Sentença Cível</h6>
            <nav
              aria-label="breadcrumb"
              class=" d-none d-md-inline-block ml-md-4"
            >
              <!-- <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
                <li class=" breadcrumb-item">
                  <a [routerLink]="['/aplicacaoapp/calculos/novocalculo']"> <i class=" fas fa-home"> </i> </a>
                </li>
                
              </ol> -->
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class=" container-fluid mt--3">

    <div class=" row">
      <div class=" col-lg-12">
        <div class=" card">
          <div class=" card-header border-dark bg-transparent">
            <h3 class=" mb-0">Novo Cálculo de Liquidação de Sentença Cível</h3>
          </div>
          <div class=" card-body">





            <div class="col-md-12 col-lg-12 col-xxl-12 pb-6 mb-6">
              <div>

                <tabset #tabsLiquidacaoSentencaCivel>

                  <tab>
                    <ng-template tabHeading>
                      <div class="col-1 p-4 mb-5">
                        <span class=" timeline-step timeline-step-xlg bg-black ">
                          1
                          
                        </span>
                      </div>
                    </ng-template>

                    <div class="mt-4 mb-4">
                      <div class="row mb-5">
                        <div class="col">
                          <h1 class="display-4"></h1>
                        </div>
                      </div>
                    </div>

                    <app-aguarde [visivel]="aguardeVisivel" ></app-aguarde>
                    
                  </tab>

                  <tab>
                    <ng-template tabHeading>
                      <div class="col-1 p-4 mb-5">
                        <span class=" timeline-step timeline-step-xlg bg-black ">
                          
                          <i class="bi-check2"></i>
                        </span>
                      </div>
                    </ng-template>

                    <div class="mt-4 mb-4">
                      <div class="row mb-5">
                        <div class="col">
                          <h1 class="display-4"></h1>
                        </div>
                      </div>
                    </div>

                    <app-aguarde [visivel]="aguardeVisivel" ></app-aguarde>
                    
                  </tab>

                </tabset>
              </div>
              
            </div>

        </div>
      </div>
    </div>
  </div>

  