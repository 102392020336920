import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { CalculoIncapacidadeTemporaria } from 'src/app/modelos/previdenciario/calculoincapacidadetemporaria';
import { ListarAuxilioIncapacidadeTemporariaService } from 'src/app/servicos/calculo/previdenciario/listar-auxilio-incapacidade-temporaria.service';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-resultado-incapacidade-temporaria',
  templateUrl: './resultado-incapacidade-temporaria.component.html',
  styleUrls: ['./resultado-incapacidade-temporaria.component.scss']
})
export class ResultadoIncapacidadeTemporariaComponent implements OnInit {

  @Input() calculoIncapacidadeTemporaria: CalculoIncapacidadeTemporaria;

  constructor(private listarAuxilioIncapacidadeTemporariaService: ListarAuxilioIncapacidadeTemporariaService,
    private notificacoesService:NotificacoesService,
    private listarPDFService:ListarPDFService,
    private configService: ConfigService) { }

  ngOnInit(): void {
    // this.carregarDados(this.calculoIncapacidadeTemporaria);

  }

  carregarDados(calculo: CalculoIncapacidadeTemporaria) {
    this.calculoIncapacidadeTemporaria = calculo
  }

  listarResultado(calculo:CalculoIncapacidadeTemporaria){
    this.listarAuxilioIncapacidadeTemporariaService.listar(calculo)
      .then((calculo: CalculoIncapacidadeTemporaria) => {
        this.configService.setAguarde(false);
        // console.log('listar resultado calculo incapacidade temporária', calculo);
        this.calculoIncapacidadeTemporaria = calculo;
        this.carregarDados(calculo);
      });
  }
  gerarPDF() {
    this.configService.setAguarde(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    this.calculoIncapacidadeTemporaria.tipoCalculo = CalculoTipos.INCAPACIDADETEMPORARIA;
    this.calculoIncapacidadeTemporaria.usuario = Global.usuario;

    this.listarPDFService.ListarPDF(this.calculoIncapacidadeTemporaria)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'AUXILIO-INCAPACIDADE-TEMPORARIA-'+ this.calculoIncapacidadeTemporaria.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
        this.configService.setAguarde(false);

      });
   }
}
