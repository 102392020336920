import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { Global } from 'src/app/modelos/global';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CriarContaRequest } from 'src/app/modelos/usuario/criarcontarequest';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { environment } from 'src/environments/environment';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';

@Injectable({
  providedIn: 'root'
})
export class BaixarMatrizPlanejamentoService {

  constructor(private httpClient:HttpClient) { }

  baixarMatrizPlanejamento(matriz:MatrizPlanejamento){
    return Promise.all([this.apicall(matriz)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(matriz:MatrizPlanejamento){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/BaixarMatrizPlanejamento',matriz, {'headers':headers}).toPromise();
    return data;
  }
}
