import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Ficha } from 'src/app/modelos/previdenciario/ficha';
import { ListaFichasComponent } from '../lista-fichas/lista-fichas.component';

@Component({
  selector: 'app-selecionar-ficha',
  templateUrl: './selecionar-ficha.component.html',
  styleUrl: './selecionar-ficha.component.scss'
})
export class SelecionarFichaComponent {
  @ViewChild("listaFichas") listaFichas: ListaFichasComponent;

  @Output() fichaSelecionadaEvent = new EventEmitter<Ficha>();

  constructor(){}

  selecionarFicha(ficha:Ficha){
    // console.log('ficha selecionada', ficha);
    this.fichaSelecionadaEvent.emit(ficha);
  }


  fichaAdicionada(ficha:Ficha){
    this.listaFichas.addFichaLista(ficha);
  }

  fichaSelecionada(ficha:Ficha){

    this.fichaSelecionadaEvent.emit(ficha);

  }
}
