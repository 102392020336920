<div *ngIf="ficha.perguntas && ficha.perguntas.length > 0">
    <div class="row">
        <div class="col-12">
            <p class="h3">
                Perguntas da Ficha
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <table class="my-4 table table-striped table-hover  align-middle" >
                <thead class="" style="display: table-header-group; page-break-inside: avoid !important;">
                  <tr class="text-center ">
                    <th scope="col">Título</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Instruções</th>
                    <th scope="col">Link</th>
                    <th scope="col">Opções</th>
                    <th scope="col">Tipo de Resposta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center" *ngFor="let pergunta of ficha.perguntas">
                    <td>{{pergunta.titulo }}</td>
                    <td>{{pergunta.descricao }}</td>
                    <td>{{pergunta.instrucao }}</td>
                    <td>{{pergunta.link }}</td>
                    <td>{{pergunta.tipoResposta | tipoResposta}}</td>
                    <td>
                        <div class="row">
                            <div class="col-auto p-0 m-0" *ngFor="let opcao of pergunta.opcoes">
                                <span class="badge rounded-pill text-bg-dark px-4 m-1">{{opcao}}</span>
                            </div>
                        </div>
                    </td>
                    
                  </tr>
                  
                </tbody>
              </table>
        </div>
    </div>
</div>