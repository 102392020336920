import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';

import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ListarClienteService } from 'src/app/servicos/usuario/listar-cliente.service';




@Component({
  selector: 'app-clientes-dashboard',
  templateUrl: './clientes-dashboard.component.html',
  styleUrls: ['./clientes-dashboard.component.scss']
})
export class ClientesDashboardComponent implements OnInit {

  atualizandoListaClientes:boolean;
  listaClientes:Cliente[];

  constructor(){}

  novaMatriz(){}

  ngOnInit() {
  }
  
  novoCliente(){}


}