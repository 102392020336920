import { Pipe, PipeTransform } from '@angular/core';
import { Marcador } from 'src/app/modelos/common/marcador';
import { TiposMarcador } from 'src/app/modelos/common/tiposmarcador';

@Pipe({
  name: 'tintaFundo',
})
export class TintaFundoPipe implements PipeTransform {

  transform(value: Marcador[], ...args: unknown[]): string {

    let tinta: string = "";
    if (value) {
      let buscaMarcadorTintaFundo = value.filter(marcador => marcador.tipoMarcador === TiposMarcador.TINTAFUNDO);
      // console.log('busca marcador tinta fundo', buscaMarcadorTintaFundo);


      if (buscaMarcadorTintaFundo.length > 0) {

        tinta = buscaMarcadorTintaFundo.reverse()[0].cor;

      }

    }

    return tinta;
  }

}
