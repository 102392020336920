import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { blob } from 'stream/consumers';

@Injectable({
  providedIn: 'root'
})
export class CalculeiApiService {

  private apiBase = environment.api;
  private apiBaseLx = environment.apiLx;
  private apiBasePaginas = environment.apiPaginas;
  private apiServices = environment.apiServices;

  constructor(private http: HttpClient) { }

  request(){

  }

  testeApi(): Observable<object>{
    const headers = { 'Access-Control-Allow-Origin': '*'};
    return this.http.get<object>(this.apiBase + 'teste', {'headers': headers} );
  }

  api(api:string, payload:object | object[]){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    return this.http.post<object>(this.apiBase+ 'api/' + api,payload, {'headers': headers} );
  }
  apiLx(api:string, payload:object | object[]){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    return this.http.post<object>(this.apiBaseLx+ 'api/' + api,payload, {'headers': headers} );
  }

  apiBlob(api:string, payload:object | object[]){
    const headers = { 'Access-Control-Allow-Origin': '*', };
    return this.http.post(this.apiBase + 'api/' + api, payload, {responseType: 'blob'});
    
  }
  apiBlobLx(api:string, payload:object | object[]){
    const headers = { 'Access-Control-Allow-Origin': '*', };
    return this.http.post(this.apiBaseLx + 'api/' + api, payload, {responseType: 'blob'});
    
  }
  apiBlobServices(api:string, payload:object | object[]){
    const headers = { 'Access-Control-Allow-Origin': '*', };
    return this.http.post(this.apiServices + 'api/' + api, payload, {responseType: 'blob'});
    
  }

  apiPaginas(api:string, payload:object | object[]){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    return this.http.post<object>(this.apiBasePaginas + 'api/' + api,payload, {'headers': headers} );
  }

  
}
