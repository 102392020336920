import { Component, OnInit } from '@angular/core';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { RequestRelatorioRendaMensalInicial } from 'src/app/modelos/previdenciario/requestrelatoriorendamensalinicial';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';


@Component({
  selector: 'app-relatorio-tempo-contribuicao',
  templateUrl: './relatorio-tempo-contribuicao.component.html',
  styleUrls: ['./relatorio-tempo-contribuicao.component.scss']
})
export class RelatorioTempoContribuicaoComponent implements OnInit {

  cnis:CNIS;

  requestRelatorioTempoContribuicao:RequestRelatorioTempoContribuicao;
  requestRelatorioRendaMensalInicial:RequestRelatorioRendaMensalInicial;

  aguardeVisivel:boolean=false;
  passo0Visivel:boolean=false;
  passo1Visivel:boolean=true;
  passo2Visivel:boolean=false;
  passo3Visivel:boolean=false;
  passo4Visivel:boolean=false;
  relatorioVisivel:boolean=false;

  constructor() { 
   
  }


  relatorioPronto(pronto:boolean){
    this.aguardeVisivel=false;
    this.passo3Visivel=true;
  }

  gerarRMI(){
    this.passo4Visivel = true;

    let requestRMI = new RequestRelatorioRendaMensalInicial();
    requestRMI.cnis = this.cnis;
    requestRMI.dataCalculo = this.requestRelatorioTempoContribuicao.dataCalculo;
    this.requestRelatorioRendaMensalInicial = requestRMI;
  }

  gerarRelatorio(){

    this.passo2Visivel=false;
    this.aguardeVisivel=true;
    this.aguardeVisivel=false;
    this.passo3Visivel=true;
  }
  gerarRelatorioTempoContribuicao(request:RequestRelatorioTempoContribuicao){

    this.requestRelatorioTempoContribuicao = request;
    this.passo2Visivel = false;
    this.aguardeVisivel=true;

  }

  preencherFormulario(){

    this.passo1Visivel = false;
    this.passo2Visivel = true;

  }

  processandoCNIS(processando:boolean){
    this.aguardeVisivel = processando;
    this.passo1Visivel = !processando;
  }

  leituraCNIS(cnis:CNIS){
    this.cnis = cnis;
    this.passo1Visivel = false;
    this.passo2Visivel = true;
    
  }

  ngOnInit(): void {

    // this.passo0Visivel=false;
    // this.passo1Visivel=false;
    // this.passo2Visivel=false;
    // this.passo3Visivel=true;

    // this.passo0Visivel=true;
    // this.passo1Visivel=true;
    // this.passo2Visivel=true;
    // this.passo3Visivel=true;

    
  }



}
