<div class=" header bg-previdenciario pb-6">
    <div class=" container-fluid">
        <div class=" header-body">
            <div class=" row align-items-center py-3">
                <div class=" col-lg-12">
                    <span class=" text-white inline-block mb-0 h6 fw-lighter">Revisão do Extrato de Tempo de
                        Contribuição</span>
                    <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
                        <!-- <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
                <li class=" breadcrumb-item">
                  <a [routerLink]="['/aplicacaoapp/calculos/novocalculo']"> <i class=" fas fa-home"> </i> </a>
                </li>
              </ol> -->
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" container-fluid mt--3 ">

    <div class=" row">
        <div class=" col-lg-12">
            <div class=" card">
                <div class=" card-header border-dark">
                    {{titulo}}
                </div>
                <div class=" card-body">
                    <div class="col-md-12 col-lg-12 col-xxl-12 pb-6 mb-6">
                        <div>

                            <tabset #tabsRevisaoTempoServico>
                                <tab>
                                    <ng-template tabHeading>
                                        <div class="col-1 p-4 mb-5">
                                            <span class=" timeline-step timeline-step-xlg bg-black ">
                                                1
                                            </span>
                                        </div>
                                    </ng-template>

                                    <div class="mt-4 mb-4">
                                        <div class="row mb-5">
                                            <div class="col">
                                                <h1 class="display-4">Importação do Extrato de Contribuições do Prisma
                                                </h1>
                                            </div>
                                        </div>

                                        <div>
                                            <app-importador-extrato-prisma
                                                [calculoRevisaoTempoServico]="calculoRevisaoTempoServico"
                                                (processandoImportacaoExtratoPrisma)="processandoImportacaoExtratoPrisma($event)"
                                                (importacaoExtratoPrismaEvent)="importacaoExtratoPrisma($event)">
                                            </app-importador-extrato-prisma>
                                        </div>
                                    </div>
                                </tab>

                                <tab id="tabResultado">
                                    <ng-template tabHeading>
                                        <div class="col-1 p-4 mb-5">
                                            <span class=" timeline-step timeline-step-xlg bg-default">
                                                <i class="bi-check2"></i>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <div class="mt-4 mb-4">
                                        <div class="row">
                                            <div class="col-md-auto">
                                                <h1 class="display-4">Resultado</h1>
                                            </div>

                                        </div>

                                        <div>
                                            <app-relatorio-extrato-prisma
                                                *ngIf=" calculoRevisaoTempoServico!=undefined && calculoRevisaoTempoServico.relatorio != undefined"
                                                [calculoRevisaoTempoServico]="calculoRevisaoTempoServico">
                                            </app-relatorio-extrato-prisma>
                                        </div>
                                        <div>
                                        </div>
                                    </div>

                                </tab>



                                <tab>
                                    <ng-template tabHeading>
                                        <app-aguarde [visivel]="aguardeVisivel"></app-aguarde>
                                    </ng-template>

                                </tab>







                            </tabset>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>