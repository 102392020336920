<app-cabecalho [titulo]="'Matrizes de Planejamento'"></app-cabecalho>

<div class="container-fluid mt--3">
    <div class="card">
        <div class="card-body">

            <div class="row rounded bg-secondary p-2 m-2">
                <div class="col-auto">
                    <div class="text-center">
                        <i class="comando bi bi-plus-square-dotted fs-4 px-0 mx-0" container="body" ngbTooltip="Criar nova matriz" triggers="hover" role="button" (click)="novaMatriz()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMatriz" aria-controls="offcanvasMatriz" ></i>
                        <p class="h6">
                            Criar nova <br>matriz de 
                            <br>planejamento
                        </p>
                    </div>
                </div>
            </div>

          

            <!-- aguarde -->
            <div class="row">
                <app-aguarde-local [aguarde]="atualizandoListaMatrizes"></app-aguarde-local>
            </div>

            <!-- listagem calculos -->
            <div class="row flex d-flex">
                <div class="col-3" *ngFor="let matriz of listaMatrizes">
                    <div class="bg-secondary rounded m-2 p-4" role="button" (click)="abrirMatriz(matriz)">
                        <div class="row flex d-flex">
                            <div class="col-10">
                                <p class="h2">
                                    {{matriz.nomeCalculo}}
                                </p>
                                <p class="h6 m-0 p-0">
                                    {{matriz.data | date : 'dd/MM/yyyy HH:mm'}}
                                </p>
                                
                            </div>
                            <div class="col-2">
                                <div [id]="matriz.id" class="spinner-grow my-2 text-right collapse" style="width: 1rem; height: 1rem;" role="status"></div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="offcanvas w-50 offcanvas-end" data-bs-scroll="false"  tabindex="-1" id="offcanvasMatriz" aria-labelledby="offCanvasMatrizLabel">
    <div class="offcanvas-header">
      <h5 *ngIf="matrizCanvas" class="offcanvas-title" id="offcanvasBottomLabel">{{matrizCanvas.nome}}</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row p-0 mb-4 m-0">
        <div class="col-12">
            <input [(ngModel)]="matrizCanvas.nome" #inputTitulo id="inputTitulo" type="text" class="tituloedit p-0 m-0" (input)="edicaoTitulo($event)" (blur)="blurEditorTitulo($event)" (focus)="focoEditorTitulo($event)">
            <p class="h6">
                Referência da matriz - clique para editar
            </p>
        </div>
      </div>
      <div class="row p-0 m-0 text-left">
        <!-- matriz -->
      </div>
    </div>
  </div>
