import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { BehaviorSubject } from 'rxjs';
import { Arquivo } from 'src/app/modelos/faturas/arquivo';
import { Global } from 'src/app/modelos/global';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { ImportacaoCNIS } from 'src/app/modelos/previdenciario/importacaocnis';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { AzureBlobService } from 'src/app/servicos/azure/azure-blob.service';
import { ImportarCNISService } from 'src/app/servicos/calculo/previdenciario/importar-cnis.service';
import { SalvarLeituraCnisService } from 'src/app/servicos/calculo/previdenciario/salvar-leitura-cnis.service';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-importador-cnis',
  templateUrl: './importador-cnis.component.html',
  styleUrls: ['./importador-cnis.component.scss']
})
export class ImportadorCNISComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;
  @Output() leituraCNISEvent = new EventEmitter<LeituraCNIS>();
  

  importacaoCNIS: ImportacaoCNIS;
  aguarde:boolean;

  progressoImportacao = new BehaviorSubject<Number>(0);

  setProgressoImportacao(progresso: Number) {
    this.progressoImportacao.next(progresso);
  }
  getProgressoImportacao(): Number {
    return this.progressoImportacao.getValue();
  }

  constructor(private autenticacaoService: AutenticacaoService,
    private azureBlobService: AzureBlobService,
    private configService: ConfigService,
    private changeDetector: ChangeDetectorRef,
    private importarCNISService: ImportarCNISService,
    private salvarLeituraCnisService: SalvarLeituraCnisService) {

      this.configService.getAguarde().subscribe(status=>this.aguarde=status);

    this.progressoImportacao.subscribe(progresso => {
      if (progresso == 100) {
        setTimeout(() => {
          let leituraCnis = new LeituraCNIS();
          leituraCnis.rowKey = uuidv4();
          leituraCnis.partitionKey = Global.usuario.rowKey;
          leituraCnis.usuario = Global.usuario;
          leituraCnis.importacaoCnis = this.importacaoCNIS;
          leituraCnis.importacaoCnis.partitionKey = leituraCnis.rowKey;

          this.salvarLeituraCnisService.salvarLeituraCnis(leituraCnis)
            .then(leituraCnis => {
              this.configService.inserirHistoricoLeituraCnis(leituraCnis as LeituraCNIS);
              this.leituraCNISEvent.emit(leituraCnis as LeituraCNIS);
              this.configService.setAguarde(false);
              
            });
          // this.importarCNISService.importarCNIS(this.importacaoCNIS)
          //   .then(cnis => {
          //     this.processandoCNISEvent.emit(false);
          //     let leituraCNIS = new LeituraCNIS();
          //     leituraCNIS.cnis = cnis as CNIS;
          //     leituraCNIS.importacaoCnis = this.importacaoCNIS;
          //     this.leituraCNISEvent.emit(leituraCNIS);

          //   });
        }, 1000);
      }
    })
  }


  selecionarArquivo(event: Event): void {

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.changeDetector.detectChanges();
    this.configService.setAguarde(true);
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;


    // this.fileInput.nativeElement.value === '';

    this.uploadFile(files[0]);


  }

  uploadFile(file: File) {

    this.importacaoCNIS = new ImportacaoCNIS();
    this.importacaoCNIS.rowKey = uuidv4();
    // this.importacaoCNIS.partitionKey = Global.usuario.rowKey;
    this.importacaoCNIS.arquivos = [];

    this.importacaoCNIS.dataImportacao = new Date(Date.now());

    let arquivo: Arquivo = new Arquivo();
    arquivo.referenciaLocal = uuidv4() + ".pdf";
    arquivo.referenciaCloud = (Global.usuario.rowKey + "/" + this.importacaoCNIS.rowKey).toLowerCase();
    arquivo.partitionKey = this.importacaoCNIS.rowKey;
    arquivo.nomeOriginal = file.name;
    arquivo.descricao = "CNIS Original";
    arquivo.rowKey = uuidv4();

    this.importacaoCNIS.arquivos.push(arquivo);
    this.importacaoCNIS.arquivosKeys = this.importacaoCNIS.arquivos.map(arquivo => arquivo.rowKey);

    this.azureBlobService.upLoadArquivo(arquivo, file)
      .subscribe(progress => {

        this.setProgressoImportacao(progress);
      });
  }

  showFileDialog(): void {
    this.fileInput.nativeElement.click();


  }

  // baixarPDF(){

  //   var pdfLink = "https://download.in.gov.br/sgpub/do/secao1/2022/2022_06_24/2022_06_24_ASSINADO_do1.pdf?arg1=kEKUiDF4cKbjDD6pkwWviw&amp;arg2=1656129356"
  //   window.open(pdfLink,"PDFFrame","width=800,top=5,left=5,height=800,location=no,statusbar=no,menubar=yes,toolbar=no,scrollbars=yes,resizable=yes");
  // }

  ngOnInit(): void {
  }

  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);

          this.changeDetector.detectChanges();
          
          this.configService.setAguarde(true);

          window.scroll({ top: 0, left: 0, behavior: 'smooth' });

          this.uploadFile(file);

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

}
