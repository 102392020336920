<ng-template #longContent let-modal>
    <div class="row">
        <div class="col-12">
            <div class="modal-header">
                <span class="display-4">{{relacaoPrevidenciaria.sequencia}}</span>
                <button type="button" class="btn-close" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <span class="display-4">{{relacaoPrevidenciaria.origemVinculo}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
            </div>
        </div>
    </div>
</ng-template>