import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeituraJsonBlobService {

  constructor() { }

  ListarJsonBlob(url:string, cb) {
    const req = new XMLHttpRequest();
    req.open('GET', url);

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }
}
