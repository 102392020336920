import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { Entrevista } from 'src/app/modelos/previdenciario/entrevista';

@Component({
  selector: 'app-editor-opcoes-entrevista',
  templateUrl: './editor-opcoes-entrevista.component.html',
  styleUrl: './editor-opcoes-entrevista.component.scss'
})
export class EditorOpcoesEntrevistaComponent {

  inputDataValidade:Date = new Date((new Date()).setMonth((new Date()).getMonth() + 1));;



  @Input() entrevista:Entrevista;

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);
  
  
  constructor(){
    this.dateInputMask.autoUnmask = true;
    
    this.dateInputMask.oncomplete = (()=>{

      // console.log('input data validade ', this.inputDataValidade)
      this.entrevista.validade = this.inputDataValidade;

    });
  }
}
