<div class="py-1">
    <div class="row">
      <div class="col">
        <p class="h3">Marcar com Tinta</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Cor
        </h5>
        <div class="row">
          <div class="col">
            <input type="color" class="form-control form-control-color" #inputCor value="#000000" title="Escolha uma cor">
          </div>
          <div class="col-auto self-align-center">
            <i class="comando bi bi-arrow-right" role="button" (click)="addTinta()"></i>
          </div>
        </div>
        <p class="h6">
          Cor de fundo da célula
        </p>
      </div>
      
      
    </div>
    
  </div>