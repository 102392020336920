import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '../layouts/admin-layout/admin-layout.component';
import { AutorizacaoAdminPagamentoService } from '../servicos/oauth/autorizacao-admin-pagamento.service';
import { AplicacaoComponent } from './aplicacao.component';
import { AutorizacaoAdminContasService } from '../servicos/oauth/autorizacao-admin-contas.service';
import { PaginaInicialComponent } from './pagina-inicial/pagina-inicial.component';

const routes: Routes = [
  {
    path: '',
    component:AdminLayoutComponent,
    children:[
      {
        path:'',
        redirectTo:'home',
        pathMatch:'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'usuario',
        loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule)
      },
      {
        path: 'calculos',
        loadChildren: () => import('./calculos/calculos.module').then(m => m.CalculosModule)
      },

      {
        path: 'entrevistas',
        loadChildren: () => import('./entrevistas/entrevistas.module').then(m => m.EntrevistasModule)
      },
      {
        path: 'home',
        component: PaginaInicialComponent,
      },


      {
        path: 'contas',
        canActivate:[AutorizacaoAdminContasService],
        loadChildren: () => import('./contas/contas.module').then(m => m.ContasModule)
      },
      {
        path: "**",
        redirectTo: "home"
      }
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AplicacaoRoutingModule { }
