import { CompartilhadoModule as CompartilhadoPrevidenciario } from './../../calculos/previdenciario/compartilhado/compartilhado.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompartilhadoDashboardComponent } from '../compartilhado-dashboard/compartilhado-dashboard.component';
import { CalculoComponent } from './calculo/calculo.component';
import { ClienteComponent } from './cliente/cliente.component';



import { ClientePreviewComponent } from './cliente-preview/cliente-preview.component';

import { CalculosListagemComponent } from './calculos-listagem/calculos-listagem.component';
import { CompartilhadoModule } from '../../compartilhado/compartilhado.module';
import { CnisListagemComponent } from './cnis-listagem/cnis-listagem.component';
import { AnonimoPipe } from '../../compartilhado/pipes/anonimopipe';
import { ClientesListagemComponent } from './clientes-listagem/clientes-listagem.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';




@NgModule({
  declarations: [
    CompartilhadoDashboardComponent,
    CalculoComponent,
    ClienteComponent,
    
    
    ClientePreviewComponent,
    
    CalculosListagemComponent,
    CnisListagemComponent,
    ClientesListagemComponent,
    
  ],
  imports: [
    CommonModule,
    CompartilhadoModule,
    CompartilhadoPrevidenciario,
    
    NgbModule
  ],
  exports:[
    CalculoComponent,
    ClienteComponent,
    
    
    
    ClientePreviewComponent,
    CalculosListagemComponent,
    CnisListagemComponent,
    ClientesListagemComponent,
    

  ],
  
})
export class CompartilhadoDashboardModule { }
