export enum IdentificadorIndice {
    IPCA,
    INPC,
    IGPM,
    IGP10,
    IGPDI,
    SELIC,
    TR,
    IPCAE,
    TABELAATUALIZACAOSALARIO,
    POUPANCA,
    NAOAPLICARINDICE,
    BENEFICIOATRASO,
    ATUALIZACAOBENEFICIOPREVIDENCIARIOCJF,
    TABELASALARIOMINIMOTETOINSSCALCULOJURIDICO,
    ORTN,
    OTN,
    IPCIBGE4272,
    IPCIBGE1014,
    BTN,
    IPCIBGE,
    IRSM,
    CONVERSAOURV,
    IPCR,
    TABELACORRECAOMONETARIAMANUALCALCULOCJF,
    METASELIC
}