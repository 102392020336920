import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ListarRelatorioRendaMensalInicialPdfService {

  constructor(private httpClient:HttpClient) { }


  ListarPDF(beneficio:Beneficio) :Observable<Blob>{
    return this.httpClient.post(environment.api as string + 'api/BeneficioPDF', beneficio, {responseType: 'blob'});
  }
 
}
