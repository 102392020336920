import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { GerarCnisService } from 'src/app/servicos/calculo/previdenciario/gerar-cnis.service';

import { FormBuilder, Validators } from '@angular/forms';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { IdentificacaoFiliado } from 'src/app/modelos/previdenciario/identificacaofiliado';
import { Sexo } from 'src/app/modelos/previdenciario/sexo';
import { AppComponent } from 'src/app/app.component';
import { createMask } from '@ngneat/input-mask';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { Global } from 'src/app/modelos/global';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';

@Component({
  selector: 'app-montagem-leitura-cnis',
  templateUrl: './montagem-leitura-cnis.component.html',
  styleUrls: ['./montagem-leitura-cnis.component.scss']
})
export class MontagemLeituraCnisComponent implements OnInit{

  @ViewChild('longContent') long;
  @Output() gerarLeituraCnisMontagemFinalizada = new EventEmitter<LeituraCNIS>();
	closeResult: string;

  constructor(private modalService: NgbModal,
    private configService:ConfigService,
	private gerarCnisService: GerarCnisService, private fb: FormBuilder) { }

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);
  

  modalRef:NgbModalRef;

  leituraCnis: LeituraCNIS;

  formCnis = this.fb.group({
    nome: ['', Validators.required],
    dataNascimento: ['', Validators.required],
    sexo: ['', Validators.required],
    
    

  });

  aguardeVisivel:boolean;
  continuarHabilitado:boolean;

  

  ngOnInit(): void {


    // this.formCnis.get('sexo').setValue(Sexo[this.leituraCnis.cnis.identificacaoFiliado.sexo]);

    this.formCnis.statusChanges
    .subscribe(()=>{
      if(this.formCnis.valid){
        this.continuarHabilitado = true;
      }else{
        this.continuarHabilitado = false;
      }
    });


  }

  gerarCnis() {

  }

  editarSexoSelecionado(){

    // console.log(this.formCnis.get('sexo').value);
    // console.log(Sexo[this.formCnis.get('sexo').value]);
  }

  continuar(){
    
    this.leituraCnis = new LeituraCNIS();

    this.aguardeVisivel = true;

    this.leituraCnis.cnis = new CNIS();
    this.leituraCnis.usuario = Global.usuario;
    this.leituraCnis.cnis.identificacaoFiliado = new IdentificacaoFiliado();

    this.leituraCnis.cnis.identificacaoFiliado.nome = this.formCnis.get('nome').value;
    this.leituraCnis.cnis.identificacaoFiliado.dataNascimento = new Date(this.formCnis.get('dataNascimento').value);
    this.leituraCnis.cnis.identificacaoFiliado.sexo = Sexo[this.formCnis.get('sexo').value];
    
    this.leituraCnis.cnis.identificacaoFiliado.dataExtratoPrevidenciario = new Date(Date.now());
    this.leituraCnis.cnis.relacoesPrevidenciarias = new Array<RelacaoPrevidenciaria>();
    


    this.gerarCnisService.gerarCnis(this.leituraCnis)
    .then(leituraCnis=>{
      this.leituraCnis = leituraCnis as LeituraCNIS;
      // console.log('leitura cnis retorno');
      // console.log(this.leituraCnis);
      this.aguardeVisivel = false;
      this.gerarLeituraCnisMontagemFinalizada.emit(this.leituraCnis)
      this.modalService.dismissAll();
    });



  }

	// openBackDropCustomClass(content) {
	// 	this.modalService.open(content, { backdropClass: 'light-blue-backdrop' });
	// }

	// openWindowCustomClass(content) {
	// 	this.modalService.open(content, { windowClass: 'dark-modal' });
	// }

	// openSm(content) {
	// 	this.modalService.open(content, { size: 'sm' });
	// }

	// openLg(content) {
	// 	this.modalService.open(content, { size: 'lg' });
	// }

	// openXl(content) {
	// 	this.modalService.open(content, { size: 'xxl', fullscreen: true });
	// }

	// openFullscreen(content) {
	// 	this.modalService.open(content, { fullscreen: true });
	// }

	// openVerticallyCentered(content) {
	// 	this.modalService.open(content, { centered: true });
	// }

	public openModal() {

		this.modalRef = this.modalService.open(this.long, { size: 'xl', scrollable: true, centered: true });

	}

  cancelar(){
    this.modalRef.close();
  }

	// openScrollableContent(longContent) {
	// 	this.modalService.open(longContent, { size: 'xl', scrollable: true, fullscreen: true });
	// }

}
