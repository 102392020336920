import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmarEmailRequest } from 'src/app/modelos/usuario/confirmaremailrequest';
import { ConfirmarEmailResponse } from 'src/app/modelos/usuario/confirmaremailresponse';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { ConfirmarEmailService } from 'src/app/servicos/usuario/confirmar-email.service';
import { EnviarEmailConfirmacaoService } from 'src/app/servicos/usuario/enviar-email-confirmacao.service';

@Component({
  selector: 'app-confirmar-email',
  templateUrl: './confirmar-email.component.html',
  styleUrls: ['./confirmar-email.component.scss']
})
export class ConfirmarEmailComponent implements OnInit {

  isCollapsed = true;
  aguarde = true;
  id:string;
  confirmarEmailRequest:ConfirmarEmailRequest;
  confirmarEmailResponse:ConfirmarEmailResponse;
  usuario:Usuario;
  usuarioPronto:boolean;

  constructor(private route: ActivatedRoute,
    private autenticacaoService:AutenticacaoService,
    private enviarEmailConfirmacaoService:EnviarEmailConfirmacaoService) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params =>{
      this.id = params.id;
      this.confirmarEmailRequest = new ConfirmarEmailRequest();
      this.confirmarEmailRequest.id = this.id;
      this.enviarEmailConfirmacaoService.enviarEmailConfirmacao(this.confirmarEmailRequest)
      .then(confirmarEmailResponse=>{
        this.usuarioPronto = true;
        this.confirmarEmailResponse = confirmarEmailResponse as ConfirmarEmailResponse;
        this.usuario = this.confirmarEmailResponse.usuario;
        

        
        
      });


      
    });

    
  }

  login(){
    this.autenticacaoService.login();
  }
}
