import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class SalvarMontagemCasoPlanejamentoKertzmanService {

  constructor(private httpClient: HttpClient) { }

  salvarMontagem(request:CalculoPlanejamentoKertzman){

    let requestClean:CalculoPlanejamentoKertzman = JSON.parse(JSON.stringify(request));
    requestClean.simulacoes = [];
    return Promise.all([this.apiCall(request)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(request:CalculoPlanejamentoKertzman){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    
    const data = this.httpClient.post(environment.api as string + 'api/SalvarCalculoPlanejamentoKertzman',request, {'headers':headers}).toPromise();
    return data;
  }
}
