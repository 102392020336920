<app-cabecalho [titulo]="'Meus Cálculos'"></app-cabecalho>

<div class="container-fluid mt--3">
  <div class="card">
    <div class="card-body">

      <div class="row rounded bg-secondary p-2 m-2">
        <div class="col-auto">
            <div class="text-center">
                <i class="comando bi bi-plus-square-dotted fs-4 px-0 mx-0" container="body" ngbTooltip="Criar nova matriz" triggers="hover" role="button" (click)="novoCalculo()"></i>
                <p class="h6">
                    Criar novo <br>cálculo
                </p>
            </div>

        </div>

    </div>

      <!-- aguarde -->
      <div class="row">
        <app-aguarde-local [aguarde]="atualizandoListaCalculos"></app-aguarde-local>
      </div>

      <!-- listagem calculos -->
      <div class="row flex d-flex">
        <div class="col-3" *ngFor="let calculo of listaCalculos">
          <div class="bg-secondary rounded m-2 p-4" role="button" (click)="abrirCalculo(calculo)">
            <div class="row flex d-flex">
              <div class="col-10">
                <p class="h2">
                  {{calculo.nomeCalculo}}
                </p>
                <p class="h6 m-0 p-0">
                  {{calculo.data | date : 'dd/MM/yyyy HH:mm'}}
                </p>
                <p>
                  {{calculo.tipoCalculo | tipocalculo}}
                </p>
              </div>
              <div class="col-2">
                <div [id]="calculo.id" class="spinner-grow my-2 text-right collapse" style="width: 1rem; height: 1rem;" role="status"></div>

              </div>
            </div>
            

            <!-- <p class="bg-red align-self-end text-right">
              
              
            </p> -->

          </div>
          <!-- <app-calculos-listagem></app-calculos-listagem> -->
        </div>
      </div>
    </div>
  </div>
</div>
