import { Pipe, PipeTransform } from '@angular/core';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';

@Pipe({
  name: 'filtroCasosMatriz',
  
})
export class FiltroCasosMatrizPipe implements PipeTransform {

  transform(value: CasoPlanejamentoPrevidenciario[], ...args: unknown[]): CasoPlanejamentoPrevidenciario[] {
    return value.filter(caso=>!caso.filtrarMatriz);
  }

}
