import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name:'anonimo'})
export class AnonimoPipe implements PipeTransform{

    transform(value: string, ...args: any[]): string {
        return value.substring(0, value.indexOf(" ") + 1);
    }

}