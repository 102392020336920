


<div class="row ">
    <div class="col-12 ">

        <div class="d-flex justify-content-center">
            <div class="row py-4">
                <div class="col-12">
                    <span class=" display-3 mb-0 text-default ">
                        Obrigado por testar o calculei <i class="bi bi-balloon-heart"></i>
                    </span>
                </div>
                
            </div>
            
          </div>

    </div>
</div>

<div class="row ">
    <div class="col-12 ">
        <div class="d-flex ">
            <div class="row py-4 justify-content-center">
                <div class="col-6">
                    <p class="text-default lh-1">
                        Estamos trabalhando para que o calculei seja uma ferramenta que permita que o seu trabalho seja mais eficiente e alcance mais pessoas.
                        Quando você assina ou deixa o seu feedback, isso nos ajuda a dar um passo a mais na direção correta.
                    </p>
                   
                    <p class="text-default lh-1s">
                        como deseja continuar?
                    </p>
                </div>
            </div>
          </div>

    </div>
</div>



<div class="row ">
    <div class="col-12 ">
        <div class="d-flex justify-content-center">
            <div class="row py-4">
                <div class="col-12">
                    <button class="btn btn-default" (click)="queroAssinar()">quero assinar</button>
                    <button *ngIf="prorrogacoes < 10" class="btn btn-outline-black" (click)="queroDeixarFeedback()">quero deixar um feedback e testar mais uma vez</button>
                    

                </div>
            </div>
          </div>

    </div>
</div>





