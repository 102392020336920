import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import * as currency from 'currency.js';
import { NgxSelectOption } from 'ngx-select-ex';
import { MeiosPagamentoPipe } from 'src/app/aplicacao/compartilhado/pipes/meiospagamentopipe';
import { AppComponent } from 'src/app/app.component';
import { MeiosPagamento } from 'src/app/modelos/pagamento/meiospagamento';
import { Plano } from 'src/app/modelos/pagamento/plano';
import { CriarPlanoRequest } from 'src/app/modelos/safe2pay/criarplanorequest';
import { CriarPlanoResponse } from 'src/app/modelos/safe2pay/criarplanoresponse';
import { DayOfWeek } from 'src/app/modelos/safe2pay/dayofweek';
import { DiscountType } from 'src/app/modelos/safe2pay/discounttype';
import { PlanFrequence } from 'src/app/modelos/safe2pay/planfrequence';
import { PlanOption } from 'src/app/modelos/safe2pay/planoption';
import { PeriodoPlano } from 'src/app/modelos/usuario/periodoplano';
import { CriarPlanoService } from 'src/app/servicos/pagamento/safe2pay/criar-plano.service';
import { InserirPlanoService } from 'src/app/servicos/pagamento/safe2pay/inserir-plano.service';


@Component({
  selector: 'app-novo-plano',
  templateUrl: './novo-plano.component.html',
  styleUrls: ['./novo-plano.component.scss']
})
export class NovoPlanoComponent implements OnInit, AfterViewInit {


  criarPlanoRequest: CriarPlanoRequest;
  plano: Plano;

  listaPlanOption: any[];
  planOptionDefault: any;

  listaDayOfWeek: any[];
  dayOfWeekDefault: any;


  listaPlanFrequence: any[];
  planFrequenceDefault: any;

  listaDiscountType: any[];
  discountTypeDefault: any;


  listaMeiosPagamento:any[];

  planoForm = this.fb.group({

    planOption: [''],
    planFrequence: [''],
    dayOfWeek: [''],
    name: [''],
    description: [''],
    amount: [''],
    chargeDay: [''],
    subscriptionTax: [''],
    subscriptionLimit: [''],
    isImmediateCharge: [''],
    billingCycle: [''],
    daysBeforeChargeDateExpiration: [''],
    callbackUrl: [''],
    daysDue: [''],

    instruction: [''],
    penaltyAmount: [''],
    interestAmount: [''],

    discountType: [''],
    discountDue: [''],
    discountAmount: [''],
    isProRata: [''],
  });

  inserirPlanoForm = this.fb.group({
    descricaoPagamento: [''],
    vantagensPagamento:[''],
    desconto:[''],
    descontoMeioPagamento:[''],
    valorOriginal:[''],
    meioPagamento: [''],
  });

  currencyInputMask = createMask(AppComponent.currencyInputOptions);
  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0'
  });



  continuarHabilitado: boolean;
  aguardeVisivel: boolean;

  constructor(private fb: FormBuilder, private criarPlanoService: CriarPlanoService,
    private inserirPlanoService: InserirPlanoService, private meioPagamentoPipe:MeiosPagamentoPipe) {

    this.criarPlanoRequest = new CriarPlanoRequest();

    this.listaPlanOption = (Object.keys(PlanOption) as Array<keyof typeof PlanOption>)
      .filter(chave => isNaN(Number(chave)))
      .map(option => { return { value: option, text: PlanOption[PlanOption[option]] } });





    // this.listaDayOfWeek = (Object.keys(DayOfWeek) as Array<keyof typeof DayOfWeek>)
    //   .filter(chave => isNaN(Number(chave)))
    //   .map(day => { return { value: day, text: DayOfWeek[DayOfWeek[day]] } });



    this.listaPlanFrequence = (Object.keys(PlanFrequence) as Array<keyof typeof PlanFrequence>)
      .filter(chave => isNaN(Number(chave)))
      .map(frequence => { return { value: frequence, text: PlanFrequence[PlanFrequence[frequence]] } });



    this.listaDiscountType = (Object.keys(DiscountType) as Array<keyof typeof DiscountType>)
      .filter(chave => isNaN(Number(chave)))
      .map(tipo => { return { value: tipo, text: DiscountType[DiscountType[tipo]] } });

      this.listaMeiosPagamento = (Object.keys(MeiosPagamento) as Array<keyof typeof MeiosPagamento>)
      .filter(chave => isNaN(Number(chave)))
      .map(option => { return { value: option, text: MeiosPagamento[MeiosPagamento[option]] } });



  }
  ngOnInit(): void {
    this.continuarHabilitado = true;

  }
  ngAfterViewInit(): void {

    this.planoForm.get('planOption').setValue(PlanOption[PlanOption.FIXO]);
    this.planoForm.get('planFrequence').setValue(PlanFrequence[PlanFrequence.MENSAL]);

    this.planoForm.statusChanges.subscribe(() => {
      if (this.planoForm.valid) {
        this.continuarHabilitado = true;

      }
    });

  }

  selectPlanOption(select) {

    this.criarPlanoRequest.planOption = PlanOption[PlanOption[select]];

  }
  selectPlanOptionChange(select: NgxSelectOption) {


  }


  selectDayOfWeek(select) {

  }
  selectDayOfWeekChange(select: NgxSelectOption) {


  }


  selectPlanFrequence(select) {

    this.criarPlanoRequest.planFrequence = PlanFrequence[PlanFrequence[select]];

  }
  selectPlanFrequenceChange(select: NgxSelectOption) {


  }



  selectDiscountType(select) {

  }
  selectDiscountTypeChange(select: NgxSelectOption) {


  }

  selectMeioPagamento(select){

  }


  continuar() {

    this.criarPlanoRequest.name = this.planoForm.get('name').value;
    this.criarPlanoRequest.description = this.planoForm.get('description').value;
    this.criarPlanoRequest.amount = currency(this.planoForm.get('amount').value, { separator: '.', decimal: ',' }).value;
    this.criarPlanoRequest.planOption = PlanOption[this.planoForm.get('planOption').value];
    this.criarPlanoRequest.planFrequence = PlanFrequence[this.planoForm.get('planFrequence').value];


    this.criarPlanoService.criarPlano(this.criarPlanoRequest)
      .then(response => {

        let criarPlanoResponse: CriarPlanoResponse = response as CriarPlanoResponse;

        if (criarPlanoResponse.success) {


          this.plano= new Plano();

          this.plano.idPlan = criarPlanoResponse.data.idPlan;
          this.plano.nome = this.criarPlanoRequest.name;
          this.plano.descricao = this.criarPlanoRequest.description;
          this.plano.meioPagamento = MeiosPagamento[this.inserirPlanoForm.get('meioPagamento').value];
          this.plano.descricaoPagamento = this.meioPagamentoPipe.transform(this.plano.meioPagamento);
          this.plano.vantagensPagamento = this.inserirPlanoForm.get('vantagensPagamento').value;
          this.plano.desconto = currency(this.inserirPlanoForm.get('desconto').value, { separator: '.', decimal: ',' }).value;
          this.plano.descontoMeioPagamento = currency(this.inserirPlanoForm.get('descontoMeioPagamento').value, { separator: '.', decimal: ',' }).value;
          this.plano.valor = this.criarPlanoRequest.amount;
          this.plano.valorOriginal = this.plano.valor / ((100 - this.plano.desconto ) / 100 );

          this.plano.valorOriginal= this.plano.valorOriginal / ((100 - this.plano.descontoMeioPagamento ) / 100 )


          this.plano.data = new Date();
          this.plano.vencimento = this.plano.data;

          if(this.criarPlanoRequest.planFrequence == PlanFrequence.MENSAL){
            this.plano.periodo = PeriodoPlano.MENSAL;
          }

          if(this.criarPlanoRequest.planFrequence == PlanFrequence.ANUAL){
            this.plano.periodo = PeriodoPlano.ANUAL;
          }

          this.plano.ativo = true;
          

          this.inserirPlanoService.inseriPlano(this.plano)
          .then(plano=>{
          });

        }



      })
  }


}
