<div *ngIf="calculoBeneficios && calculoBeneficios.leituraCnis" class="pb-2">
  <app-quadro-segurado-editor-cnis [calculoBeneficios]="calculoBeneficios" (habilitarSalvarEdicaoEvent)="habilitarSalvarEdicao($event)"></app-quadro-segurado-editor-cnis>
</div>

<div class="py-2">
  <app-salarios-preview *ngIf="calculoBeneficios.leituraCnis" [calculoBeneficios]="calculoBeneficios"></app-salarios-preview>
</div>

<div class="py-2">
  <app-editor-cnis-grid #editorCnisGrid *ngIf="calculoBeneficios.leituraCnis" [calculoBeneficios]="calculoBeneficios" (habilitarSalvarEdicaoEvent)="habilitarSalvarEdicao($event)" (gridTelaCheiaEvent)="gridTelaCheia($event)"></app-editor-cnis-grid>
</div>

<!-- <div *ngIf="calculoBeneficios && calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis" class="py-2">
  <app-buraco-tempo-contribuicao #timeline *ngIf="calculoBeneficios.leituraCnis && calculoBeneficios.leituraCnis.cnis"  [calculoBeneficios]="calculoBeneficios"></app-buraco-tempo-contribuicao>
</div> -->