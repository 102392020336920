<div *ngIf="calculoIncapacidadeTemporaria && calculoIncapacidadeTemporaria.auxilioIncapacidadeTemporaria">
  <!-- <div class="pb-6">
    <h5 class=" h3 mb-0 linha-quadro">Resultado</h5>
    <div class="pt-6">

      <div class="row">
        <div class="col-12">
          <p class="h2">
            {{calculoIncapacidadeTemporaria.auxilioIncapacidadeTemporaria.qualificacao}} {{calculoIncapacidadeTemporaria.auxilioIncapacidadeTemporaria.qualificacaoDetalhada}}
          </p>
        </div>
      </div>
      <app-quadro-beneficio-preview [esconderBotaoVerMais]="true" [beneficio]="calculoIncapacidadeTemporaria.auxilioIncapacidadeTemporaria"
        (relatorioCompletoEvent)="verRelatorioCompleto($event)"></app-quadro-beneficio-preview>
    </div>
  </div> -->
  <div class="p2-6">
    <app-relatorio-beneficio #relatorioBeneficio *ngIf="calculoIncapacidadeTemporaria.auxilioIncapacidadeTemporaria" [beneficio]="calculoIncapacidadeTemporaria.auxilioIncapacidadeTemporaria"></app-relatorio-beneficio>
  </div>
</div>
