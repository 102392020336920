import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { RelatorioKertzman } from 'src/app/modelos/previdenciario/relatoriokertzman';

@Component({
  selector: 'app-variacao-taxa-payback-simulacao-kertzman',
  templateUrl: './variacao-taxa-payback-simulacao-kertzman.component.html',
  styleUrls: ['./variacao-taxa-payback-simulacao-kertzman.component.scss']
})
export class VariacaoTaxaPaybackSimulacaoKertzmanComponent implements OnInit, OnInit {

  @Input() visualizarPdf: boolean = true;
  @Input() relatorio: RelatorioKertzman;;
  


  data: any[];
  chart: Chart;
  aguardePdf:boolean;

  constructor(private currency: CurrencyPipe, private datePipe: DatePipe, private decimalPipe: DecimalPipe,
    private listarPDFService: ListarPDFService,
    private notificacoesService: NotificacoesService) {
  }


  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.chart)
      this.carregarDados();
  }


  ngOnInit(): void {
    this.carregarDados();

  }

  carregarDados() {
    if (this.chart) { this.chart.destroy(); this.chart = null; }

    this.data = this.relatorio.variacaoTaxaPayback;

    let datasetLabels = this.relatorio.variacaoSubstituicoesTeto;

    let colors = datasetLabels.map(variacao=>variacao === this.relatorio.otimizacao.totalSubstituicoesTeto ? "#ffd600" : "#333333" )
    let dataset = {
      labels: datasetLabels, datasets: [
        {
          data: this.data,
          label: 'Taxa de Retorno',
          backgroundColor: colors,
        }
      ]
    };



    let chart = document.getElementById('variacaoTaxaPayback') as HTMLCanvasElement;


    Chart.register(annotationPlugin);
    this.chart = new Chart(chart, {
      type: 'bar',
      data: dataset,
      options: {
        onClick: (e, activeEls) => {
          // this.rmiSelecionadaEvent.emit(datasetRmi.beneficios[activeEls[0].index].beneficio as Beneficio)
        },

        onHover: (e, activeEls) => {
          (e?.native?.target as HTMLElement).style.cursor = activeEls?.length > 0 ? 'pointer' : 'auto';
        },




        plugins: {

          annotation: {
            annotations: {
            }
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Variação da Taxa de Retorno do Investimento das Contribuições de Otimização',
            // font:{
            //   size:16,
            //   weight:'normal',
            // }
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              size: 24,
              weight: 'normal',
            },
            titleFont: {
              size: 24,
              weight: 'normal',

            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += this.decimalPipe.transform(context.parsed.y * 100, '1.2-2') + "%";
                }
                return label;
              },
              title: (tooltipItens) => {
                return tooltipItens[0].parsed.x + " Contribuições de Otimização";
              },

            }
          }
        },

        scales: {
          y: {
            beginAtZero: true,

            title:{
              display:true,
              text: "Taxa de Retorno"
            },

            ticks: {
              callback: (value, index, ticks) => {
                return this.decimalPipe.transform(value as number * 100, '1.2-2', 'pt-BR') + "%";
              }
            }

          },
          x: {
            type: 'category',
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: (val, index,ticks)=>{
              //   // Hide every 2nd tick label

              //   return this.data[0].contribuicoes.map(contribuicao=>contribuicao.competencia)[];
              // },

            },
            title: {
              display: true,
              text: 'Total de contribuições de otimização'
            },




          }
        }
      }

    });
  }

  // gerarPDF() {

  //   this.aguardePdf = true;
  //   this.calculoPlanejamentoKertzman.tipoCalculo = CalculoTipos.VARIACAOTAXA;
  //   this.calculoPlanejamentoKertzman.usuario = Global.usuario;

  //   this.listarPDFService.ListarPDF(this.calculoPlanejamentoKertzman)
  //     .subscribe(blob => {
  //       const a = document.createElement('a');
  //       const objectUrl = URL.createObjectURL(blob);
  //       a.href = objectUrl;
  //       a.download = 'VARIACAO-TAXA-RETORNO-PLANEJAMENTO-CONTRIBUTIVO-KERTZMAN-' + this.calculoPlanejamentoKertzman.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
  //       a.click();
  //       URL.revokeObjectURL(objectUrl);

  //       this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');


  //       this.aguardePdf = false;


  //     });
  // }

}
