import { Component, Input, OnInit } from '@angular/core';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';

@Component({
  selector: 'app-detalhamento-relacoes-previdenciarias',
  templateUrl: './detalhamento-relacoes-previdenciarias.component.html',
  styleUrls: ['./detalhamento-relacoes-previdenciarias.component.scss']
})
export class DetalhamentoRelacoesPrevidenciariasComponent implements OnInit {

  @Input() relacoesPrevidenciarias:RelacaoPrevidenciaria;
  rows: any=[];
  
  constructor() { }

  ngOnInit(): void {
    this.rows = this.relacoesPrevidenciarias;
  }

}
