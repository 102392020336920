<app-cabecalho [titulo]="'Entrevistas de Planejamento'"></app-cabecalho>

<div class="container-fluid mt--3">
    <div class="card">
        <div class="card-body">

            <div class="row rounded bg-secondary p-2 m-2">
                <div class="col-auto">
                    <div class="text-center">
                        <i class="comando bi bi-plus-square-dotted fs-4 px-0 mx-0" container="body" ngbTooltip="Criar nova ficha" triggers="hover" role="button" [routerLink]="['/calculei/entrevistas/entrevista']"></i>
                        <p class="h6">
                            Criar nova <br>entrevista
                        </p>
                    </div>

                </div>

            </div>

            <div class="row p-2">
                <div class="col">
                    <app-listagem-entrevistas (entrevistaSelecionadaEvent)="abrirEntrevista($event)" ></app-listagem-entrevistas>
                </div>
                
            </div>
            
           
        </div>
    </div>
</div>
