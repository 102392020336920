import { Abatimento } from "./abatimento";
import { EvolucaoRMI } from "./evolucaormi";

export class AbatimentoLiquidacaoSentencaPrevidenciario{
    abatimentos:Abatimento[];
    evolucoes:EvolucaoRMI[];
    percentualValorOriginalOpcao:number;


    textoDescritivo():string{
        let texto:string = '';

        return texto;
    }
}