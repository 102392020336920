import { Component, Input, OnInit } from '@angular/core';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { Usuario } from 'src/app/modelos/usuario/usuario';

@Component({
  selector: 'app-quadro-segurado-relatorio',
  templateUrl: './quadro-segurado-relatorio.component.html',
  styleUrl: './quadro-segurado-relatorio.component.scss'
})
export class QuadroSeguradoRelatorioComponent {
  

  @Input() calculoBeneficios:CalculoBeneficios;


  
  



}
