import { Location } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router, } from '@angular/router';
import Stepper from 'bs-stepper';
import { Ficha } from 'src/app/modelos/previdenciario/ficha';
import { Pergunta } from 'src/app/modelos/previdenciario/pergunta';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nova-ficha',
  templateUrl: './nova-ficha.component.html',
  styleUrl: './nova-ficha.component.scss'
})
export class NovaFichaComponent implements AfterViewInit, AfterViewChecked {

  ficha: Ficha;
  private stepper: Stepper;


  constructor(private configService: ConfigService,
    private calculeiApiService: CalculeiApiService,
    private location: Location,
    private changeDetector: ChangeDetectorRef,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private router: Router
  ) {

    this.ficha = new Ficha();
    this.ficha.nome = "Nova ficha";
    this.ficha.perguntas = new Array<Pergunta>();
    this.configService.getUsuario().subscribe(usuario => this.ficha.usuario = usuario);

  }

  ngAfterViewChecked(): void {
    //ng0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    //erro quando coloca retorno do evento edicaocnisfinalizada no objeto calculo
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.



    if (this.location.getState().hasOwnProperty('ficha')) {

      // console.log('navigation ficha', this.location.getState());
      this.configService.setAguarde(true);
      this.ficha = this.location.getState()['ficha'] as Ficha;
      // this.carregarDados(this.calculoBeneficios);
      this.configService.setAguarde(false);
      this.stepper.to(3);


    }
    else {
      // console.log('navigation state sem ficha');
      this.ficha = new Ficha();
      this.ficha.nome = "Nova ficha";
      this.ficha.perguntas = new Array<Pergunta>();
      this.configService.getUsuario().subscribe(usuario => this.ficha.usuario = usuario);
    }
  }


  ngOnInit(): void {

    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {
      // You can call preventDefault to stop the rendering of your step
      // console.warn('stepper show', event.detail);

      switch (event.detail.to) {
        case 0:
          // console.log('entrar no passo seleção CNIS');
          break;
        case 1:

          break;
        case 2:
          if (this.ficha) {
            // this.salvarFicha();
          }
          break;

        default:
          break;
      }

      //controle salvar cnis

      switch (event.detail.from) {
        case 1:

          break;

        default:
          break;
      }
    });

    stepperElement.addEventListener('shown.bs-stepper', (event: CustomEvent) => {
      // console.warn('step shown mostrando', event.detail);

      // console.log('passo ', event.detail.indexStep);
      switch (event.detail.indexStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }

    });



    if (!environment.production) {
      // this.mock();
    };
  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("ficha.json", request => {
      this.carregarDados(request as Ficha);
    });
    this.stepper.to(3);
  }

  carregarDados(ficha: Ficha) {

    this.ficha = ficha;


  }





  gerarPDF() {

  }

  atualizarListaCnis(atualizar: boolean) {
    // this.seletorCnis.carregarDados();
  }


  proximoStepper() {
    // console.log('proximo stepper');
    this.stepper.next();
  }
  anteriorStepper() {
    // console.log('anterior stepper');
    this.stepper.previous();
  }

  proximo(event) {
    this.proximoStepper()
  }
  anterior(event) {
    this.anteriorStepper()
  }
  cancelar(event) {
    // console.log('cancelar');
    this.router.navigateByUrl('calculei/home');

  }

  salvarFicha() {

    this.configService.setAguarde(true);

    this.calculeiApiService.api("SalvarFicha", this.ficha).subscribe(resultado => {
      // console.log('resultado salvar ficha', resultado);
      this.configService.setAguarde(false);
      Swal.fire({
        title: 'Ficha pronta...',
        text: 'sua ficha foi salva e vai aparecer na lista de fichas quando você for criar a próxima entrevista',
        icon: 'success',
        iconColor: "black",
        showCloseButton: false,
        showConfirmButton: false,
        confirmButtonText: 'ok',
        confirmButtonColor: 'black'

      }).then(() => {
      });
    });

  }

}
