import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmacaoEmailComponent } from './confirmacao-email/confirmacao-email.component';
import { ConfirmarEmailComponent } from './confirmar-email/confirmar-email.component';
import { CriarcontaComponent } from './criarconta/criarconta.component';
import { EntrarComponent } from './entrar/entrar.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PaginasComponent } from './paginas.component';
import { TermoPrivacidadeComponent } from './termo-privacidade/termo-privacidade.component';
import { TermoUsoComponent } from './termo-uso/termo-uso.component';
import { FalecomnossotimeComponent } from './falecomnossotime/falecomnossotime.component';
import { PerguntasfrequentesComponent } from './perguntasfrequentes/perguntasfrequentes.component';
import { PrecosComponent } from './precos/precos.component';

const routes: Routes = [
  {
    path:'',
    component:PaginasComponent,
    children:[
      {
        path:'',
        redirectTo:'home',
        pathMatch:'full'
      },
      {
        path:'home',
        component:HomeComponent
      },
      {
        path:'login',
        component:LoginComponent
      },
      {
        path:'criarconta',
        component:CriarcontaComponent
      },
      {
        path:'entrar',
        component:EntrarComponent
      }
      ,
      {
        path:'termodeuso',
        component:TermoUsoComponent
      },
      {
        path:'termodeprivacidade',
        component:TermoPrivacidadeComponent
      },
      {
        path:'confirmacaoemail',
        component:ConfirmacaoEmailComponent
      },
      {
        path:'confirmaremail',
        component:ConfirmarEmailComponent
      },
      {
        path:'falecomnossotime',
        component:FalecomnossotimeComponent
      },
      {
        path:'perguntasfrequentes',
        component:PerguntasfrequentesComponent
      },
      {
        path:'precos',
        component:PrecosComponent
      }
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaginasRoutingModule { }
