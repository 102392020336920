import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';


import { Abatimento } from 'src/app/modelos/previdenciario/abatimento';
import { EvolucaoRMI } from 'src/app/modelos/previdenciario/evolucaormi';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import * as currency from 'currency.js';
import { ListarEvolucaoRmiService } from 'src/app/servicos/calculo/previdenciario/listar-evolucao-rmi.service';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { AppComponent } from 'src/app/app.component';
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';
import { AbatimentoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/abatimentosliquidacaosentencaprevidenciario';



@Component({
  selector: 'app-abatimentos-liquidacao-sentenca-previdenciario',
  templateUrl: './abatimentos-liquidacao-sentenca-previdenciario.component.html',
  styleUrls: ['./abatimentos-liquidacao-sentenca-previdenciario.component.scss']
})
export class AbatimentoLiquidacaoSentencaPrevidenciarioComponent implements OnInit {

  @Output() continuarEvent = new EventEmitter<CalculoLiquidacaoSentencaPrevidenciario>();
  @Output() aguardeVisivelEvent = new EventEmitter<boolean>();
  @Input() calculoLiquidacaoSentencaPrevidenciario: CalculoLiquidacaoSentencaPrevidenciario;


  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  dateCompetenciaInputMask = createMask<Date>(AppComponent.dateInputOptions);

  currencyInputMask = createMask(AppComponent.currencyInputOptions);

  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0'
  });



  tipoProcesso: TiposProcessoLiquidacaoSentencaPrevidenciario;
  tipoBeneficio: TipoBeneficioLiquidacaoSentencaPrevidenciario;

  constructor(private listarEvolucaoRmiService: ListarEvolucaoRmiService) { }



  ngOnInit(): void {


  }


  carregarDados(calculo: CalculoLiquidacaoSentencaPrevidenciario) {

    if (calculo.liquidacaoSentenca.abatimentos) {

      this.calculoLiquidacaoSentencaPrevidenciario = calculo;

      this.tipoProcesso = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso;
      this.tipoBeneficio = this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.tipoBeneficio;

    }
    else {

      this.calculoLiquidacaoSentencaPrevidenciario = calculo;
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos = new AbatimentoLiquidacaoSentencaPrevidenciario();

    }

  }


  atualizarAbatimentosHiscre(abatimentos: Abatimento[]) {

    this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos = [...this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos, ...abatimentos];
    this.updateTabelaAbatimentos();
  }

  updateTabelaAbatimentos() {
  }



  continuar() {
  }



  salvarNovaEvolucaoRMI(evolucaoRMI: EvolucaoRMI) {

  }
  salvarAbatimentosNovaEvolucaoRMI(abatimentos: Abatimento[]) {
    this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos = [...this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos, ...abatimentos];
    this.updateTabelaAbatimentos();

  }
  processandoHiscre(processando: boolean) {
    this.aguardeVisivelEvent.emit(processando);
  }
  processandoNovaEvolucaoRMI(processando: boolean) {
    this.aguardeVisivelEvent.emit(processando);
  }
  salvarNovoAbatimento(abatimento: Abatimento) {
    this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos.push(abatimento);
    this.updateTabelaAbatimentos();
  }

  incluirPercentualDescontoParcela(event) {

    // console.log('percentual' + );
    this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.percentualValorOriginalOpcao = currency(event.target.value, { separator: '.', decimal: ',' }).value;




  }
}
