import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';

import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoCategoria } from 'src/app/modelos/common/calculocategoria';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';
import { Global } from 'src/app/modelos/global';
import { CalculoAnaliseCnis } from 'src/app/modelos/previdenciario/calculoanalisecnis';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CalculoIncapacidadeTemporaria } from 'src/app/modelos/previdenciario/calculoincapacidadetemporaria';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';
import { CalculoMapaQualidadeSegurado } from 'src/app/modelos/previdenciario/calculomapaqualidadesegurado';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ListarCalculoService } from 'src/app/servicos/calculo/common/listar-calculo.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { ListarClienteService } from 'src/app/servicos/usuario/listar-cliente.service';




@Component({
  selector: 'app-calculos-dashboard',
  templateUrl: './calculos-dashboard.component.html',
  styleUrls: ['./calculos-dashboard.component.scss']
})
export class CalculosDashboardComponent implements OnInit {


  titulo: string = 'Meus Cálculos';
  visualizarCalculo: boolean;

  tiposCalculoType = CalculoTipos
  aguardeVisivel: boolean = false;
  calculo: Calculo;

  atualizandoListaCalculos: boolean;

  listaCalculos: ResultadoCalculo[];



  constructor(private listarCalculoService: ListarCalculoService, private datePipe: DatePipe,
    private calculeiApiService: CalculeiApiService,
    private configService: ConfigService,
    private router: Router,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService) {



    // this.listarCalculoService.listarCalculo()
    //   .then(calculos => {

    //     console.log('calculos prontos');
    //     console.log(calculos);


    //     // datafaturas.labels = datasetFaturas.map(({contaReferenteMes})=> this.datePipe.transform(contaReferenteMes, "MM/yyyy") );
    //     this.rows = calculos as Calculo[];
    //     this.rowsMap = this.rows.map((prop) => { return { rowKey: prop.rowKey, tipo: CalculoTipos[prop.tipo], categoria: CalculoCategoria[prop.categoria], nome: prop.cliente.nome, data: datePipe.transform(prop.data, 'dd/MM/yy HH:mm') }; });

    //     this.temp = this.rowsMap.map((prop, key) => {
    //       return {
    //         ...prop,
    //         id: key
    //       };
    //     });

    //     this.aguardeVisivel = false;
    //   });



  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculos.json", request => {
      this.carregarDados(request as Calculo[]);
    });
  }

  carregarDados(calculos: Calculo[]) {

    // console.log(calculos);

  }

  listarCalculos() {

    this.atualizandoListaCalculos = true;
    let request: RequestBase = new RequestBase();
    this.configService.getUsuario().subscribe(usuario => request.usuario = usuario);


    this.calculeiApiService.api("listarCalculo", request)
      .subscribe(lista => {

        this.listaCalculos = lista as ResultadoCalculo[];
        // console.log('resultado lista calculos', this.listaCalculos);

        this.atualizandoListaCalculos = false;

      })

  }

  ngOnInit() {


    // this.mock();
    this.listarCalculos();

  }

  tipoCalculoText(tipo: CalculoTipos): string {
    let texto: string = ''
    let conversao: CalculoTipos = CalculoTipos[tipo.toString()];
    switch (conversao) {
      case CalculoTipos.CALCULOBENEFICIOS:
        texto = 'Renda Mensal Inicial'
        break;
      case CalculoTipos.LIQUIDACAOSENTENCAPREVIDENCIARIO:
        texto = 'Liquidação de Sentença'
        break;
      case CalculoTipos.VALORCAUSA:
        texto = 'Valor da Causa'
        break;

      case CalculoTipos.REVISAOVIDATODA:
        texto = 'Revisão da Vida Toda'
        break;

      case CalculoTipos.LIQUIDACAOSENTENCACIVEL:
        texto = 'Liquidação de Sentença'
        break;

      case CalculoTipos.KERTZMAN:
        texto = 'Planejamento Contributivo Kertzman'
        break;

      case CalculoTipos.CALCULOPLANEJAMENTOPREVIDENCIARIO:
        texto = 'Simulação de Renda Mensal Inicial'
        break;


      default:
        texto = 'tipo não encontrado';
        break;
    }
    return texto;
  }

  abrirCalculo(calculo: ResultadoCalculo) {

    let editor = <HTMLInputElement>document.getElementById(calculo.id);
    editor.classList.remove('collapse');
    editor.classList.add('show');

    // console.log('abrir calculo ', calculo);
    this.configService.getUsuario().subscribe(usuario => calculo.usuario = usuario);
    this.calculeiApiService.api("AbrirCalculo", calculo).subscribe(resultado => {

      switch (calculo.tipoCalculo) {
        case CalculoTipos.CALCULOBENEFICIOS:
          const navigationExtrasCalculoBeneficios: NavigationExtras = {
            state: { calculo: resultado as CalculoBeneficios }
          }
          this.router.navigate(["/calculei/calculos/previdenciario/beneficios"], navigationExtrasCalculoBeneficios)
          // console.log('resultado abrir calculobeneficios', calculoBeneficios);
          break;
        case CalculoTipos.CALCULOPLANEJAMENTOPREVIDENCIARIO:
          const navigationExtrasCalculoPlanejamentoPrevidenciario: NavigationExtras = {
            state: { calculo: resultado as CalculoPlanejamentoPrevidenciario }
          }
          this.router.navigate(["/calculei/calculos/previdenciario/simulacao"], navigationExtrasCalculoPlanejamentoPrevidenciario)
          // console.log('resultado abrir calculoplanejamentoprevidenciario', calculoPlanejamentoPrevidenciario);
          break;

        case CalculoTipos.KERTZMAN:
          const navigationExtrasCalculoPlanejamentoKertzman: NavigationExtras = {
            state: { calculo: resultado as CalculoPlanejamentoKertzman }
          }
          this.router.navigate(["/calculei/calculos/previdenciario/kertzman"], navigationExtrasCalculoPlanejamentoKertzman)
          // console.log('resultado abrir kertzman', calculoPlanejamentoKertzman);
          break;

        case CalculoTipos.PREPARACAOANALISECNIS:
          const navigationExtrasCalculoAnaliseCnis: NavigationExtras = {
            state: { calculo: resultado as CalculoAnaliseCnis }
          }
          this.router.navigate(["/calculei/calculos/previdenciario/analisecnis"], navigationExtrasCalculoAnaliseCnis)
          // console.log('resultado abrir analisecnis', calculoAnaliseCnis);
          break;

        case CalculoTipos.QUALIDADESEGURADO:
          const navigationExtrasQualidadeSegurado: NavigationExtras = {
            state: { calculo: resultado as CalculoMapaQualidadeSegurado }
          }
          this.router.navigate(["/calculei/calculos/previdenciario/mapaqualidadesegurado"], navigationExtrasQualidadeSegurado)

          // console.log('resultado abrir mapa qualidade', calculoMapaQualidadeSegurado);
          break;
        case CalculoTipos.INCAPACIDADETEMPORARIA:
          const navigationExtrasIncapacidadeTemporaria: NavigationExtras = {
            state: { calculo: resultado as CalculoIncapacidadeTemporaria }
          }
          this.router.navigate(["/calculei/calculos/previdenciario/incapacidadetemporaria"], navigationExtrasIncapacidadeTemporaria)
          // console.log('resultado abrir incapacidade temporaria', calculoIncapacidadeTemporaria);
          break;

        case CalculoTipos.LIQUIDACAOSENTENCAPREVIDENCIARIO:
          const navigationExtrasLiquidacaoSentenca: NavigationExtras = {
            state: { calculo: resultado as CalculoLiquidacaoSentencaPrevidenciario }
          }
          this.router.navigate(["/calculei/calculos/previdenciario/liquidacaosentenca"], navigationExtrasLiquidacaoSentenca)
          // console.log('resultado abrir liquidacao sentenca previdenciaria', calculoLiquidacaoSentenca);
          break;
          case CalculoTipos.INVALIDEZ:
          const navigationExtrasCalculoBeneficiosInvalidez: NavigationExtras = {
            state: { calculo: resultado as CalculoBeneficios }
          }
          this.router.navigate(["/calculei/calculos/previdenciario/invalidez"], navigationExtrasCalculoBeneficiosInvalidez)
          // console.log('resultado abrir calculobeneficios', calculoBeneficios);
          break;

        default:
          break;
      }

      let editor = <HTMLInputElement>document.getElementById(calculo.id);
      editor.classList.remove('show');
      editor.classList.add('collapse');

    });

  }

  novoCalculo(){

    this.router.navigate(['/calculei/calculos/novocalculo']);
    
  }
}
