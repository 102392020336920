import { FluxoPontosAtencao } from './fluxopontosatencao';
import { Base } from "../base";
import { AnaliseVinculos } from "./analisevinculos";

import { FluxoTempoContribuicaoCarencia } from "./fluxotempocontribuicaocarencia";
import { IdentificacaoFiliado } from "./identificacaofiliado";
import { IndicadorCNIS } from "./indicadorcnis";
import { PlotBuracoPeriodoContributivo } from "./plotburacoperiodocontributivo";
import { PontoAtencaoCNIS } from "./pontoatencaocnis";
import { RelacaoPrevidenciaria } from "./relacaoprevidenciaria";
import { Contribuicao } from './contribuicao';
import { Tempo } from './tempo';

export class CNIS extends Base{

    identificacaoFiliado:IdentificacaoFiliado;
    relacoesPrevidenciarias:RelacaoPrevidenciaria[];
    indicadores:IndicadorCNIS[];
    pontosAtencaoCNIS:PontoAtencaoCNIS[];
    analiseVinculos:AnaliseVinculos;
    plotBuracoPeriodoContributivo:PlotBuracoPeriodoContributivo;

    totalTempoContribuicaoIdade:Tempo;
    totalTempoContribuicaoTempoContribuicao:Tempo;
    totalCarenciaIdade:number;
    totalCarenciaTempoContribuicao:number;

    fluxoTempoContribuicaoCarencia:FluxoTempoContribuicaoCarencia;
    fluxoPontosAtencao:FluxoPontosAtencao;
    totalTempoContribuicaoIdadeDias:number;
    totalTempoContribuicaoTempoContribuicaoDias:number;

    contribuicoesExcedentes:Contribuicao[];
    contribuicoesComposicaoExcedentes:Contribuicao[];


    totalCarenciaPreReforma:number;
    totalTempoContribuicaoPreReforma:Tempo;


}