import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() { }

  public static log(text:string, object?:object | object[]| any){
    if (!environment.production) {
      console.log(text, object);
      
    }
  }
}
