import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { TipoBeneficio } from 'src/app/modelos/previdenciario/tipobeneficio';

@Component({
  selector: 'app-quadro-beneficio',
  templateUrl: './quadro-beneficio.component.html',
  styleUrls: ['./quadro-beneficio.component.scss']
})
export class QuadroBeneficioComponent implements OnInit {

  @Input() beneficio:Beneficio;

  tipoBeneficio:string;
  tipoAtividade:string;
  textoRegraContagemContribuicoes:string;
  textoRegraContagemContribuicoesInfo:string;

  tiposBeneficioType = TipoBeneficio;
  apresentarPorcentoCoeficiente:boolean;

  
  
  constructor() { }

  ngOnInit(): void {


    this.textoRegraContagemContribuicoes = Beneficio.regraContagemBeneficioToString(this.beneficio);
    this.textoRegraContagemContribuicoesInfo = Beneficio.regraContagemBeneficioInfoToString(this.beneficio.relatorioRendaMensalInicial.regraContagemContribuicoes)
    this.tipoBeneficio = Beneficio.tipoBeneficioToString(this.beneficio.tipoBeneficio);
    this.tipoAtividade = Beneficio.atividadeBeneficioToString(this.beneficio.atividade);
    

  }

  

}
