
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from 'src/app/modelos/global';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CriarContaRequest } from 'src/app/modelos/usuario/criarcontarequest';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListarClienteService {

  constructor(private httpClient:HttpClient) { }

  listarCliente(){
    let listarClienteRequest:ListarClientesRequest = new ListarClientesRequest();
    listarClienteRequest.usuario = Global.usuario;

    return Promise.all([this.apicall(listarClienteRequest)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(listarClientesRequest:ListarClientesRequest){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ListarCliente',listarClientesRequest, {'headers':headers}).toPromise();
    return data;
  }
}
