<div class=" header bg-back-dark">

    <div class=" container-fluid">
        <div class=" header-body">
            <div class=" row align-items-center py-3">
                <div class=" col-lg-12">
                    <span class=" text-white inline-block mb-0 h6 fw-lighter">Edição CNIS</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" container-fluid mt--3  ">

    <div class=" row">
        <div class=" col-12"    >
            
            
            




          <div class="  ">


            <div class="pb-4">

                


                <div class=" card ">

                    <div class="row">
                        <div class="col-12">

                            <app-montagem-cnis></app-montagem-cnis>

                        </div>
                    </div>
                    
                </div>


            </div>

          </div>
        </div>
    </div>
    
</div>