

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';

import { NgxSelectOption } from 'ngx-select-ex';
import { AppComponent } from 'src/app/app.component';
import { Global } from 'src/app/modelos/global';
import { CalculoValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/calculovalorcausaprevidenciario';
import { ReferenciaValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/referenciavalorcausaprevidenciario';


import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { ValorCausaPrevidenciario } from 'src/app/modelos/previdenciario/valorcausaprevidenciario';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { ListarClienteService } from 'src/app/servicos/usuario/listar-cliente.service';

@Component({
  selector: 'app-referencia-valor-causa-previdenciario',
  templateUrl: './referencia-valor-causa-previdenciario.component.html',
  styleUrls: ['./referencia-valor-causa-previdenciario.component.scss']
})
export class ReferenciaValorCausaPrevidenciarioComponent implements OnInit {

  @Output() continuarEvent = new EventEmitter<CalculoValorCausaPrevidenciario>();
  @Output() continuarHabilitadoEvent = new EventEmitter<boolean>();
  @Input() calculoValorCausa:CalculoValorCausaPrevidenciario;

  
  cpfMask = createMask('999.999.999-99');

  tiposProcessoType = TiposProcessoLiquidacaoSentencaPrevidenciario;
  tiposProcesso = [
    {value:TiposProcessoLiquidacaoSentencaPrevidenciario.CONCESSAO, text:'Concessão de Benefício'},
    {value:TiposProcessoLiquidacaoSentencaPrevidenciario.REVISAO, text:'Revisão de Benefício'},
    {value:TiposProcessoLiquidacaoSentencaPrevidenciario.REESTABELECIMENTO, text:'Reestabelecimento de Benefício'},
  ];

  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  clienteSelecionado:Cliente;
  clientes:Cliente[];
  cpf:string = '';

  tipoProcesso:TiposProcessoLiquidacaoSentencaPrevidenciario;


  referenciaForm = new UntypedFormGroup({
    nome: new UntypedFormControl('', Validators.required),
    cpf: new UntypedFormControl('', Validators.required),
    dataCalculo: new UntypedFormControl('', Validators.required),
    tipoProcesso: new UntypedFormControl('', Validators.required),
  });

  constructor(private listarClienteService:ListarClienteService) {}

  ngOnInit(): void {

    this.referenciaForm.get('dataCalculo').setValue(new Date());
    this.referenciaForm.get('tipoProcesso').setValue(this.tiposProcesso[0].text);

    
    this.listarClienteService.listarCliente()
    .then(resultado =>{
      this.clientes = resultado as Cliente[];
      
      
    });


    this.referenciaForm.statusChanges.subscribe(()=>{
      if(this.referenciaForm.get('nome').value != ''){
        if(this.referenciaForm.valid){
          this.continuarHabilitadoEvent.emit(true);
        }else{
          this.continuarHabilitadoEvent.emit(false);
        }
      }
      
    });


    


    
    
  }

  carregarDados(calculo:CalculoValorCausaPrevidenciario){
    this.calculoValorCausa = calculo;
    this.referenciaForm.get('dataCalculo').setValue(new Date(this.calculoValorCausa.valorCausa.referencia.dataCalculo));
    this.referenciaForm.get('tipoProcesso').setValue(this.tiposProcesso.filter(tipo=>tipo.value === TiposProcessoLiquidacaoSentencaPrevidenciario[TiposProcessoLiquidacaoSentencaPrevidenciario[this.calculoValorCausa.valorCausa.referencia.tipoProcesso]])[0].text)
    this.referenciaForm.get('nome').setValue(this.calculoValorCausa.valorCausa.referencia.nome);
    this.referenciaForm.get('cpf').setValue(this.calculoValorCausa.valorCausa.referencia.cpf);
  }
  

  continuar(){

    let referencia = new ReferenciaValorCausaPrevidenciario();
    referencia.nome = this.referenciaForm.get('nome').value;
    referencia.cpf = this.referenciaForm.get('cpf').value;
    referencia.dataCalculo = this.referenciaForm.get('dataCalculo').value;
    referencia.tipoProcesso = this.tipoProcesso;
    this.calculoValorCausa.valorCausa.referencia = referencia;
    
    this.continuarEvent.emit(this.calculoValorCausa);

  }


  
  onSelect(event){
    //event.item (Cliente)
    this.referenciaForm.get('cpf').setValue(event.item.cpf);

  }

  

  selectTipoChange(select:NgxSelectOption){

    
    this.tipoProcesso = TiposProcessoLiquidacaoSentencaPrevidenciario[TiposProcessoLiquidacaoSentencaPrevidenciario[select[0].data.value]];
    this.referenciaForm.get('tipoProcesso').setValue(TiposProcessoLiquidacaoSentencaPrevidenciario[select[0].data.value]);
  }

}
