import {Pipe, PipeTransform} from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';

@Pipe({name:'beneficio'})
export class BeneficioPipe implements PipeTransform{

    transform(value: any, ...args: any[]): string {

        // console.log(value);
        return Beneficio.tipoBeneficioToString(value);
    }

}