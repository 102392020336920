import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-heat-map-matriz',
  templateUrl: './heat-map-matriz.component.html',
  styleUrl: './heat-map-matriz.component.scss'
})
export class HeatMapMatrizComponent implements OnInit {


  ngOnInit(): void {
    this.carregarDados();

  }
  constructor(private currencyPipe: CurrencyPipe) {
    this.referenciaMatrizOpcao = "Lucro acumulado (ROI)";
  }

  @Input() calculoMatrizPlanejamento: MatrizPlanejamento;

  referenciaMatriz: string;
  referenciaMatrizOpcao: string;

  xAxis: Object;
  yAxis: Object;
  dataSource: Object[][];
  cellSettings: Object;
  legendSettings: Object;
  tooltipSettings: Object;

  public paletteSettings: Object = {
    palette: [
      { color: '#d3d3d3' },
      { color: '#696969' },
    ],
    type: "Gradient"
  };



  carregarDados() {
    LogService.log('aliquotas salarios', this.calculoMatrizPlanejamento.casos.map(caso => caso.aliquotaContributiva + " " + caso.baseContributiva))

    function basesUnicas(array: any[]): any[] {
      const mapaUnicos = new Map();

      array.forEach(caso => {
        if (!mapaUnicos.has(caso.aliquotaContributiva + " " + caso.baseContributiva)) {
          mapaUnicos.set(caso.aliquotaContributiva + " " + caso.baseContributiva, caso);
        }
      });

      return Array.from(mapaUnicos.values());
    }


    function regrasUnicas(array: any[]): any[] {
      const mapaUnicos = new Map();

      array.forEach(caso => {
        if (!mapaUnicos.has(caso.qualificacaoReferencia)) {
          mapaUnicos.set(caso.qualificacaoReferencia, caso);
        }
      });

      return Array.from(mapaUnicos.values());
    }

    const bases = basesUnicas(this.calculoMatrizPlanejamento.casos).map(caso => caso.aliquotaContributiva + " " + caso.baseContributiva);

    const regras = regrasUnicas(this.calculoMatrizPlanejamento.casos).map(caso => caso.qualificacaoReferencia);

    LogService.log('aliquotas salarios filtrados', bases);

    LogService.log('regras filtrados', regras);


    this.xAxis = {
      valueType: "Category",
      labels: regras,
      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };
    this.yAxis = {

      valueType: "Category",
      labels: bases.map(base => (parseFloat(base.split(" ")[0]) * 100).toString() + "%" + " sobre " + this.currencyPipe.transform(parseFloat(base.split(" ")[1]))),

      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };

    this.cellSettings = {

      border: {
        width: 1,
        radius: 4,
        color: 'white'
      },
      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };

    this.legendSettings = {
      border: {
        width: 1,
        radius: 4,
        color: 'white'
      },
      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };

    this.tooltipSettings = {
      fill: '#000000',
      // border: {
      //     width: 1,
      //     radius: 4,
      //     color: 'white'
      // },
      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
        fontFamily: 'tt-commons-pro'
      }
    };

    // this.dataSource = bases.map(base => this.calculoMatrizPlanejamento.casos.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) === base).map(caso => caso.retornoTotalInvestimentoRoi));


    let montagem: any[][] = new Array();

    regras.forEach(regra => {
      LogService.log('regra ', regra);
      let casosRegra = this.calculoMatrizPlanejamento.casos.filter(caso => caso.qualificacaoReferencia === regra);
      LogService.log('casos por regra', [regra, casosRegra]);

      let mapa: any[] = new Array();
      bases.forEach(base => {



        if (casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base).length > 0) {

          switch (this.referenciaMatriz) {
            case 'roi':
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].retornoTotalInvestimentoRoi)

              break;

            case 'beneficio':
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].valorBeneficio)

              break;

            case 'taxa':
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].taxaRetornoPlanejamentoContributivo * 100)

              break;

            default:
              mapa.push(casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base)[0].retornoTotalInvestimentoRoi)
              break;
          }


        }
        if (casosRegra.filter(caso => (caso.aliquotaContributiva + " " + caso.baseContributiva) == base).length == 0) {

          mapa.push(0)

        }

      });
      montagem.push(mapa);
    });


    LogService.log('montagem', montagem);

    this.dataSource = montagem;





  }


  selecaoReferenciaMatriz(referencia: string) {

    this.referenciaMatriz = referencia;
    this.carregarDados();

    switch (referencia) {
      case 'roi':
        LogService.log('referencia matriz', 'roi');
        this.referenciaMatrizOpcao = "Lucro acumulado (ROI)"


        break;

      case 'beneficio':
        LogService.log('referencia matriz', 'beneficio');
        this.referenciaMatrizOpcao = "Valor mensal do benefício"

        break;

      case 'taxa':
        LogService.log('referencia matriz', 'taxa');
        this.referenciaMatrizOpcao = "Taxa de retorno do investimento"

        break;

      default:
        LogService.log('referencia matriz default', referencia);
        break;
    }

  }



}
