import { Base } from "../base";
import { Arquivo } from "../faturas/arquivo";
import { Usuario } from "../usuario/usuario";

export class ImportacaoHiscre extends Base{
    arquivos:Arquivo[];
    arquivosKeys:string[];
    cnisKeys:string[];
    dataImportacao:Date;
    usuario:Usuario;
    
}