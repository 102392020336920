import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CriarPlanoRequest } from 'src/app/modelos/safe2pay/criarplanorequest';
import { environment } from 'src/environments/environment';
import { ListarPlanoResponseObject } from 'src/app/modelos/safe2pay/listarplanosresponse';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuscaCepService {

  constructor(private httpClient:HttpClient) { }

  buscaCep(request:string){

    return Promise.all([this.apicall(request)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(request:string){
    const headers = { 'Access-Control-Allow-Origin': '*'};

    let data;

    try {

      data = await this.httpClient.get('https://viacep.com.br/ws/'+request+'/json', {'headers':headers}).toPromise();
      
    } catch (error) {
      
    }
    
    return data;
  }

  
}