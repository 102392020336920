import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Abatimento } from 'src/app/modelos/previdenciario/abatimento';
import { EvolucaoRMI } from 'src/app/modelos/previdenciario/evolucaormi';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ListarEvolucaoRmiService {

  constructor(private httpClient: HttpClient) { }

  listarEvolucaoRMI(evolucaoRMI:EvolucaoRMI){
    return Promise.all([this.apiCall(evolucaoRMI)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(evolucaoRMI:EvolucaoRMI){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ListarEvolucaoRMI',evolucaoRMI, {'headers':headers}).toPromise();
    return data;
  }
}
