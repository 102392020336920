import {Pipe, PipeTransform} from '@angular/core';
import { CalculoTiposEx } from 'src/app/modelos/common/calculotipos';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { PeriodoPlano, PeriodoPlanoEx } from 'src/app/modelos/usuario/periodoplano';

@Pipe({name:'tipocalculo'})
export class TipoCalculoPipe implements PipeTransform{

    transform(value: any, ...args: any[]): string {
        return CalculoTiposEx.tipoToString(value);
    }

}