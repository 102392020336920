import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sumario-calculo-beneficios',
  templateUrl: './sumario-calculo-beneficios.component.html',
  styleUrls: ['./sumario-calculo-beneficios.component.scss']
})
export class SumarioCalculoBeneficiosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
