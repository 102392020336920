<h5 class=" h3 mb-0 linha-quadro">Meus dados</h5>

<ng-container *ngIf="usuario; else elseTemplate">

    <div class="row g-3 py-4 flex d-flex">
        <div class="col-3">
            <p class="h2">
                Nome
            </p>
            <p class="text-default">
                {{usuario.nome}}
            </p>
        </div>

        <div class="col-3">
            <p class="h2">
                Sobrenome
            </p>
            <p class="text-default">
                {{usuario.sobrenome}}
            </p>
        </div>

        <div class="col-3">
            <p class="h2">
                E-mail
            </p>
            <p class="text-default">
                {{usuario.email}}
            </p>
        </div>

    </div>

</ng-container>
<ng-template #elseTemplate>

</ng-template>
