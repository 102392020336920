import { TokenResponse } from "angular-oauth2-oidc";
import { CriarAssinaturaRequest } from "./criarassinaturarequest";
import { Plano } from "../pagamento/plano";
import { Usuario } from "../usuario/usuario";
import { GerarTokenResponse } from "./gerartokenresponse";
import { CriarPlanoResponse } from "./criarplanoresponse";
import { Voucher } from "../common/voucher";

export class CriarAssinaturaData{
    assinaturaRequest:CriarAssinaturaRequest;
    usuarioRowkey:string;
    token:GerarTokenResponse;
    plano:Plano;
    planoVoucher:CriarPlanoResponse;
    voucher:Voucher;
}