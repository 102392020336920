export enum OpcoesImpostoRendaPlanejamento {
    NAOISENTO,
    ISENTO
}

export class OpcoesImpostoRendaPlanejamentoEx {
    static toString(opcao: OpcoesImpostoRendaPlanejamento) {
        let texto: string = "";

        switch (opcao) {
            case OpcoesImpostoRendaPlanejamento.ISENTO:

                texto = "Cliente Isento"

                break;
            case OpcoesImpostoRendaPlanejamento.NAOISENTO:
                texto = "Cliente Não Isento"
                break;

            default:
                break;
        }

        return texto;
    }
}