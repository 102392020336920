import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmarEmailRequest } from 'src/app/modelos/usuario/confirmaremailrequest';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { ConfirmarEmailService } from 'src/app/servicos/usuario/confirmar-email.service';

@Component({
  selector: 'app-confirmacao-email',
  templateUrl: './confirmacao-email.component.html',
  styleUrls: ['./confirmacao-email.component.scss']
})
export class ConfirmacaoEmailComponent implements OnInit {

  focus;
  focus1;
  focus2;
  test: Date = new Date();
  isCollapsed = true;
  concluido = false;
  aguarde = true;
  ConfirmarEmailRequest = new ConfirmarEmailRequest();


  id:string = '';

  constructor(private route: ActivatedRoute,
     private autenticacaoService:AutenticacaoService,
     private confirmarEmailService:ConfirmarEmailService ) { }

  ngOnInit(): void {

    this.route.queryParams
    .subscribe(params =>{
      this.id = params.id;
      this.ConfirmarEmailRequest.id = this.id;
      this.confirmarEmailService.confirmarEmail(this.ConfirmarEmailRequest)
      .then(confirmarEmailResponse=>{
        this.aguarde = false;
      });
    });
  }

  login(){
    this.autenticacaoService.login();
  }
}
