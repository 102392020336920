<span class=" display-3 font-weight-lighter  mb-0 text-default">
    {{titulo}}
</span>
<div class="col-xl-12 col-md-12 col-lg-12 caixacontrolesnav d-flex flex-row-reverse">
    <div class="row ">

        <div class="col-auto " [className]="aguarde ? 'visible col-auto' : 'invisible col-auto'"  container="body" [ngbTooltip]="'Processando. Aguarde!'">
            

            <div class="">
                <div [className]="aguarde ? 'visible' : 'invisible'">
                    <div class="d-flex justify-content-center my-2">
                        <div class="spinner-border spinner-border-lg my-4" role="status">
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-auto habilitado "  container="body" [ngbTooltip]="'Cancelar cálculo e voltar para a página inicial de novo cálculo'"
            (click)="cancelar()">
            <div class="col-1 p-4 mb-5  controlenav ">
                <span class=" timeline-step timeline-step-xlg ">
                    <i class="bi-x-lg "></i>
                </span>
            </div>

        </div>

        

        <div class="col-auto " [className]="excluirCnisHabilitado ? 'visible col-auto habilitado' : 'invisible col-auto habilitado'"  container="body" [ngbTooltip]="'Excluir CNIS'"
            (click)="excluirCnis()">
            <div class="col-1 p-4 mb-5 active controlenav ">
                <span class=" timeline-step timeline-step-xlg  ">
                    <i class="bi-trash"></i>
                </span>
            </div>

        </div>

        <div class="col-auto " [className]="incluirMarcadorCnisHabilitado ? 'visible col-auto habilitado' : 'invisible col-auto habilitado'"  container="body" [ngbTooltip]="'Adicionar marcador ao CNIS. Use para organizar a sua importação e edição de CNIS.'"
            (click)="incluirMarcadorCnis()">
            <div class="col-1 p-4 mb-5 active controlenav ">
                <span class=" timeline-step timeline-step-xlg  ">
                    <i class="bi-bookmark-fill"></i>
                </span>
            </div>

        </div>

        <div class="col-auto " [className]="incluirMatrizPlanejamentoHabilitado ? 'visible col-auto habilitado' : 'invisible col-auto habilitado'" container="body" [ngbTooltip]="'Adicionar marcador ao cenário calculado. Use para organizar os resultados dos cálculos realizados.'"
            (click)="iniciarSetMarcadorCaso()">
            <div class="col-1 p-4 mb-5 active controlenav ">
                <span class=" timeline-step timeline-step-xlg  ">
                    <i class="bi-bookmark-fill"></i>
                </span>
            </div>

        </div>

        <div class="col-auto " [className]="incluirMatrizPlanejamentoHabilitado ? 'visible col-auto habilitado' : 'invisible col-auto habilitado'"  container="body" [ngbTooltip]="'Adicionar o cenário na matriz de investimento. Use para visualizar vários cenários lado a lado na matriz de investimento.'" >
            <app-incluir-caso-matriz-planejamento (incluirCasoMatrizPlanejamentoEvent)="incluirCasoMatrizPlanejamento($event)"></app-incluir-caso-matriz-planejamento>

        </div>

        <div class="col-auto " [className]="baixarPDFHabilitado ? 'visible col-auto habilitado' : 'invisible col-auto habilitado'"  container="body" [ngbTooltip]="'Baixar relatório em PDF na pasta de downloads.'"
            (click)="baixarPDF()">
            <div class="col-1 p-4 mb-5 active controlenav ">
                <span class=" timeline-step timeline-step-xlg  ">
                    <i class="bi-filetype-pdf"></i>
                </span>
            </div>

        </div>

        

        

        <div class="col-auto " [disableTooltip]="!voltarHabilitado" [className]="voltarHabilitado ? 'habilitado col-auto' : 'desabilitado col-auto'"  container="body" [ngbTooltip]="'Voltar. Clique para visualizar o passo anterior.'"
            (click)="voltar()">
            <div class="col-1 p-4 mb-5  controlenav ">
                <span class=" timeline-step timeline-step-xlg ">
                    <i class="bi-arrow-left "></i>
                </span>
            </div>

        </div>

        <div class="col-auto " [disableTooltip]="!proximoHabilitado" [className]="proximoHabilitado ? 'habilitado col-auto' : 'desabilitado col-auto'"  container="body" [ngbTooltip]="'Próximo. Clique para ativar e seguir para o próximo passo.'"
            (click)="proximo()">
            <div class="col- p-4 mb-5 active controlenav ">
                <span class=" timeline-step timeline-step-xxlg  ">
                    <i class="bi-arrow-right"></i>
                </span>
            </div>

        </div>
    </div>
</div>