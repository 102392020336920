<div *ngIf="caso">
    <div class="row p-2">
        <div class="col-md-6">
            <!-- <img src={{logoUrl}} width="100px"> -->
        </div>

        <div class="col-md-6 text-right">
            <p class="font-weight-bold mb-1 h1">Relatório de Planejamento Contributivo Previdenciário
            </p>
            <p class="mb-1 h2">Cliente {{caso.beneficio.cnis.identificacaoFiliado.nome}}</p>
        </div>
    </div>

    <hr class="my-5">

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1 text-default">Planejamento Contributivo Previdenciário</span>
        </div>
    </div>

    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-2 text-default">Dados do Planejamento</span>
        </div>
    </div>

    <div class="row pt-2 pb-6 p-2">
        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Cliente</p>
            <p class="mb-1 h1"><span class="text-muted h1">Nome </span>
                {{caso.beneficio.cnis.identificacaoFiliado.nome}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">CPF </span>
                {{caso.beneficio.cnis.identificacaoFiliado.cpf}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Sexo </span>
                {{caso.beneficio.cnis.identificacaoFiliado.sexo }}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Data Nascimento </span>
                {{caso.beneficio.cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}
            </p>

            <p class="mb-1 h1"><span class="text-muted h1">Idade </span>
                {{caso.beneficio.cnis.identificacaoFiliado.idade.porExtenso}}</p>

        </div>

        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Planejamento</p>
            <p class="mb-1 h1"><span class="text-muted h1">Data do Planejamento </span>
                {{caso.dataPlanejamento | date : 'dd/MM/yyyy'}}</p>

            <p class="mb-1 h1">
                <span class="text-muted h1">Regra </span><span>
                    {{caso.beneficio.qualificacao}} - {{caso.beneficio.qualificacaoDetalhada}}
                </span>
            </p>

        </div>

    </div>

    

    <div class="row">
        <div class="col-12 ">
            <app-variacao-lucro-matriz
                #variacaoLucroMatriz
                *ngIf="matriz"
                [matriz]="matriz"
                (casoSelecionadoEvent)="casoSelecionado($event)"></app-variacao-lucro-matriz>
        </div>
        <div class="col-12 ">
            <app-variacao-rmi-matriz
                #variacaoRmiMatriz
                *ngIf="matriz"
                [matriz]="matriz"
                (casoSelecionadoEvent)="casoSelecionado($event)"></app-variacao-rmi-matriz>
        </div>
    </div>

    <div class="row">
        <div class="col-12 ">
            <app-variacao-data-matriz
                #variacaoDataMatriz
                *ngIf="matriz"
                [matriz]="matriz"
                (casoSelecionadoEvent)="casoSelecionado($event)"></app-variacao-data-matriz>
        </div>

    </div>

    <div class="row">
        <div class="col-12 ">
            <app-variacao-taxa-retorno-matriz
                #variacaoTaxaRetornoMatriz
                *ngIf="matriz"
                [matriz]="matriz"
                (casoSelecionadoEvent)="casoSelecionado($event)"></app-variacao-taxa-retorno-matriz>
        </div>
        <div class="col-12 ">
            <!-- <app-variacao-payback-matriz></app-variacao-payback-matriz> -->
        </div>
    </div>


    <div class="row">
        <div class="col-12 ">
            <app-radar-matriz
                #radarMatriz
                *ngIf="matriz"
                [matriz]="matriz"
                (casoSelecionadoEvent)="casoSelecionado($event)">
            </app-radar-matriz>
        </div>

    </div>

    <!-- <div class="py-5">
        <h5 class=" h3 mb-0 linha-quadro">Extrato de Salários de Contribuição</h5>
        <div class="row p-5">
            <div class="col-md-12">
                <table class="table">
                    <thead style="display: table-header-group;">
                        <tr>
                            <th class="border-0 text-uppercase small font-weight-bold">Competência</th>
    
                            <th class="border-0 text-uppercase small font-weight-bold">Valor Original</th>
    
                            <th class="border-0 text-uppercase small font-weight-bold">Fator de Correção INSS</th>
    
                            <th class="border-0 text-uppercase small font-weight-bold">Valor Atualizado</th>
    
    
    
                        </tr>
                    </thead>
                    <tbody>
    
                        <tr *ngFor="let contribuicao of contribuicoes">
                            <td>{{contribuicao.competencia | date : 'MM/yyyy'}}</td>
    
                            <td>{{contribuicao.valor | currency}}</td>
    
                            <td>{{contribuicao.fatorMultiplicadorCorrecao | number : '1.4-4'}}</td>
    
                            <td>{{contribuicao.valorAtualizado | currency}}</td>
    
    
                        </tr>
    
    
    
    
    
                    </tbody>
                </table>
            </div>
        </div>
    
    </div> -->
    <!-- <app-extrato-beneficio *ngIf="casoMelhor.beneficio" [beneficio]="casoMelhor.beneficio"></app-extrato-beneficio> -->
</div>