<app-cabecalho [titulo]="'Início'"></app-cabecalho>
<div class=" container-fluid mt--3">

  <div class=" row">
    <div class=" col-12">
      <div class="  ">

        <div class="pb-4">
          <div class="card">
            <div class="row">
              <div class="col">
                <div class="card-body vh-100">

                  <div class="row">
                    <div class="col-12">
                      <p class="display-1">Olá,</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="h2">bem vindo(a) ao Calculei, sua plataforma de Investimento Previdenciário</p>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-12">
                      <p class="h4">Comece criando um planejamento contributivo com o método kertzman, ou uma matriz de investimento.</p>
                    </div>
                  </div> -->

                  <!-- <div class="row py-4">

                    <div class="col-auto" [routerLink]="['/calculei/entrevistas/entrevista']" role="button">
                      <i class="comando bi bi-mic fs-2"></i>
                    </div>
                    <div class="col-auto" [routerLink]="['/calculei/entrevistas/entrevista']" role="button">
                      <p class="h1">
                        Entrevista de Planejamento
                      </p>
                      <p class="p-0 m-0">
                        Crie uma ficha de perguntas para o seu cliente e envie o link
                      </p>
                      <p class="h6 p-0 m-0">
                        Ideal para coletar informações para o planejamento.
                      </p>

                    </div>

                  </div> -->

                  <div class="row py-4">

                    <div class="col-auto" [routerLink]="['/calculei/calculos/previdenciario/simulacao']" role="button">
                      <i class="comando bi bi-palette2 fs-2"></i>
                    </div>
                    <div class="col-auto" [routerLink]="['/calculei/calculos/previdenciario/simulacao']" role="button">
                      <p class="h1">
                        Simulação
                      </p>
                      <p class="p-0 m-0">
                        Saiba a data e o valor da aposentadoria
                      </p>
                      <p class="h6 p-0 m-0">
                        Projeção de tempo e contribuições para cumprimento de requisitos das regras de transição da EC 103 2019
                      </p>

                    </div>

                  </div>

                  <div class="row py-4">

                    <div class="col-auto" [routerLink]="['/calculei/calculos/previdenciario/kertzman']" role="button">
                      <i class="comando fs-2">IK
                        <div>
                          <span class="h6 text-white align-bottom">prev</span>
                        </div>
                      </i>
                    </div>
                    <div class="col-auto" [routerLink]="['/calculei/calculos/previdenciario/kertzman']" role="button">
                      <p class="h1">
                        Método Kertzman
                      </p>
                      <p class="p-0 m-0">
                        Planejamento com a melhor taxa de retorno
                      </p>
                      <p class="h6 p-0 m-0">
                        Otimização da taxa de retorno do investimento na aposentadoria por idade de acordo com o Método Kertzman de Planejamento Contributivo Previdenciário
                      </p>

                    </div>

                  </div>

                  <div class="row py-4">
                    <div class="col-auto" role="button" [routerLink]="['/calculei/calculos/matriz']">
                      <i class="comando bi bi-grid-3x3-gap-fill fs-2"></i>

                    </div>
                    <div class="col-auto" role="button" [routerLink]="['/calculei/calculos/matriz']">

                      <p class="h1">
                        Matriz de Investimento
                      </p>
                      <p class="p-0 m-0">
                        Um quadro comparativo com todas as possibilidades de aposentadoria
                      </p>
                      <p class="h6 p-0 m-0">
                        A matriz de investimento previdenciário do calculei é a ferramenta ideal para voocê reduzir o tempo de montagem e análise de cenários de aposentadoria.
                      </p>

                    </div>
                  </div>

                  <div class="row py-4">
                    <div class="col-auto" role="button" [routerLink]="['/calculei/calculos/previdenciario/analisecnis']">

                      <i class="comando bi bi-clipboard2-pulse fs-2"></i>

                    </div>
                    <div class="col-auto" role="button" [routerLink]="['/calculei/calculos/previdenciario/analisecnis']">

                      <p class="h1">
                        Análise de CNIS
                      </p>
                      <p class="p-0 m-0">
                        Validação de sequências
                      </p>
                      <p class="h6 p-0 m-0">
                        Ajustes, avisos, pontos de atenção e indicadores
                      </p>

                    </div>
                  </div>

                 

                  <!-- 
                  <div class="row py-4">

                    <div class="col-auto" [routerLink]="['/calculei/calculos/novocalculo']" role="button">
                      <i class="comando bi bi-plus-square fs-2"></i>
                    </div>
                    <div class="col-auto" [routerLink]="['/calculei/calculos/novocalculo']" role="button">
                      <p class="h1">
                        Outras Opções
                      </p>
                      <p class="p-0 m-0">
                        Novo cálculo a partir de outros modelos
                      </p>
                      <p class="h6 p-0 m-0">
                        Escolha um template de cálculo: Aposentadoria por Invalidez, Qualidade do Segurado, Renda Mensal Inicial e Simulação
                      </p>

                    </div>

                  </div> -->

                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>