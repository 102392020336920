
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-montagem-relacao-previdenciaria',
	templateUrl: './montagem-relacao-previdenciaria.component.html',
	styleUrls: ['./montagem-relacao-previdenciaria.component.scss'],



	encapsulation: ViewEncapsulation.None,
	styles: [
		`
			.dark-modal .modal-content {
				background-color: #292b2c;
				color: white;
			}
			.dark-modal .close {
				color: white;
			}
			.light-blue-backdrop {
				background-color: #5cb3fd;
			}
		`,
	],
})
export class MontagemRelacaoPrevidenciariaComponent {

	@Input() relacaoPrevidenciaria: RelacaoPrevidenciaria;

	@ViewChild('longContent') long;
	closeResult: string;

	constructor(private modalService: NgbModal) { }

	openBackDropCustomClass(content) {
		this.modalService.open(content, { backdropClass: 'light-blue-backdrop' });
	}

	openWindowCustomClass(content) {
		this.modalService.open(content, { windowClass: 'dark-modal' });
	}

	openSm(content) {
		this.modalService.open(content, { size: 'sm' });
	}

	openLg(content) {
		this.modalService.open(content, { size: 'lg' });
	}

	openXl(content) {
		this.modalService.open(content, { size: 'xxl', fullscreen: true });
	}

	openFullscreen(content) {
		this.modalService.open(content, { fullscreen: true });
	}

	openVerticallyCentered(content) {
		this.modalService.open(content, { centered: true });
	}

	public openModal() {

		this.modalService.open(this.long, { size: 'xl', scrollable: true, fullscreen: true });

	}

	openScrollableContent(longContent) {
		this.modalService.open(longContent, { size: 'xl', scrollable: true, fullscreen: true });
	}
}