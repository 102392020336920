<div class="row py-4">
    <div class="col">

        <h5 class=" card-title text-uppercase mb-0 text-default">
            CNIS para cálculo da média de todos os salários
        </h5>
        <button class="btn btn-outline-black" (click)="openModal()">Selecionar CNIS</button>

    </div>
</div>

<ng-template #longContent let-modal>


   

    <div class="modal-body">

        <div class="row">
            <div class="col-12">

                <div [className]="visualizarSeletorCnis ? 'visible' : 'invisible'">
                    
                    <div class="pb-6">
                        <app-cnis-listagem #seletorCnis (leituraCnisSelecionadoEvent)="leituraCNISEvent($event)"></app-cnis-listagem>
                        <!-- <app-seletor-cnis #seletorCnis (leituraCnisSelecionadoEvent)="leituraCNISEvent($event)"></app-seletor-cnis> -->
                    </div>

                    <div class="pb-6">
                        <app-importador-cnis #importadorCNIS (leituraCNISEvent)="leituraCNISEvent($event)"
                            (processandoCNISEvent)="processandoCNISEvent($event)"></app-importador-cnis>
                    </div>

                </div>

                <div [className]="visualizarEditorCnis ? 'visible' : 'invisible'">
                    <div>
                        <app-editor-cnis #editorCNIS *ngIf="calculoBeneficios.leituraCnis"
                            [calculoBeneficios]="calculoBeneficios" [aguardeVisivel]="aguardeVisivel"
                            (edicaoCNISFinalizadaEvent)="edicaoCNISFinalizada($event)"
                            (listaSeletorCnisAtualizadaEvent)="atualizarListaCnis($event)"
                            (excluirCnisFinalizadoEvent)="excluirCnisFinalizado($event)"></app-editor-cnis>
                    </div>

                    

                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <div class="col-xl-12 col-md-12 col-lg-12  d-flex flex-row-reverse">
            <div class="row ">

                <div class="col-auto "
                    [className]="aguardeVisivel ? 'visible col-auto' : 'invisible col-auto'">
                    <div class="col-1 p-4 mb-5  controlenav  ">
                        <span class=" timeline-step timeline-step-xlg text-default spinner-font-weight">
                            <div  class="spinner-border spinner-border-lg"></div>
                        </span>
                    </div>
                </div>
                <div class="col-auto habilitado"
                    (click)="cancelar()">
                    <div class="col-1 p-4 mb-5 active controlenav ">
                        <span class=" timeline-step timeline-step-xlg ">
                            <i class="bi-x-lg"></i>
                        </span>
                    </div>
                </div>
                <div class="col-auto" [className]="continuarHabilitado ? 'habilitado col-auto' : 'desabilitado col-auto'"
                    (click)="continuar()">
                    <div class="col-1 p-4 mb-5 active controlenav ">
                        <span class=" timeline-step timeline-step-xlg  ">
                            <i class="bi-arrow-right"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </div>

</ng-template>