import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-barra-comandos',
  templateUrl: './barra-comandos.component.html',
  styleUrl: './barra-comandos.component.scss'
})
export class BarraComandosComponent {

  @Input() titulo: string;
  @ViewChild('inputTitulo', {static: false}) inputTitulo: ElementRef;
  
  @Output() proximoEvent = new EventEmitter<any>();
  @Output() cancelarEvent = new EventEmitter<any>();
  @Output() anteriorEvent = new EventEmitter<any>();
  @Output() pdfEvent = new EventEmitter<any>();
  @Output() salvarEdicaoCnisEvent = new EventEmitter<any>();
  @Output() editarTituloEvent = new EventEmitter<string>();

  tituloOriginal:string;


  aguarde: boolean;

  constructor(private configService:ConfigService) {
    configService.getAguarde().subscribe(habilitar => {
      this.aguarde = habilitar;
    });
  }
  proximo() {
    this.proximoEvent.emit(true);
  }
  pdf() {
    this.pdfEvent.emit(true);
  }
  anterior() {
    this.anteriorEvent.emit(true);
  }
  cancelar() {
    this.cancelarEvent.emit(true);
  }

  salvarEdicaoCnis(){
    this.salvarEdicaoCnisEvent.emit(true);
  }
  duplicarCalculo(){

  }
  
  edicaoTitulo(event){
    
  }

  setFocusEditorTitulo(event){
    this.inputTitulo.nativeElement.focus();
  }
  blurEditorTitulo(event){

    // console.log('blur editor titulo');
    if(this.titulo === ""){
      this.titulo = this.tituloOriginal
    }else{
      this.editarTituloEvent.emit(event.target.value);
    }

  }
  focoEditorTitulo(event){
    this.tituloOriginal = this.titulo;
    this.titulo = "";
    // console.log('titulo original', this.tituloOriginal);
  }

  duplicar(){

    window.open(window.location.href, "_blank")
    
  }
}

