
  <div class="row area-top-md">
    <div class="col-md-12">

      <div class="row">
        <div class="col-md-12 bg-lighter">
          <div class="row">
            <div class="col-md-4">
              <img src="assets/img/theme/logo-dark.png" width="100" class="area "/>
            </div>
            <div class="col-md-8 text-right">
              <h1 class="display-5 area-top-md">Calculei Advogados Associados</h1>
              <span >Rua perdizes, n. 10, Jd. Renascença, São Luís - MA</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center area-top-lg">
          <h1 class="display-1">Relatório de Tempo de Contribuição</h1>
        </div>
          
      </div>
      <div class="row area-top-lg">
        <div class="col-md-12">
          <h1 class="display-4">Assistido</h1>
        </div>
      </div>

      <div id="assistido" [className]="relatorioTempoContribuicao.identificacaoFiliado!=undefined? 'visible' : 'invisible'">

        <div class="row">
          <div class="col-md-12">
            <div class="row area-top-sm">
              <div class="col-md-6">
                <span>Nome: </span>
                <span>{{relatorioTempoContribuicao.identificacaoFiliado.nome}}</span>
              </div>
              <div class="col-md-6">
                <span>CPF: </span>
                <span>{{relatorioTempoContribuicao.identificacaoFiliado.cpf}}</span>
              </div>
            </div>
            <div class="row area-top-sm">
              <div class="col-md-6">
                <span>NIT: </span>
                <span>{{relatorioTempoContribuicao.identificacaoFiliado.nit}}</span>
              </div>
              <div class="col-md-6">
                <span>Data do Extrato Previdenciário: </span>
                <span>{{relatorioTempoContribuicao.identificacaoFiliado.dataExtratoPrevidenciario | date : 'dd/MM/yyyy'}}</span>
              </div>
            </div>
            <div class="row area-top-sm">
              <div class="col-md-6">
                <span>Data Nascimento: </span>
                <span>{{relatorioTempoContribuicao.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}</span>
              </div>
              <div class="col-md-6">
                <span>Idade: </span>
                <span>{{relatorioTempoContribuicao.identificacaoFiliado.idade.porExtenso}}</span>
              </div>
            </div>
            <div class="row area-top-sm">
              <div class="col-md-6">
                <span>Nome da Mãe: </span>
                <span>{{relatorioTempoContribuicao.identificacaoFiliado.nomeMae}}</span>
              </div>
              <div class="col-md-6">
                <span>Tempo de Contribuição: </span>
                <span>{{relatorioTempoContribuicao.tempoContribuicao}}</span>
              </div>
            </div>
            <div class="row area-top-sm">
              <div class="col-md-6">
                <span>Sexo: </span>
                <span>{{relatorioTempoContribuicao.identificacaoFiliado.sexo}}</span>
              </div>
              <div class="col-md-6">
                <span>Benefício: </span>
                <span>{{relatorioTempoContribuicao.beneficios}}</span>
              </div>
            </div>
            
            <div class="row area-top-sm">
              <div class="col-md-12">
                <hr/>
            </div>
          </div>
        </div>
      </div>

<!-- //11.997 -->
      <!-- <div class="row area-top-md">
        <div class="col-md-12">
          <h1 class="display-4">Análise</h1>
          <span>{{cnis.identificacaoFiliado.nomeMae}}</span>
        </div>
      </div>
      <div class="row area-top-sm">
        <div class="col-md-12">
          <h1 class="lead">Conteúdo da análise...</h1>
          <span>{{cnis.identificacaoFiliado.nomeMae}}</span>
        </div>
      </div>
      <div class="row area-top-sm">
        <div class="col-md-12 text-right">
          <h1 class="lead">Rogério Belchior</h1>
          <span>Consultor Previdenciário</span>
          <span>{{cnis.identificacaoFiliado.nomeMae}}</span>
        </div>
      </div>

      <div class="row area-top-sm bg-lighter">
        <div class="col-md-12">
          <i class="fas fa-exclamation-triangle"></i>
          <span class="area">Disclaimer da análise</span>
          <span>{{cnis.identificacaoFiliado.nomeMae}}</span>
        </div>
      </div> -->


      
      <div class="col-md-12">
        <app-detalhamento-relacoes-previdenciarias *ngIf="relatorioTempoContribuicao.relacoesPrevidenciarias" [relacoesPrevidenciarias]="relatorioTempoContribuicao.relacoesPrevidenciarias"></app-detalhamento-relacoes-previdenciarias>  
      </div>
      
      
    </div>