import { ImpostoRenda } from './../common/impostorenda';
import { CasoPlanejamentoPrevidenciario } from "./casoplanejamentoprevidenciario";

export class CapitalizacaoMensal {
    mes: number;
    investimentoAcumulado: number;
    taxaReal: number;
    rendimentoMes: number;
    saque:number;


    public static capitalizacao(caso: CasoPlanejamentoPrevidenciario) : CapitalizacaoMensal[] {

        let linhasCapitalizacaoMensal:CapitalizacaoMensal[] = new Array<CapitalizacaoMensal>();
        let indexMes = 0;
        let totalAcumulado = 0;


        while (indexMes < caso.beneficio.relatorioRendaMensalInicial.contribuicoes.filter(contribuicao=>contribuicao.substituicaoTeto).length) {

            totalAcumulado += totalAcumulado * caso.taxaRentabilidadePrivadaMensalCapitalizada + caso.valorContribuicaoComplementarMensal;

            let novaLinhaCapitalizacaoMensal = new CapitalizacaoMensal();
            novaLinhaCapitalizacaoMensal.mes = indexMes + 1;
            novaLinhaCapitalizacaoMensal.investimentoAcumulado = totalAcumulado;
            novaLinhaCapitalizacaoMensal.taxaReal = caso.taxaRentabilidadePrivadaMensalCapitalizada * 100;
            novaLinhaCapitalizacaoMensal.rendimentoMes = totalAcumulado * caso.taxaRentabilidadePrivadaMensalCapitalizada;

            linhasCapitalizacaoMensal.push(novaLinhaCapitalizacaoMensal);
            indexMes++;

        }

        return linhasCapitalizacaoMensal;

    }


    public static capitalizacaoSaque(caso: CasoPlanejamentoPrevidenciario) : CapitalizacaoMensal[] {

        let linhasCapitalizacaoMensal:CapitalizacaoMensal[] = new Array<CapitalizacaoMensal>();
        let indexMes = 0;
        let totalAcumulado = caso.totalContributivoCapitalizadoGanhoReal;
       

        // while (indexMes < caso.duracaoSaqueCapitalizacaoMeses) {
        while (totalAcumulado > 0) {

            // totalAcumulado = totalAcumulado - ((totalAcumulado - caso.valorSaqueCapitalizacao) * caso.taxaRentabilidadePrivadaMensalCapitalizada);

            let saque = totalAcumulado - caso.valorSaqueCapitalizacao > 0 ? caso.valorSaqueCapitalizacao : totalAcumulado;
            let saldo = totalAcumulado - saque;

            // let rendimento = saldo * caso.taxaRentabilidadePrivadaMensalCapitalizada;
            // totalAcumulado = saldo + rendimento;

            let novaLinhaCapitalizacaoMensal = new CapitalizacaoMensal();
            novaLinhaCapitalizacaoMensal.mes = indexMes + 1;
            novaLinhaCapitalizacaoMensal.investimentoAcumulado = saldo;
            novaLinhaCapitalizacaoMensal.taxaReal = caso.taxaRentabilidadePrivadaMensalCapitalizada * 100;
            // novaLinhaCapitalizacaoMensal.rendimentoMes = rendimento;
            novaLinhaCapitalizacaoMensal.saque = saque;
            linhasCapitalizacaoMensal.push(novaLinhaCapitalizacaoMensal);

            totalAcumulado = saldo;
            indexMes++;

        }

        return linhasCapitalizacaoMensal;

    }

    public static pmt(yearlyInterestRate:number, totalNumberOfMonths:number, loanAmount:number){
        let rate = yearlyInterestRate /12;
        let denominator = Math.pow((1+rate), totalNumberOfMonths) - 1;
        return (rate + (rate/denominator)) * loanAmount;
    }


    public static tabelaCapitalizacaoSaque(caso:CasoPlanejamentoPrevidenciario):CapitalizacaoMensal[]{

        let lista:CapitalizacaoMensal[] = new Array();
        let totalMeses:number = caso.duracaoSaqueCapitalizacaoMeses;
        let investimentoInicial:number = caso.totalContributivoCapitalizadoGanhoReal;
        let valorSaque:number = this.pmt(caso.taxaGanhoRealPrivadaAno, totalMeses, investimentoInicial);
        let indexMes = 0;
        let totalAcumulado:number = investimentoInicial;
        let taxaRendimento:number = caso.taxaRentabilidadePrivadaMensalCapitalizada;


        while (indexMes < totalMeses) {
            
            let rendimentoMensal:number = totalAcumulado * taxaRendimento;
            valorSaque = totalAcumulado + rendimentoMensal - valorSaque > 0 ? valorSaque : totalAcumulado + rendimentoMensal;
            totalAcumulado = totalAcumulado + rendimentoMensal - valorSaque;

            let capitalizacao:CapitalizacaoMensal = new CapitalizacaoMensal();
            capitalizacao.mes = indexMes + 1;
            capitalizacao.investimentoAcumulado = totalAcumulado;
            capitalizacao.taxaReal = taxaRendimento;
            capitalizacao.rendimentoMes = rendimentoMensal;
            capitalizacao.saque = valorSaque;

            indexMes++;

            lista.push(capitalizacao);
        }

        return lista;

    }


    public static tabelaCapitalizacao(caso:CasoPlanejamentoPrevidenciario): CapitalizacaoMensal[]{

        let lista:CapitalizacaoMensal[] = new Array();

        let indexMes:number = 0 + 1;
        let totalAcumulado:number = 0;

        // console.log('investimento', caso.enquadramento.impostoRenda.investimento);
        // console.log('contribuicao complementar mensal', caso.valorContribuicaoComplementarMensal);
        // console.log(caso);
        // console.log(caso.totalContributivoComplementarMeses);
        // console.log('caso investimento positivo ', caso.valorContribuicaoComplementarMensal + (caso.custoContributivoTotalImpostoRenda / caso.totalSubstituicoesTeto));

        // console.log('seletor divisor', caso.totalSubstituicoesTeto > 0 ? caso.totalSubstituicoesTeto : caso.totalContributivoComplementarMeses);
        let totalMeses = caso.totalSubstituicoesTeto > 0 ? caso.totalSubstituicoesTeto : caso.totalContributivoComplementarMeses;
        caso.valorDepositoProdutoPrivado = caso.enquadramento.impostoRenda.investimento ? caso.valorContribuicaoComplementarMensal + (caso.custoContributivoTotalImpostoRenda / totalMeses): caso.valorContribuicaoComplementarMensal;
        
        while (indexMes < totalMeses) {
            totalAcumulado += totalAcumulado * caso.taxaRentabilidadePrivadaMensalCapitalizada + caso.valorDepositoProdutoPrivado;

            let capitalizacao:CapitalizacaoMensal = new CapitalizacaoMensal()
            capitalizacao.mes = indexMes;
            capitalizacao.investimentoAcumulado = totalAcumulado;
            capitalizacao.taxaReal = caso.taxaRentabilidadePrivadaMensalCapitalizada * 100;
            capitalizacao.rendimentoMes = totalAcumulado * caso.taxaRentabilidadePrivadaMensalCapitalizada;

            lista.push(capitalizacao);
            indexMes++;
        }

        return lista;
    }

}