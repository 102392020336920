import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { RequestCalculoBeneficios } from 'src/app/modelos/previdenciario/requestcalculobeneficios';
import { ListarGruposSumarioCalculoBeneficiosService } from 'src/app/servicos/calculo/previdenciario/listar-grupos-sumario-calculo-beneficios.service';
import { ToastrService } from "ngx-toastr";


import { v4 as uuidv4 } from 'uuid';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { ListarRelatorioRendaMensalInicialPdfService } from 'src/app/servicos/relatorio/listar-relatorio-renda-mensal-inicial-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { FasesBeneficios } from 'src/app/modelos/ui/fasesbeneficios';
import { FluxoBeneficios } from 'src/app/modelos/ui/fluxobeneficios';
import { FluxoRevisaoVidaToda } from 'src/app/modelos/ui/fluxorevisaovidatoda';
import { FasesRevisaoVidaToda } from 'src/app/modelos/ui/fasesrevisaovidatoda';
import { Fluxos } from 'src/app/modelos/ui/fluxos';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { ListarPreAnaliseKertzmanService } from 'src/app/servicos/calculo/previdenciario/listar-pre-analise-kertzman.service';
import { ListarCalculoBeneficiosPlanejamentoKertzmanService } from 'src/app/servicos/calculo/previdenciario/listar-calculo-beneficios-planejamento-kertzman.service';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { RelatorioBeneficioComponent } from '../relatorio-beneficio/relatorio-beneficio.component';

@Component({
  selector: 'app-pre-analise-beneficio-kertzman',
  templateUrl: './pre-analise-beneficio-kertzman.component.html',
  styleUrls: ['./pre-analise-beneficio-kertzman.component.scss']
})
export class PreAnaliseBeneficioKertzmanComponent implements OnInit, AfterViewInit {

  @ViewChild('relatorioBeneficio') relatorioBeneficio: RelatorioBeneficioComponent;

  // @Input() calculoBeneficios: CalculoBeneficios;
  @Input() calculoPlanejamentoKertzman: CalculoPlanejamentoKertzman;
  
  @Output() visualizarSumarioEvent = new EventEmitter<boolean>();
  @Output() gruposProntoEvent = new EventEmitter<CalculoBeneficios>();
  @Output() requestFinalizadaEvent = new EventEmitter<boolean>();
  @Output() processandoCNISEvent = new EventEmitter<boolean>();
  @Output() processandoEvent = new EventEmitter<boolean>();


  
  visaoRelatorioFlag: boolean = false;
  aguardandoPdf: boolean = false;
  revisaoVidaToda: boolean = false;

  contribuicoesEvolucaoSalario: Contribuicao[];


  constructor(private listarCalculoBeneficiosPlanejamentoKertzmanService: ListarCalculoBeneficiosPlanejamentoKertzmanService,
    private listarRelatorioRendaMensalInicialPdfService: ListarRelatorioRendaMensalInicialPdfService,
    private notificacoesService: NotificacoesService) { }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {

  }

}
