



import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, retry } from 'rxjs';
import { ImportacaoFatura } from 'src/app/modelos/faturas/importacaofatura';
import { ImportacaoCNIS } from 'src/app/modelos/previdenciario/importacaocnis';
import { environment } from 'src/environments/environment';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';

@Injectable({
  providedIn: 'root'
})
export class AtualizarEdicaoCnisService {

  constructor(private httpClient: HttpClient) { }

  atualizar(calculoBeneficios:CalculoBeneficios){
    return Promise.all([this.apiCall(calculoBeneficios)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(calculoBeneficios:CalculoBeneficios){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/AtualizarEdicaoCnis',calculoBeneficios, {'headers':headers})
    .pipe(retry({count:1, delay:3000}))
    .toPromise();
    return data;
  }
}
