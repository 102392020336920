import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { environment } from 'src/environments/environment';
import { RelatorioCalculoRemocaoTusdBaseIcms } from 'src/app/modelos/relatorios/relatoriocalculoremocaotusdbaseicms';
import { RelatorioLiquidacaoSentencaoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioliquidacaosentencaprevidenciario';
import { RelatorioPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioplanejamentoprevidenciario';

@Injectable({
  providedIn: 'root'
})
export class ListarRelatorioPlanejamentoPdfService {

  constructor(private httpClient:HttpClient) { }


  ListarPDF(relatorio:RelatorioPlanejamentoPrevidenciario) :Observable<Blob>{
    return this.httpClient.post(environment.api as string + 'api/PlanejamentoPrevidenciarioPDF', relatorio, {responseType: 'blob'});
  }
 
}
