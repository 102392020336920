<app-cabecalho [titulo]="'Configurações'" ></app-cabecalho>
<div class="container-fluid mt--3">
  <div class="card-deck flex-column">
    <div class="pb-4">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col pb-4">
              <span class="display-3 font-weight-lighter mb-0 text-default">
                Identidade Visual
              </span>
            </div>
          </div>

          <app-minha-logo></app-minha-logo>
              <app-meu-avatar></app-meu-avatar>
          
        </div>
      </div>
    </div>
  </div>
</div>
