import { Component } from '@angular/core';
import { Assinatura } from 'src/app/modelos/pagamento/assinatura';
import { StatusAssinatura } from 'src/app/modelos/usuario/statusassinatura';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-minha-assinatura',
  templateUrl: './minha-assinatura.component.html',
  styleUrl: './minha-assinatura.component.scss'
})
export class MinhaAssinaturaComponent {

  assinatura:Assinatura;
  statusAssinaturaType = StatusAssinatura;

  constructor(private configService:ConfigService){
    this.configService.getUsuario().subscribe(usuario=>{
      this.assinatura = usuario.assinatura;
    });
  }

}
