
<div class="row">
    <div class="col-12">
        <h5 class=" h3 mb-0 linha-quadro pb-4">Clientes</h5>
    </div>
</div>

<div class="py-4">
    <app-add-cliente (clienteAdicionadoEvent)="clienteAdicionado($event)" ></app-add-cliente>
</div>
<div class="py-4">
    <app-lista-clientes #listaClientes (clienteSelecionadoEvent)="clienteSelecionado($event)"></app-lista-clientes>
</div>
