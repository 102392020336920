
<ng-template #classic3 let-c="close" let-d="dismiss">
    <div class="modal-content">
      <div class="col-12 modal-header">
        <h5 class="h3 mb-0 linha-quadro" id="modal-title-default">Nova Regra de Atualização Monetária</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
          
          <i class="ni ni-fat-remove " ></i>
        </button>
      </div>
      <div class="modal-body">

        <form class="form-group" [formGroup]="novaRegraForm" >

            <div class="row py-2">
                <div class="col-lg-12">
                    <div class="form-group">
                        <h5 class=" card-title text-uppercase mb-0 text-default">
                            Data Inicial
                          </h5>
                        <input
                        [inputMask]="dateInputMask"
                        class=" form-control"
                        
                        placeholder="dd/mm/aaaa"
                        type="text"
                        formControlName="dataInicial"/>
                        
                    </div>
                </div>
            </div>

            <div class="row py-2">
                <div class="col-lg-12">
                    <div class="form-group">
                        <h5 class=" card-title text-uppercase mb-0 text-default">
                            Data Final
                          </h5>
                        <input
                        [inputMask]="dateInputMask"
                        class=" form-control"
                        
                        placeholder="dd/mm/aaaa"
                        type="text"
                        formControlName="dataFinal"/>
                        
                    </div>
                </div>
            </div>
    
            <div class="row py-2">
                <div class="col-lg-12">
                    <div class="form-group">
                        <h5 class=" card-title text-uppercase mb-0 text-default">
                            Indexador
                          </h5>

                        <ngx-select
                        formControlName="indice"
                        [items]="itemsSelectIndice"
                        placeholder="Selecionar Tipo do Benefício"
                        (selectionChanges)="selectItemIndicesChanges($event)">
                        </ngx-select>

                        <!-- <select class="form-control" 
                        data-toggle="select" 
                        title="Simple select" 
                        data-live-search="true" 
                        data-live-search-placeholder="Buscar..." 
                        id="selectIndice"
                        formControlName="indice">
                        </select> -->
                        
                    </div>
                </div>
            </div>


            <div class="row py-2">
                
                <div class="col text-left">
                    <div class="form-group">
                        <button class="btn btn-outline-danger" type="button" (click)="cancelarNovaRegra()">Cancelar</button>
                        <button class="btn btn-outline-black" type="button" [disabled]="!novaRegraForm.valid" (click)="submitNovaRegra()">Adicionar Regra</button>
                    </div>
                </div>
            </div>

        </form>
      </div>
    </div>
  </ng-template>

<button class="btn btn-outline-black" (click)="novaRegra(classic3)" type="button">
    Adicionar regra de aplicação de indexador por período
</button>