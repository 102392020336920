

<div class="row">
  <div class="col-12">
    <h5 class=" h3 mb-0 linha-quadro">Salários de Contribuição</h5>
    <div class="row m-0 p-0 ">
      <div class=" col-12">

        <div class="table-responsive">
          <table class="my-4 table table-striped table-hover table-bordered table align-middle">
            <thead class="" style="display: table-header-group; page-break-inside: avoid !important;">
              <tr class="text-center ">
                <th scope="col">Competência</th>
                <th scope="col">Salário de Contribuição<br> (A)</th>
                <th scope="col">Coef. Corr. Monetária<br> (B)</th>
                <th scope="col">Salário Atualizado<br> (C = A*B)</th>
                <th scope="col">Obs.</th>
  
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let salario of beneficio.relatorioRendaMensalInicial.contribuicoes">
                <th scope="row">{{salario.competencia | date : 'MM/yyyy'}}</th>
                <td>{{salario.valor | currency}}</td>
                <td>{{salario.fatorMultiplicadorCorrecao | number : '1.0-5'}}</td>
                <td>{{salario.valorAtualizado | currency}}</td>
  
                <td>
                  <ng-container *ngIf="salario.descarte">
                    <span class="my-1 badge rounded-pill" [ngClass]="{'bg-red': true}">
                      descarte
                  </span>
                  </ng-container>
                </td>
  
              </tr>
  
            </tbody>
          </table>
        </div>

        <!-- <div class="dataTables_wrapper py-4">

          <ngx-datatable #tabelaContribuicoes
            class="material striped selection-cell" [headerHeight]="50"
            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries : undefined" [rows]="temp"
            (activate)="onActivate($event)" [messages]="{emptyMessage: 'Nenhuma contribuição encontrada'}"
            [rows]="rows"
            [columnMode]="'flex'"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            (page)="onPage($event)"
            (activate)="onActivate($event)"
            (select)="onSelect($event)">

            <ngx-datatable-column
              name="Competência"
              prop="competencia"
              [flexGrow]="25"
              [sortable]="false"
              [draggable]="false">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span [ngClass]="row.relevante ? '': 'naocalculada'">{{ value | date : "MM/yyyy" }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="Valor Original"
              prop="valor"
              [flexGrow]="25"
              [sortable]="false"
              [draggable]="false">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span [ngClass]="row.relevante ? '': 'naocalculada'">{{ value | currency }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="Fator de Correção INSS"
              prop="fatorMultiplicadorCorrecao"
              [flexGrow]="25"
              [sortable]="false"
              [draggable]="false">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span [ngClass]="row.relevante ? '': 'naocalculada'">{{ value }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="Valor Atualizado"
              prop="valorAtualizado"
              [flexGrow]="30"
              [sortable]="false"
              [draggable]="false">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span [ngClass]="row.relevante ? '': 'naocalculada'">{{ value | currency }}</span>
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>

        </div> -->

      </div>
    </div>

  </div>
</div>