import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class ListarMontagemCasoPlanejamentoService {

  constructor(private httpClient: HttpClient) { }

  listarMontagem(request:CalculoPlanejamentoPrevidenciario){
    return Promise.all([this.apiCall(request)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  listarMontagemDupla(request:CalculoPlanejamentoPrevidenciario, requestKertzman:CalculoPlanejamentoKertzman){
    return Promise.all([this.apiCall(request), this.apiCallKertzman(requestKertzman)]).then((listaAPI)=>{
      return listaAPI;
    });
  }

  private async apiCall(request:CalculoPlanejamentoPrevidenciario){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.api as string + 'api/ListarMontagemCasoPlanejamento',request, {'headers':headers}).toPromise();
    // const response = data;
    // const dataSalvar = this.httpClient.post(environment.api as string + 'api/SalvarCalculoPlanejamento',response, {'headers':headers}).toPromise();
    return data;
  }

  private async apiCallKertzman(request:CalculoPlanejamentoKertzman){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    let requestClean:CalculoPlanejamentoKertzman = JSON.parse(JSON.stringify(request));
    requestClean.casos = [];
    const data = await this.httpClient.post(environment.api as string + 'api/ListarMontagemCasoPlanejamentoKertzman',requestClean, {'headers':headers}).toPromise();
    // const response = data;
    // const dataSalvar = this.httpClient.post(environment.api as string + 'api/SalvarCalculoPlanejamentoKertzman',response, {'headers':headers}).toPromise();
    return data;
  }
}
