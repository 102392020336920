<div *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio">
    <!-- <app-marcador-caso [marcador]="marcadorCaso"></app-marcador-caso> -->



    

    
    <app-destaque-kertzman [visualizarPdf]="true" (gerarPdfEvent)="gerarPDF($event)" #destaqueKertzman *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio" [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-destaque-kertzman>

    <app-variacao-contribuicoes-originais-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-contribuicoes-originais-simulacao-kertzman>

    <app-variacao-rmi-simulacao-kertzman-chart  [visualizarPdf]="false" [relatorio]="calculoPlanejamentoKertzman.relatorio" (recalcularOtimizacaoEspecificaEvent)="recalcularOtimizacaoEspecifica($event)"></app-variacao-rmi-simulacao-kertzman-chart>

    <app-variacao-payback-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-payback-simulacao-kertzman>

    <app-variacao-taxa-payback-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio" ></app-variacao-taxa-payback-simulacao-kertzman>

    <app-variacao-lucro-simulacao-kertzman [visualizarPdf]="false"  [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-variacao-lucro-simulacao-kertzman>

    <app-caso-planejamento-previdenciario [relatorio]="calculoPlanejamentoKertzman.relatorio"></app-caso-planejamento-previdenciario>

    <!-- <app-relatorio-beneficio [beneficio]="calculoPlanejamentoKertzman.otimizacao.beneficio"></app-relatorio-beneficio>  -->

</div>