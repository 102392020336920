
<div class="row py-4">
    <div class="col">
        <h5 class=" h3 mb-0 linha-quadro">
            Lista de Indicadores no CNIS
            <i class=" comando bi bi-person-raised-hand h6" type="button" data-bs-toggle="collapse" data-bs-target="#help" aria-expanded="false" aria-controls="help">

            </i>
            <div class="collapse" id="help">
                <p class=" lh-1 py-2">
                    Lista de indicadores presentes nas sequências e contribuições do CNIS.
                </p>

            </div>
        </h5>
    </div>
</div>

<div class="row py-2">
    <div class="col-12">

        <ng-container class *ngFor="let sequencia of calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias; index as index;">

            <div class>
                <ng-container *ngIf="sequencia.indicadoresCnis.length!=0">
                    <div class="pb-4">
                        <span>Indicadores na Sequência {{sequencia.sequencia}}</span>
                        <div class="table-responsive">
                            <table class="table table-bordered table-sm  table-striped  table-hover align-middle">
                                <thead>

                                    <tr>
                                        <th class=" alignt-middle text-center" scope="col" width="20%">
                                            <span>
                                                Sigla
                                            </span>
                                        </th>
                                        <th class=" alignt-middle text-center" scope="col" width="10%">
                                            <span class>
                                                Descrição
                                            </span>
                                        </th>
                                        <th class=" alignt-middle text-center" scope="col">
                                            <span class>
                                                Esclarecimentos
                                            </span>
                                        </th>
                                        <th class=" alignt-middle text-center" scope="col">
                                            <span class>
                                                Providências
                                            </span>
                                        </th>

                                    </tr>

                                </thead>
                                <tbody>
                                    <ng-container *ngIf="sequencia.indicadoresCnis">
                                        <ng-container *ngIf="sequencia.indicadoresCnis.length>0">
                                            <ng-container class *ngFor="let indicador of sequencia.indicadoresCnis; index as index;">

                                                <tr>
                                                    <td class=" align-middle text-center">
                                                        <span class=" align-middle text-center display-4 fw-lighter">
                                                            {{indicador.sigla}}
                                                        </span>

                                                    </td>

                                                    <td class=" align-middle text-center">
                                                        {{indicador.descricao}}
                                                    </td>
                                                    <td class=" align-middle text-justify">
                                                        {{indicador.esclarecimentos}}
                                                    </td>
                                                    <td class=" align-middle text-justify">
                                                        {{indicador.providenciasNecessarias}}
                                                    </td>

                                                </tr>

                                            </ng-container>

                                        </ng-container>
                                        <ng-container *ngIf="sequencia.indicadoresCnis.length==0">
                                            <tr>
                                                <td colspan="4" class="align-middle text-center">
                                                    <span class="fw-lighter"> Nenhum indicador na sequência {{sequencia.sequencia}}</span>
                                                </td>
                                            </tr>

                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="(sequencia.contribuicoes | indicadoresUnicos).length > 0">
                    <div class="pb-4">
                        <span>Indicadores nas Contribuições da Sequência {{sequencia.sequencia}}</span>
                        <table class="table table-bordered table-sm  table-striped  table-hover align-middle">
                            <thead>

                                <tr>
                                    <th class=" alignt-middle text-center" scope="col" width="20%">
                                        <span>
                                            Sigla
                                        </span>
                                    </th>
                                    <th class=" alignt-middle text-center" scope="col" width="10%">
                                        <span class>
                                            Descrição
                                        </span>
                                    </th>
                                    <th class=" alignt-middle text-center" scope="col">
                                        <span class>
                                            Esclarecimentos
                                        </span>
                                    </th>
                                    <th class=" alignt-middle text-center" scope="col">
                                        <span class>
                                            Providências
                                        </span>
                                    </th>

                                </tr>

                            </thead>
                            <tbody>
                                <ng-container *ngIf="sequencia.contribuicoes && sequencia.contribuicoes.length > 0 ">

                                    <ng-container class *ngFor="let indicador of (sequencia.contribuicoes | indicadoresUnicos); index as index;">

                                        <tr>
                                            <td class=" align-middle text-center">
                                                <span class=" align-middle text-center display-4 fw-lighter">
                                                    {{indicador.sigla}}

                                                </span>
                                                
                                            </td>

                                            <td class=" align-middle text-center">
                                                {{indicador.descricao}}
                                            </td>
                                            <td class=" align-middle text-justify">
                                                {{indicador.esclarecimentos}}
                                            </td>
                                            <td class=" align-middle text-center">
                                                {{indicador.providenciasNecessarias | grupoIndicador}}
                                            </td>

                                        </tr>

                                    </ng-container>

                                    <ng-container *ngIf="(sequencia.contribuicoes | indicadoresUnicos).length==0">
                                        <tr>
                                            <td colspan="4" class="align-middle text-center">
                                                <span class="fw-lighter"> Nenhum indicador nas contribuições da sequência {{sequencia.sequencia}}</span>
                                            </td>
                                        </tr>

                                    </ng-container>

                                </ng-container>

                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>

        </ng-container>

    </div>
</div>
