import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Safe2payModule } from './safe2pay/safe2pay.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Safe2payModule
  ]
})
export class PagamentoModule { }
