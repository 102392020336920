import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Global } from 'src/app/modelos/global';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ExtratoBeneficioComponent } from '../extrato-beneficio/extrato-beneficio.component';

@Component({
  selector: 'app-relatorio-beneficio',
  templateUrl: './relatorio-beneficio.component.html',
  styleUrls: ['./relatorio-beneficio.component.scss']
})
export class RelatorioBeneficioComponent implements OnInit, AfterViewInit {

  @Input() beneficio:Beneficio;

  @ViewChild('extratoBeneficio') extratoBeneficio:ExtratoBeneficioComponent;
  
  
  constructor() {
    
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    this.carregarDados();
    
  }

  carregarDados(){

    if(this.extratoBeneficio != undefined){
      this.extratoBeneficio.carregarDados();
    }

  }
  

}
