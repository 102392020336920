import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Pergunta } from 'src/app/modelos/previdenciario/pergunta';
import { Resposta } from 'src/app/modelos/previdenciario/resposta';
import { TiposResposta } from 'src/app/modelos/previdenciario/tiposResposta';
import { TipoRespostaPipe } from '../../compartilhado/pipes/tipo-resposta.pipe';

@Component({
  selector: 'app-nova-pergunta',
  templateUrl: './nova-pergunta.component.html',
  styleUrl: './nova-pergunta.component.scss'
})
export class NovaPerguntaComponent {


  @ViewChild("novaOpcao") novaOpvao: ElementRef;

  @Output() addPerguntaEvent = new EventEmitter<Pergunta>();
  pergunta: Pergunta;

  tiposRespostasType = TiposResposta;
  novaPerguntaForm = this.fb.group({
    titulo: [""],
    descricao: [""],
    instrucao: [""],
    link: [""],
  });


  listaNovasOpcoes: string[] = new Array<string>();

  itemsTiposResposta: any[];
  constructor(private fb: FormBuilder,
    private tipoRespostaPipe: TipoRespostaPipe
  ) {

    this.pergunta = new Pergunta();
    this.pergunta.tipoResposta = TiposResposta.TEXTO;
    this.pergunta.titulo = "";
    this.pergunta.descricao = "";
    this.pergunta.instrucao = "";
    this.pergunta.link = "";
    this.pergunta.respostas = new Array<Resposta>();
    this.pergunta.opcoes = new Array<string>();
    this.pergunta.respondida = false;

    

    this.itemsTiposResposta = (Object.keys(TiposResposta) as Array<keyof typeof TiposResposta>)
      .filter(chave => isNaN(Number(chave)))
      .map(tipo => { return { value: tipo, text: this.tipoRespostaPipe.transform(TiposResposta[tipo]) } });


      this.novaPerguntaForm.valueChanges.subscribe(changes=>{

        this.pergunta.titulo = changes.titulo;
        this.pergunta.descricao = changes.descricao;
        this.pergunta.instrucao = changes.instrucao;
        this.pergunta.link = changes.link;

      })
    
  }

  editarTipoResposta(value: string) {
    // console.log('value tipo resposta',value);
    this.pergunta.tipoResposta = TiposResposta[value];
  }


  addOpcao() {
    this.listaNovasOpcoes.push(this.novaOpvao.nativeElement.value);
    // console.log("lista opcoes", this.listaNovasOpcoes);
    this.pergunta.opcoes = this.listaNovasOpcoes;

  }

  addPergunta() {
    // console.log('add pergunta nova pergunta', this.pergunta)
    this.addPerguntaEvent.emit(JSON.parse(JSON.stringify(this.pergunta)));
  }
}
