
<div class="row">
    <div class="col-12">
        <h5 class=" h3 mb-0 linha-quadro pb-4">Validade</h5>
    </div>
</div>



<div class="row py-2">
    <div class="col-3">
        <h5 class="  text-uppercase mb-0 text-default">
            Mensagel Inicial
        </h5>
        <textarea class=" form-control text-left" type placeholder="Ex. atividade autônoma é aquela que..." [(ngModel)]="entrevista.mensagemInicial"></textarea>
        <p class="h6">
            Mensagem apresentada antes de iniciar o formulário
        </p>
    </div>


    <div class="col-3">
        <h5 class="  text-uppercase mb-0 text-default">
            Mensagem Final
        </h5>
        <textarea class=" form-control text-left" type placeholder="Ex. atividade autônoma é aquela que..." [(ngModel)]="entrevista.mensagemFinal"></textarea>
        <p class="h6">
            Aparece ao concluir o formulário
        </p>
    </div>


    <div class="col-3">
        <h5 class="  text-uppercase mb-0 text-default">
            Responder até
        </h5>
        <input [inputMask]="dateInputMask" class=" form-control text-left" type="text" [(ngModel)]="inputDataValidade"  />
        <p class="h6">
            Data de validade da entrevista
        </p>
    </div>
</div>

