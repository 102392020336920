import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OAuthEvent } from 'angular-oauth2-oidc';
import { AppComponent } from 'src/app/app.component';
import { Global } from 'src/app/modelos/global';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { LogService } from 'src/app/servicos/log.service';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { InicializarUsuarioService } from 'src/app/servicos/usuario/inicializar-usuario.service';
import { ListarUsuarioService } from 'src/app/servicos/usuario/listar-usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {



  constructor(private autenticacaoService: AutenticacaoService,
    private router: Router,
    private route: ActivatedRoute,
    private inicializarUsuarioService: InicializarUsuarioService,
    private listarUsuarioService: ListarUsuarioService,
    private configService: ConfigService) {



    this.route.queryParams.subscribe(params => {

      if (params['state'] === undefined && params['code'] === undefined) {

        if (!this.autenticacaoService.oauthService.hasValidAccessToken() && this.autenticacaoService.oauthService.getAccessToken() == null && this.autenticacaoService.oauthService.getIdToken() == null) {
          console.log('auth token event init code flow');
          this.autenticacaoService.oauthService.initCodeFlow();
        } else {
          this.autenticacaoService.oauthService.tryLoginCodeFlow().then(() => {
            this.configService.initializeAfterLogin();
            console.log('auth token event initialize after login');
            setTimeout(() => {
              this.autenticacaoService.login();
            }, 2000);
            if (this.autenticacaoService.isLoggedIn()) {
              LogService.log("logado ");
            }
            if (!this.autenticacaoService.isLoggedIn()) {
              LogService.log("nao logado ");
            }
          });
        }
      }
      else {
        this.autenticacaoService.oauthService.tryLoginCodeFlow().then(() => {
          this.configService.initializeAfterLogin();
          console.log('auth token event initialize after login trye login code flow initialize after login');


        });

      }

    });




    //'discovery_document_loaded' 
    //'jwks_load_error' | 
    //'invalid_nonce_in_state' | 
    //'discovery_document_load_error' | 
    //'discovery_document_validation_error' | 
    //'user_profile_loaded' | 
    //'user_profile_load_error' | 
    //'token_received' | 
    //'token_error' | 
    //'code_error' | 
    //'token_refreshed' | 
    //'token_refresh_error' | 
    //'silent_refresh_error' | 
    //'silently_refreshed' | 
    //'silent_refresh_timeout' | 
    //'token_validation_error' | 
    //'token_expires' | 
    //'session_changed' | 
    //'session_error' | 
    //'session_terminated' | 
    //'session_unchanged' | 
    //'logout' | 
    //'popup_closed' | 
    //'popup_blocked' | 
    //'token_revoke_error';


    this.autenticacaoService.oauthService.events.subscribe(({ type }: OAuthEvent) => {

      switch (type) {
        case 'token_validation_error':
          this.autenticacaoService.logout();

          // if (this.autenticacaoService.oauthService.hasValidAccessToken()) {
          //   if (this.router.url.includes('/login')) {
          //     this.router.navigateByUrl('calculei/calculos/novocalculo');
          //   }
          // }
          break;
        case 'discovery_document_loaded':
        case 'token_received':
        case 'token_refreshed':
        case 'token_expires':
          



        // case 'discovery_document_loaded':
        //   if (this.autenticacaoService.oauthService.hasValidAccessToken()) {
        //     if (this.router.url.includes('/login')) {
        //       this.router.navigateByUrl('calculei/calculos/novocalculo');
        //     }
        //   }
          break;
        default:
          console.log('auth token event', type);
          this.autenticacaoService.logout();
          break;
      }
    });
  }

  ngOnInit(): void {

    // this.autenticacaoService.oauthService.initCodeFlow();

    // this.autenticacaoService.oauthService.tryLogin().then(()=>{
    //   this.router.navigateByUrl('calculei/calculos/novocalculo');
    // });

  }

  logout() {
    this.autenticacaoService.logout();
  }

  // verificarConfirmacaoEmail() {

  //   const idToken = this.autenticacaoService.oauthService.getIdToken();
  //   const accessToken = this.autenticacaoService.oauthService.getAccessToken();

  //   if (accessToken && idToken) {
  //     let claims = this.autenticacaoService.oauthService.getIdentityClaims();

  //     let usuarioRequest = new Usuario();
  //     usuarioRequest.rowKey = claims['oid'];
  //     usuarioRequest.email = claims['emails'][0];

  //     this.listarUsuarioService.listarUsuario(usuarioRequest)
  //       .then(async usuario => {

  //         //controle de usuario e assinatura
  //         await this.configService.setUsuario(usuario as Usuario);
  //         this.configService.setAssinatura(Global.usuario.assinatura);
  //         await this.configService.setAssinaturaStatus();



  //         if (Global.usuario.emailConfirmado) {

  //           if (this.router.url.includes('/login')) {
  //             this.router.navigateByUrl('calculei/calculos/novocalculo');
  //           }
  //         }
  //         else {
  //           this.router.navigateByUrl('confirmaremail?id=' + Global.usuario.rowKey);
  //         }

  //       });
  //   } else {

  //     // this.autenticacaoService.oauthService.initCodeFlow();
  //     // this.router.navigateByUrl('/login');

  //   }

  // }

}
