<div class=" card">
    <div class=" card-header border-dark">
        <h5 class=" h3 mb-0">Simulador de Desconto</h5>
    </div>
    <div class=" card-body">








        <form [formGroup]="simuladorForm">



            <div class="row py-4">
              
             

                <div class="col-4 py-4" popoverTitle="valorCheio" [popover]="'Valor cobrado a cada renovação do ciclo do plano.'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        valorCheio
                    </h5>

                    <input type="text" [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" formControlName="valorCheio"
                        class="form-control">

                </div>



                <!-- <div class="col-4 py-4" popoverTitle="valorOriginal" [popover]="'Nome do plano. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        valorOriginal
                    </h5>

                    <input type="text" [inputMask]="currencyInputMask" placeholder="R$ 0,00" formControlName="valorOriginal" class="form-control">

                </div> -->

                <div class="col-4 py-4" popoverTitle="desconto" [popover]="'Nome do plano. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        desconto
                    </h5>

                    <input type="text" [inputMask]="jurosInputMask" placeholder=" 0.00 %" formControlName="desconto" class="form-control">

                </div>

                <div class="col-4 py-4" popoverTitle="descontoMeioPagamento" [popover]="'Nome do plano. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        descontoMeioPagamento
                    </h5>

                    <input type="text" [inputMask]="jurosInputMask" placeholder=" 0.00 %" formControlName="descontoMeioPagamento" class="form-control">

                </div>



                <div class="col-4 py-4" popoverTitle="valorFinal" [popover]="'Valor cobrado a cada renovação do ciclo do plano.'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        valorFinal
                    </h5>

                    <input type="text" [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" formControlName="valorFinal"
                        class="form-control">

                </div>


            
               



            </div>





        </form>






        














    </div>
</div>