
<ng-template #classic3 let-c="close" let-d="dismiss">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">Novo Abatimento por Evolução de RMI</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
          
          <i class="ni ni-fat-remove " ></i>
        </button>
      </div>
      <div class="modal-body">

        <form [formGroup]="novaEvolucaoRMIForm" (ngSubmit)="submitNovaEvolucaoRMI()">
            <div class="row py-2">
                <div class="col-lg-12">
                    <div class="form-group">
                        <h5 class=" card-title text-uppercase mb-0 text-default">
                            Início
                          </h5>
                        <input
                        [inputMask]="dateInputMask"
                        class=" form-control"
                        
                        placeholder="dd/mm/aaaa"
                        type="text"
                        formControlName="competenciaInicial"/>
                        
                    </div>
                </div>
            </div>

            <div class="row py-2">
                <div class="col-lg-12">
                    <div class="form-group">
                        <h5 class=" card-title text-uppercase mb-0 text-default">
                            Fim
                          </h5>
                        <input
                        [inputMask]="dateInputMask"
                        class=" form-control"
                        
                        placeholder="dd/mm/aaaa"
                        type="text"
                        formControlName="competenciaFinal"/>
                        
                    </div>
                </div>
            </div>
    
            <div class="row py-2">
                <div class="col-lg-12">
                    <div class="form-group">
                        <h5 class=" card-title text-uppercase mb-0 text-default">
                            Valor
                          </h5>
                        <input
                            [inputMask]="currencyInputMask"
                            class=" form-control text-left"
                            
                            placeholder="R$ 0,00"
                            type="text"
                            formControlName="valor"/>
                        
                    </div>
                </div>
            </div>


            <div class="row py-2">
                <div class="col text-left">
                    <div class="form-group">
                        <button class="btn btn-outline-danger" type="button" (click)="cancelarAbatimentoEvolucaoRMI()">cancelar</button>
                        <button class="btn btn-outline-black" [disabled]="!novaEvolucaoRMIForm.valid" type="button" (click)="submitNovaEvolucaoRMI()">Adicionar Evolução RMI</button>
                    </div>
                </div>
            </div>

            <div  [className]="aguardeVisivel ? 'visible' : 'invisible'">
                <h1 class="display-1">
                  <i class="fas fa-spinner fa-spin"></i>
                </h1>
            </div>

        </form>
      </div>
    </div>
  </ng-template>



<button class="btn btn-outline-black" (click)="novaEvolucaoRMI(classic3)">
    <i *ngIf="aguardeVisivel" class="fas fa-spinner fa-spin"></i> Adcionar por Evolução de RMI
</button>