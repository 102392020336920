import { Component, OnInit } from '@angular/core';
import { AutenticacaoService } from 'src/app/servicos/oauth/autenticacao.service';

@Component({
  selector: 'app-entrar',
  templateUrl: './entrar.component.html',
  styleUrls: ['./entrar.component.scss']
})
export class EntrarComponent implements OnInit {

  constructor(private autenticacaoService:AutenticacaoService) { }

  ngOnInit(): void {
    this.login();
  }
  login(){
    this.autenticacaoService.login();
  }

}
