import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { CompartilhadoAdminModule } from './compartilhado-admin/compartilhado-admin.module';
import { CompartilhadoModule } from '../aplicacao/compartilhado/compartilhado.module';
import { UsuarioModule } from '../aplicacao/usuario/usuario.module';


@NgModule({
  declarations: [
    AdminComponent,
    DashboardAdminComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    CompartilhadoAdminModule,
    CompartilhadoModule,
    UsuarioModule
  ]
})
export class AdminModule { }
