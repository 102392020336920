<input
  style="display: none"
  type="file"
  #fileInput
  (change)="selecionarArquivo($event)"
/>

<button type="button" (click)="showFileDialog()" class="btn btn-outline-black">
  <i *ngIf="aguardeVisivel" class="fas fa-spinner fa-spin"></i>
    Selecionar Hiscre
</button>