import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';

@Component({
  selector: 'app-incluir-caso-matriz-planejamento',
  templateUrl: './incluir-caso-matriz-planejamento.component.html',
  styleUrls: ['./incluir-caso-matriz-planejamento.component.scss']
})
export class IncluirCasoMatrizPlanejamentoComponent {
  
  @Output() incluirCasoMatrizPlanejamentoEvent = new EventEmitter<boolean>();

  incluir(){
    this.incluirCasoMatrizPlanejamentoEvent.emit(true);
  }

}
