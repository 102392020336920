import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ListarPlanoResponseObject, ListarPlanosResponse } from 'src/app/modelos/safe2pay/listarplanosresponse';
import { DesabilitarPlanoService } from 'src/app/servicos/pagamento/safe2pay/desabilitar-plano.service';
import { ListarPlanosCriadosService } from 'src/app/servicos/pagamento/safe2pay/listar-planos-criados.service';


@Component({
  selector: 'app-planos-criados',
  templateUrl: './planos-criados.component.html',
  styleUrls: ['./planos-criados.component.scss']
})
export class PlanosCriadosComponent implements OnInit, AfterViewChecked{

  ColumnMode = ColumnMode;

  entries: number = 10;
  selected: any[] = [];
  temp = [];
  activeRow: any;
  rows = [];
  rowsMap=[];

  planos:ListarPlanosResponse;
  

  constructor(private listarPlanosCriadosService:ListarPlanosCriadosService,
    private desabilitarPlanoService:DesabilitarPlanoService,
    private changeDetector: ChangeDetectorRef){
    

  }

  ngOnInit(): void {
    this.updatePlanos();
    
  }
  ngAfterViewChecked(): void {
    
    this.changeDetector.detectChanges();
    window.dispatchEvent(new Event('resize'));
  }



  entriesChange($event) {
    this.entries = $event.target.value;
  }
  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.rowsMap.filter(function(d) {
    
      for (var key in d) {
        
        if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
          return true;
        }
      }
      return false;
    });
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  onActivate(event) {
    
    if(event.type === "click"){
      this.activeRow = event.row;
      // this.usuario = this.rows.filter(row=>row.rowKey===this.activeRow.rowKey)[0];
    }
    
  }

  desativarPlano(row:number){

    this.desabilitarPlanoService.desabilitarPlano(this.rows[row] as ListarPlanoResponseObject)
    .then(response=>{
      this.updatePlanos();
    })
    //criar servico desativar plano
  }

  updatePlanos(){

    this.listarPlanosCriadosService.listarPlanos()
    .then(planos=>{

      this.planos = planos as ListarPlanosResponse;



      this.rows = this.planos.data.objects;
      // this.rowsMap = this.rows.map((prop)=> {return { idPlan:prop.idPlan, nome:prop.name, quantidade:prop.quantitySubscription,  valor:prop.amount};});
      this.temp = this.rows.map((prop, key) => {
        return {
          ...prop,
          id: key
        };
      });


      

    });
    
  }

}
