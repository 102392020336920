import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContasRoutingModule } from './contas-routing.module';
import { ContasComponent } from './contas.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { CompartilhadoModule } from '../compartilhado/compartilhado.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
  declarations: [
    ContasComponent,
    UsuariosComponent
  ],
  imports: [
    CommonModule,
    ContasRoutingModule,
    CompartilhadoModule,
    NgxDatatableModule
  ],
  exports:[
    UsuariosComponent
  ]
})
export class ContasModule { }
