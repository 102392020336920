<div *ngIf="entrevistas">
    <div class="row">
        <div class="col">
            <p class="h3">
                Entrevistas
            </p>
        </div>
    </div>
    
    <div class="row flex d-flex py-2">
        <div class="col-3" *ngFor="let entrevista of entrevistas" >
            <div class="row bg-secondary rounded m-2 p-2" role="button" (click)="selecionarEntrevista(entrevista)">
                <div class="col">
                    <span class="h3 d-block p-0 m-0">
                        {{entrevista.nome}}
                    </span>
                    <span class="h6 d-block p-0 m-0">
                        {{entrevista.cliente.nome}} {{entrevista.cliente.sobrenome}}
                    </span>
                    <span class="h6 d-block p-0 m-0">
                        {{entrevista.ficha.nome}} 
                    </span>
                </div>
            </div>
        </div>
    </div>
    
</div>