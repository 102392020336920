import { Component, Input, OnInit } from '@angular/core';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';
import { TiposIndicadoresCNIS } from 'src/app/modelos/previdenciario/tiposindicadorescnis';
import { LogService } from 'src/app/servicos/log.service';

@Component({
  selector: 'app-canvas-indicadores',
  templateUrl: './canvas-indicadores.component.html',
  styleUrl: './canvas-indicadores.component.scss'
})
export class CanvasIndicadoresComponent implements OnInit{
  @Input() calculoBeneficios:CalculoBeneficios;
  

  constructor(){}

  ngOnInit(): void {
    LogService.log("sequencias indicadores", this.calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias);
  }

}
