export class CriarAssinaturaRequest {

    paymentMethod: number;
    emails: string[];
    customer: CriarAssinaturaRequestCustomer;
    Token: string;

}

export class CriarAssinaturaRequestCustomer {
    name: string;
    identity: string;
    phone: string;
    email: string;
    address: CriarAssinaturaRequestAddress;

}
export class CriarAssinaturaRequestAddress {
    street: string;
    number: string;
    district: string;
    zipCode: string;
    city: CriarAssinaturaRequestCity;
    complement:string;
    cityName:string;
    stateInitials:string;
    countryName:string;

}
export class CriarAssinaturaRequestCity {
    codeIBGE: string;
    cityName: string;
}