import { RelatorioMapaQualidadeSeguradoConsolidadoComponent } from './relatorio-mapa-qualidade-segurado-consolidado/relatorio-mapa-qualidade-segurado-consolidado.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RelatoriosComponent } from './relatorios.component';
import { RelatorioPlanejamentoKertzmanComponent } from './relatorio-planejamento-kertzman/relatorio-planejamento-kertzman.component';
import { RelatorioPlanejamentoComponent } from './relatorio-planejamento/relatorio-planejamento.component';
import { RelatorioPlanejamentoComparativoComponent } from './relatorio-planejamento-comparativo/relatorio-planejamento-comparativo.component';

import { RelatorioVariacaoRmiComponent } from './relatorio-variacao-rmi/relatorio-variacao-rmi.component';
import { RelatorioVariacaoContribuicoesOriginaiSimulacaoComponent } from './relatorio-variacao-contribuicoes-originai-simulacao/relatorio-variacao-contribuicoes-originai-simulacao.component';
import { RelatorioVariacaoPaybackComponent } from './relatorio-variacao-payback/relatorio-variacao-payback.component';
import { RelatorioVariacaoTaxaComponent } from './relatorio-variacao-taxa/relatorio-variacao-taxa.component';
import { RelatorioVariacaoLucroKertzmanComponent } from './relatorio-variacao-lucro-kertzman/relatorio-variacao-lucro-kertzman.component';
import { RelatorioSumarioSimulacaoRmiComponent } from './relatorio-sumario-simulacao-rmi/relatorio-sumario-simulacao-rmi.component';
import { RelatorioMatrizPlanejamentoComponent } from './relatorio-matriz-planejamento/relatorio-matriz-planejamento.component';
import { RelatorioJanelasPeriodoContributivoComponent } from './relatorio-janelas-periodo-contributivo/relatorio-janelas-periodo-contributivo.component';
import { RelatorioEvolucaoSalariosPreAnaliseComponent } from './relatorio-evolucao-salarios-pre-analise/relatorio-evolucao-salarios-pre-analise.component';
import { RelatorioCalculoBeneficiosSumarioComponent } from './relatorio-calculo-beneficios-sumario/relatorio-calculo-beneficios-sumario.component';
import { RelatorioPreparacaoAnaliseCnisComponent } from './relatorio-preparacao-analise-cnis/relatorio-preparacao-analise-cnis.component';
import { RelatorioIncapacidadeTemporariaComponent } from './relatorio-incapacidade-temporaria/relatorio-incapacidade-temporaria.component';
import { RelatorioMapaQualidadeSeguradoComponent } from './relatorio-mapa-qualidade-segurado/relatorio-mapa-qualidade-segurado.component';
import { RelatorioQualidadeSeguradoComponent } from './relatorio-qualidade-segurado/relatorio-qualidade-segurado.component';
import { RelatorioLiquidacaoSentencaComponent } from './relatorio-liquidacao-sentenca/relatorio-liquidacao-sentenca.component';
import { RelatorioBeneficioComponent } from '../aplicacao/calculos/previdenciario/compartilhado/relatorio-beneficio/relatorio-beneficio.component';
import { RelatorioRmiBeneficioComponent } from './relatorio-rmi-beneficio/relatorio-rmi-beneficio.component';
import { RelatorioTabelaMatrizComponent } from './relatorio-tabela-matriz/relatorio-tabela-matriz.component';
import { RelatorioAposentadoriaInvalidezComponent } from './relatorio-aposentadoria-invalidez/relatorio-aposentadoria-invalidez.component';


const routes: Routes = [
  {
    path:'',
    component:RelatoriosComponent,
    children:[
      {
        path:'',
        redirectTo:'planejamentokertzman',
        pathMatch:'full'
      },
      {
        path:'planejamentokertzman',
        component:RelatorioPlanejamentoKertzmanComponent
      },
      {
        path:'planejamento',
        component:RelatorioPlanejamentoComponent
      },
      {
        path:'planejamentocomparativo',
        component:RelatorioPlanejamentoComparativoComponent
      },
      {
        path:'variacaocontribuicoes',
        component:RelatorioVariacaoContribuicoesOriginaiSimulacaoComponent
      },
      {
        path:'variacaormi',
        component:RelatorioVariacaoRmiComponent
      },
      {
        path:'variacaopayback',
        component:RelatorioVariacaoPaybackComponent
      },
      {
        path:'variacaotaxa',
        component:RelatorioVariacaoTaxaComponent
      },
      {
        path:'variacaolucro',
        component:RelatorioVariacaoLucroKertzmanComponent
      },

      {
        path:'sumariosimulacaormi',
        component:RelatorioSumarioSimulacaoRmiComponent
      },
      {
        path:'matrizplanejamento',
        component:RelatorioMatrizPlanejamentoComponent
      },
      {
        path:'janelasperiodocontributivo',
        component:RelatorioJanelasPeriodoContributivoComponent
      },
      {
        path:'evolucaosalariospreanalise',
        component:RelatorioEvolucaoSalariosPreAnaliseComponent
      },
      {
        path:'calculobeneficios',
        component:RelatorioCalculoBeneficiosSumarioComponent
      },
      {
        path:'analisecnis',
        component:RelatorioPreparacaoAnaliseCnisComponent
      },
      {
        path:'incapacidadetemporaria',
        component:RelatorioIncapacidadeTemporariaComponent
      },
      {
        path:'qualidadesegurado',
        component:RelatorioQualidadeSeguradoComponent
      },
      {
        path:'mapaqualidadesegurado',
        component:RelatorioMapaQualidadeSeguradoComponent
      },
      {
        path:'mapaqualidadeseguradoconsolidado',
        component:RelatorioMapaQualidadeSeguradoConsolidadoComponent
      },
      {
        path:'liquidacaosentenca',
        component:RelatorioLiquidacaoSentencaComponent
      },
      {
        path:'beneficio',
        component:RelatorioRmiBeneficioComponent
        
      },

      {
        path:'invalidez',
        component:RelatorioAposentadoriaInvalidezComponent
        
      },

      {
        path:'tabelamatriz',
        component:RelatorioTabelaMatrizComponent
        
      },

      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatoriosRoutingModule { }
