<nav
  id="navbar-main"
  class="navbar navbar-horizontal navbar-main navbar-expand-lg navbar-dark bg-dark"
>
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/']">
      <!-- <img src="assets/img/brand/banner-logo-dark.svg" /> -->
      <img src="assets/img/brand/logo-light-default.svg" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbar-collapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse navbar-custom-collapse collapse"
      id="collapseBasic" [collapse]="isCollapsed"
    >
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/']">
              <!-- <img src="assets/img/brand/banner-logo-light.svg" /> -->
              <img src="assets/img/brand/logo-light-default.svg" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbar-collapse"
              aria-controls="navbar-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              (click)="isCollapsed = !isCollapsed"
            >
              <span></span> <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/dashboards/dashboard']"
          >
            <span class="nav-link-inner--text">Dashboard</span>
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/examples/pricing']"
          >
            <span class="nav-link-inner--text">Pricing</span>
          </a>
        </li> -->
        
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/examples/register']"
          >
            <span class="nav-link-inner--text">Register</span>
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/examples/lock']"
          >
            <span class="nav-link-inner--text">Lock</span>
          </a>
        </li> -->
      </ul>
      <hr class="d-lg-none" />
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">

        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="['/blog/home']"
            
          >
            <span class="nav-link-inner--text">Blog</span>
          </a>
        </li> -->

        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Facebook"
          >
            <i class="fab fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Instagram"
          >
            <i class="fab fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://twitter.com/calculeiapp"
            target="_blank"
            tooltip="Siga-nos no Twitter"
          >
            <i class="fab fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li>

        
        <!-- <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://github.com/creativetimofficial"
            target="_blank"
            tooltip="Star us on Github"
          >
            <i class="fab fa-github"></i>
            <span class="nav-link-inner--text d-lg-none">Github</span>
          </a>
        </li> -->
        


        <li class="nav-item">
          <a
          
            class="nav-link"
            routerLinkActive="active"
            href="javascript:void(0)"
            
            (click)="login()"
            
          >
            <span class="nav-link-inner--text">Entrar</span>
          </a>
        </li>
        
        <li class="nav-item">
          <a
            routerLinkActive="active"
            href="javascript:void(0)"
            class="nav-link"
            [routerLink]="['/paginas/criarconta']"
          > 
            <span class="nav-link-inner--text">Criar minha conta grátis</span>
          </a>
        </li>
        
      </ul>
    </div>
  </div>
</nav>
<div class="main-content">
  <!-- Header -->
  <div class="header home bg-dark pt-5 pb-7">
    <div class="container h-100">
      <div class="header-body h-100">
        <div class="row align-items-center h-100">
          <div class="col-lg-6">
            <div class="pr-5">
              <h1 class="display-1 text-white font-weight-bold mb-0">
                Calculei
              </h1>
              <h2 class="display-4 text-white font-weight-light">
                Cálculo Jurídico [Simples]
              </h2>
              <p class="text-white mt-4">
                Do seu jeito. Simples.
              </p>
              <p class="text-white mt-4">
                
              </p>
              <div class="mt-5">
                <a
                  class="btn btn-outline-white"
                  [routerLink]="['/paginas/criarconta']"
                  
                >Criar minha conta grátis</a>
                
                <!-- <a
                  href="https://www.creative-tim.com/product/argon-dashboard-pro-angular?ref=adpa-presentation-page"
                  class="btn btn-default my-2"
                  >já tenho acesso</a
                > -->
              </div>
            </div>
          </div>


          <div class="col-lg-6">
            <div class="row pt-5">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-info text-white rounded-circle shadow mb-4"
                    >
                    60+
                      <!-- <i class="fas fa-blind"></i> -->
                    </div>
                    <h5 class="h2">Previdenciário</h5>
                    <!-- <p>Cálculo de Benefícios</p>
                    <p>Liquidação de Sentença</p> -->


                    <!-- <ul class=" list-group list-group-flush" data-toggle="checklist">
                      <li class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                        
                        <div class=" checklist-item checklist-item-info">
                          <div class=" checklist-info">
                            <h3 class=" checklist-title mb-0">Cálculo de Benefícios</h3>
                            
                          </div>
                        </div>
                    </li>
                    <li class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                        
                      <div class=" checklist-item checklist-item-info">
                        <div class=" checklist-info">
                          <h3 class=" checklist-title mb-0">Benefícios</h3>
                          
                        </div>
                      </div>
                    </li>
                    
                    <li class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                        
                      <div class=" checklist-item checklist-item-info">
                        <div class=" checklist-info">
                          <h3 class=" checklist-title mb-0">Liquidação de Sentença</h3>
                          
                        </div>
                      </div>
                    </li>

                    <li class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                        
                      <div class=" checklist-item checklist-item-info">
                        <div class=" checklist-info">
                          <h3 class=" checklist-title mb-0">Revisão da Vida Toda</h3>
                          
                        </div>
                      </div>
                    </li>

                    <li class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                        
                      <div class=" checklist-item checklist-item-info">
                        <div class=" checklist-info">
                          <h3 class=" checklist-title mb-0">Valor da Causa</h3>
                          
                        </div>
                      </div>
                    </li>
          
                    </ul> -->
                  </div>
                </div>
                
              </div>



              <div class="col-md-6 pt-lg-5 pt-4">

                <div class="card">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-success text-white rounded-circle shadow mb-4"
                    >
                      <i class="fas fa-home"></i>
                    </div>
                    <h5 class="h2">Cível</h5>
                    <!-- <p>Cálculo de Benefícios</p>
                    <p>Liquidação de Sentença</p> -->


                    <!-- <ul class=" list-group list-group-flush" data-toggle="checklist">
                      
                    <li class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                        
                      <div class=" checklist-item checklist-item-success">
                        <div class=" checklist-success">
                          <h3 class=" checklist-title mb-0">Liquidação de Sentença Cível</h3>
                          
                        </div>
                      </div>
                    </li>
          
                    </ul> -->
                  </div>
                </div>

              </div> 



              <!-- <div class="col-md-6 pt-lg-5 pt-4">
                <div class="card mb-4">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow mb-4"
                    >
                    <i class="fas fa-id-badge"></i>
                    </div>
                    <h5 class="h3">Trabalhista</h5>
                    <p>Rescisão</p>
                    <p>FGTS</p>
                    <p>Seguro Desemprego</p>
                  </div>
                </div>


                
                <div class="card mb-4">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-success text-white rounded-circle shadow mb-4"
                    >
                      <i class="fas fa-home"></i>  
                    </div>
                    <h5 class="h3">Cível</h5>
                    <p>Revisão de Contratos</p>
                    <p>Revisão do FGTS</p>
                    <p>Revisão do PASEP</p>
                  </div>
                </div>
              </div> -->



            </div>






            <div class="row pt-5">
              



              <!-- <div class="col-md-6 pt-lg-5 pt-4">

                <div class="card">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-danger text-white rounded-circle shadow mb-4"
                    >
                    <i class="fas fa-receipt"></i>
                    </div>
                    <h5 class="h3">Tributário</h5>
                    <p>Exclusão de ICMS sobre TUSD</p>
                    
                  </div>
                </div>

              </div>  -->



              <!-- <div class="col-md-6 pt-lg-5 pt-4">
                <div class="card mb-4">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow mb-4"
                    >
                    <i class="fas fa-id-badge"></i>
                    </div>
                    <h5 class="h3">Trabalhista</h5>
                    <p>Rescisão</p>
                    <p>FGTS</p>
                    <p>Seguro Desemprego</p>
                  </div>
                </div>


                
                <div class="card mb-4">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-success text-white rounded-circle shadow mb-4"
                    >
                      <i class="fas fa-home"></i>  
                    </div>
                    <h5 class="h3">Cível</h5>
                    <p>Revisão de Contratos</p>
                    <p>Revisão do FGTS</p>
                    <p>Revisão do PASEP</p>
                  </div>
                </div>
              </div> -->



            </div>
          </div>
          <!-- <div class="col-lg-6">
            <div class="row pt-5">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-red text-white rounded-circle shadow mb-4"
                    >
                      <i class="fas fa-coins"></i>
                    </div>
                    <h5 class="h3">Atualização monetária</h5>
                    <p>Liquidação de sentença</p>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-info text-white rounded-circle shadow mb-4"
                    >
                      <i class="fas fa-blind"></i>
                    </div>
                    <h5 class="h3">Previdenciário</h5>
                    <p>
                      Tempo de contribuição<br>
                      Salário de benefício<br>
                      Revisão<br>
                    </p>
                    
                  </div>
                </div>
              </div>
              <div class="col-md-6 pt-lg-5 pt-4">
                <div class="card mb-4">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-success text-white rounded-circle shadow mb-4"
                    >
                      <i class="fas fa-id-badge"></i>
                    </div>
                    <h5 class="h3">Trabalhista</h5>
                    <p>
                      Rescisão<br>
                      FGTS<br>
                      Seguro Desemprego<br>
                    </p>
                  </div>
                </div>
                <div class="card mb-4">
                  <div class="card-body">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow mb-4"
                    >
                      <i class="fas fa-receipt"></i>
                    </div>
                    <h5 class="h3">Tributário</h5>
                    <p>
                      ICMS sobre TUSD/TUST<br>
                      ICMS sobre PIS/COFINS<br>
                      ISS sobre PIS/COFINS<br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    
  </div>
  <section class="py-6 pb-9 bg-default">
    <div class="container-fluid">
      <div class="row justify-content-center text-center">
        <div class="col-md-6">
          <h2 class="display-3 text-white">Simples e Transparente</h2>
          <p class="lead text-white">

            Programa de cálculo não precisa ser caixa-preta! O Calculei foi feito para te dar segurança e rapidez com clareza

          </p>


          <p class="lead text-white">

            Você fica informado de todos os passos, e os porquês de cada valor do Cálculo.

          </p>

          <p class="lead text-white">

            Você não precisa mandar o seu cliente para outro advogado. Com a importação automática do CNIS, Hiscre e Sentença o Calculei indica o melhor benefício e todos os relatórios com 2 clicks.

          </p>

        </div>
      </div>
    </div>
  </section>
  <section class="section section-lg pt-lg-0 mt--7">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-4">
              <div class="card card-lift--hover shadow border-0">
                <div class="card-body py-5">
                  <div
                    class="icon icon-shape bg-default text-white rounded-circle mb-4"
                  >
                    <i class="ni ni-spaceship"></i>
                  </div>
                  <h4 class="h3 text-default text-uppercase">
                    Design Thinking
                  </h4>
                  <p class="description mt-3">
                    Uma metodologia poderosa para decretar o fim das velhas ideias, potencializar a inovação e conduzir sua advocacia ao sucesso
                  </p>
                  <div>
                    <span class="badge badge-pill badge-default mr-1"
                      >inovação</span
                    >
                    <span class="badge badge-pill badge-default mr-1"
                      >sucesso</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-lift--hover shadow border-0">
                <div class="card-body py-5">
                  <div
                    class="icon icon-shape bg-default text-white rounded-circle mb-4"
                  >
                    <i class="ni ni-palette"></i>
                  </div>
                  <h4 class="h3 text-default text-uppercase">
                    Visual Law
                  </h4>
                  <p class="description mt-3">
                    Compreensão, transmissão e retenção de informações facilitadas. Leitura de relatórios e formulários mais agradáveis.
                  </p>
                  <div>
                    <span class="badge badge-pill badge-default mr-1">compreensão</span>
                    <span class="badge badge-pill badge-default mr-1">facilidade</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-lift--hover shadow border-0">
                <div class="card-body py-5">
                  <div
                    class="icon icon-shape bg-default text-white rounded-circle mb-4"
                  >
                    <i class="ni ni-support-16"></i>
                  </div>
                  <h4 class="h3 text-default text-uppercase">
                    Simples
                  </h4>
                  <p class="description mt-3">
                    Aquisição de dados do cálculo de forma didática promovendo maior entendimento e produtividade.
                  </p>
                  <div>
                    <span class="badge badge-pill badge-default mr-1"
                      >didática</span
                    >
                    <span class="badge badge-pill badge-default mr-1"
                      >entendimento</span
                    >
                    <span class="badge badge-pill badge-default"
                      >produtividade</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-7">
    <div class="container">
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="display-3">
            Gostou do Calculei
            <span class="text-success">e quer ter no seu escritório?</span>
          </h2>
          <p class="lead">
            Se você gostou, você pode tê-lo agora. Clique no botão
            abaixo para criar uma conta grátis!
            <!-- Se você gostou, você pode tê-lo agora. Clique no botão
            abaixo para criar uma conta e testar grátis por 15 dias ou escolher o melhor plano e assinar.
            Construa para o seu escritório um novo horizonte! -->
          </p>
          <div class="btn-wrapper">
            <a
                  class="btn btn-default my-2"
                  [routerLink]="['/paginas/criarconta']"
                  
                >Criar minha conta grátis</a>
            <!-- <a
              [routerLink]="['/paginas/criarconta']"
              class="btn btn-primary btn-icon mb-3 mb-sm-0"
            >
              <span class="btn-inner--icon"><i class="ni ni-basket"></i></span>
              <span class="btn-inner--text">Escolher meu plano e Assinar</span>
              <span
                class="badge badge-md badge-pill badge-floating badge-danger border-white"
                >R$90</span
              >
            </a> -->
          </div>
          
        </div>
      </div>
    </div>
  </section>
  
  <section class="py-6">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img src="assets/img/theme/telacalculei.png" class="img-fluid" />
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <h1>Sabemos que detalhe conta para aumentar o ganho na produtividade da sua advocacia</h1>
            
            <ul class="list-unstyled mt-5">
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="bi-check2"></i>
                    </div>
                  </div>
                  <div><h4 class="mb-0">Experiência de usuário moderna e confortável</h4></div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="bi-check2"></i>
                    </div>
                  </div>
                  <div><h4 class="mb-0">Relatórios personalizados com a sua marca</h4></div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="bi-check2"></i>
                    </div>
                  </div>
                  <div><h4 class="mb-0">Flexibilidade para escolher a aplicação de juros e correção monetária</h4></div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="bi-check2"></i>
                    </div>
                  </div>
                  <div><h4 class="mb-0">Time de suporte humanizado</h4></div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="bi-check2"></i>
                    </div>
                  </div>
                  <div><h4 class="mb-0">Atualização constante de novas regras e cálculos
                  </h4></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
</div>


<!-- Footer -->
<footer class="py-5" id="footer-main">
  <div class="container">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }}
          <a
            href="javascript:void(0)"
            class="font-weight-bold ml-1"
            
            >calculei.app</a
          >
        </div>
      </div>
      <div class="col-xl-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-xl-end"
        >
          <li class="nav-item">
            <a
              href="javascript:void(0)"
              class="nav-link"
              
              >Termos de uso</a
            >
          </li>
          <li class="nav-item">
            <a
            href="javascript:void(0)"
            class="nav-link"
              >LGPD</a
            >
          </li>
          
        </ul>
      </div>
    </div>
  </div>
</footer>
