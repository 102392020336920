
import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { Calculo } from 'src/app/modelos/common/calculo';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { ResultadoCalculo } from 'src/app/modelos/common/resultadocalculo';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { CapitalizacaoMensal } from 'src/app/modelos/previdenciario/capitalizacaomensal';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { RelatorioPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioplanejamentoprevidenciario';
import { FasesPlanejamento } from 'src/app/modelos/ui/fasesplanejamento';
import { FasesPlanejamentoKertzman } from 'src/app/modelos/ui/fasesplanejamentokertzman';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { ListarCalculoChaveService } from 'src/app/servicos/calculo/common/listar-calculo-chave.service';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ListarTabelaCapitalizacaoSaqueService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao-saque.service';
import { ListarTabelaCapitalizacaoService } from 'src/app/servicos/calculo/previdenciario/listar-tabela-capitalizacao.service';
import { LeituraJsonBlobService } from 'src/app/servicos/relatorio/leitura-json-blob.service';
import { ListarRelatorioPlanejamentoPdfService } from 'src/app/servicos/relatorio/listar-relatorio-planejamento-pdf.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';

@Component({
  selector: 'app-relatorio-sumario-simulacao-rmi',
  templateUrl: './relatorio-sumario-simulacao-rmi.component.html',
  styleUrls: ['./relatorio-sumario-simulacao-rmi.component.scss']
})
export class RelatorioSumarioSimulacaoRmiComponent implements OnInit, AfterViewInit {

  constructor(private location: Location, private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private leituraJsonBlobService:LeituraJsonBlobService,
    private listarTabelaCapitalizacaoSaqueService: ListarTabelaCapitalizacaoSaqueService,
    private listarTabelaCapitalizacaoService: ListarTabelaCapitalizacaoService,
    private route: ActivatedRoute,
    private listatCalculoChaveService: ListarCalculoChaveService,
    private listarCalculoResultadoService: ListarCalculoResultadoService) { }


  caso: CasoPlanejamentoPrevidenciario;

  logoUrl: string;


  linhasCapitalizacaoMensal: CapitalizacaoMensal[];
  linhasCapitalizacaoMensalSaque: CapitalizacaoMensal[];

  calculoRowKey: string;
  calculoPartitionKey: string;
  calculoUrl: string;

  resultadoCalculo: ResultadoCalculo;

  casoMelhor:CasoPlanejamentoPrevidenciario;
  simulacoes:CasoPlanejamentoPrevidenciario[];

  calculoPlanejamento:CalculoPlanejamentoPrevidenciario;

  beneficios:Beneficio[];


  carregarDados(request: CalculoPlanejamentoPrevidenciario) {

    this.calculoPlanejamento = request
    this.beneficios = this.calculoPlanejamento.casos.map(caso=>caso.beneficio);

    this.logoUrl = this.calculoPlanejamento.usuario.logoUrl;
  }

  ngAfterViewInit(): void {
   
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {


      this.calculoUrl = params.url;
      this.calculoRowKey = params.rowkey;
      this.calculoPartitionKey = params.partitionkey;

      let resultadoCalculo: ResultadoCalculo = new ResultadoCalculo();

      resultadoCalculo.rowKey = this.calculoRowKey;
      resultadoCalculo.partitionKey = this.calculoPartitionKey;


      let calculo: Calculo = new Calculo();
      calculo.rowKey = this.calculoRowKey;
      calculo.partitionKey = this.calculoPartitionKey;
      calculo.tipo = CalculoTipos.SUMARIOSIMULACAORMI;


      this.leituraJsonBlobService.ListarJsonBlob(this.calculoUrl, request =>{

        let calculo:CalculoPlanejamentoPrevidenciario = request as CalculoPlanejamentoPrevidenciario;
        // console.log(casoResultado);
        this.carregarDados(request as CalculoPlanejamentoPrevidenciario);
      });
      
    });

  }


}
