
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';

@Injectable({
  providedIn: 'root'
})
export class ListarTabelaCapitalizacaoSaqueService {

  constructor(private httpClient: HttpClient) { }

  listarTabelaCapizacaoMensalSaque(caso:CasoPlanejamentoPrevidenciario){
    return Promise.all([this.apiCall(caso)]).then((listaAPI)=>{
      return listaAPI[0];
    });
  }

  private async apiCall(caso:CasoPlanejamentoPrevidenciario){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.apiPaginas as string + 'api/ListarTabelaCapitalizacaoSaque',caso, {'headers':headers}).toPromise();
    return data;
  }
}
