import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Marcador } from 'src/app/modelos/common/marcador';
import { TiposMarcador } from 'src/app/modelos/common/tiposmarcador';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';

@Component({
  selector: 'app-tabela-matriz',
  templateUrl: './tabela-matriz.component.html',
  styleUrl: './tabela-matriz.component.scss'
})
export class TabelaMatrizComponent {

  @Input() calculoMatrizPlanejamento: MatrizPlanejamento;
  @Output() atualizacaoMatrizEvent = new EventEmitter<MatrizPlanejamento>();
  tiposMarcadorType = TiposMarcador;



  constructor() { }

  addTinta(caso: CasoPlanejamentoPrevidenciario, marcador: Marcador, tipo: string, idCelula: string) {
    this.addMarcador(caso, marcador, tipo);
    let celula = <HTMLInputElement>document.getElementById(idCelula);
    celula.style.setProperty('background-color', marcador.cor);
  }

  addMarcador(caso: CasoPlanejamentoPrevidenciario, marcador: Marcador, tipo: string) {
    switch (tipo) {
      case "ALIQUOTA":
        if (!caso.aliquotaContributivaMarcadores) caso.aliquotaContributivaMarcadores = new Array<Marcador>();
        caso.aliquotaContributivaMarcadores.push(marcador);
        break;
      case "CASO":
        if (!caso.marcadores) caso.marcadores = new Array<Marcador>();
        caso.marcadores.push(marcador);
        break;

      case "DATAAPOSENTADORIA":
        if (!caso.dataAposentadoriaMarcadores) caso.dataAposentadoriaMarcadores = new Array<Marcador>();
        caso.dataAposentadoriaMarcadores.push(marcador);
        break;
      case "QUALIFICACAOREFERENCIA":
        if (!caso.qualificacaoReferenciaMarcadores) caso.qualificacaoReferenciaMarcadores = new Array<Marcador>();
        caso.qualificacaoReferenciaMarcadores.push(marcador);
        break;
      case "VALORBENEFICIO":
        if (!caso.valorBeneficioMarcadores) caso.valorBeneficioMarcadores = new Array<Marcador>();
        caso.valorBeneficioMarcadores.push(marcador);
        break;
      case "TOTALCONTRIBUTIVOCOMPLEMENTARMESES":
        if (!caso.totalContributivoComplementarMesesMarcadores) caso.totalContributivoComplementarMesesMarcadores = new Array<Marcador>();
        caso.totalContributivoComplementarMesesMarcadores.push(marcador);
        break;
      case "VALORCONTRIBUICAOCOMPLEMENTARMENSAL":
        if (!caso.valorContribuicaoComplementarMensalMarcadores) caso.valorContribuicaoComplementarMensalMarcadores = new Array<Marcador>();
        caso.valorContribuicaoComplementarMensalMarcadores.push(marcador);
        break;
      case "TOTALINVESTIMENTO":
        if (!caso.totalInvestimentoMarcadores) caso.totalInvestimentoMarcadores = new Array<Marcador>();
        caso.totalInvestimentoMarcadores.push(marcador);
        break;
      case "RETORNOTOTALINVESTIMENTOROI":
        if (!caso.retornoTotalInvestimentoRoiMarcadores) caso.retornoTotalInvestimentoRoiMarcadores = new Array<Marcador>();
        caso.retornoTotalInvestimentoRoiMarcadores.push(marcador);
        break;
      case "TAXARETORNOPLANEJAMENTOCONTRIBUTIVO":
        if (!caso.taxaRetornoPlanejamentoContributivoMarcadores) caso.taxaRetornoPlanejamentoContributivoMarcadores = new Array<Marcador>();
        caso.taxaRetornoPlanejamentoContributivoMarcadores.push(marcador);
        break;
      case "TEMPORETORNOINVESTIMENTOMESESABONO":
        if (!caso.tempoRetornoInvestimentoMesesAbonoMarcadores) caso.tempoRetornoInvestimentoMesesAbonoMarcadores = new Array<Marcador>();
        caso.tempoRetornoInvestimentoMesesAbonoMarcadores.push(marcador);
        break;
      case "LUCROPLANEJAMENTOCONTRIBUTIVO":
        if (!caso.lucroPlanejamentoContributivoMarcadores) caso.lucroPlanejamentoContributivoMarcadores = new Array<Marcador>();
        caso.lucroPlanejamentoContributivoMarcadores.push(marcador);
        break;
      case "PERCENTUALTETOINSS":
        if (!caso.percentualTetoInssMarcadores) caso.percentualTetoInssMarcadores = new Array<Marcador>();
        caso.percentualTetoInssMarcadores.push(marcador);
        break;
      case "ACUMULADOCAPITALIZACAO":
        if (!caso.acumuladoCapitalizacaoMarcadores) caso.acumuladoCapitalizacaoMarcadores = new Array<Marcador>();
        caso.acumuladoCapitalizacaoMarcadores.push(marcador);
        break;




      default:
        break;
    }
    // console.log('add marcador matriz', this.calculoMatrizPlanejamento);
    this.atualizacaoMatrizEvent.emit(this.calculoMatrizPlanejamento);

  }

  excluirMarcador(caso: CasoPlanejamentoPrevidenciario, marcador: Marcador, tipo: string) {

    switch (tipo) {
      case "ALIQUOTA":
        caso.aliquotaContributivaMarcadores.splice(caso.aliquotaContributivaMarcadores.indexOf(marcador), 1);
        break;
      case "CASO":
        caso.marcadores.splice(caso.marcadores.indexOf(marcador), 1);
        break;

      case "DATAAPOSENTADORIA":
        caso.dataAposentadoriaMarcadores.splice(caso.dataAposentadoriaMarcadores.indexOf(marcador), 1);
        break;

      case "QUALIFICACAOREFERENCIA":
        caso.qualificacaoReferenciaMarcadores.splice(caso.qualificacaoReferenciaMarcadores.indexOf(marcador), 1);
        break;

      case "VALORBENEFICIO":
        caso.valorBeneficioMarcadores.splice(caso.valorBeneficioMarcadores.indexOf(marcador), 1);
        break;
      case "TOTALCONTRIBUTIVOCOMPLEMENTARMESES":
        caso.totalContributivoComplementarMesesMarcadores.splice(caso.totalContributivoComplementarMesesMarcadores.indexOf(marcador), 1);
        break;
      case "VALORCONTRIBUICAOCOMPLEMENTARMENSAL":
        caso.valorContribuicaoComplementarMensalMarcadores.splice(caso.valorContribuicaoComplementarMensalMarcadores.indexOf(marcador), 1);
        break;
      case "TOTALINVESTIMENTO":
        caso.totalInvestimentoMarcadores.splice(caso.totalInvestimentoMarcadores.indexOf(marcador), 1);
        break;
      case "RETORNOTOTALINVESTIMENTOROI":
        caso.retornoTotalInvestimentoRoiMarcadores.splice(caso.retornoTotalInvestimentoRoiMarcadores.indexOf(marcador), 1);
        break;
      case "TAXARETORNOPLANEJAMENTOCONTRIBUTIVO":
        caso.taxaRetornoPlanejamentoContributivoMarcadores.splice(caso.taxaRetornoPlanejamentoContributivoMarcadores.indexOf(marcador), 1);
        break;
      case "TEMPORETORNOINVESTIMENTOMESESABONO":
        caso.tempoRetornoInvestimentoMesesAbonoMarcadores.splice(caso.tempoRetornoInvestimentoMesesAbonoMarcadores.indexOf(marcador), 1);
        break;
      case "LUCROPLANEJAMENTOCONTRIBUTIVO":
        caso.lucroPlanejamentoContributivoMarcadores.splice(caso.lucroPlanejamentoContributivoMarcadores.indexOf(marcador), 1);
        break;
      case "PERCENTUALTETOINSS":
        caso.percentualTetoInssMarcadores.splice(caso.percentualTetoInssMarcadores.indexOf(marcador), 1);
        break;
      case "ACUMULADOCAPITALIZACAO":
        caso.acumuladoCapitalizacaoMarcadores.splice(caso.acumuladoCapitalizacaoMarcadores.indexOf(marcador), 1);
        break;

      default:
        break;
    }

    this.atualizacaoMatrizEvent.emit(this.calculoMatrizPlanejamento);
  }

  edicaoTitulo(event, caso: CasoPlanejamentoPrevidenciario) {
    // console.log(event.target.value);
  }
  editarTituloCenario(caso: CasoPlanejamentoPrevidenciario, idEditor) {
    let editor = <HTMLInputElement>document.getElementById(idEditor);
    caso.referenciaMatriz = editor.value;

    this.atualizacaoMatrizEvent.emit(this.calculoMatrizPlanejamento);
  }
  fecharDrop(idEditor) {

    // console.log('fechar', idEditor);
    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');

  }
  focoEditorTitulo() { }
  esconderCenario(caso: CasoPlanejamentoPrevidenciario, idEditor) {

    caso.filtrarMatriz = true;
    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    this.atualizacaoMatrizEvent.emit(this.calculoMatrizPlanejamento);

  }


}
