<!-- 
<div id="aguarde" [className]="true ? 'visible' : 'invisible'">
  <div class="col-1 p-4 mb-5">
  <h1 class="display-1">
    <i class="fas fa-spinner fa-spin"></i>
  </h1>
</div> -->



<div id="aguarde" [className]="visivel ? 'visible' : 'invisible'">
  <div class="col-1 p-4 mb-5">
    <div class=" timeline-step timeline-step-xlg  ">
      <h1 class="display-1">
        <i class="fas fa-spinner fa-spin"></i>
      </h1>
    </div>
  </div>

</div>
