<form [formGroup]="novaPerguntaForm">
    <div class="row py-2">

        <div class="col-3">
            <h5 class="  text-uppercase mb-0 text-default">
                Título
            </h5>
            <input class=" form-control text-left" type="text" placeholder="Ex. exerce alguma atividade como autônomo?" formControlName="titulo" />
            <p class="h6">
                Pergunta que vai ser apresentada no formulário
            </p>
        </div>
        
        

        <div class="col-3">
            <h5 class=" text-uppercase mb-0 text-default">
                Descrição
            </h5>
            <textarea class=" form-control text-left" type placeholder="Ex. atividade autônoma é aquela que..." formControlName="descricao"></textarea>
            <p class="h6">
                Um esclarecimento breve da pergunta (opcional)
            </p>
        </div>

        <div class="col-3">
            <h5 class="  text-uppercase mb-0 text-default">
                Instruções
            </h5>
            <textarea class=" form-control text-left" type placeholder="Ex. certifique-se de que..." formControlName="instrucao"></textarea>
            <p class="h6">
                Alguma instrução ou alerta para orientar o entrevistado (opcional)
            </p>
        </div>


        <div class="col-3">
            <h5 class="  text-uppercase mb-0 text-default">
                Link
            </h5>
            <input class=" form-control text-left" type="text" placeholder="Ex. https://google.com" formControlName="link" />
            <p class="h6">
                Link externo para informações complementares sore a pergunta (opcional)
            </p>
        </div>


        <div class="col-3 ">
            <div class="row justify-content-start">
                <div class="col-auto">
                    <h5 class="text-uppercase mb-0 text-default">
                        Tipo de Resposta
                    </h5>
                </div>
            </div>
            <div class="row justify-content-start">
                <div class="col-12">
                    <div class="dropdown w-100">
                        <button class="btn w-100 btn-default btn-lg  text-left dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {{pergunta.tipoResposta | tipoResposta}}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li *ngFor="let tipo of itemsTiposResposta">
                                <button class="dropdown-item" (click)="editarTipoResposta(tipo.value)">{{tipo.text}}</button>
                            </li>
                        </ul>
                    </div>
                    <p class="h6">
                        Forma que o campo de resposta aparece ao entrevistado
                    </p>

                </div>
            </div>
        </div>

        <div class="col-3" *ngIf="pergunta.tipoResposta === tiposRespostasType.SELECAO || pergunta.tipoResposta === tiposRespostasType.SELECAOMULTIPLA">

            <h5 class="  text-uppercase mb-0 text-default">
                Nova Opção
            </h5>
            <div class=" flex d-flex">
                <input class=" form-control text-left" type="text" placeholder="Ex. sim tiro pró-labore" #novaOpcao />
                <i class="comando bi bi-arrow-right fs-6 px-2 mx-2" role="button" (click)="addOpcao()"></i>
            </div>
            <p class="h6">
                Opção da lista de opções de respostas para a pergunta
            </p>

        </div>

        <div class="col-3 " *ngIf="pergunta.tipoResposta === tiposRespostasType.SELECAO || pergunta.tipoResposta === tiposRespostasType.SELECAOMULTIPLA">

            <div class="row py-2">
                <div class="col">
                    <h5 class="  text-uppercase mb-0 text-default">
                        Opções selecionadas
                    </h5>
                </div>
            </div>
            <div class="row">
                <div class="col-auto p-0 m-0" *ngFor="let opcao of listaNovasOpcoes">
                    <span class="badge rounded-pill text-bg-dark px-4 m-1">{{opcao}}</span>
                </div>
            </div>
            
            
        </div>

        <div class="col-auto text-right align-self-center">
            <i class="comando bi bi-plus-lg fs-4 " role="button" (click)="addPergunta()"></i>
        </div>


        

    </div>

    

</form>