import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Calculo } from 'src/app/modelos/common/calculo';
import { RequestBase } from 'src/app/modelos/common/requestbase';
import { Global } from 'src/app/modelos/global';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { CriarContaRequest } from 'src/app/modelos/usuario/criarcontarequest';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListarCalculoChaveService {

  constructor(private httpClient:HttpClient) { }

  listarCalculo(calculo:Calculo){

    

    return Promise.all([this.apicall(calculo)]).then((listaAPI)=>{return listaAPI[0]});

  }

  private async apicall(calculo:Calculo){
    const headers = { 'Access-Control-Allow-Origin': '*'};
    const data = await this.httpClient.post(environment.apiPaginas as string + 'api/ListarCalculoChave',calculo, {'headers':headers}).toPromise();
    return data;
  }
}
