
<div class=" main-content">

  <div class=" bg-secondary " style="height:100vh;">
    <div class=" container h-100 ">

      <div class="row  align-items-center  h-100">


        <div class="row ">
          <div class="col-12 ">
            <div class="d-flex justify-content-center">
              <!-- <div class="spinner-grow my-2" style="width: 2.5rem; height: 2.5rem;" role="status">
              </div> -->
              <app-aguarde-config [aguarde]="true"></app-aguarde-config>
              
            </div>
          </div>
        </div>

        

      </div>

    </div>
  </div>
</div>