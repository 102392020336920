import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CivelComponent } from './civel.component';
import { LiquidacaoSentencaCivelComponent } from './liquidacao-sentenca-civel/liquidacao-sentenca-civel.component';

const routes: Routes = [
  {
    path:'',
    component:CivelComponent,
    children:[
      {
        path:'',
        redirectTo:'liquidacaosentenca',
        pathMatch:'full'
      },
      
      {
        path:'liquidacaosentenca',
        component:LiquidacaoSentencaCivelComponent,
      },
      
    ]

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CivelRoutingModule { }
